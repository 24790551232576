import React, { useState } from 'react'
import { DatePicker, Input } from 'antd'
import { Modal, } from 'antd';
import DropDown from '../inputcomponents/DropDown';
import axios from "axios";
import { authHeader } from "../../helpers/usertoken/UserToken";
import InputField from "../inputcomponents/InputField";
import moment from "moment";
import PrimaryButton from '../buttons/PrimaryButton';
import { BiPlus } from 'react-icons/bi'
import { Row, Col } from 'antd'
import { IoTrashBin } from 'react-icons/io5'
import Inputerror from '../errors/Inputerror';
import { data_not_found } from '../../helpers/Constants';
import toast,{Toaster} from 'react-hot-toast';

const { TextArea } = Input;

function Reminder({ visible, func, id, type, getreminderfun, reminder }) {


    const [stage, setstage] = useState('1')
    const [data, setdata] = useState({ type: type, type_id: id, title: '', description: '', on_date_time: '', repeat: '', notify: '' })
    const [dataerr, setdataerr] = useState({ on_date_time_err: {}, repeat_type_err: {}, notify_err: {}, type_err: {}, title_err: {} })


    const colors = ['#00b2f2', '#c75000', '#29cf5e', '#a148b5', '#f0a762', '#c979b0']



    const repeatData = [
        {
            'id': 'Once',
            'value': 'Once'
        },
       
    ]
    const notifyData = [
        {
            'id': 'Email',
            'value': 'Email'
        },
       
    ]
    function addReminder() {
        if (!data.title) {
            setdataerr({ ...dataerr, title_err: { error: true, message: 'This field is required' } })
        } else if (!data.on_date_time) {
            setdataerr({ ...dataerr, on_date_time_err: { error: true, message: 'This field is required' } })
        } else if (!data.repeat) {
            setdataerr({ ...dataerr, repeat_type_err: { error: true, message: 'This field is required' } })
        } else if (!data.notify) {
            setdataerr({ ...dataerr, notify_err: { error: true, message: 'This field is required' } })
        } else {
            if (data.type === 'assign_lead' || data.type === 'assign_lead1' || data.type === 'assign_lead2') {
                data.type = 'lead'
            }
            let reminderData = {
                'type': data.type.charAt(0).toUpperCase() + data.type.slice(1),
                'type_id': id,
                'title': data.title,
                'description': data.description,
                'on_date_time': data.on_date_time,
                'repeat': data.repeat,
                'notify': data.notify
            }

            axios.post(`reminder/create`, reminderData, authHeader).then((res) => {
                toast.success('Reminder created successfully')
                setdata({ ...data, title: '', description: '', on_date_time: '', repeat: '', notify: '' })
                getreminderfun(id)
                setstage('1')
                // getReminder()
            })
        }
    }

    function disabledDate(current, anna) {
        return current && current < moment().startOf('day');

    }

    function handleChange({ key, value }) {
        if (key === 'title') {
            setdataerr({ ...dataerr, title_err: {} })
        } else if (key === 'on_date_time') {
            setdataerr({ ...dataerr, on_date_time_err: {} })
        } else if (key === 'repeat') {
            setdataerr({ ...dataerr, repeat_type_err: {} })
        } else if (key === 'notify') {
            setdataerr({ ...dataerr, notify_err: {} })
        }
        setdata({ ...data, [key]: value })
    }

    function selectedvalue(v) {
        if (v?.id) {
            setdataerr({ ...dataerr, [v.nameerr]: {} })
            setdata({ ...data, [v.name]: v.id })
        }
    }

    function setfalseremindar() {
        func()
        //navigate('/contact_detail/' + id)

    }

    function handledatechange(v, v1) {
        setdataerr({ ...dataerr, on_date_time_err: {} })
        setdata({ ...data, on_date_time: v1 })
    }

    function handleok(v, v1) {
        // console.log('handleok', v, v1)
    }

    function updatereminder(v) {
        const send_data = {}
        send_data['status'] = false
        axios.put(`reminder/update/${v}`, send_data, authHeader)
            .then((res) => {
                console.log('reminder/update/ here', res)
                if (res.data.status) {
                    getreminderfun(id)
                }
            })
    }



    return (
        <>
        <Toaster/>
        <Modal
            centered
            visible={visible}
            footer={false}
            onOk={() => addReminder()}
            closable={false}
            onCancel={() => { stage === '1' ? setfalseremindar() : setstage('1') }}
            style={{ maxWidth: '350px' }}
        >
            <div className='row' style={{ width: '330px', maxHeight: '380px' }}>

                {stage === '1' ?
                    <div>
                        {reminder?.length > 0 ?
                            <div style={{ maxHeight: '350px' }}>
                                <h6 style={{ fontSize: '14px', fontWeight: '600',marginLeft:'3px' }}>Reminder added ({reminder.length})</h6>
                                <div style={{ minWidth:'100%',minHeight: '180px', maxHeight: '310px', overflowY: 'scroll' }}>

                                    <Row>
                                        {reminder?.map((r) => (

                                            <Col span={12} style={{ marginBottom: '5px' }} key={(r?.id !== null || r?.id !== undefined || r?.id !== '') ? r?.id : r?._id} >
                                                <div style={{ background: `${colors[Math.floor(Math.random() * colors.length)]}`, padding: '10px', borderRadius: '4px', position: 'relative', marginBottom: '5px', width: '96%', margin: 'auto', opacity: r.isActive ? 1 : 0.5 }}>
                                                    {r.isActive && <IoTrashBin size={16} style={{ position: 'absolute', right: '5px', top: '10px' }} onClick={() => updatereminder(r._id)} />}
                                                    <h6 style={{ fontSize: '13px', fontWeight: '700',maxWidth:'94%' }}>{r.title}</h6>
                                                    <h6 style={{ fontSize: '11px' }}>{r.description}</h6>
                                                    <p style={{ fontSize: '10px', fontWeight: '600', border: '1px solid black', marginBottom: '0px', width: 'fit-content', padding: '2px', borderRadius: '2px' }}>{moment(r.on_date_time).format('lll')}</p>
                                                </div>
                                            </Col>
                                        ))}

                                    </Row>
                                </div>
                            </div>
                            :
                            <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '20px', minHeight: '200px' }}>
                                <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '130px', objectFit: 'contain' }} />
                                <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center' }}>{"You don't have any reminder's created."}</h4>
                                <h6 style={{ color: '#747474', fontSize: '12px', maxWidth: '80%', textAlign: 'center', margin: 'auto', marginTop: '-15px' }}>{"Click on the below add reminder button to create a new reminder."}</h6>
                            </div>

                        }

                        <div style={{ display: 'flex', position: 'absolute', cursor: 'pointer', right: '10px', bottom: '10px', background: 'white', alignItems: 'center', marginTop: '0px' }} onClick={() => setstage('2')}>
                            <BiPlus color="#00b2f2" />
                            <p style={{ color: '#00b2f2', marginBottom: '0px',fontWeight:'600',fontSize:'12px' }}>Add Reminder</p>
                        </div>
                    </div>
                    :
                    <>
                        <h5 style={{ fontSize: '16px', fontWeight: '600' }}>Add Reminder</h5>

                        <div className="col-md-12 mt-3">
                            <h6 style={{ fontSize: '14px' }}>Title</h6>
                            <InputField
                                placeholder="Enter title"
                                type="text"
                                priority="high"
                                error_obj={dataerr.title_err}
                                onChange={handleChange}
                                name={'title'}
                                value={data.title}
                            />
                        </div>

                        <div className="col-md-12 mt-3">
                            <h6 style={{ fontSize: '14px' }}>Description</h6>
                            <TextArea
                                priority="high"
                                rows={4}
                                onChange={(e) => setdata({ ...data, description: e.target.value })}
                                name={'description'}
                                style={{ height: '40px', maxHeight: '40px' }}
                                value={data.description}
                                error_obj={dataerr.description_err}
                            />
                        </div>

                        <div className="col-md-6 mt-3">
                            <div style={{ margin: '0px 0px', width: '100%', alignItems: 'center' }}>
                                <h6 style={{ fontSize: '14px' }}>Date</h6>
                                <DatePicker
                                    showTime
                                    disabledDate={disabledDate}
                                    error_obj={dataerr.on_date_time_err}
                                    onChange={handledatechange} onOk={handleok}
                                    format="YYYY-MM-DD HH:mm"
                                    value={data.on_date_time !== '' ? moment(data.on_date_time) : null}
                                    size={'medium'}
                                    style={{ width: '100%', borderLeft: '4px solid #292961', borderRadius: '4px', marginTop: '1px' }}
                                />
                                <Inputerror message={dataerr.on_date_time_err.message} />
                            </div>
                        </div>

                        <div className="col-md-6 mt-3">
                            <h6 style={{ fontSize: '14px' }}>Repeat Type</h6>
                            <DropDown
                                onChange={(v) => selectedvalue(v)}
                                priority={'high'}
                                name='repeat'
                                nameerr='repeat_type_err'
                                value={data.repeat}
                                error_obj={dataerr.repeat_type_err}
                                dropdownValue={data.repeat}
                                options={repeatData}
                            />
                        </div>

                        <div className="col-md-6 mt-3">
                            <h6 style={{ fontSize: '14px' }}>Notify</h6>
                            <DropDown
                                onChange={(v) => selectedvalue(v)}
                                priority={'high'}
                                name='notify'
                                nameerr='notify_err'
                                value={data.notify}
                                error_obj={dataerr.notify_err}
                                dropdownValue={data.notify}
                                options={notifyData}
                            />
                        </div>

                        <div onClick={() => addReminder()} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <PrimaryButton btn_name="Add Reminder" />
                        </div>
                    </>}
            </div>
        </Modal>
        </>
    )
}

export default Reminder;
