import React from 'react'
import { IconButton } from '@material-ui/core';
import { IoIosArrowBack } from 'react-icons/io'

import {  Button,Empty } from 'antd';
import {useNavigate} from 'react-router-dom'
import { main_bg } from '../../../components/constants/Constants1';

function Notification() {

    const navigate = useNavigate()


    return (
        <div style={{width:'100%',height:'100%'}}>
            <div style={{background:`${main_bg}`,display:'flex',flexDirection:'row',alignItems:'center',width:'100%',position:'sticky',top:'0px',left:'0px',minHeight:'45px'}}>
                <IconButton onClick={()=>navigate(-1)}><IoIosArrowBack color="#fff" size={24} /></IconButton>    
                <h6 style={{marginBottom:'0px',color:'#fff',textAlign:'center'}}>Notifications</h6>
            </div>
            
            <div style={{marginTop:'2vh'}}>
                {/* <div style={{padding:'5px 20px',display:'flex',borderBottom:'1px solid #f0f0f0'}}>
                    <p><Avatar style={{ backgroundColor: colors[Math.floor(Math.random() * 10)],marginBottom:'0px'}} size="large">K</Avatar></p>
                    <p style={{marginLeft:'20px',marginBottom:'0px',fontSize:'14px'}}><strong>Krishna Prasad PK</strong><span style={{color:'#e8e8e8',marginLeft:'5'}}>as sent you an document to share me at new time</span></p>
                </div> */}

                  
            </div>
            
            <div style={{width:'100%',height:'100vw',display:'flex',justifyContent:'center',alignItems:'center'}}>
            <Empty
                 image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                 imageStyle={{
                    height: 160,
                    }}
                description={
                    <span>
                        No Notification Available
                    </span>
                }
                >
                 <Button type="primary">Go Back</Button>       

                </Empty>
                </div>    
        </div>
    )
}

export default Notification
