import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Select, DatePicker, Input, Upload, Button } from 'antd'
import makeAnimated from 'react-select/animated';
import {AiOutlineInfoCircle} from 'react-icons/ai';
import { SmallText } from '../../../helpers/constants/ConstantsText'
import axios from 'axios';
import { authHeader } from '../../../helpers/usertoken/UserToken';
import FmsDashboardMainRoot from '../FmsDashboardMainRoot';
import TextArea from 'antd/lib/input/TextArea'
import PrimaryButton, { SecondaryButton } from '../../../components/buttons/PrimaryButton';
import { useLocation, useNavigate } from 'react-router-dom';
import { Toaster, toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { CloseOutlined, UploadOutlined } from '@mui/icons-material';

function ClientInvoiceCE() {
  const animatedComponents = makeAnimated();

  const {state,pathname} = useLocation()
  const navigator = useNavigate()
  const token = useSelector(state=>state.Auth.access_token)


  const AuthHeader = {headers:{
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
  }}

 

  const path = pathname?.split('/')[pathname?.split('/')?.length - 1]
  const roles = useSelector(state=>state.Auth.roles)
  const [data,setdata] = useState({
    client:'',
    month_year:'',
    assigned_user_type:'',
    field_officer:'',
    deployed_officer:'',
    message:'',
    remarks:'',
    status:'',
    attachment:'',
    remark_attachment:'',

  })  
  const [clients,setclients] = useState([])  
  const [users,setusers] = useState([])


  async function searchuser(v){ 
    if(data?.assigned_user_type !== undefined){
        if(data?.assigned_user_type?.label === 'InHouse-Employee'){
            await axios.get(`fms/fms_in_house_employee/search?search=${v}`,AuthHeader)
            .then((res)=>{
                let arr = []
                res?.data?.datas?.forEach(d => {
                    arr.push({label:`${d?.name}[Employee Id : ${d?.employee_id}]`,value:d?._id})
                });
                setusers(arr)
                return arr
            }).catch((err)=>{
            })
        }else if(data?.assigned_user_type?.label === 'Deployed Employee'){{
            await axios.post(`fms/basicemployee/search?search_text=${v}`,{},AuthHeader)
            .then((res)=>{
                // console.log("res?.data",res?.data)
                let arr = []
                res?.data?.data?.forEach(d => {
                    arr.push({label:`${d?.name}[Employee Id : ${d?.employee_id}]`,value:d?.id})
                });
                setusers(arr)
                return arr
            }).catch((err)=>{
            })
        }
   
    }
   }
}

   async function searchData(v){ 
    await axios.get(`fms/fmsclients/search?search_text=${v}`,AuthHeader)
     .then((res)=>{
         let arr = []
         res?.data?.datas?.forEach(d => {
             arr.push({label:`${d?.site_name}[client name : ${d?.client_name}]`,value:d?._id})
         });
       
         setclients(arr)
         return arr
     }).catch((err)=>{
     })
   }


 
    const [options,setoptions] = useState([{label:'Phone',value:'Phone'},{label:'Email',value:'Email'},{label:'Whatsapp',value:'Whatsapp'},{label:'Application',value:'Application'},{label:'Other',value:'Other'}])

    const [error,seterror] = useState({
        report_date:'',
        resolve_date:'',
        client:'',
        field_officer:'',
        message:'',
        raised_by:'',
        escalted_by:'',
        remarks:'',
        resolution:'',
        status:''
    })  

    useEffect(()=>{
    
        if(path === 'edit'){

        if(options?.find((f)=>f?.label === state?.via) !== undefined){

            let send_Data = {
                ...data,
                ...state,
                via:options?.find((f)=>f?.label === state?.via),
                assigned_user_type:{label:state?.assigned_user_type,value:state?.assigned_user_type},
                raised_user_type:{label:state?.raised_user_type,value:state?.raised_user_type},
                status:status?.find((f)=>f?.label === state?.status),
                resolve_date:state?.resolve_date !== null ? state?.resolve_date : '',
                client:{label:`${state?.client?.site_name}[client name : ${state?.client?.client_name}]`,value:state?.client?._id}
            }


            if(state?.assigned_user_type !== ''){
                if(state?.assigned_user_type === 'InHouse-Employee'){
                    send_Data['field_officer'] = {label:`${state?.field_officer?.name}[Employee Id : ${state?.field_officer?.employee_id}]`,value:state?.field_officer?._id} 
                }else if(state?.assigned_user_type === 'Deployed Employee'){
                    send_Data['field_officer'] = {label:`${state?.deployed_officer?.name}[Employee Id : ${state?.deployed_officer?.employee_id}]`,value:state?.deployed_officer?._id} 
                }
            }

            console.log("state",state)

            setdata({...send_Data})
        }else{

            let send_Data = {
                ...data,
                ...state,
                via:options?.find((f)=>f?.label === state?.via),
                assigned_user_type:{label:state?.assigned_user_type,value:state?.assigned_user_type},
                raised_user_type:{label:state?.raised_user_type,value:state?.raised_user_type},
                status:status?.find((f)=>f?.label === state?.status),
                resolve_date:state?.resolve_date !== null ? state?.resolve_date : '',
                client:{label:`${state?.client?.site_name}[client name : ${state?.client?.client_name}]`,value:state?.client?._id}
            }


            if(state?.assigned_user_type !== ''){
                if(state?.assigned_user_type === 'InHouse-Employee'){
                    send_Data['field_officer'] = {label:`${state?.field_officer?.name}[Employee Id : ${state?.field_officer?.employee_id}]`,value:state?.field_officer?._id} 
                }else if(state?.assigned_user_type === 'Deployed Employee'){
                    send_Data['field_officer'] = {label:`${state?.deployed_officer?.name}[Employee Id : ${state?.deployed_officer?.employee_id}]`,value:state?.deployed_officer?._id} 
                }
            }

            send_Data['via'] = state?.via !== 'Other' ? state?.via : options?.find((f)=>f?.label === 'Other')
            send_Data['via1'] = state?.via1
            send_Data['resolve_date'] = state?.resolve_date !== null ? state?.resolve_date : ''

            setdata({...send_Data})
        } 
        
        }else{
        setdata({
            client:'',
            month_year:'',
            assigned_user_type:'',
            field_officer:'',
            deployed_officer:'',
            message:'',
            remarks:'',
            status:'',
            attachment:'',
            remark_attachment:'',
        })
        seterror({
            client:'',
            month_year:'',
            assigned_user_type:'',
            field_officer:'',
            deployed_officer:'',
            message:'',
            remarks:'',
            status:'',
            attachment:'',
            remark_attachment:'',
        })
        }
    },[])

   const status = [{label:'Pending',value:'Pending'},{label:'In Progress',value:'In Progress'},{label:'Completed',value:'Completed'},{label:'Rejected',value:'Rejected'}]
   const assigned_user_type = [{label:'InHouse-Employee',value:'InHouse-Employee'},{label:'Deployed Employee',value:'Deployed Employee'},{label:'Client',value:'Client'}]


   function handleChange1(v,name){   
        if(name === 'via'){
            setdata({...data,[name]:options?.find((v1)=>v1?.label === v)})
            seterror({...error,[name]:''})
        }else if(name === 'status'){
            setdata({...data,[name]:status?.find((v1)=>v1?.label === v)})
            seterror({...error,[name]:''})
        }else if(name === 'assigned_user_type'){
            setdata({...data,[name]:assigned_user_type?.find((v1)=>v1?.label === v)})
            seterror({...error,[name]:''})
        }
   }

  function submitData(){
    if(data.client === ''){
        seterror({...error,client:'This Field is required*'})
    }else if(data.month_year === ''){
        seterror({...error,month_year:'This Field is required*'})
    }else if(data.message === ''){
        seterror({...error,message:'This Field is required*'})
    }else if(data.attachment === ''){
        seterror({...error,attachment:'This Field is required*'})
    }else if(data.assigned_user_type === ''){
        seterror({...error,assigned_user_type:'This Field is required*'})
    }else if(data.assigned_user_type?.label === 'InHouse-Employee' && data?.field_officer === ''){
        seterror({...error,field_officer:'This Field is required*'})
    }else if(data.assigned_user_type?.label === 'Deployed Employee' && data?.deployed_officer === ''){
        seterror({...error,deployed_officer:'This Field is required*'})
    }else{
                
   
            if(path === 'create'){
               
                let send_Data = {...data}
                delete send_Data.field_officer
                send_Data['client'] = data?.client?.value
                if(data?.assigned_user_type?.value  === 'InHouse-Employee'){
                    send_Data['field_officer'] = data?.field_officer?.value
                    delete send_Data.deployed_officer
                }
                if(data?.assigned_user_type?.value  === 'Deployed Employee'){
                    send_Data['deployed_officer'] = data?.deployed_officer?.value
                    delete send_Data.field_officer
                }
                send_Data['assigned_user_type'] = data?.assigned_user_type?.label === undefined ? data?.assigned_user_type : data?.assigned_user_type?.label
                send_Data['status'] = 'Pending'



                console.log("sendData",send_Data)  
                axios.post('fms/client_invoice/create',send_Data,AuthHeader)
                .then((res)=>{
                    if(res.status === 201){
                        toast.success('Client Invoice Raised Successfully')
                        resetForm()
                    }
                }).catch((err)=>{
                })
            }else if(path === 'edit'){
                let send_Data = {...data}
                send_Data['client'] = data?.client?.value
                if(data?.assigned_user_type?.value  === 'InHouse-Employee'){
                    send_Data['field_officer'] = data?.field_officer?.value
                    delete send_Data.deployed_officer
                }
                if(data?.assigned_user_type?.value  === 'Deployed Employee'){
                    send_Data['deployed_officer'] = data?.field_officer?.value
                    delete send_Data.field_officer
                }
                send_Data['status'] = 'Pending'
                send_Data['via'] = data?.via?.label !== 'Other' ? data?.via?.label : data?.via1
                send_Data['raised_user_type'] = data?.raised_user_type?.label
                send_Data['assigned_user_type'] = data?.assigned_user_type?.label


                if((roles.includes('fms_crm_head') || roles.includes('admin'))){
                    send_Data['status'] = data?.status?.value === undefined ? data?.status : data?.status?.value
                }else{
                    send_Data['status'] = 'In Progress'
                }    
                // send_Data['field_officer'] = data?.field_officer?.value
                axios.put(`fms/client_invoice/update/${state?._id}`,send_Data,AuthHeader)
                .then((res)=>{
                    if(res.status === 200){
                        toast.success('Client Invoice Updated Successfully')
                        resetForm()
                        navigator(-1)
                    }
                }).catch((err)=>{
                })
            }
   
    }
  }

  function handleUpload(v,type){
   
    authHeader['headers']['Content-Type'] = 'multipart/form-data'
    var fd = new FormData()
    fd.append('file',v?.fileList[v?.fileList.length - 1]?.originFileObj)
    axios.post(`fms/basicemployee/upload_file`,fd,authHeader)
    .then((res)=>{
        setdata({...data,[type]:res?.data?.data})
        seterror({...error,[type]:''})
    }).catch((err)=>{
    })
  }

  function resetForm(){
     setdata({
        report_date:'',
        resolve_date:'',
        client:'',
        field_officer:'',
        message:'',
        raised_by:'',
        escalted_by:'',
        remarks:'',
        resolution:'',
        status:''
     })
     seterror({
        report_date:'',
        resolve_date:'',
        client:'',
        field_officer:'',
        message:'',
        raised_by:'',
        escalted_by:'',
        remarks:'',
        resolution:'',
        status:''
     })
  }

  function handleChange(v,type){
    if(type === 'client'){
       setdata({...data,client:clients?.find((c)=>c?.value === v)})
       seterror({...error,client:''})
    }else if(type === 'field_officer'){
        setdata({...data,field_officer:users?.find((c)=>c?.value === v)})
       seterror({...error,field_officer:''})
    }else if(type === 'deployed_officer'){
        setdata({...data,deployed_officer:users?.find((c)=>c?.value === v)})
       seterror({...error,deployed_officer:''})
    }
  }


  return (
    <FmsDashboardMainRoot>
        <Toaster />
        <div >
            <h6>Create/Edit Client Invoice Form</h6>
            <h6 style={{fontSize:'10px',background:'#3d7aba',fontWeight:'600',color:'#fff',padding:6,width:'300px'}}>Use the below form to create or update the ticket raised by the clients.</h6>

                <div style={{display:'flex',width:'800px',marginTop:'20px'}}>
                <div style={{width:'300px'}}>
                <SmallText fontWeight={'600'} val={'Client Name'}  />
                <Select disabled={['admin','fms_hr','fms_finance','fms_crm_head'].filter(f=>roles?.includes((f)))?.length < 0 } showSearch filterOption={false} value={data?.client} style={{borderLeft:'4px solid #292961',width:'100%'}}  options={clients} onSearch={searchData} onChange={(v)=>handleChange(v,'client')}  />

                <div style={{display:'flex',marginTop:'4px'}}>
                    {(error?.client) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                    <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.client}</p>
                </div>

                <div  style={{margin:'0px 0px'}}>
                <SmallText marginfun={'0px 0px 10px 0px'} fontWeight={'600'} val={'Month'}  />
                 <Input  disabled={['admin','fms_hr','fms_finance','fms_crm_head'].filter(f=>roles?.includes((f)))?.length < 0 } style={{borderLeft:'4px solid #292961',}}  type='month'value={data?.month_year} onChange={(e)=>{setdata({...data,month_year:e.target.value});seterror({...error,month_year:''})}} />  
                 <div  style={{display:'flex',marginTop:'4px'}}>
                    {(error?.month_year) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                    <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.month_year}</p>
                </div>
                </div> 

             

        
                <div  style={{margin:'0px 0px'}}>
                <SmallText marginfun={'0px 0px 10px 0px'} fontWeight={'600'} val={'Message'}  />
                 <TextArea disabled={['admin','fms_hr','fms_finance','fms_crm_head'].filter(f=>roles?.includes((f)))?.length < 0 } style={{borderLeft:'4px solid #292961',}} value={data?.message} onChange={(e)=>{setdata({...data,message:e.target.value});seterror({...error,message:''})}} />  
                 <div  style={{display:'flex',marginTop:'4px'}}>
                    {(error?.message) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                    <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.message}</p>
                </div>
                </div> 

                <div style={{minWidth:'100% !important'}}>
                <SmallText marginfun={'0px 0px 10px 0px'} fontWeight={'600'} val={'Attachment'}  />

                {(data.attachment === null || data.attachment === '' || data.attachment == undefined) ?
                    <Upload  disabled={['admin','fms_hr','fms_finance','fms_crm_head'].filter(f=>roles?.includes((f)))?.length < 0 } showUploadList={false} onChange={(v)=>handleUpload(v,'attachment')} style={{width:'200px',borderLeft:'4px solid #292961'}}>
                        <Button style={{width:'300px',display:'flex',alignSelf:'flex-end',justifyContent:'end',borderLeft:'4px solid #292961'}} icon={<UploadOutlined   style={{borderLeft:'1px solid #eee',color:'#999',paddingLeft:'0px'}}/>}></Button>
                    </Upload>
                    :
                    <Button disabled={['admin','fms_hr','fms_finance','fms_crm_head'].filter(f=>roles?.includes((f)))?.length < 0 } onClick={()=>{setdata({...data,attachment:null});seterror({...error,attachment:null})}}  style={{width:'300px',display:'flex',alignItems:'center',fontSize:'10px',marginBottom:'0px',borderLeft:'4px solid #292961'}} ><p style={{marginBottom:'0px', wordBreak: 'break-word',whiteSpace:'nowrap',width:'90%',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px',textAlign:'left'}}>{data.attachment?.name != undefined ? data.attachment?.name : data?.attachment?.split('/')[data?.attachment?.split('/').length-1] }</p><CloseOutlined size={15}  style={{borderLeft:'1px solid #eee',color:'#999',paddingLeft:'0px',position:'absolute',right:'0px'}}/></Button>
                    }
                </div>

                <div  style={{display:'flex',marginTop:'4px'}}>
                    {(error?.attachment) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                    <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.attachment}</p>
                </div>

                <div style={{margin:'10px 0px'}}>
                    <SmallText marginfun={'0px 0px 10px 0px'} fontWeight={'600'} val={'ASSIGNED USER TYPE'}  />
                    <div  style={{margin:'0px 0px 10px 0px',zIndex:200,borderLeft:'4px solid #292961'}}>
                    <Select 
                        disabled={['admin','fms_hr','fms_finance','fms_crm_head'].filter(f=>roles?.includes((f)))?.length < 0 }
                        cacheOptions
                        value={data?.assigned_user_type?.label}
                        placeholder="" 
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                        onChange={(v)=>handleChange1(v,'assigned_user_type')}
                        options={assigned_user_type}
                        style={{ width: '100%', }}
                    />
                    </div>
                </div>

                <div  style={{display:'flex',marginTop:'4px'}}>
                    {(error?.assigned_user_type) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                    <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.assigned_user_type}</p>
                </div>

                 {data?.assigned_user_type?.label === 'InHouse-Employee' && <>
                <SmallText marginfun={'10px 0px 10px 0px'} fontWeight={'600'} val={'Field Officer'}  />
                <Select  disabled={['admin','fms_hr','fms_finance','fms_crm_head'].filter(f=>roles?.includes((f)))?.length < 0 } showSearch filterOption={false} value={data?.field_officer} style={{borderLeft:'4px solid #292961',width:'100%'}}  options={users} onSearch={searchuser} onChange={(v)=>handleChange(v,'field_officer')}  />
               
                <div  style={{display:'flex',marginTop:'4px'}}>
                    {(error?.field_officer) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                    <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.field_officer}</p>
                </div>
                </>}

           


                {data?.assigned_user_type?.label === 'Deployed Employee' && <>
                <SmallText marginfun={'10px 0px 10px 0px'} fontWeight={'600'} val={'Deployed Officer'}  />
                <Select disabled={['admin','fms_hr','fms_finance','fms_crm_head'].filter(f=>roles?.includes((f)))?.length < 0 } showSearch filterOption={false} value={data?.deployed_officer} style={{borderLeft:'4px solid #292961',width:'100%'}}  options={users} onSearch={searchuser} onChange={(v)=>handleChange(v,'deployed_officer')}  />
                <div  style={{display:'flex',marginTop:'4px'}}>
                    {(error?.deployed_officer) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                    <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.deployed_officer}</p>
                </div>
                </>}

                

               {path === 'edit' &&
                <>

                <SmallText fontWeight={'600'} val={'Status'}  />


                <div  style={{margin:'0px 0px 10px 0px',zIndex:200,borderLeft:'4px solid #292961'}}>
                <Select 
                cacheOptions
                    value={data?.status?.label}
                    placeholder="" 
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    onChange={(v)=>handleChange1(v,'status')}
                    options={status}
                    style={{ width: '100%', }}
                />

                </div>
                <div style={{display:'flex',marginTop:'4px'}}>
                    {(error?.status) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.status}</p>
                </div>


                <div style={{minWidth:'100% !important'}}>
                <SmallText marginfun={'0px 0px 10px 0px'} fontWeight={'600'} val={'Remark Attachment'}  />

                {(data.remark_attachment === null || data.remark_attachment === '' || data.remark_attachment == undefined) ?
                    <Upload  showUploadList={false} onChange={(v)=>handleUpload(v,'remark_attachment')} style={{width:'200px'}}>
                        <Button style={{width:'300px',display:'flex',alignSelf:'flex-end',justifyContent:'end'}} icon={<UploadOutlined   style={{borderLeft:'1px solid #eee',color:'#999',paddingLeft:'0px'}}/>}></Button>
                    </Upload>
                    :
                    <Button onClick={()=>{setdata({...data,remark_attachment:null});seterror({...error,remark_attachment:null})}}  style={{width:'300px',display:'flex',alignItems:'center',fontSize:'10px',marginBottom:'0px'}} ><p style={{marginBottom:'0px', wordBreak: 'break-word',whiteSpace:'nowrap',width:'90%',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px',textAlign:'left'}}>{data.remark_attachment?.name != undefined ? data.remark_attachment?.name : data?.remark_attachment?.split('/')[data?.remark_attachment?.split('/').length-1] }</p><CloseOutlined size={15}  style={{borderLeft:'1px solid #eee',color:'#999',paddingLeft:'0px',position:'absolute',right:'0px'}}/></Button>
                    }
                </div>

                <div  style={{display:'flex',marginTop:'4px'}}>
                    {(error?.remark_attachment) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                    <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.remark_attachment}</p>
                </div>


                <div  style={{margin:'0px 0px'}}>
                <SmallText marginfun={'0px 0px 10px 0px'} fontWeight={'600'} val={'Remarks'}  />
                 <TextArea style={{borderLeft:'4px solid #292961',}} value={data?.remarks} onChange={(e)=>{setdata({...data,remarks:e.target.value});seterror({...error,remarks:''})}} />  
                 <div  style={{display:'flex',marginTop:'4px'}}>
                    {(error?.remarks) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                    <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.remarks}</p>
                </div>
                </div> 

                

              
                </>}

                

               
                </div> 

              
                </div>

                {!roles?.includes('fms_data_view') && (roles?.includes('admin') || roles?.includes('fms_hr') || roles?.includes('fms_deployed_employee') || roles?.includes('fms_crm_head') || roles?.includes('fms_finance') || roles?.includes('fms_user')) &&
                <div style={{display:'flex',flexDirection:'row'}}>
                 <PrimaryButton btn_name={'Save'} onClick={submitData}/>
                 <h6 style={{width:'5px'}}></h6>
                 <SecondaryButton btn_name={'Cancel'} onClick={()=>navigator(-1)} />
                </div>}



        </div>
    </FmsDashboardMainRoot>
  )
}

export default ClientInvoiceCE