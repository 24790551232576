import React, { useEffect, useState } from 'react'
import FmsDashboardMainRoot from '../FmsDashboardMainRoot'
import { BoldText1 } from '../../../helpers/constants/ConstantsText'
import axios from 'axios'
import { authHeader } from '../../../helpers/usertoken/UserToken'
import { useSelector } from 'react-redux'
import {Row,Col} from 'antd'
import moment from 'moment'
import {AiOutlineCalendar} from 'react-icons/ai';
import { useNavigate } from 'react-router-dom'

function HandlingClient() {
   
  const [data,setdata] = useState([])  

  const roles = useSelector(state=>state.Auth.roles)
  const navigation = useNavigate()


  useEffect(()=>{
    gethandlingClient()
  },[])
 
  async function gethandlingClient(){
    await axios.get(`fms/fmsclients/assigned_clients`,authHeader)
    .then((res)=>{
       setdata(res?.data?.data)
    }).catch((err)=>{
    })
  }  

  return (
    <FmsDashboardMainRoot>
        <div style={{width:'97%'}}>
            <div style={{borderBottom:'1px solid #eee'}}>
            <BoldText1 val={`Clients Handling (${data?.length})`} />
            </div> 

            <Row style={{marginTop:'10px'}}>
                {data?.map((d)=>(
                    d?.client?.map((d1)=>(
                      <Col span={4} style={{border:'1px solid #eee',padding:'4px',position:'relative',marginRight:'10px'}}>
                        <div>
                            <AiOutlineCalendar onClick={()=>navigation('/fms/employee_based_on_clients',{state:d1?._id})}  fontSize={11} style={{position:'absolute',right:2,top:2,background:'white',cursor:'pointer'}} />
                            <h6 style={{fontSize:'11px'}}>Client Info : <span style={{fontWeight:'600'}}>{d1?.client_name}</span></h6>
                            <h6 style={{fontSize:'11px',marginBottom:'0px'}}>Site Name : {d1?.site_name}</h6>
                            <h6 style={{fontSize:'11px',marginBottom:'0px'}}>Address : {d1?.site_address}</h6>
                            <h6 style={{fontSize:'11px',marginBottom:'0px',background:'#f3f3f3',padding:'2px',marginTop:'5px'}}>Assigned on : <span style={{fontWeight:'700'}}>{moment(d?.createdAt)?.format('LL')}</span> </h6>
                        </div>
                      </Col>
                    ))
                ))}

            </Row>


        </div>
    </FmsDashboardMainRoot>
  )
}

export default HandlingClient