import React,{useState,useEffect} from 'react';

import TextInput from '../../components/ItComponents/TextInput';
import {  Itheadings, ItSmallText, SmallText } from '../../helpers/constants/ConstantsText';
import ITMain from './ITMain';
import {  gray_dark_1 } from '../../helpers/constants/ConstantColor';
import PrimaryButton, { PrimaryButtonAdd } from '../../components/buttons/PrimaryButton';


import {IoClose} from 'react-icons/io5'
import {theme_color } from '../../helpers/constants/ConstantColor';

import { Drawer} from 'antd';
import IconButton from '@mui/material/IconButton';
import Tooltip from "@material-ui/core/Tooltip";
import toast, { Toaster } from 'react-hot-toast'
import { Select } from 'antd';
import Dragger from "antd/lib/upload/Dragger";
import { base_url } from '../../App';


import {AiOutlineInfoCircle} from 'react-icons/ai';
import {BiEditAlt} from 'react-icons/bi';

import axios from 'axios';
import { MdOutlineDelete } from 'react-icons/md';
import './Maincss.css';
import { authHeader } from '../../helpers/usertoken/UserToken';
import { image_not_Added_for_Asset } from '../../components/constants/Constants1';


const { Option } = Select;

function Mouse(){

   


    const [modal,setmodal] = useState(false)
    const [mouse,setmouse] = useState({id:null,mouse_id:'',model_id:'',device_stage:'',brand:'',type:'',image:null})
    const [error,seterror] = useState({mouse_id:'',model_id:'',device_stage:'',brand:'',type:'',image:null})
    const [pagination,setpagination] = useState({})
    const [mouse_arr,setmouse_arr] = useState([])
    const [wireschema_arr,setwireschema_arr] = useState([])
    const [brand_arr,setbrand_arr] = useState([])
    const [devicestage_arr,setdevicestage_arr] = useState([])
    const [purchasetype_arr,setpurchasetype_arr] = useState([])


    useEffect(()=>{
        setmouse({id:null,mouse_id:'',model_id:'',device_stage:'',brand:'',type:'',image:null})
        getdata()
        getwireschema()
        getbrands()
        getstages()
        getpurchasetypeschema()
    },[])  


    function getdata(){
        setmouse({id:null,mouse_id:'',model_id:'',device_stage:'',brand:'',type:'',image:null})
        axios.get('it/mouse/get')
        .then((res)=>{
            setpagination(res.data.pagination)
            setmouse_arr(res.data.datas)
        }).catch((err)=>{
            // console.log('err',err)
        })
    }

    function getwireschema(){
        axios.get('it/wireschema/get')
        .then((res)=>{
            console.log('res.data.data',res.data.data)

            let arr = []
            res.data.data.forEach((d)=>{
                let obj = {
                    id : d._id,
                    type : d.type
                }
                arr.push(obj)
            })
            setwireschema_arr(arr)
        }).catch((err)=>{
            // console.log('err',err)
        })
    }

    function getbrands(){
        axios.get('it/brand/get')
        .then((res)=>{
            let arr = []
            res.data.data.forEach((d)=>{
                let obj = {
                    id : d._id,
                    brand_name:d.brand_name
                }
                arr.push(obj)
            })
            setbrand_arr(arr)
        }).catch((err)=>{
            // console.log('err',err)
        })
    }

    function getstages(){
        axios.get('it/devicestage/get')
        .then((res)=>{
            let arr = []
            res.data.data.forEach((d)=>{
                let obj = {
                    id : d._id,
                    stage:d.stage
                }
                arr.push(obj)
            })
            setdevicestage_arr(arr)
        }).catch((err)=>{
            // console.log('err',err)
        })
    }
    
    
    function add_mouse(){
        if(!mouse.mouse_id){
            seterror({...error,mouse_id:'The Mouse Id Field is required'})
        }else if(!mouse.model_id){
            seterror({...error,model_id:'The Model Id Field is required'})
        }else if(!mouse.purchase_type?.id){
            seterror({...error,purchase_type:'The Purchase Type Field is required'})
        }else if(!mouse.device_stage?.id){
            seterror({...error,device_stage:'The Device Stage Field is required'})
        }else if(!mouse.brand?.id){
            seterror({...error,brand:'The Brand Field is required'})
        }else if(!mouse.type?.id){
            seterror({...error,type:'The Type Field is required'})
        }else{
            const fd = new FormData()
            fd.append('mouse_id',mouse.mouse_id)
            fd.append('model_id',mouse.model_id)
            fd.append('brand',mouse.brand?.id)
            fd.append('purchase_type',mouse.purchase_type?.id)
            fd.append('device_stage',mouse.device_stage?.id)
            fd.append('type',mouse.type?.id)
            mouse?.image?.originFileObj !== undefined && fd.append('mouse_image',mouse?.image?.originFileObj)

            authHeader['headers']['Content-Type'] = 'multipart/form-data'

            axios.post('it/mouse/create',fd,authHeader)
            .then((res)=>{
                toast.success(res.data.message)
                setmodal(false)
                getdata()
            }).catch((err)=>{
                if(err?.response?.data?.err?.keyPattern?.mouse_id){
                    seterror({...error,mouse_id:'Mouse Id should be unique'})
                }else if(err?.response?.data?.err?.keyPattern?.model_id){
                    seterror({...error,model_id:'Model Id should be unique'})
                }
            })
        }
    }

    function update_mouse(){
        if(!mouse.mouse_id){
            seterror({...error,mouse_id:'The Mouse Id Field is required'})
        }else if(!mouse.model_id){
            seterror({...error,model_id:'The Model Id Field is required'})
        }else if(!mouse.purchase_type?.id){
            seterror({...error,purchase_type:'The Purchase Type Field is required'})
        }else if(!mouse.device_stage?.id){
            seterror({...error,device_stage:'The Device Stage Field is required'})
        }else if(!mouse.brand?.id){
            seterror({...error,brand:'The Brand Field is required'})
        }else if(!mouse.type?.id){
            seterror({...error,type:'The Type Field is required'})
        }else{
            const fd = new FormData()
            fd.append('mouse_id',mouse.mouse_id)
            fd.append('model_id',mouse.model_id)
            fd.append('brand',mouse.brand?.id)
            fd.append('purchase_type',mouse.purchase_type?.id)
            fd.append('device_stage',mouse.device_stage?.id)
            fd.append('type',mouse.type?.id)
            mouse?.image?.originFileObj !== undefined && fd.append('mouse_image',mouse?.image?.originFileObj)
            fd.append('method','_PUT')
            axios.post(`it/mouse/update/${mouse.id}`,fd)
            .then((res)=>{
                toast.success(res.data.message)
                setmodal(false)
                getdata()
            }).catch((err)=>{
                if(err?.response?.data?.err?.keyPattern?.mouse_id){
                    seterror({...error,mouse_id:'Mouse Id should be unique'})
                }else if(err?.response?.data?.err?.keyPattern?.model_id){
                    seterror({...error,model_id:'Model Id should be unique'})
                }
            })
        }
    }

    function delete_mouse(v){
        axios.delete(`it/mouse/delete/${v._id}`)
        .then((res)=>{
            toast.success(res.data.message)
            getdata()
        }).catch((err)=>{
        })
    }

    const handleUpload = (v) => {
        setmouse({...mouse,image:v.fileList[0]})
    }


    function setdataforedit(p){
        console.log("p",p)
        setmouse({
            id:p._id,
            model_id:p.model_id,
            mouse_id:p.mouse_id,
            device_stage:{stage:p?.device_stage?.stage,id:p?.device_stage?._id},
            brand:{brand_name:p?.brand?.brand_name,id:p?.brand?._id},
            type:{type:p?.type?.type,id:p?.type?._id},
            purchase_type:{type:p?.purchase_type?.type,id:p?.purchase_type?._id},
            type_id:p.type._id,
            image:p.image})
        setmodal(true)  
    }


    console.log("mouse",mouse)

    function getpurchasetypeschema(){
        axios.get('it/purchasetype/get')
        .then((res)=>{
            let arr = []
            res.data.data.forEach((d)=>{
                let obj = {
                    id : d._id,
                    type : d.type
                }
                arr.push(obj)
            })
            setpurchasetype_arr(arr)
        }).catch((err)=>{
        })
    }


    return(
        <ITMain>
            <div style={{width:'98%'}}>
                <Toaster />

                <Drawer visible={modal} closable={false}>
                {/* <Modal visible={modal} footer={false} closable={false} width='260px'> */}
                    <div style={{width:'220px',position:'relative',height: (mouse.image === undefined || mouse.image === null || mouse.image === "") ? '380px' : '320px'}}>

                    <p style={{fontSize:'10px',fontWeight:'500',background:'#c9ffe2',padding:'4px',marginTop:'0px'}}>Use the below form to create or update the mouse for your employees</p>


                    <Tooltip title="Close" >
                            <IconButton style={{padding:'2px',marginTop:'-5px',position:'absolute',right:-10,top:-10}}>
                            <IoClose  onClick={()=>setmodal(!modal)} style={{ cursor: 'pointer',color:gray_dark_1, }} size={16} />
                            </IconButton>
                        </Tooltip>

                        <div style={{marginLeft:'-5px'}}>
                        <ItSmallText val={'Mouse Id'} fontSize={12.5} marginfun={'5px 0px 5px 0px'}/>
                        <TextInput val={mouse?.mouse_id} onchange={(e)=>{setmouse({...mouse,mouse_id:e.target.value});seterror({...error,mouse_id:''})}} />
                        <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                            {(error?.mouse_id) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                            <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.mouse_id}</p>
                        </div>

                        <ItSmallText val={'Model Id'} fontSize={12.5} marginfun={'5px 0px 5px 0px'}/>
                        <TextInput val={mouse.model_id} onchange={(e)=>{setmouse({...mouse,model_id:e.target.value});seterror({...error,model_id:''})}} />
                        <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                            {(error?.model_id) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                            <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.model_id}</p>
                        </div>

                        <div style={{marginLeft:'0px'}}>
                        <ItSmallText val={'Purchase Type'} fontSize={12.5} marginfun={'5px 0px 5px 0px'}/>
                        <Select value={mouse?.purchase_type?.type} onChange={(v)=>{setmouse({...mouse,purchase_type:purchasetype_arr?.find((b)=>b?.id === v)});seterror({...error,purchase_type:''})}} style={{width:'100%',borderLeft:`3px solid ${theme_color}`,borderRadius:'4px'}} >
                                {purchasetype_arr?.map((r)=>(
                                    <Option key={r?.id} value={r?.id} style={{textTransform:'capitalize'}}>{r?.type}</Option>
                                ))}
                        </Select>
                        <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                            {(error?.purchase_type) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                            <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.purchase_type  }</p>
                        </div>
                        </div>  


                        <ItSmallText val={'Device Stage'} fontSize={12.5} marginfun={'5px 0px 5px 0px'} />
                        <Select value={mouse.device_stage?.stage} onChange={(v)=>{setmouse({...mouse,device_stage:devicestage_arr.find((b)=>b?.id == v)});seterror({...error,device_stage:''})}} style={{width:'100%',borderLeft:`3px solid ${theme_color}`,borderRadius:'4px'}} >
                                {devicestage_arr.map((r)=>(
                                    <Option key={r} value={r?.id} style={{textTransform:'capitalize'}}>{r?.stage}</Option>
                                ))}
                        </Select>
                        <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                            {(error?.device_stage?.stage) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                            <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.device_stage  }</p>
                        </div>

                        <ItSmallText val={'Brand'} fontSize={12.5} marginfun={'5px 0px 5px 0px'} />
                        <Select value={mouse.brand?.brand_name} onChange={(v)=>{setmouse({...mouse,brand:brand_arr?.find((b)=>b?.id == v)});seterror({...error,brand:''})}} style={{width:'100%',borderLeft:`3px solid ${theme_color}`,borderRadius:'4px'}} >
                                {brand_arr.map((r)=>(
                                    <Option key={r} value={r?.id}>{r?.brand_name}</Option>
                                ))}
                        </Select>
                        <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                            {(error?.brand) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                            <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.brand  }</p>
                        </div>

                       
                        <ItSmallText val={'Type '} marginfun={'5px 0px 5px 0px'} />
                            <Select value={mouse.type?.type} onChange={(v)=>{setmouse({...mouse,type:wireschema_arr.find((b)=>b?.id == v)});seterror({...error,type:''})}} style={{width:'100%',borderLeft:`3px solid ${theme_color}`,borderRadius:'4px'}} >
                                {wireschema_arr.map((r)=>(
                                    <Option key={r} value={r?.id}>{r?.type}</Option>
                                ))}
                        </Select>
                        <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                            {(error?.type) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                            <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.type  }</p>
                        </div>
                        <ItSmallText val={'Image'} fontSize={12.5} marginfun={'5px 0px 5px 0px'} />
                        {(mouse.image === undefined || mouse.image === null || mouse.image === "")   ?
                        <Dragger style={{ marginTop: '10px',height:'100px',minHeight:'100px',maxHeight:'80px'}}
                            onChange={handleUpload}
                            beforeUpload={() => false}
                            showUploadList={false}>
                            
                            <div style={{display:'flex',height:'100px',alignItems:'center',flexDirection:'column'}}>
                            <img src={'https://cdn-icons-png.flaticon.com/512/2504/2504768.png'} style={{ width: '30px', objectFit: 'contain',alignItems:'center',display:'flex',justifyItems:'center',justifySelf:'center',alignSelf:'center' }} />
                            <p className="ant-upload-text" style={{fontWeight:'600',fontSize:'8px',marginTop:'10px'}}>Click or drag file to this area to upload</p>
                            <p className="ant-upload-hint" style={{fontSize:'6px'}}>
                                Support for a single file upload. Strictly used from uploading brand image
                            </p>
                            </div>
                        </Dragger>
                        :
                        <div>
                            {mouse?.image?.name?.length !== undefined && mouse?.image?.name?.length !== null ?
                            <div style={{ display: 'flex', alignItems: 'center', border: '1px solid lightgray', padding: '2px 10px', position: 'relative', marginTop: '10px' }}>
                                <img src={'https://cdn-icons-png.flaticon.com/512/2504/2504768.png'} style={{ width: '20px', objectFit: 'contain' }} />
                                <div style={{ marginLeft: '10px',marginTop:'5px' }}>
                                    <h6 style={{ fontSize: '8px', fontWeight: '600', marginBottom: '2px' }}>{mouse?.image?.name?.slice(0,25)}</h6>
                                    <h6 style={{ fontSize: '6px', color: 'gray' }}>{mouse?.image?.size} bytes</h6>
                                </div>
                                <MdOutlineDelete size={15} style={{ position: 'absolute', right: '10px' }} onClick={()=>setmouse({...mouse,image:null})} />
                            </div>
                            :
                            <div style={{position:'relative',}}>
                                <IoClose  onClick={()=>setmouse({...mouse,image:null})} style={{ cursor: 'pointer',color:gray_dark_1,position:'absolute',right:0 }} size={16} />
                                <img src={`${base_url}${mouse?.image}`} style={{ width: '40px',height:'40px', objectFit: 'contain' }} />
                            </div>  }  
                        </div>}
                        </div>
                        <div style={{marginLeft:'-5px'}}>
                        <PrimaryButton btn_name={mouse.id === null ? 'ADD MOUSE' : "UPDATE MOUSE"} onClick={mouse.id === null ? add_mouse : update_mouse}  />
                        </div>

                    </div>
                {/* </Modal> */}
                </Drawer>

                <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',marginTop:'-10px',borderBottom:`1px solid #f2f2f2`,maxHeight:'50px'}}>
                <Itheadings val={'Mouse'}  />
                
                <div style={{display:'flex',alignItems:'center',flexDirection:'row'}}>
                   {/* <Select value={mouse.device_stage =='' ?null : mouse.device_stage} onChange={(v)=>{setmouse({...mouse,device_stage_id:v,device_stage:devicestage_arr.find((b)=>b?.id == v)?.stage});seterror({...error,device_stage:''})}} style={{width:'40%',borderLeft:`3px solid ${theme_color}`,borderRadius:'2px',marginRight:'10px'}} placeholder="current stage" >
                            {devicestage_arr.map((r)=>(
                                <Option key={r} value={r?.id} style={{textTransform:'capitalize'}}>{r?.stage}</Option>
                            ))}
                    </Select> */}
                    <div onClick={()=>{setmodal(true);setmouse({id:null,model_id:'',device_stage:'',brand:'',type:'',image:''})}} > 
                    <PrimaryButtonAdd btn_name={'Add Mouse'}  />
                    </div>
                </div>
                 
                </div>

                <SmallText fontWeight={'600'} fontSize={13} val={`Total Found (${pagination?.total})`} marginfun={'10px 0px 10px 0px'} />
            
                        <div className='row'>
                        {mouse_arr?.map((p)=>(
                        <div className='col-md-3'>
                        <div className='card_processor '  key={p._id} style={{display:'flex',margin:'5px 0px',cursor:'pointer',paddingLeft:'10px',width:'100%',borderRadius:'2px',border:'0.5px solid #ddd',padding:'10px',display:'flex',position:'relative'}}>
                            <span style={{position:'absolute',right:0,top:0,fontSize:'8px',padding:'2px 10px',textAlign:'center',fontWeight:'800',background:(p.device_stage.stage !== 'damaged' &&  p.device_stage.stage !== 'under-service') ? '#72dba1' : '#c4655c',borderBottomLeftRadius:'21px',textTransform:'uppercase'}}>{p.device_stage.stage}</span>
                            {/* <img src={`${base_url}${p.image}`} style={{minWidth:'80px',maxWidth:'80px',height:'60px',objectFit:'contain'}} /> */}
                            {(p?.image !== null && p?.image !== undefined) ? 
                                <img src={`${base_url}${p.image}`} style={{minWidth:'80px',maxWidth:'80px',height:'60px',objectFit:'contain'}} />
                                :
                                <img src={`${image_not_Added_for_Asset}`} style={{minWidth:'80px',maxWidth:'80px',height:'60px',objectFit:'contain'}} />}
                            <div style={{paddingTop:'1px',marginLeft:'3%'}}>
                            <SmallText fontWeight={'600'} fontSize={13} val={`${p?.brand?.brand_name} MOUSE`} marginfun={'0px 0px 0px 0px'} />
                            <SmallText fontWeight={'500'} fontSize={10} val={`Model Id : ${p?.model_id}`} marginfun={'0px 0px 0px 0px'} />
                            <SmallText fontWeight={'500'} fontSize={10} val={`Type  : ${p?.type?.type}`} marginfun={'0px 0px 0px 0px'} />
                            <div style={{display:'flex',marginLeft:'-10px',marginTop:'5px'}} >
                            <Tooltip title="Delete" >
                                <IconButton style={{marginTop:'-5px'}} onClick={()=>delete_mouse(p)}>
                            <MdOutlineDelete  size={16} style={{color:'#000'}} />
                            </IconButton>
                            </Tooltip>
                            <Tooltip title="Edit" >
                                <IconButton style={{marginTop:'-5px',}} onClick={()=>setdataforedit(p)}>
                            <BiEditAlt  size={16} style={{color:'#000'}} />
                            </IconButton>
                            </Tooltip>
                            </div>
                            </div>
                        </div>
                        </div>
                        ))}
                        </div>
            

            </div>
        </ITMain>
    )
}


export default Mouse