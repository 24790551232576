import React, { useEffect, useState } from 'react'
import { Modal, Upload, Menu, Dropdown, Input, Checkbox, Select, Tabs, Row, Col } from 'antd'

import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';

import { authHeader } from '../../../helpers/usertoken/UserToken';
import { bd_active_tab } from '../../../redux/actionPayload/BdPayload'

import ReactMaterialTable from '../../../components/table_design/ReactMaterialTable';
import PrimaryButton, { PrimaryButtonAdd, PrimaryButtonFilter } from '../../../components/buttons/PrimaryButton'


import { GrFormAdd } from 'react-icons/gr'
import { HiOutlineFilter } from 'react-icons/hi'
import { IoClose } from 'react-icons/io5'
import { MdOutlineDeleteOutline } from 'react-icons/md'

import DateField from '../../../components/inputcomponents/DateField';
import axios from "axios";
import readXlsxFile from 'read-excel-file'
import Loader from '../../../components/Loaders/Loader';
import { data_not_found } from '../../../helpers/Constants';
import toast,{ Toaster } from 'react-hot-toast';
import { IoIosClose } from 'react-icons/io';
import Tooltip from '@mui/material/Tooltip';

const { TabPane } = Tabs;

function ViewContact() {


    const fcpl_id = useSelector(state => state.Auth.fcpl_id)


    const [loader, setloader] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [datepickervisible, setdatepickervisible] = useState(false);
    const [shareContactVisible, setshareContactVisible] = useState(false);

    const [datas, setDatas] = useState([])
    const [pagination, setpagination] = useState({})
    const [page, setpage] = useState(1)
    const [file, setfile] = useState()
    const [search_contact, setsearch_contact] = useState({ search_text: '', contact_status: '', from_date: '', to_date: '' })
    const [tempsearch_contact, settempsearch_contact] = useState({ search_text: '', contact_status: '', from_date: '', to_date: '' })
    const [reminder, setReminders] = useState([])

    const [departement_arr, setdepartement_arr] = useState([])
    const [employees_arr, setemployees_arr] = useState([])
    const [current_tab, setcurrent_tab] = useState('1')
    const [current_tab1, setcurrent_tab1] = useState('1')

    const [xl_valid, setxl_valid] = useState(false)



    const [sharecontact_data, setsharecontact_data] = useState({ department_id: '', employee_id: '', sender_id: '' })



    const [selected_contact, setselected_contact] = useState('')
    const [selected_sharedcontact, setselected_sharedcontact] = useState('')
    const [selected_sharedcontacttable, setselected_sharedcontacttable] = useState('')


    function setselected_contact_func(v) {
        setselected_contact(v)
    }


    const contact_status_filter = [
        {
            id: "Active",
            value: "Active"
        },
        {
            id: "In Active",
            value: "In Active"
        },
        {
            id: "Connected",
            value: "Connected"
        },
        {
            id: "Not Connected",
            value: "Not Connected"
        },
        {
            id: "Old Client",
            value: "Old Client"
        }
    ]
   
    let keys_arr = ['Company', 'Contact Name', 'Phone', 'Email', 'Designation', 'Contact Source', 'Contact Status', 'Contact Owner']
    const label_arr = ['company_name', 'contact_name', 'phone', 'email', 'designation', 'contact_source', 'status', 'contact_owner',]

    const navigate = useNavigate();
    const dispatch = useDispatch()

    const { Dragger } = Upload;
    const { Option } = Select;

    useEffect(() => {
        getAllContact()
        getDepartements()
    }, [])

    function handleChangefun(v, v1) {
        if (v1 === 'department_id') {
            getusersbydepartmentId(v)
            setsharecontact_data({ ...sharecontact_data, [v1]: v, employee_id: '' })
        } else {
            setsharecontact_data({ ...sharecontact_data, [v1]: v })
        }
    }
    function getusersbydepartmentId(v) {
        axios.get(`user/get-users-by-departmentId/${v}`, authHeader)
            .then((res) => {
                let temp_Arr = []
                res.data.datas.filter(e => (e.employee_id !== fcpl_id && e.isActive === true)).map((d) => {
                    let obj = {}
                    obj['label'] = d.name
                    obj['value'] = d.name
                    obj['id'] = d._id
                    let des = ''
                    d.user_roles.forEach((u) => {
                        des = des + u.roles[0].display_name + ','
                    })
                    obj['label'] = obj['label'] + ` [ ${d.employee_id} ]`
                    obj['value'] = obj['value'] + ` [${d.employee_id}]`
                    temp_Arr.push(obj)
                })
                setemployees_arr(temp_Arr)
            }).catch((err) => {
            })
    }
    function getDepartements() {
        axios.get('department/get', authHeader)
            .then((res) => {
                const departement_arr_created = []
                res.data.datas.filter(d => d?.department_name !== 'Database Team' && d?.department_name !== 'Media Team' && d?.department_name !== 'MD Office').forEach(d => {
                    let data = {}
                    data['label'] = d.department_name
                    data['value'] = d.department_name
                    data['id'] = d.id
                    departement_arr_created.push(data)
                })
                setdepartement_arr(departement_arr_created)
            })
    }
    function getAllContact() {
        setloader(true)
        axios.get(`contact/get/${page}`, authHeader).then((res) => {
            setpagination(res.data.pagination)
            setDatas(res.data.datas)
            setloader(false)
        })

    }
    function contactdetailfunc({ id }) {
        navigate(`/contact_detail/${id}`)
        dispatch(bd_active_tab('1'))
    }
    function contactdetailfunc1(id) {
        navigate(`/contact_detail/${id}`)
        dispatch(bd_active_tab('1'))
    }
    function addcontactfunc() {
        navigate('/create_contact')
    }
    function gotoleadcreatefunc(v) {
        navigate(`/create_lead`, { state: { datas: v } })
    }
    function massupload() {
        setIsModalVisible(true)
    }
    const handleUpload = (v) => {
        setfile(v.fileList[0].originFileObj)

        let xl_temp_format = [
            "Comapany Name",
            "Company Phone",
            "Company Email",
            "Website",
            "Industry",
            "Contact Name",
            "Phone",
            "Email",
            "Designation",
            "Secondary Contact Person",
            "Secondary Contact Phone",
            "Secondary Contact Designation",
            "Street",
            "City",
            "State",
            "Country",
            "Pin",
            "Description",
        ]

        readXlsxFile(v.fileList[0].originFileObj).then((rows) => {

            if (rows[0]?.sort()?.join(',') === xl_temp_format?.sort()?.join(',')) {
                toast.success('EXCEL format validated')
                setxl_valid(true)
            } else {
                toast.success('EXCEL format invalid')
                setxl_valid(false)

            }
        })

    }
    const reselectfile = () => {
        setfile()
    }
    function uploadxl() {
        if (file?.name?.split('.')[file?.name?.split('.').length - 1] === 'xlsx') {
            authHeader['headers']['Content-Type'] = 'multipart/form-data'
            const data = new FormData()
            data.append('file', file)
            setloader(true)
            axios.post(`contact/excel-import`, data, authHeader)
                .then((res) => {
                    if (res.status === 201) {
                        toast.success(res.data.msg)
                    } else if (res.status === 206) {
                        toast.success('Partially data uploaded')
                    }
                    setloader(false)
                    getAllContact()
                    setfile()
                    setIsModalVisible(false)
                })
        } else {
            toast.error('Invalid file format unable to upload')
        }

    }
    function referrcontactfunc() {
        setshareContactVisible(true)
    }
    async function setactivetab(v) {
        setcurrent_tab(v)
        if (v === '1') {
            getAllContact()
        } else if (v === '2') {
            setactivetab1('1')
            getcontactsreferedtome()
        }
    }
    async function setactivetab1(v) {
        if (v === '1') {
            setcurrent_tab1(v)
            getcontactsreferedtome()
        } else if (v === '2') {
            setcurrent_tab1(v)
            getcontactsreferedbyme()
        }
    }
    const menu = (
        <Menu style={{ width: '350px', marginTop: '12px', padding: '10px' }}>
            <h6 style={{ marginLeft: '14px', fontSize: '14px', fontWeight: '500' }}>Add Search Query</h6>
            <div style={{ display: 'flex', width: '90%' }}>
                <Input type={"text"} style={{ width: '95%', margin: 'auto', marginLeft: '5%' }} value={tempsearch_contact.search_text} onChange={e => settempsearch_contact({ ...tempsearch_contact, search_text: e.target.value })} />
                <PrimaryButton btn_name="+" padding='5px 10px' onClick={() => setsearch_contact({ ...search_contact, search_text: tempsearch_contact.search_text })} />
            </div>
        </Menu>
    );
    const menu1 = (
        <Menu style={{ width: '200px', marginTop: '10px' }}>
            <h6 style={{ padding: '2%', fontWeight: '500', fontSize: '13px' }}>Choose an option</h6>
            <Row style={{ width: '90%', margin: 'auto' }}>
                {contact_status_filter?.map((s) => (
                    <Col key={s.id} span={24} style={{ background: 'white', padding: '0px 5px', display: 'flex' }} onClick={() => setsearch_contact({ ...search_contact, contact_status: s.value })}>
                        <Checkbox style={{ marginRight: '5px' }} />
                        <p style={{ fontWeight: '600', fontSize: '13px' }}>{s.value}</p>
                    </Col>
                ))}
            </Row>
        </Menu>
    )
    async function getReminder(id) {
        await axios.get(`reminder/get?type=${'Contact'}&type_id=${id}`, authHeader).then((res) => {
            setReminders(res.data.datas.reverse())
        })
    }
    function handleChange({ key, value }) {
        settempsearch_contact({ ...tempsearch_contact, [key]: value })
    }
    const paginateddata = (v) => {
        axios.get(`contact/get/${v}`, authHeader)
            .then((res) => {
                setDatas(res.data.datas)
                setpagination(res.data.pagination)
            })
    }
    const paginateddata1 = (v) => {
        axios.get(`contact/referred-by-me/${v}`, authHeader)
            .then((res) => {
                setpage(1)
                setpagination(res.data.pagination)
                setDatas(res.data.datas)
            }).catch((err) => {
            })
    }
    function setdatepickervisiblefun() {
        setdatepickervisible(false)
        setsearch_contact({ ...search_contact, from_date: '', to_date: '' })
    }
    function setselectedDateRange() {
        if (!tempsearch_contact.from_date || !tempsearch_contact.to_date) {
            toast.info('From date or To date might be missing')
        } else {
            settempsearch_contact({ ...tempsearch_contact, from_date: '', to_date: '' })
            setsearch_contact({ ...search_contact, from_date: tempsearch_contact.from_date, to_date: tempsearch_contact.to_date })
            setdatepickervisible(false)
        }
    }
    function applyfilter() {
        if (search_contact.search_text || search_contact.contact_status || search_contact.from_date || search_contact.to_date) {
            axios.get(`contact/search?search_text=${search_contact.search_text}&contact_status=${search_contact.contact_status}&from_date=${search_contact.from_date}&to_date=${search_contact.to_date}&page=${page}`, authHeader)
                .then((res) => {
                    setsearch_contact({ ...search_contact, filter_status: true })
                    settempsearch_contact({ ...tempsearch_contact, search_text: '' })
                    setpagination(res.data.pagination)
                    setDatas(res.data.datas)

                })
        } else {
            toast.info('Select any filter option before applying ')
        }
    }
    function resetfilter() {
        setsearch_contact({ search_text: '', contact_status: '', from_date: '', to_date: '', filter_status: false })
        settempsearch_contact({ search_text: '', contact_status: '', from_date: '', to_date: '' })
        getAllContact()
    }
    function setshareContactVisiblefun() {
        setshareContactVisible(false)
    }
    function setselected_sharedcontactfun(v, v1) {
        setselected_sharedcontact(v1)
        setselected_sharedcontacttable(v)
    }
    function sharecontactfun() {
        const send_data = {
            contact_id: selected_contact ? selected_contact : selected_sharedcontact,
            department_id: sharecontact_data.department_id,
            referred_to: sharecontact_data.employee_id,
        }

        setloader(true)
        axios.post(`contact/refer`, send_data, authHeader)
            .then((res) => {
                if (res.data.status) {
                    toast.success('Contact shared successfully')
                    setsharecontact_data({ ...sharecontact_data, department_id: '', employee_id: '', contact_id: '' })
                    setshareContactVisible(false)
                    setselected_contact('')
                    setselected_sharedcontact('')
                    setselected_sharedcontacttable('')
                }
                setloader(false)
            }).catch((err) => {
                setloader(false)
                console.log('err here contact/refer', err)
            })
    }
    function getcontactsreferedbyme() {
        setloader(true)
        axios.get(`contact/referred-by-me/${page}`, authHeader)
            .then((res) => {
                setloader(false)
                setpage(1)
                setpagination(res.data.pagination)
                setDatas(res.data.datas)
            }).catch((err) => {
            })
    }
    function getcontactsreferedtome() {
        setloader(true)
        axios.get(`contact/referred-to-me/${page}`, authHeader)
            .then((res) => {
                setloader(false)
                setpage(1)
                setpagination(res.data.pagination)
                setDatas(res.data.datas)
            }).catch((err) => {
            })
    }

    return (
        <div style={{ width: '100%', margin: 'auto', display: 'flex', flexDirection: 'column', background: 'white', minHeight: '100vh' }}>
            {loader && <Loader />}
            <Toaster />
            <div style={{ width: '90%', margin: '40px auto', position: 'sticky', top: '0px' }}>
           
                <Modal visible={datepickervisible} closable={false} footer={false} onCancel={() => setdatepickervisiblefun()} style={{ top: '20%', maxWidth: '350px' }}>
                    <div style={{ maxWidth: '200px', }}>
                    <IoIosClose  onClick={() => setdatepickervisiblefun()}size={20} style={{position:'absolute',top:'20px',cursor:'pointer',right:'25px'}} />
                        <h6 style={{ padding: '2%', fontWeight: '500', fontSize: '13px' }}>Select date range option</h6>
                        <Row style={{ width: '300px', margin: 'auto', display: 'flex' }}>
                            <Col span={12} style={{ background: 'white', padding: '0px 5px', width: '100%' }}>
                                <h6 style={{ fontSize: '12px' }}>From Date</h6>
                                <DateField
                                    onChangefun={handleChange}
                                    name={'from_date'}
                                    type="1"
                                    date_value={tempsearch_contact.from_date}
                                    style={{ width: '100%' }} />
                            </Col>

                            <Col span={12} style={{ background: 'white', padding: '0px 5px' }}>
                                <h6 style={{ fontSize: '12px' }}>To Date</h6>
                                <DateField
                                    name={'to_date'}
                                    type="1"
                                    onChangefun={handleChange}
                                    date_value={tempsearch_contact.to_date}
                                    style={{ width: '100%' }} />
                            </Col>

                            <div style={{ marginLeft: '5px' }} onClick={setselectedDateRange}>
                                <PrimaryButton btn_name={'Apply'} />
                            </div>
                        </Row>
                    </div>
                </Modal>

                <Modal visible={shareContactVisible} footer={false} closable={false} onCancel={() => setshareContactVisiblefun()} style={{ maxWidth: '280px' }} centered>
                    <div style={{ maxWidth: '200px', }}>
                    <IoIosClose  onClick={() => setshareContactVisiblefun()}size={20} style={{position:'absolute',top:'20px',cursor:'pointer',right:'25px'}} />
                        <h6 style={{ padding: '1%', fontWeight: '700', fontSize: '13px' }}>Share Contact</h6>

                        <h6 style={{ fontSize: '11px', width: '112%', wordBreak: 'break-word', padding: '8px', background: '#def0ff', border: '1px solid #236499', borderRadius: '2px' }}><strong>Note:</strong>Once you share contact you cant revert this shared contact back be sure that person your transferring is trust worthy</h6>
                        <Row style={{ width: '300px', margin: 'auto', display: 'flex' }}>
                            <Col span={24} style={{ background: 'white', padding: '0px 5px', width: '80%', maxWidth: '230px' }}>
                                <h6 style={{ fontSize: '12px' }}>Select Department</h6>
                                <Select
                                    showSearch
                                    placeholder="Select Department"
                                    optionFilterProp="children"
                                    value={sharecontact_data.department_id}
                                    onChange={(v) => handleChangefun(v, 'department_id')}
                                    size='medium'
                                    filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    style={{ width: '100%' }}
                                >
                                    {
                                        departement_arr.map((d) => (
                                            <Option key={d.id} value={d.id}><div style={{fontWeight:'500',fontSize:'13px',textTransform:'capitalize'}}>{d.value}</div></Option>
                                        ))
                                    }
                                </Select>
                            </Col>

                            {sharecontact_data.department_id &&
                                <Col span={24} style={{ background: 'white', padding: '0px 5px', width: '80%', maxWidth: '230px', marginTop: '10px' }}>
                                    <h6 style={{ fontSize: '12px' }}>Select Employee</h6>
                                    <Select
                                        showSearch
                                        placeholder="Select Employee"
                                        optionFilterProp="children"
                                        value={sharecontact_data.employee_id}
                                        onChange={(v) => handleChangefun(v, 'employee_id')}
                                        size='medium'
                                        filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        style={{ width: '100%' }}
                                    >
                                        {
                                            employees_arr.map((d) => (
                                                <Option key={d.id} value={d.id} ><div style={{fontWeight:'500',fontSize:'13px',textTransform:'capitalize'}}>{d.value}</div></Option>
                                            ))

                                        }
                                    </Select>
                                </Col>}

                            {(sharecontact_data.department_id && sharecontact_data.employee_id) &&
                                <div style={{ marginLeft: '5px' }} onClick={sharecontactfun}>
                                    <PrimaryButton btn_name={' Share Contact'} />
                                </div>}
                        </Row>
                    </div>
                </Modal>

                <Modal visible={isModalVisible} style={{maxWidth:'350px'}} closable={false} centered footer={false} onCancel={() => setIsModalVisible(false)} >
                    <Tooltip title="close"><IoIosClose  onClick={() => setIsModalVisible(false)}size={20} style={{position:'absolute',top:'5px',cursor:'pointer',right:'20px'}} /></Tooltip>
                    {file === undefined ?
                        <Dragger style={{ marginTop: '5px' }}
                            onChange={handleUpload}
                            beforeUpload={() => false}
                            showUploadList={false}>
                            <img src={'https://cdn-icons-png.flaticon.com/512/4726/4726040.png'} style={{ width: '70px', objectFit: 'contain',marginBottom:'20px' }} />
                            <p  style={{fontWeight:'800',fontSize:'13px'}}>Click or drag file to this area to upload</p>
                            <p className="ant-upload-hint"  style={{fontWeight:'600',fontSize:'11px'}}>
                                Support for a single file upload. Strictly used from uploading company data
                            </p>
                        </Dragger>
                        :
                        <div>
                            <h6 style={{ fontWeight: '800' }}>File uploaded</h6>
                            <div style={{ display: 'flex', alignItems: 'center', border: '1px solid lightgray', padding: '2px 10px', position: 'relative', marginTop: '20px' }}>
                                <img src={'https://fidecrms3bucket.s3.amazonaws.com/xlsx.png'} style={{ width: '30px', objectFit: 'contain' }} />
                                <div style={{ marginLeft: '10px' }}>
                                    <h6 style={{ fontSize: '14px', fontWeight: '600', marginBottom: '2px' }}>{file.name}</h6>
                                    <h6 style={{ fontSize: '10px', color: 'gray' }}>{file.size} bytes</h6>
                                </div>
                                <MdOutlineDeleteOutline size={22} style={{ position: 'absolute', right: '10px' }} onClick={reselectfile} />
                            </div>

                            {xl_valid &&
                                <div onClick={uploadxl}>
                                    <PrimaryButton btn_name={'Upload Xl'} />
                                </div>}
                        </div>
                    }
                </Modal>

                <Tabs
                    defaultActiveKey={current_tab}
                    size="small"
                    style={{ width: '100%', margin: '10px auto', marginBottom: '0px', position: 'sticky', top: '80px' }}
                    onChange={(v) => setactivetab(v)}>

                    <TabPane tab="My Contacts" key="1">
                        <div style={{ position: 'sticky', top: '-20px', zIndex: 10, background: 'white' }}>
                            <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between', margin: '10px 0px', padding: '0px 0px', background: 'transparent', marginTop: '-10px' }}>
                                <h6 style={{ marginBottom: '0px',fontSize:'15px', fontWeight: 'bolder' }}>Total Contacts ({pagination?.total})</h6>
                                <div style={{ display: 'flex', alignItems: 'center', }}>

                                    <div style={{ display: 'flex', alignItems: 'center' }}>

                                        {selected_contact &&
                                            <div onClick={() => referrcontactfunc()} style={{ marginRight: '10px' }}>
                                                <PrimaryButton btn_name="Share Contact" />
                                            </div>}

                                        <div onClick={() => massupload()} style={{ marginRight: '10px' }}>
                                            <PrimaryButton btn_name="Upload EXCEL" />
                                        </div>
                                        <div onClick={() => addcontactfunc()}>
                                            <PrimaryButtonAdd btn_name="Create Contact" />
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div style={{ display: 'flex',marginTop:'-20px' }}>
                                <div style={{ display: 'flex', alignItems: 'center', marginLeft: '0px', padding: '5px 0px' }}>
                                    <HiOutlineFilter size={24} style={{ marginRight: '10px' }} />
                                    <h6 style={{ marginBottom: '0px', marginRight: '15px', fontSize: '14px', fontWeight: 'bolder' }}>Filter Contact By</h6>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>

                                        {search_contact.search_text === '' ?
                                            <Dropdown overlay={menu} trigger={['click']}>
                                                <button type='button' className='btn  btn-light btn-sm m-1'   >
                                                    <GrFormAdd color="white" size={18} style={{ marginRight: '6px', color: 'white' }} />
                                                    <span>Add Search filter</span>
                                                </button>
                                            </Dropdown>
                                            :
                                            <div style={{ display: 'flex', alignItems: 'center', background: '#f8f9fa', padding: '8px', borderRadius: '2px' }}>
                                                <h6 style={{ marginBottom: '0px', fontSize: '12px' }}>{search_contact.search_text.slice(0, 20)}{search_contact.search_text.length > 20 && '...'}</h6>
                                                <IoClose style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => setsearch_contact({ ...search_contact, search_text: '' })} />
                                            </div>

                                        }

                                        {search_contact.contact_status === '' ?
                                            <Dropdown overlay={menu1} trigger={['click']}>
                                                <button type='button' className='btn  btn-light btn-sm m-1'   >
                                                    <GrFormAdd color="white" size={18} style={{ marginRight: '6px', color: 'white' }} />
                                                    <span>Add Contact Status</span>
                                                </button>
                                            </Dropdown>
                                            :
                                            <div style={{ display: 'flex', alignItems: 'center', background: '#f8f9fa', padding: '8px', borderRadius: '2px', marginLeft: '5px' }}>
                                                <h6 style={{ marginBottom: '0px', fontSize: '12px' }}>{search_contact.contact_status.slice(0, 20)}{search_contact.contact_status.length > 20 && '...'}</h6>
                                                <IoClose style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => setsearch_contact({ ...search_contact, contact_status: '' })} />
                                            </div>
                                        }


                                        {search_contact.from_date === '' || search_contact.to_date === '' ?
                                            <div>
                                                <button type='button' className='btn  btn-light btn-sm m-1' onClick={() => setdatepickervisible(true)}   >
                                                    <GrFormAdd color="white" size={18} style={{ marginRight: '6px', color: 'white' }} />
                                                    <span>Date interval</span>
                                                </button>

                                            </div>
                                            :
                                            <div style={{ display: 'flex', alignItems: 'center', background: '#f8f9fa', padding: '8px', borderRadius: '2px', margin: '0px 5px' }}>
                                                <h6 style={{ marginBottom: '0px', fontSize: '12px' }}>{search_contact.from_date + ':' + search_contact.to_date}</h6>
                                                <IoClose style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => setsearch_contact({ ...search_contact, from_date: '', to_date: '' })} />
                                            </div>
                                        }


                                        <div style={{ marginRight: '5px' }} onClick={applyfilter}>
                                            <PrimaryButtonFilter btn_name="Apply Filter" padding={'2px 8px'} />
                                        </div>
                                        <div style={{ marginRight: '5px' }} onClick={resetfilter}>
                                            <PrimaryButtonFilter btn_name="Reset Filter" padding={'2px 8px'} />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        {loader ?
                            <Loader />
                            :
                            <>
                                {
                                    (datas.length ? <ReactMaterialTable
                                        reminder={reminder}
                                        headers={keys_arr}
                                        data={datas}
                                        type="contact"
                                        selected_contact={selected_contact}
                                        setselected_contact_func={(v) => setselected_contact_func(v)}
                                        dataLabels={label_arr}
                                        func={(v) => contactdetailfunc(v)}
                                        gotoleadcreatefunc={(v) => gotoleadcreatefunc(v)}
                                        pages={pagination.totalPages}
                                        current_page={page}
                                        setpagefuun={v => paginateddata(v)}
                                        pagination={pagination}
                                        getreminderfun={(v) => getReminder(v)} />
                                        :
                                        <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                                            <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '200px', objectFit: 'contain' }} />
                                            <h4 style={{ fontSize: '18px', fontWeight: '700', marginTop: '10px', textAlign: 'center' }}>{search_contact.filter_status ? 'No data Found' : "You don't have any contact created yet."}</h4>
                                            <h6 style={{ color: '#747474', fontSize: '13px', maxWidth: '80%', textAlign: 'center', margin: 'auto' }}>{search_contact.filter_status ? 'No data found with provided search options please check other format filter options' : "Visit a contact page and press on a add contact icon. Then, you can find the contact on this page."}</h6>
                                        </div>
                                    )
                                }
                            </>
                        }
                    </TabPane>

                    <TabPane tab="Shared Contact" key="2">
                        <Tabs
                            activeKey={current_tab1}
                            size="small"
                            style={{ width: '100%', marginBottom: '0px', position: 'sticky', marginTop:'-15px' }}
                            onChange={(v) => setactivetab1(v)}>
                            <TabPane tab="Shared To Me" key="1">
                                {loader ?
                                    <Loader />
                                    :
                                    <>

                                        <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between', margin: '10px 0px', padding: '0px 0px', background: 'transparent', position: 'sticky', top: '10px', height: '40px', marginTop: '-10px' }}>



                                            <h6 style={{ marginBottom: '0px', fontWeight: 'bolder', fontSize: '14px' }}>Share Contacts ({pagination.total})</h6>

                                            <div style={{ display: 'flex', alignItems: 'center', }}>

                                                <div style={{ display: 'flex', alignItems: 'center' }}>

                                                    {selected_sharedcontact &&
                                                        <div onClick={() => referrcontactfunc()} style={{ marginRight: '10px' }}>
                                                            <PrimaryButton btn_name="Share Contact" />
                                                        </div>}
                                                </div>
                                            </div>
                                        </div>


                                        {
                                            (datas.length ? <ReactMaterialTable
                                                data={datas}
                                                reminder={reminder}
                                                selected_contact={selected_sharedcontacttable}
                                                setselected_contact_func={(v, v1) => setselected_sharedcontactfun(v, v1)}
                                                type="Referred To me"
                                                func1={(v) => contactdetailfunc1(v)}
                                                gotoleadcreatefunc={(v) => gotoleadcreatefunc(v)}
                                                pages={pagination.totalPages}
                                                current_page={page}
                                                setpagefuun={v => paginateddata(v)}
                                                pagination={pagination}
                                                getreminderfun={(v) => getReminder(v)} />
                                                :
                                                <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                                                    <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '200px', objectFit: 'contain' }} />
                                                    <h4 style={{ fontSize: '18px', fontWeight: '700', marginTop: '10px', textAlign: 'center' }}>{search_contact.filter_status ? 'No data Found' : "You don't have any contact Referred yet."}</h4>
                                                    <h6 style={{ color: '#747474', fontSize: '13px', maxWidth: '80%', textAlign: 'center', margin: 'auto' }}>{search_contact.filter_status ? 'No data found with provided search options please check other format filter options' : "Visit a a friend and ask to share a contact for your reference to connect."}</h6>
                                                </div>
                                            )
                                        }
                                    </>
                                }

                            </TabPane>

                            <TabPane tab={`Shared By Me`} key="2">
                                {loader ?
                                    <Loader />
                                    :
                                    <>
                                        <h6 style={{ marginBottom: '10px', fontWeight: 'bolder', fontSize: '14px' }}>Shared Contacts ({pagination.total})</h6>
                                        {
                                            (datas.length ? <ReactMaterialTable
                                                data={datas}
                                                type="Referred by me"
                                                func1={(v) => contactdetailfunc1(v)}
                                                pages={pagination.totalPages}
                                                current_page={page}
                                                setpagefuun={v => paginateddata1(v)}
                                                pagination={pagination}
                                                getreminderfun={(v) => getReminder(v)} />
                                                :
                                                <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                                                    <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '200px', objectFit: 'contain' }} />
                                                    <h4 style={{ fontSize: '18px', fontWeight: '700', marginTop: '10px', textAlign: 'center' }}>{search_contact.filter_status ? 'No data Found' : "You don't have any contact shared yet."}</h4>
                                                    <h6 style={{ color: '#747474', fontSize: '13px', maxWidth: '80%', textAlign: 'center', margin: 'auto' }}>{search_contact.filter_status ? 'No data found with provided search options please check other format filter options' : "Visit a contact page and press on a add contact icon. Then, you can find the contact on this page."}</h6>
                                                </div>
                                            )
                                        }
                                    </>
                                }

                            </TabPane>
                        </Tabs>
                    </TabPane>
                </Tabs>
            </div>
        </div>
    )
}

export default ViewContact
