import React,{useEffect, useState} from 'react'
import FmsDashboardMainRoot from '../FmsDashboardMainRoot'
import { Toaster } from 'react-hot-toast'
import { BoldText1 } from '../../../helpers/constants/ConstantsText'
import {BsSearch} from 'react-icons/bs';
import { MdFileDownload } from "react-icons/md"
import {MdOutlineRefresh} from 'react-icons/md'
import { useLocation } from 'react-router-dom';
import {Select } from 'antd'
import axios from 'axios';
import { authHeader } from '../../../helpers/usertoken/UserToken';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

function DownloadClientAttendance() {

  const {state} = useLocation()  

  const [year,setyear] = useState([])
  const [employeeArr,setemployeeArr] = useState([])
  const [datas,setdatas] = useState([])
  
  var month = [{id:0,value:"January"},{id:1,value:"February"},{id:2,value:"March"},{id:3,value:"April"},{id:4,value:"May"},{id:5,value:"June"},{id:6,value:"July"},{id:7,value:"August"},{id:8,value:"September"},{id:9,value:"October"},{id:10,value:"November"},{id:11,value:"December"}];
  const [filter,setfilter] = useState({year:'',month:'',employee:'',attendance_finalized:false})
  


  useEffect(()=>{
    Attendanceyearfun()
    getEmployeeList()
  },[])

  function Attendanceyearfun(){
    axios.get(`fms/attendanceyear/get`,authHeader)
       .then((res)=>{
         let arr = []
         res?.data?.datas?.filter((d)=>{
           arr.push({value:d?.name,label:`${d?.name}`})
         })
         setyear(arr)
       }).catch((err)=>{
 
       })
  }

  function handleChange1(v){   
    setfilter({...filter,employee:employeeArr?.find(v1=>v1.value === v)})
  }


  function getdata(){
    axios.get(`fms/fmsclients/get_attendance_based_client?year=${filter?.year}&month=${filter?.month !== '' ? month?.find((f)=>f?.value === filter?.month)?.id + 1 : ''}&user=${filter?.employee?.value !== undefined ? filter?.employee?.value : ''}`,authHeader)
    .then((res)=>{
        console.log("res?.data",res?.data)
        setdatas(res?.data?.data)
    })
  }


  const getEmployeeList = async (v) => {
       await axios.get(`fms/fmsclients/employee_based_on_client_all/${state?._id}`,authHeader)
        .then((res)=>{
            let arr = []
            if(res?.data?.datas?.length > 0){
                res?.data?.datas?.forEach((d)=>{
                    // console.log("d annaya",d)
                    arr.push({value:d?._id,label:`[${d?.employee_id}]-${d?.name}-[client:${d?.department_branch_were_deployed?.client_name}]`})
                })
                setemployeeArr(arr)
                
                // return arr.filter((i) =>
                // i.label.toLowerCase().includes(v.toLowerCase())
                // );
  
            }else{
                return arr
            }
  
        }).catch((err)=>{
            // console.log("err data",err?.response?.data)
        })
    };
  

   async function  downloadExcel(){
    // Create a new workbook and a sheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('My Sheet');
 

    let arr = [
        { header: 'SL NO', key: 'id', width: 10 },
        { header: 'Employee Name', key: 'name', width: 20 },
        { header: 'Employee Id', key: 'employee_id', width: 20 },
        { header: 'Designation', key: 'designation', width: 20 },
        { header: 'DOJ', key: 'doj', width: 20 },
        { header: 'Client Name', key: 'client_name', width: 20 },
       
    ]

    for(let i=0;i<31;i++){
        arr.push( { header: i+1, key: i+1, width: 5 },)
    }

    arr.push(
        { header: 'Working Days', key: 'working_days', width: 15 },
        { header: 'Week Off', key: 'week_off', width: 15 },
        { header: 'OT in Hrs', key: 'ot', width: 15 },
        { header: 'Total', key: 'total', width: 15 },
    )

    worksheet.columns = [...arr];


    datas?.forEach((d,i1)=>{

       
        let data = d?.attendance[0]
        let hours = 0;

        data?.attendance?.forEach((a)=>{
            if(a?.ot){
             hours += parseInt(a?.ot_hrs)
            }
           })

        let obj = {
            "id":i1+1,
            "name":d?.employee?.name,
            "employee_id":d?.employee?.employee_id,
            "designation":d?.employee?.designation?.designation_name,
            "doj":d?.employee?.date_of_joining,
            "client_name":state?.client_name,
        }

        for(let i=0;i<31;i++){
            obj[i+1] = data?.attendance?.filter((d)=>new Date(d?.date).getDate() - 1 === i )?.length > 0 ? data?.attendance?.filter((d)=>new Date(d?.date).getDate() - 1 === i )[0]?.employee_stage : ''
        }

        obj["working_days"] = data?.attendance?.filter((f)=>f?.employee_stage === 'P')?.length
        obj["week_off"] = data?.attendance?.filter((f)=>f?.employee_stage === 'WO')?.length
        obj["ot"] = hours
        obj["total"] = data?.attendance?.filter((f)=>f?.employee_stage === 'WO')?.length + data?.attendance?.filter((f)=>f?.employee_stage === 'P')?.length




        worksheet.addRow(obj);
    })

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, `Client:[${state?.client_name}]_attendance_report.xlsx`);

   }

  function applyFilter(){
    if(filter?.year && filter?.month){
        getdata()
    }

  } 
  
  
  function resetFilter(){
    setfilter({year:'',month:'',employee:'',attendance_finalized:false})
    setdatas([])
  }



  function return_ot_fun(d){
    let hours = 0
    d?.attendance?.forEach((a)=>{
        if(a?.ot){
         hours += parseInt(a?.ot_hrs)
        }
       })
    return hours   
  }


  return (
    <FmsDashboardMainRoot>
        <div>
            <Toaster />

            <div style={{width:'98%'}}>
                <div style={{borderBottom:'1px solid #f3f3f3',paddingBottom:'10px',display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between'}}>
                <BoldText1 val={`[ ${state?.site_name} ] Employee Attendance`} />

                {/* <PrimaryButton btn_name={'Add Point'} onClick={()=>setmodal(true)} /> */}
                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <div style={{width:'50%',padding:'3px 0px',border:'1px solid  #f3f3f3',marginRight:'10px'}}>
                    <Select bordered={false} placeholder={'Year'} value={filter?.year !== '' ? filter?.year : null}  showArrow={false} style={{width:'80px',fontSize:'14px',fontWeight:'800'}} size='small' options={year} onSelect={(v)=>{setfilter({...filter,year:year?.find(y=>y.value === v)?.label})}} /> 
                    </div>
                    <div style={{width:'50%',padding:'3px 0px',border:'1px solid  #f3f3f3',marginRight:'10px'}}>
                    <Select bordered={false} placeholder={'Month'}  value={filter?.month !== '' ? filter?.month : null}  showArrow={false} style={{width:'80px',fontSize:'14px',fontWeight:'800'}} size='small' options={month} onSelect={(v)=>{setfilter({...filter,month:v})}} /> 
                    </div>

                    <div style={{maxWidth:'180px',padding:'0px 0px',border:'1px solid  #f3f3f3',marginRight:'10px'}}>
                    <Select 
                        // showSearch
                        bordered={false}
                        style={{ maxWidth:'180px',boxShadow:'none' }}
                        value={filter?.employee === '' ? null : filter?.employee?.label}
                        options={employeeArr}
                        onChange={(v)=>handleChange1(v,'employee')}
                        placeholder={'Search Employee'}
                        // filterOption={(input, option) =>
                        // (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        // }
                    />
                    </div>

                    <BsSearch onClick={applyFilter} style={{marginLeft:'0px',width:'50px',height:'100%',padding:'8px',background:'#eee',}}/>
                    <MdOutlineRefresh onClick={resetFilter} style={{marginLeft:'10px',width:'50px',height:'100%',background:'#eee',padding:'8px'}}/>
                    {datas?.length > 0 &&
                    <MdFileDownload onClick={downloadExcel} style={{marginLeft:'10px',width:'50px',height:'100%',background:'#eee',padding:'8px'}}/>}
                </div>
                </div>

                

                {datas?.length > 0 && 
                <div className='container1'>
                    <div style={{border:'1px solid  #f3f3f3',display:'flex',flex:1,background:'#7cc8cc',width:'auto'}}>
                    <h6  style={{minWidth:'50px',maxWidth:'50px',padding:'4px',fontSize:'11px',textTransform:'capitalize',marginBottom:0,borderRight:'1px solid  #f3f3f3',}}>SL NO</h6>
                    <h6  style={{minWidth:'120px',maxWidth:'120px',padding:'4px',fontSize:'11px',textTransform:'capitalize',marginBottom:0,borderRight:'1px solid  #f3f3f3',}}>Emp Name</h6>
                    <h6  style={{minWidth:'100px',maxWidth:'100px',padding:'4px',fontSize:'11px',textTransform:'capitalize',marginBottom:0,borderRight:'1px solid  #f3f3f3',}}>Emp Id</h6>
                    <h6  style={{minWidth:'100px',maxWidth:'100px',padding:'4px',fontSize:'11px',textTransform:'capitalize',marginBottom:0,borderRight:'1px solid  #f3f3f3',}}>Designation</h6>
                    <h6  style={{minWidth:'100px',maxWidth:'100px',padding:'4px',fontSize:'11px',textTransform:'capitalize',marginBottom:0,borderRight:'1px solid  #f3f3f3',}}>DOJ</h6>
                    <h6  style={{minWidth:'100px',maxWidth:'100px',padding:'4px',fontSize:'11px',textTransform:'capitalize',marginBottom:0,borderRight:'1px solid  #f3f3f3',}}>Client Name</h6>
                    <div style={{display:'flex',justifyContent:'space-evenly',flexDirection:'row',marginBottom:0}}>    
                    {new Array(31).fill('')?.map((d1,i)=>(
                        <div key={i} style={{padding:'4px',minWidth:'30px',maxWidth:'30px',borderRight:'1px solid  #f3f3f3',marginBottom:0}}>
                        <h6>{ <h6 style={{fontSize:'8px',fontWeight:'900px',marginBottom:0}}>{i+1}</h6>}</h6>
                        </div>
                    ))}
                  
                    </div>
                    <h6  style={{minWidth:'100px',maxWidth:'100px',padding:'4px',fontSize:'11px',textTransform:'capitalize',marginBottom:0,borderRight:'1px solid  #f3f3f3',}}>Working Days</h6>
                    <h6  style={{minWidth:'100px',maxWidth:'100px',padding:'4px',fontSize:'11px',textTransform:'capitalize',marginBottom:0,borderRight:'1px solid  #f3f3f3',}}>Week Off</h6>
                    <h6  style={{minWidth:'100px',maxWidth:'100px',padding:'4px',fontSize:'11px',textTransform:'capitalize',marginBottom:0,borderRight:'1px solid  #f3f3f3',}}>OT in Hrs</h6>
                    <h6  style={{minWidth:'100px',maxWidth:'100px',padding:'4px',fontSize:'11px',textTransform:'capitalize',marginBottom:0,borderRight:'1px solid  #f3f3f3',}}>Total</h6>


                    </div>
                    <div style={{border:'1px solid  #f3f3f3'}}>
                    {datas?.map((d,i)=>(
                    <div style={{display:'flex',borderBottom:i + 1 !== datas?.length && '1px solid  #f3f3f3',}}>
                    <h6  style={{minWidth:'50px',maxWidth:'50px',padding:'4px',fontSize:'11px',textTransform:'capitalize',marginBottom:0,borderRight:'1px solid  #f3f3f3',}}>{i+1}</h6>
                    <h6  style={{minWidth:'120px',maxWidth:'120px',padding:'4px',fontSize:'11px',textTransform:'capitalize',marginBottom:0,borderRight:'1px solid  #f3f3f3',}}>{d?.employee?.name}</h6>
                    <h6  style={{minWidth:'100px',maxWidth:'100px',padding:'4px',fontSize:'11px',textTransform:'capitalize',marginBottom:0,borderRight:'1px solid  #f3f3f3',}}>{d?.employee?.employee_id}</h6>
                    <h6  style={{minWidth:'100px',maxWidth:'100px',padding:'4px',fontSize:'11px',textTransform:'capitalize',marginBottom:0,borderRight:'1px solid  #f3f3f3',}}>{d?.employee?.designation?.designation_name}</h6>
                    <h6  style={{minWidth:'100px',maxWidth:'100px',padding:'4px',fontSize:'11px',textTransform:'capitalize',marginBottom:0,borderRight:'1px solid  #f3f3f3',}}>{d?.employee?.date_of_joining}</h6>
                    <h6  style={{minWidth:'100px',maxWidth:'100px',padding:'4px',fontSize:'11px',textTransform:'capitalize',marginBottom:0,borderRight:'1px solid  #f3f3f3',}}>{state?.client_name}</h6>
                    <div style={{display:'flex',justifyContent:'space-evenly',flexDirection:'row',marginBottom:0}}>    
                    {new Array(31).fill('')?.map((d1,i)=>(
                        <div style={{padding:'4px',minWidth:'30px',maxWidth:'30px',borderRight:'1px solid  #f3f3f3',marginBottom:0}}>
                        <h6 style={{fontSize:'12px'}}>{d?.attendance[0]?.attendance?.filter((d)=>new Date(d?.date).getDate() - 1 === i )?.length > 0 && d?.attendance[0]?.attendance?.filter((d)=>new Date(d?.date).getDate() - 1 === i )[0]?.employee_stage}</h6>
                        </div>
                    ))}
                    </div>
                    <h6  style={{minWidth:'100px',maxWidth:'100px',padding:'4px',fontSize:'11px',textTransform:'capitalize',marginBottom:0,borderRight:'1px solid  #f3f3f3',}}>{d?.attendance[0]?.attendance?.filter((f)=>f?.employee_stage === 'P')?.length}</h6>
                    <h6  style={{minWidth:'100px',maxWidth:'100px',padding:'4px',fontSize:'11px',textTransform:'capitalize',marginBottom:0,borderRight:'1px solid  #f3f3f3',}}>{d?.attendance[0]?.attendance?.filter((f)=>f?.employee_stage === 'WO')?.length}</h6>
                    <h6  style={{minWidth:'100px',maxWidth:'100px',padding:'4px',fontSize:'11px',textTransform:'capitalize',marginBottom:0,borderRight:'1px solid  #f3f3f3',}}>{return_ot_fun(d?.attendance[0])}</h6>
                    <h6  style={{minWidth:'100px',maxWidth:'100px',padding:'4px',fontSize:'11px',textTransform:'capitalize',marginBottom:0,borderRight:'1px solid  #f3f3f3',}}>{d?.attendance[0]?.attendance?.filter((f)=>f?.employee_stage === 'P')?.length + d?.attendance[0]?.attendance?.filter((f)=>f?.employee_stage === 'WO')?.length}</h6>


                    </div>))}
                    </div>
                </div>}

          </div>

        </div>
    </FmsDashboardMainRoot>
  )
}

export default DownloadClientAttendance