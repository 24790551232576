import React,{useEffect, useState} from 'react'
import FmsDashboardMainRoot from '../../FmsDashboardMainRoot'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Upload,Modal  } from 'antd';
import { CloseOutlined, UploadOutlined } from '@mui/icons-material';
import { Pagination,Select,Button } from 'antd'
import { authHeader } from '../../../../helpers/usertoken/UserToken'
import axios from 'axios';
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import PrimaryButton, { SecondaryButton } from '../../../../components/buttons/PrimaryButton';
import { BoldText1, SmallText } from '../../../../helpers/constants/ConstantsText';
import moment from 'moment';
import toast, { Toaster } from 'react-hot-toast';
import TextArea from 'antd/lib/input/TextArea';
import { theme_color } from '../../../../helpers/constants/ConstantColor';
import { base_url } from '../../../../App';


function WorkAssignedListDetail() {

    const {state} = useLocation()

    const userInfo = useSelector(state=>state.Auth)
    const roles = useSelector(state=>state.Auth.roles)
    const [page,setpage] = useState(1)   
    const [modal,setmodal] = useState(false)   

    const [options,setoptions] = useState([])
    const [status,setstatus] = useState('')

    const navigator = useNavigate()

    const optionsArr = [{label:'Pending',value:'Pending'},{label:'Progress',value:'Progress'},{label:'Done',value:'Done'},{label:'Completed',value:'Completed'},{label:'Not Completed',value:'Not Completed'}]


    const [singleData,setsingleData] = useState({remarks:'',image:''})
    const [pagination,setpagination] = useState({})
    const [data,setdata] = useState([])


    useEffect(()=>{
        if(roles?.includes('admin') || roles?.includes('fms_crm_head') || roles?.includes('fms_hr')){
        setoptions(optionsArr)
        }else{
        setoptions([{label:'Progress',value:'Progress'},{label:'Done',value:'Done'}])
        }
    },[])

    useEffect(()=>{

        getData()
    },[page])


    function getData(){
        axios.get(`fms/work_assigned/get_followup/${state?._id}?page=${page}`,authHeader)
        .then((res)=>{
            setdata(res?.data?.datas)
            setpagination(res?.data?.pagination)
        })
    }

    async function uploadFile(name,v){
   
        authHeader['headers']['Content-Type'] = 'multipart/form-data'
        var fd = new FormData()
        fd.append('file',v?.fileList[v?.fileList.length - 1]?.originFileObj)
       await axios.post(`fms/work_assigned/upload_file`,fd,authHeader)
        .then((res)=>{
            setsingleData({...singleData,image:res?.data?.data})
        })
    }

    function submit(){
        if(!singleData?.remarks){
          toast.error("This field is required*")
        }else{
            let sendData = {
                ...singleData
            }
            sendData['work_assigned'] = state?._id

            authHeader['headers']['Content-Type'] = 'application/json'

            if(singleData?._id !== undefined){
                axios.put(`fms/work_assigned/update_followup/${singleData?._id}`,sendData,authHeader)
                .then((res)=>{
                    if(res.status === 200){
                        getData()
                        setmodal(false)
                        toast.success('Team Work Updated Successfully')
                        setsingleData({remarks:'',image:''})
                    }
                }).catch((err)=>{
                })
            }else{
                axios.post('fms/work_assigned/create_followup',sendData,authHeader)
                .then((res)=>{
                    if(res.status === 201){
                        getData()
                        setmodal(false)
                        toast.success('Team Work Created Successfully')
                        setsingleData({remarks:'',image:''})
                    }
                }).catch((err)=>{
                })
            }
        }
    }   


    async function updateData() {
        axios.put(`fms/work_assigned/update/${state?._id}`,{status:status},authHeader)
        .then((res)=>{
            if(res.status === 200){
                 navigator(-1)
                toast.success('Team Work Updated Successfully')
            }
        }).catch((err)=>{
        })
    }

    function openFile(v){
        window.open(`${base_url}${v}`,'_blank')
    }
    
    function deleteData(id){
        axios.delete(`fms/work_assigned/delete_followup/${id}`,authHeader)
        .then((res)=>{
            toast.success('Deleted Successfully')
            getData()
        })
      }

    return (
    <FmsDashboardMainRoot>
        <Modal open={modal} width={300} closable={false} footer={false}>
            <div style={{position:'relative'}}>
               <h6 style={{fontWeight:'800',fontSize:'13px',marginBottom:'10px'}}>Add Work Updation</h6>


                <SmallText fontWeight={'600'} val={'Title'}  />
                <TextArea type="text"  style={{borderLeft:'4px solid #292961',marginBottom:'10px'}}  value={singleData.remarks} onChange={(e)=>{setsingleData({...singleData,remarks:e.target.value});}} />

                <SmallText fontWeight={'600'} val={'Image'}  />
                {(singleData.image === '' || singleData.image === null || singleData.image == undefined || singleData.image == '') ?
                <Upload showUploadList={false} onChange={(v)=>uploadFile('photo',v)} style={{borderLeft:`4px solid ${theme_color}`,width:'250px'}} >
                    <Button style={{width:'250px',display:'flex',alignSelf:'flex-end',justifyContent:'end'}} icon={<UploadOutlined   style={{borderLeft:'1px solid #eee',color:'#999',paddingLeft:'0px'}}/>}></Button>
                </Upload>
                :
                <Button  onClick={()=>setsingleData({...singleData,image:''})} style={{borderLeft:`4px solid ${theme_color}`,width:'250px',display:'flex',alignItems:'left',fontSize:'10px',marginBottom:'0px'}} ><p style={{marginBottom:'0px', wordBreak: 'break-word',whiteSpace:'nowrap',width:'90%',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px',textAlign:'left',marginBottom:'0px'}}>{singleData.image?.name != undefined ? singleData?.image?.name : singleData?.image?.split('/')[singleData?.image?.split('/').length-1] }</p><CloseOutlined size={15}  style={{borderLeft:'1px solid #eee',color:'#999',paddingLeft:'0px',position:'absolute',right:'0px'}}/></Button>
                }

               <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-end',paddingTop:'5px',marginTop:'10px',borderTop:'1px solid #ddd'}}>
                 <SecondaryButton onClick={()=>setmodal(false)} btn_name={'Close'} />
                 <PrimaryButton onClick={submit} btn_name={'Save'} />
               </div>
            </div>

        </Modal>
        <Toaster />
        <div style={{width:'98%',display:'flex',height:'100%',marginTop:'-30px'}}>
           <div style={{width:'30%',paddingTop:'20px',paddingRight:'20px',minHeight:'100vh',borderRight:'1px solid #eee'}}>
               
               <h6 style={{fontWeight:'800',fontSize:'15px',borderBottom:'1px solid #eee',paddingBottom:'10px',marginBottom:'10px'}}>Work Info</h6>
                
                <h6 style={{fontWeight:'800',fontSize:'12px',}}>Title</h6>
                <h6 style={{fontSize:'12px',background:'#fafafa',padding:'10px'}}>{state?.title}</h6>
                
                <h6 style={{fontWeight:'800',fontSize:'12px',}}>Description</h6>
                <h6 style={{fontSize:'12px',background:'#fafafa',padding:'10px'}}>{state?.description}</h6>
                <h6 style={{fontWeight:'500',fontSize:'12px',}}>Status : {state?.status}</h6>
                <h6 style={{fontWeight:'500',fontSize:'12px',}}>Deadline : {moment(state?.dead_line)?.format('LL')}</h6>

                {state?.remarks !== '' && 
                <h6 style={{fontWeight:'500',fontSize:'12px',}}>Remarks : {state?.remarks}</h6>}
            


           
            
                <h6 style={{fontWeight:'800',fontSize:'12px',marginTop:'10px'}}>Created By : {state?.created_by?.name}</h6>
               
               

                <h6 style={{fontWeight:'800',fontSize:'12px',marginTop:'10px'}}>Assigned Employee ({state?.assigned_employee?.length})</h6>

                <div style={{display:'flex',flexWrap:'wrap'}}>
                  {state?.assigned_employee?.map((e)=>(
                    <span style={{background:'#ddd',padding:'4px',fontSize:'10px',marginRight:'10px'}}>{e?.name}</span>
                  ))}
                </div>

                <SmallText marginfun={'10px 0px 10px 0px'} fontWeight={'600'} val={'Work Status'}  />

                <Select 
                    value={status}
                    placeholder="" 
                    onChange={(v)=>setstatus(v)}
                    options={options}
                    style={{ width: '100%',borderLeft:'4px solid #292961',marginRight:'5px' }}
                />
                
                <PrimaryButton btn_name={"Update"} onClick={updateData} />

          
           </div>
           <div style={{width:'70%',paddingTop:'20px',padding:'10px 20px',}}>
            <div style={{borderBottom:'1px solid #eee',paddingBottom:'10px',marginBottom:'10px',display:'flex',alignItems:'center',justifyContent:'space-between'}}>
              <h6 style={{fontWeight:'800',fontSize:'15px',}}>Work Updation ({pagination?.total})</h6>
              <PrimaryButton onClick={()=>setmodal(true)} btn_name={'Add Updation'} />
            </div>
 
            {data?.map((d)=>(
                 <div style={{borderBottom:'1px solid #ddd',position:'relative',padding:'10px'}}>
                     {d?.remarks !== '' && <h6 style={{fontSize:'12px',background:'#fafafa',padding:'5px'}}>Remarks : {d?.remarks}</h6>}
                     <h6 style={{fontSize:'10px'}}>Created By : {d?.created_by?.name}</h6>
                     <h6 style={{fontSize:'10px'}}>CreatedAt : {moment(d?.createdAt)?.format('LLL')}</h6>
                     {d?.image !== '' && <h6 onClick={()=>openFile(d?.image)} style={{fontSize:'12px',cursor:'pointer',textDecorationLine:'underline',fontWeight:'bold',color:'blue'}}>View File</h6>}
                    
                   {(roles?.includes('admin') || roles?.includes('fms_crm_head') || roles?.includes('fms_hr')) &&
                    <div style={{position:'absolute',bottom:'10px',right:'10px'}}>
                      <AiOutlineEdit style={{marginRight:'10px'}} onClick={()=>{setsingleData(d);setmodal(true)}} />
                      <AiOutlineDelete onClick={()=>deleteData(d?._id)} />
                    </div>}  
               
                 </div>   
            ))}


            <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
                {pagination.total > pagination?.limit &&
                    <Pagination current={page} size="small" total={pagination.total} onChange={(v) => setpage(v)}  pageSize={pagination?.limit} />
                }
            </div>

          
           </div>


        </div>
    </FmsDashboardMainRoot>
  )
}

export default WorkAssignedListDetail