import React from 'react'
import { isMobile } from '../../helpers/mobileresize/Mobileresize'
import WebProfile from './web/index'
import MobileProfile from './android/index'

const index = ({func}) =>{
    if(isMobile){
        return(
            <div>
                <MobileProfile />
            </div>
        )
    }else{
        return(
            <div>
                <WebProfile  func={()=>func()}/>
            </div>
        )
    }
}

export default index