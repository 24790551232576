import React, { useState,useEffect } from 'react'
import FmsDashboardMainRoot from '../FmsDashboardMainRoot'
import { BoldText1 } from '../../../helpers/constants/ConstantsText'
import PrimaryButton from '../../../components/buttons/PrimaryButton'
import axios from 'axios'
import { authHeader } from '../../../helpers/usertoken/UserToken'
import { Pagination,Row,Col,Drawer,Select } from 'antd'
import {AiOutlineFileDone,AiOutlineClose,AiOutlineSearch,AiOutlineFilter} from 'react-icons/ai';
import {BsFilterSquare} from 'react-icons/bs';
import {BiCheckbox,BiCheckboxSquare} from 'react-icons/bi'
import {GrPowerReset} from 'react-icons/gr'
import { Toaster, toast } from 'react-hot-toast'


function AttendanceOptionBaseList() {

  const [data,setdata] = useState([])
  const [page,setpage] = useState(1)
  const [step,setstep] = useState(1)
  const [pagination,setpagination] = useState({})
  const [selected_data,setselected_data] = useState({})
  const [modal,setmodal] = useState(false)

  const [datas_selected,setdatas_selected] = useState([])
  const [employeeArr,setemployeeArr] = useState([])

  const [year,setyear] = useState([])
  var month = [{id:0,value:"January"},{id:1,value:"February"},{id:2,value:"March"},{id:3,value:"April"},{id:4,value:"May"},{id:5,value:"June"},{id:6,value:"July"},{id:7,value:"August"},{id:8,value:"September"},{id:9,value:"October"},{id:10,value:"November"},{id:11,value:"December"}];
  const [filter,setfilter] = useState({year:'',month:'',employee:'',attendance_finalized:false})

  useEffect(()=>{
    Attendanceyearfun()
  },[])

  useEffect(()=>{
    getData()
  },[page])

 
 function getData(v){
    axios.get(`fms/basicemployee/get_attendance_base?year=${filter?.year}&month=${filter?.month !== '' ? month?.find((f)=>f?.value === filter?.month)?.id + 1 : ''}&user=${filter?.employee?.value !== undefined ? filter?.employee?.value : ''}&page=${v !== undefined ? v : page}`,authHeader)
    .then((res)=>{
        setdata(res?.data?.datas)
        setpagination(res?.data?.pagination)
    })
 }

 function Attendanceyearfun(){
   axios.get(`fms/attendanceyear/get`,authHeader)
      .then((res)=>{
        let arr = []
        res?.data?.datas?.filter((d)=>{
          arr.push({value:d?.name,label:`${d?.name}`})
        })
        setyear(arr)
      }).catch((err)=>{

      })
 }

 function setpagefuun(v){
    setpage(v)
 }



 function updateattendancefinalized(){
  axios.put(`fms/basicemployee/update_attendance_base/${selected_data?.id}`,{attendance_finalized:selected_data.attendance_finalized},authHeader)
  .then((res)=>{
      setmodal(false)
      setselected_data({})
      toast.success('Updated Successfully')
      getData()
  })
 }

 function select_mass_data(v){
   let old_data = [...datas_selected]
   if(old_data?.length <= 30){
    if(old_data?.filter((f)=>f?.id === v.id)?.length > 0){
        let index = old_data?.findIndex((f)=>f?.id === v?.id)
        old_data.splice(index,1)
        setdatas_selected(old_data)
    }else {
        old_data?.push(v)
        setdatas_selected(old_data)
    }
   }else{
    toast.error('Maximum Selection Reached')
   }
 }

 function updateMassData(){
  let send_data = {...filter}
  send_data['month'] = filter?.month !== '' && month?.find((f)=>f?.value === filter?.month)?.id + 1 
  delete send_data.employee
  axios.put(`fms/basicemployee/update_attendance_mass`,send_data,authHeader)
  .then((res)=>{
      setmodal(false)
      setselected_data({})
      toast.success('Mass Updated Successfully')
      resetFilter()
  })
 }

 function updateMassDataArray(){
  let send_data = {
    users:datas_selected?.map((f)=>f.id),
    attendance_finalized:filter.attendance_finalized
  }

  // console.log("send_data",send_data)
  axios.put(`fms/basicemployee/update_attendance_mass_array`,send_data,authHeader)
  .then((res)=>{
      setmodal(false)
      setselected_data({})
      setdatas_selected([])
      toast.success('Mass Updated Successfully')
      resetFilter()
  })
 }

 function handleChange1(v){   
  setfilter({...filter,employee:employeeArr?.find(v1=>v1.value === v)})
  // seterror({...error,[name]:''})
}

 const filterEmployee = async (v) => {

  if(v.length > 3){
     await axios.post(`fms/basicemployee/search?search_text=${v}`,{},authHeader)
      .then((res)=>{
          let arr = []
          if(res?.data?.data?.length > 0){
              res?.data?.data?.forEach((d)=>{
                  // console.log("d annaya",d)
                  arr.push({value:d?.id,label:`[${d?.employee_id}]-${d?.name}-[client:${d?.department_branch_were_deployed?.client_name}]`})
              })
              setemployeeArr(arr)
              
              return arr.filter((i) =>
              i.label.toLowerCase().includes(v.toLowerCase())
              );

          }else{
              return arr
          }

      }).catch((err)=>{
          // console.log("err data",err?.response?.data)
      })
  }
  };

  function applyFilter(){
    getData(1)
  }

  function resetFilter(){
    setfilter({year:'',month:'',employee:'',attendance_finalized:false})
    setpage(1)
    axios.get(`fms/basicemployee/get_attendance_base?page=1`,authHeader)
    .then((res)=>{
        setdata(res?.data?.datas)
        setpagination(res?.data?.pagination)
    })
  }

  

  return (
    <FmsDashboardMainRoot>
      <Toaster />
      <Drawer width={300} open={modal} footer={null} closable={false}>
      <div style={{position:'relative',marginTop:20}}>
        <AiOutlineClose onClick={()=>{setmodal(!modal);resetFilter()}} style={{position:'absolute',right:0,top:-30}} />
        {step === 1 &&
        <>
        <h6 style={{fontSize:'12px',lineHeight:'18px'}}>Are you sure want to update the finalized attendance of the selected employee.</h6>

        <h6 style={{fontSize:'12px',fontWeight:'700'}}>{selected_data?.name} / {selected_data?.employee_id}</h6> 
        <h6 style={{fontSize:'12px',fontWeight:'700'}}>{selected_data?.month} / {selected_data?.year}</h6> 
        
        {!selected_data?.attendance_finalized ? 
        <BiCheckbox onClick={()=>setselected_data({...selected_data,attendance_finalized:!selected_data?.attendance_finalized})} size={24} style={{color:'#ddd',marginLeft:'-6px'}} /> :
        <BiCheckboxSquare onClick={()=>setselected_data({...selected_data,attendance_finalized:!selected_data?.attendance_finalized})} size={24} style={{color:'#587487',marginLeft:'-6px'}}  />}
         

         <div style={{marginTop:'5px',marginLeft:'-4px'}}>
          <PrimaryButton btn_name={'Save'} onClick={updateattendancefinalized} />
         </div>
         </>}


         {step === 2 &&
        <>
        <h6 style={{fontSize:'12px',lineHeight:'18px'}}>Are you sure want to update the mass attendance of the selected employee list.</h6>
        
        <h6 style={{margin:'10px 0px',fontSize:'11px',fontWeight:'700'}}>Employee Data Selected ({datas_selected?.length}) </h6>
        {datas_selected?.map((d)=>(
          <span style={{fontSize:'11px',}}>{d?.name},</span>
        ))}
        {/* <h6 style={{fontSize:'12px',fontWeight:'700'}}>{selected_data?.name} / {selected_data?.employee_id}</h6> 
        <h6 style={{fontSize:'12px',fontWeight:'700'}}>{selected_data?.month} / {selected_data?.year}</h6> 
         */}
         <div>

         <div style={{marginTop:'10px'}}>
        {!filter?.attendance_finalized ? 
        <BiCheckbox onClick={()=>setfilter({...filter,attendance_finalized:!filter?.attendance_finalized})} size={24} style={{color:'#ddd',marginLeft:'-6px'}} /> :
        <BiCheckboxSquare onClick={()=>setfilter({...selected_data,attendance_finalized:!selected_data?.attendance_finalized})} size={24} style={{color:'#587487',marginLeft:'-6px'}}  />}
         </div>

         </div>
         <div style={{marginTop:'5px',marginLeft:'-4px'}}>
          <PrimaryButton btn_name={'Save'} onClick={updateMassDataArray} />
         </div>
         </>}
      
         {step === 3 &&
         <>
        <h6 style={{fontSize:'12px',lineHeight:'18px'}}>Are you sure want to update the mass attendance of the selected year and month for all the employee list.</h6>

        <div style={{display:'flex',marginTop:'10px'}}>
        <div style={{width:'50%',padding:'3px 0px',border:'1px solid #ddd',marginRight:'10px'}}>
        <Select bordered={false}  value={filter?.year}  showArrow={false} style={{width:'80px',fontSize:'14px',fontWeight:'800'}} size='small' options={year} onSelect={(v)=>{setfilter({...filter,year:year?.find(y=>y.value === v)?.label})}} /> 
        </div>
        <div style={{width:'50%',padding:'3px 0px',border:'1px solid #ddd',marginRight:'10px'}}>
        <Select bordered={false} value={filter?.month}  showArrow={false} style={{width:'80px',fontSize:'14px',fontWeight:'800'}} size='small' options={month} onSelect={(v)=>{setfilter({...filter,month:v})}} /> 
        </div>
        </div>

        <div style={{marginTop:'10px'}}>
        {!filter?.attendance_finalized ? 
        <BiCheckbox onClick={()=>setfilter({...filter,attendance_finalized:!filter?.attendance_finalized})} size={24} style={{color:'#ddd',marginLeft:'-6px'}} /> :
        <BiCheckboxSquare onClick={()=>setfilter({...selected_data,attendance_finalized:!selected_data?.attendance_finalized})} size={24} style={{color:'#587487',marginLeft:'-6px'}}  />}
         </div>

         {(filter?.year !== '' && filter?.month !== '') &&
         <div style={{marginTop:'5px',marginLeft:'-4px'}}>
          <PrimaryButton btn_name={'Save'} onClick={updateMassData} />
         </div>}


         </>} 

      </div>
      </Drawer>
       
        <div style={{width:'96%'}}>
            <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between',borderBottom:'1px solid #eee',minHeight:'45px',maxHeight:'45px'}}>
                <BoldText1 val={`Attendance Base (${pagination?.total})`} />


                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                {/* {!filter?.attendance_finalized ? 
                  <BiCheckbox style={{marginRight:'10px',color:'#aaa'}} onClick={()=>setfilter({...filter,attendance_finalized:!filter.attendance_finalized})} size={20} /> :
                  <BiCheckboxSquare style={{marginRight:'10px',color:'##96a9b5'}} onClick={()=>setfilter({...filter,attendance_finalized:!filter.attendance_finalized})} size={20}  />} */}
                  <div style={{width:'60px',padding:'3px 0px',border:'1px solid #ddd',marginRight:'10px'}}>
                  <Select placeholder="Year" bordered={false} value={filter?.year == '' ? null : filter?.year}  showArrow={false} style={{width:'80px',fontSize:'14px',fontWeight:'800'}} size='small' options={year} onSelect={(v)=>{setfilter({...filter,year:year?.find(y=>y.value === v)?.label})}} /> 
                  </div>
                  <div style={{width:'80px',padding:'3px 0px',border:'1px solid #ddd',marginRight:'10px'}}>
                  <Select placeholder="Month" bordered={false} value={filter?.month == '' ? null : filter?.month}  showArrow={false} style={{width:'80px',fontSize:'14px',fontWeight:'800'}} size='small' options={month} onSelect={(v)=>{setfilter({...filter,month:v})}} /> 
                  </div>
                  <div style={{width:'160px',padding:'0px 0px',border:'1px solid #ddd',marginRight:'10px'}}>
                  <Select 
                    showSearch
                    bordered={false}
                    style={{ width: '100%',boxShadow:'none' }}
                    value={filter?.employee === '' ? null : filter?.employee?.label}
                    options={employeeArr}
                    placeholder="Search Employee"
                    onChange={(v)=>handleChange1(v,'employee')}
                    onSearch={filterEmployee}
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                  />
                  </div>

                  <AiOutlineSearch  size={30} onClick={applyFilter} style={{background:'#eee' ,padding:'6px',marginRight:'10px'}}/>
                  <GrPowerReset  size={30} onClick={resetFilter} style={{background:'#eee' ,padding:'6px',marginRight:'10px'}}/>
                  {datas_selected?.length > 0 && <AiOutlineFilter   onClick={()=>{setmodal(true);setstep(2)}} size={30} style={{background:'#eee' ,padding:'6px',marginRight:'10px'}}/>}
                  {<BsFilterSquare onClick={()=>{setmodal(true);setstep(3)}} size={30} style={{background:'#eee' ,padding:'6px'}}/>}
                {/* {datas_selected?.length > 0 && <SecondaryButton btn_name={'Mass Update'} onClick={()=>navigate('/fms/ticket/create')} />}  */}
                </div> 
            </div>

            <div style={{marginTop:'10px',display:'flex',flexDirection:'row',flexWrap:'wrap'}}>
              {data?.map((d)=>(
              <div span={4} style={{border:'1px solid #eee',width:'16.3%',margin:'2px',padding:'4px 10px',borderLeft:d?.attendance_finalized ? '3px solid #587487' : '1px solid #eee',position:'relative'}}>
                 <div style={{display:'flex',alignItems:'center',background:'white',position:'absolute',top:0,right:0}}>
                    <AiOutlineFileDone size={12}  onClick={()=>{setselected_data(d);setmodal(true);setstep(1)}} />
                    {datas_selected?.filter((f)=>f?.id === d?.id)?.length === 0 ? 
                    <BiCheckbox style={{color:'#aaa'}} onClick={()=>select_mass_data(d)} size={16} /> :
                    <BiCheckboxSquare style={{color:'##96a9b5'}} onClick={()=>select_mass_data(d)} size={16}  />}
                    </div>
                  <h6 style={{fontSize:'10.5px',marginBottom:'4px',marginTop:'10px'}}>{d?.name} / <span style={{fontWeight:'700'}}>{d?.employee_id}</span></h6>
                  <h6 style={{fontSize:'9px'}}>Month / Year :  <span style={{fontWeight:'700'}}>{month[d?.month-1]?.value} / {d?.year}</span> </h6>
              </div>
              ))}
            </div>

            <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
                {pagination?.total > pagination?.limit &&
                  <Pagination current={page} size="small" total={pagination?.total} onChange={(v) => setpagefuun(v)}  pageSize={pagination?.limit} />
                }
            </div>

        </div>
    </FmsDashboardMainRoot>
  )
}

export default AttendanceOptionBaseList