import React,{useState} from 'react'
import { Input } from 'antd';
import { Button } from 'antd';
import Inputerror from '../../../components/errors/Inputerror';


function Forgot(){

    const [email,setemail] = useState('')
    const [emailerr,setemailerr] = useState('')
     
    const regex =new RegExp(
        '^(([^<>()\\[\\]\\\\.,;:\\s@]+(\\.[^<>()\\[\\]\\\\.,;:\\s@]+)*)|(.+))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
    )

    function handleChange(e){
        setemail(e.target.value)
    }


    function send_verification_link(){
          if(!email){
              setemailerr('FCPL Email id is required')
          }else if(!regex.test(email)){
              setemailerr('Enter a valid email id')
          }else{
              setemailerr('')
              console.log('email validation successfully')
          }
    }


    return(
        <div style={{display:'grid',background:'white',width:'100%',height:'100vh'}}>
            <div style={{background:'white',display:'flex',width:'100%',margin:'auto'}} > 
                <div style={{width:'100%'}} className="p-3" >
                <h4>Forgot Password</h4>
                <h6>Enter your registered email address, to change your FCPL CRM account password.</h6>
                <Input size="large" placeholder="Enter Your official Email Address" class="input" className="mt-3" onChange={handleChange} />
                <Inputerror message={emailerr} />
                <Button type="primary" className="mt-4" size={'large'} onClick={()=>send_verification_link()} block>SEND LINK</Button>
                </div>  
            </div>
            {/* <p type="link" size={'large'} className="text-center mt-0 fw-medium" style={{color:'#589afc',cursor:'pointer'}} onClick={()=>window.location.pathname = "/login"} >Remember Password ?Login</p> */}

            <p style={{width:'100%',fontSize:'12px',textAlign:'center',bottom:'10px',position:'absolute'}}>© 2022, Fidelitus Corp Pvt. Ltd. All Rights Reserved.</p>

        </div>
    )
}


export default Forgot