import React, { useState } from 'react'
import { DatePicker, Input } from 'antd'
import { Drawer } from 'antd';
import DropDown from '../inputcomponents/DropDown';
import axios from "axios";
import { authHeader } from "../../helpers/usertoken/UserToken";
import InputField from "../inputcomponents/InputField";
import moment from "moment";
import PrimaryButton from '../buttons/PrimaryButton';
import { Row, Col } from 'antd'
import { IoTrashBin } from 'react-icons/io5'
import Inputerror from '../errors/Inputerror';
import { BsPlusCircleFill } from 'react-icons/bs'
import { AiFillMinusCircle } from 'react-icons/ai'
import { IoMdClose } from 'react-icons/io';
import { data_not_found } from '../../helpers/Constants';
import toast from 'react-hot-toast';
const { TextArea } = Input;

function ReminderMobile({ visible, func, id, type, getreminderfun, reminder }) {


    const [stage, setstage] = useState('1')
    const [data, setdata] = useState({ type: type, type_id: id, title: '', description: '', on_date_time: '', repeat: '', notify: '' })
    const [dataerr, setdataerr] = useState({ on_date_time_err: {}, repeat_type_err: {}, notify_err: {}, type_err: {}, title_err: {} })


    const colors = ['#00b2f2', '#c75000', '#29cf5e', '#a148b5', '#f0a762', '#c979b0']



    const repeatData = [
        {
            'id': 'Once',
            'value': 'Once'
        },

    ]
    const notifyData = [
        {
            'id': 'Email',
            'value': 'Email'
        },
    ]
    function addReminder() {
        if (!data.title) {
            setdataerr({ ...dataerr, title_err: { error: true, message: 'This field is required' } })
        } else if (!data.on_date_time) {
            setdataerr({ ...dataerr, on_date_time_err: { error: true, message: 'This field is required' } })
        } else if (!data.repeat) {
            setdataerr({ ...dataerr, repeat_type_err: { error: true, message: 'This field is required' } })
        } else if (!data.notify) {
            setdataerr({ ...dataerr, notify_err: { error: true, message: 'This field is required' } })
        } else {
            if (data.type === 'assign_lead' || data.type === 'assign_lead1' || data.type === 'assign_lead2') {
                data.type = 'lead'
            }
            let reminderData = {
                'type': data.type === 'Shared To Me' ? 'Contact' : data.type,
                'type_id': id,
                'title': data.title,
                'description': data.description,
                'on_date_time': data.on_date_time,
                'repeat': data.repeat,
                'notify': data.notify
            }



            axios.post(`reminder/create`, reminderData, authHeader).then((res) => {
                toast.success('Reminder created successfully')
                setdata({ ...data, title: '', description: '', on_date_time: '', repeat: '', notify: '' })
                getreminderfun(id)
                setstage('1')
            }).catch((err) => {
                // console.log('err na here', err.response.data)
            })
        }
    }

    function disabledDate(current) {
        return current && current < moment().startOf('day');

    }

    function handleChange({ key, value }) {
        if (key === 'title') {
            setdataerr({ ...dataerr, title_err: {} })
        } else if (key === 'on_date_time') {
            setdataerr({ ...dataerr, on_date_time_err: {} })
        } else if (key === 'repeat') {
            setdataerr({ ...dataerr, repeat_type_err: {} })
        } else if (key === 'notify') {
            setdataerr({ ...dataerr, notify_err: {} })
        }
        setdata({ ...data, [key]: value })
    }

    function selectedvalue(v) {
        if (v?.id) {
            setdataerr({ ...dataerr, [v.nameerr]: {} })
            setdata({ ...data, [v.name]: v.id })
        }
    }

    function setfalseremindar() {
        func()

    }

    function handledatechange(v, v1) {
        setdataerr({ ...dataerr, on_date_time_err: {} })
        setdata({ ...data, on_date_time: v1 })
    }

    function handleok() {
        // console.log('handleok', v, v1)
    }

    function updatereminder(v) {
        const send_data = {}
        send_data['status'] = false
        axios.put(`reminder/update/${v}`, send_data, authHeader)
            .then((res) => {
                if (res.data.status) {
                    getreminderfun(id)
                }
            })
    }



    return (
        <>
            {/* <Modal
            centered
            visible={visible}
            footer={false}
            onOk={() => addReminder()}
            onCancel={()=>{stage === '1' ? setfalseremindar() : setstage('1')}}
            style={{maxWidth:'350px'}}
        >
            <div className='row' style={{width:'330px',maxHeight:'380px'}}>

               {stage === '1' ? 
               <div>
                 {reminder?.length > 0 ?
                   <div style={{maxHeight:'350px'}}>
                    <h6 style={{fontSize:'14px',fontWeight:'800'}}>Reminder added ({reminder.length})</h6>
                        <div style={{maxHeight:'300px',minHeight:'180px',maxHeight:'310px',overflowY:'scroll'}}>

                            <Row>
                    {reminder?.map((r)=>(

                                <Col span={12} style={{marginBottom:'5px'}}  key={(r?.id !== null || r?.id !== undefined || r?.id !== '') ? r?.id : r?._id} >
                                    <div style={{background:`${colors[Math.floor(Math.random() * colors.length)]}`,padding:'10px',borderRadius:'4px',position:'relative',marginBottom:'5px',width:'96%',margin:'auto',opacity:r.isActive ? 1 : 0.5}}>
                                    {r.isActive && <IoTrashBin size={18} style={{position:'absolute',right:'10px',top:'10px'}} onClick={()=>updatereminder(r._id)} />}
                                    <h6 style={{fontSize:'14px',fontWeight:'800'}}>{r.title}</h6>
                                    <h6 style={{fontSize:'12px'}}>{r.description}</h6>
                                    <p style={{fontSize:'10px',fontWeight:'600',border:'1px solid black',marginBottom:'0px',width:'fit-content',padding:'2px',borderRadius:'2px'}}>{moment(r.on_date_time).format('L, h:mm:ss a')}</p>
                                    </div>
                                </Col>
                    ))}

                            </Row>
                        </div>
                 </div>
                 :
                 <div style={{display:'grid',justifyContent:'center',alignItems:'center',marginTop:'20px',minHeight:'200px'}}>
                    <img src={"https://fidecrms3bucket.s3.amazonaws.com/nodatafound.svg"} alt="nodatafouund" style={{margin:'auto',width:'130px',objectFit:'contain'}} />
                    <h4 style={{fontSize:'15px',fontWeight:'700',marginTop:'10px',textAlign:'center'}}>{ "You don't have any reminder's created."}</h4>     
                    <h6 style={{color:'#747474',fontSize:'12px',maxWidth:'80%',textAlign:'center',margin:'auto',marginTop:'-15px'}}>{ "Click on the below add reminder button to create a new reminder."}</h6>
                 </div> 

                 }
                 
                 <div style={{display:'flex',position:'absolute',cursor:'pointer',right:'10px',bottom:'2px',background:'white',alignItems:'center',marginTop:'0px'}} onClick={()=>setstage('2')}>
                     <BiPlus color="#00b2f2" />
                     <p style={{color:'#00b2f2',marginBottom:'0px'}}>Add Reminder</p>
                 </div>
                </div> 
                :
                <>
                <h5 style={{fontSize:'16px',fontWeight:'600'}}>Add Reminder</h5>

                <div className="col-md-12 mt-3">
                    <h6 style={{fontSize: '14px'}}>Title</h6>
                    <InputField
                        placeholder="Enter title"
                        type="text"
                        priority="high"
                        error_obj={dataerr.title_err}
                        onChange={handleChange}
                        name={'title'}
                        value={data.title}
                    />
                </div>

                <div className="col-md-12 mt-3">
                    <h6 style={{fontSize: '14px'}}>Description</h6>
                    <TextArea
                        priority="high"
                        rows={4}
                        onChange={(e) => setdata({...data, description: e.target.value})}
                        name={'description'}
                        style={{height:'40px',maxHeight:'40px'}}
                        value={data.description}
                        error_obj={dataerr.description_err}
                    />
                </div>

                <div className="col-md-6 mt-3">
                    <div style={{margin: '0px 0px', width: '100%', alignItems: 'center'}}>
                        <h6 style={{fontSize: '14px'}}>Date</h6>
                        <DatePicker
                            showTime
                            disabledDate={disabledDate}
                            error_obj={dataerr.on_date_time_err}
                            onChange={handledatechange} onOk={handleok}
                            format="YYYY-MM-DD HH:mm"
                            value={data.on_date_time !== '' ? moment(data.on_date_time) : null}
                            size={'medium'}
                            style={{width:'100%',borderLeft:'4px solid #292961',borderRadius:'4px',marginTop:'1px'}}
                        />
                        <Inputerror message={dataerr.on_date_time_err.message} />
                    </div>
                </div>

                <div className="col-md-6 mt-3">
                    <h6 style={{fontSize: '14px'}}>Repeat Type</h6>
                    <DropDown
                        onChange={(v) => selectedvalue(v)}
                        priority={'high'}
                        name='repeat'
                        nameerr='repeat_type_err'
                        value={data.repeat}
                        error_obj={dataerr.repeat_type_err}
                        dropdownValue={data.repeat}
                        options={repeatData}
                    />
                </div>

                <div className="col-md-6 mt-3">
                    <h6 style={{fontSize: '14px'}}>Notify</h6>
                    <DropDown
                        onChange={(v) => selectedvalue(v)}
                        priority={'high'}
                        name='notify'
                        nameerr='notify_err'
                        value={data.notify}
                        error_obj={dataerr.notify_err}
                        dropdownValue={data.notify}
                        options={notifyData}
                    />
                </div>

                <div onClick={()=>addReminder()} style={{display:'flex',justifyContent:'flex-end'}}>
                <PrimaryButton btn_name="Add Reminder" />
                </div>
                </>}
            </div>
        </Modal> */}

            <Drawer placement="bottom" visible={visible} onClose={() => setfalseremindar()} height={'300px'} width={'100%'} closable={false} maskClosable={false} bodyStyle={{ background: '#fff', margin: '5%', padding: '0px',maxWidth:'100%' }}>

                {stage === '1' && <div style={{ display: 'flex',justifyContent:'space-between', marginBottom: '5px',background:'#fff',zIndex:100 }}>
                    <h6 style={{ opacity: 1, fontWeight: '900', display: 'flex' }}>Reminders ({reminder?.length})</h6>
                   <IoMdClose onClick={() => setfalseremindar()} size={22} style={{right: '0px', top: '10px' }} />
                      
                </div>}
                {stage === '1' ?
                    <div style={{marginTop:'10px'}}>
                        {reminder?.length > 0 ?
                            <div style={{ maxHeight: '350px', marginLeft: '-2px' }}>
                                <div style={{  minHeight: '180px', maxHeight: '310px', overflowY: 'scroll' }}>

                                    <Row>
                                        {reminder?.map((r) => (

                                            <Col span={12} style={{ marginBottom: '5px' }} key={(r?.id !== null || r?.id !== undefined || r?.id !== '') ? r?.id : r?._id} >
                                                <div style={{ background: `${colors[Math.floor(Math.random() * colors.length)]}`, padding: '10px', borderRadius: '4px', position: 'relative', marginBottom: '5px', width: '96%', margin: 'auto', opacity: r.isActive ? 1 : 0.5 }}>
                                                    {r.isActive && <IoTrashBin size={18} style={{ position: 'absolute', right: '10px', top: '10px' }} onClick={() => updatereminder(r._id)} />}
                                                    <h6 style={{ fontSize: '14px', fontWeight: '800', maxWidth: '80%' }}>{r.title}</h6>
                                                    <h6 style={{ fontSize: '12px' }}>{r?.description?.slice(0, 40)}{r?.description?.length > 40 && '...'}</h6>
                                                    <p style={{ fontSize: '10px', fontWeight: '600', border: '1px solid black', marginBottom: '0px', width: 'fit-content', padding: '2px', borderRadius: '2px' }}>{moment(r.on_date_time).format('L, h:mm:ss a')}</p>
                                                </div>
                                            </Col>
                                        ))}

                                    </Row>
                                </div>
                            </div>
                            :
                            <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '20%', minHeight: '200px' }}>
                                <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '130px', objectFit: 'contain' }} />
                                <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center' }}>{"You don't have any reminder's created."}</h4>
                                <h6 style={{ color: '#747474', fontSize: '12px', maxWidth: '80%', textAlign: 'center', margin: 'auto', marginTop: '-15px' }}>{"Click on the below add reminder button to create a new reminder."}</h6>
                            </div>

                        }
                    </div>
                    :
                    <div style={{width:'100%',maxWidth:'100%',overflow:'hidden'}}>
                        <div style={{ display: 'flex',justifyContent:'space-between', marginBottom: '5px',background:'#fff',zIndex:100 }}>
                        <h5 style={{ fontSize: '16px', fontWeight: '600' }}>Add Reminder</h5>
                        <IoMdClose onClick={() => setfalseremindar()} size={22} />
                        </div> 

                        <div className='row'>
                            <div className="col-6 mt-3">
                                <h6 style={{ fontSize: '14px' }}>Title</h6>
                                <InputField
                                    placeholder="Enter title"
                                    type="text"
                                    priority="high"
                                    size={'medium'}
                                    error_obj={dataerr.title_err}
                                    onChange={handleChange}
                                    name={'title'}
                                    value={data.title}
                                />
                            </div>

                            <div className="col-6 mt-3">
                                <h6 style={{ fontSize: '14px' }}>Description</h6>
                                <TextArea
                                    priority="high"
                                    rows={4}
                                    onChange={(e) => setdata({ ...data, description: e.target.value })}
                                    name={'description'}
                                    style={{ height: '30px', maxHeight: '30px' }}
                                    value={data.description}
                                    error_obj={dataerr.description_err}
                                />
                            </div>

                            <div className="col-6 mt-3">
                                <div style={{ margin: '0px 0px', width: '100%', alignItems: 'center' }}>
                                    <h6 style={{ fontSize: '14px' }}>Date</h6>
                                    <DatePicker
                                        showTime
                                        disabledDate={disabledDate}
                                        error_obj={dataerr.on_date_time_err}
                                        onChange={handledatechange} onOk={handleok}
                                        format="YYYY-MM-DD HH:mm"
                                        value={data.on_date_time !== '' ? moment(data.on_date_time) : null}
                                        size={'medium'}
                                        style={{ width: '100%', borderLeft: '4px solid #292961', borderRadius: '4px', marginTop: '1px' }}
                                    />
                                    <Inputerror message={dataerr.on_date_time_err.message} />
                                </div>
                            </div>

                            <div className="col-6 mt-3">
                                <h6 style={{ fontSize: '14px' }}>Repeat Type</h6>
                                <DropDown
                                    onChange={(v) => selectedvalue(v)}
                                    priority={'high'}
                                    name='repeat'
                                    nameerr='repeat_type_err'
                                    value={data.repeat}
                                    error_obj={dataerr.repeat_type_err}
                                    dropdownValue={data.repeat}
                                    options={repeatData}
                                />
                            </div>

                            <div className="col-6 mt-3">
                                <h6 style={{ fontSize: '14px' }}>Notify</h6>
                                <DropDown
                                    onChange={(v) => selectedvalue(v)}
                                    priority={'high'}
                                    name='notify'
                                    nameerr='notify_err'
                                    value={data.notify}
                                    error_obj={dataerr.notify_err}
                                    dropdownValue={data.notify}
                                    options={notifyData}
                                />
                            </div>

                            <div onClick={() => addReminder()} style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '10px' }}>
                                <PrimaryButton padding={'9px 15px'} btn_name="Add Reminder" />
                            </div>
                        </div>
                    </div>}

                {stage === '1' ?
                    <BsPlusCircleFill size={50} style={{ position: 'absolute', right: 20, bottom: 30, color: '#e6e6e6', opacity: 0.6 }} onClick={() => setstage('2')} /> :
                    <AiFillMinusCircle size={50} style={{ position: 'absolute', right: 20, bottom: 30, color: '#e6e6e6', opacity: 0.6 }} onClick={() => setstage('1')} />}
            </Drawer>

        </>
    )
}

export default ReminderMobile;
