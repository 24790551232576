import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Select, DatePicker, Input } from 'antd'
import makeAnimated from 'react-select/animated';
import {AiOutlineInfoCircle} from 'react-icons/ai';
import { SmallText } from '../../../helpers/constants/ConstantsText'
import axios from 'axios';
import { authHeader } from '../../../helpers/usertoken/UserToken';
import FmsDashboardMainRoot from '../FmsDashboardMainRoot';
import TextArea from 'antd/lib/input/TextArea'
import PrimaryButton, { SecondaryButton } from '../../../components/buttons/PrimaryButton';
import { useLocation, useNavigate } from 'react-router-dom';
import { Toaster, toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';

function TicketsCE() {
  const animatedComponents = makeAnimated();

  const {state,pathname} = useLocation()
  const navigator = useNavigate()
  const token = useSelector(state=>state.Auth.access_token)


  const AuthHeader = {headers:{
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
  }}

 

  const path = pathname?.split('/')[pathname?.split('/')?.length - 1]
  const roles = useSelector(state=>state.Auth.roles)
  const [data,setdata] = useState({
    report_date:'',
    resolve_date:'',
    client:'',
    field_officer:'',
    message:'',
    raised_by:'',
    escalted_by:'',
    remarks:'',
    resolution:'',
    status:'',
    via:'',
    via1:'',
  })  
  const [clients,setclients] = useState([])  
  const [users,setusers] = useState([])


  async function searchuser(v){ 
    if(data?.assigned_user_type !== undefined){
        if(data?.assigned_user_type?.label === 'InHouse-Employee'){
            await axios.get(`fms/fms_in_house_employee/search?search=${v}`,AuthHeader)
            .then((res)=>{
                let arr = []
                res?.data?.datas?.forEach(d => {
                    arr.push({label:`${d?.name}[Employee Id : ${d?.employee_id}]`,value:d?._id})
                });
                setusers(arr)
                return arr
            }).catch((err)=>{
            })
        }else if(data?.assigned_user_type?.label === 'Deployed Employee'){{
            await axios.post(`fms/basicemployee/search?search_text=${v}`,{},AuthHeader)
            .then((res)=>{
                // console.log("res?.data",res?.data)
                let arr = []
                res?.data?.data?.forEach(d => {
                    arr.push({label:`${d?.name}[Employee Id : ${d?.employee_id}]`,value:d?.id})
                });
                setusers(arr)
                return arr
            }).catch((err)=>{
            })
        }
   
    }
   }
}

   async function searchData(v){ 
    await axios.get(`fms/fmsclients/search?search_text=${v}`,AuthHeader)
     .then((res)=>{
         let arr = []
         res?.data?.datas?.forEach(d => {
             arr.push({label:`${d?.site_name}[client name : ${d?.client_name}]`,value:d?._id})
         });
       
         setclients(arr)
         return arr
     }).catch((err)=>{
     })
   }


 
    const [options,setoptions] = useState([{label:'Phone',value:'Phone'},{label:'Email',value:'Email'},{label:'Whatsapp',value:'Whatsapp'},{label:'Application',value:'Application'},{label:'Other',value:'Other'}])

    const [error,seterror] = useState({
        report_date:'',
        resolve_date:'',
        client:'',
        field_officer:'',
        message:'',
        raised_by:'',
        escalted_by:'',
        remarks:'',
        resolution:'',
        status:''
    })  

    useEffect(()=>{
    
        if(path === 'edit'){

        if(options?.find((f)=>f?.label === state?.via) !== undefined){

            let send_Data = {
                ...data,
                ...state,
                via:options?.find((f)=>f?.label === state?.via),
                assigned_user_type:{label:state?.assigned_user_type,value:state?.assigned_user_type},
                raised_user_type:{label:state?.raised_user_type,value:state?.raised_user_type},
                status:status?.find((f)=>f?.label === state?.status),
                resolve_date:state?.resolve_date !== null ? state?.resolve_date : '',
                client:{label:`${state?.client?.site_name}[client name : ${state?.client?.client_name}]`,value:state?.client?._id}
            }


            if(state?.assigned_user_type !== ''){
                if(state?.assigned_user_type === 'InHouse-Employee'){
                    send_Data['field_officer'] = {label:`${state?.field_officer?.name}[Employee Id : ${state?.field_officer?.employee_id}]`,value:state?.field_officer?._id} 
                }else if(state?.assigned_user_type === 'Deployed Employee'){
                    send_Data['field_officer'] = {label:`${state?.deployed_officer?.name}[Employee Id : ${state?.deployed_officer?.employee_id}]`,value:state?.deployed_officer?._id} 
                }
            }

            console.log("state",state)

            setdata({...send_Data})
        }else{

            let send_Data = {
                ...data,
                ...state,
                via:options?.find((f)=>f?.label === state?.via),
                assigned_user_type:{label:state?.assigned_user_type,value:state?.assigned_user_type},
                raised_user_type:{label:state?.raised_user_type,value:state?.raised_user_type},
                status:status?.find((f)=>f?.label === state?.status),
                resolve_date:state?.resolve_date !== null ? state?.resolve_date : '',
                client:{label:`${state?.client?.site_name}[client name : ${state?.client?.client_name}]`,value:state?.client?._id}
            }


            if(state?.assigned_user_type !== ''){
                if(state?.assigned_user_type === 'InHouse-Employee'){
                    send_Data['field_officer'] = {label:`${state?.field_officer?.name}[Employee Id : ${state?.field_officer?.employee_id}]`,value:state?.field_officer?._id} 
                }else if(state?.assigned_user_type === 'Deployed Employee'){
                    send_Data['field_officer'] = {label:`${state?.deployed_officer?.name}[Employee Id : ${state?.deployed_officer?.employee_id}]`,value:state?.deployed_officer?._id} 
                }
            }

            send_Data['via'] = state?.via !== 'Other' ? state?.via : options?.find((f)=>f?.label === 'Other')
            send_Data['via1'] = state?.via1
            send_Data['resolve_date'] = state?.resolve_date !== null ? state?.resolve_date : ''

            setdata({...send_Data})
        } 
        
        }else{
        setdata({
            report_date:'',
            resolve_date:'',
            client:'',
            field_officer:'',
            message:'',
            raised_by:'',
            escalted_by:'',
            remarks:'',
            resolution:'',
            status:''
        })
        seterror({
            report_date:'',
            resolve_date:'',
            client:'',
            field_officer:'',
            message:'',
            raised_by:'',
            escalted_by:'',
            remarks:'',
            resolution:'',
            status:'',
        })
        }
    },[])

   const status = [{label:'Pending',value:'Pending'},{label:'In Progress',value:'In Progress'},{label:'Resolved',value:'Resolved'}]
   const raised_user_type = [{label:'InHouse-Employee',value:'InHouse-Employee'},{label:'Deployed Employee',value:'Deployed Employee'},{label:'Client',value:'Client'}]
   const assigned_user_type = [{label:'InHouse-Employee',value:'InHouse-Employee'},{label:'Deployed Employee',value:'Deployed Employee'},{label:'Client',value:'Client'}]


   function handleChange1(v,name){   
        if(name === 'via'){
            setdata({...data,[name]:options?.find((v1)=>v1?.label === v)})
            seterror({...error,[name]:''})
        }else if(name === 'status'){
            setdata({...data,[name]:status?.find((v1)=>v1?.label === v)})
            seterror({...error,[name]:''})
        }else if(name === 'raised_user_type'){
            setdata({...data,[name]:raised_user_type?.find((v1)=>v1?.label === v)})
            seterror({...error,[name]:''})
        }else if(name === 'assigned_user_type'){
            setdata({...data,[name]:assigned_user_type?.find((v1)=>v1?.label === v)})
            seterror({...error,[name]:''})
        }
   }

  function submitData(){
                
    if((roles?.includes('fms_user') || roles?.includes('fms_ticket'))){
        if(data?.client?.value === undefined){
            seterror({...error,client:'This field is required*'})
        }else if(!data.report_date){
            seterror({...error,report_date:'This field is required*'})
        }else if(!data.message){
            seterror({...error,message:'This field is required*'})
        }else if(!data.raised_by){
            seterror({...error,raised_by:'This field is required*'})
        }else if(!data.escalted_by  && path === 'edit'){
            seterror({...error,escalted_by:'This field is required*'})
        }else if(!data.remarks &&  (!roles.includes('admin') && !roles.includes('fms_crm_head') && !roles.includes('fms_it'))  && (roles?.includes('fms_user') || roles?.includes('fms_ticket')) && path === 'edit'){
            seterror({...error,remarks:'This field is required*'})
        }else if(!data.resolve_date && data?.status?.value === 'Resolved'  && path === 'edit'){
            seterror({...error,resolve_date:'This field is required*'})
        }else if(!data.resolution && data?.status?.value === 'Resolved'  && path === 'edit'){
            seterror({...error,resolution:'This field is required*'})
        }else if(!data.via){
            console.log("9")
            seterror({...error,via:'This field is required*'})
        }else{
            if(path === 'create'){
               
                let send_Data = {...data}
                delete send_Data.field_officer
                send_Data['client'] = data?.client?.value
                if(data?.assigned_user_type?.value  === 'InHouse-Employee'){
                    send_Data['field_officer'] = data?.field_officer?.value
                    delete send_Data.deployed_officer
                }
                if(data?.assigned_user_type?.value  === 'Deployed Employee'){
                    send_Data['deployed_officer'] = data?.field_officer?.value
                    delete send_Data.field_officer
                }
                send_Data['status'] = 'Pending'
                send_Data['via'] = data?.via?.label !== 'Other' ? data?.via?.label : data?.via1
                send_Data['raised_user_type'] = data?.raised_user_type?.label
                send_Data['assigned_user_type'] = data?.assigned_user_type?.label



                console.log("sendData",send_Data)  
                axios.post('fms/ticket/create',send_Data,authHeader)
                .then((res)=>{
                    if(res.status === 201){
                        toast.success('Ticket Raised Successfully')
                        resetForm()
                    }
                }).catch((err)=>{
                })
            }else if(path === 'edit'){
                let send_Data = {...data}
                send_Data['client'] = data?.client?.value
                if(data?.assigned_user_type?.value  === 'InHouse-Employee'){
                    send_Data['field_officer'] = data?.field_officer?.value
                    delete send_Data.deployed_officer
                }
                if(data?.assigned_user_type?.value  === 'Deployed Employee'){
                    send_Data['deployed_officer'] = data?.field_officer?.value
                    delete send_Data.field_officer
                }
                send_Data['status'] = 'Pending'
                send_Data['via'] = data?.via?.label !== 'Other' ? data?.via?.label : data?.via1
                send_Data['raised_user_type'] = data?.raised_user_type?.label
                send_Data['assigned_user_type'] = data?.assigned_user_type?.label


                if((roles.includes('fms_crm_head') || roles.includes('admin'))){
                    send_Data['status'] = data?.status?.value === undefined ? data?.status : data?.status?.value
                }else{
                    send_Data['status'] = 'In Progress'
                }    
                // send_Data['field_officer'] = data?.field_officer?.value
                axios.put(`fms/ticket/update/${state?._id}`,send_Data,authHeader)
                .then((res)=>{
                    if(res.status === 200){
                        toast.success('Ticket Updated Successfully')
                        resetForm()
                        navigator(-1)
                    }
                }).catch((err)=>{
                })
            }
        }
    }else if(roles.includes('fms_crm_head') || roles.includes('fms_hr') || roles.includes('admin')){
        if(data?.client?.value === undefined){
            seterror({...error,client:'This field is required*'})
        }else if(!data.report_date){
            seterror({...error,report_date:'This field is required*'})
        }else if(!data.message){
            seterror({...error,message:'This field is required*'})
        }else if(data?.field_officer?.value === undefined){
            seterror({...error,field_officer:'This field is required*'})
        }else if(!data.raised_by){
            seterror({...error,raised_by:'This field is required*'})
        }else if(!data.escalted_by  && path === 'edit'){
            seterror({...error,escalted_by:'This field is required*'})
        }else if(!data.remarks  && path === 'edit'){
            seterror({...error,remarks:'This field is required*'})
        }else if(!data.resolve_date && data?.status?.value === 'Resolved'  && path === 'edit'){
            seterror({...error,resolve_date:'This field is required*'})
        }else if(!data.resolution && data?.status?.value === 'Resolved'  && path === 'edit'){
            seterror({...error,resolution:'This field is required*'})
        }else if(!data.via){
            seterror({...error,via:'This field is required*'})
        }else{
            if(path === 'create'){
                let send_Data = {...data}
                send_Data['client'] = data?.client?.value

                // console.log("assigned_user_type",data?.assigned_user_type)
                // console.log("data?.assigned_user_type == 'InHouse-Employee'",data?.assigned_user_type?.value == 'InHouse-Employee')
                // console.log("data?.assigned_user_type == 'Deployed Employee'",data?.assigned_user_type?.value == 'Deployed Employee')
                
                if(data?.assigned_user_type?.value == 'InHouse-Employee'){
                    send_Data['field_officer'] = data?.field_officer?.value
                    delete send_Data.deployed_officer
                }
                if(data?.assigned_user_type?.value == 'Deployed Employee'){
                    send_Data['deployed_officer'] = data?.field_officer?.value
                    delete send_Data.field_officer
                }
                send_Data['status'] = 'Pending'
                send_Data['via'] = data?.via?.label !== 'Other' ? data?.via?.label : data?.via1
                send_Data['raised_user_type'] = data?.raised_user_type?.label
                send_Data['assigned_user_type'] = data?.assigned_user_type?.label


                // console.log("send_Data annaya",send_Data)
                axios.post('fms/ticket/create',send_Data,authHeader)
                .then((res)=>{
                    if(res.status === 201){
                        toast.success('Ticket Raised Successfully')
                        resetForm()
                    }
                }).catch((err)=>{
                })
            }else if(path === 'edit'){
                let send_Data = {...data}

                console.log("data",data)
                send_Data['client'] = data?.client?.value
                if(data?.assigned_user_type?.value  === 'InHouse-Employee'){
                    send_Data['field_officer'] = data?.field_officer?.value
                    delete send_Data.deployed_officer
                }
                if(data?.assigned_user_type?.value  === 'Deployed Employee'){
                    send_Data['deployed_officer'] = data?.field_officer?.value
                    delete send_Data.field_officer
                }
                send_Data['status'] = 'Pending'
                send_Data['via'] = data?.via?.label !== 'Other' ? data?.via?.label : data?.via1
                send_Data['raised_user_type'] = data?.raised_user_type?.label
                send_Data['assigned_user_type'] = data?.assigned_user_type?.label




                if((roles.includes('fms_crm_head') || roles.includes('admin'))){
                    send_Data['status'] = data?.status?.value === undefined ? data?.status : data?.status?.value
                }else{
                    send_Data['status'] = 'In Progress'
                }    
                // send_Data['field_officer'] = data?.field_officer?.value
                axios.put(`fms/ticket/update/${state?._id}`,send_Data,authHeader)
                .then((res)=>{
                    if(res.status === 200){
                        toast.success('Ticket Updated Successfully')
                        resetForm()
                        navigator(-1)
                    }
                }).catch((err)=>{
                })
            }
        }
    }else{
        toast.error('Ticket Cannot Be Updated At this Stage')
    }    
    // }
  }

  function resetForm(){
     setdata({
        report_date:'',
        resolve_date:'',
        client:'',
        field_officer:'',
        message:'',
        raised_by:'',
        escalted_by:'',
        remarks:'',
        resolution:'',
        status:''
     })
     seterror({
        report_date:'',
        resolve_date:'',
        client:'',
        field_officer:'',
        message:'',
        raised_by:'',
        escalted_by:'',
        remarks:'',
        resolution:'',
        status:''
     })
  }

  function handleChange(v,type){
    if(type === 'client'){
       setdata({...data,client:clients?.find((c)=>c?.value === v)})
    }else if(type === 'user'){
        // console.log("test",users?.find((c)=>c?.value === v))
        setdata({...data,field_officer:users?.find((c)=>c?.value === v)})
    }
  }

  return (
    <FmsDashboardMainRoot>
        <Toaster />
        <div >
            <h6>Create/Edit Tickets Form</h6>
            <h6 style={{fontSize:'10px',background:'#3d7aba',fontWeight:'600',color:'#fff',padding:6,width:'300px'}}>Use the below form to create or update the ticket raised by the clients.</h6>

                <div style={{display:'flex',width:'800px',marginTop:'20px'}}>
                <div style={{width:'350px'}}>
                <SmallText fontWeight={'600'} val={'Client Name'}  />
                <Select showSearch filterOption={false} value={data?.client} style={{borderLeft:'4px solid #292961',width:'100%'}}  options={clients} onSearch={searchData} onChange={(v)=>handleChange(v,'client')}  />

                {/* <Input type="text"  style={{borderLeft:'4px solid #292961',}}  value={data.client} onChange={(e)=>{setdata({...data,client:e.target.value});seterror({...error,client:''})}} /> */}
                <div style={{display:'flex',marginTop:'4px'}}>
                    {(error?.client) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.client}</p>
                </div>

                

                

                


               

                <div  style={{margin:'0px 0px'}}>
                <SmallText marginfun={'0px 0px 10px 0px'} fontWeight={'600'} val={'VOC'}  />
                 <TextArea style={{borderLeft:'4px solid #292961',}} value={data?.message} onChange={(e)=>{setdata({...data,message:e.target.value});seterror({...error,message:''})}} />  
                 <div  style={{display:'flex',marginTop:'4px'}}>
                    {(error?.message) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                    <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.message}</p>
                </div>
                </div> 

               

                

              


                

                {path === 'edit' &&
                <>

                

                <div  style={{margin:'10px 0px'}}>
                <SmallText  marginfun={'10px 0px 10px 0px'} fontWeight={'600'} val={'Remarks'}  />

                 <TextArea style={{borderLeft:'4px solid #292961',}} value={data?.remarks} onChange={(e)=>{setdata({...data,remarks:e.target.value});seterror({...error,remarks:''})}} />  
                 <div style={{display:'flex',marginTop:'4px'}}>
                    {(error?.remarks) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                    <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.remarks}</p>
                </div>
                </div> 

                {data?.status?.value === 'Resolved' &&
                <div  style={{margin:'10px 0px'}}>
                <SmallText marginfun={'10px 0px 10px 0px'} fontWeight={'600'} val={'Resolve Date'}  />
                 {/* <p style={{marginBottom:'8px',fontSize:'14px'}}>Resolve Date</p>   */}
                 <DatePicker 
                    value={data?.resolve_date !== '' ? moment(data.resolve_date) : null}
                    size="small"  
                    placeholder=''
                    format={'YYYY-MM-DD'}  
                    onChange={(v,v1)=>{setdata({...data,resolve_date:v1});seterror({...error,resolve_date:''})}} 
                    style={{width:'100%',padding:'4px',borderLeft:'4px solid #292961',borderRadius:'0px'}} 
                 />
                </div>}
               
                {data?.status?.value === 'Resolved' &&
                <div  style={{margin:'10px 0px'}}>
                <SmallText marginfun={'10px 0px 10px 0px'} fontWeight={'600'} val={'Resolution'}  />

                 {/* <p style={{marginBottom:'8px',fontSize:'14px'}}>rESOLUTION</p> */}
                 <TextArea style={{borderLeft:'4px solid #292961',}} value={data?.resolution} onChange={(e)=>{setdata({...data,resolution:e.target.value});seterror({...error,resolution:''})}} />  
                 <div style={{display:'flex',marginTop:'4px'}}>
                    {(error?.resolution) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                    <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.resolution}</p>
                </div>
                </div>} 

                <div style={{display:'flex',marginTop:'4px'}}>
                    {(error?.resolve_date) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                    <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.resolve_date}</p>
                </div>

                {(roles?.includes('admin') || roles?.includes('fms_crm_head')) &&
                <>
                <SmallText fontWeight={'600'} val={'Ticket Status'}  />


                <div  style={{margin:'0px 0px 10px 0px',zIndex:200,borderLeft:'4px solid #292961'}}>
                <Select 
                   cacheOptions
                    value={data?.status?.label}
                    placeholder="" 
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    onChange={(v)=>handleChange1(v,'status')}
                    options={status}
                    style={{ width: '100%', }}
                />
                
                </div>
                <div style={{display:'flex',marginTop:'4px'}}>
                    {(error?.status) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.status}</p>
                </div>
                </>}
                </>}
                </div> 

                <div style={{width:'350px',marginLeft:'20px'}}>
                <div  style={{margin:'0px 0px'}}>
                <SmallText marginfun={'0px 0px 10px 0px'} fontWeight={'600'} val={'Report Date'}  />

                 <DatePicker 
                    value={data?.report_date !== '' ? moment(data.report_date) : null}
                    size="small"  
                    placeholder=''
                    format={'YYYY-MM-DD'}  
                    onChange={(v,v1)=>{setdata({...data,report_date:v1});seterror({...error,report_date:''})}} 
                    style={{width:'100%',padding:'4px',borderLeft:'4px solid #292961',borderRadius:'0px'}} 
                 />
                </div>
                <div  style={{display:'flex',marginTop:'4px'}}>
                    {(error?.report_date) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                    <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.report_date}</p>
                </div>

                 {['admin','fms_hr','fms_it','fms_crm_head']?.filter((f)=>roles?.includes(f))?.length > 0 &&
                 <>
                <div style={{margin:'10px 0px'}}>
                    <SmallText marginfun={'0px 0px 10px 0px'} fontWeight={'600'} val={'ASSIGNED USER TYPE'}  />
                    <div  style={{margin:'0px 0px 10px 0px',zIndex:200,borderLeft:'4px solid #292961'}}>
                    <Select 
                       cacheOptions
                        value={data?.assigned_user_type?.label}
                        placeholder="" 
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                        onChange={(v)=>handleChange1(v,'assigned_user_type')}
                        options={assigned_user_type}
                        style={{ width: '100%', }}
                    />
                    </div>
                </div>
                

                <SmallText marginfun={'10px 0px 10px 0px'} fontWeight={'600'} val={'Field Officer'}  />
                {/* <Input type="text"  style={{borderLeft:'4px solid #292961',}}  value={data.field_officer} onChange={(e)=>{setdata({...data,field_officer:e.target.value});seterror({...error,field_officer:''})}} /> */}
                <Select showSearch filterOption={false} value={data?.field_officer} style={{borderLeft:'4px solid #292961',width:'100%'}}  options={users} onSearch={searchuser} onChange={(v)=>handleChange(v,'user')}  />
                </>}
                <div  style={{display:'flex',marginTop:'4px'}}>
                    {(error?.field_officer) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                    <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.field_officer}</p>
                </div>

               

                <div style={{margin:'10px 0px'}}>
                    <SmallText marginfun={'0px 0px 10px 0px'} fontWeight={'600'} val={'RAISED USER TYPE'}  />
                    <div  style={{margin:'0px 0px 10px 0px',zIndex:200,borderLeft:'4px solid #292961'}}>
                    <Select 
                    cacheOptions
                        value={data?.raised_user_type?.label}
                        placeholder="" 
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                        onChange={(v)=>handleChange1(v,'raised_user_type')}
                        options={raised_user_type}
                        style={{ width: '100%', }}
                    />
                    </div>
                </div>

                <SmallText marginfun={'5px 0px 10px 0px'} fontWeight={'600'} val={'Raised By'}  />
                <Input type="text"  style={{borderLeft:'4px solid #292961',}}  value={data.raised_by} onChange={(e)=>{setdata({...data,raised_by:e.target.value});seterror({...error,raised_by:''})}} />
                <div  style={{display:'flex',marginTop:'4px'}}>
                    {(error?.raised_by) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                    <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.raised_by}</p>
                </div>

                <SmallText fontWeight={'600'} val={'Via'}  />

               
                <div  style={{margin:'0px 0px 10px 0px',zIndex:200,borderLeft:'4px solid #292961'}}>
               
                <Select 
                cacheOptions
                    value={data?.via}
                    placeholder="" 
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    onChange={(v)=>handleChange1(v,'via')}
                    options={options}
                    style={{ width: '100%', }}
                />
                
                
                </div>

                {data?.via?.label === 'Other' &&
                <Input type="text"  style={{borderLeft:'4px solid #292961',marginBottom:'10px'}}  value={data.via1} onChange={(e)=>{setdata({...data,via1:e.target.value});seterror({...error,via1:''})}} />
                } 

                <div style={{display:'flex',marginTop:'4px'}}>
                    {(error?.status) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.status}</p>
                </div>

                {/* {path === 'edit' &&
                <> */}

                <SmallText marginfun={'0px 0px 10px 0px'} fontWeight={'600'} val={'Escalted By'}  />
                <Input type="text"  style={{borderLeft:'4px solid #292961',}}  value={data.escalted_by} onChange={(e)=>{setdata({...data,escalted_by:e.target.value});seterror({...error,escalted_by:''})}} />
                <div  style={{display:'flex',marginTop:'4px'}}>
                    {(error?.escalted_by) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                    <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.escalted_by}</p>
                </div>
                {/* </>} */}

                </div>
                </div>

                {!roles?.includes('fms_data_view') &&
                <div style={{display:'flex',flexDirection:'row'}}>
                 <PrimaryButton btn_name={'Save'} onClick={submitData}/>
                 <h6 style={{width:'5px'}}></h6>
                 <SecondaryButton btn_name={'Cancel'} onClick={()=>navigator(-1)} />
                </div>}



        </div>
    </FmsDashboardMainRoot>
  )
}

export default TicketsCE