import { useLocation,Navigate,Outlet } from "react-router-dom";
import {useSelector} from 'react-redux'

function RequireAuth({allowedRoles}){
    const login = useSelector(state=>state.Auth.login)
    const roles = useSelector(state=>state.Auth.roles)
    const location = useLocation()

    return(
        (roles.find(role=>allowedRoles?.includes(role)) && login )
        ? 
        <>
        <Outlet /> 
        </>
        : 
        login ?
        (
        <Navigate to="/permission_not_provided" state={{from:location}} replace />
        )
        :
        (
        <Navigate to="/login" state={{from:location}} replace />
        )
         
    )
}

export default RequireAuth