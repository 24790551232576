import React from 'react'
import { Card,Row,Col } from 'antd';
import {useNavigate,useLocation} from 'react-router-dom'
import {RiBuildingLine} from 'react-icons/ri'
import {MdOutlineMarkEmailUnread} from 'react-icons/md'
import {GiRotaryPhone} from 'react-icons/gi'
import {GrLocation} from 'react-icons/gr'
import {HiOutlineClipboard} from 'react-icons/hi'
import {FiUser} from 'react-icons/fi'
import {BiPhone} from 'react-icons/bi'
import {FaGraduationCap} from 'react-icons/fa'
import { Steps } from 'antd';
import moment from 'moment'

const { Step } = Steps;
function TrackLead() {

  const navigate = useNavigate()
  const {state} = useLocation()
  

  console.log('location',state)
  
  return (
    <div style={{paddingTop:'80px',background:'#f4f5f7',minHeight:'100vh'}}>

          <h6 style={{marginLeft:'10%',fontSize:'20px',fontWeight:'bolder',marginBottom:'40px'}}>Track Lead status {state.datas.lead_datas[0].lead_id.lead_name}  </h6>
          <Row style={{width:'80%',margin:'auto',}}>
            <Col span={6}>
              <div style={{ width: 300,background:'white',padding:'10px',boxShadow: '0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%)',border: '2px solid transparent',borderRadius:'2px',marginBottom:'20px' }}>
                <h6 style={{fontWeight:'800',color:'rgb(41 41 97)'}}>Company Information</h6>
                
                <div style={{display:'flex',alignItems:'center',marginLeft:'10px',marginBottom:'5px'}}>
                  <RiBuildingLine size={20} />
                  <p style={{marginBottom:'0px',marginLeft:'10px'}}>{state.datas.lead_datas[0].lead_id.contact_id.company_name}</p>
                </div>

                <div style={{display:'flex',alignItems:'center',marginLeft:'10px',position:'relative',marginBottom:'5px'}}>
                  <MdOutlineMarkEmailUnread size={20} />
                  <p style={{marginBottom:'0px',marginLeft:'10px'}}>{state.datas.lead_datas[0].lead_id.contact_id.company_email}</p>
                  <HiOutlineClipboard style={{width:'20px',position:'absolute',right:'10px'}} />
                </div>

                <div style={{display:'flex',alignItems:'center',marginLeft:'10px',position:'relative',marginBottom:'5px'}}>
                  <GiRotaryPhone size={20} />
                  <p style={{marginBottom:'0px',marginLeft:'10px'}}>{state.datas.lead_datas[0].lead_id.contact_id.company_phone}</p>
                  <HiOutlineClipboard style={{width:'20px',position:'absolute',right:'10px'}} />
                </div>

                <div style={{display:'flex',alignItems:'center',marginLeft:'10px',marginBottom:'5px'}}>
                  <GrLocation size={20} />
                  <p style={{marginBottom:'0px',marginLeft:'10px'}}>{state.datas.lead_datas[0].lead_id.contact_id.company_name}</p>
                </div>
               <p style={{position:'relative',textAlign:'right',marginBottom:'0px',fontSize:'12px'}}>Created at <strong>{moment(state.datas.lead_datas[0].lead_id.contact_id.createdAt).format('LL')}</strong></p>
                
              </div>

              <div style={{ width: 300,background:'white',padding:'10px',boxShadow: '0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%)',border: '2px solid transparent',borderRadius:'2px',marginBottom:'20px'  }}>
                <h6 style={{fontWeight:'800',color:'rgb(41 41 97)'}}>Contact Person 1</h6>
                
                <div style={{display:'flex',alignItems:'center',marginLeft:'10px',marginBottom:'5px'}}>
                  <FiUser size={20} />
                  <p style={{marginBottom:'0px',marginLeft:'10px'}}>{state.datas.lead_datas[0].lead_id.contact_id.contact_name}</p>
                </div>

                <div style={{display:'flex',alignItems:'center',marginLeft:'10px',position:'relative',marginBottom:'5px'}}>
                  <BiPhone size={20} />
                  <p style={{marginBottom:'0px',marginLeft:'10px'}}>{state.datas.lead_datas[0].lead_id.contact_id.phone}</p>
                  <HiOutlineClipboard style={{width:'20px',position:'absolute',right:'10px'}} />
                </div>

                <div style={{display:'flex',alignItems:'center',marginLeft:'10px',position:'relative',marginBottom:'5px'}}>
                  <MdOutlineMarkEmailUnread size={20} />
                  <p style={{marginBottom:'0px',marginLeft:'10px'}}>{state.datas.lead_datas[0].lead_id.contact_id.email}</p>
                  <HiOutlineClipboard style={{width:'20px',position:'absolute',right:'10px'}} />
                </div>

                <div style={{display:'flex',alignItems:'center',marginLeft:'10px',marginBottom:'5px'}}>
                  <FaGraduationCap size={20} />
                  <p style={{marginBottom:'0px',marginLeft:'10px'}}>{state.datas.lead_datas[0].lead_id.contact_id.designation}</p>
                </div>
                
              </div>

              <div style={{ width: 300,background:'white',padding:'10px',boxShadow: '0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%)',border: '2px solid transparent',borderRadius:'2px',marginBottom:'20px'  }}>
                <h6 style={{fontWeight:'800',color:'rgb(41 41 97)'}}>Contact Person 2</h6>
                
                <div style={{display:'flex',alignItems:'center',marginLeft:'10px',marginBottom:'5px'}}>
                  <FiUser size={20} />
                  <p style={{marginBottom:'0px',marginLeft:'10px'}}>{state.datas.lead_datas[0].lead_id.contact_id.secondary_contact_person ? state.datas.lead_datas[0].lead_id.contact_id.secondary_contact_person : 'Not Added'}</p>
                </div>

                <div style={{display:'flex',alignItems:'center',marginLeft:'10px',position:'relative',marginBottom:'5px'}}>
                  <BiPhone size={20} />
                  <p style={{marginBottom:'0px',marginLeft:'10px'}}>{state.datas.lead_datas[0].lead_id.contact_id.secondary_contact_phone ? state.datas.lead_datas[0].lead_id.contact_id.secondary_contact_phone : 'Not Added'}</p>
                  <HiOutlineClipboard style={{width:'20px',position:'absolute',right:'10px'}} />
                </div>

                <div style={{display:'flex',alignItems:'center',marginLeft:'10px',marginBottom:'5px'}}>
                  <FaGraduationCap size={20} />
                  <p style={{marginBottom:'0px',marginLeft:'10px'}}>{state.datas.lead_datas[0].lead_id.contact_id.secondary_contact_designation ? state.datas.lead_datas[0].lead_id.contact_id.secondary_contact_designation : 'Not Added'}</p>
                </div>
                
              </div>
            </Col>
            <Col span={18}>
                <div style={{background:'transparent',marginLeft:'50px',padding:'0px',marginBottom:'20px' }}>

              <div style={{background:'white',boxShadow: '0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%)',border: '2px solid transparent',borderRadius:'2px',padding:'10px'}}>
              <h6 style={{fontWeight:'800',color:'rgb(41 41 97)'}}>Lead Information</h6>
                <div style={{display:'flex',alignItems:'center',marginLeft:'10px',marginBottom:'5px'}}>
                <p style={{marginBottom:'0px',marginLeft:'10px'}}>Name</p>
                  <p style={{marginBottom:'0px',marginLeft:'10px'}}>{state.datas.lead_datas[0].lead_id.lead_name}</p>
                </div>
                <div style={{display:'flex',alignItems:'center',marginLeft:'10px',marginBottom:'5px'}}>
                <p style={{marginBottom:'0px',marginLeft:'10px'}}>Title</p>

                  <p style={{marginBottom:'0px',marginLeft:'10px'}}>{state.datas.lead_datas[0].lead_id.lead_title}</p>
                </div>
                <div style={{display:'flex',alignItems:'center',marginLeft:'10px',marginBottom:'5px'}}>
                  <p style={{marginBottom:'0px',marginLeft:'10px'}}>Stage</p>
                  <p style={{marginBottom:'0px',marginLeft:'10px'}}>{state.datas.lead_datas[0].lead_id.lead_status}</p>
                </div>

                <p style={{position:'relative',textAlign:'right',marginBottom:'0px',fontSize:'12px'}}>Created at <strong>{moment(state.datas.lead_datas[0].lead_id.createdAt).format('LL')}</strong></p>
              </div>
                
              <div style={{background:'white',boxShadow: '0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%)',border: '2px solid transparent',borderRadius:'2px',padding:'10px',marginTop:'20px'}}>
              <h6 style={{fontWeight:'800',color:'rgb(41 41 97)'}}>Lead Tracker</h6>
                <Steps direction="vertical" size="small" current={2+state.datas.assigneduser.length -1} style={{marginLeft:'20px',marginTop:'30px'}}>
                 
                 <Step title={`Company Name ${state.datas.lead_datas[0].lead_id.contact_id.company_name} contact owner (${state.datas.lead_datas[0].lead_id.contact_id.contact_owner.name}) `} description={`contact created on ${moment(state.datas.lead_datas[0].lead_id.contact_id.createdAt).format('LL')}`} />
                 <Step title={`Lead Name ${state.datas.lead_datas[0].lead_id.lead_name} Lead status (${state.datas.lead_datas[0].lead_id.lead_status}) `} description={`Lead converted on  ${moment(state.datas.lead_datas[0].lead_id.createdAt).format('LL')}`} />
                  
                  {state.datas.assigneduser.map((a)=>(
                    <Step title={`Assigned by ${a.assigned_by.name} at (${moment(a.assigned_by.createdAt).format('LL')}) --> Assigned To ${a?.user_id?.name}`} description={`${a.remarks}`} />
                  ))}
                  
                </Steps>  
                </div>
            </div> 
            </Col>  
          </Row>

    </div>
  )
}

export default TrackLead