import React,{useState,useEffect,useRef} from 'react';
import { BoldHeadText, BoldText1, SmallText } from '../../../helpers/constants/ConstantsText';
import { Input,Upload,Select,Button,DatePicker } from "antd";
import FmsDashboardMainRoot from '../FmsDashboardMainRoot';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import {useSelector} from 'react-redux';
import PrimaryButton, { SecondaryButton } from '../../../components/buttons/PrimaryButton';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { FiEdit,FiTrash2 } from 'react-icons/fi';
// import { FiTrash2 } from 'react-icons/fc';
import SelectData from 'react-select'

import { data_not_found } from '../../../helpers/Constants';
import { CloseOutlined, UploadOutlined } from '@mui/icons-material';
import moment from 'moment';
import axios from 'axios';
import { authHeader } from '../../../helpers/usertoken/UserToken';
import { AiOutlineInfoCircle,AiOutlineReload,AiOutlineCheck } from 'react-icons/ai';
import toast, { Toaster } from "react-hot-toast";
import {BsArrowLeft} from 'react-icons/bs';
import Tooltip from '@mui/material/Tooltip';
import SignatureCanvas from 'react-signature-canvas'
import { useParams } from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import { base_url } from '../../../App';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

function EmployeeInformationForm(){

   const { Option } = Select; 
   const { TextArea } = Input;
   const canvasRef = useRef(null)
   

   const {id} = useParams();


 

   const roles = useSelector(state => state.Auth.roles)
   const userId = useSelector(state=>state.Auth.id)
   const navigate = useNavigate();

//    console.log("roles anna",roles)


//    console.log('roles here',roles)
//    const [loader,setloader] = useState(false)
   const [oldData,setoldData] = useState({})
   const [data,setdata] = useState({id:'',employee_id:'',father_name:'',name:'',designation:'',date_of_joining:'',total_work_experience:'',department_branch_were_deployed:'',department_branch_were_deployed_id:'',date_of_birth:'',gender:'',mobile_no:'',emergency_contact:'',personal_email_id:'',blood_group:'',nationality:'Indian',martial_status:'',present_address:'',permanent_address:'',photo:null,signature:null,addhar_card_no:'',addhar_file:null,pan_card_no:'',pan_file:null,previous_uan_pf_no:'',previous_esi_no:'',form_type:'',is_verified:false,is_approved:false,take_attendance:true,is_rejected:false,remarks:'',take_home:'',ip_dispensary:'',family_dispensary:'',generate_docs:false,save_in_google_sheet:false,upload_to_excel:false,password:'',app_access:false,modified:false,roles:[],insurance_or_esi:'ESI',salary_structure:''})
   const [error,seterror] = useState({employee_id_err:'',father_nameerr:'',nameerr:'',designation_err:'',date_of_joining_err:'',date_of_birth_err:'',gender_err:'',mobile_no_err:'',emergency_contact_err:'',blood_group_err:'',nationality_err:'',martial_status_err:'',photo_err:'',signature_err:'',addhar_no_err:'',pan_no_err:'',martial_status_err:'',department_branch_were_deployed_err:'',take_home_err:'',ip_dispensary:'',family_dispensary:'',family_length_err:'',password:'',app_access:'',modified:'',roles:[],insurance_or_esi:'',salary_structure:''})

   const [languages,setlanguages] = useState([])
   const [families,setfamilies] = useState([])
   const [esiInfo,setesiInfo] = useState([])
   const [dispensaryArr,setdispensaryArr] = useState([])
   const [bank,setbank] = useState({bank_name:'',account_no:'',ifsc_code:'',bank_address:'',bank_photo:null})
   const [bank_err,setbank_err] = useState({bank_name_err:'',account_no_err:'',ifsc_code_err:'',bank_address:'',bank_photo:null})
   const [check_list,setcheck_list] = useState({offer_letter_copy:false,appointment_letter_copy:false,employee_information_dic:false,policy_sign_off:false,passport_size_photo:false,resume_of_employee:false,education_certificate:false,previous_employee_certificate:false,previous_salary_proof:false,id_proof:false,pan_card:false,addhar_card:false,address_proof_permanent:false,address_proof_current:false,bank_proof:false,id:null})
   const [activeStep, setActiveStep] = useState(0);
   const [subactiveStep, setsubActiveStep] = useState(0);

   const [hrPolicy,sethrPolicy] = useState({})
   const theme_color = useSelector(state=>state.Theme.theme_color)


   const [drawer,set_drawer] = useState(false)


   const [language,setlanguage] = useState({id:'',language:'',read:false,write:false,speak:false})
   const [language_err,setlanguage_err] = useState({language_err1:''})
   const [family,setfamily] = useState({id:'',name:'',relationship:'',education:'',occupation:'',age:'',file:''})
   const [family_err,setfamily_err] = useState({name_err:'',relationship_err:'',education_err:'',occupation_err:'',age_err:'',file:''})
   const [esi_info,setesi_info] = useState({name:'',relationship:'',dob:'',age:'',addhar_no:'',file:null})
   const [esi_info_err,setesi_info_err] = useState({name:'',relationship:'',dob:'',age:'',addhar_no:'',file:''})
   const [fmsclients,setfmsclients] = useState([])
   const [fmsemployee_designation,setfmsemployee_designation] = useState([])
   const [selected_language,setselected_language] = useState('EN')
   const [hrPolicyDocsList,sethrPolicyDocsList] = useState({})
   const [hrPolicyDocsListErr,sethrPolicyDocsListErr] = useState({})
   const [roles_arr, setroles_arr] = useState([])


   const [salaryStatementArr,setsalaryStatementArr] = useState([])


  
   useEffect(()=>{
    if(id !== undefined){
      axios.get(`fms/basicemployee/get_detail/${id}`,authHeader)
      .then((res)=>{

          let d = res.data.data[0]

        //   console.log("d here",d?.salary_structure)


          if(d?.take_home !== undefined && d?.take_home <= 21000){
              setsteps([
                  {
                      label: 'Basic Info',
                      description: `Add a emplyee basic info for your database`,
                  },
                  {
                      label: 'Languages Known',
                      description:'Add the languages known by employee',
                  },
                  {
                      label: 'Family details',
                      description: `Employee Family Details for your refernce`,
                  },
                  {
                      label: 'Bank details',
                      description: `Add Employee Bank Details for the salary process`,
                  },
                //   {
                //       label: 'Esi Requirment',
                //       description: `Use the below form to add the esi family info required `,
                //   },
                  {
                      label: 'File Checklist',
                      description: `Check on all the documents you hv recieved`,
                  },
                  {
                    label: 'HR Policy',
                    description: `Complete the hr policy approval to continue`,
                  },
                 
              ])
          }else{
            setsteps([
                {
                    label: 'Basic Info',
                    description: `Add a emplyee basic info for your database`,
                },
                {
                    label: 'Languages Known',
                    description:'Add the languages known by employee',
                },
                {
                    label: 'Family details',
                    description: `Employee Family Details for your refernce`,
                },
                {
                    label: 'Bank details',
                    description: `Add Employee Bank Details for the salary process`,
                },
                {
                    label: 'File Checklist',
                    description: `Check on all the documents you hv recieved`,
                },
                {
                    label: 'HR Policy',
                    description: `Complete the hr policy approval to continue`,
                },
               
            ])
          }

        //   console.log("amma",d)
          let rolesArr = []
          d?.roles?.forEach((r)=>{
            let data = {}
            data['label'] = r.role_name
            data['value'] = r.display_name
            data['id'] = r._id
            rolesArr.push(data)
          })

          let data_set = {
              id:d?._id,
              employee_id:d?.employee_id == '' ? '' : d?.employee_id,
              name:d?.name,
              father_name:d?.father_name,
              designation:d?.designation?._id !== undefined ? {label:d?.designation?.display_name,value:d?.designation?._id} : '',
              designation_id:d?.designation?._id !== undefined ? d?.designation?._id : '',
              date_of_joining:d?.date_of_joining,
              total_work_experience:d?.total_work_experience,
              department_branch_were_deployed: d?.department_branch_were_deployed !== undefined ? {label:d?.department_branch_were_deployed?.client_name,value:d?.department_branch_were_deployed?._id} : '',
              department_branch_were_deployed_id:d?.department_branch_were_deployed !== undefined ? d?.department_branch_were_deployed._id : '',
              date_of_birth:d?.date_of_birth,
              gender:d?.gender,
              mobile_no:d?.mobile_no,
              emergency_contact:d?.emergency_contact,
              personal_email_id:d?.personal_email_id,
              blood_group:d?.blood_group,
              nationality:d?.nationality,
              martial_status:d?.martial_status,
              present_address:d?.present_address,
              permanent_address:d?.permanent_address,
              photo:d?.photo,
              signature:d?.signature,
              addhar_card_no:d?.addhar_card_no,
              addhar_file:d?.addhar_file,
              pan_card_no:d?.pan_card_no,
              pan_file:d?.pan_file,
              previous_uan_pf_no:d?.previous_uan_pf_no,
              previous_esi_no:d?.previous_esi_no,
              form_type:d?.form_type,
              is_verified:d?.is_verified  == undefined ? false : d?.is_verified,
              modified:d?.modified  == undefined ? false : d?.modified,
              is_approved:d?.is_approved == undefined ? false : d?.is_approved,
              is_rejected:d?.is_rejected  == undefined ? false : d?.is_rejected,
              terminated:d?.terminated  == undefined ? false : d?.terminated,
              inactive_due_to_client:d?.inactive_due_to_client  == undefined ? false : d?.inactive_due_to_client,
              take_attendance:d?.take_attendance  == undefined ? false : d?.take_attendance,
              take_home:d?.take_home,
              ip_dispensary:d?.ip_dispensary !== undefined ? d?.ip_dispensary?.name !== undefined ?  {label:d?.ip_dispensary?.name,value:d?.ip_dispensary?._id} : '' : '' ,
              family_dispensary:d?.family_dispensary !== undefined ? d?.family_dispensary?.name !== undefined ?  {label:d?.family_dispensary?.name,value:d?.family_dispensary?._id} : '' : '' ,
              remarks:d?.remarks,
              app_access:d?.app_access,
              password:d?.password,
              roles:rolesArr,
              insurance_or_esi:d?.insurance_or_esi
          }


          if(d?.salary_structure !== '' && d?.salary_structure !== undefined && d?.salary_structure !== null){
            data_set['salary_structure'] = {label:`Net Pay Take Home : ${d?.salary_structure?.net_pay_take_home_salary} Rs `,value:d?.salary_structure?._id}
          }

        //   console.log("d?.department_branch_were_deployed?._id",d?.department_branch_were_deployed?._id)

          
           getSalaryStatement(d?.department_branch_were_deployed?._id)

        

          let checklist_set = {}
          if(d?.document_recieved !== undefined && d?.document_recieved?.length > 0){ 
           checklist_set = {
              id:d?.document_recieved[0]._id,
              offer_letter_copy:d.document_recieved[0].offer_letter_copy,
              appointment_letter_copy:d.document_recieved[0].appointment_letter_copy,
              employee_information_dic:d.document_recieved[0].employee_information_dic,
              policy_sign_off:d.document_recieved[0].policy_sign_off,
              passport_size_photo:d.document_recieved[0].passport_size_photo,
              resume_of_employee:d.document_recieved[0].resume_of_employee,
              education_certificate:d.document_recieved[0].education_certificate,
              previous_employee_certificate:d.document_recieved[0].previous_employee_certificate,
              previous_salary_proof:d.document_recieved[0].previous_salary_proof,
              id_proof:d.document_recieved[0].id_proof,
              pan_card:d.document_recieved[0].pan_card,
              addhar_card:d.document_recieved[0].addhar_card,
              address_proof_permanent:d.document_recieved[0].address_proof_permanent,
              address_proof_current:d.document_recieved[0].address_proof_current,
              bank_proof:d.document_recieved[0].bank_proof
           }
        }
          let bank_details_set = {
              id:d?.employee_banks[0]?._id,
              bank_name:d?.employee_banks[0]?.bank_name,
              account_no:d?.employee_banks[0]?.account_no,
              ifsc_code:d?.employee_banks[0]?.ifsc_code,
              bank_address:d?.employee_banks[0]?.bank_address,
              bank_photo:d?.employee_banks[0]?.bank_photo
          }

          if(d.employee_languages.length > 0){
              let arr = []
              d.employee_languages.forEach((e)=>{
                  let obj={
                      id:e._id,
                      language:e.language,
                      read:e.read,
                      write:e.write,
                      speak:e.speak,
                  }
                  arr.push(obj)
              })
              setlanguages(arr)
          }

          if(d.esiemployees !== undefined && d.esiemployees.length > 0){
              let arr = []
              d.esiemployees.forEach((e)=>{
                  let obj={
                      id:e._id,
                      name:e.name,
                      relationship:e.relationship,
                      addhar_no:e.addhar_no,
                      dob:e.dob,
                      file:e.file,
                  }
                  arr.push(obj)
              })
              setesiInfo(arr)
          }

            // console.log("d.employee_family_info",d.employee_family_info)
          if(d.employee_family_info.length > 0){
              let arr = []
              d.employee_family_info.forEach((e)=>{
                  let obj={
                      id:e._id,
                      name:e.name,
                      occupation:e.occupation,
                      relationship:e.relationship,
                      education:e.education,
                      age:e.age,
                      file:e?.file !== undefined ? e?.file  : ''
                  }
                  arr.push(obj)
              })
              setfamilies(arr)
          }

          if(d?.employeepolicyapprovals?.length > 0){
            let employee_approval = d?.employeepolicyapprovals[0]
            let obj = {
                check1:employee_approval?.hr_policy_approval == 1 ? true : false,
                check2:employee_approval?.self_declartion_form == 1 ? true : false,
                signature:employee_approval?.employee_signature,
                file:employee_approval?.video,
                id:employee_approval?._id
            }
            if(employee_approval?.video !== undefined){
                obj['file'] = employee_approval?.video
            }
            sethrPolicyDocsList({...hrPolicyDocsList,...obj})
            getHrPolicyDocs(obj)
            } else{

            getHrPolicyDocs()
            }


          setdata(data_set)
          setoldData({...d})
          setcheck_list(checklist_set)
          setbank(bank_details_set)
      }).catch((err)=>{
      })
    }else{
    //   resetallform();
      getHrPolicyDocs()
    }
    getRoles()

   },[id])

   useEffect(()=>{
    if(selected_language === 'EN'){
     sethrPolicyDocsList({...hrPolicyDocsList,docs1:hrPolicy?.docs1_english,docs2:hrPolicy?.docs2_english,hr_policy_text:hrPolicy?.docs1_english_text,hr_policy_text1:hrPolicy?.docs1_1_english_text,self_declartion_text:hrPolicy?.docs2_english_text})
    }
    if(selected_language === 'KN'){
     sethrPolicyDocsList({...hrPolicyDocsList,docs1:hrPolicy?.docs1_kannada,docs2:hrPolicy?.docs2_kannada,hr_policy_text:hrPolicy?.docs1_kannada_text,hr_policy_text1:hrPolicy?.docs1_1_kannada_text,self_declartion_text:hrPolicy?.docs2_kannada_text})
    }
    if(selected_language === 'HI'){
     sethrPolicyDocsList({...hrPolicyDocsList,docs1:hrPolicy?.docs1_hindi,docs2:hrPolicy?.docs2_hindi,hr_policy_text:hrPolicy?.docs1_hindi_text,hr_policy_text1:hrPolicy?.docs1_1_hindi_text,self_declartion_text:hrPolicy?.docs2_hindi_text})
    }
   },[selected_language])
   



    function getSalaryStatement(v){
        axios.get(`fms/fmsclientsbasedsalarystructure/get/${data?.department_branch_were_deployed?.value !== undefined ? data?.department_branch_were_deployed?.value : v}?page=${1}`,authHeader).then((res)=>{
            let arr = []
            let dataArr = res.data.datas
            dataArr.forEach((d)=>{
                arr?.push({label:`Net Pay Take Home : ${d?.net_pay_take_home_salary} Rs `,value:d?._id,other_info:d})
            })
            
            setsalaryStatementArr(arr)
            }).catch((err)=>{
                console.log("err",err?.response?.data)
            })
    }

    
    // console.log("salaryStatementArr here",salaryStatementArr)

   function getHrPolicyDocs(v){
        axios.get('fms/fmshrpolicy/get',authHeader)
        .then((res)=>{
            let d = res?.data?.data[0]
            sethrPolicy(res?.data?.data[0])
            if(selected_language === 'EN'){
                if(v !== undefined){
                    sethrPolicyDocsList({...v,docs1:d?.docs1_english,docs2:d?.docs2_english,hr_policy_text:d?.docs1_english_text,hr_policy_text1:d?.docs1_1_english_text,self_declartion_text:d?.docs2_english_text})
                }else{
                    sethrPolicyDocsList({docs1:d?.docs1_english,docs2:d?.docs2_english,hr_policy_text:d?.docs1_english_text,hr_policy_text1:d?.docs1_1_english_text,self_declartion_text:d?.docs2_english_text,check1:false,check2:false,signature:'',file:''})
                }
            }
            if(selected_language === 'KN'){
                if(v !== undefined){
                sethrPolicyDocsList({...v,docs1:d?.docs1_kannada,docs2:d?.docs2_kannada,hr_policy_text:d?.docs1_kannada_text,hr_policy_text1:d?.docs1_1_kannada_text,self_declartion_text:d?.docs2_kannada_text})
                }else{
                sethrPolicyDocsList({docs1:d?.docs1_kannada,docs2:d?.docs2_kannada,hr_policy_text:d?.docs1_kannada_text,hr_policy_text1:d?.docs1_1_kannada_text,self_declartion_text:d?.docs2_kannada_text,check1:false,check2:false,signature:'',file:''})
                }
                }
            if(selected_language === 'HI'){
                if(v !== undefined){
                sethrPolicyDocsList({...v,docs1:d?.docs1_hindi,docs2:d?.docs2_hindi,hr_policy_text:d?.docs1_hindi_text,hr_policy_text1:d?.docs1_1_hindi_text,self_declartion_text:d?.docs2_hindi_text})
                }else{
                sethrPolicyDocsList({docs1:d?.docs1_hindi,docs2:d?.docs2_hindi,hr_policy_text:d?.docs1_hindi_text,hr_policy_text1:d?.docs1_1_hindi_text,self_declartion_text:d?.docs2_hindi_text,check1:false,check2:false,signature:'',file:''})
                } 
            }
        })    
   }

//  console.log("id",id)

   const languages_Arr = [
     'Kannada',
     'English',
     'Hindi',
     'Telugu',
     'Urdu',
     'Bengali',
     'Malyalam'
   ]

   const relationship_Arr = [
    'Mother',
    'Father',
    'Cousin',
    'Brother',
    'Sister',
    'Child',
    'Spouse',
    'Neighbour',
    'Other',
    
   ]

   const form_type = [
    'Compliance',
    'Non-Compliance',
    'Contract Based'
   ]

   const gender_Arr = [
    'Male',
    'Female',
    'Others',
   ]

   const martial_Arr = [
    'Single',
    'Married',
   ]

   const blood_group_Arr = [
    'A+',
    'A-',
    'B+',
    'B-',
    'O+',
    'O-',
    'AB+',
    'AB-',
   ]


   function getRoles(){
    axios.get('fms/app_roles/get',authHeader)
         .then((res)=>{
            const role_arr_created = []
            res.data.datas.forEach(d=>{
                 let data = {}
                 data['label'] = d.role_name
                 data['value'] = d.display_name
                 data['id'] = d._id
                 role_arr_created.push(data)
            })
            setroles_arr(role_arr_created)

        })
}  

  function addlanguage(){
    if(language.language === ''){
        setlanguage_err({...language_err,language_err1:'Language Field is required'})
    }else{
        const previous_languages = [...languages]
        if(language.id === ''){
            let language_set = {
                ...language
            }
            language_set['id'] = languages.length
            setlanguages([...previous_languages,language_set]);
        }else{
            let index = languages.findIndex(e=>e.id == language.id)
            let updated = previous_languages.splice(index,1,language)
            setlanguages(previous_languages)
        }
        setlanguage({language:'',read:false,write:false,speak:false,id:''})
        set_drawer(false)
    }
  }

  function addfamily(){
    if(family.name === ''){
        setfamily_err({...family_err,name_err:'Name Field is required'})
    }else if(family.relationship === ''){
        setfamily_err({...family_err,relationship_err:'Relationship Field is required'})
    }
    // else if(!family.file){
    //     setfamily_err({...family_err,file_err:'Addhar Field is required'})
    // }
    else{
        const previous_family = [...families]
        if(family.id === ''){
            let family_set = {
                ...family
            }
            family_set['id'] = families.length
            setfamilies([...previous_family,family_set]);
        }else{
            let index = families.findIndex(e=>e.id == family.id)
            let updated = previous_family.splice(index,1,family)
            setfamilies(previous_family)
        }
        setfamily({name:'',relationship:'',education:'',occupation:'',age:'',id:'',file:''})
        set_drawer(false)
       
  }
  }

  function addesiInfo(){
    if(esi_info.name === ''){
        setesi_info_err({...esi_info_err,name:'Name Field is required'})
    }else if(esi_info.relationship === ''){
        setesi_info_err({...esi_info_err,relationship:'Relationship Field is required'})
    }else if(esi_info.addhar_no === ''){
        setesi_info_err({...esi_info_err,addhar_no:'Addhar No Field is required'})
    }else if(esi_info.dob === ''){
        setesi_info_err({...esi_info_err,dob:'DOB Field is required'})
    }else{
        const previous_esi_info_list = [...esiInfo]
        if(esi_info.id === ''){
            let esiinfo_set = {
                ...esi_info
            }
            esi_info['id'] = esiInfo.length
            setesiInfo([...previous_esi_info_list,esiinfo_set]);
        }else{
            let index = esiInfo.findIndex(e=>e.id == esi_info.id)
            let updated = previous_esi_info_list.splice(index,1,esi_info)
            setesiInfo(previous_esi_info_list)
        }
        setesi_info({name:'',relationship:'',dob:'',age:'',addhar_no:'',file:null,id:''})
        setesi_info_err({name:'',relationship:'',dob:'',age:'',addhar_no:'',file:'',id:''})
        set_drawer(false)
       
  }
  }

  function deletelanguage(v){
    let updated = languages.filter((l)=>l.id !== v.id)
    setlanguages(updated)
  }

  function deletefamily(v){
    let updated = families.filter(f=>f.id !== v.id)
    setfamilies(updated)
  }

  function deleteesiInfo(v){
    let updated = esiInfo.filter(f=>f.id !== v.id)
    setesiInfo(updated)
  }

  const handleUpload = async (name,v) => {
    if(name != 'bank_photo'){
        if(name == 'photo'){
            seterror({...error,photo_err:''})
        }else if(name == 'signature'){
            seterror({...error,signature_err:''})
        }else if(name == 'hr_docs_file' ){
            // console.log("annaya",v.fileList[v.fileList.length - 1].originFileObj,v)
            if(v?.fileList[v.fileList.length - 1].originFileObj?.type === "video/mp4" || v?.fileList[v.fileList.length - 1].originFileObj?.type === "video/mp3"){
                sethrPolicyDocsListErr({...hrPolicyDocsListErr,file:''})
                var fd = new FormData()
                fd.append('file',v?.fileList[v?.fileList.length - 1]?.originFileObj)
                await axios.post(`fms/basicemployee/upload_file`,fd,authHeader)
                .then((res)=>{
                sethrPolicyDocsList({...hrPolicyDocsList,file:res?.data?.data})
                })
            }else{
                sethrPolicyDocsListErr({...hrPolicyDocsListErr,file:'The valid file type is mp3 and mp4'})
            }
        }else if(name === 'family'){
            setfamily({...family,file:v.fileList[v.fileList.length - 1].originFileObj})
            setfamily_err({...family_err,file_err:''})
        }
        authHeader['headers']['Content-Type'] = 'multipart/form-data'
        var fd = new FormData()
        fd.append('file',v?.fileList[v?.fileList.length - 1]?.originFileObj)
        await axios.post(`fms/basicemployee/upload_file`,fd,authHeader)
        .then((res)=>{
        setdata({...data,[name]:res?.data?.data});
        })
    }else{
        console.log("kppp anna")
        var fd = new FormData()
        fd.append('file',v?.fileList[v?.fileList.length - 1]?.originFileObj)
        await axios.post(`fms/basicemployee/upload_file`,fd,authHeader)
        .then((res)=>{
        setbank({...bank,bank_photo:res?.data?.data})
        setbank_err({...bank_err,bank_photo:''})
        })
    }
   
  }

  
  
  function resetform(){
      setActiveStep(0)
      if(activeStep == 0){
        setdata({id:'',employee_id:'',name:'',designation:'',date_of_joining:'',total_work_experience:'',department_branch_were_deployed:'',date_of_birth:'',gender:'',mobile_no:'',emergency_contact:'',personal_email_id:'',blood_group:'',nationality:'',martial_status:'',present_address:'',permanent_address:'',photo:null,signature:null,addhar_card_no:'',addhar_file:null,pan_card_no:'',pan_file:null,previous_uan_pf_no:'',previous_esi_no:''})
      }else if(activeStep == 1){
        setlanguages([])
      }else if(activeStep == 2){
        setfamilies([])
      }else if(activeStep == 3){
        setbank({bank_name:'',account_no:'',ifsc_code:'',bank_address:'',bank_photo:null})
        // setActiveStep(2)
      }else if(activeStep == 4){
        setcheck_list({offer_letter_copy:false,appointment_letter_copy:false,employee_information_dic:false,policy_sign_off:false,passport_size_photo:false,resume_of_employee:false,education_certificate:false,previous_employee_certificate:false,previous_salary_proof:false,id_proof:false,pan_card:false,addhar_card:false,address_proof_permanent:false,address_proof_current:false,bank_proof:false})
        // setActiveStep(3)
      }else if(activeStep == 5){
        sethrPolicyDocsList({})
        sethrPolicyDocsListErr({})
      }
  }

  const handleNext = () => {

    if (data.insurance_or_esi !== 'ESI') {
        if (!family.name || !family.relationship) {
          alert('Name and Relationship fields must have data.');
          return;
        }
        setActiveStep(2);
      }
     else{
        setActiveStep(2);
     }
    
  };

  

  function nextstep(){

    if(activeStep == 0){
        let myAge = 0
        if(data.date_of_birth !== ''){
           const dob = new Date(data.date_of_birth).getTime();
           const dateToCompare = new Date(new Date()).getTime();
           const age = (dateToCompare - dob) / (365 * 24 * 60 * 60 * 1000);
           myAge = Math.floor(age)
        }
            // console.log("myAge",myAge < 18 || myAge > 56)
            // if(myAge < 18 || myAge > 56){
            //     seterror({...error,date_of_birth_err:'We cant onboard you because your age does not lies bw 18 or 56'})
            // }else{
            //     seterror({...error,date_of_birth_err:''})
            // }

        if(data?.name?.length < 3){
        seterror({...error,nameerr:'Employee name should be atleast 3 characters'})
        }
        else  if((!roles.includes('fms_hr') || !roles.includes('admin')) &&  data?.father_name?.length < 2){
        seterror({...error,father_nameerr:'Father name should be atleast 2 characters'})
        }else if(data.form_type === ''){
        seterror({...error,nameerr:'',form_type_err:'This Field is required'})
        }else if(!data.designation){
        seterror({...error,nameerr:'',form_type_err:'',designation_err:'This Field is required'})
        }else if(!data.date_of_joining){
        seterror({...error,nameerr:'',form_type_err:'',designation_err:'',date_of_joining_err:'Date of Joining Field is required'})
        }else if(!data.date_of_birth){
        seterror({...error,nameerr:'',form_type_err:'',designation_err:'',date_of_joining_err:'',date_of_birth_err:'Date of Birth Field is required'})
        }else if(myAge < 18 || myAge > 56){
        seterror({...error,date_of_birth_err:'We cant onboard you because your age does not lies bw 18 or 56'}) 
        }else if(!data.gender){
        seterror({...error,nameerr:'',form_type_err:'',designation_err:'',date_of_joining_err:'',date_of_birth_err:'',gender_err:'Gender Field is required'})
        }else if(data.mobile_no.length != 10){
        seterror({...error,nameerr:'',form_type_err:'',designation_err:'',date_of_joining_err:'',date_of_birth_err:'',gender_err:'',mobile_no_err:'Enter a valid 10 digit mobile number'})
        }else if((data.form_type == 'Compliance' || data.form_type == 'Contract Based') && data.emergency_contact.length != 10){
        seterror({...error,nameerr:'',form_type_err:'',designation_err:'',date_of_joining_err:'',date_of_birth_err:'',gender_err:'',mobile_no_err:'',emergency_contact_err:'Enter a valid 10 digit mobile number'})
        }else if(!data.nationality){
        seterror({...error,nameerr:'',form_type_err:'',designation_err:'',date_of_joining_err:'',date_of_birth_err:'',gender_err:'',mobile_no_err:'',emergency_contact_err:'',blood_group_err:'',nationality_err:'This Field is required'})
        }else if((data.form_type == 'Compliance' || data.form_type == 'Contract Based') && !data.martial_status){
        seterror({...error,nameerr:'',form_type_err:'',designation_err:'',date_of_joining_err:'',date_of_birth_err:'',gender_err:'',mobile_no_err:'',emergency_contact_err:'',blood_group_err:'',nationality_err:'',martial_status_err:'This Field is required'})
        }else if(!data.department_branch_were_deployed_id){
        seterror({...error,nameerr:'',form_type_err:'',designation_err:'',date_of_joining_err:'',date_of_birth_err:'',gender_err:'',mobile_no_err:'',emergency_contact_err:'',blood_group_err:'',nationality_err:'',martial_status_err:'',department_branch_were_deployed_err:'This Field is required'})
        }else if(!data.photo){
        seterror({...error,nameerr:'',form_type_err:'',designation_err:'',date_of_joining_err:'',date_of_birth_err:'',gender_err:'',mobile_no_err:'',emergency_contact_err:'',blood_group_err:'',nationality_err:'',photo_err:'This Field is required'})
        }else if(!data.signature && !roles.includes('fms_hr')){
        seterror({...error,nameerr:'',form_type_err:'',designation_err:'',date_of_joining_err:'',date_of_birth_err:'',gender_err:'',mobile_no_err:'',emergency_contact_err:'',blood_group_err:'',nationality_err:'',photo_err:'',signature_err:'This Field is required'})
        }else if(!data.addhar_card_no){
        seterror({...error,nameerr:'',form_type_err:'',designation_err:'',date_of_joining_err:'',date_of_birth_err:'',gender_err:'',mobile_no_err:'',emergency_contact_err:'',blood_group_err:'',nationality_err:'',photo_err:'',signature_err:'',addhar_no_err:'This Field is required'})
        }
        // else if(data.is_verified && (roles.includes('fms_hr') || roles.includes('admin') || roles.includes('controller'))){
        // seterror({...error,nameerr:'',form_type_err:'',designation_err:'',date_of_joining_err:'',date_of_birth_err:'',gender_err:'',mobile_no_err:'',emergency_contact_err:'',blood_group_err:'',nationality_err:'',photo_err:'',signature_err:'',addhar_no_err:'',pan_no_err:'',previous_uan_pf_no_err:'',previous_esi_no_err:'',employee_id_err:'Employee Id Required'})
        // }
        else if(roles.includes('fms_user') && !data.take_home){
        seterror({...error,take_home_err:'This Field is required',form_type_err:'',designation_err:'',date_of_joining_err:'',date_of_birth_err:'',gender_err:'',mobile_no_err:'',emergency_contact_err:'',blood_group_err:'',nationality_err:'',photo_err:'',signature_err:'',addhar_no_err:'',pan_no_err:'',previous_uan_pf_no_err:'',previous_esi_no_err:'',employee_id_err:''})
        }else if(!data.insurance_or_esi && !roles.includes('fms_hr')){
            seterror({...error,nameerr:'',form_type_err:'',designation_err:'',date_of_joining_err:'',date_of_birth_err:'',gender_err:'',mobile_no_err:'',emergency_contact_err:'',blood_group_err:'',nationality_err:'',photo_err:'',signature_err:'',insurance_or_esi:'This Field is required'})
        }else{
        seterror({...error,nameerr:'',form_type_err:'',designation_err:'',date_of_joining_err:'',date_of_birth_err:'',gender_err:'',mobile_no_err:'',emergency_contact_err:'',blood_group_err:'',nationality_err:'',photo_err:'',signature_err:'',addhar_no_err:'',pan_no_err:'',previous_uan_pf_no_err:'',previous_esi_no_err:'',employee_id_err:''})
        setActiveStep(1)
        // checkesiinforequired()
        }    
    }else if(activeStep == 1){
       setActiveStep(2)
    }else if(activeStep == 2){
        // if(data?.previous_esi_no === ''){
            // if((roles.includes('fms_hr') || roles.includes('admin') || roles.includes('controller')) === false){
                if (data.insurance_or_esi !== 'ESI') {
                    if (families?.length < 2) {
                        alert('2 Families Info required');
                        setActiveStep(2);

                      }else{
                      setActiveStep(3);
                      }
                  }
                 else{
                    setActiveStep(3);
                 }
                // if(families?.length < 1){
                //     set_drawer(true)
                //     subactiveStep(0)
                //     seterror({...error,family_length_err:'Atleast 1 family memeber details required'})
                // }
                // else if(!data.ip_dispensary && data?.take_home <= 21000){
                //     set_drawer(true)
                //     setsubActiveStep(1)
                //     seterror({...error,ip_dispensary:'Ip dispensary field is required'})
                // }else if(!data.family_dispensary && data?.take_home <= 21000){
                //     set_drawer(true)
                //     setsubActiveStep(1)
                //     seterror({...error,family_dispensary:'Family dispensary field is required'})
                // }
                // else{
                //     setActiveStep(3)
                // }
            // }
            // else{
            //     setActiveStep(3)
            // }
        // }
        // else{
        //     setActiveStep(3)
        // }
    }else if(activeStep == 3){
        if((roles.includes('fms_hr') || roles.includes('admin') || roles.includes('controller')) === false){
            if(bank.bank_name.length < 3){
            setbank_err({...bank_err,bank_name_err:'Bank Name Field is required'})
            }else if(bank.account_no === ''){
            setbank_err({...bank_err,bank_name_err:'',account_no_err:'This Field is required'})
            }else if(!bank.ifsc_code){
            setbank_err({...bank_err,bank_name_err:'',account_no_err:'',ifsc_code_err:'This Field is required'})
            }else if(bank.bank_photo === null){
                setbank_err({...bank_err,bank_name_err:'',account_no_err:'',ifsc_code_err:'',bank_photo:'This Pass Book Field is required'})
            }else{
                setActiveStep(4)
            }
        }else{
            setActiveStep(4)
        }
    }else if(activeStep == 4){
        setActiveStep(5)
    }else if(activeStep == 5){
        if((roles.includes('fms_hr') || roles.includes('admin') || roles.includes('controller')) === false){
            if(!hrPolicyDocsList.check1){
                sethrPolicyDocsListErr({...hrPolicyDocsListErr,check1:'Hr Approval need to be agreed'})
            }else if(!hrPolicyDocsList.check2){
                sethrPolicyDocsListErr({...hrPolicyDocsListErr,check2:'Self Declarion need to be agreed'})
            }
            // else if(!hrPolicyDocsList.signature){
            //     sethrPolicyDocsListErr({...hrPolicyDocsListErr,signature:'Signature field is required'})  
            // }
            // else if(hrPolicyDocsList?.file === ''){
            //     sethrPolicyDocsListErr({...hrPolicyDocsListErr,file:'File field is required'})  
            // }
            else{
                data?.id !== '' ? 
                updatesubmit() : finalsubmit()
            }
        }else{
            data?.id !== '' ? updatesubmit() : finalsubmit()
        }
       
    }   
  }

  const [steps,setsteps] = useState([
    {
      label: 'Basic Info',
      description: `Add a emplyee basic info for your database`,
    },
    {
      label: 'Languages Known',
      description:'Add the languages known by employee',
    },
    {
      label: 'Family details',
      description: `Employee Family Details for your refernce`,
    },
    {
        label: 'Bank details',
        description: `Add Employee Bank Details for the salary process`,
    },
    {
        label: 'File Checklist',
        description: `Check on all the documents you hv recieved`,
    },
    {
        label: 'HR Policy',
        description: `Complete the hr policy approval to continue`,
    },
    
   
  ]);
 
  function finalsubmit(){

    // const fd = new FormData()

    const languagesArr = [] 
    const familiesArr = [] 
    const esiArr = [] 
    
    languages?.forEach((l)=>{
        languagesArr.push({
            'language':l?.language,
            'read':l?.read,
            'write':l?.write,
            'speak':l?.speak
         })
    })

    families?.forEach((f)=>{
        familiesArr.push({
            'name':f?.name,
            'relationship':f?.relationship,
            'education':f?.education,
            'occupation':f?.occupation,
            'file':f?.file
        })
    })

    esiInfo?.forEach((e)=>{
        esiArr.push({
            'name':e?.name,
            'relationship':e?.relationship,
            'dob':e?.dob,
            'file':e?.file,
            'addhar_no':e?.addhar_no,
        })
    })

    let rolesArr = []
    data?.roles?.forEach((d)=>{
        rolesArr?.push(d?.id)
    })
    const sendData = {
        'created_by':userId,
        'employee_id':data?.employee_id,
        'name':data?.name,
        'father_name':data?.father_name,
        'form_type':data.form_type,
        'designation':data.designation_id,
        'date_of_joining':data.date_of_joining,
        'total_work_experience':data.total_work_experience,
        'department_branch_were_deployed':data.department_branch_were_deployed_id,
        'date_of_birth':data.date_of_birth,
        'gender':data.gender,
        'mobile_no':data.mobile_no,
        'emergency_contact':data.emergency_contact,
        'personal_email_id':data.personal_email_id,
        'blood_group':data.blood_group,
        'nationality':data.nationality,
        'martial_status':data.martial_status,
        'present_address':data.present_address,
        'permanent_address':data.permanent_address,
        'addhar_card_no':data.addhar_card_no,
        'pan_card_no':data.pan_card_no,
        'previous_uan_pf_no':data.previous_uan_pf_no,
        'previous_esi_no':data.previous_esi_no,
        'employee_type':data.form_type,
        'is_verified':data.is_verified,
        'is_approved':data.is_approved,
        'is_rejected':data.is_rejected,
        'terminated':data.terminated,
        'take_attendance':data.take_attendance,
        'take_home':data.take_home,
        'inactive_due_to_client':data.inactive_due_to_client,
        'photo':data.photo,
        'signature':data.signature,
        'addhar_file':data.addhar_file,
        'pan_file':data.pan_file,
        'save_in_google_sheet':data.save_in_google_sheet,
        'insurance_or_esi':data.insurance_or_esi,
        

        'bank_name':bank.bank_name,
        'account_no':bank.account_no,
        'ifsc_code':bank.ifsc_code,
        'bank_address':bank.bank_address,
        'bank_photo':bank.bank_photo,

        'offer_letter_copy':check_list.offer_letter_copy,
        'appointment_letter_copy':check_list.appointment_letter_copy,
        'employee_information_dic':check_list.employee_information_dic,
        'policy_sign_off':check_list.policy_sign_off,
        'passport_size_photo':check_list.passport_size_photo,
        'resume_of_employee':check_list.resume_of_employee,
        'education_certificate':check_list.education_certificate,
        'previous_employee_certificate':check_list.previous_employee_certificate,
        'previous_salary_proof':check_list.previous_salary_proof,
        'id_proof':check_list.id_proof,
        'pan_card':check_list.pan_card,
        'addhar_card':check_list.addhar_card,
        'address_proof_permanent':check_list.address_proof_permanent,
        'address_proof_current':check_list.address_proof_current,
        'bank_proof':check_list.bank_proof,


        'hr_policy_approval':hrPolicyDocsList?.check1,
        'self_declartion_form':hrPolicyDocsList?.check2,
        'employee_signature':hrPolicyDocsList?.signature,
        'language':selected_language,
        'video_recorded':hrPolicyDocsList?.file,
        'languages':languagesArr,
        'families':familiesArr,
        'esiInfo':esiArr,
        'remarks':data?.remarks,
        'app_access':data?.app_access,
        'password':data?.password,
        'roles':rolesArr
    } 

    if(data?.salary_structure !== '' && data?.salary_structure !== undefined){
        sendData['salary_structure'] = data?.salary_structure?.value !== undefined ?  data?.salary_structure?.value : data?.salary_structure
    }   

    if(data.ip_dispensary?.value !== undefined ){
        sendData['ip_dispensary'] = data.ip_dispensary?.value
    }

    if(data.family_dispensary?.value !== undefined ){
        sendData['family_dispensary'] = data.family_dispensary?.value
    }

    if(data.family_dispensary?.value !== undefined ){
        sendData['family_dispensary'] = data.family_dispensary?.value
    }

    // fd.append('created_by',userId)
    // fd.append('employee_id',data.employee_id)
    // fd.append('name',data.name)
    // fd.append('father_name',data.father_name)
    // fd.append('form_type',data.form_type)
    // fd.append('designation',data.designation_id)
    // fd.append('date_of_joining',data.date_of_joining)
    // fd.append('total_work_experience',data.total_work_experience)
    // fd.append('department_branch_were_deployed',data.department_branch_were_deployed_id)
    // fd.append('date_of_birth',data.date_of_birth)
    // fd.append('gender',data.gender)
    // fd.append('mobile_no',data.mobile_no)
    // fd.append('emergency_contact',data.emergency_contact)
    // fd.append('personal_email_id',data.personal_email_id)
    // fd.append('blood_group',data.blood_group)
    // fd.append('nationality',data.nationality)
    // fd.append('martial_status',data.martial_status)
    // fd.append('present_address',data.present_address)
    // fd.append('permanent_address',data.permanent_address)
    // fd.append('addhar_card_no',data.addhar_card_no)
    // fd.append('pan_card_no',data.pan_card_no)
    // fd.append('previous_uan_pf_no',data.previous_uan_pf_no)
    // fd.append('previous_esi_no',data.previous_esi_no)
    // fd.append('employee_type',data.form_type)
    // fd.append('is_verified',data.is_verified == true ? 1 :0)
    // fd.append('is_rejected',data.is_rejected == true ? 1 :0)
    // fd.append('terminated',data.terminated  == true ? 1 :0)
    // fd.append('take_attendance',data.take_attendance  == true ? 1 :0)
    // fd.append('take_home',data.take_home)
    // data.ip_dispensary?.value !== undefined && fd.append('ip_dispensary',data.ip_dispensary?.value)
    // data.family_dispensary?.value !== undefined &&  fd.append('family_dispensary',data.family_dispensary?.value)
    // fd.append('inactive_due_to_client',data.inactive_due_to_client  == true ? 1 :0)

    // if(data?.photo?.name != undefined){
        // fd.append('photo',data.photo !== null ? data?.photo : '')
    // }
    // if(data?.signature?.name != undefined){
        // fd.append('signature',data.signature !== null ? data?.signature : '')
    // }

    // if(data?.addhar_file != null){
        // fd.append('addhar_file',data.addhar_file !== null ? data?.addhar_file : '')
    // }
    // if(data?.pan_file != null){
        // fd.append('pan_file',data.pan_file !== null ? data?.pan_file : '')
    // }


    // fd.append('bank_name',bank.bank_name)
    // fd.append('account_no',bank.account_no)
    // fd.append('ifsc_code',bank.ifsc_code)
    // fd.append('bank_address',bank.bank_address)
    // if(bank.bank_photo != null && bank?.bank_photo?.name !== undefined){
    // fd.append('bank_photo',bank.bank_photo !== null ? bank?.bank_photo : '')
    // }

    // fd.append('offer_letter_copy',check_list.offer_letter_copy)
    // fd.append('appointment_letter_copy',check_list.appointment_letter_copy)
    // fd.append('employee_information_dic',check_list.employee_information_dic)
    // fd.append('policy_sign_off',check_list.policy_sign_off)
    // fd.append('passport_size_photo',check_list.passport_size_photo)
    // fd.append('resume_of_employee',check_list.resume_of_employee)
    // fd.append('education_certificate',check_list.education_certificate)
    // fd.append('previous_employee_certificate',check_list.previous_employee_certificate)
    // fd.append('previous_salary_proof',check_list.previous_salary_proof)
    // fd.append('id_proof',check_list.id_proof)
    // fd.append('pan_card',check_list.pan_card)
    // fd.append('addhar_card',check_list.addhar_card)
    // fd.append('address_proof_permanent',check_list.address_proof_permanent)
    // fd.append('address_proof_current',check_list.address_proof_current)
    // fd.append('bank_proof',check_list.bank_proof)




    // languages.forEach((e,i) => {
    //     fd.append(`languages[${i}][language]`,languages[i].language)
    //     fd.append(`languages[${i}][read]`,languages[i].read)
    //     fd.append(`languages[${i}][write]`,languages[i].write)
    //     fd.append(`languages[${i}][speak]`,languages[i].speak)
    // });



    // families.forEach((e,i) => {
    //     fd.append(`families[${i}][name]`,families[i].name)
    //     fd.append(`families[${i}][relationship]`,families[i].relationship)
    //     fd.append(`families[${i}][education]`,families[i].education)
    //     fd.append(`families[${i}][occupation]`,families[i].occupation)
    //     // if(families[i].file != null &&families[i].file?.name !== undefined){
    //     fd.append(`families[${i}][file]`,families[i]?.file !== null ? families[i]?.file : '')
    //     //  }

    // });


    // esiInfo?.forEach((e,i)=>{
    //     fd.append(`esiinfo[${i}][name]`,esiInfo[i].name)
    //     fd.append(`esiinfo[${i}][relationship]`,esiInfo[i].relationship)
    //     fd.append(`esiinfo[${i}][dob]`,esiInfo[i].dob)
    //     fd.append(`esiinfo[${i}][file]`,esiInfo[i].file)
    //     fd.append(`esiinfo[${i}][addhar_no]`,esiInfo[i].addhar_no)
    // })

    // fd.append('hr_policy_approval',hrPolicyDocsList?.check1 ? 1 : 0)
    // fd.append('self_declartion_form',hrPolicyDocsList?.check2  ? 1 : 0)
    // fd.append('employee_signature',hrPolicyDocsList?.signature)
    // fd.append('language',selected_language)

    // // if(hrPolicyDocsList.file != null && hrPolicyDocsList?.file?.name !== undefined){
    //     fd.append('video_recorded',hrPolicyDocsList?.file)
    // // }

    // if(hrPolicyDocsList?.id !== undefined){
    //     fd.append('hr_policy_approval_id',hrPolicyDocsList?.id)
    // }

    // console.log('body data',fd)

    
    // authHeader['headers']['Content-Type'] = 'multipart/form-data'
    authHeader['headers']['Content-Type'] = 'application/json'

    axios.post('fms/basicemployee/create',sendData,authHeader)
    .then((res)=>{
        resetallform()
        toast.success(res.data.message)
        // console.log('res data here na kp',res.data)
    }).catch((err)=>{
        // console.log('err data here na kp',err)
    })
  }

  function updatesubmit(){

    // const fd = new FormData()

    

    // fd.append('id',data.id)
    // fd.append('employee_id',data.employee_id !== 'FMS_' ? data.employee_id : '')
    // fd.append('name',data.name)
    // fd.append('father_name',data.father_name)
    // fd.append('form_type',data.form_type)
    // fd.append('designation',data.designation_id)
    // fd.append('date_of_joining',data.date_of_joining)
    // fd.append('total_work_experience',data.total_work_experience)
    // fd.append('department_branch_were_deployed',data.department_branch_were_deployed_id)
    // fd.append('date_of_birth',data.date_of_birth)
    // fd.append('gender',data.gender)
    // fd.append('mobile_no',data.mobile_no)
    // fd.append('emergency_contact',data.emergency_contact)
    // fd.append('personal_email_id',data.personal_email_id)
    // fd.append('blood_group',data.blood_group)
    // fd.append('nationality',data.nationality)
    // fd.append('martial_status',data.martial_status)
    // fd.append('present_address',data.present_address)
    // fd.append('permanent_address',data.permanent_address)
    // fd.append('is_verified',data.is_verified  == true ? 1 :0)
    // fd.append('is_approved',data.is_approved == true ? 1 :0)
    // fd.append('is_rejected',data.is_rejected  == true ? 1 :0)
    // fd.append('verified',data.verified  == true ? 1 :0)
    // fd.append('terminated',data.terminated  == true ? 1 :0)
    // fd.append('take_attendance',data.take_attendance  == true ? 1 :0)
    // fd.append('inactive_due_to_client',data.inactive_due_to_client  == true ? 1 :0)
    // fd.append('remarks',data.remarks)
    // fd.append('take_home',data.take_home)

    // fd.append('_method','PUT')

   
    // fd.append('addhar_card_no',data.addhar_card_no)
    // fd.append('pan_card_no',data.pan_card_no)
    // fd.append('previous_uan_pf_no',data.previous_uan_pf_no)
    // fd.append('previous_esi_no',data.previous_esi_no)
    // fd.append('employee_type',data.form_type)
    // data.ip_dispensary?.value !== undefined && fd.append('ip_dispensary',data.ip_dispensary?.value)
    // data.family_dispensary?.value !== undefined &&  fd.append('family_dispensary',data.family_dispensary?.value)


    // // if(data?.photo?.name !== undefined){
    //     fd.append('photo',data.photo !== null ? bank.bank_photo : '')
    // // }
    // // if(data?.signature?.name !== undefined){
    //     fd.append('signature',data.signature !== null ? bank.bank_photo : '')
    // // }
    // // if(data?.addhar_file?.name !== undefined){
    //     fd.append('addhar_file',data.addhar_file !== null ? bank.bank_photo : '')
    // // }
    // // if(data?.pan_file?.name !== undefined){
    //     fd.append('pan_file',data.pan_file !== null ? bank.bank_photo : '')
    // // }


    // fd.append('bank_id',bank.id)
    // fd.append('bank_name',bank.bank_name)
    // fd.append('account_no',bank.account_no)
    // fd.append('ifsc_code',bank.ifsc_code)
    // fd.append('bank_address',bank.bank_address)
    // // if(bank.bank_photo != null && bank?.bank_photo?.name !== undefined){
    //     fd.append('bank_photo',bank.bank_photo !== null ? bank.bank_photo : '')
    // // }


    // fd.append('document_id',check_list.id) 
    // fd.append('offer_letter_copy',check_list.offer_letter_copy ? 1 : 0 )
    // fd.append('appointment_letter_copy',check_list.appointment_letter_copy  ? 1 : 0 )
    // fd.append('employee_information_dic',check_list.employee_information_dic  ? 1 : 0 )
    // fd.append('policy_sign_off',check_list.policy_sign_off  ? 1 : 0 )
    // fd.append('passport_size_photo',check_list.passport_size_photo  ? 1 : 0 )
    // fd.append('resume_of_employee',check_list.resume_of_employee  ? 1 : 0 )
    // fd.append('education_certificate',check_list.education_certificate  ? 1 : 0 )
    // fd.append('previous_employee_certificate',check_list.previous_employee_certificate  ? 1 : 0 )
    // fd.append('previous_salary_proof',check_list.previous_salary_proof  ? 1 : 0 )
    // fd.append('id_proof',check_list.id_proof  ? 1 : 0 )
    // fd.append('pan_card',check_list.pan_card  ? 1 : 0 )
    // fd.append('addhar_card',check_list.addhar_card  ? 1 : 0 )
    // fd.append('address_proof_permanent',check_list.address_proof_permanent  ? 1 : 0 )
    // fd.append('address_proof_current',check_list.address_proof_current  ? 1 : 0 )
    // fd.append('bank_proof',check_list.bank_proof  ? 1 : 0 )
    // fd.append('generate_docs',data?.generate_docs ? 1 : 0)


    // languages.forEach((e,i) => {
    //     fd.append(`languages[${i}][language]`,languages[i].language)
    //     fd.append(`languages[${i}][read]`,languages[i].read)
    //     fd.append(`languages[${i}][write]`,languages[i].write)
    //     fd.append(`languages[${i}][speak]`,languages[i].speak)
    // });

    // families.forEach((e,i) => {
    //     fd.append(`families[${i}][id]`,families[i].id)
    //     fd.append(`families[${i}][name]`,families[i].name)
    //     fd.append(`families[${i}][relationship]`,families[i].relationship)
    //     fd.append(`families[${i}][education]`,families[i].education)
    //     fd.append(`families[${i}][occupation]`,families[i].occupation)
    //     fd.append(`families[${i}][age]`,families[i].age)
    //     fd.append(`families[${i}][file]`,families[i].file)
    // });

    // esiInfo?.forEach((e,i)=>{
    //     fd.append(`esiinfo[${i}][name]`,esiInfo[i].name)
    //     fd.append(`esiinfo[${i}][relationship]`,esiInfo[i].relationship)
    //     fd.append(`esiinfo[${i}][dob]`,esiInfo[i].dob)
    //     fd.append(`esiinfo[${i}][file]`,esiInfo[i].file)
    //     fd.append(`esiinfo[${i}][addhar_no]`,esiInfo[i].addhar_no)
    // })

    // fd.append('selected_language',selected_language)
    // fd.append('hr_policy_approval',hrPolicyDocsList?.check1 ? 1 : 0)
    // fd.append('self_declartion_form',hrPolicyDocsList?.check2  ? 1 : 0)
    // fd.append('employee_signature',hrPolicyDocsList?.signature)

    // // if(hrPolicyDocsList.file != null && hrPolicyDocsList?.file?.name !== undefined){
    //     fd.append('video_recorded',hrPolicyDocsList?.file)
    // // }

    // if(hrPolicyDocsList?.id !== undefined){
    //     fd.append('hr_policy_approval_id',hrPolicyDocsList?.id)
    // }

    const languagesArr = [] 
    const familiesArr = [] 
    const esiArr = [] 
    
    languages?.forEach((l)=>{
        languagesArr.push({
            'language':l?.language,
            'read':l?.read,
            'write':l?.write,
            'speak':l?.speak
         })
    })

    families?.forEach((f)=>{
        familiesArr.push({
            'id':f?.id,
            'name':f?.name,
            'relationship':f?.relationship,
            'education':f?.education,
            'occupation':f?.occupation,
            'file':f?.file
        })
    })

    esiInfo?.forEach((e)=>{
        esiArr.push({
            'name':e?.name,
            'relationship':e?.relationship,
            'dob':e?.dob,
            'file':e?.file,
            'addhar_no':e?.addhar_no,
        })
    })

    let rolesArr = []
    data?.roles?.forEach((d)=>{
        rolesArr?.push(d?.id)
    })

    const sendData = {
        'id':data?.id,
        'employee_id':data?.employee_id !== 'FMS_' ? data?.employee_id : '',
        'name':data?.name,
        'father_name':data?.father_name,
        'form_type':data.form_type,
        'designation':data.designation_id,
        'date_of_joining':data.date_of_joining,
        'total_work_experience':data.total_work_experience,
        'department_branch_were_deployed':data.department_branch_were_deployed_id,
        'date_of_birth':data.date_of_birth,
        'gender':data.gender,
        'mobile_no':data.mobile_no,
        'emergency_contact':data.emergency_contact,
        'personal_email_id':data.personal_email_id,
        'blood_group':data.blood_group,
        'nationality':data.nationality,
        'martial_status':data.martial_status,
        'present_address':data.present_address,
        'permanent_address':data.permanent_address,
        'addhar_card_no':data.addhar_card_no,
        'pan_card_no':data.pan_card_no,
        'previous_uan_pf_no':data.previous_uan_pf_no,
        'previous_esi_no':data.previous_esi_no,
        'employee_type':data.form_type,
        'is_verified':data.is_verified,
        'is_approved':data.is_approved,
        'is_rejected':data.is_rejected,
        'terminated':data.terminated,
        'take_attendance':data.take_attendance,
        'take_home':data.take_home,
        'inactive_due_to_client':data.inactive_due_to_client,
        'photo':data.photo,
        'signature':data.signature,
        'addhar_file':data.addhar_file,
        'pan_file':data.pan_file,
        'insurance_or_esi':data.insurance_or_esi,
        
        'bank_id':bank.id,
        'bank_name':bank.bank_name,
        'account_no':bank.account_no,
        'ifsc_code':bank.ifsc_code,
        'bank_address':bank.bank_address,
        'bank_photo':bank.bank_photo,

        'document_id':check_list.id,
        'offer_letter_copy':check_list.offer_letter_copy,
        'appointment_letter_copy':check_list.appointment_letter_copy,
        'employee_information_dic':check_list.employee_information_dic,
        'policy_sign_off':check_list.policy_sign_off,
        'passport_size_photo':check_list.passport_size_photo,
        'resume_of_employee':check_list.resume_of_employee,
        'education_certificate':check_list.education_certificate,
        'previous_employee_certificate':check_list.previous_employee_certificate,
        'previous_salary_proof':check_list.previous_salary_proof,
        'id_proof':check_list.id_proof,
        'pan_card':check_list.pan_card,
        'addhar_card':check_list.addhar_card,
        'address_proof_permanent':check_list.address_proof_permanent,
        'address_proof_current':check_list.address_proof_current,
        'bank_proof':check_list.bank_proof,

        'hr_policy_approval':hrPolicyDocsList?.check1,
        'self_declartion_form':hrPolicyDocsList?.check2,
        'employee_signature':hrPolicyDocsList?.signature,
        'language':selected_language,
        'video_recorded':hrPolicyDocsList?.file,
        'languages':languagesArr,
        'families':familiesArr,
        'esiInfo':esiArr,
        'remarks':data?.remarks,
        'generate_docs':data?.generate_docs ? 1 : 0,
        'app_access':data?.app_access,
        'upload_to_excel':data?.upload_to_excel,
        'password':data?.password,
        'modified':data?.modified,
        'roles':rolesArr
    } 

    if(data?.salary_structure !== '' && data?.salary_structure !== undefined){
        sendData['salary_structure'] = data?.salary_structure?.value !== undefined ?  data?.salary_structure?.value : data?.salary_structure
    }

    if(data.ip_dispensary?.value !== undefined ){
        sendData['ip_dispensary'] = data.ip_dispensary?.value
    }

    if(data.family_dispensary?.value !== undefined ){
        sendData['family_dispensary'] = data.family_dispensary?.value
    }

    if(data.family_dispensary?.value !== undefined ){
        sendData['family_dispensary'] = data.family_dispensary?.value
    }

    let remarks = '' 
    let type = ''

    if(bank.account_no !== oldData?.employee_banks[0]?.account_no){
        if(oldData.is_verified && oldData.is_approved && roles?.includes('fms_user')){
            sendData['modified'] = true
        }
        remarks += ` Account No Modified From : ${oldData?.employee_banks[0]?.account_no} - ${bank.account_no} ,`

    }
    if(bank.bank_name !== oldData?.employee_banks[0]?.bank_name){
        remarks += ` Bank Name Modified From : ${oldData?.employee_banks[0]?.bank_name} - ${bank.bank_name} ,`
        if(oldData.is_verified && oldData.is_approved && roles?.includes('fms_user')){
            sendData['modified'] = true
        }
    }
    if(bank.ifsc_code !== oldData?.employee_banks[0]?.ifsc_code){
        remarks += ` IFSC Code Modified From : ${oldData?.employee_banks[0]?.ifsc_code} - ${bank.ifsc_code} ,`
        if(oldData.is_verified && oldData.is_approved && roles?.includes('fms_user')){
            sendData['modified'] = true
        }
    }
    if(bank.bank_photo !== oldData?.employee_banks[0]?.bank_photo){
        remarks += ` Bank Passbook Modified From : ${oldData?.employee_banks[0]?.bank_photo} - ${bank.bank_photo} ,`
        if(oldData.is_verified && oldData.is_approved && roles?.includes('fms_user')){
            sendData['modified'] = true
        }
    }


  

    if(data.name !== oldData.name) {
        remarks += `Name Modified From : ${oldData?.name} - ${data.name} ,`
        type += ` Name Modified , `
    }

    if((data.addhar_card_no !== oldData.addhar_card_no) || (data.addhar_file !== oldData.addhar_file)) {
        if((data.addhar_card_no !== oldData.addhar_card_no)){
            remarks += `Addhar No Modified From : ${oldData?.addhar_card_no} - ${data.addhar_card_no} ,`
            type += ` Addhar No Modified , `

        }
        if((data.addhar_file !== oldData.addhar_file)){
            remarks += `Addhar File Modified From : ${oldData?.addhar_file} - ${data.addhar_file} ,`
            type += ` Addhar File Modified , `

        }

    }

    if((data.pan_card_no !== oldData.pan_card_no) || (data.pan_file !== oldData.pan_file)) {
        if((data.pan_card_no !== oldData.pan_card_no)){
            remarks += `Pan No Modified From : ${oldData?.pan_card_no} - ${data.pan_card_no} ,`
            type += ` Pan No Modified , `

        }
        if((data.pan_file !== oldData.pan_file)){
            remarks += `Pan File Modified From : ${oldData?.pan_file} - ${data.pan_file} ,`
            type += ` Pan File Modified , `

        }
    }

    sendData['modified_remarks'] = remarks
    sendData['modified_type'] = type


    sendData['updated_by'] = userId
   
    authHeader['headers']['Content-Type'] = 'application/json'

    axios.post(`fms/basicemployee/update/${data.id}`,sendData,authHeader)
    .then((res)=>{
        resetallform()
        setTimeout(() => {
            navigate(-1)
        }, 1500);
        toast.success(res.data.message)
    }).catch(err=>{
        if(err.response?.status == 403){
          setActiveStep(0)
          seterror({...error,employee_id_err:err?.response?.data?.message})
        }
    })

  }
  
  function adddispensary(){
    if(!data.ip_dispensary){
        seterror({...error,ip_dispensary:'This Field id required *'})
    }else if(!data.family_dispensary){
        seterror({...error,family_dispensary:'This Field id required *'})
    }else{
        setsubActiveStep(0)
        set_drawer(false)
    }
  }

  function uploadFile(name,v){
   
    authHeader['headers']['Content-Type'] = 'multipart/form-data'
    var fd = new FormData()
    fd.append('file',v?.fileList[v?.fileList.length - 1]?.originFileObj)
    axios.post(`fms/basicemployee/upload_file`,fd,authHeader)
    .then((res)=>{
        if(name === 'photo'){
            setdata({...data,photo:res?.data?.data})
            seterror({...error,photo_err:''})
        }else if(name == 'signature'){
            setdata({...data,signature:res?.data?.data})
            seterror({...error,signature_err:''})
        }else if(name == 'addhar_file'){
            setdata({...data,addhar_file:res?.data?.data})
        }else if(name == 'pan_file'){
            setdata({...data,pan_file:res?.data?.data})
        }else if(name == 'bank_photo'){
            setbank({...bank,bank_photo:res?.data?.data})
            setbank_err({...bank_err,bank_photo:''})
        }else if(name == 'bank_photo'){
            setbank({...bank,bank_photo:res?.data?.data})
            setbank_err({...bank_err,bank_photo:''})
        }else if(name == 'family_addhar'){
           setfamily({...family,file:res?.data?.data})
        }
       
    }).catch((err)=>{
        console.log("err data",err)
    })
  }

//   function submitloopdata(){

//     for(let i=0;i<25;i++){
//         setInterval(() => {
//             finalsubmit()
//          }, 2000);
//     }
    
//   }

//  console.log('data approved',data.is_approved)

  function resetallform(){
    setActiveStep(0)
    setdata({id:'',employee_id:'',father_name:'',name:'',designation:'',date_of_joining:'',total_work_experience:'',department_branch_were_deployed:'',department_branch_were_deployed_id:'',date_of_birth:'',gender:'',mobile_no:'',emergency_contact:'',personal_email_id:'',blood_group:'',nationality:'Indian',martial_status:'',present_address:'',permanent_address:'',photo:null,signature:null,addhar_card_no:'',addhar_file:null,pan_card_no:'',pan_file:null,previous_uan_pf_no:'',previous_esi_no:'',form_type:'',is_verified:false,is_approved:false,modified:false,take_attendance:true,is_rejected:false,remarks:'',take_home:'',password:'',app_access:false,roles:[],insurance_or_esi:'',salary_structure:''})
    setlanguages([])
    setfamilies([])
    setesiInfo([])
    setesi_info({name:'',relationship:'',dob:'',age:'',addhar_no:'',file:null})
    setbank({bank_name:'',account_no:'',ifsc_code:'',bank_address:'',bank_photo:null,id:null})
    setcheck_list({offer_letter_copy:false,appointment_letter_copy:false,employee_information_dic:false,policy_sign_off:false,passport_size_photo:false,resume_of_employee:false,education_certificate:false,previous_employee_certificate:false,previous_salary_proof:false,id_proof:false,pan_card:false,addhar_card:false,address_proof_permanent:false,address_proof_current:false,bank_proof:false,id:null})
    setselected_language('EN')
    getHrPolicyDocs(selected_language)
  }

  function handleChange1(selectedOptions,datakey_name){   
    const array = [] 
    if(selectedOptions){
        selectedOptions.forEach((t) => {
            array.push(t);
          });
    }
    setdata({...data,[datakey_name]:array})
}

  async function searchdesignation(e){
    if(e?.length > 2){
        await axios.get(`fms/fmsdesignationonboardemployee/search?search_text=${e}`,authHeader)
        .then((res)=>{
            let arr = []
            res.data.datas.forEach((d)=>{
                let obj = {
                    value : d._id,
                    label:d.designation_name
                }
                arr.push(obj)
            })
            setfmsemployee_designation(arr)

            return arr.filter((i) =>
                i.label.toLowerCase().includes(e.toLowerCase())
                );

        }).catch((err)=>{
        })
    }
  }

  async function searchclients(e){
    if(e?.length > 2){
        await axios.get(`fms/fmsclients/search?search_text=${e}`,authHeader)
        .then((res)=>{
            let arr = []
            res.data.datas.forEach((d)=>{
                let obj = {
                    value : d._id,
                    label:d.client_name
                }
                arr.push(obj)
            })
            setfmsclients(arr)

            return arr.filter((i) =>
                i.label.toLowerCase().includes(e.toLowerCase())
                );

        }).catch((err)=>{
        })
    }
  }

  async function searchdispensary(e){
    if(e?.length > 2){
        axios.get(`fms/fmsdispensary/search?search_text=${e}`,authHeader)
        .then((res)=>{
            let arr = []
            res.data.datas?.forEach((d)=>{
                arr.push({
                    value : d._id,
                    label:d.name
                })
            })
            setdispensaryArr(arr)
        }).catch((err)=>{
            // console.log('err data here na',err)
        })

        
    }
  }

  async function verifydob(v){
    // console.log("v here",v !== '')
    if(v !== ''){
        const dob = new Date(v).getTime();
        const dateToCompare = new Date(new Date()).getTime();
        const age = (dateToCompare - dob) / (365 * 24 * 60 * 60 * 1000);
        let myAge = Math.floor(age)

        // console.log("myAge",myAge < 18 || myAge > 56)
        if(myAge < 18 || myAge > 56){
            seterror({...error,date_of_birth_err:'We cant onboard you because your age does not lies bw 18 or 56'})
        }else{
            seterror({...error,date_of_birth_err:''})
        }
    }
  }

//   console.log("error",error)

 

  

    return(
          <FmsDashboardMainRoot>
            <Toaster />

              <SwipeableDrawer
                anchor='right'
                style={{minWidth:'200px',maxWidth:'200px',zIndex:10}}
                    open={drawer}
                    onClose={()=>set_drawer(!drawer)}
                > 
                        <div style={{width:'300px',padding:'30px'}}> 
                           {activeStep == 1 &&
                           <div>
                            <BoldHeadText  val="Language Form" fontSize={'15px'} fontWeight='900'/>
                            <p style={{borderBottom:'1px solid #eee'}}></p>
                            <div style={{marginBottom:'10px'}}>
                            <div style={{width:'150px'}}>
                            <SmallText fontWeight={'600'} val={'Choose Language'}  />
                            </div>
                            <Select  style={{borderLeft:`4px solid ${theme_color}`,width:'100%',zIndex:100,fontSize:'13px',fontWeight:'600'}} value={language?.language}  onChange={(e)=>{setlanguage({...language,language:e});setlanguage_err({...language_err,language_err1:''})}} >
                            {languages_Arr?.map((l)=>(
                                <Option style={{zIndex:100,marginBottom:'0px',fontWeight:'500',fontSize:'13px'}} key={l} value={l}>{l}</Option>
                                ))}
                            </Select>  
                            <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                                {(language_err?.language_err1) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{language_err?.language_err1  }</p>
                            </div> 
                            </div>

                            <p style={{marginTop:'20px'}}></p>
                            
                            <p>
                                <p className={'checked_here2'} style={{cursor:'pointer'}} onClick={()=>setlanguage({...language,read:!language.read})}>
                                {language.read && <p className='checked_here1' style={{background:theme_color}}></p>}
                                <p style={{marginLeft:'25px'}}>
                                <SmallText val='READ' />
                                </p>
                            </p>
                            </p>

                            <p>
                                <p className={'checked_here2'} style={{cursor:'pointer'}} onClick={()=>setlanguage({...language,write:!language.write})}>
                                {language.write &&  <p className='checked_here1' style={{background:theme_color}}></p>}
                                <p style={{marginLeft:'25px'}}>
                                <SmallText val='WRITE' />
                                </p>
                            </p>
                            </p>

                            <p>
                                <p className={'checked_here2'} style={{cursor:'pointer'}} onClick={()=>setlanguage({...language,speak:!language.speak})}>
                                {language.speak &&  <p className='checked_here1' style={{background:theme_color}}></p>}
                                <p style={{marginLeft:'25px'}}>
                                <SmallText val='SPEAK' />
                                </p>
                            </p>
                            </p>
                        
                            <PrimaryButton btn_name={'Continue'} onClick={addlanguage} />
                            

                           </div>
                           }

                          {activeStep == 2 &&
                          <>
                           {subactiveStep == 0 ?
                           <div>
                            <BoldHeadText  val="Family Detail Form" fontSize={'15px'} fontWeight='900'/>
                            <p style={{borderBottom:'1px solid #eee'}}></p>
                              
                               <div style={{marginBottom:'10px'}}>
                                <div style={{width:'150px'}}>
                                <SmallText fontWeight={'600'} val={'Name'}  />
                                </div>
                                <Input type="text" style={{borderLeft:`4px solid ${theme_color}`}}  value={family.name} onChange={(e)=>{setfamily({...family,name:e.target.value});setfamily_err({...family_err,name_err:''})}} />
                                <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                                {(family_err?.name_err) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{family_err?.name_err  }</p>
                                </div> 
                                </div>


                                <div style={{marginBottom:'10px'}}>
                                <div style={{width:'150px'}}>
                                <SmallText fontWeight={'600'} val={'Relationship'}  />
                                </div>
                                <Select  style={{borderLeft:`4px solid ${theme_color}`,width:'100%',zIndex:100,fontSize:'13px',fontWeight:'600'}} value={family.relationship}  onChange={(e)=>{setfamily({...family,relationship:e});setfamily_err({...family_err,relationship_err:''})}} >
                                {relationship_Arr?.map((l)=>(
                                    <Option style={{zIndex:100,marginBottom:'0px',fontWeight:'500',fontSize:'13px'}} key={l} value={l}>{l}</Option>
                                    ))}
                                </Select>   
                                <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                                {(family_err?.relationship_err) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{family_err?.relationship_err  }</p>
                                </div>
                                
                                </div>


                                <div style={{marginBottom:'10px'}}>
                                <div style={{width:'150px'}}>
                                <SmallText fontWeight={'600'} val={'Education'}  />
                                </div>
                                <Input type="text"   value={family.education} onChange={(e)=>setfamily({...family,education:e.target.value})} />
                                <div>
                                {(family_err?.education_err) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{family_err?.education_err  }</p>
                                </div>
                                </div>


                                <div style={{marginBottom:'10px'}}>
                                <div style={{width:'150px'}}>
                                <SmallText fontWeight={'600'} val={'Occupation'}  />
                                </div>
                                <Input type="text"   value={family.occupation} onChange={(e)=>setfamily({...family,occupation:e.target.value})} />
                                <div>
                                {(family_err?.occupation_err) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{family_err?.occupation_err  }</p>
                                </div>
                                </div>

                                <div style={{marginBottom:'10px',width:'200px'}}>
                                <div style={{width:'150px'}}>
                                <SmallText fontWeight={'600'} val={`Addhar Card`} />
                                </div>
                                {(family?.file === null || family?.file == undefined || family?.file === '') ?
                                <Upload showUploadList={false}  onChange={(v)=>uploadFile("family_addhar",v)} style={{width:'200px'}}>
                                    {/* ,borderLeft:`4px solid ${theme_color}` */}
                                    <Button style={{width:'240px',display:'flex',alignSelf:'flex-end',justifyContent:'end'}} icon={<UploadOutlined   style={{borderLeft:'1px solid #eee',color:'#999',paddingLeft:'0px'}}/>}></Button>
                                </Upload>
                                :
                                <Button onClick={()=>{setfamily({...family,file:''});setfamily_err({...family_err,file_err:''})}}  style={{width:'240px',display:'flex',alignItems:'center',fontSize:'10px',marginBottom:'0px',borderLeft:`4px solid ${theme_color}`}} ><p style={{marginBottom:'0px', wordBreak: 'break-word',whiteSpace:'nowrap',width:'90%',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px',textAlign:'left'}}>{(family?.file?.name != undefined && family?.file?.name !== '')  ? family?.file?.name : family?.file?.split('/')[family?.file?.split('/').length-1] }</p><CloseOutlined size={15}  style={{borderLeft:'1px solid #eee',color:'#999',paddingLeft:'0px',position:'absolute',right:'0px'}}/></Button>
                                }
                                <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                                {(family_err?.file_err) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{family_err?.file_err}</p>
                                </div>
                                </div>

                                {activeStep == 2 &&
                                <div style={{marginBottom:'10px'}}>
                                <div style={{width:'150px'}}>
                                <SmallText fontWeight={'600'} val={'Age'}  />
                                </div>
                                <Input type="text" value={family.age} onChange={(e)=>setfamily({...family,age:e.target.value})} /><div>
                                {(family_err?.age_err) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{family_err?.age_err  }</p>
                                </div>
                                </div>}

                                {activeStep == 4 &&
                                <div style={{marginBottom:'10px'}}>
                                <div style={{width:'150px'}}>
                                <SmallText fontWeight={'600'} val={'DOB'}  />
                                </div>
                                <DatePicker style={{width:'100%',borderLeft:`4px solid ${theme_color}`}}  placeholder='' value={data.date_of_joining !== '' ? moment(data.date_of_joining) : null} onChange={(v,v1)=>{setdata({...data,date_of_joining:v1});seterror({...error,date_of_joining_err:''})}}/>
                                {/* <Input type="text" value={family.age} onChange={(e)=>setfamily({...family,age:e.target.value})} /> */}
                                <div>
                                {(family_err?.age_err) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{family_err?.age_err  }</p>
                                </div>
                                </div>}

                            <p style={{marginTop:'1px'}}></p>
                            
                            <PrimaryButton btn_name={'Continue'} onClick={addfamily} />
                            

                          </div>
                           :
                           <div>
                            <BoldHeadText  val="Add/Edit Dispensary Info" fontSize={'15px'} fontWeight='900'/>

                            <div style={{justifyContent:'space-between',marginRight:'20px'}}>
                                <div style={{marginBottom:'10px'}}>
                                <div style={{width:'150px'}}>
                                <SmallText fontWeight={'600'} val={'IP Dispensary'} />
                                </div>
                                <Select 
                                allowClear={true} 
                                filterOption={false} 
                                options={dispensaryArr} 
                                showSearch={true}
                                onSearch={(e)=>{searchdispensary(e)}}
                                style={{width:'100%',zIndex:100,fontSize:'13px',fontWeight:'600'}}
                                value={data.ip_dispensary}
                                onChange={(e)=>{setdata({...data,ip_dispensary:dispensaryArr.find((c)=>c.value === e)});seterror({...error,ip_dispensary:''})}}  
                                />
                                
                                <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                                {(error?.ip_dispensary) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.ip_dispensary  }</p>
                                </div>
                                </div>
                                
                                <div style={{marginBottom:'10px'}}>
                                    <div style={{width:'150px'}}>
                                    <SmallText fontWeight={'600'} val={'Family Dispensary'} />
                                    </div>
                                    {/* <Input type="text"  style={{borderLeft:`4px solid ${theme_color}`}} value={data.family_dispensary} onChange={(e)=>{setdata({...data,family_dispensary:e.target.value});seterror({...error,family_dispensary:''})}} /> */}
                                    <Select 
                                    allowClear={true} 
                                    filterOption={false} 
                                    options={dispensaryArr} 
                                    showSearch={true}
                                    onSearch={(e)=>{searchdispensary(e)}}
                                    style={{width:'100%',zIndex:100,fontSize:'13px',fontWeight:'600'}}
                                    value={data.family_dispensary}
                                    onChange={(e)=>{setdata({...data,family_dispensary:dispensaryArr.find((c)=>c.value === e)});seterror({...error,family_dispensary:''})}}  
                                    />

                                    <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                                    {(error?.family_dispensary) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                                    <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.family_dispensary  }</p>
                                    </div>
                                </div>

                               <PrimaryButton btn_name={'Continue'} onClick={adddispensary} />

                            </div>    

                           </div>}
                          </>
                          }

                          {activeStep == 4 &&
                           <div>
                            <BoldHeadText  val="Esi Family Detail" fontSize={'15px'} fontWeight='900'/>
                            <p style={{borderBottom:'1px solid #eee'}}></p>
                              
                               <div style={{marginBottom:'10px'}}>
                                <div style={{width:'150px'}}>
                                <SmallText fontWeight={'600'} val={'Name'}  />
                                </div>
                                <Input type="text" style={{borderLeft:`4px solid ${theme_color}`}}  value={esi_info.name} onChange={(e)=>{setesi_info({...esi_info,name:e.target.value});setesi_info_err({...esi_info_err,name:''})}} />
                                <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                                {(esi_info_err?.name) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{esi_info_err?.name  }</p>
                                </div> 
                                </div>


                                <div style={{marginBottom:'10px'}}>
                                <div style={{width:'150px'}}>
                                <SmallText fontWeight={'600'} val={'Relationship'}  />
                                </div>
                                <Select  style={{borderLeft:`4px solid ${theme_color}`,width:'100%',zIndex:100,fontSize:'13px',fontWeight:'600'}} value={esi_info.relationship}  onChange={(e)=>{setesi_info({...esi_info,relationship:e});setesi_info_err({...esi_info_err,relationship:''})}} >
                                {relationship_Arr?.map((l)=>(
                                    <Option style={{zIndex:100,marginBottom:'0px',fontWeight:'500',fontSize:'13px'}} key={l} value={l}>{l}</Option>
                                    ))}
                                </Select>   
                                <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                                {(esi_info_err?.relationship) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{esi_info_err?.relationship}</p>
                                </div>
                                
                                </div>


                                <div style={{marginBottom:'10px'}}>
                                <div style={{width:'150px'}}>
                                <SmallText fontWeight={'600'} val={'Addhar No'}  />
                                </div>
                                <Input type="text" style={{width:'100%',borderLeft:`4px solid ${theme_color}`}}  value={esi_info.addhar_no} onChange={(e)=>setesi_info({...esi_info,addhar_no:e.target.value})} />
                                <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                                {(esi_info_err?.addhar_no) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{esi_info_err?.addhar_no  }</p>
                                </div>
                                </div>
                               

                                <div style={{marginBottom:'10px'}}>
                                <div style={{width:'150px'}}>
                                <SmallText fontWeight={'600'} val={'DOB'}  />
                                </div>
                                <DatePicker style={{width:'100%',borderLeft:`4px solid ${theme_color}`}}  placeholder='' value={esi_info.dob !== '' ? moment(esi_info.dob) : null} onChange={(v,v1)=>{setesi_info({...esi_info,dob:v1});setesi_info_err({...error,esi_info_err:''})}}/>
                                {/* <Input type="text" value={family.age} onChange={(e)=>setfamily({...family,age:e.target.value})} /> */}
                                <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                                {(esi_info_err?.dob) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{esi_info_err?.dob}</p>
                                </div>
                                </div>


                                <div style={{marginBottom:'10px'}}>
                                <div style={{width:'150px'}}>
                                <SmallText fontWeight={'600'} val={'File'}  />
                                </div>
                                {esi_info?.file === null ?
                                <Upload showUploadList={false} onChange={(v)=>uploadFile(v)} style={{borderLeft:`4px solid ${theme_color}`,width:'240px'}} >
                                    <Button style={{borderLeft:`4px solid ${theme_color}`,width:'240px',display:'flex',alignSelf:'flex-end',justifyContent:'end'}} icon={<UploadOutlined   style={{borderLeft:'1px solid #eee',color:'#999',paddingLeft:'0px'}}/>}></Button>
                                </Upload>
                                :
                                <Button  onClick={()=>setesi_info({...esi_info,file:null})} style={{borderLeft:`4px solid ${theme_color}`,width:'240px',display:'flex',alignItems:'left',fontSize:'10px',marginBottom:'0px'}} ><p style={{marginBottom:'0px', wordBreak: 'break-word',whiteSpace:'nowrap',width:'90%',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px',textAlign:'left',marginBottom:'0px'}}>{esi_info.file}</p><CloseOutlined size={15}  style={{borderLeft:'1px solid #eee',color:'#999',paddingLeft:'0px',position:'absolute',right:'0px'}}/></Button>
                                }
                                <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                                {(error?.photo_err) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.photo_err  }</p>
                                </div>
                                </div>


                            <p style={{marginTop:'1px'}}></p>
                            
                            <PrimaryButton btn_name={'Continue'} onClick={addesiInfo} />
                            

                          </div>
                          }
                        </div>
              </SwipeableDrawer>

              <BoldText1 val={'Employee Personal Information'} />
              <p style={{borderBottom:'1px solid #eee'}}></p>
              
              <div style={{display:'flex'}}>
              <div style={{borderRight:'1px solid #eee',marginRight:'40px',paddingRight:'30px'}}>
              <Stepper activeStep={activeStep} orientation="vertical" >
                {steps.map((step, index) => (
                <Step key={step.label}>
                    <StepLabel StepIconProps={{style: {theme_color}}} >
                    <div style={{marginTop:'16px',maxWidth:'150px'}}>
                    <BoldHeadText fontWeight={'900'} val={step.label} marginfun={'2px 0px'} fontSize={'13px'} />
                    <SmallText val = {step.description} fontSize={'11px'}/>
                    </div>  
                    </StepLabel>
                </Step>
                ))}
              </Stepper>
              </div> 

               {/* step 1 */}
               {activeStep == 0 &&
               <div>
              <div style={{display:'flex',justifyContent:'space-between'}}>
                <div style={{width:'300px',marginTop:'20px'}}> 


                <BoldHeadText fontWeight={'900'} fontSize='15px' val={'Basic Info'} />
                <p style={{height:'6px'}}></p>
                {(roles.includes('fms_hr') || roles.includes('admin') || roles.includes('controller'))  &&
                <div style={{marginBottom:'10px'}}>
                <div style={{width:'150px'}}>
                <SmallText fontWeight={'600'} val={'Employee ID'} />
                </div>
                <Input type="text"  style={{borderLeft:`4px solid ${theme_color}`}} value={data.employee_id} onChange={(e)=>{setdata({...data,employee_id:e.target.value});seterror({...error,employee_id_err:''})}} />
                <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                {(error?.employee_id_err) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.employee_id_err  }</p>
                </div>
                </div>}

                <div style={{marginBottom:'10px'}}>
                <div style={{width:'150px'}}>
                <SmallText fontWeight={'600'} val={'Employee Name'} />
                </div>
                <Input type="text"  style={{borderLeft:`4px solid ${theme_color}`}} value={data.name} onChange={(e)=>{setdata({...data,name:e.target.value});seterror({...error,nameerr:''})}} />
                <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                {(error?.nameerr) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.nameerr  }</p>
                </div>
                </div>

                <div style={{marginBottom:'10px'}}>
                <div style={{width:'150px'}}>
                <SmallText fontWeight={'600'} val={'Father/Guardian Name'} />
                </div>
                <Input type="text"  style={{borderLeft:`4px solid ${theme_color}`}} value={data.father_name} onChange={(e)=>{setdata({...data,father_name:e.target.value});seterror({...error,father_nameerr:''})}} />
                <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                {(error?.father_nameerr) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.father_nameerr  }</p>
                </div>
                </div>

                <div style={{marginBottom:'10px'}}>
                <div style={{width:'150px'}}>
                <SmallText fontWeight={'600'} val={'Form Type'} />
                </div>
                <Select  style={{borderLeft:`4px solid ${theme_color}`,width:'100%',zIndex:100,fontSize:'13px',fontWeight:'600'}} value={data.form_type}  onChange={(e)=>{setdata({...data,form_type:e});seterror({...error,form_type_err:''})}} >
                {form_type?.map((l)=>(
                    <Option style={{zIndex:100,marginBottom:'0px',fontWeight:'500',fontSize:'13px'}} key={l} value={l}>{l}</Option>
                ))}
                </Select>  
                <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                {(error?.form_type_err) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.form_type_err  }</p>
                </div>
                </div>

                <div style={{marginBottom:'10px'}}>
                <div style={{width:'150px'}}>
                <SmallText fontWeight={'600'} val={'Designation'}  />
                </div>
                <Select allowClear={true} filterOption={false} options={fmsemployee_designation} showSearch={true} onSearch={(e)=>{searchdesignation(e)}}  style={{borderLeft:`4px solid ${theme_color}`,width:'100%',zIndex:100,fontSize:'13px',fontWeight:'600'}}  value={data?.designation}  onChange={(e)=>{setdata({...data,designation:fmsemployee_designation.find((c)=>c?.value === e),designation_id:e});seterror({...error,designation_err:''})}} />
                   
         
                
                <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                {(error?.designation_err) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.designation_err  }</p>
                </div>
                </div>


                <div style={{marginBottom:'10px'}}>
                <div style={{width:'150px'}}>
                <SmallText fontWeight={'600'} val={'Date of Joining'}  />
                </div>
                <DatePicker style={{width:'100%',borderLeft:`4px solid ${theme_color}`}}  placeholder='' value={data.date_of_joining !== '' ? moment(data.date_of_joining) : null} onChange={(v,v1)=>{setdata({...data,date_of_joining:v1});seterror({...error,date_of_joining_err:''})}}/>
                {/* <Input type="text"  value={data.name} onChange={(e)=>setdata({...data,name:e.target.value})} /> */}
                <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                {(error?.date_of_joining_err) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.date_of_joining_err  }</p>
                </div>
                </div>

               

                <div style={{marginBottom:'10px'}}>
                <div style={{width:'150px'}}>
                <SmallText fontWeight={'600'} val={'Date of Birth '} />
                </div>
                <DatePicker style={{width:'100%',borderLeft:`4px solid ${theme_color}`}}  placeholder='' value={data.date_of_birth !== '' ? moment(data.date_of_birth) : null} onChange={(v,v1)=>{setdata({...data,date_of_birth:v1});verifydob(v1)}}/>
                {/* <Input type="text"  value={data.name} onChange={(e)=>setdata({...data,name:e.target.value})} /> */}
                <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                {(error?.date_of_birth_err) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.date_of_birth_err}</p>
                </div>
                </div>


                <div style={{marginBottom:'10px'}}>
                <div style={{width:'150px'}}>
                <SmallText fontWeight={'600'} val={'Gender (M/F/O)'} />
                </div>
                <Select  style={{borderLeft:`4px solid ${theme_color}`,width:'100%',zIndex:100,fontSize:'13px',fontWeight:'600'}}  value={data.gender} onChange={(e)=>{setdata({...data,gender:e});seterror({...error,gender_err:''})}} >
                    {gender_Arr?.map((l)=>(
                        <Option style={{zIndex:100,marginBottom:'0px',fontWeight:'500',fontSize:'13px'}} key={l} value={l}>{l}</Option>
                        ))}
                </Select>  
                <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                {(error?.gender_err) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.gender_err  }</p>
                </div>
                {/* <Input type="text" style={{borderLeft:`4px solid ${theme_color}`}} value={data.name} onChange={(e)=>setdata({...data,name:e.target.value})} /> */}
                </div>

                <div style={{marginBottom:'10px'}}>
                <div style={{width:'150px'}}>
                <SmallText fontWeight={'600'} val={'Mobile Number'} />
                </div>
                <Input type="text" style={{borderLeft:`4px solid ${theme_color}`}} value={data.mobile_no} onChange={(e)=>{setdata({...data,mobile_no:e.target.value});seterror({...error,mobile_no_err:''})}} />
                <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                {(error?.mobile_no_err) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.mobile_no_err  }</p>
                </div>
                </div>

                <div style={{marginBottom:'10px'}}>
                <div style={{width:'150px'}}>
                <SmallText fontWeight={'600'} val={'Emergency Contact'} />
                </div>
                <Input type="text" style={{borderLeft:(data.form_type == 'Compliance' || data.form_type == 'Contract Based')  &&`4px solid ${theme_color}`}} value={data.emergency_contact} onChange={(e)=>{setdata({...data,emergency_contact:e.target.value});seterror({...error,emergency_contact_err:''})}} />
                <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                {(error?.emergency_contact_err) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.emergency_contact_err  }</p>
                </div>
                </div>

               

                <div style={{marginBottom:'10px'}}>
                <div style={{width:'150px'}}>
                <SmallText fontWeight={'600'} val={'Blood Group'} />
                </div>
                <Select  style={{width:'100%',zIndex:100,fontSize:'13px',fontWeight:'600'}}  value={data.blood_group} onChange={(e)=>{setdata({...data,blood_group:e});seterror({...error,blood_group_err:''})}} >
                            {blood_group_Arr?.map((l)=>(
                                <Option style={{zIndex:100,marginBottom:'0px',fontWeight:'500',fontSize:'13px'}} key={l} value={l}>{l}</Option>
                                ))}
                            </Select>  
                            <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                {(error?.blood_group_err) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.blood_group_err  }</p>
                </div>            
                {/* <Input type="text" style={{borderLeft:`4px solid ${theme_color}`}} value={data.name} onChange={(e)=>setdata({...data,name:e.target.value})} /> */}
                </div>

                <div style={{marginBottom:'10px'}}>
                <div style={{width:'150px'}}>
                <SmallText fontWeight={'600'} val={'Nationality'} />
                </div>
                <Input type="text" style={{borderLeft:`4px solid ${theme_color}`}}  value={data.nationality} onChange={(e)=>{setdata({...data,nationality:e.target.value});seterror({...error,nationality_err:''})}} />
                <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                {(error?.nationality_err) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.nationality_err  }</p>
                </div>
                </div>

                <div style={{marginBottom:'10px'}}>
                <div style={{width:'150px'}}>
                    <SmallText fontWeight={'600'} val={'Martial Status'} />
                    </div>
                    <Select  style={{borderLeft:(data.form_type == 'Compliance' || data.form_type == 'Contract Based')  && `4px solid ${theme_color}`,width:'100%',zIndex:100,fontSize:'13px',fontWeight:'600'}} value={data.martial_status} onChange={(e)=>{setdata({...data,martial_status:e});seterror({...error,martial_status_err:''})}} >
                            {martial_Arr?.map((l)=>(
                                <Option style={{zIndex:100,marginBottom:'0px',fontWeight:'500',fontSize:'13px'}} key={l} value={l}>{l}</Option>
                                ))}
                            </Select> 
                            <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                {(error?.martial_status_err) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.martial_status_err  }</p>
                </div>         
                    {/* <Input type="text" style={{borderLeft:`4px solid ${theme_color}`}} value={data.name} onChange={(e)=>setdata({...data,name:e.target.value})} /> */}
                </div>

                <div style={{marginBottom:'10px'}}>
                <div style={{width:'150px'}}>
                <SmallText fontWeight={'600'} val={'Personal Email Id'} />
                </div>
                <Input type="text"  value={data.personal_email_id} onChange={(e)=>setdata({...data,personal_email_id:e.target.value})} />
                
                </div>

                <div style={{marginBottom:'10px'}}>
                <div style={{width:'150px'}}>
                <SmallText fontWeight={'600'} val={'Total Work Experience'} />
                </div>
                <Input type="text"  value={data.total_work_experience} onChange={(e)=>setdata({...data,total_work_experience:e.target.value})} />
                </div>

                <div style={{marginBottom:'10px'}}>
                <div >
                <SmallText fontWeight={'600'} val={'Department/Branch Were Deployed'} />
                </div>
                <Select allowClear={true} filterOption={false} options={fmsclients} showSearch={true} onSearch={(e)=>{searchclients(e)}}  style={{borderLeft:`4px solid ${theme_color}`,width:'100%',zIndex:100,fontSize:'13px',fontWeight:'600'}}  value={data.department_branch_were_deployed} onChange={(e)=>{setdata({...data,department_branch_were_deployed:fmsclients.find((c)=>c.value === e),department_branch_were_deployed_id:e});seterror({...error,department_branch_were_deployed_err:''})}} />
            
                <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                {(error?.department_branch_were_deployed_err) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.department_branch_were_deployed_err}</p>
                </div>
                </div>

                <div style={{marginBottom:'10px'}}>
                <div style={{width:'150px'}}>
                    <SmallText fontWeight={'600'} val={'Present Address'} />
                    </div>
                    <TextArea maxLength={150} type="text"  value={data.present_address} onChange={(e)=>setdata({...data,present_address:e.target.value})} />
                </div>

                <div style={{marginBottom:'10px'}}>
                <div style={{width:'150px'}}>
                    <SmallText fontWeight={'600'} val={'Permanent Address'} />
                    </div>
                    <TextArea maxLength={150} type="text"  value={data.permanent_address} onChange={(e)=>setdata({...data,permanent_address:e.target.value})} />
                </div>
                
                
                </div>
                
                <div>
                <div style={{marginTop:'68px',width:'300px',marginLeft:'100px'}}>
                <div style={{width:'150px'}}>
                <SmallText fontWeight={'600'} val={'Photo'} />
                </div>
                {(data.photo === null || data.photo == undefined || data.photo == '') ?
                <Upload showUploadList={false} onChange={(v)=>uploadFile('photo',v)} style={{borderLeft:`4px solid ${theme_color}`,width:'200px'}} >
                    <Button style={{borderLeft:`4px solid ${theme_color}`,width:'300px',display:'flex',alignSelf:'flex-end',justifyContent:'end'}} icon={<UploadOutlined   style={{borderLeft:'1px solid #eee',color:'#999',paddingLeft:'0px'}}/>}></Button>
                </Upload>
                :
                <Button  onClick={()=>setdata({...data,photo:null})} style={{borderLeft:`4px solid ${theme_color}`,width:'300px',display:'flex',alignItems:'left',fontSize:'10px',marginBottom:'0px'}} ><p style={{marginBottom:'0px', wordBreak: 'break-word',whiteSpace:'nowrap',width:'90%',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px',textAlign:'left',marginBottom:'0px'}}>{data.photo?.name != undefined ? data.photo?.name : data?.photo?.split('/')[data?.photo?.split('/').length-1] }</p><CloseOutlined size={15}  style={{borderLeft:'1px solid #eee',color:'#999',paddingLeft:'0px',position:'absolute',right:'0px'}}/></Button>
                }
                <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                {(error?.photo_err) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.photo_err  }</p>
                </div>
               
                </div>


                <div style={{marginTop:'8px',width:'300px',marginLeft:'100px'}}>
                <div style={{width:'150px'}}>
                <SmallText fontWeight={'600'} val={`Signature`} />
                </div>
                {(data.signature === null || data.signature == undefined || data.signature == '') ?
                <Upload showUploadList={false} onChange={(v)=>uploadFile('signature',v)} style={{width:'200px'}}>
                    <Button style={{width:'300px',display:'flex',alignSelf:'flex-end',justifyContent:'end',borderLeft:`4px solid ${theme_color}`}} icon={<UploadOutlined   style={{borderLeft:'1px solid #eee',color:'#999',paddingLeft:'0px'}}/>}></Button>
                </Upload>
                 :
                <Button onClick={()=>setdata({...data,signature:null})}  style={{width:'300px',display:'flex',alignItems:'center',fontSize:'10px',marginBottom:'0px',borderLeft:`4px solid ${theme_color}`}} ><p style={{marginBottom:'0px', wordBreak: 'break-word',whiteSpace:'nowrap',width:'90%',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px',textAlign:'left'}}>{data.signature?.name != undefined ? data.signature?.name : data?.signature?.split('/')[data?.signature?.split('/').length-1] }</p><CloseOutlined size={15}  style={{borderLeft:'1px solid #eee',color:'#999',paddingLeft:'0px',position:'absolute',right:'0px'}}/></Button>
                }
                <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                {(error?.signature_err) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.signature_err  }</p>
                </div>
                </div>
                 
                



                <div style={{marginTop:'8px',width:'300px',marginLeft:'100px'}}>
                <div style={{width:'150px'}}>
                <SmallText fontWeight={'600'} val={'Addhar No'} />
                </div>
                <Input type="text"  style={{borderLeft:`4px solid ${theme_color}`}} value={data.addhar_card_no} onChange={(e)=>{setdata({...data,addhar_card_no:e.target.value});seterror({...error,addhar_no_err:''})}} />
                <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                {(error?.addhar_no_err) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.addhar_no_err  }</p>
                </div>
                <SmallText fontWeight={'600'} val={'Addhar File'} marginfun={'10px 0px 8px 0px'} />

                {(data.addhar_file === null || data.addhar_file == undefined || data.addhar_file == '') ?
                <Upload showUploadList={false} onChange={(v)=>uploadFile('addhar_file',v)} style={{width:'200px'}}>
                    <Button style={{width:'300px',display:'flex',alignSelf:'flex-end',justifyContent:'end'}} icon={<UploadOutlined   style={{borderLeft:'1px solid #eee',color:'#999',paddingLeft:'0px'}}/>}></Button>
                </Upload>
                 :
                <Button onClick={()=>setdata({...data,addhar_file:null})}  style={{width:'300px',display:'flex',alignItems:'center',fontSize:'10px',marginBottom:'0px'}} ><p style={{marginBottom:'0px', wordBreak: 'break-word',whiteSpace:'nowrap',width:'90%',overflow:'hidden',textOverflow:'ellipsis',textAlign:'left'}}>{data.addhar_file?.name != undefined ? data.addhar_file?.name : data?.addhar_file?.split('/')[data?.addhar_file?.split('/').length-1] }</p><CloseOutlined size={15}  style={{borderLeft:'1px solid #eee',color:'#999',paddingLeft:'0px',position:'absolute',right:'0px'}}/></Button>
                }
                </div>

                <div style={{marginTop:'8px',width:'300px',marginLeft:'100px'}}>
                <div style={{width:'150px'}}>
                <SmallText fontWeight={'600'} val={'Pan No'} />
                </div>
                <Input type="text"   value={data.pan_card_no} onChange={(e)=>setdata({...data,pan_card_no:e.target.value})} />
                <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                {(error?.pan_no_err) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.pan_no_err  }</p>
                </div>
                <SmallText fontWeight={'600'} val={'Pan File'} marginfun={'10px 0px 8px 0px'} />

                    {(data.pan_file === null || data.pan_file == undefined || data.pan_file == '') ?
                    <Upload showUploadList={false} onChange={(v)=>uploadFile('pan_file',v)} style={{width:'200px'}}>
                        <Button style={{width:'300px',display:'flex',alignSelf:'flex-end',justifyContent:'end'}} icon={<UploadOutlined   style={{borderLeft:'1px solid #eee',color:'#999',paddingLeft:'0px'}}/>}></Button>
                    </Upload>
                    :
                    <Button onClick={()=>setdata({...data,pan_file:null})}  style={{width:'300px',display:'flex',alignItems:'center',fontSize:'10px',marginBottom:'0px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',}} ><p style={{marginBottom:'0px', wordBreak: 'break-word',whiteSpace:'nowrap',width:'90%',overflow:'hidden',textOverflow:'ellipsis',textAlign:'left'}}>{data.pan_file?.name != undefined ? data.pan_file?.name : data?.pan_file?.split('/')[data?.pan_file?.split('/').length-1] }</p><CloseOutlined size={15}  style={{borderLeft:'1px solid #eee',color:'#999',paddingLeft:'0px',position:'absolute',right:'0px'}}/></Button>
                    }
                </div>

                <div style={{marginTop:'8px',width:'300px',marginLeft:'100px'}}>
                <div style={{width:'150px'}}>
                <SmallText fontWeight={'600'} val={'Previous UAN/PF No'} />
                </div>
                <Input type="text"   value={data.previous_uan_pf_no} onChange={(e)=>{setdata({...data,previous_uan_pf_no:e.target.value});seterror({...error,previous_uan_pf_no_err:''})}} />
                <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                {(error?.previous_uan_pf_no_err) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.previous_uan_pf_no_err  }</p>
                </div>
              
                </div>

                <div style={{marginTop:'8px',width:'300px',marginLeft:'100px'}}>
                <div style={{width:'150px'}}>
                <SmallText fontWeight={'600'} val={'Previous ESI No'} />
                </div>
                <Input type="text"  value={data.previous_esi_no} onChange={(e)=>{setdata({...data,previous_esi_no:e.target.value});seterror({...error,previous_esi_no_err:''})}} />
                <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                {(error?.previous_esi_no_err) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.previous_esi_no_err  }</p>
                </div>
                </div>

                <div style={{marginTop:'8px',width:'300px',marginLeft:'100px'}}>
                <div style={{width:'150px'}}>
                <SmallText fontWeight={'600'} val={'Take Home'} />
                </div>
                <Input type="number" style={{borderLeft:`4px solid ${theme_color}`,}}  required={true} value={data.take_home} onChange={(e)=>{setdata({...data,take_home:e.target.value});seterror({...error,take_home_err:''})}} />
                <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                {(error?.take_home_err) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.take_home_err}</p>
                </div>
                </div>

                <div style={{marginTop:'8px',width:'300px',marginLeft:'100px'}}>
                <div style={{width:'150px'}}>
                <SmallText fontWeight={'600'} val={'ESI / INSURANCE Type '} />
                </div>
                <Select  style={{borderLeft:`4px solid ${theme_color}`,width:'100%',zIndex:100,fontSize:'13px',fontWeight:'600'}} value={data?.insurance_or_esi}  onChange={(e)=>{setdata({...data,insurance_or_esi:e});seterror({...error,insurance_or_esi:''})}} >
                {['ESI','INSURANCE']?.map((l)=>(
                    <Option style={{zIndex:100,marginBottom:'0px',fontWeight:'500',fontSize:'13px'}} key={l} value={l}>{l}</Option>
                    ))}
                </Select>  
                </div>

                <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                {(error?.insurance_or_esi) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.insurance_or_esi}</p>
                </div>

                {(roles.includes('fms_hr') || roles.includes('admin') || roles.includes('controller')) &&
                <div style={{marginTop:'8px',width:'300px',marginLeft:'100px'}}>
                    <div style={{width:'150px'}}>
                    <SmallText fontWeight={'600'} val={'Salary Structure '} />
                    </div>
                    <Select options={salaryStatementArr}  style={{borderLeft:`4px solid ${theme_color}`,width:'100%',zIndex:100,fontSize:'13px',fontWeight:'600'}} value={data?.salary_structure}  onChange={(e)=>{setdata({...data,salary_structure:e});seterror({...error,salary_structure:''})}} >
                    </Select>  
                </div>}

               


               
    



                {(roles.includes('fms_hr') || roles.includes('admin') || roles.includes('controller')) &&
                <div style={{display:'flex',marginLeft:'100px',marginTop:'10px'}}>
                <div style={{marginTop:'8px',marginRight:'10px',display:'flex'}}>
                <p className={data.is_verified ? 'checked_here2': 'checked_here'} style={{marginRight:'10px'}} onClick={()=>setdata({...data,is_verified:!data.is_verified}) }>
                 {data.is_verified && <p className='checked_here1' style={{background:theme_color}}></p>}
                </p>
                <SmallText fontWeight={'600'} val={'Is Verified'}  />
                </div>
                <div style={{marginTop:'8px',marginRight:'10px',display:'flex'}}>
                <p className={data.is_approved ? 'checked_here2': 'checked_here'} style={{marginRight:'10px'}} onClick={()=>setdata({...data,is_approved:!data.is_approved}) }>
                 {data.is_approved && <p className='checked_here1' style={{background:theme_color}}></p>}
                </p>
                <SmallText fontWeight={'600'} val={'Is Approved'}  />
                </div>
                <div style={{marginTop:'8px',marginRight:'10px',display:'flex'}}>
                <p className={data.is_rejected ? 'checked_here2': 'checked_here'} style={{marginRight:'10px'}} onClick={()=>setdata({...data,is_rejected:!data.is_rejected}) }>
                 {data.is_rejected && <p className='checked_here1' style={{background:theme_color}}></p>}
                </p>
                <SmallText fontWeight={'600'} val={'Is Rejected'}  />
                </div>
                </div>}

                {(roles.includes('fms_hr') || roles.includes('admin') || roles.includes('controller')) &&
                <div style={{display:'flex',marginLeft:'100px',marginTop:'10px'}}>
                <div style={{marginTop:'8px',marginRight:'10px',display:'flex'}}>
                <p className={data.terminated ? 'checked_here2': 'checked_here'} style={{marginRight:'10px'}} onClick={()=>setdata({...data,terminated:!data.terminated}) }>
                 {data.terminated && <p className='checked_here1' style={{background:theme_color}}></p>}
                </p>
                <SmallText fontWeight={'600'} val={'Terminated'}  />
                </div>
                <div style={{marginTop:'8px',marginRight:'10px',display:'flex'}}>
                <p className={data.inactive_due_to_client ? 'checked_here2': 'checked_here'} style={{marginRight:'10px'}} onClick={()=>setdata({...data,inactive_due_to_client:!data.inactive_due_to_client}) }>
                 {data.inactive_due_to_client && <p className='checked_here1' style={{background:theme_color}}></p>}
                </p>
                <SmallText fontWeight={'600'} val={'Client InActive'}  />
                </div>
                <div style={{marginTop:'8px',marginRight:'10px',display:'flex'}}>
                <p className={data?.take_attendance ? 'checked_here2': 'checked_here'} style={{marginRight:'10px'}} onClick={()=>setdata({...data,take_attendance:!data?.take_attendance}) }>
                 {data?.take_attendance && <p className='checked_here1' style={{background:theme_color}}></p>}
                </p>
                <SmallText fontWeight={'600'} val={'Take Attendance'}  />
                </div>
                </div>}   
                {(roles.includes('fms_hr') || roles.includes('admin')) &&
                <div style={{display:'flex',marginTop:'0px'}}>

                  
                    <div style={{marginTop:'12px',marginRight:'10px',alignItems:'center',marginLeft:'100px',display:'flex'}}>
                
                    <p className={data?.generate_docs ? 'checked_here2': 'checked_here'} style={{marginRight:'10px'}} onClick={()=>setdata({...data,generate_docs:!data?.generate_docs}) }>
                    {data?.generate_docs && <p className='checked_here1' style={{background:theme_color}}></p>}
                    </p>
                    <SmallText fontWeight={'600'} val={'Jenerate Docs'}  />
                    </div>

                    <div style={{marginTop:'12px',marginRight:'10px',alignItems:'center',marginLeft:'10px',display:'flex'}}>
                    
                    <p className={data?.generate_docs ? 'checked_here2': 'checked_here'} style={{marginRight:'10px'}} onClick={()=>setdata({...data,app_access:!data?.app_access}) }>
                        {data?.app_access && <p className='checked_here1' style={{background:theme_color}}></p>}
                    </p>
                    <SmallText fontWeight={'600'} val={'App Access'}  />
                    </div>

                    <div style={{marginTop:'12px',marginRight:'10px',alignItems:'center',marginLeft:'10px',display:'flex'}}>
                        <p className={data?.upload_to_excel ? 'checked_here2': 'checked_here'} style={{marginRight:'10px'}} onClick={()=>setdata({...data,upload_to_excel:!data?.upload_to_excel}) }>
                            {data?.upload_to_excel && <p className='checked_here1' style={{background:theme_color}}></p>}
                        </p>
                        <SmallText fontWeight={'600'} val={'Save To Master Tracker'}  />
                    </div>

                   

                </div>}


                {(roles.includes('fms_hr') || roles.includes('admin')) &&
                <div style={{display:'flex',marginTop:'0px',marginLeft:'90px'}}>
                    <div style={{marginTop:'12px',marginRight:'10px',alignItems:'center',marginLeft:'10px',display:'flex'}}>
                        <p className={data?.modified ? 'checked_here2': 'checked_here'} style={{marginRight:'10px'}} onClick={()=>setdata({...data,modified:!data?.modified}) }>
                            {data?.modified && <p className='checked_here1' style={{background:theme_color}}></p>}
                        </p>
                        <SmallText fontWeight={'600'} val={'Data Modified'}  />
                    </div>
                </div>}


                
                {((roles.includes('fms_hr') || roles.includes('admin')) && data?.modified !== undefined && data?.modified)  &&
                <div style={{marginTop:'8px',width:'300px',marginLeft:'100px'}}>
                <div style={{width:'150px'}}>
                <SmallText fontWeight={'600'} val={'Modified Remarks'}  />
                </div>
                <TextArea type="text" value={data.modified_remarks} onChange={(e)=>{setdata({...data,modified_remarks:e.target.value});seterror({...error,modified_remarks_err:''})}} />
                <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                {(error?.modified_remarks_err) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.modified_remarks_err}</p>
                </div>
                </div>}

                {/* {(roles.includes('fms_it') || roles.includes('admin')) && */}
               
                {data?.app_access &&
                <>

                <div style={{marginTop:'8px',width:'300px',marginLeft:'100px'}}>
                <div style={{width:'150px'}}>
                <SmallText fontWeight={'600'} val={'Password'} />
                </div>
                <Input type="text"   value={data.password} onChange={(e)=>{setdata({...data,password:e.target.value});seterror({...error,password:''})}} />
                <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                {(error?.password) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.password  }</p>
                </div>
              
                </div>

                <div style={{marginTop:'8px',width:'300px',marginLeft:'100px'}}>
                <div style={{width:'150px'}}>
                <SmallText fontWeight={'600'} val={'Roles'} />
                </div>
                <SelectData
                    value={data.roles}
                    closeMenuOnSelect={true}
                    placeholder=''
                    components={animatedComponents}
                    isMulti
                    onChange={(v)=>handleChange1(v,'roles')}
                    options={roles_arr}
                    style={{ width: '100%',borderLeft:'4px solid #292961',borderRadius:'5px',boxShadow:'none' }}
                >
                </SelectData>
                </div>
                </>}


                {/* }  */}

                {data.is_rejected &&
                <div style={{marginTop:'8px',width:'300px',marginLeft:'100px'}}>
                <div style={{width:'150px'}}>
                    <SmallText fontWeight={'600'} val={'Remarks'} />
                    </div>
                    <TextArea readOnly={(!roles.includes('fms_hr') && !roles.includes('admin') && !roles.includes('controller'))} maxLength={150} type="text"  value={data.remarks} onChange={(e)=>setdata({...data,remarks:e.target.value})} />
                </div>}
 
                {roles.includes('fms_user') && data.is_rejected &&
                <div style={{marginTop:'8px',marginRight:'10px',alignItems:'center',marginLeft:'100px',display:'flex'}}>
               
                <p className={data?.is_rejected ? 'checked_here2': 'checked_here'} style={{marginRight:'10px'}} onClick={()=>setdata({...data,is_rejected:!data?.is_rejected}) }>
                 {!data?.is_rejected && <p className='checked_here1' style={{background:theme_color}}></p>}
                </p>
                <SmallText fontWeight={'600'} val={'Resend For Review'}  />
                </div>}

              
              {(roles.includes('fms_hr') || roles.includes('admin') || roles.includes('controller')) &&  
              <div style={{marginTop:'8px',width:'300px',marginLeft:'100px'}}>
              <PrimaryButton btn_name="Save & Submit" onClick={()=>{data?.id !== '' ? 
                updatesubmit() : finalsubmit()}} />
              </div>}
 


               


                </div>
             </div>
             <div style={{marginLeft:'0px',display:'flex',width:'100px',justifyContent:'space-between'}}>
              <PrimaryButton btn_name="Next" onClick={nextstep} />
              <SecondaryButton btn_name={"Clear"} onClick={resetform} />
              </div>
               </div>}
                
                {/* step 2 */}
                {activeStep == 1 && 
               <div>
                  <span style={{display:'flex',cursor:'pointer'}} onClick={()=>setActiveStep(0)}>
                    <BsArrowLeft style={{marginRight:'5px'}}/>
                    <SmallText val={'Back'} />
                    </span>
                <div style={{display:'flex',justifyContent:'space-between'}}>
                <div style={{marginTop:'0px'}}> 
                <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between'}}>
                <BoldHeadText fontWeight={'900'} fontSize='15px' val={'Languages Info'} />
                <PrimaryButton btn_name="Add Language" onClick={()=>set_drawer(true)} />
                </div>
                {languages.length > 0 ? 
                    <div style={{ border:'0px solid lightgray',overflowY:'scroll',width:'500px'}}>
                        <div className='mateial_table_header'>
                            <p style={{width: '10%',color:'black'}}> Sl No</p>
                            <p style={{width: '25%',marginRight:'2%'}}>Language</p>
                            <p style={{width: '15%',marginRight:'2%'}}>Read</p>
                            <p style={{width: '15%'}}>Write</p>
                            <p style={{width: '15%'}}>Speak</p>
                            <p style={{width: '10%'}}>Option</p>
                        </div>

                {languages.map((d,i) => (
                    <div key={d.id} className={'mateial_table_body'}>
                      
                        <p style={{minWidth: '10%', color: "#0594f2",wordBreak:'break-word',fontWeight:'700'}}>{i+1}</p>
                        <p style={{minWidth: '25%',wordBreak:'break-word',marginRight:'2%'}}>{d?.language}</p>
                        <p style={{minWidth: '15%',wordBreak:'break-word',marginRight:'2%'}}>{
                         <p>
                         <p className={'checked_here2'} style={{cursor:'pointer'}} onClick={()=>setlanguage({...language,speak:!language.speak})}>
                         {d?.read &&  <p className='checked_here1' style={{background:theme_color}}></p>} 
                        </p>
                        </p>
                        }</p>
                        <p style={{minWidth: '15%',wordBreak:'break-word'}}>
                            {
                                <p>
                                <p className={'checked_here2'} style={{cursor:'pointer'}} onClick={()=>setlanguage({...language,speak:!language.speak})}>
                                {d?.write &&  <p className='checked_here1' style={{background:theme_color}}></p>} 
                               </p>
                               </p>
                            }
                        </p>
                        <p style={{minWidth: '15%',wordBreak:'break-word'}}>{
                            <p>
                            <p className={'checked_here2'} style={{cursor:'pointer'}} onClick={()=>setlanguage({...language,speak:!language.speak})}>
                            {d?.speak &&  <p className='checked_here1' style={{background:theme_color}}></p>} 
                           </p>
                           </p>
                        }</p>
                        <p style={{minWidth: '10%',display:'flex',marginLeft:'-10px'}}>
                            <FiEdit size={14} style={{marginRight:'10px'}} onClick={()=>{set_drawer(true);setlanguage(d)}} /> 
                            <FiTrash2 size={15} onClick={()=>deletelanguage(d)} />
                        </p>
  
                    </div>
                ))}



               
                    </div>
                    :
                    <div style={{display:'grid',justifyContent:'center',alignItems:'center',marginTop:'100px',alignSelf:'center'}}>
                    <img src={data_not_found} alt="nodatafouund" style={{margin:'auto',width:'120px',objectFit:'contain'}} />
                    <BoldText1 val={'No data added till now'} alignfun='center' />
                    <SmallText alignfun='center' val={'Click on a add language Button. Then, you can find the data added on this page.'} />
                    </div> 
                 }
                
            
             <div style={{marginLeft:'0px',display:'flex',width:'100px',justifyContent:'space-between'}}>
              <PrimaryButton btn_name="Next"  onClick={()=>setActiveStep(2)}/>
              {/* <PrimaryButton btn_name="Next" onClick={handleNext} /> */}

              <SecondaryButton btn_name={"Back"} onClick={resetform} />
              </div>
               </div>
               </div>
               </div>
               }

               {activeStep == 2 && 
               <div>
                  <span style={{display:'flex',cursor:'pointer'}} onClick={()=>setActiveStep(1)}>
                    <BsArrowLeft style={{marginRight:'5px'}}/>
                    <SmallText val={'Back'} />
                    </span>
                    <div style={{display:'flex',maxWidth:'750px',justifyContent:'space-between'}}>
                    <div style={{marginTop:'0px'}}> 
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between'}}>
                    <BoldHeadText fontWeight={'900'} fontSize='15px' val={'Family Details '} />
                    <div style={{display:'flex'}}>
                    {(!data?.previous_esi_no && data.take_home <= 21000) &&
                    <div style={{marginRight:'10px'}}>
                    <PrimaryButton btn_name="Add Dispensary Info" onClick={()=>{set_drawer(true);setsubActiveStep(1)}} />
                    </div>}
                    <PrimaryButton btn_name="Add Family Info" onClick={()=>{set_drawer(true);setsubActiveStep(0)}} />
                    </div>
                    </div>

                    {data?.previous_esi_no === '' &&<h6 style={{fontSize:'11px',maxWidth:'100%',marginBottom:'15px'}}>{'Hi we found that you have not added esi no so to continue for further process please add dispensary details and minimum 1 family member details'}</h6>}
                    {families.length > 0 ? 
                        <div style={{ border:'0px solid lightgray',overflow:'hidden',width:'750px'}}>
                            <div className='mateial_table_header'>
                                <p style={{minWidth: '10%',color:'black'}}> Sl No</p>
                                <p style={{minWidth: '15%',marginRight:'2%'}}>Name</p>
                                <p style={{minWidth: '15%',marginRight:'2%'}}>Relationship</p>
                                {/* <p style={{minWidth: '10%'}}>Education</p> */}
                                {/* <p style={{minWidth: '15%'}}>Occupation</p> */}
                                {/* <p style={{minWidth: '10%'}}>Age</p> */}
                                <p style={{minWidth: '35%'}}>Addhar File</p>
                                <p style={{minWidth: '10%'}}>Option</p>
                            </div>

                    {families.map((d,i) => (
                        <div key={d.id} className={'mateial_table_body'}>
                            
                            <p style={{minWidth: '10%', color: "#0594f2",wordBreak:'break-word',fontWeight:'700'}}>{i+1}</p>
                            <Tooltip title={d?.name}><p style={{minWidth: '15%',maxWidth: '15%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginRight:'2%',marginLeft:'-1%'}}>{d?.name}</p></Tooltip>
                            <Tooltip title={d?.relationship}><p style={{minWidth: '15%',maxWidth: '15%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginRight:'2%'}}>{d?.relationship}</p></Tooltip>
                            {/* <Tooltip title={d?.education}><p style={{minWidth: '10%',maxWidth: '10%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{d?.education}</p></Tooltip> */}
                            {/* <Tooltip title={d?.occupation}><p style={{minWidth: '15%',maxWidth: '15%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-0.5%'}}>{d?.occupation}</p></Tooltip> */}
                            {/* <Tooltip title={d?.age}><p style={{minWidth: '10%',maxWidth: '10%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-0.5%'}}>{d?.age !== "undefined" ? d?.age : ''}</p></Tooltip> */}
                            {/* <p style={{minWidth: '10%',maxWidth: '10%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-0.5%'}}>{d?.age !== "undefined" ? d?.age : ''}</p> */}
                            <p style={{minWidth: '35%'}}>{d?.file?.name !== undefined ? d?.file?.name?.split('/')[d?.file?.name?.split('/').length - 1] :  d?.file?.split('/')[d?.file?.split('/').length - 1]}</p>                        
                            <p style={{minWidth: '10%',maxWidth: '10%',display:'flex',marginLeft:'-5px'}}>
                            <Tooltip title={'Edit'}><span><FiEdit size={15} style={{marginRight:'10px'}} onClick={()=>{setfamily(d);set_drawer(true)}}/></span></Tooltip> 
                            <Tooltip title={'Delete'}><span><FiTrash2 size={15} onClick={()=>deletefamily(d)} /></span></Tooltip>
                            </p>
        
                        </div>
                    ))}



                    
                        </div>
                        :
                        <div style={{display:'grid',justifyContent:'center',alignItems:'center',marginTop:'100px',alignSelf:'center'}}>
                        <img src={data_not_found} alt="nodatafouund" style={{margin:'auto',width:'120px',objectFit:'contain'}} />
                        <BoldText1 val={'No data added till now'} alignfun='center' />
                        <SmallText alignfun='center' val={'Click on a add family details Button. Then, you can find the data added on this page.'} />
                        </div> 
                        }
                    
                
                    <div style={{marginLeft:'0px',display:'flex',width:'100px',justifyContent:'space-between'}}>
                    <PrimaryButton btn_name="Next"  onClick={()=>nextstep()}/>
                    <SecondaryButton btn_name={"Clear"} onClick={resetform} />
                    </div>
                    </div>
                    </div>

               </div>
               }

               {activeStep == 3 && 
                <div style={{width:'300px',marginTop:'0px'}}>
                      <span style={{display:'flex',cursor:'pointer'}} onClick={()=>setActiveStep(2)}>
                    <BsArrowLeft style={{marginRight:'5px'}}/>
                    <SmallText val={'Back'} />
                    </span>
                <BoldHeadText fontWeight={'900'} fontSize='15px' val={'Bank Details'} />

                    <div style={{marginBottom:'10px',marginTop:'30px'}}>
                        <div style={{width:'150px'}}>
                        <SmallText fontWeight={'600'} val={'Bank Name'}  />
                        </div>
                        <Input type="text" style={{borderLeft:`4px solid ${theme_color}`}}  value={bank.bank_name} onChange={(e)=>{setbank({...bank,bank_name:e.target.value});setbank_err({...bank_err,bank_name_err:''})}} />
                        <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                        {(bank_err?.bank_name_err) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                        <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{bank_err?.bank_name_err  }</p>
                        </div>
                    </div>

                    <div style={{marginBottom:'10px'}}>
                        <div style={{width:'150px'}}>
                        <SmallText fontWeight={'600'} val={'Account No'}  />
                        </div>
                        <Input type="text" style={{borderLeft:`4px solid ${theme_color}`}}   value={bank.account_no} onChange={(e)=>{setbank({...bank,account_no:e.target.value});setbank_err({...bank_err,account_no_err:''})}} />
                        <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                        {(bank_err?.account_no_err) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                        <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{bank_err?.account_no_err}</p>
                        </div>
                    </div>

                    <div style={{marginBottom:'10px'}}>
                        <div style={{width:'150px'}}>
                        <SmallText fontWeight={'600'} val={'IFSC Code'}  />
                        </div>
                        <Input type="text" style={{borderLeft:`4px solid ${theme_color}`}}   value={bank.ifsc_code} onChange={(e)=>{setbank({...bank,ifsc_code:e.target.value});setbank_err({...bank_err,ifsc_code_err:''})}} />
                        <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                        {(bank_err?.ifsc_code_err) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                        <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{bank_err?.ifsc_code_err}</p>
                        </div>
                    </div>

                    <div style={{width:'300px',marginBottom:'5px'}}>
                <div style={{width:'150px'}}>
                <SmallText fontWeight={'600'} val={'Photo'} />
                </div>
                {/* <p>{bank?.bank_photo?.name}{JSON.stringify(bank?.bank_photo?.name == undefined)}</p> */}
                {bank.bank_photo == null ?
                <Upload showUploadList={false} onChange={(v)=>handleUpload('bank_photo',v)} style={{width:'200px'}} >
                    <Button style={{width:'300px',display:'flex',alignSelf:'flex-end',justifyContent:'end',borderLeft:`4px solid ${theme_color}`}} icon={<UploadOutlined   style={{borderLeft:'1px solid #eee',color:'#999',paddingLeft:'0px'}}/>}></Button>
                </Upload>
                 :
                 <Button onClick={()=>{setbank({...bank,bank_photo:null});setbank_err({...bank_err,bank_photo:''})}}  style={{width:'300px',display:'flex',alignItems:'center',fontSize:'10px',marginBottom:'0px',borderLeft:`4px solid ${theme_color}`}} ><p style={{marginBottom:'0px', wordBreak: 'break-word',whiteSpace:'nowrap',width:'90%',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px',textAlign:'left'}}>{bank.bank_photo?.name != undefined ? bank.bank_photo?.name : bank.bank_photo?.split('/')[bank.bank_photo?.split('/').length-1] }</p><CloseOutlined size={15}  style={{borderLeft:'1px solid #eee',color:'#999',paddingLeft:'0px',position:'absolute',right:'0px'}}/></Button>
                }
                
                {(bank_err?.bank_photo !== '' && bank_err?.bank_photo !== undefined) &&
                <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                {(bank_err?.bank_photo) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                <p style={{marginBottom:'0px',fontSize:'10px',paddingLeft:'3px',color:'red'}}>{bank_err?.bank_photo}</p>
                </div>}
               
                </div>

                    <div style={{marginBottom:'10px'}}>
                        <div style={{width:'150px'}}>
                        <SmallText fontWeight={'600'} val={'Bank Address'}  />
                        </div>
                        <TextArea  type="text"   value={bank.bank_address} onChange={(e)=>setbank({...bank,bank_address:e.target.value})} />
                    </div>

                    <div style={{marginLeft:'0px',display:'flex',width:'100px',justifyContent:'space-between'}}>
                    <PrimaryButton btn_name="Next" onClick={nextstep} />
                    <SecondaryButton btn_name={"Clear"} onClick={resetform} />
                    </div>
                </div>
               }

               {/* {activeStep == 4 && 
               <div>
                  <span style={{display:'flex',cursor:'pointer'}} onClick={()=>setActiveStep(1)}>
                    <BsArrowLeft style={{marginRight:'5px'}}/>
                    <SmallText val={'Back'} />
                    </span>
               <div style={{display:'flex',justifyContent:'space-between'}}>
               <div style={{marginTop:'0px'}}> 
               <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between'}}>
               <BoldHeadText fontWeight={'900'} fontSize='15px' val={'Esi Details '} />
               <PrimaryButton btn_name="Add Esi Details Info" onClick={()=>set_drawer(true)} />
               </div>
               {esiInfo.length > 0 ? 
                   <div style={{ border:'0px solid lightgray',overflowY:'scroll',width:'600px'}}>
                       <div className='mateial_table_header'>
                           <p style={{minWidth: '10%',color:'black'}}> Sl No</p>
                           <p style={{minWidth: '15%',marginRight:'2%'}}>Name</p>
                           <p style={{minWidth: '15%',marginRight:'2%'}}>Relationship</p>
                           <p style={{minWidth: '15%'}}>Dob</p>
                           <p style={{minWidth: '30%'}}>File</p>
                           <p style={{minWidth: '10%',marginLeft:'5px'}}>Option</p>
                       </div>

               {esiInfo?.map((d,i) => (
                   <div key={d.id} className={'mateial_table_body'}>
                     
                       <p style={{minWidth: '10%', color: "#0594f2",wordBreak:'break-word',fontWeight:'700'}}>{i+1}</p>
                       <Tooltip title={d?.name}><p style={{minWidth: '15%',maxWidth: '15%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginRight:'2%',marginLeft:'-1%'}}>{d?.name}</p></Tooltip>
                       <Tooltip title={d?.relationship}><p style={{minWidth: '15%',maxWidth: '15%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginRight:'2%'}}>{d?.relationship}</p></Tooltip>
                       <Tooltip title={d?.occupation}><p style={{minWidth: '15%',maxWidth: '15%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-0.5%'}}>{d?.dob}</p></Tooltip>
                       <Tooltip title={d?.FILE}><p style={{minWidth: '30%',maxWidth: '30%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-0.5%'}}>{d?.file}</p></Tooltip>
                       <p style={{minWidth: '10%',maxWidth: '10%',display:'flex',marginLeft:'5px'}}>
                       <Tooltip title={'Edit'}><span><FiEdit size={15} style={{marginRight:'10px'}} onClick={()=>{setesi_info(d);set_drawer(true)}}/></span></Tooltip> 
                       <Tooltip title={'Delete'}><span><FiTrash2 size={15} onClick={()=>deleteesiInfo(d)} /></span></Tooltip>
                       </p>
 
                   </div>
               ))}



              
                   </div>
                   :
                   <div style={{display:'grid',justifyContent:'center',alignItems:'center',marginTop:'100px',alignSelf:'center'}}>
                   <img src={data_not_found} alt="nodatafouund" style={{margin:'auto',width:'120px',objectFit:'contain'}} />
                   <BoldText1 val={'No data added till now'} alignfun='center' />
                   <SmallText alignfun='center' val={'Click on a add esi family details Button. Then, you can find the data added on this page.'} />
                   </div> 
                }
               
                <div style={{marginLeft:'0px',display:'flex',width:'100px',justifyContent:'space-between'}}>
                <PrimaryButton btn_name="Next"  onClick={nextstep}/>
                <SecondaryButton btn_name={"Clear"} onClick={resetform} />
                </div>
              </div>
              </div>
               </div>
               } */}


              {activeStep == 4 && 
                <div style={{width:'500px',marginTop:'0px'}}>
                      <span style={{display:'flex',cursor:'pointer'}} onClick={()=>setActiveStep(3)}>
                    <BsArrowLeft style={{marginRight:'5px'}}/>
                    <SmallText val={'Back'} />
                    </span>
                <BoldHeadText fontWeight={'900'} fontSize='15px' val={'File Checklist'} />
                <SmallText fontWeight={'600'} val={'Tick all the documents you have recieved for this employee and submit them'}  />


                    <div style={{marginBottom:'10px',marginTop:'40px',display:'flex'}}>
                    <p>
                                <p className={'checked_here2'} style={{cursor:'pointer'}} onClick={()=>setcheck_list({...check_list,offer_letter_copy:!check_list.offer_letter_copy})}>
                                {check_list.offer_letter_copy &&  <p className='checked_here1' style={{background:theme_color}}></p>}
                              
                            </p>
                            </p>
                        <div style={{width:'400px',marginLeft:'20px'}}>
                        <SmallText fontWeight={'600'} val={'Offer Letter Copy [Signed by the employee]'}  />
                        </div>
                       
                    </div>

                    <div style={{marginBottom:'10px',marginTop:'10px',display:'flex'}}>
                    <p>
                                <p className={'checked_here2'} style={{cursor:'pointer'}} onClick={()=>setcheck_list({...check_list,appointment_letter_copy:!check_list.appointment_letter_copy})}>
                                {check_list.appointment_letter_copy &&  <p className='checked_here1' style={{background:theme_color}}></p>}
                              
                            </p>
                            </p>
                        <div style={{width:'400px',marginLeft:'20px'}}>
                        <SmallText fontWeight={'600'} val={'Appointment Letter Copy [Signed by the employee]'}  />
                        </div>
                      
                    </div>

                    <div style={{marginBottom:'10px',marginTop:'10px',display:'flex'}}>
                    <p>
                                <p className={'checked_here2'} style={{cursor:'pointer'}} onClick={()=>setcheck_list({...check_list,employee_information_dic:!check_list.employee_information_dic})}>
                                {check_list.employee_information_dic &&  <p className='checked_here1' style={{background:theme_color}}></p>}
                              
                            </p>
                            </p>
                        <div style={{width:'400px',marginLeft:'20px'}}>
                        <SmallText fontWeight={'600'} val={'Employee information document'}  />
                        </div>
                      
                    </div>

                    <div style={{marginBottom:'10px',marginTop:'10px',display:'flex'}}>
                    <p>
                                <p className={'checked_here2'} style={{cursor:'pointer'}} onClick={()=>setcheck_list({...check_list,policy_sign_off:!check_list.policy_sign_off})}>
                                {check_list.policy_sign_off &&  <p className='checked_here1' style={{background:theme_color}}></p>}
                              
                            </p>
                            </p>
                        <div style={{width:'400px',marginLeft:'20px'}}>
                        <SmallText fontWeight={'600'} val={'Policy sign off / NDA'}  />
                        </div>
                      
                    </div>

                    <div style={{marginBottom:'10px',marginTop:'10px',display:'flex'}}>
                    <p>
                                <p className={'checked_here2'} style={{cursor:'pointer'}} onClick={()=>setcheck_list({...check_list,passport_size_photo:!check_list.passport_size_photo})}>
                                {check_list.passport_size_photo &&  <p className='checked_here1' style={{background:theme_color}}></p>}
                              
                            </p>
                            </p>
                        <div style={{width:'400px',marginLeft:'20px'}}>
                        <SmallText fontWeight={'600'} val={'1 Passport size Photo'}  />
                        </div>
                      
                    </div>

                    <div style={{marginBottom:'10px',marginTop:'10px',display:'flex'}}>
                    <p>
                                <p className={'checked_here2'} style={{cursor:'pointer'}} onClick={()=>setcheck_list({...check_list,resume_of_employee:!check_list.resume_of_employee})}>
                                {check_list.resume_of_employee &&  <p className='checked_here1' style={{background:theme_color}}></p>}
                              
                            </p>
                            </p>
                        <div style={{width:'400px',marginLeft:'20px'}}>
                        <SmallText fontWeight={'600'} val={'Resume of the employee'}  />
                        </div>
                      
                    </div>

                    <div style={{marginBottom:'10px',marginTop:'10px',display:'flex'}}>
                    <p>
                                <p className={'checked_here2'} style={{cursor:'pointer'}} onClick={()=>setcheck_list({...check_list,education_certificate:!check_list.education_certificate})}>
                                {check_list.education_certificate &&  <p className='checked_here1' style={{background:theme_color}}></p>}
                              
                            </p>
                            </p>
                        <div style={{width:'400px',marginLeft:'20px'}}>
                        <SmallText fontWeight={'600'} val={'Copies of Academic certificates - 10th, 12th/ PUC, graduation and PG(IF APPLICABLE)'}  />
                        </div>
                      
                    </div>

                    <div style={{marginBottom:'10px',marginTop:'10px',display:'flex'}}>
                    <p>
                                <p className={'checked_here2'} style={{cursor:'pointer'}} onClick={()=>setcheck_list({...check_list,previous_employee_certificate:!check_list.previous_employee_certificate})}>
                                {check_list.previous_employee_certificate &&  <p className='checked_here1' style={{background:theme_color}}></p>}
                              
                            </p>
                            </p>
                        <div style={{width:'400px',marginLeft:'20px'}}>
                        <SmallText fontWeight={'600'} val={'Copies of documents of all the previous employment - Experience Certificate and relieving letter (IF APPLICABLE)'}  />
                        </div>
                      
                    </div>

                    <div style={{marginBottom:'10px',marginTop:'10px',display:'flex'}}>
                    <p>
                                <p className={'checked_here2'} style={{cursor:'pointer'}} onClick={()=>setcheck_list({...check_list,previous_salary_proof:!check_list.previous_salary_proof})}>
                                {check_list.previous_salary_proof &&  <p className='checked_here1' style={{background:theme_color}}></p>}
                              
                            </p>
                            </p>
                        <div style={{width:'400px',marginLeft:'20px'}}>
                        <SmallText fontWeight={'600'} val={'Copy of previous Salary Proof(Last employment)(IF APPLICABLE)'}  />
                        </div>
                      
                    </div>

                    


                    <div style={{marginBottom:'10px',marginTop:'10px',display:'flex'}}>
                    <p>
                                <p className={'checked_here2'} style={{cursor:'pointer'}} onClick={()=>setcheck_list({...check_list,id_proof:!check_list.id_proof})}>
                                {check_list.id_proof &&  <p className='checked_here1' style={{background:theme_color}}></p>}
                              
                            </p>
                            </p>
                        <div style={{width:'400px',marginLeft:'20px'}}>
                        <SmallText fontWeight={'600'} val={'ID Proof - License, Ration Card,voter id etc'}  />
                        </div>
                      
                    </div>

                    <div style={{marginBottom:'10px',marginTop:'10px',display:'flex'}}>
                    <p>
                                <p className={'checked_here2'} style={{cursor:'pointer'}} onClick={()=>setcheck_list({...check_list,pan_card:!check_list.pan_card})}>
                                {check_list.pan_card &&  <p className='checked_here1' style={{background:theme_color}}></p>}
                              
                            </p>
                            </p>
                        <div style={{width:'400px',marginLeft:'20px'}}>
                        <SmallText fontWeight={'600'} val={'Pan Card'}  />
                        </div>
                      
                    </div>

                    <div style={{marginBottom:'10px',marginTop:'10px',display:'flex'}}>
                    <p>
                                <p className={'checked_here2'} style={{cursor:'pointer'}} onClick={()=>setcheck_list({...check_list,addhar_card:!check_list.addhar_card})}>
                                {check_list.addhar_card &&  <p className='checked_here1' style={{background:theme_color}}></p>}
                              
                            </p>
                            </p>
                        <div style={{width:'400px',marginLeft:'20px'}}>
                        <SmallText fontWeight={'600'} val={'Aadhar Card'}  />
                        </div>
                      
                    </div>

                    <div style={{marginBottom:'10px',marginTop:'10px',display:'flex'}}>
                    <p>
                                <p className={'checked_here2'} style={{cursor:'pointer'}} onClick={()=>setcheck_list({...check_list,address_proof_permanent:!check_list.address_proof_permanent})}>
                                {check_list.address_proof_permanent &&  <p className='checked_here1' style={{background:theme_color}}></p>}
                              
                            </p>
                            </p>
                        <div style={{width:'400px',marginLeft:'20px'}}>
                        <SmallText fontWeight={'600'} val={'Address proof - Permanent'}  />
                        </div>
                      
                    </div>

                    <div style={{marginBottom:'10px',marginTop:'10px',display:'flex'}}>
                    <p>
                                <p className={'checked_here2'} style={{cursor:'pointer'}} onClick={()=>setcheck_list({...check_list,address_proof_current:!check_list.address_proof_current})}>
                                {check_list.address_proof_current &&  <p className='checked_here1' style={{background:theme_color}}></p>}
                              
                            </p>
                            </p>
                        <div style={{width:'400px',marginLeft:'20px'}}>
                        <SmallText fontWeight={'600'} val={'Address proof - Current'}  />
                        </div>
                      
                    </div>


                    <div style={{marginBottom:'10px',marginTop:'10px',display:'flex'}}>
                    <p>
                        <p className={'checked_here2'} style={{cursor:'pointer'}} onClick={()=>setcheck_list({...check_list,bank_proof:!check_list.bank_proof})}>
                        {check_list.bank_proof &&  <p className='checked_here1' style={{background:theme_color}}></p>}
                        
                    </p>
                            </p>
                        <div style={{width:'400px',marginLeft:'20px'}}>
                        <SmallText fontWeight={'600'} val={'Bank account statement / Cancel cheque / bank pass book (Any 1) '}  />
                        </div>
                      
                    </div>

                    

                    <div style={{marginLeft:'0px',display:'flex',width:'100px',justifyContent:'space-between'}}>
                    <PrimaryButton btn_name="Next" onClick={()=>setActiveStep(5)} />
                    <SecondaryButton btn_name={"Back"} onClick={()=>setActiveStep(4)}/>
                    </div>
                </div>
               }

                {activeStep == 5 && 
                <div style={{width:'500px',marginTop:'0px'}}>
                    <span style={{display:'flex',cursor:'pointer'}} onClick={()=>setActiveStep(4)}>
                    <BsArrowLeft style={{marginRight:'5px'}}/>
                    <SmallText val={'Back'} />
                    </span>
                    <BoldHeadText fontWeight={'900'} fontSize='15px' val={'HR Policy Approval'} />
                    <SmallText fontWeight={'600'} val={'Agree all the terms and condition and upload the docs to enroll the onboard employee.'}  />



                    <div>
                    <div style={{display:'flex',marginBottom:20}}>
                        <h6 onClick={()=>setselected_language('EN')} style={{fontSize:12,cursor:'pointer',backgroundColor:selected_language === 'EN' ? theme_color : '#fff',color:selected_language === 'EN' ? '#fff' : '#000',fontWeight:'600',padding:5}}>English - EN</h6>
                        <h6 onClick={()=>setselected_language('KN')} style={{fontSize:12,cursor:'pointer',backgroundColor:selected_language === 'KN' ? theme_color : '#fff',color:selected_language === 'KN' ? '#fff' : '#000',fontWeight:'600',padding:5}}>ಕನ್ನಡ - KN </h6>
                        <h6 onClick={()=>setselected_language('HI')} style={{fontSize:12,cursor:'pointer',backgroundColor:selected_language === 'HI' ? theme_color : '#fff',color:selected_language === 'HI' ? '#fff' : '#000',fontWeight:'600',padding:5}}>हिन्दी - HI  </h6>
                        </div>
                     <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',marginBottom:5}}>   
                        <h6 style={{fontWeight:'700',fontSize:'14px',}}>HR Policy Agreement</h6>

                      <a target='_blank' href={`${base_url}${hrPolicyDocsList?.docs1}`}>
                     <h6 style={{textDecoration:'underline',cursor:'pointer',fontSize:'11px',color:theme_color,fontWeight:'600'}}>View Docs</h6></a>  
                     {/* onPress={()=>navigation?.navigate('pdf_preview',{url:`${imgBaseUrl}${hrPolicyDocsList?.docs1}`})}  */}
                     </div>
                     {/* {hrPolicyDocsList?.docs1} */}

                    </div>
                    <div>
                     <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',marginBottom:5,marginTop:20}}>   
                        <h6 style={{fontWeight:'700',fontSize:'14px',}}>Self Declartion</h6>
                        <a target='_blank' href={`${base_url}${hrPolicyDocsList?.docs2}`}>
                        <h6  style={{textDecoration:'underline',cursor:'pointer',fontSize:'11px',color:theme_color,fontWeight:'600'}}>View Docs</h6>
                        </a>
                     </div>
                     {/* onPress={()=>navigation?.navigate('pdf_preview',{url:`${imgBaseUrl}${hrPolicyDocsList?.docs2}`})} */}
                    </div>

                    <div style={{marginBottom:'10px',marginTop:'40px',}}>

                    <div style={{marginBottom:'10px',marginTop:'10px',display:'flex'}}>
                    <p>
                                <p className={'checked_here2'} style={{cursor:'pointer'}} onClick={()=>{sethrPolicyDocsList({...hrPolicyDocsList,check1:!hrPolicyDocsList.check1});sethrPolicyDocsListErr({...hrPolicyDocsListErr,check1:''})}}>
                                {hrPolicyDocsList.check1 &&  <p className='checked_here1' style={{background:theme_color}}></p>}
                              
                            </p>
                            </p>
                        <div style={{width:'400px',marginLeft:'20px'}}>
                        <SmallText fontWeight={'600'} val={ hrPolicyDocsList.hr_policy_text + '   \n' +   hrPolicyDocsList.hr_policy_text1}  />
                        </div>
                      
                    </div>

                    <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                        {(hrPolicyDocsListErr?.check1) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                        <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{hrPolicyDocsListErr?.check1  }</p>
                    </div>

                    <div style={{marginBottom:'10px',marginTop:'10px',display:'flex'}}>
                    <p>
                                <p className={'checked_here2'} style={{cursor:'pointer'}} onClick={()=>{sethrPolicyDocsList({...hrPolicyDocsList,check2:!hrPolicyDocsList.check2});;sethrPolicyDocsListErr({...hrPolicyDocsListErr,check2:''})}}>
                                {hrPolicyDocsList.check2 &&  <p className='checked_here1' style={{background:theme_color}}></p>}
                              
                            </p>
                            </p>
                        <div style={{width:'400px',marginLeft:'20px'}}>
                        <SmallText fontWeight={'600'} val={ hrPolicyDocsList.self_declartion_text}  />
                        </div>
                      
                    </div>

                    <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                        {(hrPolicyDocsListErr?.check2) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                        <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{hrPolicyDocsListErr?.check2  }</p>
                    </div>
                    

                    <SmallText fontWeight={'600'} val={'Signature'}  />

                    {hrPolicyDocsList.signature == '' ? 
                    <div style={{border:'1px solid #ddd',position:'relative'}}>
                        <AiOutlineCheck onClick={()=>{sethrPolicyDocsList({...hrPolicyDocsList,signature:canvasRef.current.toDataURL()});;sethrPolicyDocsListErr({...hrPolicyDocsListErr,signature:''})}}  size={24} style={{position:'absolute',right:0,background:'#fff',padding:4,border:'1px solid #eee'}} />
                        <AiOutlineReload onClick={()=>canvasRef.current.clear()} size={24} style={{position:'absolute',right:22,background:'#fff',padding:4,border:'1px solid #eee'}} />
                      
                        
                    
                        <SignatureCanvas penColor='green'
                        ref={canvasRef}
                        canvasProps={{width: 500, height: 200, className: 'sigCanvas'}} />
                    </div>
                    : 
                    <div style={{border:'1px solid #ddd',position:'relative'}}>
                        <AiOutlineReload onClick={()=>{sethrPolicyDocsList({...hrPolicyDocsList,signature:''});sethrPolicyDocsListErr({...hrPolicyDocsListErr,signature:''})}} size={24} style={{position:'absolute',right:0,background:'#fff',padding:4,border:'1px solid #eee'}} />
                      <img  src={hrPolicyDocsList.signature} style={{width:'100%',height:'200px'}} />
                    </div>         
                }

                    <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                        {(hrPolicyDocsListErr?.signature) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                        <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{hrPolicyDocsListErr?.signature  }</p>
                    </div>

                    <div style={{width:'300px',marginBottom:'5px',marginTop:'10px'}}>
                    <div style={{width:'150px'}}>
                    <SmallText fontWeight={'600'} val={'Recorded Video'} />
                    </div>
                    {/* <p>{bank?.bank_photo?.name}{JSON.stringify(bank?.bank_photo?.name == undefined)}</p> */}
                    {hrPolicyDocsList.file == '' ?
                    <Upload accept="mp4" showUploadList={false} onChange={(v)=>handleUpload('hr_docs_file',v)} style={{width:'200px'}} >
                        <Button style={{width:'300px',display:'flex',alignSelf:'flex-end',justifyContent:'end'}} icon={<UploadOutlined   style={{borderLeft:'1px solid #eee',color:'#999',paddingLeft:'0px'}}/>}></Button>
                    </Upload>
                    :
                    <Button onClick={()=>{sethrPolicyDocsList({...hrPolicyDocsList,file:''});;sethrPolicyDocsListErr({...hrPolicyDocsListErr,file:''})}}  style={{width:'300px',display:'flex',alignItems:'center',fontSize:'10px',marginBottom:'0px'}} ><p style={{marginBottom:'0px', wordBreak: 'break-word',whiteSpace:'nowrap',width:'90%',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px',textAlign:'left'}}>{hrPolicyDocsList.file?.name != undefined ? hrPolicyDocsList.file?.name : hrPolicyDocsList.file?.split('/')[hrPolicyDocsList.file?.split('/').length-1] }</p><CloseOutlined size={15}  style={{borderLeft:'1px solid #eee',color:'#999',paddingLeft:'0px',position:'absolute',right:'0px'}}/></Button>
                    }
               
               
                </div>

                    <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                    {(hrPolicyDocsListErr?.file) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                    <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{hrPolicyDocsListErr?.file}</p>
                    </div>

                    <div style={{marginLeft:'0px',display:'flex',width:'100px',justifyContent:'space-between'}}>
                    {/* <PrimaryButton btn_name="Submit" onClick={data?.id !== '' ?  updatesubmit : finalsubmit} /> */}
                    <PrimaryButton btn_name="Submit" onClick={nextstep} />
                    <SecondaryButton btn_name={"Clear"} onClick={resetform}/>
                    </div>
              
                   </div>
                </div>}  

                </div>

          </FmsDashboardMainRoot>
    )
}


export default EmployeeInformationForm