import React, { useEffect, useState } from 'react'
import FmsDashboardMainRoot from '../FmsDashboardMainRoot'
import axios from 'axios'
import { authHeader } from '../../../helpers/usertoken/UserToken'
import { Pagination,DatePicker } from 'antd'
import moment from 'moment'
import { data_not_found } from '../../../helpers/Constants';
import { BoldText1 } from '../../../helpers/constants/ConstantsText'
import { AiOutlineDelete } from 'react-icons/ai'
import { Toaster, toast } from 'react-hot-toast'
import { theme_color } from '../../../helpers/constants/ConstantColor'
import PrimaryButton from '../../../components/buttons/PrimaryButton'
import { base_url, local_base_url } from '../../../App'
import fileDownload from "js-file-download";
import { useSelector } from 'react-redux'
import { Select } from 'antd'


function MissedClientScan() {

  const roles = useSelector(state => state.Auth.roles)

  const [data,setdata] = useState([])
  const [pagination,setpagination] = useState({})
  const [page,setpage] = useState(1)

  const [clients,setclients] = useState([])

  const [filter,setfilter] = useState({from_date:'',to_date:'',from_date1:'',to_date1:'',client:'',check_point:''})

  useEffect(()=>{
    getClients()
  },[])

  useEffect(()=>{
    getdata(page)
  },[page])

 

  async function getdata(v){
    await axios.get(`fms/missed_client_place_scanner/get/''?page=${v ? v : page}&from_date=${filter?.from_date1}&to_date=${filter?.to_date1}`,authHeader)
    .then((res)=>{
      setdata(res.data.datas)
      setpagination(res.data.pagination)
    }).catch((err)=>{
    })
  }

  

  // console.log("clients",clients)

  
  function setpagefuun(v){
    setpage(v)
  }

  function deleteData(id){
    axios.delete(`fms/missed_client_place_scanner/delete/${id}`,authHeader)
    .then((res)=>{
      if(res?.status == 200){
        getdata()
        toast.success('Deleted Successfully')
      }
      // setdata(res.data.datas)
      // setpagination(res.data.pagination)
    }).catch((err)=>{
    })
  }

  async function getClients(){
    await axios.get(`fms/fmsclientspoints/get_clients_based_checkpoint`,authHeader)
    .then((res)=>{
      let client = res.data.data
      // console.log("client recieved",client)

      let arr = []
      client.forEach((f)=>{
        let arr1 = []
       
        if(f?.checkpoints?.length > 0){
          f?.checkpoints?.forEach((d1)=>{
            arr1.push({label:d1?.name,value:d1?._id})
          })
        }

        if(f?.client !== undefined){
          arr.push({label:f?.client?.client_name,value:f?.client?._id,check_points:arr1})
       }

      })
      setclients(arr)
    }).catch((err)=>{
      console.log("err",err)
    })
  }


  // console.log("pagination?.limit",pagination)

  // console.log("filter here",filter)

  async function applyFilter(){
    // console.log("filter",filter)
    await axios.get(`fms/missed_client_place_scanner/get?page=${1}&from_date=${filter?.from_date1}&to_date=${filter?.to_date1}&client=${filter.client !== '' ?  filter?.client?.value : ''}&check_point=${filter.check_point !== '' ?  filter?.check_point?.value : ''}`,authHeader)
    .then((res)=>{
      // console.log("res.data KP",res.data)
      setdata(res.data.datas)
      setpagination(res.data.pagination)
    }).catch((err)=>{
    })
  }

  async function resetFilter(){
     setfilter({from_date:'',to_date:'',from_date1:'',to_date1:'',client:'',check_point:''})
     await axios.get(`fms/missed_client_place_scanner/get?page=${1}&from_date=${''}&to_date=${''}&client=${''}&check_point=${''}`,authHeader)
     .then((res)=>{
       console.log("res.data KP",res.data)
       setdata(res.data.datas)
       setpagination(res.data.pagination)
     }).catch((err)=>{
     })
  }

  async function downloadReport(){
    await axios.post(`fms/missed_client_place_scanner/download_report?page=${1}&from_date=${filter.from_date1}&to_date=${filter.to_date1}&client=${filter.client !== '' ?  filter?.client?.value : ''}&check_point=${filter.check_point !== '' ?  filter?.check_point?.value : ''}`,{},authHeader)
    .then((res)=>{
      let path = res.data.path.replace('public/','')
      handleDownload(`${local_base_url}${path}`,path.split('/')[path.split('/')?.length - 1])
    }).catch((err)=>{
    })
  }  

  const handleDownload = (url, filename) => {
    // console.log('url',url,'filename',filename)

    axios.get(url, {
        responseType: 'blob',
    })
        .then((res) => {
            fileDownload(res.data, filename)
            toast.success("Employee Excel Downloaded") 
        })
}

  return (
    <FmsDashboardMainRoot>
      <Toaster />
      <div style={{width:'98%'}}>
        <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',paddingBottom:'2px',borderBottom:'1px solid #ddd'}}>
        <BoldText1 val={`Missed Client Scan Report (${pagination?.total})`} /> 

        {(roles.includes('admin') || roles.includes('fms_hr') || roles.includes('fms_crm_head')) &&
        <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center',marginTop:'-20px'}}>
         
         <Select placeholder={'Select Client'} value={filter?.client === '' ? null : filter?.client} options={clients}  onChange={(e)=>setfilter({...filter,client:clients?.find(f=>f.value === e),check_point:''})} style={{width:'120px',marginBottom:0,marginRight:10}} />
         
         {filter?.client?.check_points?.length > 0 &&
          <Select placeholder={'Select CheckPoint'} value={filter?.check_point === '' ? null : filter?.check_point} options={filter?.client?.check_points}  onChange={(e)=>setfilter({...filter,check_point:filter?.client?.check_points?.find(f=>f.value === e)})} style={{width:'120px',marginBottom:0,marginRight:10}} />
           }

          <DatePicker value={filter?.from_date} style={{width:'130px'}} onChange={(e,e1)=>setfilter({...filter,from_date:e,from_date1:e1})} size='medium' placeholder='From Date' />
          <h6 style={{opacity:0}}>W</h6>
          <DatePicker value={filter?.to_date} style={{width:'130px'}} onChange={(e,e1)=>setfilter({...filter,to_date:e,to_date1:e1})} placeholder='To Date'  />
          <h6 style={{opacity:0}}>W</h6>
          <PrimaryButton onClick={applyFilter} btn_name={'Apply Filter'} />
          <h6 style={{opacity:0}}>W</h6>
          <PrimaryButton onClick={resetFilter} btn_name={'Reset Filter'} />

          <h6 style={{opacity:0}}>W</h6>
          <PrimaryButton onClick={downloadReport} btn_name={'Download Excel'} />

        </div>}
        </div>
      </div>

      <div>
      {data.length > 0 ? 
      <div style={{ border: '0px solid lightgray',marginTop:'20px', width:'95%' }}>
      
      <div className='row' style={{marginLeft:'2px'}}>
      {data.map((d,i) => (
          <div className="col-md-3" key={i} style={{border:'1px solid #ddd',position:'relative',width:'24%',margin:'0.2%'}} >
               <AiOutlineDelete onClick={()=>deleteData(d?._id)} size={12} style={{position:'absolute',right:'2px',top:'2px'}} />
               <h6 style={{fontSize:'12.5px',fontWeight:'800',marginTop:'6px'}}>Client Info : {d?.point?.client?.client_name}</h6>
               <h6 style={{fontSize:'11px',fontWeight:'400',marginTop:'-3px'}}>Client Place : {d?.point?.name}</h6>
               <h6 style={{fontSize:'11px',fontWeight:'400',marginTop:'-3px'}}>Point Lat / Lon : {d?.point?.lats} / {d?.point?.long}</h6>
               {/* <h6 style={{fontSize:'11px',fontWeight:'400',marginTop:'-3px'}}>Scan Lat / Lon : {d?.latitude} / {d?.longitude}</h6>  */}
               {/* <h6 style={{fontSize:'11px',fontWeight:'400',marginTop:'-3px'}}>Scanned User : {d?.user?.name}</h6> */}
               {/* <h6 style={{fontSize:'11px',fontWeight:'400',marginTop:'-3px'}}>Scanned Place : {d?.location}</h6> */}
               {/* <h6 style={{fontSize:'11px',fontWeight:'400',marginTop:'-3px'}}>Scanned Points :  */}
               {/* <div style={{display:'flex',marginTop:10,flexDirection:'row',flexWrap:'wrap'}}>
               {d?.check_point_list?.map((f)=>(
                 <h6 style={{fontSize:'11px',fontWeight:'400',border:'1px solid #eee',padding:'2px 4px',textTransform:'capitalize',background:theme_color,fontWeight:'700',color:'#fff'}}>{f?.name}</h6>
               ))}
               </div> */}
               {/* </h6> */}

               <h6 style={{fontSize:'11px',fontWeight:'400',marginTop:'-3px'}}>Scanned Remarks : {d?.remarks !== null ? d?.remarks : ''} </h6>

               
               <h6 style={{fontSize:'11px',fontWeight:'700',marginTop:'-3px'}}>{moment(d?.createdAt).format('lll')}</h6>

          </div>
      ))}
      </div>

      <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
          {pagination?.total > pagination?.limit &&
            <Pagination current={page} size="small" total={pagination.total} onChange={(v) => setpagefuun(v)}  pageSize={pagination?.limit} />
          }
      </div>

      </div> 
      :
      <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
          <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
          <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any clients scan report created yet"}</h4>
      </div>}
      </div>

    </FmsDashboardMainRoot>
  )
}

export default MissedClientScan