import React,{useState,useEffect} from 'react'
import FmsDashboardMainRoot from '../FmsDashboardMainRoot'
import { BoldText1, SmallText } from '../../../helpers/constants/ConstantsText'
import { Row,Col,Select } from 'antd'
import axios from 'axios'
import { authHeader } from '../../../helpers/usertoken/UserToken'
import {TbReportAnalytics} from 'react-icons/tb'
import fileDownload from "js-file-download";
import { base_url } from '../../../App'
import { toast } from 'react-hot-toast'

function DownloadAttendence() {
  const [fmsclients,setfmsclients] = useState([])
  const [year,setyear] = useState([])

  const [data,setdata] = useState({month:'6',year:'2023'})


  const AuthHeader = {headers:{
    'Content-Type': 'application/json',
    }}

  var month = [{id:0,value:"January"},{id:1,value:"February"},{id:2,value:"March"},{id:3,value:"April"},{id:4,value:"May"},{id:5,value:"June"},{id:6,value:"July"},{id:7,value:"August"},{id:8,value:"September"},{id:9,value:"October"},{id:10,value:"November"},{id:11,value:"December"}];


    useEffect(()=>{
        getdata()
        getyear()
    },[])


    function getyear(){
      axios.get(`fms/attendanceyear/get`,authHeader)
      .then((res)=>{
        let arr = []
        res?.data?.datas?.filter((d)=>{
          arr.push({value:d?._id,label:`${d?.name}`})
        })
        setyear(arr)
      }).catch((err)=>{
  
      })
    }

    // console.log("YEAR",year)


    function getdata(){
        axios.get('fms/fmsclients/get',AuthHeader)
        .then((res)=>{
            setfmsclients(res.data.datas)
        }).catch((err)=>{
        })
    }


    function downloadAttendenceReport(v){
      axios.get(`fms/basicemployee/getEmployeeReport_basedClient/${v}?month=${parseInt(data?.month) + 1}&year=${data.year}`,AuthHeader)
      .then((res)=>{
        let path = res.data.path.replace('public/','')
        handleDownload(`${base_url}${path}`,path.split('/')[path.split('/')?.length - 1])
          // setfmsclients(res.data.datas)
      }).catch((err)=>{
      })
    }

    const handleDownload = (url, filename) => {
      // console.log('url',url,'filename',filename)
      axios.get(url, {
          responseType: 'blob',
      })
          .then((res) => {
              fileDownload(res.data, filename)
              toast.success("Employee Excel Downloaded") 
          })
    }


  return (
    <FmsDashboardMainRoot>
        <div style={{width:'97%'}}>
            <div style={{borderBottom:'1px solid #eee',paddingBottom:'10px',display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between'}}>
            <BoldText1 val={'Download Report'} />
            <div style={{display:'flex',flexDirection:'row'}}>
            <div style={{width:'80px',border:'1px solid #eee',borderRadius:'2px',marginRight:'10px',height:'28px'}}>
            <Select value={data?.year} showArrow={false} style={{width:'100%',fontSize:'14px',fontWeight:'800'}} size='small' options={year} onSelect={(v)=>{setdata({...data,year:year?.find(y=>y.value === v)?.label})}} bordered={false} />
            </div> 
            <div style={{width:'80px',border:'1px solid #eee',borderRadius:'2px',marginRight:'10px',height:'28px'}}>
            <Select value={month[data?.month]?.value} showArrow={false} style={{width:'100%',fontSize:'14px',fontWeight:'800'}} size='small' options={month} onSelect={(v)=>{setdata({...data,month:month?.find(y=>y.value === v)?.id})}} bordered={false} /> 
            </div> 

            </div>
            </div>
            <Row style={{marginTop:'10px'}}>
              {fmsclients?.map((f)=>(
              <Col span={4.8} style={{width:'20%',padding:'10px',border:'1px solid #eee',margin:'0px 5px 0px 0px',position:'relative'}}>
                 <TbReportAnalytics size={20} style={{position:'absolute',right:0,top:0,background:'#fff',padding:'2px'}}  onClick={()=>downloadAttendenceReport(f?._id)}/>
                 <h6 style={{fontSize:'12px',marginBottom:'2px'}}>{f?.client_name} / {f?.site_name}</h6>
                 <h6 style={{fontSize:'11px',marginBottom:'2px'}}>Address : {f?.site_address}</h6>
                 <h6 style={{fontSize:'10px',fontWeight:'700'}}>Phone : {f?.client_no}</h6>
              </Col>
              ))}
            </Row>

        </div>
    </FmsDashboardMainRoot>
  )
}

export default DownloadAttendence