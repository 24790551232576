import React from 'react'
import { Tooltip, Tabs } from 'antd'
import { MdOutlineContactPage } from 'react-icons/md'
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import { FaHandshake, FaIndustry } from 'react-icons/fa'
import { BsFillTelephoneFill, BsTelephone } from 'react-icons/bs'
import { MdEmail, MdOutlineMarkEmailUnread } from 'react-icons/md'
import { GiOfficeChair } from 'react-icons/gi'
import { HiOutlineMenuAlt2 } from 'react-icons/hi'
import { RiBuilding4Line } from 'react-icons/ri'
import ContactComponent from './ContactComponent';
import LeadComponent from './LeadComponent';
import DealComponent from './DealComponent';
import { useDispatch } from 'react-redux';
import { bd_active_tab } from '../../redux/actionPayload/BdPayload';





const { TabPane } = Tabs;

function DetailDesignBody2({ section, id, values, labels, type, activetab, converted_status, obj, lead_id }) {


    let currenttab = activetab

    const dispatch = useDispatch()
    function setactive(v) {
        dispatch(bd_active_tab(v))
    }



    return (
        <div style={{ background: '#fff', minHeight: '100vh', display: 'flex' }}>

            <div style={{ flex: 0.25, minWidth: '30%', maxWidth: '30%', maxHeight: '100vh', position: 'sticky', left: '0px', top: '50px', padding: '2% 5%', background: 'white', wordWrap: 'break-word' }}>

                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <h1 style={{ textAlign: 'center', background: '#e6f7ff', color: '#17668a', width: '100px', height: '100px', display: 'flex', borderRadius: '50%', alignSelf: 'center', alignItems: 'center', justifyContent: 'center' }}>{values[0][0]?.company_name?.slice(0, 1)}</h1>
                </div>

                <Tooltip title={values[0][0].company_name} placement="bottom" color="black"><h1 style={{ fontWeight: 'bolder', fontSize: '25PX', textAlign: 'center' }}>{values[0][0]?.contact_name}</h1></Tooltip>

                <div style={{ width: '40%', margin: '5% auto', display: 'flex', justifyContent: 'space-between' }}>
                    <Tooltip title={values[0][0].phone} placement="bottom" color="black"><BsFillTelephoneFill size={34} style={{ color: 'black', background: '#8cafff', padding: '10px', borderRadius: '20px', cursor: 'pointer' }} /></Tooltip>
                    <Tooltip title={values[0][0].email} placement="bottom" color="black"><MdEmail size={34} style={{ color: 'black', background: '#ffa32b', padding: '10px', borderRadius: '20px', cursor: 'pointer' }} /></Tooltip>
                    <Tooltip title={values[0][0].designation} placement="bottom" color="black"><GiOfficeChair size={34} style={{ color: 'black', background: '#19e657', padding: '10px', borderRadius: '20px', cursor: 'pointer' }} /></Tooltip>
                </div>

                <div style={{ width: '100%', margin: '10% 0%' }}>
                </div>

                <div style={{ marginTop: '10%' }}>
                    <div style={{ display: 'flex', alignItems: 'center', borderBottom: '1px solid #d6d6d6' }}>
                        <HiOutlineMenuAlt2 size={24} />
                        <h6 style={{ paddingLeft: '10px', fontSize: '14px', paddingTop: '5px' }}>Company Info</h6>
                    </div>

                    <div style={{ display: 'flex', margin: '10px 0px' }}>

                        <p style={{ fontWeight: '500', fontSize: '13px', marginBottom: '0px', minWidth: '50%' }}><RiBuilding4Line style={{ marginRight: '6px' }} /> Company Name</p>
                        <p style={{ fontWeight: '600', fontSize: '13px', color: '#29bfff', marginBottom: '0px', textAlign: 'left', }}>{values[0][0]?.company_name}</p>
                    </div>

                    <div style={{ display: 'flex', margin: '0px 0px' }}>
                        <p style={{ fontWeight: '500', fontSize: '13px', minWidth: '50%' }}><BsTelephone style={{ marginRight: '6px' }} />Company Number</p>
                        <p style={{ fontWeight: '600', fontSize: '13px', color: '#29bfff', maxWidth: '50%', textAlign: 'left' }}>{values[0][0]?.company_phone}</p>
                    </div>

                    <div style={{ display: 'flex', margin: '0px 0px' }}>
                        <p style={{ fontWeight: '500', fontSize: '13px', minWidth: '50%', }}><MdOutlineMarkEmailUnread style={{ marginRight: '6px' }} /> Company Email</p>
                        <p style={{ fontWeight: '600', fontSize: '13px', color: '#29bfff', maxWidth: '50%', textAlign: 'left' }}>{values[0][0]?.company_email}</p>
                    </div>

                    <div style={{ display: 'flex', margin: '0px 0px' }}>
                        <p style={{ fontWeight: '500', fontSize: '13px', minWidth: '50%' }}><FaIndustry style={{ marginRight: '6px' }} /> Industry Type</p>
                        <p style={{ fontWeight: '600', fontSize: '13px', color: '#29bfff', maxWidth: '50%', textAlign: 'left' }}>{values[0][0]?.industry}</p>
                    </div>

                </div>
            </div>

            <div style={{ flex: 0.75, minWidth: '70%', maxWidth: '70%', padding: '0 2.5%', background: 'transparent', maxHeight: '100vh', overflow: 'scroll' }}>
                <Tabs defaultActiveKey={currenttab} tabBarStyle={{ color: 'black', position: 'sticky' }}>
                    {(currenttab === '1' || currenttab === '2' || currenttab === '3') &&
                        <TabPane onTabClick={() => setactive(1)}
                            tab={
                                <span>
                                    <MdOutlineContactPage size={20} style={{ marginRight: '10px' }} />
                                    Contact
                                </span>
                            }
                            key="1"
                        >
                            <ContactComponent type={type} section={section[0]} id={id} values={values[0]} labels={labels[0]} />
                        </TabPane>}

                    {(currenttab === '2' || currenttab === '3') &&
                        <TabPane onTabClick={() => setactive(2)}
                            tab={
                                <span>
                                    <BusinessCenterIcon size={16} style={{ marginRight: '10px' }} />
                                    Lead
                                </span>
                            }
                            key="2"
                        >
                            <LeadComponent obj={obj} id={type === 'Contact' ? lead_id : id} type={type} section={section[1]} values={values[1]} labels={labels[1]} converted_status={converted_status} />
                        </TabPane>
                    }

                    {(currenttab === '3') &&
                        <TabPane onTabClick={() => setactive(3)}
                            tab={
                                <span>
                                    <FaHandshake size={20} style={{ marginRight: '10px' }} />
                                    Deal
                                </span>
                            }
                            key="3"
                        >
                            <DealComponent type={type} section={section[2]} id={id} values={values[2]} labels={labels[2]} converted_status={converted_status} />
                        </TabPane>}
                </Tabs>
            </div>
        </div>
    )
}

export default DetailDesignBody2
