import React, { useState,useEffect } from 'react'
import FmsDashboardMainRoot from '../../FmsDashboardMainRoot'
import { BoldText1 } from '../../../../helpers/constants/ConstantsText'
import PrimaryButton from '../../../../components/buttons/PrimaryButton'
import { data_not_found } from '../../../../helpers/Constants'
import { Pagination,Tag,DatePicker } from 'antd'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { authHeader } from '../../../../helpers/usertoken/UserToken'
import {AiOutlineEdit,AiOutlineDelete} from 'react-icons/ai';
import { Toaster, toast } from 'react-hot-toast'
import { useSelector } from 'react-redux'
import { Tabs,Select,Row, Col,  } from 'antd';
import { AiOutlineFilter } from "react-icons/ai";
import { BiReset } from "react-icons/bi";
import moment from 'moment'
import { CiViewTable } from "react-icons/ci";
import { CiGrid41 } from "react-icons/ci";
import { theme_color } from '../../../../helpers/constants/ConstantColor'

function JobCardList() {

const { TabPane } = Tabs;

 const [activetab,setactivetab] = useState('1')
 const [search,setsearch] = useState({from_date:'',to_date:'',from_date1:'',to_date1:'',user:''})
 const navigate = useNavigate()   
 const [type,settype] = useState(1)
 const [data,setdata] = useState([])   
 const [page,setpage] = useState(1)   
 const [pagination,setpagination] = useState({})
 const [fmsEmployee,setfmsEmployee] = useState([])

 const roles = useSelector(state=>state.Auth.roles)

 
 useEffect(()=>{
    getData()
 },[page,activetab])
 
 function getData(){
    axios.get(`fms/job_card/get?page=${page}&type=${activetab == '1' ? 'Inside Job Card' : 'Outside Job Card'}&from_date=${search?.from_date}&to_date=${search?.to_date}&user=${search?.user}`,authHeader)
    .then((res)=>{
        setdata(res?.data?.datas)
        setpagination(res?.data?.pagination)
    })
 }

 function setpagefuun(v){
    setpage(v)
 }

 function deleteData(id){
    axios.delete(`fms/job_card/delete/${id}`,authHeader)
    .then((res)=>{
        toast.success('Deleted Successfully')
        getData()
    })
 }

 function setactivetabfunc(v){
    setpage(1)
    setactivetab(v)
 }

 async function searchuser(v){ 
    await axios.get(`fms/fms_in_house_employee/search?search=${v}`,authHeader)
        .then((res)=>{
            let arr = []
            res?.data?.datas?.forEach(d => {
                arr.push({label:`${d?.name}[Employee Id : ${d?.employee_id}]`,value:d?._id})
            });
            setfmsEmployee(arr)
            return arr
        }).catch((err)=>{
        })
}        


 function handleChange1(v){  
    // if(name === 'employee'){ 
       let employee = fmsEmployee?.find((v1)=>v1?.value === v)
       setsearch({...search,user:v})

    // }
 }


 function applyfilter() {
    setpage(1)
    axios.get(`fms/job_card/get?page=${1}&type=${activetab == '1' ? 'Inside Job Card' : 'Outside Job Card'}&from_date=${search?.from_date}&to_date=${search?.to_date}&user=${search?.user}`,authHeader)
    .then((res)=>{
        setdata(res?.data?.datas)
        setpagination(res?.data?.pagination)
    })
 }

 function resetfilter() {
    setpage(1)
    setsearch({from_date:'',to_date:'',from_date1:'',to_date1:'',user:''})
    axios.get(`fms/job_card/get?page=${1}&type=${activetab == '1' ? 'Inside Job Card' : 'Outside Job Card'}&from_date=${''}&to_date=${''}&user=${''}`,authHeader)
    .then((res)=>{
        setdata(res?.data?.datas)
        setpagination(res?.data?.pagination)
    })
 }

 console.log("search",search)

  return (
    <FmsDashboardMainRoot>
        <Toaster />
        <div style={{width:'98%'}}>
            <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between',borderBottom:'1px solid #eee'}}>
                <BoldText1 val={`Job Card (${pagination?.total})`} />
              

                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                
                <div style={{cursor:'pointer',marginRight:10,border:'1px solid #ddd',borderRadius:5}}>
                <CiViewTable size={30} onClick={()=>settype(1)}  style={{background:type == 1 && theme_color,color:type == 1 && 'white',padding:5,borderRadius:5}} />    
                <CiGrid41 size={30} onClick={()=>settype(2)} style={{background:type == 2 && theme_color,color:type == 2 && 'white',padding:5,borderRadius:5}}  />    
                </div>
                <DatePicker style={{marginRight:'5px'}} value={search?.from_date} onChange={(e,e1)=>setsearch({...search,from_date:e,from_date1:e1})} placeholder='From Date' />
                <DatePicker style={{marginRight:'5px'}} value={search?.to_date} onChange={(e,e1)=>setsearch({...search,to_date:e,to_date1:e1})} placeholder='To Date' />
                {(roles?.includes('admin') || roles?.includes('fms_crm_head') || roles?.includes('fms_user') || roles?.includes('fms_hr') || roles?.includes('fms_ticket')) &&
                <Select 
                    showSearch
                    cacheOptions
                    filterOption={false}
                    allowClear={true}
                    value={search?.user == '' ? null : search?.user}
                    onSearch={searchuser}
                    placeholder="FMS Employee " 
                    closeMenuOnSelect={true}
                    onChange={(v)=>handleChange1(v,'employee')}
                    options={fmsEmployee}
                    style={{ width: '120px',marginRight:'5px' }}
                  />}
                
                <BiReset onClick={resetfilter} size={30} style={{background:'#e3e3e3',color:'#000',padding:7,marginRight:5,borderRadius:5}} />
                <AiOutlineFilter onClick={applyfilter} size={30} style={{background:'#0e68bf',color:'#fff',padding:7,marginRight:5,borderRadius:5}} />
                <PrimaryButton btn_name={'Add Job Card'} onClick={()=>navigate('/fms/job_card/create',{state:activetab})} />
                </div>


            </div>


            <Tabs defaultActiveKey={activetab} onChange={(v)=>setactivetabfunc(v)} style={{ marginTop: '5px' }}>

            <TabPane tab="Inside Job Card" key="1">
            {data?.length > 0 ? 

            
               <div style={{ border: '0px solid lightgray',marginTop:'10px', width:'100%',overflow:'hidden' }}>
                {type == 1 &&
                  <>
                        <div className='mateial_table_header' style={{borderLeft:'1px solid #eee',borderRight:'1px solid #eee'}}>
                            <p className='checked_here' style={{opacity:0}} ></p> 
                            <p style={{fontSize:'11px',minWidth: '5%',maxWidth: '5%',borderRight:'1px solid #eee', color: 'black',marginLeft:'-10px',marginRight:'10px',opacity:'1' }}>SL No</p>
                            <p style={{fontSize:'12px',minWidth: '20%',maxWidth: '20%',borderRight:'1px solid #eee', marginRight: '0.5%' }}>Title</p>
                            <p style={{fontSize:'12px',minWidth: '30%',maxWidth: '30%',borderRight:'1px solid #eee', marginRight: '0.5%' }}>Description</p>
                            <p style={{fontSize:'12px',minWidth: '10%',maxWidth: '10%',borderRight:'1px solid #eee', marginRight: '0.5%' }}>Type</p>
                            <p style={{fontSize:'12px',minWidth: '15%',maxWidth: '15%',borderRight:'1px solid #eee', marginRight: '0.5%' }}>Date</p>
                            <p style={{fontSize:'11px',minWidth: '10%',maxWidth: '10%',borderRight:'1px solid #eee', marginRight: '0.5%' }}>Status</p>
                            <p style={{fontSize:'12px',minWidth: '8%',maxWidth: '8%' }}>Actions</p>
                        </div>
                        {data.map((d, i) => (
                            <div key={i}   style={{borderLeft:'1px solid #eee',padding:'0px !important',display:'flex',borderRight:'1px solid #eee',borderBottom:'1px solid #eee',height:'100%'}}>
                            {/* <p style={{opacity:0}}></p> */}
                            <p style={{paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '5%',maxWidth: '5%',borderRight:'1px solid #eee', color: 'black',marginLeft:'0px',marginRight:'10px',opacity:'1',textAlign:'center' }} >{page > 1 ? (page - 1) * pagination?.limit + i + 1 : i+1}</p>
                            <p style={{paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '19.7%',maxWidth: '19.7%',borderRight:'1px solid #eee', marginRight: '0.4%' }}>{d?.title}</p>
                            <p style={{paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '29.7%',maxWidth: '29.7%',borderRight:'1px solid #eee', marginRight: '0.4%' }}>{d?.description} <br></br> Remarks : {d?.remarks}</p>
                            <p style={{paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '10%',maxWidth: '10%',borderRight:'1px solid #eee', marginRight: '0.4%' }}>{d?.type}</p>
                            <p style={{paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '15%',maxWidth: '15%',borderRight:'1px solid #eee', marginRight: '0.4%' }}>Created At : {moment(d?.createdAt)?.format('LLL')} / Updated At : {moment(d?.updatedAt)?.format('LLL')}</p>
                            <p style={{paddingBottom:'0px',marginBottom:'0px',marginTop:'5px',fontSize:'12px',minWidth: '10%',maxWidth: '10%',borderRight:'1px solid #eee', marginRight: '0.4%' }}>{d?.remarks !== '' ?  <Tag style={{background:'#cddaf7'}}><span style={{fontSize:'10px',}}>{'Completed'}</span></Tag> : <Tag style={{background:'#fcb9a7'}}><span style={{fontSize:'10px',background:'#fcb9a7'}}>{'Pending'}</span></Tag>}</p>


                        {!roles?.includes('fms_data_view') &&

                            <p style={{fontSize:'12px',minWidth: '8%',maxWidth: '8%', marginRight: '0.5%' }}>
                                <AiOutlineEdit style={{marginRight:'10px'}} onClick={()=>navigate('edit',{state:d})} />
                                {(roles?.includes('admin') || roles?.includes('fms_crm_head')) &&
                                <AiOutlineDelete onClick={()=>deleteData(d?._id)} />}
                            </p>}

                            </div>
                        ))}
                 </>}
                {type == 2 && 
                 <Row style={{paddingLeft:'5px',marginTop:'0px'}}>
                 {data.map((d, i) => (
                 <Col  onClick={()=>navigate('edit',{state:d})} span={4} style={{padding:'5px',background:d?.remarks !== '' ? '#cddaf7' : '#fcb9a7',margin:'2px'}}>
                     <h6 style={{fontSize:'14px',fontWeight:'bold',marginBottom:'0px'}}>Title : {d?.title}</h6>
                     <h6 style={{fontSize:'12px'}}>Description : {d?.description}</h6>
                     <h6 style={{fontSize:'10px'}}>Created At : {moment(d?.createdAt)?.format('LLL')}</h6>
                     {d?.remarks !== '' && 
                     <>
                     <h6 style={{fontSize:'12px'}}>Remarks : {d?.remarks}</h6>
                     <h6 style={{fontSize:'10px'}}>Updated At : {moment(d?.updatedAt)?.format('LLL')}</h6>
                     </>}
                 </Col>))}
                </Row> 
                }

            <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
                {pagination.total > pagination?.limit &&
                    <Pagination current={page} size="small" total={pagination.total} onChange={(v) => setpagefuun(v)}  pageSize={pagination?.limit} />
                }
            </div>

            </div> 
            :
            <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
                <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any clients created yet"}</h4>
                <h4 style={{ fontSize: '12px', fontWeight: '500', marginTop: '2px', textAlign: 'center',opacity:'0.8' }}>{"To see clients details click on the add button and create a new client form and submit"}</h4>
            </div>}
            </TabPane>

            <TabPane tab="Outside Job Card" key="2">
            {data?.length > 0 ? 
            <div style={{ border: '0px solid lightgray',marginTop:'10px', width:'100%',overflow:'hidden' }}>
            {type == 1 &&    
            <>
            <div className='mateial_table_header' style={{borderLeft:'1px solid #eee',borderRight:'1px solid #eee'}}>
                <p className='checked_here' style={{opacity:0}} ></p> 
                <p style={{fontSize:'11px',minWidth: '5%',maxWidth: '5%',borderRight:'1px solid #eee', color: 'black',marginLeft:'-10px',marginRight:'10px',opacity:'1' }}>SL No</p>
                <p style={{fontSize:'12px',minWidth: '14%',maxWidth: '14%',borderRight:'1px solid #eee', marginRight: '0.5%' }}>Title</p>
                <p style={{fontSize:'12px',minWidth: '18%',maxWidth: '18%',borderRight:'1px solid #eee', marginRight: '0.5%' }}>Description</p>
                <p style={{fontSize:'12px',minWidth: '12%',maxWidth: '12%',borderRight:'1px solid #eee', marginRight: '0.5%' }}>Address</p>
                <p style={{fontSize:'12px',minWidth: '8%',maxWidth: '8%',borderRight:'1px solid #eee', marginRight: '0.5%' }}>Type</p>
                <p style={{fontSize:'12px',minWidth: '15%',maxWidth: '15%',borderRight:'1px solid #eee', marginRight: '0.5%' }}>Contact person Info</p>
                <p style={{fontSize:'12px',minWidth: '8%',maxWidth: '8%',borderRight:'1px solid #eee', marginRight: '0.5%' }}>Date</p>
                <p style={{fontSize:'11px',minWidth: '8%',maxWidth: '8%',borderRight:'1px solid #eee', marginRight: '0.5%' }}>Status</p>
                <p style={{fontSize:'12px',minWidth: '8%',maxWidth: '8%' }}>Actions</p>
            </div>


            {data.map((d, i) => (
                <div key={i}   style={{borderLeft:'1px solid #eee',padding:'0px !important',display:'flex',borderRight:'1px solid #eee',borderBottom:'1px solid #eee',height:'100%'}}>
                {/* <p style={{opacity:0}}></p> */}
                <p style={{paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '5%',maxWidth: '5%',borderRight:'1px solid #eee', color: 'black',marginLeft:'0px',marginRight:'10px',opacity:'1',textAlign:'center' }} >{page > 1 ? (page - 1) * pagination?.limit + i + 1 : i+1}</p>
                <p style={{paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '14%',maxWidth: '14%',borderRight:'1px solid #eee', marginRight: '0.4%' }}>{d?.title}</p>
                <p style={{paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '18%',maxWidth: '18%',borderRight:'1px solid #eee', marginRight: '0.4%' }}>{d?.description} <br></br> Remarks : {d?.remarks}</p>
                <p style={{paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '12%',maxWidth: '12%',borderRight:'1px solid #eee', marginRight: '0.4%' }}><b>{d?.address}</b> <br></br> Lats : {d?.latitude} <br></br> Long : {d?.longitude} </p>
                <p style={{paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '8%',maxWidth: '8%',borderRight:'1px solid #eee', marginRight: '0.4%' }}>{d?.type}</p>
                <p style={{paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '15%',maxWidth: '15%',borderRight:'1px solid #eee', marginRight: '0%' }}><b>{d?.contact_person}</b> <br></br> Mobile : {d?.mobile} <br></br> Email : {d?.email} </p>
                <p style={{paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '8%',maxWidth: '8%',borderRight:'1px solid #eee', marginRight: '0.4%' }}>Created At : {moment(d?.createdAt)?.format('LLL')} / Updated At : {moment(d?.updatedAt)?.format('LLL')}</p>
                <p style={{paddingBottom:'0px',marginBottom:'0px',marginTop:'5px',fontSize:'12px',minWidth: '8%',maxWidth: '8%',borderRight:'1px solid #eee', marginRight: '0.4%' }}>{d?.remarks !== '' ?  <Tag style={{background:'#cddaf7'}}><span style={{fontSize:'10px',}}>{'Completed'}</span></Tag> : <Tag style={{background:'#fcb9a7'}}><span style={{fontSize:'10px',background:'#fcb9a7'}}>{'Pending'}</span></Tag>}</p>


                {!roles?.includes('fms_data_view') &&

                <p style={{fontSize:'12px',minWidth: '8%',maxWidth: '8%', marginRight: '0.5%' }}>
                    <AiOutlineEdit style={{marginRight:'10px'}} onClick={()=>navigate('edit',{state:d})} />
                    {(roles?.includes('admin') || roles?.includes('fms_crm_head')) &&
                    <AiOutlineDelete onClick={()=>deleteData(d?._id)} />}
                </p>}

                </div>
            ))}
            </>}

            {type == 2 && 
                <Row style={{paddingLeft:'5px',marginTop:'0px'}}>
                {data.map((d, i) => (
                <Col onClick={()=>navigate('edit',{state:d})} span={4} style={{padding:'5px',background:d?.remarks !== '' ? '#cddaf7' : '#fcb9a7',margin:'2px'}}>
                    <h6 style={{fontSize:'14px',fontWeight:'bold',marginBottom:'0px'}}>Title : {d?.title}</h6>
                    <h6 style={{fontSize:'12px'}}>Description : {d?.description}</h6>
                    <h6 style={{fontSize:'12px'}}>Job Open Location : {d?.address}</h6>
                    <h6 style={{fontSize:'10px'}}>Created At : {moment(d?.createdAt)?.format('LLL')}</h6>
                    {d?.remarks !== '' && 
                    <>
                    <h6 style={{fontSize:'12px'}}>Job Close Location : {d?.exit_address}</h6>
                    <h6 style={{fontSize:'12px'}}>Remarks : {d?.remarks}</h6>
                    <h6 style={{fontSize:'10px'}}>Updated At : {moment(d?.updatedAt)?.format('LLL')}</h6>
                    </>}
                </Col>))}
            </Row> 
            }


            <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
                {pagination.total > pagination?.limit &&
                    <Pagination current={page} size="small" total={pagination.total} onChange={(v) => setpagefuun(v)}  pageSize={pagination?.limit} />
                }
            </div>

            </div> 
            :
            <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
                <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any clients created yet"}</h4>
                <h4 style={{ fontSize: '12px', fontWeight: '500', marginTop: '2px', textAlign: 'center',opacity:'0.8' }}>{"To see clients details click on the add button and create a new client form and submit"}</h4>
            </div>}
            </TabPane>

            </Tabs>
        </div>
    </FmsDashboardMainRoot>
  )
}

export default JobCardList