import React,{useState,useEffect} from 'react'
import { IconButton } from '@material-ui/core';
import {useNavigate} from 'react-router-dom'
import {BsFillPlusCircleFill,BsTelephoneFill} from 'react-icons/bs'
import {AiOutlineEdit} from 'react-icons/ai'
import {MdMail,MdOutlineWork} from 'react-icons/md'
import {FaIdCardAlt} from 'react-icons/fa'
import {FiChevronLeft} from 'react-icons/fi'
import { main_bg } from '../../../components/constants/Constants1';
import {useSelector} from 'react-redux'
import {Tag} from 'antd'

import axios from 'axios'
import { authHeader } from '../../../helpers/usertoken/UserToken';
import { base_url } from '../../../App';

function Profile() {

    const navigate = useNavigate()

    const [visible,setvisible] = useState(false)
    const [profilepic,setprofilepic] = useState(null)

    const name = useSelector(state=>state.Auth.name)
    const id = useSelector(state=>state.Auth.id)
    const roles = useSelector(state=>state.Auth.roles)
    const department = useSelector(state=>state.Auth.department)
    const email = useSelector(state=>state.Auth.email)
    const designation = useSelector(state=>state.Auth.designation)
    const fcpl_id = useSelector(state=>state.Auth.fcpl_id)
    const phone = useSelector(state=>state.Auth.phone)
    const colors = ['magenta','red','volcano','orange','gold','lime','green','cyan','blue','geekblue']

    useEffect(()=>{
        getprofile()
    },[])

    function getprofile(){
        axios.get(`user/get/${id}`,authHeader)
        .then((res)=>{
            setprofilepic(res?.data?.datas[0].profile_photo)
            console.log('res data here',res.data)
        })
     }

    function uploadprofilepic(v){
        // console.log('value is',v.target.files[0])
        const fd = new FormData()
        fd.append('image',v.target.files[0])
        authHeader['headers']['Content-Type'] = 'multipart/form-data'
        axios.put(`user/update/${id}`,fd,authHeader)
        .then((res)=>{
            getprofile()
            // console.log('uploadprofilepic update here',res.data)
        })
    } 

    return (
        <div style={{width:'100%',minWidth:'100%',maxWidth:'100%',height:'100%',background:'#fff',overflowX:'hidden'}}>
            <div style={{width:'100%',maxWidth:'100%',height:'30vh',minHeight:'30vh',height:'100%',background:`${main_bg}`,color:'white',padding:'10px',zIndex:12}}>
                <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between',width:'100%',background:`${main_bg}`,zIndex:100}}>
                    <IconButton onClick={()=>navigate(-1)} style={{marginLeft:'-10px'}}><FiChevronLeft color="white" size={24} /></IconButton>    
                    <h6 style={{marginBottom:'0px',color:'white',textAlign:'center'}}>Profile Page</h6>
                    <IconButton style={{display:'block',opacity:0,color:"#fff",}} onClick={()=>setvisible(!visible)}><AiOutlineEdit size={24}/></IconButton>
                </div>

                <div style={{width:'100%',maxWidth:'100%',display:'flex',flexDirection:'row',marginTop:'10px',justifyContent:'space-between',alignItems:'center',zIndex:10,padding:'10px'}}>
                    <div>
                        <h6 style={{color:'white',fontWeight:'600',marginBottom:'0px',fontSize:'20px',textTransform:'capitalize'}}>{name}</h6>
                        <p style={{fontSize:'14px',marginTop:8,fontWeight:'700'}}><span><MdOutlineWork size={22} color="#f0bc00"  style={{marginRight:'10px'}}/></span>{designation.designation_name}</p>
                    </div>   
                    <div style={{position:'relative'}}>
                    {profilepic 
                    ?
                    <div style={{width:'100px',height:'100px',objectFit:'contain',borderRadius:'50px',backgroundColor:'#ededed',border:'1px solid white',padding:'0px',position:'relative'}}>  
                    <img src={`${base_url}${profilepic}`} style={{width:'100%',height:'100%',objectFit:'cover',borderRadius:'50px',border:'2px solid white',zIndex:10}} alt="user_profile" />
                    <input type="file" style={{width:'100px',position:'absolute',bottom:6,right:10,zIndex:100,opacity:0}} accept="image/*"  onChange={v=>uploadprofilepic(v)}/>
                    </div> 
                    :
                    <div style={{width:'100px',height:'100px',objectFit:'contain',borderRadius:'50px',backgroundColor:'#ededed',border:'1px solid white',padding:'0px',position:'relative'}}>
                    <input type="file" style={{width:'100px',position:'absolute',bottom:6,opacity:0}} accept="image/*"  onChange={v=>uploadprofilepic(v)}/>
                    </div>    
                    }
                    <BsFillPlusCircleFill size={24} style={{position:'absolute',color:'white',bottom:6,right:5}} />
                    </div>
                </div>
            </div>

            <div style={{marginTop:15,zIndex:10,background:'white',width:'94%',margin:'auto',borderRadius:'4px',marginBottom:'20px'}}>
                <h6 style={{paddingLeft:'10px',paddingTop:'20px',color:`${main_bg}`,fontWeight:'900',fontSize:'16px'}}>User Information</h6>
                    <div style={{display:'flex',alignItems:'center',padding:'10px 20px'}} >
                     <FaIdCardAlt size={18} color="#fcb103" />
                     <h6 style={{marginBottom:'0px',marginLeft:'15px',fontSize:'12px'}}>{fcpl_id} <span style={{fontWeight:'900'}}>{department[0]?.department_name}</span> </h6>
                    </div>
                  
                    <div style={{display:'flex',alignItems:'center',padding:'10px 20px'}} >
                     <BsTelephoneFill size={18}  color="#fcb103"/>
                     <h6 style={{marginBottom:'0px',marginLeft:'15px',fontSize:'12px'}}> +91 {phone}</h6>
                    </div>

                    <div style={{display:'flex',alignItems:'center',padding:'10px 20px'}} >
                     <MdMail size={18} color="#fcb103"/>
                     <h6 style={{marginBottom:'0px',marginLeft:'15px',fontSize:'12px'}}>{email}</h6>
                    </div>

                    {visible &&
                    <>
                    <h6 style={{paddingLeft:'10px',paddingTop:'10px',color:`${main_bg}`,fontWeight:'900',fontSize:'15px'}}>User Roles</h6>
                    <div style={{margin:'10px',paddingBottom:'20px'}}>
                        <span style={{display:'flex',alignItems:'center',border:'2px solid white',paddingLeft:'0px',padding:'3px',borderRadius:'12px',width:'180px'}}>
                            {roles.map((r,i)=><Tag style={{marginBottom:'2px'}} key={i} color={`${colors[Math.floor(Math.random() * colors.length)]}`}>{r}  </Tag>)}
                        </span>
                    </div>
                    </>}
                   
                </div>
        </div>
    )
}

export default Profile
