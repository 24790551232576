import { FOOTER_ACTIVE_NO } from "../actionTypes/MobileFooterAction";


const initialstate = {
    footer_Active_no: 1
}


const MobileFooterReducer = (state = initialstate,action)=>{
         switch(action.type){
            case FOOTER_ACTIVE_NO:
                state.footer_Active_no = action.payload
                return {...state}
            default:
                return state     
        }    
}

export default MobileFooterReducer