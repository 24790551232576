import React from 'react'

export const SmallText = ({val,col,marginfun,alignfun,fontSize,fontWeight,textTransform}) =>{
    return <h6 style={{fontSize:fontSize?fontSize:'12px',fontWeight:fontWeight,color:col,textAlign:alignfun,margin:marginfun,textTransform:textTransform,}}>{val}</h6>
}



export const BoldText1 = ({val,col,marginfun,alignfun,fontSize,fontWeight,textTransform}) =>{
    return <h6 style={{fontSize:fontSize?fontSize:'14px',color:col,textAlign:alignfun,margin:marginfun,fontWeight:fontWeight?fontWeight:'800',textTransform:textTransform, wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',}}>{val}</h6>
}

export const BoldHeadText = ({val,col,marginfun,alignfun,fontSize,fontWeight,textTransform}) =>{
    return <h6 style={{fontSize:fontSize?fontSize:'18px',color:col,textAlign:alignfun,margin:marginfun,fontWeight:fontWeight?fontWeight:'700',textTransform:textTransform, wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',}}>{val}</h6>
}



// text for IT Dashboard

export const ItSmallText = ({val,col,marginfun,alignfun,fontSize,fontWeight,textTransform}) => {
    return <h6 style={{fontSize:'11px',fontWeight:fontWeight?fontWeight:'600',color:col,textAlign:alignfun,margin:marginfun,textTransform:textTransform, wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',}}>{val}</h6>
}

export const ItSmallText1 = ({val,col,marginfun,alignfun,fontSize,fontWeight,textTransform}) => {
    return <h6 style={{fontSize:fontSize?fontSize:'12px',fontWeight:fontWeight?fontWeight:'500',color:col,textAlign:alignfun,margin:marginfun,textTransform:textTransform, wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',}}>{val}</h6>
}

export const Itheadings = ({val,col,marginfun,alignfun,fontSize,fontWeight,textTransform}) => {
    return <h6 style={{fontSize:fontSize?fontSize:'15px',fontWeight:fontWeight?fontWeight:'800',color:col,textAlign:alignfun,margin:marginfun,textTransform:textTransform, wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',}}>{val}</h6>
}






