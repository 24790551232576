import React,{useState,useEffect} from 'react';
import TextInput from '../../components/ItComponents/TextInput';
import {  Itheadings, ItSmallText } from '../../helpers/constants/ConstantsText';
import ITMain from './ITMain';
import { gray_dark_1, theme_color } from '../../helpers/constants/ConstantColor';
import PrimaryButton, { PrimaryButtonAdd } from '../../components/buttons/PrimaryButton';
import {AiOutlineInfoCircle} from 'react-icons/ai';


import {IoClose} from 'react-icons/io5'

import { Drawer,Select} from 'antd';
import IconButton from '@mui/material/IconButton';
import Tooltip from "@material-ui/core/Tooltip";
import toast, { Toaster } from 'react-hot-toast'
import Dragger from "antd/lib/upload/Dragger";


import {
    createMuiTheme,
  } from "@material-ui/core/styles";
import axios from 'axios';
import { MdOutlineDelete } from 'react-icons/md';
import { BiEditAlt } from 'react-icons/bi';
import './Maincss.css';
import { base_url } from '../../App';
import { image_not_Added_for_Asset } from '../../components/constants/Constants1';


const { Option } = Select;


function Connector(){

    const AuthHeader = {headers:{
        'Content-Type': 'multipart/form-data',
    }}

    const [modal,setmodal] = useState(false)
    const [connector,setconnector] = useState({_id:null,related_to:'',model_id:'',adapter_name:'',adapter_image:null})
    const [error,seterror] = useState({_id:null,related_to:'',model_id:'',adapter_name:'',adapter_image:null})
    const [connector_arr,setconnector_arr] = useState([])
    const [related_device_arr,setrelated_device_arr] = useState([])

    

    useEffect(()=>{
        getdata()
        getrelateddevices()
    },[])  
    
    function getdata(){
        axios.get('it/adapters/get')
        .then((res)=>{
            setconnector_arr(res.data.data)
        }).catch((err)=>{
            // console.log('err',err)
        })
    }

    function getrelateddevices(){
        axios.get('it/relatedtodevice/get')
        .then((res)=>{
            let arr = []
            res.data.data.forEach((d)=>{
                let obj = {
                    _id:d._id,
                    value:d.related_to
                }
                arr.push(obj)
            })
            setrelated_device_arr(arr)
        }).catch((err)=>{
            // console.log('err',err)
        })
    }

    function add_connector(){
        if(!connector.model_id){
            seterror({...error,model_id:'The Model Id Field is required'})
        }else if(!connector.related_to){
            seterror({...error,related_to:'The Related To Field is required'})
        }else if(!connector.adapter_name){
            seterror({...error,adapter_name:'The Adapter Name Field is required'})
        }else{
        let fd = new FormData()
        fd.append('related_to',connector.related_to?.id)
        fd.append('adapter_name',connector.adapter_name)
        fd.append('model_id',connector.model_id)
        connector?.adapter_image?.originFileObj !== undefined && fd.append('image',connector?.adapter_image?.originFileObj)

        axios.post('it/adapters/create',fd,AuthHeader)
        .then((res)=>{
            toast.success(res.data.message)
            setmodal(false)
            getdata()
        }).catch((err)=>{
            console.log("err",err)
            if(err?.response?.data?.err?.keyPattern?.model_id){
                seterror({...error,model_id:'Model Id should be unique'})
            }
        })
    }
   }

    function update_connector(){
        let fd = new FormData()
        fd.append('related_to',connector.related_to?.id)
        fd.append('adapter_name',connector.adapter_name)
        fd.append('model_id',connector.model_id)
        connector?.adapter_image?.originFileObj !== undefined &&  fd.append('image',connector?.adapter_image?.originFileObj)
        fd.append('_id',connector.id)
        fd.append('method','_PUT')
        axios.post(`it/adapters/update/${connector.id}`,fd)
        .then((res)=>{
            toast.success(res.data.message)
            setmodal(false)
            getdata()
        }).catch((err)=>{
        })
    }

    function delete_connector(v){
        axios.delete(`it/adapters/delete/${v._id}`)
        .then((res)=>{
            toast.success(res.data.message)
            getdata()
        }).catch((err)=>{

        })
    }

    const handleUpload = (v) => {
        setconnector({...connector,adapter_image:v.fileList[0]})
    }

    function setdataforedit(p){
        console.log("p anna",p)
        // related_to:{},model_id:'',adapter_name:'',adapter_image:null
        setconnector({
            id:p._id,
            adapter_name:p?.adapter_name,
            model_id:p?.model_id,
            adapter_image:p?.adapter_image,
            related_to:{value:p?.related_to?.related_to,id:p?.related_to?._id},
            })
        setmodal(true)
    }


    return(
        <ITMain>
            <div style={{width:'95%'}}>
                <Toaster />

                <Drawer visible={modal} closable={false}>
                {/* <Modal visible={modal} footer={false} closable={false} width='260px'> */}
                    <div style={{width:'220px',position:'relative'}}>

                    <Tooltip title="Close" >
                            <IconButton style={{padding:'2px',marginTop:'-5px',position:'absolute',right:-10,top:-10}}>
                            <IoClose  onClick={()=>setmodal(!modal)} style={{ cursor: 'pointer',color:gray_dark_1, }} size={16} />
                            </IconButton>
                        </Tooltip>

                        <p style={{fontSize:'10px',fontWeight:'500',background:'#c9ffe2',padding:'4px',marginTop:'0px'}}>Use the below form to create or update the connector for your assets.</p>

                        <ItSmallText val={'Connector Id'} marginfun={'10px 0px 5px 0px'} />
                        <TextInput val={ connector.model_id} onchange={(e)=>{setconnector({...connector,model_id:e.target.value});seterror({...error,model_id:''})}} />
                        <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                            {(error?.model_id) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>}
                            <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.model_id}</p>
                        </div>

                        <ItSmallText val={'Related To'} marginfun={'10px 0px 5px 0px'} />
                            <Select value={connector?.related_to?.value} onChange={(v)=>{setconnector({...connector,related_to:related_device_arr?.find((d)=>d.id === v)});seterror({...error,related_to:''})}} style={{width:'100%',borderLeft:`3px solid ${theme_color}`,borderRadius:'4px'}} >
                                {related_device_arr?.map((r)=>(
                                    <Option key={r._id} value={r._id}>{r?.value}</Option>
                                ))}
                            </Select>
                            <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                            {(error?.related_to) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>}
                            <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.related_to}</p>
                        </div>

                         

                        <ItSmallText val={'Adapter Name'} marginfun={'10px 0px 5px 0px'} />
                        <TextInput val={ connector.adapter_name} onchange={(e)=>{setconnector({...connector,adapter_name:e.target.value});seterror({...error,adapter_name:''})}} />
                        <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                            {(error?.adapter_name) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>}
                            <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.adapter_name}</p>
                        </div>
                        <ItSmallText val={'Adapter Photo'} marginfun={'10px 0px 5px 0px'} />
                        {connector.adapter_image === undefined || connector.adapter_image === null || connector.adapter_image === "null"   ?
                        <Dragger style={{ marginTop: '10px',minHeight:'80px',maxHeight:'100px' }}
                            onChange={handleUpload}
                            beforeUpload={() => false}
                            showUploadList={false}>
                            
                            <div style={{display:'flex',height:'100px',alignItems:'center',flexDirection:'column'}}>
                            <img src={'https://cdn-icons-png.flaticon.com/512/2504/2504768.png'} style={{ width: '30px', objectFit: 'contain',alignItems:'center',display:'flex',justifyItems:'center',justifySelf:'center',alignSelf:'center' }} />
                            <p className="ant-upload-text" style={{fontWeight:'600',fontSize:'8px',marginTop:'10px'}}>Click or drag file to this area to upload</p>
                            <p className="ant-upload-hint" style={{fontSize:'6px'}}>
                                Support for a single file upload. Strictly used from uploading brand image
                            </p>
                            </div>
                        </Dragger>
                        :
                        <div>
                            {connector?.adapter_image?.name?.length !== undefined && connector?.adapter_image.name?.length !== null ?
                            <div style={{ display: 'flex', alignItems: 'center', border: '1px solid lightgray', padding: '2px 10px', position: 'relative', marginTop: '10px' }}>
                                <img src={'https://cdn-icons-png.flaticon.com/512/2504/2504768.png'} style={{ width: '20px', objectFit: 'contain' }} />
                                <div style={{ marginLeft: '10px',marginTop:'5px' }}>
                                    <h6 style={{ fontSize: '8px', fontWeight: '600', marginBottom: '2px' }}>{connector?.adapter_image?.name?.slice(0,25)}</h6>
                                    <h6 style={{ fontSize: '6px', color: 'gray' }}>{connector?.adapter_image?.size} bytes</h6>
                                </div>
                                <MdOutlineDelete size={15} style={{ position: 'absolute', right: '10px' }} onClick={()=>setconnector({...connector,adapter_image:null})} />
                            </div>
                            :
                            <div style={{position:'relative',}}>
                                <IoClose  onClick={()=>setconnector({...connector,adapter_image:null})} style={{ cursor: 'pointer',color:gray_dark_1,position:'absolute',right:0 }} size={16} />
                                <img src={`${base_url}${connector?.adapter_image}`} style={{ width: '100px',height:'100px', objectFit: 'stretch' }} />
                            </div>  }  
                        </div>}  

                        <PrimaryButton btn_name={connector._id === null ? 'ADD ADAPTER' : "UPDATE ADAPTER"} onClick={connector._id === null ? add_connector : update_connector} />
                    </div>
                {/* </Modal> */}
                </Drawer>
                <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',marginTop:'-10px',borderBottom:`1px solid #f2f2f2`}}>
                <Itheadings val={'Adapters'} />
                  <div onClick={()=>(setconnector({_id:null,adapter_name:'',adapter_image:null,related_to:''}),setmodal(true))}>
                 <PrimaryButtonAdd btn_name={'Add ADAPTER'}  />
                 </div>
                </div>
            
                        <div className='row'>
                        {connector_arr.map((p)=>(
                        <div className='col-md-3'>
                        <div className='card_processor '  key={p._id} style={{display:'flex',margin:'15px 0px',cursor:'pointer',borderLeft:'4px solid #4079AC',paddingLeft:'10px',width:'100%',position:'relative'}}>
                           {(p?.adapter_image !== null && p?.adapter_image !== undefined) ? 
                            <img src={`${base_url}${p.adapter_image}`} style={{minWidth:'20px',maxWidth:'40px',height:'30px',objectFit:'contain'}} />
                            :
                            <img src={`${image_not_Added_for_Asset}`} style={{minWidth:'20px',maxWidth:'40px',height:'30px',objectFit:'contain'}} />}

                            {/* <img src={`${base_url}${p?.adapter_image}`} style={{minWidth:'20px',maxWidth:'40px',height:'30px',objectFit:'cover'}}  /> */}
                            <div style={{marginLeft:'10px',display:'flex'}}>
                            <div>
                            <h6 style={{fontSize:'13px',marginBottom:'0px'}}>{p.adapter_name}</h6>
                            <h6 style={{fontWeight:'800',fontSize:'12px',marginBottom:'0px'}}>{p.related_to.related_to}</h6>
                            </div>
                            <div style={{display:'flex',marginTop:'5px',position:'absolute',right:10}} className="delete_visible">
                            <Tooltip title="Delete" >
                                <IconButton style={{marginTop:'-5px'}} onClick={()=>delete_connector(p)}>
                            <MdOutlineDelete  size={16} style={{color:'#000'}} />
                            </IconButton>
                            </Tooltip>
                            <Tooltip title="Edit" >
                                <IconButton style={{marginTop:'-5px',}} onClick={()=>setdataforedit(p)}>
                            <BiEditAlt  size={16} style={{color:'#000'}} />
                            </IconButton>
                            </Tooltip>
                            </div>
                            </div>
                            
                        </div>
                        </div>
                        ))}
                        </div>
            

            </div>
        </ITMain>
    )
}


export default Connector