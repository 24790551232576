import React, { useEffect, useState } from 'react'

import axios from "axios";
import { useNavigate } from "react-router-dom";
import { authHeader } from '../../../helpers/usertoken/UserToken';
import PrimaryButton, { PrimaryButtonFilter } from '../../../components/buttons/PrimaryButton'
import { useSelector } from 'react-redux';
import { Modal, message, Menu, Dropdown, Input, Tabs, Select, Checkbox, Pagination, Drawer } from 'antd'
import { Row, Col, Tag } from 'antd';
import { GrFormAdd } from 'react-icons/gr'
import { HiOutlineFilter } from 'react-icons/hi'
import { IoClose } from 'react-icons/io5'
import DateField from '../../../components/inputcomponents/DateField';
import moment from 'moment'
import Texttruncate from '../../../components/TextTruncater/Texttruncate';

import { Timeline } from '@mui/lab';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';

import { IoCreateOutline } from 'react-icons/io5'
import { AiOutlineClockCircle, AiOutlineEdit } from 'react-icons/ai'
import { BiTransfer } from 'react-icons/bi'
import { data_not_found } from '../../../helpers/Constants';
import { MdTimeline } from 'react-icons/md';
import { IoIosClose } from 'react-icons/io';


const { TabPane } = Tabs;
const { Option } = Select;



function TeamCLD() {

  const navigate = useNavigate()


  const theme_color = useSelector(state=>state.Theme.theme_color)



  const [search_contact, setsearch_contact] = useState({ search_text: '', contact_status: '', from_date: '', to_date: '', filter_status: false, department_id: '', employee_id: '' })
  const [tempsearch_contact, settempsearch_contact] = useState({ search_text: '', contact_status: '', from_date: '', to_date: '', filter_status: false, department_id: '', employee_id: '' })

  const [search_lead, setsearch_lead] = useState({ search_text: '', lead_stage: [], from_date: '', to_date: '', filter_status: false, department_id: '', employee_id: '' })
  const [tempsearch_lead, settempsearch_lead] = useState({ search_text: '', lead_stage: [], from_date: '', to_date: '', department_id: '', employee_id: '' })

  const [search_deal, setsearch_deal] = useState({ search_text: '', deal_stage: '', from_date: '', to_date: '', filter_status: false, department_id: '', employee_id: '' })
  const [tempsearch_deal, settempsearch_deal] = useState({ search_text: '', deal_stage: '', from_date: '', to_date: '', department_id: '', employee_id: '' })

  const [departement_arr, setdepartement_arr] = useState([])

  const [tab_active, settab_active] = useState('1')
  const [tab_active1, settab_active1] = useState('1')


  const [lead_status_filter, setlead_status_filter] = useState()

  const [deal_status_filter, setdeal_status_filter] = useState()
  const [main_data, setmain_data] = useState({})


  const type = [
    {
      'label': 'By Department',
      'value': 'By Department',
      'id': '2'
    },
    {
      'label': 'By Employee',
      'value': 'By Employee',
      'id': '3'
    },
  ]


  const [employee, setemployee] = useState([])

  const [md_team, setmd_team] = useState([])

  const [modalstep, setmodalstep] = useState({ step1: false, step2: false, step3: false, step4: false, step5: false })


  const contact_status_filter = [{
    id: "Active",
    value: "Active"
  },
  {
    id: "In Active",
    value: "In Active"
  },
  {
    id: "Connected",
    value: "Connected"
  },
  {
    id: "Not Connected",
    value: "Not Connected"
  },
  {
    id: "Old Client",
    value: "Old Client"
  }]


  const [lead_status, setlead_status] = useState([])


  let lead_status_basic_gallery_options = [{ id: 'Attempted to Contact', value: 'Attempted to Contact' }, { id: 'Contact in Future', value: 'Contact in Future' }, { id: 'Contacted', value: 'Contacted' }, { id: 'Junk lead', value: 'Junk lead' }, { id: 'Lost lead', value: 'Lost lead' }, { id: 'Not Contacted', value: 'Not Contacted' }, { id: 'Pre Qualified', value: 'Pre Qualified' }, { id: 'Not Qualified', value: 'Not Qualified' }, { id: 'Hold', value: 'Hold' }]
  let lead_status_basic_fms_options = [{ id: 'Meeting/Discussion', value: 'Meeting/Discussion' }, { id: 'Assessment', value: 'Assessment' }, { id: ' Proposal sent', value: ' Proposal sent' }, { id: 'Negotiation', value: 'Negotiation' }, { id: 'Final closure', value: 'Final closure' }, { id: 'Agreement', value: 'Agreement' }, { id: 'Hold', value: 'Hold' }]
  let lead_status_basic_project_options = [{ id: 'BD', value: 'BD' }, { id: 'Design', value: 'Design' }, { id: 'QS', value: 'QS' }, { id: 'MEP', value: 'MEP' }, { id: 'BOQ Review', value: 'BOQ Review' }, { id: 'Contact in Future', value: 'Contact in Future' }, { id: 'Closed', value: 'Closed' }, { id: 'Lost', value: 'Lost' }, { id: 'Junk Lead', value: 'Junk Lead' }, { id: 'Hold', value: 'Hold' }]
  let lead_status_basic_trans_options = [{ id: 'RFQ', value: 'RFQ' }, { id: 'Options sent/Provided', value: 'Options sent/Provided' }, { id: 'Inspection', value: 'Inspection' }, { id: 'Meeting/Discussion', value: 'Meeting/Discussion' }, { id: 'LOI', value: 'LOI' }, { id: 'Agreement/Registration', value: 'Agreement/Registration' }, { id: 'Collection', value: 'Collection' }, { id: 'Lead Lost', value: 'Lead Lost' }, { id: 'Negotiation', value: 'Negotiation' }, { id: 'Hold', value: 'Hold' }]
  let lead_status_basic_hr_options = [{ id: 'Follow up', value: 'Follow up' }, { id: 'Waiting for requirement', value: 'Waiting for requirement' }, { id: 'Closed', value: 'Closed' }, { id: 'lost', value: 'lost' }, { id: 'Hold', value: 'Hold' }]
  const dealStage_proj =
    [
      {
        id: "Execution",
        value: "Execution"
      },
      {
        id: "Handing Over",
        value: "Handing Over"
      },
      {
        id: "Payment Pending",
        value: "Payment Pending"
      },
      {
        id: "Invoice raised",
        value: "Invoice raised"
      },
      {
        id: "Closed",
        value: "Closed"
      },
    ]
  const dealStage_trans =
    [
      {
        id: "Payment Received",
        value: "Payment Received"
      },
      {
        id: "Payment Pending",
        value: "Payment Pending"
      },
      {
        id: "Agreement/Registered",
        value: "Agreement/Registered"
      },
      {
        id: "Invoice Raised",
        value: "Invoice Raised"
      },
    ]
  const dealStage_hr =
    [
      {
        id: "Payment Received",
        value: "Payment Received"
      },
      {
        id: "Payment Pending",
        value: "Payment Pending"
      },
      {
        id: "Deal Closed",
        value: "Deal Closed"
      },
      {
        id: "Service stopped",
        value: "Service stopped"
      },
    ]
  const dealStage_fms =
    [
      {
        id: "Agreement Renewal",
        value: "Agreement Renewal"
      },
      {
        id: "Organic Growth",
        value: "Organic Growth"
      },
      {
        id: "Payment Received",
        value: "Payment Received"
      },
      {
        id: "Payment Pending",
        value: "Payment Pending"
      },
      {
        id: "Invoice Raised",
        value: "Invoice Raised"
      },
    ]
  const dealStage_gallery =
    [
      {
        id: "Payment Received",
        value: "Payment Received"
      },
      {
        id: "Payment Pending",
        value: "Payment Pending"
      },
      {
        id: "Invoise Raised",
        value: "Invoise Raised"
      },
      {
        id: "Work In Progess",
        value: "Work In Proges"
      },
    ]



  useEffect(() => {
    if (roles.includes('admin') || roles.includes('db_head') || roles.includes('db_user')) {
      setlead_status([])
    } else {
      setsearch_contact({ ...search_contact, department_id: department[0]?.id })
      if (department[0].department_name === 'Transaction Team') {
        getusersbydepartmentId(department[0].id)
        setassign_data({ ...assign_data, department_id: department[0].id, department_name: department[0].department_name })
        setlead_status(lead_status_basic_trans_options)
        setdeal_status_filter(dealStage_trans)
      } else if (department[0].department_name === 'Project Team') {
        getusersbydepartmentId(department[0].id)
        setassign_data({ ...assign_data, department_id: department[0].id, department_name: department[0].department_name })
        setlead_status(lead_status_basic_project_options)
        setdeal_status_filter(dealStage_proj)
      } else if (department[0].department_name === 'Hr Team') {
        getusersbydepartmentId(department[0].id)
        setassign_data({ ...assign_data, department_id: department[0].id, department_name: department[0].department_name })
        setlead_status(lead_status_basic_hr_options)
        setdeal_status_filter(dealStage_hr)
      } else if (department[0].department_name === 'FMS Team') {
        getusersbydepartmentId(department[0].id)
        setassign_data({ ...assign_data, department_id: department[0].id, department_name: department[0].department_name })
        setlead_status(lead_status_basic_fms_options)
        setdeal_status_filter(dealStage_fms)
      } else if (department[0].department_name === 'Gallery Team') {
        getusersbydepartmentId(department[0].id)
        setassign_data({ ...assign_data, department_id: department[0].id, department_name: department[0].department_name })
        setlead_status(lead_status_basic_gallery_options)
        setdeal_status_filter(dealStage_gallery)
      }
    }
  }, [])


  const menu = (
    <Menu style={{ width: '350px', marginTop: '12px', padding: '10px' }}>
      <h6 style={{ marginLeft: '14px', fontSize: '14px', fontWeight: '500' }}>Add Search Query</h6>
      <div style={{ display: 'flex', width: '90%' }}>
        {tab_active === '1' && <Input type={"text"} style={{ width: '95%', margin: 'auto', marginLeft: '5%' }} value={tempsearch_contact.search_text} onChange={e => settempsearch_contact({ ...tempsearch_contact, search_text: e.target.value })} />}
        {tab_active === '2' && <Input type={"text"} style={{ width: '95%', margin: 'auto', marginLeft: '5%' }} value={tempsearch_lead.search_text} onChange={e => settempsearch_lead({ ...tempsearch_lead, search_text: e.target.value })} />}
        {tab_active === '3' && <Input type={"text"} style={{ width: '95%', margin: 'auto', marginLeft: '5%' }} value={tempsearch_deal.search_text} onChange={e => settempsearch_deal({ ...tempsearch_deal, search_text: e.target.value })} />}
        {tab_active === '1' && <PrimaryButton btn_name="+" padding='5px 10px' onClick={() => setsearch_contact({ ...search_contact, search_text: tempsearch_contact.search_text })} />}
        {tab_active === '2' && <PrimaryButton btn_name="+1" padding='5px 10px' onClick={() => setsearch_lead({ ...search_lead, search_text: tempsearch_lead.search_text })} />}
        {tab_active === '3' && <PrimaryButton btn_name="+2" padding='5px 10px' onClick={() => setsearch_deal({ ...search_deal, search_text: tempsearch_deal.search_text })} />}
      </div>
    </Menu>
  )

  const menu1 = (
    <Menu style={{ width: '200px', marginTop: '10px' }}>
      <h6 style={{ padding: '2%', fontWeight: '500', fontSize: '13px' }}>Choose an option</h6>
      <Row style={{ width: '90%', margin: 'auto' }}>
        {contact_status_filter?.map((s) => (
          <Col key={s.id} span={24} style={{ background: 'white', padding: '0px 5px', display: 'flex' }} onClick={() => setsearch_contact({ ...search_contact, contact_status: s.value })}>
            <Checkbox style={{ marginRight: '5px' }} />
            <p style={{ fontWeight: '600', fontSize: '13px' }}>{s.value}</p>
          </Col>
        ))}
      </Row>
    </Menu>
  )

  const menu2 = (
    <Menu style={{ width: '400px', marginTop: '10px' }}>
      <h6 style={{ padding: '2%', fontWeight: '500', fontSize: '13px' }}>Choose an option</h6>
      <Row style={{ width: '90%', margin: 'auto' }}>
        {lead_status_filter?.map((s) => (
          <Col span={12} style={{ background: 'white', padding: '0px 5px', display: 'flex' }} onClick={() => setleadstage(s.value)}>
            <Checkbox style={{ marginRight: '5px' }} checked={search_lead.lead_stage.includes(s.value)} />
            <p style={{ fontWeight: '600', fontSize: '13px' }}>{s.value}</p>
          </Col>
        ))}
      </Row>
    </Menu>
  )

  const menu3 = (
    <Menu style={{ width: '500px', marginTop: '10px' }}>
      <h6 style={{ padding: '2%', fontWeight: '500', fontSize: '13px' }}>Choose an option</h6>
      <Row style={{ width: '90%', margin: 'auto' }}>
        {deal_status_filter?.map((s) => (
          <Col key={s} span={12} style={{ background: 'white', padding: '0px 5px', display: 'flex' }} onClick={() => setsearch_deal({ ...search_deal, deal_stage: s.value })}>
            <Checkbox style={{ marginRight: '5px' }} />
            <p style={{ fontWeight: '600', fontSize: '13px' }}>{s.value}</p>
          </Col>
        ))}
      </Row>
    </Menu>
  )



  const [contacts, setcontacts] = useState([])
  const [leads, setleads] = useState([])
  const [deals, setdeals] = useState([])
  const [visible1, setvisible1] = useState(false)
  const [assignleads, setassignleads] = useState([])

  const [datepickervisible, setdatepickervisible] = useState(false);

  const [assign_data, setassign_data] = useState({ department_id: '', employee_id: '', type: '', type_name: '', department_name: '', employee_name: '' })

  const [md_data_filter, setmd_data_filter] = useState({ department_id: '', from_date: '', to_date: '', toDep: '', department_name: '', department_name1: '' })

  const [pagination, setpagination] = useState({})

  const [page, setpage] = useState(1)

  const department = useSelector(state => state.Auth.department)
  const roles = useSelector(state => state.Auth.roles)


  const [lead_tracker, setlead_tracker] = useState([])
  const [task_tracker, settask_tracker] = useState([])
  const [meeting_tracker, setmeeting_tracker] = useState([])

  useEffect(() => {
    if (roles.includes('hod') && (!roles.includes('admin') && !roles.includes('db_head') && !roles.includes('db_user'))) {
      getcontactshod(1)
      setsearch_contact({ ...search_contact, department_id: department[0]?.id })
      setsearch_lead({ ...search_lead, department_id: department[0]?.id })
      setsearch_deal({ ...search_deal, department_id: department[0]?.id })
      getusersbydepartmentId(department[0].id)
    } else if (!roles.includes('hod') && ((roles.includes('admin') || roles.includes('db_head') || roles.includes('db_user')))) {
      getcontacts(1)
    }
    getDepartements()
    mdteamsetting()
  }, [])

  function getcontacts(v) {
    setpage(v)
    axios.get(`contact/getallcontacts/${v}`, authHeader)
      .then((res) => {
        // console.log('contacts',res.data,'page',page)
        setcontacts(res.data.datas)
        setpagination(res.data.pagination)
      })
  }
  function getleads(v) {
    setpage(v)
    axios.get(`lead/getallleads/${v}`, authHeader)
      .then((res) => {
        console.log('leads', res.data, 'page', page)
        setleads(res.data.datas)
        setpagination(res.data.pagination)
      })
  }
  function getdeals(v) {
    setpage(v)
    axios.get(`deal/getalldeals/${v}`, authHeader)
      .then((res) => {
        console.log('deals', res.data, 'page', page)
        setdeals(res.data.datas)
        setpagination(res.data.pagination)
      })
  }
  function getAssignedLeads(v) {
    setpage(v)
    axios.get(`assign/getall/${v}`, authHeader)
      .then((res) => {
        setassignleads(res.data.datas)
        setpagination(res.data.pagination)
      }).catch((err) => {
      })
  }
  function getAssignedLeadsHod(v) {
    setpage(v)
    axios.get(`assign/getallbydepartment/${department[0]?.id}/${v}`, authHeader)
      .then((res) => {
        setassignleads(res.data.datas)
        setpagination(res.data.pagination)
        // console.log('res data here', res.data)
      }).catch((err) => {
        // console.log('err me na', err)
      })
  }
  function getcontactshod(v,v1) {
    // console.log('getcontactshod',v1);
    let status = v1 == 'reset';
    setpage(v)
    axios.get(`contact/search-all?type=${'okk '}&department_id=${department[0]?.id}&employee_id=${status ? ''  : assign_data?.employee_id}&search_text=${status ? ''  :  search_contact?.search_text}&status=${status ? ''  : search_contact?.contact_status}&from_date=${status ? ''  : search_contact?.from_date}&to_date=${status ? ''  : search_contact?.to_date}&page=${v}`, authHeader)
      .then((res) => {
        // setsearch_contact({ ...search_contact,filter_status: true })
        setpagination(res.data.pagination)
        setcontacts(res.data.datas)
      })
  }
  function getleadshod(v,v1) {
    let status = v1 == 'reset';
    setpage(v)
    axios.get(`lead/search-all?type=${'okk'}&department_id=${department[0].id}&employee_id=${status ? ''  : assign_data.employee_id}&status=${status ? ''  : search_lead.lead_stage}&from_date=${status ? ''  : search_lead.from_date}&to_date=${status ? ''  : search_lead.to_date}&page=${v}`, authHeader)
      .then((res) => {
        // setsearch_lead({ ...search_lead, department_id: department[0].id, filter_status: true })
        setpagination(res.data.pagination)
        setleads(res.data.datas)
      })
  }
  function getdealshod(v,v1) {
    let status = v1 == 'reset';
    setpage(v)
    axios.get(`deal/search-all?type=${'okk'}&department_id=${department[0].id}&employee_id=${status ? ''  : assign_data.employee_id}&deal_stage=${status ? ''  : search_deal.deal_stage}&from_date=${status ? ''  : search_deal.from_date}&to_date=${status ? ''  : search_deal.to_date}&page=${v}`, authHeader)
      .then((res) => {
        // setsearch_deal({ ...search_deal, department_id: department[0].id, filter_status: true })
        setpagination(res.data.pagination)
        setdeals(res.data.datas)
      })
  }
  function getDepartements() {
    axios.get('department/get', authHeader)
      .then((res) => {
        const departement_arr_created = []
        res.data.datas.forEach(d => {
          let data = {}
          data['label'] = d.department_name
          data['value'] = d.department_name
          data['id'] = d.id
          departement_arr_created.push(data)
        })
        setdepartement_arr(departement_arr_created)


        let md_team = ['Database Team', 'MD Office', 'Media Team']
        let md_team_updated = departement_arr_created?.filter(d => md_team?.includes(d.value))
        setmd_team(md_team_updated)

        // console.log('md_team_updated', md_team_updated)
      })
  }
  const paginateddata = (v) => {
    setpage(v)
    // console.log('tab_active tab_active',tab_active)
    if (tab_active === '1') {
      if (search_contact.filter_status) {

      } else {
        axios.get(`contact/getallcontacts/${v}`, authHeader)
          .then((res) => {
            setcontacts(res.data.datas)
            setpagination(res.data.pagination)
          }).catch((err) => {
          })
      }
    } else if (tab_active === '2') {
      getleads(v)
    } else if (tab_active === '3') {
      getdeals(v)
    }

  }
  function setactivetab(v) {
    setpage(1)

    if (roles.includes('hod') && !roles.includes('admin') && !roles.includes('db_head') && !roles.includes('db_user')) {
      setassign_data({ ...assign_data, department_name: department[0].department_name, employee_name: '', department_id: department[0].department_id, employee_id: '' })
    } else {
      setassign_data({ ...assign_data, department_name: '', employee_name: '', department_id: '', employee_id: '' })
    }

    if (v === '1') {
      settab_active(v)
      setsearch_contact({ ...search_contact, department_id: '', employee_id: '', search_text: '', contact_status: '', from_date: '', to_date: '', filter_status: false })
      if (roles.includes('hod') && !roles.includes('admin') && !roles.includes('db_head') && !roles.includes('db_user')) {
        setsearch_contact({ ...search_contact, department_id: department[0]?.id })
        getcontactshod(1)
      } else if (!roles.includes('hod') && (roles.includes('admin') || roles.includes('db_head') || roles.includes('db_user'))) {
        getcontacts(1)
      }
    } else if (v === '2') {
      settab_active(v)
      setsearch_lead({ ...search_lead, search_text: '', lead_stage: '', from_date: '', to_date: '', filter_status: false })
      if (roles.includes('hod') && !roles.includes('admin') && !roles.includes('db_head') && !roles.includes('db_user')) {
        getleadshod(1)
        // console.log('department_id',search_lead?.department_id)
        if (departement_arr.find(d => d?.id === search_lead?.department_id)?.value === 'Transaction Team') {
          setlead_status_filter(lead_status_basic_trans_options)
        } else if (departement_arr.find(d => d?.id === search_lead?.department_id)?.value === 'Project Team') {
          setlead_status_filter(lead_status_basic_project_options)
        } else if (departement_arr.find(d => d?.id === search_lead?.department_id)?.value === 'Hr Team') {
          setlead_status_filter(lead_status_basic_hr_options)
        }
      } else if (!roles.includes('hod') && (roles.includes('admin') || roles.includes('db_head') || roles.includes('db_user'))) {
        getleads(1)
      }
    } else if (v === '3') {
      settab_active(v)
      setsearch_deal({ ...search_deal, search_text: '', deal_stage: '', from_date: '', to_date: '', filter_status: false })
      if (roles.includes('hod') && !roles.includes('admin') && !roles.includes('db_head') && !roles.includes('db_user')) {
        getdealshod(1)
      } else if (!roles.includes('hod') && (roles.includes('admin') || roles.includes('db_head') || roles.includes('db_user'))) {
        getdeals(1)
      }
    } else if (v === '4') {
      settab_active(v)
      if (roles.includes('hod') && !roles.includes('admin') && !roles.includes('db_head') && !roles.includes('db_user')) {
        getAssignedLeadsHod(1)
      } else if (!roles.includes('hod') && (roles.includes('admin') || roles.includes('db_head') || roles.includes('db_user'))) {
        getAssignedLeads(1)
      }
    } else if (v === '5') {
      console.log('iam here krishna okk',v)
      setmd_data_filter({ ...md_data_filter, department_id: departement_arr?.find(d => d.value === 'MD Office')?.id, department_name: departement_arr?.find(d => d.value === 'MD Office')?.value })
      settab_active(v)
      settab_active1('1')
      getmdleads(1)
    }
  }
  function setactivetab1(v) {
    setmd_data_filter({ ...md_data_filter, department_id: departement_arr?.find(d => d.value === 'MD Office')?.id, department_name: departement_arr?.find(d => d.value === 'MD Office')?.value, department_name1: '', toDep: '', from_date: '', to_date: '' })
    settempsearch_lead({ ...tempsearch_lead, from_date: '', to_date: '' })
    if (v === '1') {
      getmdleads(1)
      settab_active1(v)
    } else if (v === '2') {
      getmdassignedleads(1)
      settab_active1(v)
    }
  }
  function getusersbydepartmentId(v) {
    axios.get(`user/get-users-by-departmentId/${v}`, authHeader)
      .then((res) => {
        let temp_Arr = []
        res.data.datas.map((d) => {
          let obj = {}
          obj['label'] = d.name
          obj['value'] = d.name
          obj['id'] = d.employee_id
          // let des = ''
          // d.user_roles.forEach((u) => {
          //   des = des + u.roles[0].display_name + ','
          // })
          // obj['label'] = obj['label'] + `(${des})`
          // obj['value'] = obj['value'] + `(${des})`
          obj['label'] = obj['label']
          obj['value'] = obj['value']
          temp_Arr.push(obj)
        })
        setemployee(temp_Arr)
      }).catch((err) => {
      })
  }
  function getpaginatedcontactsbasedfilter(v) {
    setpage(v)
    axios.get(`contact/search-all?type=junk&department_id=${assign_data.department_id}&employee_id=${assign_data.employee_id}&search_text=${search_contact.search_text}&status=${search_contact.contact_status}&from_date=${search_contact.from_date}&to_date=${search_contact.to_date}&page=${v}`, authHeader)
      .then((res) => {
        setsearch_contact({ ...search_contact, filter_status: true })
        settempsearch_contact({ search_text: '', contact_status: '', from_date: '', to_date: '', department_id: '', employee_id: '', })
        setsearch_lead({ ...search_lead, filter_status: false })
        setsearch_deal({ ...search_deal, filter_status: false })
        setpagination(res.data.pagination)
        setcontacts(res.data.datas)
      })
  }
  function getpaginatedleadsbasedfilter(v) {
    setpage(v)
    axios.get(`lead/search-all?type=junk&department_id=${assign_data.department_id}&employee_id=${assign_data.employee_id}&status=${search_lead.lead_stage.join(',')}&from_date=${search_lead.from_date}&to_date=${search_lead.to_date}&page=${v}`, authHeader)
      .then((res) => {
        setsearch_contact({ ...search_contact, filter_status: false })
        settempsearch_lead({ search_text: '', lead_stage: '', from_date: '', to_date: '', department_id: '', employee_id: '', })
        setsearch_lead({ ...search_lead, filter_status: true })
        setsearch_deal({ ...search_deal, filter_status: false })
        setpagination(res.data.pagination)
        setleads(res.data.datas)
      })
  }
  function getpaginateddealsbasedfilter(v) {
    setpage(v)
    axios.get(`deal/search-all?type=junk&department_id=${assign_data.department_id}&employee_id=${assign_data.employee_id}&search_text=${search_deal.search_text}&deal_stage=${search_deal.deal_stage}&from_date=${search_deal.from_date}&to_date=${search_deal.to_date}&page=${v}`, authHeader)
      .then((res) => {
        setsearch_contact({ ...search_contact, filter_status: false })
        settempsearch_deal({ search_text: '', deal_stage: '', from_date: '', to_date: '', department_id: '', employee_id: '', })
        setsearch_lead({ ...search_lead, filter_status: false })
        setsearch_deal({ ...search_deal, filter_status: true })
        setpagination(res.data.pagination)
        setdeals(res.data.datas)
      })
  }
  function applyfilter() {
    setpage(1)
    if (!roles.includes('hod') && ((roles.includes('admin') || roles.includes('db_head') || roles.includes('db_user')))) {
      if (tab_active === '1') {
        getpaginatedcontactsbasedfilter(1)
      } else if (tab_active === '2') {
        getpaginatedleadsbasedfilter(1)
      } else if (tab_active === '3') {
        getpaginateddealsbasedfilter(1)
      } else if (tab_active1 === '1') {
        getmdleads(1)
      } else if (tab_active1 === '2') {
        getmdassignedleads(1)
      }
    }
    else if (roles.includes('hod') && ((!roles.includes('admin') && !roles.includes('db_head') && !roles.includes('db_user')))) {
      if (tab_active === '1') {
        getcontactshod(1)
      } else if (tab_active === '2') {
        getleadshod(1)
      } else if (tab_active === '3') {
        getdealshod(1)
      } else if (tab_active === '4') {
        getAssignedLeadsHod(1)
      }
    }
  }
  function mdteamsetting() {

  }
  function getmdleads(v, v1) {
    setpage(v)
    axios.get(`activity/get-dep-wise-leads?depId=${md_data_filter.department_id === '' ? departement_arr?.find(d => d.value === 'MD Office')?.id : md_data_filter.department_id}&from_date=${v1 === 'reset' ? '' : md_data_filter?.from_date}&to_date=${v1 === 'reset' ? '' : md_data_filter?.to_date}&toDep=${md_data_filter?.toDep}&page=${v}`, authHeader)
      .then((res) => {
        setleads(res.data.datas)
        setpagination(res.data.pagination)
      })
      .catch(err => {
      })
  }
  function getmdassignedleads(v, v1) {
    setpage(v)
    axios.get(`activity/get-dep-wise-leads-assigned?depId=${md_data_filter.department_id === '' ? departement_arr?.find(d => d.value === 'MD Office')?.id : md_data_filter.department_id}&from_date=${v1 === 'reset' ? '' : md_data_filter.from_date}&to_date=${v1 === 'reset' ? '' : md_data_filter.to_date}&toDep=${md_data_filter.toDep}&page=${v}`, authHeader)
      .then((res) => {
        setassignleads(res?.data?.datas)
        setpagination(res?.data?.pagination)
      })
      .catch(err => {
      })
  }
  function resetfilter() {
    console.log('im working here all right',roles)

    setpage(1)
    if (!roles.includes('hod') && ((roles.includes('admin') || roles.includes('db_head') || roles.includes('db_user')))) {
      setmd_data_filter({ ...md_data_filter, department_id: departement_arr?.find(d => d.value === 'MD Office')?.id, department_name: departement_arr?.find(d => d.value === 'MD Office')?.value })

      setsearch_contact({ search_text: '', contact_status: '', from_date: '', to_date: '', department_id: '', employee_id: '', filter_status: false })
      settempsearch_contact({ search_text: '', contact_status: '', from_date: '', to_date: '', department_id: '', employee_id: '', })
      setassign_data({ department_id: '', employee_id: '', type: '', type_name: '', department_name: '', employee_name: '' })

      setsearch_lead({ search_text: '', lead_stage: '', from_date: '', to_date: '', filter_status: false, department_id: '', employee_id: '', })
      settempsearch_lead({ search_text: '', lead_stage: '', from_date: '', to_date: '', department_id: '', employee_id: '', })
      setassign_data({ department_id: '', employee_id: '', type: '', type_name: '', department_name: '', employee_name: '' })

      setsearch_deal({ search_text: '', deal_stage: '', from_date: '', to_date: '', filter_status: false, department_id: '', employee_id: '', })
      settempsearch_deal({ search_text: '', deal_stage: '', from_date: '', to_date: '', department_id: '', employee_id: '', })
      setassign_data({ department_id: '', employee_id: '', type: '', type_name: '', department_name: '', employee_name: '' })

      if (tab_active === '1') {
        getcontacts(1)
      } else if (tab_active === '2') {
        getleads(1)
      } else if (tab_active === '3') {
        getdeals(1)
      } else if (tab_active1 === '1') {
        getmdleads(1)
      } else if (tab_active1 === '2') {
        getmdassignedleads(1)
      }
    } else {
      console.log('iam working here okk status',tab_active === '1')

      setsearch_contact({ search_text: '', contact_status: '', from_date: '', to_date: '', filter_status: false, department_id: department[0].id, employee_id: '', })
      settempsearch_contact({ search_text: '', contact_status: '', from_date: '', to_date: '', department_id: department[0].id, employee_id: '', })
      setassign_data({ department_id: department[0].id, employee_id: '', type: '', type_name: '', department_name: department[0].department_name, employee_name: '' })
      setsearch_lead({ search_text: '', lead_stage: '', from_date: '', to_date: '', filter_status: false, department_id: department[0].id, employee_id: '', })
      settempsearch_lead({ search_text: '', lead_stage: '', from_date: '', to_date: '', department_id: department[0].id, employee_id: '', })
      setassign_data({ department_id: department[0].id, employee_id: '', type: '', type_name: '', department_name: department[0].department_name, employee_name: '' })
      setsearch_deal({ search_text: '', deal_stage: '', from_date: '', to_date: '', filter_status: false, department_id: department[0].id, employee_id: '', })
      settempsearch_deal({ search_text: '', deal_stage: '', from_date: '', to_date: '', department_id: department[0].id, employee_id: '', })
      setassign_data({ department_id: department[0].id, employee_id: '', type: '', type_name: '', department_name: department[0].department_name, employee_name: '' })
      if (tab_active === '1') {
        getcontactshod(1, 'reset')
      } else if (tab_active === '2') {
        console.log('im here now a days okk')
        getleadshod(1, 'reset')
      } else if (tab_active === '3') {
        getdealshod(1, 'reset')
      }
    }
  }
  async function resetfilter1() {
    settempsearch_lead({ ...tempsearch_lead, from_date: '', to_date: '' })
    setmd_data_filter({ from_date: '', to_date: '', department_id: departement_arr?.find(d => d.value === 'MD Office')?.id, department_name: departement_arr?.find(d => d.value === 'MD Office')?.value, toDep: '', department_name1: null })
    if (tab_active1 === '1') {
      getmdleads(1, 'reset')
    } else if (tab_active1 === '2') {
      getmdassignedleads(1, 'reset')
    }
  }
  function handleChange({ key, value }) {
    settempsearch_lead({ ...tempsearch_lead, [key]: value })
  }
  function setdatepickervisiblefun() {
    setdatepickervisible(false)
    setsearch_lead({ ...search_lead, from_date: '', to_date: '' })
  }
  function setselectedDateRange() {
    if (!tempsearch_lead.from_date || !tempsearch_lead.to_date) {
      message.info('From date or To date might be missing')
    } else if (tab_active !== '4' && tab_active !== '5') {
      if (tab_active === '1') {
        settempsearch_lead({ ...tempsearch_lead, from_date: '', to_date: '' })
        setsearch_contact({ ...search_contact, from_date: tempsearch_lead.from_date, to_date: tempsearch_lead.to_date })
        setdatepickervisible(false)
      } else if (tab_active === '2') {
        settempsearch_lead({ ...tempsearch_lead, from_date: '', to_date: '' })
        setsearch_lead({ ...search_lead, from_date: tempsearch_lead.from_date, to_date: tempsearch_lead.to_date })
        setdatepickervisible(false)
      } else if (tab_active === '3') {
        settempsearch_lead({ ...tempsearch_lead, from_date: '', to_date: '' })
        setsearch_deal({ ...search_deal, from_date: tempsearch_lead.from_date, to_date: tempsearch_lead.to_date })
        setdatepickervisible(false)
      }
    } else {
      setmd_data_filter({ ...md_data_filter, from_date: tempsearch_lead.from_date, to_date: tempsearch_lead.to_date })
      setdatepickervisible(false)
    }
  }
  function handleChangefun(v, v1, v2) {
    if (v1 === 'type') {
      setassign_data({ ...assign_data, [v1]: v, type_name: type.find((t) => t.id === v).value })
      setmodalstep({ ...modalstep, step1: false })
    } else if (v1 === 'department_id') {
      if (tab_active === '1') {
        setsearch_contact({ ...search_contact, department_id: v })
      } else if (tab_active === '2') {
        setsearch_lead({ ...search_lead, department_id: v })
        if (departement_arr?.find(d => d?.id === v)?.value === 'Transaction Team') {
          setlead_status_filter(lead_status_basic_trans_options)
        } else if (departement_arr?.find(d => d?.id === v)?.value === 'Project Team') {
          setlead_status_filter(lead_status_basic_project_options)
        } else if (department?.find(d => d?.id === v)?.value === 'Hr Team') {
          setlead_status_filter(lead_status_basic_hr_options)
        } else {
          setlead_status_filter([])
        }
      } else if (tab_active === '3') {
        setsearch_deal({ ...search_deal, department_id: v })
        if (departement_arr.find(d => d.id === v)?.value === 'Transaction Team') {
          setdeal_status_filter(dealStage_trans)
        } else if (departement_arr.find(d => d.id === v)?.value === 'Project Team') {
          setdeal_status_filter(dealStage_proj)
        } else if (departement_arr.find(d => d.id === v)?.value === 'Hr Team') {
          setdeal_status_filter(dealStage_hr)
        }
      } else if (tab_active1 === '1') {
        if (v1 === '' || v2 === undefined) {
          setmd_data_filter({ ...md_data_filter, department_id: v, department_name: departement_arr.find((d) => d.id === v)?.value })
        } else {
          setmd_data_filter({ ...md_data_filter, toDep: v, department_name1: departement_arr.find((d) => d.id === v)?.value })
        }
      } else if (tab_active1 === '2') {
        if (v1 === '' || v2 === undefined) {
          setmd_data_filter({ ...md_data_filter, department_id: v, department_name: departement_arr.find((d) => d.id === v)?.value })
        } else {
          setmd_data_filter({ ...md_data_filter, toDep: v, department_name1: departement_arr.find((d) => d.id === v)?.value })
        }
      }

      setassign_data({ ...assign_data, [v1]: v, department_name: departement_arr.find((d) => d.id === v)?.value })
      getusersbydepartmentId(v)
      setmodalstep({ ...modalstep, step2: false, step4: false, step5: false })

    } else if (v1 === 'employee_id') {
      if (tab_active === '1') {
        setsearch_contact({ ...search_contact, employee_id: v })
      } else if (tab_active === '2') {
        setsearch_lead({ ...search_lead, employee_id: v })
      } else if (tab_active === '2') {
        setsearch_deal({ ...search_deal, employee_id: v })
      }
      setassign_data({ ...assign_data, [v1]: v, employee_name: employee.find((d) => d.id === v)?.value })
      setmodalstep({ ...modalstep, step3: false })
    }
  }
  function tracker(id) {

    axios.get(`lead/get/${id}`, authHeader)
      .then((res) => {
        setmain_data(res.data.datas[0])
        // console.log('res data here',res.data)
      })

    axios.get(`activity/get-timeline/Lead/${id}`, authHeader)
      .then((res) => {
        setlead_tracker(res.data.datas)
        let tracker_arrange = []
        let tracker_arrange1 = []
        res.data.datas.sub.filter(t => t.type === 'Task' && t.history === '<p>Task created</p>' && t.event === 'create').forEach(e1 => {
          tracker_arrange.push(e1)
          // console.log('tracker_arrange.push(t)1',tracker_arrange)
          if (res.data.datas.sub.filter(t1 => t1.type === 'Task' && t1.event === 'update' && t1.type_id === e1.type_id).length > 0) {
            res.data.datas.sub.filter(t1 => t1.type === 'Task' && t1.event === 'update' && t1.type_id === e1.type_id).forEach(e => {
              tracker_arrange.push(e)
            })
            // console.log('tracker_arrange.push(t)2',tracker_arrange)

          }
        })
        settask_tracker(tracker_arrange)

        res.data.datas.sub.filter(t => t.type === "Meeting" && t.history === '<p>Meeting created</p>' && t.event === 'create').forEach(e1 => {
          tracker_arrange1.push(e1)
          // console.log('tracker_arrange.push(t)1',tracker_arrange)
          if (res.data.datas.sub.filter(t1 => t1.type === 'Meeting' && t1.event === 'update' && t1.type_id === e1.type_id).length > 0) {
            res.data.datas.sub.filter(t1 => t1.type === 'Meeting' && t1.event === 'update' && t1.type_id === e1.type_id).forEach(e => {
              tracker_arrange1.push(e)
            })
            // console.log('tracker_arrange.push(t)2',tracker_arrange)

          }
        })
        setmeeting_tracker(tracker_arrange1)
        setvisible1(true)
      }).catch((err) => {
      })
  }
  function setleadstage(v) {
    let current_search_lead = [...tempsearch_lead.lead_stage]
    if (current_search_lead?.includes(v)) {
      current_search_lead.splice(current_search_lead?.findIndex(v1 => v1 === v), 1)
    } else {
      current_search_lead.push(v)
    }
    // console.log('current_search_lead', current_search_lead)
    settempsearch_lead({ ...tempsearch_lead, lead_stage: current_search_lead })
    setsearch_lead({ ...search_lead, lead_stage: current_search_lead })
  }



  // console.log('search_lead?.department_id && lead_status_filter?.length', search_lead?.department_id, lead_status_filter?.length)

  return (
    <div style={{ width: '90%', margin: 'auto', marginTop: '50px' }}>

      <Drawer title="Basic Drawer" placement="right" onClose={() => setvisible1(false)} visible={visible1} width="400px" closable={false} headerStyle={{ display: 'none' }} >
        <h4 style={{ fontSize: '20px', fontWeight: '800' }}>TimeLine <AiOutlineClockCircle size={22} style={{ margin: '0px 5px' }} /> </h4>
        <Timeline style={{ marginTop: '30px', width: '100%' }} >

          {lead_tracker?.base?.filter((l => l.type === 'Lead' && (l?.history?.includes('Converted from contact ')))).map(t => (
            <TimelineItem>
              <TimelineOppositeContent color="text.secondary">
                <h6 style={{ fontSize: '12px', color: '#0ca7fa', fontWeight: 'bold' }}> {moment(main_data?.contact_id?.createdAt).format('lll')}</h6>
              </TimelineOppositeContent>
              <TimelineSeparator>
                {t.event === 'convert' && <IoCreateOutline size={20} color="#171636" />}
                {/* {t.event === 'update' && <AiOutlineEdit size={20} color="#171636"/>} */}
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent style={{ maxWidth: '155px', backgroundColor: '#fff' }}>
                <h6 style={{ fontSize: '12px', fontWeight: '600', marginBottom: '0px', paddingBottom: '0px' }}>Contact Created</h6>
                <span style={{ marginleft: '0px', fontSize: '11px', color: '#0087f5', fontWeight: '700', cursor: 'pointer', paddingLeft: '0px', marginTop: '0px', paddingBottom: '2px' }} onClick={() => navigate(`/contact_detail/${t?.history.split(':')[1]}`)}>View Details</span>
                <h6 style={{ fontSize: '11px', fontWeigt: '600', color: '#858585', marginBottom: '0px', paddingBottom: '0px' }}>{t?.user_id?.name}</h6>
              </TimelineContent>
            </TimelineItem>
          ))}

          {lead_tracker?.base?.filter((l => l.type === 'Lead' && (l?.history?.includes('Converted from contact ')))).map(t => (
            <TimelineItem>
              <TimelineOppositeContent color="text.secondary">
                <h6 style={{ fontSize: '12px', color: '#0ca7fa', fontWeight: 'bold' }}> {moment(t?.createdAt).format('lll')}</h6>
              </TimelineOppositeContent>
              <TimelineSeparator>
                {t.event === 'convert' && <BiTransfer size={20} color="#171636" />}
                {/* {t.event === 'update' && <AiOutlineEdit size={20} color="#171636"/>} */}
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent style={{ maxWidth: '155px', backgroundColor: '#fff' }}>
                <h6 style={{ fontSize: '12px', fontWeight: '600', marginBottom: '0px', paddingBottom: '0px' }} >{t?.history.split(':')[0]}</h6>
                <span style={{ marginleft: '0px', fontSize: '11px', color: '#0087f5', fontWeight: '700', cursor: 'pointer', paddingLeft: '0px', marginTop: '0px', paddingBottom: '2px' }} onClick={() => navigate(`/contact_detail/${t?.history.split(':')[1]}`)}>View Details</span>
                <h6 style={{ fontSize: '11px', fontWeigt: '600', color: '#858585', marginBottom: '0px', paddingBottom: '0px' }}>{t?.user_id?.name}</h6>
              </TimelineContent>
            </TimelineItem>
          ))}

          {lead_tracker?.base?.filter((l => l.type === 'Lead' && (l?.history?.includes('<p>Lead Created</p>')))).map(t => (
            <TimelineItem>
              <TimelineOppositeContent color="text.secondary">
                <h6 style={{ fontSize: '12px', color: '#0ca7fa', fontWeight: 'bold' }}> {moment(t?.createdAt).format('lll')}</h6>
              </TimelineOppositeContent>
              <TimelineSeparator>
                {t.event === 'create' && <IoCreateOutline size={20} color="#171636" />}
                {t.event === 'update' && <AiOutlineEdit size={20} color="#171636" />}
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent style={{ maxWidth: '155px', backgroundColor: '#fff' }}>
                <h6 style={{ fontSize: '12px', fontWeight: '600', marginBottom: '0px', paddingBottom: '0px' }} dangerouslySetInnerHTML={{ __html: t?.history }}></h6>
                {/* <span style={{marginleft:'0px',fontSize:'11px',color:'#0087f5',fontWeight:'700',cursor:'pointer',paddingLeft:'0px',marginTop:'0px',paddingBottom:'2px'}} onClick={()=>navigate(`/contact_detail/${t?.history.split(':')[1]}`)}>View Details</span> */}
                <h6 style={{ fontSize: '11px', fontWeigt: '600', color: '#858585', marginBottom: '0px', paddingBottom: '0px' }}>{t?.user_id?.name}</h6>
              </TimelineContent>
            </TimelineItem>
          ))}

          {/* <p>output here na {lead_tracker?.base?.filter((l=> l.event === "assign_lead")).length}</p> */}

          {lead_tracker?.base?.filter((l => l.event === "assign_lead")).map(t => (
            <>
              <TimelineItem>
                <TimelineOppositeContent color="text.secondary">
                  <h6 style={{ fontSize: '12px', color: '#0ca7fa', fontWeight: 'bold' }}> {moment(t?.createdAt).format('lll')}</h6>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  {t.event === 'assign_lead' && <IoCreateOutline size={20} color="#171636" />}
                  {/* {t.event === 'update' && <AiOutlineEdit size={20} color="#171636"/>} */}
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent style={{ maxWidth: '155px', backgroundColor: '#fff' }}>
                  <h6 style={{ fontSize: '12px', fontWeight: '600', marginBottom: '0px', paddingBottom: '0px' }} dangerouslySetInnerHTML={{ __html: t?.history }}></h6>
                  {/* <span style={{marginleft:'0px',fontSize:'11px',color:'#0087f5',fontWeight:'700',cursor:'pointer',paddingLeft:'0px',marginTop:'0px',paddingBottom:'2px'}} onClick={()=>navigate(`/contact_detail/${t?.history.split(':')[1]}`)}>View Details</span> */}
                  <h6 style={{ fontSize: '11px', fontWeigt: '600', color: '#858585', marginBottom: '0px', paddingBottom: '0px' }}>{t?.user_id?.name}</h6>
                </TimelineContent>
              </TimelineItem>
              <>
                {lead_tracker?.base?.filter((l1 => l1.type === 'Lead' && l1.event === 'update')).map(t => (
                  <TimelineItem>
                    <TimelineOppositeContent color="text.secondary">
                      <h6 style={{ fontSize: '12px', color: '#0ca7fa', fontWeight: 'bold' }}> {moment(t?.createdAt).format('lll')}</h6>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      {t.event === 'create' && <IoCreateOutline size={20} color="#171636" />}
                      {t.event === 'update' && <AiOutlineEdit size={20} color="#171636" />}
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent style={{ maxWidth: '155px', backgroundColor: '#fff' }}>
                      <h6 style={{ fontSize: '12px', fontWeight: '600', marginBottom: '0px', paddingBottom: '0px' }} dangerouslySetInnerHTML={{ __html: t?.history }}></h6>
                      {/* <span style={{marginleft:'0px',fontSize:'11px',color:'#0087f5',fontWeight:'700',cursor:'pointer',paddingLeft:'0px',marginTop:'0px',paddingBottom:'2px'}} onClick={()=>navigate(`/contact_detail/${t?.history.split(':')[1]}`)}>View Details</span> */}
                      <h6 style={{ fontSize: '11px', fontWeigt: '600', color: '#858585', marginBottom: '0px', paddingBottom: '0px' }}>{t?.user_id?.name}</h6>
                    </TimelineContent>
                  </TimelineItem>
                ))}
              </>
            </>

          ))}

          {lead_tracker?.base?.filter((l => l.type === 'Lead' && l.event === 'update')).map(t => (
            <TimelineItem>
              <TimelineOppositeContent color="text.secondary">
                <h6 style={{ fontSize: '12px', color: '#0ca7fa', fontWeight: 'bold' }}> {moment(t?.createdAt).format('lll')}</h6>
              </TimelineOppositeContent>
              <TimelineSeparator>
                {t.event === 'create' && <IoCreateOutline size={20} color="#171636" />}
                {t.event === 'update' && <AiOutlineEdit size={20} color="#171636" />}
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent style={{ maxWidth: '155px', backgroundColor: '#fff' }}>
                <h6 style={{ fontSize: '12px', fontWeight: '600', marginBottom: '0px', paddingBottom: '0px' }} dangerouslySetInnerHTML={{ __html: t?.history }}></h6>
                {/* <span style={{marginleft:'0px',fontSize:'11px',color:'#0087f5',fontWeight:'700',cursor:'pointer',paddingLeft:'0px',marginTop:'0px',paddingBottom:'2px'}} onClick={()=>navigate(`/contact_detail/${t?.history.split(':')[1]}`)}>View Details</span> */}
                <h6 style={{ fontSize: '11px', fontWeigt: '600', color: '#858585', marginBottom: '0px', paddingBottom: '0px' }}>{t?.user_id?.name}</h6>
              </TimelineContent>
            </TimelineItem>
          ))}

          {lead_tracker?.sub?.filter((l => l.type === 'Deal')).map(t => (
            <TimelineItem>
              <TimelineOppositeContent color="text.secondary">
                <h6 style={{ fontSize: '12px', color: '#0ca7fa', fontWeight: 'bold' }}> {moment(t?.createdAt).format('lll')}</h6>
              </TimelineOppositeContent>
              <TimelineSeparator>
                {t.event === 'create' && <IoCreateOutline size={20} color="#171636" />}
                {t.event === 'convert' && <BiTransfer size={20} color="#171636" />}
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent style={{ maxWidth: '155px', backgroundColor: '#fff' }}>
                <h6 style={{ fontSize: '12px', fontWeight: '600', marginBottom: '0px', paddingBottom: '0px' }} >{t?.history?.replace(/(<([^>]+)>)/gi, "").split(':')[0]}</h6>
                {t.event === 'create' ?
                  <>
                    {lead_tracker?.sub?.find((l => l.type === 'Deal' && l.history.includes('Converted from lead'))) &&
                      <span style={{ marginleft: '0px', fontSize: '11px', color: '#0087f5', fontWeight: '700', cursor: 'pointer', paddingLeft: '0px', marginTop: '0px', paddingBottom: '2px' }} onClick={() => navigate(`/deal_detail/${lead_tracker?.sub?.find((l => l.type === 'Deal' && l.history.includes('Converted from lead')))?.type_id}`)}>View Details</span>
                    }
                  </>
                  :
                  <span style={{ marginleft: '0px', fontSize: '11px', color: '#0087f5', fontWeight: '700', cursor: 'pointer', paddingLeft: '0px', marginTop: '0px', paddingBottom: '2px' }} onClick={() => navigate(`/deal_detail/${t?.type_id}`)}>View Details</span>
                }
                <h6 style={{ fontSize: '11px', fontWeigt: '600', color: '#858585', marginBottom: '0px', paddingBottom: '0px' }}>{t?.user_id?.name}</h6>
              </TimelineContent>
            </TimelineItem>
          ))}

          {task_tracker.map(t => (
            <TimelineItem>
              <TimelineOppositeContent color="text.secondary">
                <h6 style={{ fontSize: '12px', color: '#0ca7fa', fontWeight: 'bold' }}> {moment(t?.createdAt).format('lll')}</h6>
              </TimelineOppositeContent>
              <TimelineSeparator>
                {t.event === 'create' && <IoCreateOutline size={20} color="#171636" />}
                {t.event === 'update' && <AiOutlineEdit size={20} color="#171636" />}
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent style={{ maxWidth: '155px', backgroundColor: '#fff' }}>
                <h6 style={{ fontSize: '12px', fontWeight: '900', marginBottom: '0px', paddingBottom: '2px' }} dangerouslySetInnerHTML={{ __html: t?.history }}></h6>
                <h6 style={{ fontSize: '11px', fontWeigt: '600', color: '#858585', marginBottom: '0px', paddingBottom: '0px' }}>{t?.user_id?.name}</h6>
              </TimelineContent>
            </TimelineItem>
          ))}

          {meeting_tracker?.map(t => (
            <TimelineItem>
              <TimelineOppositeContent color="text.secondary">
                <h6 style={{ fontSize: '12px', color: '#0ca7fa', fontWeight: 'bold' }}> {moment(t?.createdAt).format('lll')}</h6>
              </TimelineOppositeContent>
              <TimelineSeparator>
                {t.event === 'create' && <IoCreateOutline size={20} color="#171636" />}
                {t.event === 'update' && <AiOutlineEdit size={20} color="#171636" />}
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent style={{ maxWidth: '155px', backgroundColor: '#fff' }}>
                <h6 style={{ fontSize: '12px', fontWeight: '900', marginBottom: '0px', paddingBottom: '2px' }} dangerouslySetInnerHTML={{ __html: t?.history }}></h6>
                <h6 style={{ fontSize: '11px', fontWeigt: '600', color: '#858585', marginBottom: '0px', paddingBottom: '0px' }}>{t?.user_id?.name}</h6>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </Drawer>


      <Modal visible={datepickervisible} footer={false} onCancel={() => setdatepickervisiblefun()}  closable={false} style={{ top: '21%', maxWidth: '350px' }}>
        <div style={{ maxWidth: '200px', }}>
        <IoIosClose  onClick={() => setdatepickervisiblefun()}size={20} style={{position:'absolute',top:'20px',cursor:'pointer',right:'25px'}} />

          <h6 style={{ padding: '2%', fontWeight: '500', fontSize: '13px' }}>Select Date range option</h6>
          <Row style={{ width: '300px', margin: 'auto', display: 'flex' }}>
            <Col span={12} style={{ background: 'white', padding: '0px 5px', width: '100%' }}>
              <h6 style={{ fontSize: '12px' }}>From Date</h6>
              <DateField
                disableprev='true'
                onChangefun={handleChange}
                name={'from_date'}
                date_value={tempsearch_lead.from_date}
                style={{ width: '100%' }} />
            </Col>

            <Col span={12} style={{ background: 'white', padding: '0px 5px' }}>
              <h6 style={{ fontSize: '12px' }}>To Date</h6>
              <DateField
                disableprev='true'
                name={'to_date'}
                onChangefun={handleChange}
                date_value={tempsearch_lead.to_date}
                style={{ width: '100%' }} />
            </Col>

            <div style={{ marginLeft: '5px' }} onClick={setselectedDateRange}>
              <PrimaryButton btn_name={'+ Selected Range'} />
            </div>
          </Row>
        </div>
      </Modal>

      <Modal visible={modalstep.step1} onCancel={() => setmodalstep({ ...modalstep, step1: false })} style={{ maxWidth: '300px', marginTop: '30px', marginLeft: '44%' }} footer={false}>
        <Row>
          <Col span={24}>
            <div style={{ width: '96%', margin: ' 10px auto', }}>
              <h6 style={{ fontSize: '12px', fontWeight: '600' }}>Select Type</h6>

              <Select
                showSearch
                placeholder="Select Type"
                optionFilterProp="children"
                onChange={(v) => handleChangefun(v, 'type')}
                size='medium'
                filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                style={{ width: '100%' }}
              >
                {
                  type.map((d) => (
                    <Option key={d.id} value={d.id}><div>{d.value}</div></Option>
                  ))
                }
              </Select>
            </div>
          </Col>
        </Row>
      </Modal>

      <Modal visible={modalstep.step2} onCancel={() => setmodalstep({ ...modalstep, step2: false })} style={{ maxWidth: '300px', }} centered footer={false}>
        <Row>
          <Col span={24}>
            <div style={{ width: '96%', margin: ' 10px auto', }}>
              <h6 style={{ fontSize: '12px', fontWeight: '600' }}>Select Departement</h6>

              <Select
                showSearch
                placeholder="Select Department"
                optionFilterProp="children"
                onChange={(v) => handleChangefun(v, 'department_id')}
                size='medium'
                value={assign_data.department_name}
                filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                style={{ width: '100%' }}
              >
                {
                  departement_arr.map((d) => (
                    <Option key={d.id} value={d.id}><div>{d.value}</div></Option>
                  ))
                }
              </Select>
            </div>
          </Col>
        </Row>
      </Modal>

      <Modal visible={modalstep.step3} onCancel={() => setmodalstep({ ...modalstep, step3: false })} style={{ maxWidth: '300px' }} centered footer={false}>
        <Row>
          <Col span={24}>
            <div style={{ width: '96%', margin: ' 10px auto', }}>
              <h6 style={{ fontSize: '12px', fontWeight: '600' }}>Select Employee</h6>

              <Select
                showSearch
                placeholder="Select Employee"
                optionFilterProp="children"
                onChange={(v) => handleChangefun(v, 'employee_id')}
                value={assign_data.employee_name}
                size='medium'
                filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                style={{ width: '100%' }}
              >
                {
                  employee.map((d) => (
                    <Option key={d.id} value={d.id}><div style={{fontSize:'13px',marginBottom:'0px',fontWeight:'600'}}>{d.value}</div></Option>
                  ))
                }
              </Select>
            </div>
          </Col>
        </Row>

      </Modal>

      <Modal visible={modalstep.step4} onCancel={() => setmodalstep({ ...modalstep, step4: false })} style={{ maxWidth: '300px', }} centered footer={false}>
        <Row>
          <Col span={24}>
            <div style={{ width: '96%', margin: ' 10px auto', }}>
              <h6 style={{ fontSize: '12px', fontWeight: '600' }}>Select Departement</h6>

              <Select
                showSearch
                placeholder="Select Department"
                optionFilterProp="children"
                onChange={(v) => handleChangefun(v, 'department_id')}
                size='medium'
                value={md_data_filter?.department_name}
                style={{ width: '100%' }}
              >
                {
                  md_team.map((d) => (
                    <Option key={d.id} value={d.id}><div>{d.value}</div></Option>
                  ))
                }
              </Select>
            </div>
          </Col>
        </Row>
      </Modal>

      <Modal visible={modalstep.step5} onCancel={() => setmodalstep({ ...modalstep, step5: false })} style={{ maxWidth: '300px', }} centered footer={false}>
        <Row>
          <Col span={24}>
            <div style={{ width: '96%', margin: ' 10px auto', }}>
              <h6 style={{ fontSize: '12px', fontWeight: '600' }}>Select Departement</h6>

              <Select
                showSearch
                placeholder="Select Department"
                optionFilterProp="children"
                onChange={(v) => handleChangefun(v, 'department_id', 'toDep')}
                size='medium'
                value={md_data_filter?.department_name1}
                filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                style={{ width: '100%' }}
              >
                {
                  departement_arr.map((d) => (
                    <Option key={d.id} value={d.id}><div>{d.value}</div></Option>
                  ))
                }
              </Select>
            </div>
          </Col>
        </Row>
      </Modal>

      {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <h1 style={{ fontWeight: '600', fontSize: '1.45em', margin: '16px 0' }}>{roles.includes('hod') ? 'Department' : 'Company'} CLD</h1>
      </div> */}

      <Tabs
        activeKey={tab_active}
        size="small"
        // style={{ borderTop: '1px solid #dbdbdb', }}
        onChange={(v) => setactivetab(v)}
      >
        <TabPane tab={` Contacts`} key="1" >
          <p style={{ paddingBottom: '0px', color: 'black', marginBottom: '10px', }}>{pagination.total} Contact's Found</p>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', borderBottom: '1px solid #f0f0f0', }}>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '-5px' }}>
              <div style={{ display: 'flex', alignItems: 'center', padding: '0px', marginLeft: '0px', padding: '0px 0px' }}>
                <HiOutlineFilter size={24} style={{ marginRight: '10px' }} />
                <h6 style={{ marginBottom: '0px', marginRight: '15px', fontSize: '14px', fontWeight: 'bolder' }}>Filter Contact By</h6>
                <div style={{ display: 'flex', alignItems: 'center' }}>


                  {search_contact.department_id === '' ?
                    <button type='button' className='btn  btn-light btn-sm m-1' onClick={() => setmodalstep({ ...modalstep, step2: true })} >
                      <GrFormAdd color="white" size={18} style={{ marginRight: '6px',marginTop:'-3px', color: 'white' }} />
                      <span>Select Department</span>
                    </button>
                    :
                    <div style={{ display: 'flex', alignItems: 'center', background: '#f8f9fa', padding: '8px', borderRadius: '2px', marginLeft: '2px' }}>
                      <h6 style={{ marginBottom: '0px', fontSize: '12px' }}>{assign_data?.department_name?.slice(0, 20)}</h6>
                      {(!roles.includes('hod') && (roles.includes('admin') || roles.includes('db_head') || roles.includes('db_user'))) && <IoClose style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => { setsearch_contact({ ...search_contact, department_id: '', employee_id: '' }); setassign_data({ ...assign_data, department_id: '', department_name: '', employee_id: '', employee_name: '' }) }} />}
                    </div>
                  }

                  {(roles.includes('hod') || roles.includes('admin') || !roles.includes('db_head') || !roles.includes('db_user')) && search_contact.department_id &&
                    <div style={{ display: 'flex', alignItems: 'center', padding: '0px', marginLeft: '0px', padding: '0px 0px' }}>
                      {search_contact.employee_id === '' ?
                        <button type='button' className='btn  btn-light btn-sm m-1' onClick={() => setmodalstep({ ...modalstep, step3: true })} >
                          <GrFormAdd color="white" size={18} style={{ marginRight: '6px',marginTop:'-3px', color: 'white' }} />
                          <span>Select Employee</span>
                        </button>
                        :
                        <div style={{ display: 'flex', alignItems: 'center', background: '#f8f9fa', padding: '8px', borderRadius: '2px', marginLeft: '2px', marginRight: '10px' }}>
                          <h6 style={{ marginBottom: '0px', fontSize: '12px' }}>{assign_data.employee_name}</h6>
                          <IoClose style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => { setsearch_contact({ ...search_contact, employee_id: '' }); setassign_data({ ...assign_data, employee_id: '', employee_name: '' }) }} />
                        </div>
                      }
                    </div>
                  }

                  {search_contact.search_text === '' ?
                    <Dropdown overlay={menu} trigger={['click']}>
                      <button type='button' className='btn  btn-light btn-sm m-1'   >
                        <GrFormAdd color="white" size={18} style={{ marginRight: '6px',marginTop:'-3px', color: 'white' }} />
                        <span>Add Search filter</span>
                      </button>
                    </Dropdown>
                    :
                    <div style={{ display: 'flex', alignItems: 'center', background: '#f8f9fa', padding: '8px', borderRadius: '2px' }}>
                      <h6 style={{ marginBottom: '0px', fontSize: '12px' }}>{search_contact.search_text.slice(0, 20)}{search_contact?.search_text?.length > 20 && '...'}</h6>
                      <IoClose style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => setsearch_contact({ ...search_contact, search_text: '' })} />
                    </div>

                  }

                  {search_contact.contact_status === '' ?
                    <Dropdown overlay={menu1} trigger={['click']}>
                      <button type='button' className='btn  btn-light btn-sm m-1'   >
                        <GrFormAdd color="white" size={18} style={{ marginRight: '6px',marginTop:'-3px', color: 'white' }} />
                        <span>Add Contact Status</span>
                      </button>
                    </Dropdown>
                    :
                    <div style={{ display: 'flex', alignItems: 'center', background: '#f8f9fa', padding: '8px', borderRadius: '2px', marginLeft: '5px' }}>
                      <h6 style={{ marginBottom: '0px', fontSize: '12px' }}>{search_contact?.contact_status?.slice(0, 20)}{search_contact?.contact_status?.length > 20 && '...'}</h6>
                      <IoClose style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => setsearch_contact({ ...search_contact, contact_status: '' })} />
                    </div>
                  }


                  {search_contact.from_date === '' || search_contact.to_date === '' ?
                    <div>
                      <button type='button' className='btn  btn-light btn-sm m-1' onClick={() => setdatepickervisible(true)}   >
                        <GrFormAdd color="white" size={18} style={{ marginRight: '6px',marginTop:'-3px', color: 'white' }} />
                        <span>Date interval</span>
                      </button>

                    </div>
                    :
                    <div style={{ display: 'flex', alignItems: 'center', background: '#f8f9fa', padding: '8px', borderRadius: '2px', margin: '0px 5px' }}>
                      <h6 style={{ marginBottom: '0px', fontSize: '12px' }}>{search_contact.from_date + ':' + search_contact.to_date}</h6>
                      <IoClose style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => setsearch_contact({ ...search_contact, from_date: '', to_date: '' })} />
                    </div>
                  }


                  <div style={{ marginRight: '5px' }} onClick={applyfilter}>
                    <PrimaryButtonFilter btn_name="Apply Filter" padding={'2px 8px'} />
                  </div>
                  <div style={{ marginRight: '5px' }} onClick={()=>resetfilter()}>
                    <PrimaryButtonFilter btn_name="Reset Filter" padding={'2px 8px'} />
                  </div>

                </div>
              </div>
            </div>
          </div>

          {contacts.length > 0 ?
            <>
              <div>
                <div className='mateial_table_header'>
                  <p style={{ width: '13%', color: 'black' }}>Company </p>
                  <p style={{ width: '14%', paddingLeft: '0.5%' }}>Contact Name</p>
                  <p style={{ width: '10%' }}>Phone</p>
                  <p style={{ width: '25%' }}>Email</p>
                  <p style={{ width: '15%' }}>Designation</p>
                  <p style={{ width: '10%' }}>Contact Status</p>
                  <p style={{ width: '13%' }}>Contact Owner</p>
                </div>
                {contacts.map((d) => (
                  <div key={d.id} className='mateial_table_body'>
                    <p style={{ width: '13%', fontWeight: '700', color: "#0594f2", wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis' }} onClick={() => navigate(`/contact_detail/${d?.id !== undefined ? d?.id : d?._id} `)} >{d?.company_name}</p>
                    <p style={{ width: '14%', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',fontSize:'13px', paddingLeft: '0.5%',paddingRight:'0.5%' }}>{d?.contact_name}</p>
                    <p style={{ width: '10%', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',fontSize:'12px',fontWeight:'800' }}>{d?.phone}</p>
                    <p style={{ width: '25%', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',fontSize:'13px',paddingRight:'0.5%'}}>{d?.email}</p>
                    <p style={{ width: '15%', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',fontSize:'13px',paddingRight:'0.5%' }}>{d?.designation}</p>
                    <p style={{ width: '10%', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',fontSize:'13px',paddingLeft:'4px' }}><Tag color={theme_color}>{d?.status}</Tag></p>
                    <p style={{ width: '13%', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',fontSize:'13px',paddingLeft:'4px' }}>{d?.contact_owner?.name}</p>
                  </div>
                ))}

              </div>
              {pagination.total > 20 &&
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
                  {search_contact.filter_status
                    ?
                    <Pagination current={page} size="small" total={pagination.total} onChange={(v) => getpaginatedcontactsbasedfilter(v)} showSizeChanger pageSizeOptions={[20]} pageSize={20} />
                    :
                    <Pagination current={page} size="small" total={pagination.total} onChange={(v) => paginateddata(v)} showSizeChanger pageSizeOptions={[20]} pageSize={20} />
                  }
                </div>}
            </>
            :
            <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
              <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '200px', objectFit: 'contain' }} />
              <h4 style={{ fontSize: '18px', fontWeight: '700', marginTop: '10px', textAlign: 'center' }}>{search_contact.filter_status ? "No Contact's Found" : "You don't have any contact added yet."}</h4>
              <h6 style={{ color: '#747474', fontSize: '13px', maxWidth: '80%', textAlign: 'center', margin: 'auto' }}>{search_contact.filter_status ? 'No data found with provided search options please check other format filter options' : 'Visit a contact create page and press on a add contact button. Then, you can find the contact on this page.'}</h6>
            </div>
          }

        </TabPane>

        <TabPane tab={`Leads`} key="2">
          <p style={{ paddingBottom: '0px', color: 'black', marginBottom: '10px' }}>{pagination.total} Lead's Found</p>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', borderBottom: '1px solid #f0f0f0', }}>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '-5px' }}>
              <HiOutlineFilter size={24} style={{ marginRight: '10px' }} />
              <h6 style={{ marginBottom: '0px', marginRight: '15px', fontSize: '14px', fontWeight: 'bolder' }}>Filter Leads </h6>
              <div style={{ display: 'flex', alignItems: 'center' }}>

                {search_lead.department_id === '' ?
                  <button type='button' className='btn  btn-light btn-sm m-1' onClick={() => setmodalstep({ ...modalstep, step2: true })} >
                    <GrFormAdd color="white" size={18} style={{ marginRight: '6px',marginTop:'-3px', color: 'white' }} />
                    <span>Select Department</span>
                  </button>
                  :
                  <div style={{ display: 'flex', alignItems: 'center', background: '#f8f9fa', padding: '8px', borderRadius: '2px', marginLeft: '2px' }}>
                    <h6 style={{ marginBottom: '0px', fontSize: '12px' }}>{assign_data.department_name}</h6>
                    {(!roles.includes('hod') && (roles.includes('admin') || roles.includes('db_head') || roles.includes('db_user'))) && <IoClose style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => { setsearch_lead({ ...search_lead, department_id: '', lead_stage: '' }); setassign_data({ ...assign_data, department_id: '', department_name: '' }) }} />}
                  </div>
                }


                {(roles.includes('hod') || roles.includes('admin') || !roles.includes('db_head') || !roles.includes('db_user')) && search_lead.department_id &&
                  <div style={{ display: 'flex', alignItems: 'center', padding: '0px', marginLeft: '0px', padding: '0px 0px' }}>
                    {assign_data.employee_id === '' ?
                      <button type='button' className='btn  btn-light btn-sm m-1' onClick={() => setmodalstep({ ...modalstep, step3: true })} >
                        <GrFormAdd color="white" size={18} style={{ marginRight: '6px',marginTop:'-3px', color: 'white' }} />
                        <span>Select Employee</span>
                      </button>
                      :
                      <div style={{ display: 'flex', alignItems: 'center', background: '#f8f9fa', padding: '8px', borderRadius: '2px', marginLeft: '4px', marginRight: '4px' }}>
                        <h6 style={{ marginBottom: '0px', fontSize: '12px' }}>{search_contact.search_text.slice(0, 20)}{assign_data.employee_name}</h6>
                        <IoClose style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => { setsearch_lead({ ...search_lead, employee_id: '' }); setassign_data({ ...assign_data, employee_id: '', employee_name: '' }) }} />
                      </div>
                    }
                  </div>
                }


                {search_lead?.search_text === '' ?
                  <Dropdown overlay={menu} trigger={['click']}>
                    <button type='button' className='btn  btn-light btn-sm m-1'   >
                      <GrFormAdd color="white" size={18} style={{ marginRight: '6px',marginTop:'-3px', color: 'white' }} />
                      <span>Add Search filter</span>
                    </button>
                  </Dropdown>
                  :
                  <div style={{ display: 'flex', alignItems: 'center', background: '#f8f9fa', padding: '8px', borderRadius: '2px' }}>
                    <h6 style={{ marginBottom: '0px', fontSize: '12px' }}>{search_lead?.search_text?.slice(0, 20)}{search_lead?.search_text?.length > 20 && '...'}</h6>
                    <IoClose style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => setsearch_lead({ ...search_lead, search_text: '' })} />
                  </div>
                }





                {search_lead?.department_id && lead_status_filter?.length > 0 &&
                  <>
                    {search_lead?.lead_stage.length === 0 ?
                      <Dropdown overlay={menu2} trigger={['click']}>
                        <button type='button' className='btn  btn-light btn-sm m-1'   >
                          <GrFormAdd color="white" size={18} style={{ marginRight: '6px',marginTop:'-3px', color: 'white' }} />
                          <span>Add Lead status</span>
                        </button>
                      </Dropdown>
                      :
                      <Dropdown overlay={menu2} trigger={['click']}>
                        <div style={{ display: 'flex', alignItems: 'center', background: '#f8f9fa', padding: '8px', borderRadius: '2px', marginLeft: '5px' }}>
                          <h6 style={{ marginBottom: '0px', fontSize: '12px' }}>{search_lead?.lead_stage[0]}{search_lead?.lead_stage?.length > 1 && `  +${search_lead?.lead_stage?.length - 1}`}</h6>
                          <IoClose style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => { setsearch_lead({ ...search_lead, lead_stage: [] }); settempsearch_deal({ ...tempsearch_lead, lead_stage: [] }) }} />
                        </div>
                      </Dropdown>
                    }
                  </>
                }


                {search_lead?.from_date === '' || search_lead?.to_date === '' ?
                  <div>
                    <button type='button' className='btn  btn-light btn-sm m-1' onClick={() => setdatepickervisible(true)}   >
                      <GrFormAdd color="white" size={18} style={{ marginRight: '6px',marginTop:'-3px', color: 'white' }} />
                      <span>Date interval</span>
                    </button>

                  </div>
                  :
                  <div style={{ display: 'flex', alignItems: 'center', background: '#f8f9fa', padding: '8px', borderRadius: '2px', margin: '0px 5px' }}>
                    <h6 style={{ marginBottom: '0px', fontSize: '12px' }}>{search_lead?.from_date + ':' + search_lead?.to_date}</h6>
                    <IoClose style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => setsearch_lead({ ...search_lead, from_date: '', to_date: '' })} />
                  </div>
                }


                <div style={{ marginRight: '5px' }} onClick={applyfilter}>
                  <PrimaryButtonFilter btn_name="Apply Filter" padding={'2px 8px'} />
                </div>
                <div style={{ marginRight: '5px' }} onClick={resetfilter}>
                  <PrimaryButtonFilter btn_name="Reset Filter" padding={'2px 8px'} />
                </div>

              </div>
            </div>
          </div>


          {leads.length > 0 ?
            <>
              <div className='mateial_table_header'>
                {/* <Checkbox style={{width: '4%'}}/> */}
                {/* <p style={{width:'4%'}}></p> */}
                <p style={{ width: '12%', color: 'black' }}>Lead Name</p>
                <p style={{ width: '10%', marginRight: '2%' }}>Company</p>
                <p style={{ width: '22%', marginRight: '2%' }}>Email</p>
                <p style={{ width: '12%' }}>Phone</p>
                <p style={{ width: '13%', marginRight: '4%' }}>Lead Status</p>
                <p style={{ width: '13%' }}>Company Source</p>
                <p style={{ width: '12%' }}>Lead Owner</p>
              </div>

              {leads?.map((d) => (
                <div key={d.id} className='mateial_table_body'>
                  {/* <Checkbox style={{width: '4%',marginTop:'5px'}}/> */}
                  <p style={{ width: '12%', color: "#0594f2", wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',fontSize:'13px', fontWeight: '700' }} onClick={() => navigate(`/lead_detail/${d?.id || d?._id}`)} >{d?.lead_name}</p>
                  <p style={{ width: '10%', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis', marginRight: '2%',fontSize:'13px',paddingLeft:'4px', }}>{d?.contact_id?.company_name}</p>
                  <p style={{ width: '22%', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis', marginRight: '2%',fontSize:'13px',paddingLeft:'10px', }}>{d?.contact_id?.email}</p>
                  <p style={{ width: '12%', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',fontSize:'12px',fontWeight:'800',paddingLeft:'3%', }}>{d?.contact_id?.phone}</p>
                  <p style={{ width: '13%', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis', marginRight: '4%',fontSize:'13px',paddingLeft:'3%' }}><Tag color="geekblue">{d?.lead_status}</Tag></p>
                  <p style={{ width: '13%', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',fontSize:'13px',paddingLeft:'5%' }}><Tag color="green">{d?.contact_id?.contact_source?.source_name}</Tag></p>
                  <p style={{ width: '12%', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',fontSize:'13px',paddingLeft:'5%' }}>{d?.lead_owner?.name}</p>
                  <p style={{ width: '5%' }}></p>
                </div>
              ))}

              {pagination.total > 20 &&
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
                  {search_lead.filter_status
                    ?
                    <Pagination current={page} size="small" total={pagination.total} onChange={(v) => getpaginatedleadsbasedfilter(v)} showSizeChanger pageSizeOptions={[20]} pageSize={20} />
                    :
                    <Pagination current={page} size="small" total={pagination.total} onChange={(v) => paginateddata(v)} showSizeChanger pageSizeOptions={[20]} pageSize={20} />
                  }
                </div>}
            </>
            :
            <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
              <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '200px', objectFit: 'contain' }} />
              <h4 style={{ fontSize: '18px', fontWeight: '700', marginTop: '10px', textAlign: 'center' }}>{search_lead.filter_status ? 'No Leads data Found' : "You don't have any lead created yet."}</h4>
              <h6 style={{ color: '#747474', fontSize: '13px', minWidth: '80%', textAlign: 'center', margin: 'auto' }}>{search_lead.filter_status ? 'No data found with provided search options ' : 'Visit a contact page and press on a convert icon. Then, you can find the lead on this page.'}</h6>
            </div>
          }
        </TabPane>

        <TabPane tab={`Deals`} key="3">
          <p style={{ paddingBottom: '0px', color: 'black', marginBottom: '10px' }}>{pagination.total} Deal's Found</p>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', borderBottom: '1px solid #f0f0f0', }} >
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '-5px' }}>
              {/* <div style={{display:'flex',alignItems:'center',marginLeft:'0px',padding:'5px 0px'}}> */}
              <HiOutlineFilter size={24} style={{ marginRight: '10px' }} />
              <h6 style={{ marginBottom: '0px', marginRight: '15px', fontSize: '14px', fontWeight: 'bolder' }}>Filter Deal By</h6>
              <div style={{ display: 'flex', alignItems: 'center' }}>


                {search_deal.department_id === '' ?
                  <button type='button' className='btn  btn-light btn-sm m-1' onClick={() => setmodalstep({ ...modalstep, step2: true })} >
                    <GrFormAdd color="white" size={18} style={{ marginRight: '6px',marginTop:'-3px', color: 'white' }} />
                    <span>Select Department</span>
                  </button>
                  :
                  <div style={{ display: 'flex', alignItems: 'center', background: '#f8f9fa', padding: '8px', borderRadius: '2px', marginLeft: '2px' }}>
                    <h6 style={{ marginBottom: '0px', fontSize: '12px' }}>{assign_data.department_name}</h6>
                    {(!roles.includes('hod') && (roles.includes('admin') || roles.includes('db_head') || roles.includes('db_user'))) && <IoClose style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => { setsearch_deal({ ...search_deal, department_id: '' }); setassign_data({ ...assign_data, department_id: '', department_name: '' }) }} />}
                  </div>
                }


                {(roles.includes('hod') || roles.includes('admin') || !roles.includes('db_head') || !roles.includes('db_user')) && search_deal.department_id &&
                  <div style={{ display: 'flex', alignItems: 'center', padding: '0px', marginLeft: '0px', padding: '0px 0px' }}>
                    {assign_data.employee_id === '' ?
                      <button type='button' className='btn  btn-light btn-sm m-1' onClick={() => setmodalstep({ ...modalstep, step3: true })} >
                        <GrFormAdd color="white" size={18} style={{ marginRight: '6px',marginTop:'-3px', color: 'white' }} />
                        <span>Select Employee</span>
                      </button>
                      :
                      <div style={{ display: 'flex', alignItems: 'center', background: '#f8f9fa', padding: '8px', borderRadius: '2px', marginLeft: '2px' }}>
                        <h6 style={{ marginBottom: '0px', fontSize: '12px' }}>{search_contact.search_text.slice(0, 20)}{assign_data.employee_name}</h6>
                        <IoClose style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => setassign_data({ ...assign_data, employee_id: '', employee_name: '' })} />
                      </div>
                    }
                  </div>
                }



                {search_deal.search_text === '' ?
                  <Dropdown overlay={menu} trigger={['click']}>
                    <button type='button' className='btn  btn-light btn-sm m-1'   >
                      <GrFormAdd color="white" size={18} style={{ marginRight: '6px',marginTop:'-3px', color: 'white' }} />
                      <span>Add Search filter</span>
                    </button>
                  </Dropdown>
                  :
                  <div style={{ display: 'flex', alignItems: 'center', background: '#f8f9fa', padding: '8px', borderRadius: '2px' }}>
                    <h6 style={{ marginBottom: '0px', fontSize: '12px' }}>{search_deal.search_text.slice(0, 20)}{search_deal.search_text.length > 20 && '...'}</h6>
                    <IoClose style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => setsearch_deal({ ...search_deal, search_text: '' })} />
                  </div>

                }

                {search_deal?.department_id && deal_status_filter?.length > 0 &&
                  <>
                    {search_deal?.deal_stage === '' ?
                      <Dropdown overlay={menu3} trigger={['click']}>
                        <button type='button' className='btn  btn-light btn-sm m-1'   >
                          <GrFormAdd color="white" size={18} style={{ marginRight: '6px',marginTop:'-3px', color: 'white' }} />
                          <span>Add Deal Stage</span>
                        </button>
                      </Dropdown>
                      :
                      <div style={{ display: 'flex', alignItems: 'center', background: '#f8f9fa', padding: '8px', borderRadius: '2px', marginLeft: '5px', marginRight: '5px' }}>
                        <h6 style={{ marginBottom: '0px', fontSize: '12px' }}>{search_deal?.deal_stage?.slice(0, 20)}{search_deal?.deal_stage?.length > 20 && '...'}</h6>
                        <IoClose style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => setsearch_deal({ ...search_deal, deal_stage: '' })} />
                      </div>
                    }
                  </>}


                {search_deal.from_date === '' || search_deal.to_date === '' ?
                  <div>
                    <button type='button' className='btn  btn-light btn-sm m-1' onClick={() => setdatepickervisible(true)}   >
                      <GrFormAdd color="white" size={18} style={{ marginRight: '6px',marginTop:'-3px', color: 'white' }} />
                      <span>Date interval</span>
                    </button>
                  </div>
                  :
                  <div style={{ display: 'flex', alignItems: 'center', background: '#f8f9fa', padding: '8px', borderRadius: '2px', margin: '0px 5px' }}>
                    <h6 style={{ marginBottom: '0px', fontSize: '12px' }}>{search_deal.from_date + ':' + search_deal.to_date}</h6>
                    <IoClose style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => setsearch_deal({ ...search_deal, from_date: '', to_date: '' })} />
                  </div>
                }


                <div style={{ marginRight: '5px' }} onClick={applyfilter}>
                  <PrimaryButtonFilter btn_name="Apply Filter" padding={'2px 8px'} />
                </div>
                <div style={{ marginRight: '5px' }} onClick={resetfilter}>
                  <PrimaryButtonFilter btn_name="Reset Filter" padding={'2px 8px'} />
                </div>

              </div>
            </div>
          </div>
          {/* </div>         */}

          {deals.length > 0 ?
            <>
              <div className='mateial_table_header'>
                {/* <Checkbox style={{width: '4%',marginTop:'5px'}}/> */}
                <p style={{ width: '12%' }}>Deal Name</p>
                <p style={{ width: '10%' }}>Company</p>
                <p style={{ width: '15%' }}>Stage</p>
                <p style={{ width: '13%' }}>Closing Date</p>
                <p style={{ width: '10%' }}>Phone</p>
                <p style={{ width: '20%', marginRight: '1%' }}>Email</p>
                <p style={{ width: '12%' }}>Deal Owner</p>
              </div>
              {deals?.map((d) => (
                <div key={d.id} className='mateial_table_body'>
                  {/* <Checkbox style={{width: '4%',marginTop:'5px'}}/> */}
                  <p style={{ width: '12%',fontSize:'13px', color: "#0594f2", cursor: 'pointer', fontWeight: '700', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',paddingRight:'1.5%' }} onClick={() => navigate(`/deal_detail/${d.id}`)}
                  >{d?.deal_name}</p>
                  <p style={{ width: '10%',fontSize:'13px',  wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-8px' }}>{d?.lead_id?.contact_id?.contact_name}</p>
                  <p style={{ width: '15%',fontSize:'13px',  wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'  }}><Tag color={theme_color}>{d?.deal_stage}</Tag></p>
                  <p style={{ width: '13%',fontSize:'12px',fontWeight:'800',  wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-10px'  }}>{moment(d?.closing_date).format('LL')}</p>
                  <p style={{ width: '10%',fontSize:'12px',fontWeight:'800',  wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-2px'  }}>{d?.lead_id?.contact_id?.phone}</p>
                  <p style={{ width: '20%',fontSize:'13px',  wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis' , marginRight: '1%',marginLeft:'-6px',paddingRight:'1%' }}>{d?.lead_id?.contact_id?.email}</p>
                  <p style={{ width: '12%',fontSize:'13px',  wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'  }}><Tag color={theme_color}>{d?.deal_owner?.name}</Tag></p>
                </div>
              ))}

              {pagination.total > 20 &&
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
                  {search_deal.filter_status
                    ?
                    <Pagination current={page} size="small" total={pagination.total} onChange={(v) => getpaginateddealsbasedfilter(v)} showSizeChanger pageSizeOptions={[20]} pageSize={20} />
                    :
                    <Pagination current={page} size="small" total={pagination.total} onChange={(v) => paginateddata(v)} showSizeChanger pageSizeOptions={[20]} pageSize={20} />
                  }
                </div>
              }
            </>
            :
            <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
              <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '200px', objectFit: 'contain' }} />
              <h4 style={{ fontSize: '18px', fontWeight: '700', marginTop: '10px', textAlign: 'center' }}>{search_deal.filter_status ? 'No Deals data Found' : "You don't have any deal created yet."}</h4>
              <h6 style={{ color: '#747474', fontSize: '13px', maxWidth: '80%', textAlign: 'center', margin: 'auto' }}>{search_deal.filter_status ? 'No data found with provided search options please check other format filter options' : 'Visit a lead page and press on a convert icon. Then, you can find the deal on this page.'}</h6>
            </div>
          }
        </TabPane>

        <TabPane tab="Assigned Leads" key="4">
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', borderBottom: '1px solid #f0f0f0', }} >
            <p style={{ paddingBottom: '0px', color: 'black' }}>{pagination.total} Assigned lead's Found</p>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '-5px' }}>
              <div style={{ display: 'flex', alignItems: 'center', marginLeft: '0px', padding: '5px 10px' }}>
              </div>
            </div>
          </div>

          {assignleads?.length > 0 && tab_active === '4' ?
            <>
              <div className='mateial_table_header'>
                {/* <Checkbox style={{width: '4%',marginTop:'5px'}}/> */}
                <p style={{ width: '12%' }}>Lead Name</p>
                <p style={{ width: '10%' }}>Company</p>
                <p style={{ width: '15%' }}>Email</p>
                <p style={{ width: '10%' }}>Phone</p>
                <p style={{ width: '16%', marginRight: '1%' }}>Assigned By</p>
                <p style={{ width: '15%',marginLeft:'-10px' }}>Assigned To</p>
                <p style={{ width: '10%' }}>Remarks</p>
                <p style={{ width: '10%',marginLeft:'16px' }}>Actions</p>
              </div>
              {assignleads?.map((d) => (
                <div key={d.id} className='mateial_table_body'>
                  {/* <Checkbox style={{width: '4%',marginTop:'5px'}}/> */}
                  <p style={{ width: '12%',fontSize:'13px', color: "#0594f2", cursor: 'pointer', fontWeight: '700',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',paddingRight:'0.5%' }}
                    onClick={() => navigate(`/lead_detail/${d?.lead_id?._id || d?.lead_id?._id}`)}
                  >{d?.lead_id?.lead_name}</p>
                  <p style={{ width: '10%',fontSize:'13px',   wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',paddingRight:'0.5%' }}>{d?.lead_id?.contat_id !== undefined && d?.lead_id?.contat_id[0]?.company_name}</p>
                  <p style={{ width: '15%',fontSize:'13px', marginLeft:'-10px' ,wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',paddingRight:'0.5%' }}>{d?.lead_id?.contat_id !== undefined && d?.lead_id?.contat_id[0]?.email}</p>
                  <p style={{ width: '10%',fontWeight:'800',fontSize:'12px',   wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis', }}>{d?.lead_id?.contat_id !== undefined && d?.lead_id?.contat_id[0]?.phone}</p>
                  <p style={{ width: '16%',fontSize:'13px',   wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-10px' }}><Tag color="green">{d?.assigned_by?.name}</Tag></p>
                  <p style={{ width: '15%',fontSize:'13px',   wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis', marginRight: '1%' }}><Tag color="cyan">{d?.user_id?.name}</Tag></p>
                  <p style={{ width: '10%',fontSize:'13px',   wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-10px' }}>{d?.remarks}</p>
                  <p style={{ width: '10%',fontSize:'13px',   wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'10px' }} onClick={() => tracker(d?.lead_id?._id)}><Tag color={theme_color}>Track me</Tag></p>
                </div>
              ))}

              {pagination.total > 20 &&
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
                  {roles.includes('hod') ?
                  <Pagination current={page} size="small" total={pagination.total} onChange={(v) => getAssignedLeadsHod(v)} showSizeChanger pageSizeOptions={[20]} pageSize={20}  /> :
                  (roles.includes('hod') || roles.includes('admin') || roles.includes('db_head') || roles.includes('db_user')) && 
                  <Pagination current={page} size="small" total={pagination.total} onChange={(v) => getAssignedLeads(v)} showSizeChanger pageSizeOptions={[20]} pageSize={20}  />}
                </div>}
            </>
            :
            <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
              <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '200px', objectFit: 'contain' }} />
              <h4 style={{ fontSize: '18px', fontWeight: '700', marginTop: '10px', textAlign: 'center' }}>No Assigned Leads found.</h4>
              <h6 style={{ color: '#747474', fontSize: '13px', maxWidth: '80%', textAlign: 'center', margin: 'auto' }}>{'No leads assigned found please try back later'}</h6>
            </div>
          }
        </TabPane>


        {(roles.includes('admin') || roles.includes('db_head') || roles.includes('db_user')) &&
          <TabPane tab="MD Team Leads" key="5">
            <Tabs
              activeKey={tab_active1}
              size="small"
              style={{ width: '100%', margin: '10px auto', marginBottom: '0px', marginTop: '-10px' }}
              onChange={(v) => setactivetab1(v)}>
              <TabPane tab="All Leads" key="1">
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', }} >

                  <p style={{ paddingBottom: '0px', color: 'black', marginBottom: '10px' }}>{pagination.total} MD Lead's Found</p>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', }}>
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '-5px' }}>
                      <HiOutlineFilter size={24} style={{ marginRight: '10px' }} />
                      <h6 style={{ marginBottom: '0px', marginRight: '15px', fontSize: '14px', fontWeight: 'bolder' }}>Filter Leads </h6>
                      <div style={{ display: 'flex', alignItems: 'center' }}>

                        {md_data_filter?.department_id === '' ?
                          <button type='button' className='btn  btn-light btn-sm m-1' onClick={() => setmodalstep({ ...modalstep, step4: true })} >
                            <GrFormAdd color="white" size={18} style={{ marginRight: '6px',marginTop:'-3px', color: 'white' }} />
                            <span>Select Department</span>
                          </button>
                          :
                          <div style={{ display: 'flex', alignItems: 'center', background: '#f8f9fa', padding: '8px', borderRadius: '2px', marginLeft: '2px' }}>
                            <h6 style={{ marginBottom: '0px', fontSize: '12px' }}>{md_data_filter?.department_name}</h6>
                            {(!roles.includes('hod') && (roles.includes('admin') || roles.includes('db_head') || roles.includes('db_user'))) && <IoClose style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => { setmd_data_filter({ ...md_data_filter, department_id: '', department_name: null }) }} />}
                          </div>
                        }






                        {md_data_filter?.from_date === '' || md_data_filter?.to_date === '' ?
                          <div>
                            <button type='button' className='btn  btn-light btn-sm m-1' onClick={() => setdatepickervisible(true)}   >
                              <GrFormAdd color="white" size={18} style={{ marginRight: '6px',marginTop:'-3px', color: 'white' }} />
                              <span>Date interval</span>
                            </button>
                          </div>
                          :
                          <div style={{ display: 'flex', alignItems: 'center', background: '#f8f9fa', padding: '8px', borderRadius: '2px', margin: '0px 5px' }}>
                            <h6 style={{ marginBottom: '0px', fontSize: '12px' }}>{md_data_filter?.from_date + ':' + md_data_filter?.to_date}</h6>
                            <IoClose style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => setmd_data_filter({ ...md_data_filter, from_date: '', to_date: '' })} />
                          </div>
                        }

                        {md_data_filter?.toDep === '' ?
                          <button type='button' className='btn  btn-light btn-sm m-1' onClick={() => setmodalstep({ ...modalstep, step5: true })} >
                            <GrFormAdd color="white" size={18} style={{ marginRight: '6px',marginTop:'-3px', color: 'white' }} />
                            <span>To Department</span>
                          </button>
                          :
                          <div style={{ display: 'flex', alignItems: 'center', background: '#f8f9fa', padding: '8px', borderRadius: '2px', marginLeft: '2px' }}>
                            <h6 style={{ marginBottom: '0px', fontSize: '12px' }}>{md_data_filter?.department_name1}</h6>
                            {(!roles.includes('hod') && (roles.includes('admin') || roles.includes('db_head') || roles.includes('db_user'))) && <IoClose style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => { setmd_data_filter({ ...md_data_filter, toDep: '', department_name1: null }) }} />}
                          </div>
                        }


                        <div style={{ marginRight: '5px' }} onClick={applyfilter}>
                          <PrimaryButtonFilter btn_name="Apply Filter" padding={'2px 8px'} />
                        </div>
                        <div style={{ marginRight: '5px' }} onClick={resetfilter1}>
                          <PrimaryButtonFilter btn_name="Reset Filter" padding={'2px 8px'} />
                        </div>

                      </div>
                    </div>
                  </div>
                </div>


                {leads.length > 0 ?
                  <>
                    <div className='mateial_table_header'>
                      {/* <Checkbox style={{width: '4%'}}/> */}
                      {/* <p style={{width:'4%'}}></p> */}
                      <p style={{ width: '12%', color: 'black' }}>Lead Name</p>
                      <p style={{ width: '10%', marginRight: '2%' }}>Company</p>
                      <p style={{ width: '22%', marginRight: '2%' }}>Email</p>
                      <p style={{ width: '12%' }}>Phone</p>
                      <p style={{ width: '10%', marginRight: '3%' }}>Lead Status</p>
                      <p style={{ width: '14%' }}>Contact Person</p>
                      <p style={{ width: '12%',marginLeft:'15px' }}>Lead Owner</p>
                    </div>

                    {leads?.map((d) => (
                      <div key={d.id} className='mateial_table_body'>
                        {/* <Checkbox style={{width: '4%',marginTop:'5px'}}/> */}
                        <p style={{ width: '12%', color: "#0594f2", wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis', fontWeight: '700' }} onClick={() => navigate(`/lead_detail/${d?.id || d?._id}`)} >{<Texttruncate text={d?.lead_name} />}</p>
                        <p style={{ width: '10%', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis', marginRight: '2%' }}>{d?.contact_id?.company_name}</p>
                        <p style={{ width: '22%', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis', marginRight: '2%' }}>{d?.contact_id?.email}</p>
                        <p style={{ width: '12%', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',fontWeight:'800',fontSize:'12px' }}>{d?.contact_id?.phone}</p>
                        <p style={{ width: '10%', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis', marginRight: '3%' }}>{d?.lead_status}</p>
                        <p style={{ width: '14%',marginLeft:'-10px',paddingRight:'0.5%', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis' }}>{d?.contact_id?.contact_name}</p>
                        <p style={{ width: '12%',fontSize:'12px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'15px' }}>{d?.lead_owner?.name}</p>
                      </div>
                    ))}

                    {pagination.total > 20 &&
                      <div style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
                        {search_lead.filter_status
                          ?
                          <Pagination current={page} size="small" total={pagination.total} onChange={(v) => getmdleads(v)} showSizeChanger pageSizeOptions={[20]} pageSize={20} />
                          :
                          <Pagination current={page} size="small" total={pagination.total} onChange={(v) => getmdleads(v)} showSizeChanger pageSizeOptions={[20]} pageSize={20} />
                        }
                      </div>}
                  </>
                  :
                  <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                    <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '200px', objectFit: 'contain' }} />
                    <h4 style={{ fontSize: '18px', fontWeight: '700', marginTop: '10px', textAlign: 'center' }}>You don't have any MD lead created yet.</h4>
                    <h6 style={{ color: '#747474', fontSize: '13px', maxWidth: '80%', textAlign: 'center', margin: 'auto' }}>Visit a contact page and press on a convert icon. Then, you can find the Md lead created on this page.</h6>
                  </div>
                }
              </TabPane>
              <TabPane tab="Assigned Leads" key="2">
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', }} >
                  <p style={{ paddingBottom: '0px', color: 'black' }}>{assignleads.length} MD Assigned lead's Found</p>

                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', }}>
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '-5px' }}>
                      <HiOutlineFilter size={24} style={{ marginRight: '10px' }} />
                      <h6 style={{ marginBottom: '0px', marginRight: '15px', fontSize: '14px', fontWeight: 'bolder' }}>Filter Leads </h6>
                      <div style={{ display: 'flex', alignItems: 'center' }}>

                        {md_data_filter?.department_id === '' ?
                          <button type='button' className='btn  btn-light btn-sm m-1' onClick={() => setmodalstep({ ...modalstep, step4: true })} >
                            <GrFormAdd color="white" size={18} style={{ marginRight: '6px',marginTop:'-3px', color: 'white' }} />
                            <span>Select Department</span>
                          </button>
                          :
                          <div style={{ display: 'flex', alignItems: 'center', background: '#f8f9fa', padding: '8px', borderRadius: '2px', marginLeft: '2px' }}>
                            <h6 style={{ marginBottom: '0px', fontSize: '12px' }}>{md_data_filter?.department_name}</h6>
                            {(!roles.includes('hod') && (roles.includes('admin') || roles.includes('db_head') || roles.includes('db_user'))) && <IoClose style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => { setmd_data_filter({ ...md_data_filter, department_id: '' }) }} />}
                          </div>
                        }






                        {md_data_filter?.from_date === '' || md_data_filter?.to_date === '' ?
                          <div>
                            <button type='button' className='btn  btn-light btn-sm m-1' onClick={() => setdatepickervisible(true)}   >
                              <GrFormAdd color="white" size={18} style={{ marginRight: '6px',marginTop:'-3px', color: 'white' }} />
                              <span>Date interval</span>
                            </button>

                          </div>
                          :
                          <div style={{ display: 'flex', alignItems: 'center', background: '#f8f9fa', padding: '8px', borderRadius: '2px', margin: '0px 5px' }}>
                            <h6 style={{ marginBottom: '0px', fontSize: '12px' }}>{md_data_filter?.from_date + ':' + md_data_filter?.to_date}</h6>
                            <IoClose style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => setmd_data_filter({ ...md_data_filter, from_date: '', to_date: '' })} />
                          </div>
                        }

                        {md_data_filter?.toDep === '' ?
                          <button type='button' className='btn  btn-light btn-sm m-1' onClick={() => setmodalstep({ ...modalstep, step5: true })} >
                            <GrFormAdd color="white" size={18} style={{ marginRight: '6px',marginTop:'-3px', color: 'white' }} />
                            <span>To Department</span>
                          </button>
                          :
                          <div style={{ display: 'flex', alignItems: 'center', background: '#f8f9fa', padding: '8px', borderRadius: '2px', marginLeft: '2px' }}>
                            <h6 style={{ marginBottom: '0px', fontSize: '12px' }}>{md_data_filter?.department_name1}</h6>
                            {(!roles.includes('hod') && (roles.includes('admin') || roles.includes('db_head') || roles.includes('db_user'))) && <IoClose style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => { setmd_data_filter({ ...md_data_filter, toDep: '' }) }} />}
                          </div>
                        }


                        <div style={{ marginRight: '5px' }} onClick={applyfilter}>
                          <PrimaryButtonFilter btn_name="Apply Filter" padding={'2px 8px'} />
                        </div>
                        <div style={{ marginRight: '5px' }} onClick={resetfilter1}>
                          <PrimaryButtonFilter btn_name="Reset Filter" padding={'2px 8px'} />
                        </div>

                      </div>
                    </div>
                  </div>

                </div>

                {assignleads.length > 0 ?
                  <>
                    <div className='mateial_table_header'>
                      {/* <Checkbox style={{width: '4%',marginTop:'5px'}}/> */}
                      <p style={{ minWidth: '12%' }}>Lead Name</p>
                      <p style={{ minWidth: '13%' }}>Company</p>
                      <p style={{ minWidth: '18%',marginLeft:'10px' }}>Email</p>
                      <p style={{ minWidth: '9%' }}>Phone</p>
                      <p style={{ minWidth: '14%', marginRight: '1%' }}>Assigned By</p>
                      <p style={{ minWidth: '14%' }}>Assigned To</p>
                      <p style={{ minWidth: '14%' }}>Remarks</p>
                      <p style={{ minWidth: '10%' }}>Actions</p>
                    </div>
                    {assignleads?.map((d) => (
                      <div key={d.id} className='mateial_table_body'>
                        {/* <Checkbox style={{width: '4%',marginTop:'5px'}}/> */}
                        <p style={{ minWidth: '12%',maxWidth:'12%',paddingRight:'1.5%', color: "#0594f2", cursor: 'pointer', fontWeight: '700',  wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis' }}
                          onClick={() => navigate(`/lead_detail/${d?.lead_id?._id || d?.lead_id?._id}`)}
                        >{<Texttruncate text={d?.lead_id?.lead_name} />}</p>
                        <p style={{ minWidth: '13%',maxWidth: '13%',marginLeft:'-8px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis' }}>{d?.lead_id?.contact_id?.company_name}</p>
                        <p style={{ minWidth: '18%',maxWidth: '18%',marginLeft:'10px',  wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis' }}>{d?.lead_id?.contact_id?.email?.slice(0, 25)}</p>
                        <p style={{ minWidth: '9%', maxWidth: '9%',marginLeft:'-8px',  wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',fontWeight:'800' }}>{d?.lead_id?.contact_id?.phone}</p>
                        <p style={{ minWidth: '24%',maxWidth: '14%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis' }}>{d?.assigned_by?.name}</p>
                        <p style={{ minWidth: '14%',maxWidth: '14%',marginLeft:'-10%',  wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis', marginRight: '1%' }}>{d?.user_id?.name}</p>
                        <p style={{ minWidth: '14%',maxWidth: '14%',marginLeft:'-0.5%',  wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginRight:'20px', }}>{d?.remarks}</p>
                        <p style={{ minWidth: '10%',maxWidth: '10%',marginLeft:'-5px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis' }} onClick={() => tracker(d?.lead_id?._id)}><MdTimeline size={20} style={{ color: 'black', marginLeft: '0px' }} /></p>
                      </div>
                    ))}
                    {pagination.total > 20 &&
                      <div style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
                        {search_lead.filter_status
                          ?
                          <Pagination current={page} size="small" total={pagination.total} onChange={(v) => getmdassignedleads(v)} showSizeChanger pageSizeOptions={[20]} pageSize={20} />
                          :
                          <Pagination current={page} size="small" total={pagination.total} onChange={(v) => getmdassignedleads(v)} showSizeChanger pageSizeOptions={[20]} pageSize={20} />
                        }
                      </div>}
                  </>
                  :
                  <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                    <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '200px', objectFit: 'contain' }} />
                    <h4 style={{ fontSize: '18px', fontWeight: '700', marginTop: '10px', textAlign: 'center' }}>No MD Assigned Leads found.</h4>
                  </div>
                }
              </TabPane>
            </Tabs>

          </TabPane>}
      </Tabs>

    </div>
  )
}

export default TeamCLD;
