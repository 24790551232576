import React from 'react'
import { Card} from 'antd';
import {IoMdCloseCircle} from 'react-icons/io'
import { deepOrange  } from '@mui/material/colors';
import { IconButton } from '@material-ui/core';
import Avatar from '@mui/material/Avatar';
import {BsBuilding} from 'react-icons/bs'
import { useNavigate } from 'react-router-dom';

function Notification() {

    const navigate = useNavigate();


    return (
        <div style={{width:'100%',minHeight:'100vh',height:'100%',background:'#f7f7f7'}}>
              <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',background:'#292961',padding:'2% 15%',position:'sticky',top:0,zIndex:10}}>
                <span>
                <h6 style={{fontSize:'18px',fontWeight:'500',marginBottom:'5px',color:'white'}}>Notification History</h6>
                <p style={{opacity:0.5,fontSize:'13px',marginBottom:'0px',color:'white'}}>Your notification history is listed as a individual items, starting with the most recent. </p>
                </span>
                <IconButton>
                <IoMdCloseCircle color="white" style={{cursor:'pointer'}} size={24} onClick={()=>navigate(-1)}/>
                </IconButton>
                </div>

            <div style={{position:'fixed',bottom:'0px',left:'10px',display:'flex',fontSize:'12px',width:'180px',alignItems:'center'}}>
            <BsBuilding size={44} style={{marginTop:'-15px'}} />
             <p style={{marginLeft:'10px'}}>Your <a style={{color:'#29a2ff'}} href="/">Notification is managed</a> by fcpl CRM</p>
            </div>    
            <div style={{width:'70%',margin:'auto',overflow:'scroll'}}>
              

                <Card title="Today's Notification -Tuesday 14th Dec 2021  " bordered={false} style={{ width: '100%',marginTop:'20px' }}>
                    <div style={{display:'flex',width:'100%',borderBottom:'0.5px solid #f2faff',marginBottom:'10px',paddingBottom:'10px'}}>
                        <Avatar sx={{ bgcolor: deepOrange[500],width:34,height:34,fontSize:14,marginTop:'2px' }} alt="Krishna Sharp" src="/" />
                        <div style={{marginLeft:'40px',width:'90%'}}> 
                            <block><p style={{fontSize:'14px',marginBottom:'0px',}}> From <strong>Krishna Prasad Pk</strong> 
                        <span style={{fontSize:'10px',marginBottom:'0px',right:'0px',width:'20%',marginLeft:'20px'}}>1 min ago,</span>
                            
                            </p></block>
                            <span style={{fontSize:'12px',minWidth:'100%',marginBottom:'0px',color:'grey',textAlign:'left'}}>Lorem s survived not only five centuries, but also the leap into electronic typesetting, remaining essentially survived not only five centuries, but also the leap into electronic typesetting, remaining essentially survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged......</span>
                        </div>
                        <div style={{marginLeft:'50px'}}>
                        
                        </div>
                    </div> 

                    <div style={{display:'flex',width:'100%',borderBottom:'0.5px solid #f2faff',marginBottom:'10px',paddingBottom:'10px'}}>
                        <Avatar sx={{ bgcolor: deepOrange[500],width:34,height:34,fontSize:14,marginTop:'2px' }} alt="Krishna Sharp" src="/" />
                        <div style={{marginLeft:'40px',width:'90%'}}> 
                            <block><p style={{fontSize:'14px',marginBottom:'0px',}}> From <strong>Krishna Prasad Pk</strong> 
                        <span style={{fontSize:'10px',marginBottom:'0px',right:'0px',width:'20%',marginLeft:'20px'}}>1 min ago,</span>
                            
                            </p></block>
                            <span style={{fontSize:'12px',minWidth:'100%',marginBottom:'0px',color:'grey',textAlign:'left'}}>Lorem s survived not only five centuries, but also the leap into electronic typesetting, remaining essentially survived not only five centuries, but also the leap into electronic typesetting, remaining essentially survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged......</span>
                        </div>
                        <div style={{marginLeft:'50px'}}>
                        
                        </div>
                    </div> 

                   
                </Card>

                <Card title="Today's Notification -Tuesday 14th Dec 2021  " bordered={false} style={{ width: '100%',marginTop:'20px' }}>
                    <div style={{display:'flex',width:'100%',borderBottom:'0.5px solid #f2faff',marginBottom:'10px',paddingBottom:'10px'}}>
                        <Avatar sx={{ bgcolor: deepOrange[500],width:34,height:34,fontSize:14,marginTop:'2px' }} alt="Krishna Sharp" src="/" />
                        <div style={{marginLeft:'40px',width:'90%'}}> 
                            <block><p style={{fontSize:'14px',marginBottom:'0px',}}> From <strong>Krishna Prasad Pk</strong> 
                        <span style={{fontSize:'10px',marginBottom:'0px',right:'0px',width:'20%',marginLeft:'20px'}}>1 min ago,</span>
                            
                            </p></block>
                            <span style={{fontSize:'12px',minWidth:'100%',marginBottom:'0px',color:'grey',textAlign:'left'}}>Lorem s survived not only five centuries, but also the leap into electronic typesetting, remaining essentially survived not only five centuries, but also the leap into electronic typesetting, remaining essentially survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged......</span>
                        </div>
                        <div style={{marginLeft:'50px'}}>
                        
                        </div>
                    </div> 

                    <div style={{display:'flex',width:'100%',borderBottom:'0.5px solid #f2faff',marginBottom:'10px',paddingBottom:'10px'}}>
                        <Avatar sx={{ bgcolor: deepOrange[500],width:34,height:34,fontSize:14,marginTop:'2px' }} alt="Krishna Sharp" src="/" />
                        <div style={{marginLeft:'40px',width:'90%'}}> 
                            <block><p style={{fontSize:'14px',marginBottom:'0px',}}> From <strong>Krishna Prasad Pk</strong> 
                        <span style={{fontSize:'10px',marginBottom:'0px',right:'0px',width:'20%',marginLeft:'20px'}}>1 min ago,</span>
                            
                            </p></block>
                            <span style={{fontSize:'12px',minWidth:'100%',marginBottom:'0px',color:'grey',textAlign:'left'}}>Lorem s survived not only five centuries, but also the leap into electronic typesetting, remaining essentially survived not only five centuries, but also the leap into electronic typesetting, remaining essentially survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged......</span>
                        </div>
                        <div style={{marginLeft:'50px'}}>
                        
                        </div>
                    </div> 

                   
                </Card>
                <Card title="Today's Notification -Tuesday 14th Dec 2021  " bordered={false} style={{ width: '100%',marginTop:'20px' }}>
                    <div style={{display:'flex',width:'100%',borderBottom:'0.5px solid #f2faff',marginBottom:'10px',paddingBottom:'10px'}}>
                        <Avatar sx={{ bgcolor: deepOrange[500],width:34,height:34,fontSize:14,marginTop:'2px' }} alt="Krishna Sharp" src="/" />
                        <div style={{marginLeft:'40px',width:'90%'}}> 
                            <block><p style={{fontSize:'14px',marginBottom:'0px',}}> From <strong>Krishna Prasad Pk</strong> 
                        <span style={{fontSize:'10px',marginBottom:'0px',right:'0px',width:'20%',marginLeft:'20px'}}>1 min ago,</span>
                            
                            </p></block>
                            <span style={{fontSize:'12px',minWidth:'100%',marginBottom:'0px',color:'grey',textAlign:'left'}}>Lorem s survived not only five centuries, but also the leap into electronic typesetting, remaining essentially survived not only five centuries, but also the leap into electronic typesetting, remaining essentially survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged......</span>
                        </div>
                        <div style={{marginLeft:'50px'}}>
                        
                        </div>
                    </div> 

                    <div style={{display:'flex',width:'100%',borderBottom:'0.5px solid #f2faff',marginBottom:'10px',paddingBottom:'10px'}}>
                        <Avatar sx={{ bgcolor: deepOrange[500],width:34,height:34,fontSize:14,marginTop:'2px' }} alt="Krishna Sharp" src="/" />
                        <div style={{marginLeft:'40px',width:'90%'}}> 
                            <block><p style={{fontSize:'14px',marginBottom:'0px',}}> From <strong>Krishna Prasad Pk</strong> 
                        <span style={{fontSize:'10px',marginBottom:'0px',right:'0px',width:'20%',marginLeft:'20px'}}>1 min ago,</span>
                            
                            </p></block>
                            <span style={{fontSize:'12px',minWidth:'100%',marginBottom:'0px',color:'grey',textAlign:'left'}}>Lorem s survived not only five centuries, but also the leap into electronic typesetting, remaining essentially survived not only five centuries, but also the leap into electronic typesetting, remaining essentially survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged......</span>
                        </div>
                        <div style={{marginLeft:'50px'}}>
                        
                        </div>
                    </div> 

                   
                </Card>
                <Card title="Today's Notification -Tuesday 14th Dec 2021  " bordered={false} style={{ width: '100%',marginTop:'20px' }}>
                    <div style={{display:'flex',width:'100%',borderBottom:'0.5px solid #f2faff',marginBottom:'10px',paddingBottom:'10px'}}>
                        <Avatar sx={{ bgcolor: deepOrange[500],width:34,height:34,fontSize:14,marginTop:'2px' }} alt="Krishna Sharp" src="/" />
                        <div style={{marginLeft:'40px',width:'90%'}}> 
                            <block><p style={{fontSize:'14px',marginBottom:'0px',}}> From <strong>Krishna Prasad Pk</strong> 
                        <span style={{fontSize:'10px',marginBottom:'0px',right:'0px',width:'20%',marginLeft:'20px'}}>1 min ago,</span>
                            
                            </p></block>
                            <span style={{fontSize:'12px',minWidth:'100%',marginBottom:'0px',color:'grey',textAlign:'left'}}>Lorem s survived not only five centuries, but also the leap into electronic typesetting, remaining essentially survived not only five centuries, but also the leap into electronic typesetting, remaining essentially survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged......</span>
                        </div>
                        <div style={{marginLeft:'50px'}}>
                        
                        </div>
                    </div> 

                    <div style={{display:'flex',width:'100%',borderBottom:'0.5px solid #f2faff',marginBottom:'10px',paddingBottom:'10px'}}>
                        <Avatar sx={{ bgcolor: deepOrange[500],width:34,height:34,fontSize:14,marginTop:'2px' }} alt="Krishna Sharp" src="/" />
                        <div style={{marginLeft:'40px',width:'90%'}}> 
                            <block><p style={{fontSize:'14px',marginBottom:'0px',}}> From <strong>Krishna Prasad Pk</strong> 
                        <span style={{fontSize:'10px',marginBottom:'0px',right:'0px',width:'20%',marginLeft:'20px'}}>1 min ago,</span>
                            
                            </p></block>
                            <span style={{fontSize:'12px',minWidth:'100%',marginBottom:'0px',color:'grey',textAlign:'left'}}>Lorem s survived not only five centuries, but also the leap into electronic typesetting, remaining essentially survived not only five centuries, but also the leap into electronic typesetting, remaining essentially survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged......</span>
                        </div>
                        <div style={{marginLeft:'50px'}}>
                        
                        </div>
                    </div> 

                   
                </Card>


            </div>
        </div>
    )
}

export default Notification
