import React, { useState, useEffect } from 'react'
import { Tabs } from 'antd';
import { Row, Col, Empty } from 'antd';
import TableView1 from '../../../components/bdcomponenttable/mobileComponents/listview_mobile/TableView1';
// import { authHeader } from '../../../helpers/usertoken/UserToken'
// import axios from 'axios'
import { IoFlask } from 'react-icons/io5';
import { data_not_found } from '../../../helpers/Constants';

const { TabPane } = Tabs;


function SubViewLeads({ setselected_lead_fun, selected_lead, setvisiblefun, roles, Assigneddatas, setactivetab, setactivetab1, setassigned_lead, setselected_assigned_lead, assigned_lead, setassignedlead, acceptassignedlead, search_lead, reminder, getreminderfun, leaddetailfunc, remarkfunc, tracker, pagination, leads, page, setpage, paginateddata, paginateddata1, paginateddata2 }) {

    // const [leads, setLeads] = useState([])
    // const [pagination, setpagination] = useState()
    // const [page, setpage] = useState(1)

    // useEffect(async () => {
    //     getleads()
    // }, [])

    // function getleads() {
    //     axios.get(`lead/get/${1}`, authHeader)
    //         .then((res) => {
    //             setLeads(res.data.datas)
    //             setpagination(res.data.pagination)
    //         }).catch((err) => {
    //         })
    // }


    return (
        <>
            {roles.find(r => r === 'admin') || roles.find(r => r === 'controller') || roles.find(r => r === 'hod') || roles.find(r => r === 'db_head') || roles.find(r => r === 'db_user') || roles.find(r => r === 'db_sub_user') || roles.find(r => r === 'manager') ?
                <Tabs defaultActiveKey="1" style={{ width: '94%', margin: 'auto' }} size="small" onChange={(v) => setactivetab(v)}>
                    <TabPane
                        tab={
                            <span>
                                My Leads
                            </span>
                        }
                        key="1"
                    >
                        <Row>
                            <Col span={24}>

                                <div style={{ position: 'fixed', right: '30px', width: '40px', bottom: '58px', backgroundColor: '#222f3e', padding: 8, borderRadius: 20 }}>
                                    <IoFlask size={24} color="#fff" onClick={() => setvisiblefun()} />
                                </div>

                                {leads.length > 0 ?
                                    <div style={{ height: '100%', width: '100%', backgroundColor: 'white', overflow: 'scroll' }}>
                                        <h6 style={{ fontSize: '13px', color: '#677078' }}> <strong style={{ color: 'black' }}>Total Leads ({pagination?.total})</strong></h6>
                                        <TableView1
                                            reminder={reminder}
                                            detailfun={v => leaddetailfunc(v)}
                                            getreminderfun={v => getreminderfun(v)}
                                            data={leads}
                                            type="My Leads"
                                            selected_lead={selected_lead}
                                            setselected_leadfun={(v) => setselected_lead_fun(v)}
                                        />


                                    </div>
                                    :
                                    <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                                        <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '130px', objectFit: 'contain' }} />
                                        <h4 style={{ fontSize: '18px', fontWeight: '700', marginTop: '10px', textAlign: 'center' }}>{search_lead.filter_status ? 'No data Found' : "No leads created yet."}</h4>
                                        <h6 style={{ color: '#747474', fontSize: '12px', maxWidth: '95%', textAlign: 'center', margin: 'auto' }}>{search_lead.filter_status ? 'No data found with provided search options ' : "Visit a contact page and press on a convert icon. Then, you can find the lead on this page."}</h6>
                                    </div>}



                                {pagination?.total > 20 &&
                                    <div style={{ width: '100%', display: 'grid', alignItems: 'center', position: 'fixed', bottom: '0px', paddingBottom: '10px', paddingTop: '20px', marginLeft: '-10px', background: 'white' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', width: '38%', justifyContent: 'space-between', alignSelf: 'center', justifySelf: 'center' }}>
                                            {page === 1 ?
                                                <h6 style={{ color: '#d6d6d6', fontWeight: '600' }}>{'< Prev'}</h6>
                                                :
                                                <h6 style={{ color: '#0073ba', fontWeight: '600' }} onClick={() => { paginateddata(page - 1); setpage(page - 1) }}>{'< Prev'}</h6 >
                                            }
                                            <h6 style={{ fontWeight: '800' }}> {page} </h6>
                                            {pagination.totalPages === page ?
                                                <h6 style={{ color: '#d6d6d6', fontWeight: '600' }}>{'Next >'}</h6>
                                                :
                                                <h6 style={{ color: '#0073ba', fontWeight: '600' }} onClick={() => { paginateddata(page + 1); setpage(page + 1) }}>{'Next >'}</h6>
                                            }
                                        </div>
                                    </div>
                                }

                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane
                        tab={
                            <span>
                                Assigned Leads
                            </span>
                        }
                        key="2"
                    >
                        <Tabs defaultActiveKey="1" style={{ width: '100%', margin: 'auto', marginTop: '-15px' }} onChange={(v) => setactivetab1(v)} size="small">
                            <TabPane
                                tab={
                                    <span>
                                        {/* <AppleOutlined /> */}
                                        Leads Assigned To Me
                                    </span>
                                }
                                key="1"
                            >

                                <Row>
                                    <Col span={24}>
                                        {Assigneddatas.length > 0 ?
                                            <div style={{ height: '100%', width: '100%', backgroundColor: 'white', overflow: 'scroll' }}>
                                                <h6 style={{ fontSize: '13px', color: '#b3b3b3' }}> <strong style={{ color: 'black' }}>Assigned Leads To Me ({Assigneddatas?.length})</strong></h6>
                                                <TableView1
                                                    data={Assigneddatas}
                                                    detailfun={v => leaddetailfunc(v)}
                                                    type="Leads Assigned To Me"
                                                    selected_lead={assigned_lead}
                                                    setassigned_lead={(v) => setassigned_lead(v)}
                                                    setselected_leadfun={(v) => setassignedlead(v)}
                                                    setselected_assigned_lead={(v) => setselected_assigned_lead(v)}
                                                    acceptassignedlead={(v, v1) => acceptassignedlead(v, v1)}
                                                    reminder={reminder}
                                                    getreminderfun={v => getreminderfun(v)}
                                                    remarkfunc={v => remarkfunc(v)}
                                                />

                                            </div>
                                            :
                                            <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                                                <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '130px', objectFit: 'contain' }} />
                                                <h4 style={{ fontSize: '18px', fontWeight: '700', marginTop: '10px', textAlign: 'center' }}>No Leads assigned .</h4>
                                                <h6 style={{ color: '#747474', fontSize: '12px', maxWidth: '100%', textAlign: 'center', margin: 'auto' }}>No leads have been assigned to you.</h6>
                                            </div>
                                        }

                                        {pagination?.total > 20 &&
                                            <div style={{ width: '100%', display: 'grid', alignItems: 'center', position: 'fixed', bottom: '0px', paddingBottom: '10px', paddingTop: '20px', marginLeft: '-10px', background: 'white' }}>
                                                <div style={{ display: 'flex', flexDirection: 'row', width: '38%', justifyContent: 'space-between', alignSelf: 'center', justifySelf: 'center' }}>
                                                    {page === 1 ?
                                                        <h6 style={{ color: '#d6d6d6', fontWeight: '600' }}>{'< Prev'}</h6>
                                                        :
                                                        <h6 style={{ color: '#0073ba', fontWeight: '600' }} onClick={() => { paginateddata2(page - 1) }}>{'< Prev'}</h6 >
                                                    }
                                                    <h6 style={{ fontWeight: '800' }}> {page} </h6>
                                                    {pagination.totalPages === page ?
                                                        <h6 style={{ color: '#d6d6d6', fontWeight: '600' }}>{'Next >'}</h6>
                                                        :
                                                        <h6 style={{ color: '#0073ba', fontWeight: '600' }} onClick={() => { paginateddata2(page + 1) }}>{'Next >'}</h6>
                                                    }
                                                </div>
                                            </div>}

                                        {/* <CardView2 data={leads} type="lead" func={func} editlead={(v)=>editlead(v)} gotodealcreatefunc={gotodealcreatefunc} /> */}
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane
                                tab={
                                    <span>
                                        {/* <AppleOutlined /> */}
                                        Leads Assigned By Me
                                    </span>
                                }
                                key="2"
                            >
                                <Row>
                                    <Col span={24}>
                                        {Assigneddatas.length > 0 ?
                                            <div style={{ height: '100%', width: '100%', backgroundColor: 'white', overflow: 'scroll' }}>
                                                <h6 style={{ fontSize: '13px', color: '#b3b3b3' }}><strong style={{ color: 'black' }}>Assigned Leads To Me ({Assigneddatas?.length})</strong></h6>

                                                <TableView1
                                                    data={Assigneddatas}
                                                    type="Leads Assigned By Me"
                                                    selected_lead={selected_lead}
                                                    reminder={reminder}
                                                    getreminderfun={v => getreminderfun(v)}
                                                    setselected_leadfun={(v) => setselected_lead_fun(v)}
                                                    detailfun={(v) => leaddetailfunc(v)}
                                                    tracker={v => tracker(v)}
                                                />
                                            </div>
                                            :
                                            <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                                                <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '130px', objectFit: 'contain' }} />
                                                <h4 style={{ fontSize: '18px', fontWeight: '700', marginTop: '10px', textAlign: 'center' }}>You have not assigned lead yet.</h4>
                                                <h6 style={{ color: '#747474', fontSize: '12px', maxWidth: '80%', textAlign: 'center', margin: 'auto' }}>Visit a lead page and press on a assign icon. Then, you can find the assigned lead on this page.</h6>
                                            </div>
                                        }



                                        {pagination?.total > 20 &&
                                            <div style={{ width: '100%', display: 'grid', alignItems: 'center', position: 'fixed', bottom: '0px', paddingBottom: '10px', paddingTop: '20px', marginLeft: '-10px', background: 'white' }}>
                                                <div style={{ display: 'flex', flexDirection: 'row', width: '38%', justifyContent: 'space-between', alignSelf: 'center', justifySelf: 'center' }}>
                                                    {page === 1 ?
                                                        <h6 style={{ color: '#d6d6d6', fontWeight: '600' }}>{'< Prev'}</h6>
                                                        :
                                                        <h6 style={{ color: '#0073ba', fontWeight: '600' }} onClick={() => { paginateddata1(page - 1) }}>{'< Prev'}</h6 >
                                                    }
                                                    <h6 style={{ fontWeight: '800' }}> {page} </h6>
                                                    {pagination.totalPages === page ?
                                                        <h6 style={{ color: '#d6d6d6', fontWeight: '600' }}>{'Next >'}</h6>
                                                        :
                                                        <h6 style={{ color: '#0073ba', fontWeight: '600' }} onClick={() => { paginateddata1(page + 1) }}>{'Next >'}</h6>
                                                    }
                                                </div>
                                            </div>}

                                        {/* <CardView2 data={leads} type="lead" func={func} editlead={(v)=>editlead(v)} gotodealcreatefunc={gotodealcreatefunc} /> */}
                                    </Col>
                                </Row>
                            </TabPane>
                        </Tabs>
                    </TabPane>
                </Tabs>
                :
                <Tabs defaultActiveKey="1" style={{ width: '94%', margin: 'auto' }}>
                    <TabPane
                        tab={
                            <span>
                                My Leads
                            </span>
                        }
                        key="1"
                    >
                        <Row>
                            <Col span={24}>
                                <div style={{ maxHeight: '95vh', minHeight: '85vh', width: '100%', backgroundColor: 'white', overflow: 'scroll' }}>
                                    <h6 style={{ fontSize: '13px', color: '#b3b3b3' }}> <strong style={{ color: 'black' }}>({pagination?.total})</strong> leads found</h6>
                                    <TableView1
                                        data={leads}
                                        type="My Leads"
                                        selected_lead={selected_lead}
                                        setselected_leadfun={(v) => setselected_lead_fun(v)}
                                    />

                                    <div style={{ position: 'fixed', right: '30px', bottom: '55px', backgroundColor: '#52a5ff', padding: 8, borderRadius: 20 }} onClick={() => setvisiblefun()}>
                                        <IoFlask size={25} onClick={() => setvisiblefun()} />
                                    </div>

                                </div>



                                {pagination?.total > 20 &&
                                    <div style={{ width: '100%', display: 'grid', alignItems: 'center', position: 'fixed', bottom: '0px', paddingBottom: '15px', paddingTop: '10px', marginLeft: '-14px', backgroundColor: 'white' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', width: '42%', justifyContent: 'space-between', alignSelf: 'center', justifySelf: 'center' }}>
                                            {page === 1 ?
                                                <h6 style={{ color: '#d6d6d6' }}>{'< Prev'}</h6>
                                                :
                                                <h6 style={{ color: '#26c5ff' }} onClick={() => setpage(page - 1)}>{'< Prev'}</h6 >
                                            }
                                            <h6> {page} </h6>
                                            {pagination?.totalPages === page ?
                                                <h6 style={{ color: '#d6d6d6' }}>{'Next >'}</h6>
                                                :
                                                <h6 style={{ color: '#26c5ff' }} onClick={() => setpage(page + 1)}>{'Next >'}</h6>
                                            }
                                        </div>
                                    </div>}

                                {/* <CardView2 data={leads} type="lead" func={func} editlead={(v)=>editlead(v)} gotodealcreatefunc={gotodealcreatefunc} /> */}
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane
                        tab={
                            <span>
                                {/* <AppleOutlined /> */}
                                Assigned Leads
                            </span>
                        }
                        key="2"
                    >
                        <Row>
                            <Col span={24}>
                                <Empty
                                    style={{ marginTop: '30px' }}
                                    image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                                    imageStyle={{
                                        height: 120,
                                    }}
                                    description={
                                        <span>
                                            No Leads assigned yet
                                        </span>
                                    }
                                >

                                </Empty>
                            </Col>

                        </Row>
                    </TabPane>
                </Tabs>
            }
        </>

    )
}

export default SubViewLeads
