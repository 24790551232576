import axios from 'axios';
import React,{useState,useEffect} from 'react';
import { BoldHeadText, BoldText1, SmallText } from '../../helpers/constants/ConstantsText';
import { authHeader } from '../../helpers/usertoken/UserToken';
import { Select,DatePicker,Tabs,Drawer } from 'antd';
import moment from 'moment';


import {RiContactsBookFill} from 'react-icons/ri';
import {MdLeaderboard, MdOutlineAssignment, MdOutlineFolderShared} from 'react-icons/md';
import {SiCashapp} from 'react-icons/si';
import {VscGraphLine} from 'react-icons/vsc';
import { green,blueGrey} from '@mui/material/colors';


// import {Bar} from "react-chartjs-2";
import PrimaryButton from '../../components/buttons/PrimaryButton';
import './Analytics.css'

import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  BarChart, Bar,
  Tooltip
} from "recharts";
import { useSelector } from 'react-redux';

import Avatar from '@mui/material/Avatar';

const { Option } = Select;

const { TabPane } = Tabs;

function Analytics() {

  const arr =  [ 
    blueGrey[500],green[100],blueGrey[600]
  ]


    const [analytics_data,setanalytics_data] = useState();
    const [team_analytics_data,setteam_analytics_data] = useState();
    const overall_analytics_data = null;


    const userId = useSelector(state => state.Auth.id)
    const department = useSelector(state => state.Auth.department)


    const [type,settype] = useState('Monthly analytics')
    const [current_graph_range,setcurrent_graph_range] = useState('')
    const [current_graph_range1,setcurrent_graph_range1] = useState('')


    const [date,setdate] = useState({start_date:'',end_date:''})
    const [date1,setdate1] = useState({start_date:'',end_date:''})

    const [current_tab,setcurrent_tab] = useState('1')

   
    const [drawer,setdrawer] = useState(false)

    const [fcplreport,setfcplreport] = useState([])
    const [departmentreport,setdepartmentreport] = useState([])
    const [keys,setkeys] = useState([])


    const [user_selected,setuser_selected] = useState()

    let stage = [
      'Weekly analytics',
      'Monthly analytics',
      'Yearly analytics',
      'Overall analytics',
      'Custom analytics'
      // 'Custom Date'
    ]



    const [graph1val,setgraph1val] = useState([])
    const [graph2val,setgraph2val] = useState([])
    const [graph3val,setgraph3val] = useState([])

    useEffect(()=>{
        getanalytics()
    },[]);

    async function getanalytics(v){
        await axios.get(`analytics/bd-user-analytics/${userId}?type=user`,authHeader)
        .then((res)=>{
            setdate({start_date:'',end_date:''})
            setanalytics_data(res.data.datas)
            let contacts = res?.data?.datas?.contacts_data
            let leads = res?.data?.datas?.leads_data
            let deals = res?.data?.datas?.deals_data
            current_state(v)
            setcurrent_graph_range('Monthly analytics')
            setgraph(contacts[0].month_report,'month_report')
            setgraph1(leads[0].month_report,deals[0].month_report,'month_report')
        }).catch((err)=>{
        })
    
    }

    async function getanalyticscustomrange(v){
      await axios.get(`analytics/bd-user-analytics/${userId}?type=user&start_date=${date.start_date}&end_date=${date.end_date}`,authHeader)
      .then((res)=>{
          let contacts = res?.data?.datas?.contacts_data
          let leads = res?.data?.datas?.leads_data
          let deals = res?.data?.datas?.deals_data
          settype('Custom analytics')
          setcurrent_graph_range('Custom analytics')
          setgraph(contacts[0].custom_report)
          setgraph1(leads[0].custom_report,deals[0].custom_report)
      }).catch((err)=>{
      })
    }

    async function getanalyticscustomrange1(v){
      await axios.get(`analytics/bd-user-analytics/${userId}?type=user&start_date=${date1.start_date}&end_date=${date1.end_date}`,authHeader)
      .then((res)=>{
          let contacts = res?.data?.datas?.contacts_data
          let leads = res?.data?.datas?.leads_data
          let deals = res?.data?.datas?.deals_data
          settype('Custom analytics')
          setcurrent_graph_range('Custom analytics')
          setgraph(contacts[0].custom_report)
          setgraph1(leads[0].custom_report,deals[0].custom_report)
      }).catch((err)=>{
      })
    }

    async function getTeamReport(){
      await axios.get(`analytics/crm_department_wise_report_employee/${department[0]?.id}`,authHeader)
      .then((res)=>{
        setdepartmentreport(res.data)
        setkeys([...Object.keys(res?.data?.data)])
      }).catch((err)=>{
        // console.log('err data kp',err)
      })
    }

    async function getTeamReportGraphically(){
      await axios.get(`analytics/bd-user-analytics/${department[0]?.id}?type=department`,authHeader)
      .then((res)=>{
        console.log('res data here',res.data)
          setdate({start_date:'',end_date:''})
          setteam_analytics_data(res.data.datas)
          let contacts = res?.data?.datas?.contacts_data
          let leads = res?.data?.datas?.leads_data
          let deals = res?.data?.datas?.deals_data
          // current_state(v)
          setgraph(contacts[0].month_report,'month_report')
          setgraph1(leads[0].month_report,deals[0].month_report,'month_report')
      }).catch((err)=>{
      })
    } 

    async function getTeamReportGraphicallyCustomized(){
      await axios.get(`analytics/bd-user-analytics/${department[0]?.id}?type=department`,authHeader)
      .then((res)=>{
        console.log('res data here',res.data)
          setdate({start_date:'',end_date:''})
          setteam_analytics_data(res.data.datas)
          let contacts = res?.data?.datas?.contacts_data
          let leads = res?.data?.datas?.leads_data
          let deals = res?.data?.datas?.deals_data
          // current_state(v)
          setgraph(contacts[0].month_report,'month_report')
          setgraph1(leads[0].month_report,deals[0].month_report,'month_report')
      }).catch((err)=>{
      })
    } 

    async function getfcplreport(){
      axios.get('analytics/crm_department_wise_report_employee')
      .then((res)=>{
        console.log('res data here crm_department_wise_report_employee',res.data)
        setfcplreport(res.data)
        setkeys([...Object.keys(res?.data?.data)])
      })
    }
    
    //set graph based on an array
    function getanalytics1(v){
     if(current_tab === '1'){
      let contacts = analytics_data?.contacts_data
      let leads = analytics_data?.leads_data
      let deals = analytics_data?.deals_data
      current_state(v)
      if(v === 'Monthly analytics'){
        // setcurrent_graph_range('Report For Last 30 Days')
        setgraph(contacts[0].month_report,'month_report')
        setgraph1(leads[0].month_report,deals[0].month_report,'month_report')
     }else if(v === 'Weekly analytics'){
      //  setcurrent_graph_range('Report For Last 7 Days')
       setgraph(contacts[0].week_report,'month_report')
       setgraph1(leads[0].week_report,deals[0].week_report,'month_report')
     }else if(v === 'Yearly analytics'){
      // setcurrent_graph_range('Report For Current Year')
      setgraph(contacts[0].year_report,'month_report')
      setgraph1(leads[0].year_report,deals[0].year_report,'month_report')
     }else if(v === 'Overall analytics'){
      // setcurrent_graph_range('Overall Report')
      setgraph(contacts[0].overall_report,'month_report')
      setgraph1(leads[0].overall_report,deals[0].overall_report,'month_report')
     }
     }else if(current_tab === '2'){
      // setgraph(contacts[0].month_report,'month_report')
      // setgraph1(leads[0].month_report,deals[0].month_report,'month_report')
      let contacts = team_analytics_data?.contacts_data
      let leads = team_analytics_data?.leads_data
      let deals = team_analytics_data?.deals_data
      current_state(v)
      if(v === 'Monthly analytics'){
        // setcurrent_graph_range('Report For Last 30 Days')
        setgraph(contacts[0].month_report,'month_report')
        setgraph1(leads[0].month_report,deals[0].month_report,'month_report')
     }else if(v === 'Weekly analytics'){
      //  setcurrent_graph_range('Report For Last 7 Days')
       setgraph(contacts[0].week_report,'month_report')
       setgraph1(leads[0].week_report,deals[0].week_report,'month_report')
     }else if(v === 'Yearly analytics'){
      // setcurrent_graph_range('Report For Current Year')
      setgraph(contacts[0].year_report,'month_report')
      setgraph1(leads[0].year_report,deals[0].year_report,'month_report')
     }else if(v === 'Overall analytics'){
      // setcurrent_graph_range('Overall Report')
      setgraph(contacts[0].overall_report,'month_report')
      setgraph1(leads[0].overall_report,deals[0].overall_report,'month_report')
     }
     }else if(current_tab === '3'){
      let contacts = overall_analytics_data?.contacts_data
      let leads = overall_analytics_data?.leads_data
      let deals = overall_analytics_data?.deals_data
      current_state(v)
      if(v === 'Monthly analytics'){
        // setcurrent_graph_range('Report For Last 30 Days')
        setgraph(contacts[0].month_report,'month_report')
        setgraph1(leads[0].month_report,deals[0].month_report,'month_report')
     }else if(v === 'Weekly analytics'){
      //  setcurrent_graph_range('Report For Last 7 Days')
       setgraph(contacts[0].week_report,'month_report')
       setgraph1(leads[0].week_report,deals[0].week_report,'month_report')
     }else if(v === 'Yearly analytics'){
      // setcurrent_graph_range('Report For Current Year')
      setgraph(contacts[0].year_report,'month_report')
      setgraph1(leads[0].year_report,deals[0].year_report,'month_report')
     }else if(v === 'Overall analytics'){
      // setcurrent_graph_range('Overall Report')
      setgraph(contacts[0].overall_report,'month_report')
      setgraph1(leads[0].overall_report,deals[0].overall_report,'month_report')
     }
     }
    }    

    function setanalyticsmanuallyforuser(v){
      let contacts = analytics_data?.contacts_data
      let leads = analytics_data?.leads_data
      let deals = analytics_data?.deals_data
      current_state(v)
      if(v === 'Monthly analytics'){
        // setcurrent_graph_range('Report For Last 30 Days')
        setgraph(contacts[0].month_report,'month_report')
        setgraph1(leads[0].month_report,deals[0].month_report,'month_report')
     }else if(v === 'Weekly analytics'){
      //  setcurrent_graph_range('Report For Last 7 Days')
       setgraph(contacts[0].week_report,'month_report')
       setgraph1(leads[0].week_report,deals[0].week_report,'month_report')
     }else if(v === 'Yearly analytics'){
      // setcurrent_graph_range('Report For Current Year')
      setgraph(contacts[0].year_report,'month_report')
      setgraph1(leads[0].year_report,deals[0].year_report,'month_report')
     }else if(v === 'Overall analytics'){
      // setcurrent_graph_range('Overall Report')
      setgraph(contacts[0].overall_report,'month_report')
      setgraph1(leads[0].overall_report,deals[0].overall_report,'month_report')
     }
    }

    async function setgraph(v){
       let data = []
       v?.forEach(d=>{
        let obj={
          name:d.date,
          count:d.count
        }
        data.push(obj)
       })
       setgraph1val(data)
    }

    async function setgraph1(v,v1){
      // console.log('leads na ',v,'deals na',v1)
      let data = []
      let data1 = []
       v?.forEach(val=>{
        let obj={
          name:val.date,
          count:val.count
        }
           data.push(obj)
       });

       v1?.forEach(val1=>{
        let obj={
          name:val1.date,
          count:val1.count
        }
        data1.push(obj)
       });
       setgraph2val(data)
       setgraph3val(data1)
    }

    async function current_state(t='Monthly analytics'){
       
      if(user_selected === ''){
       if(t === 'Monthly analytics'){
          setcurrent_graph_range('Report For Last 30 Days')
       }else if(t === 'Weekly analytics'){
          setcurrent_graph_range('Report For Last 7 Days')
       }else if(t === 'Yearly analytics'){
          setcurrent_graph_range('Report For Current Year')
       }else if(t === 'Overall analytics'){
          setcurrent_graph_range('Overall Report')
       }else{
          setcurrent_graph_range(t)
       }
      }else{
        setcurrent_graph_range1(t)
      }
    }

    function setactivetab(v){
      setdate({start_date:'',end_date:''})
      setcurrent_tab(v)
      if(v === '3'){
        getfcplreport()
      }
      if(v === '2'){
        getTeamReport()
        getTeamReportGraphically()
      }
      if(v === '1'){
        getanalytics()
      }
    }

    async function getsingleuserreport(v){
      setuser_selected(v)
      await axios.get(`analytics/bd-user-analytics/${v?.user_id}?type=user`,authHeader)
      .then((res)=>{
        console.log('res data analytics/bd-user-analytics',res.data)
          setanalytics_data(res.data.datas)
          let contacts = res?.data?.datas?.contacts_data
          let leads = res?.data?.datas?.leads_data
          let deals = res?.data?.datas?.deals_data
          current_state('Monthly analytics')
          setgraph(contacts[0].month_report,'month_report')
          setgraph1(leads[0].month_report,deals[0].month_report,'month_report')
      }).catch((err)=>{
      })
    }

    async function getsingleuserreportcustomrange(v){
      await axios.get(`analytics/bd-user-analytics/${v}`,authHeader)
      .then((res)=>{
          setanalytics_data(res.data.datas)
          let contacts = res?.data?.datas?.contacts_data
          let leads = res?.data?.datas?.leads_data
          let deals = res?.data?.datas?.deals_data
          current_state(v)
          setgraph(contacts[0].month_report,'month_report')
          setgraph1(leads[0].month_report,deals[0].month_report,'month_report')
      }).catch((err)=>{
      })
    }

    async function getTeamReportCustomize(){
      await axios.get(`analytics/crm_department_wise_report_employee/${department[0]?.id}?from_date=${date.from_date}&to_date=${date.to_date}`,authHeader)
      .then((res)=>{
        setdepartmentreport(res.data)
        setkeys([...Object.keys(res?.data?.data)])
      }).catch((err)=>{
        // console.log('err data kp',err)
      })
    }


    return (
        <div style={{ width: '100%', margin: 'auto', paddingTop: '60px',backgroundColor:'#fff' }} >
           <Drawer
            visible={drawer}
            anchor={"right"}
            open={drawer}
            width={'85%'}
            onClose={()=>setdrawer(!drawer)}
            
          >
            <div style={{width:window.innerWidth/1.2,padding:'10px',zIndex:100}}>
              <div style={{display:'flex',justifyContent:'space-between',margin:'20px 0px'}}>
                                  <div>
                                  <BoldHeadText val={`Analytics Dashboard ${user_selected?.user_name}`} textTransform={'Capitalize'} marginfun={'0px 0px 0px 0px'} />
                                  <SmallText val={'Check your crm account progress in this analytics section'} col="#bfbfbf" fontSize={'12px'} fontWeight={'800'} marginfun={'5px 0px'} /> 
                                  </div>

                      
                                <div style={{display:'flex',alignItems:'center',marginBottom:'10px'}}>
                                    <DatePicker placeholder='Start Date' value={date1?.start_date === '' ? null : moment(date1?.start_date)} onChange={(v,v1)=>{setdate1({...date1,start_date:v1})}} onOk={()=>{}} />
                                    <h6 style={{width:'10px'}}></h6>
                                    <DatePicker placeholder='End Date' value={date1?.end_date === '' ? null : moment(date1?.end_date)} onChange={(v,v1)=>{setdate1({...date1,end_date:v1})}} onOk={()=>{}} />

                                    <h6 style={{width:'10px'}}></h6>
                                    <PrimaryButton btn_name={'APPLY FILTER'} onClick={getanalyticscustomrange1} />
                                    <h6 style={{width:'10px'}}></h6>
                                    <div style={{display:'flex',alignItems:'center',marginRight:'20px',border:'1px solid #ededed',padding:'-2px 4px',borderRadius:'4px',zIndex:1000}}>
                                            <h6 style={{marginBottom:'0px',fontSize:'12px',color:'#d1d1d1',marginLeft:'10px',fontWeight:'800'}}>Show :</h6>

                                            <Select value={type} bordered={false} style={{borderColor:'white',fontWeight:'700',fontSize:'12px' }} onChange={(v)=>{settype(v);setanalyticsmanuallyforuser(v);setdate1({start_date:'',end_date:''})}} >
                                                {stage.map((s)=>(
                                                <Option value={s}>{s}</Option>
                                                ))}
                                            </Select>
                                    </div>
                                  </div>
              </div>
                          
                              <div className='section_1_analytics'>

                                  <div className='row' style={{width:'100%'}} >
                                      <div className="col-sm">
                                          <div style={{margin:'30px 0px',padding:'15px',borderRadius:'0px',width:'100%',border:'1px solid #f5f5f5'}}>
                                              <div style={{display:'flex',alignItems:'center'}}>
                                                  <RiContactsBookFill size={35} style={{background:'#eef4ff',border:'2px solid transparent',padding:'8px',borderRadius:'5px',color:'#5997ff'}} />
                                                  <BoldText1 val={'Contacts'} marginfun={'0px 0px 0px 10px'} />
                                              </div>
                                              <div style={{marginTop:'10px',display:'flex',justifyContent:'space-between'}}>
                                              <SmallText val={'TOTAL CONTACTS'} col="#4e4f4e" fontWeight={'800'} marginfun={'5px 0px'} /> 
                                              <SmallText marginfun={'5px 0px'} val={parseInt(analytics_data?.contacts_total).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                                                      fontWeight='800'
                                                      fontSize={'14px'} />
                                              </div>
                                          </div>
                                      </div>

                                      <div className="col-sm">
                                          <div style={{margin:'30px 0px',padding:'15px',borderRadius:'0px',width:'100%',border:'1px solid #f5f5f5'}}>
                                              <div style={{display:'flex',alignItems:'center'}}>
                                                  <MdLeaderboard size={35} style={{color:'#ffa8b2',background:'#fdf4f5',border:'2px solid transparent',padding:'8px',borderRadius:'5px'}} />
                                                  <BoldText1 val={'Leads'} marginfun={'0px 0px 0px 10px'} />
                                              </div>
                                              <div style={{marginTop:'10px',display:'flex',justifyContent:'space-between'}}>
                                              <SmallText val={'TOTAL LEADS'} col="#4e4f4e" fontWeight={'800'} marginfun={'5px 0px'} /> 
                                              <SmallText marginfun={'5px 0px'} val={(parseInt(analytics_data?.leads_total).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'))}
                                                      fontWeight='800'
                                                      fontSize={'14px'} />
                                              </div>

                                          </div>
                                      </div>

                                      <div className="col-sm">
                                          <div style={{margin:'30px 0px',background:'#fff',padding:'15px',borderRadius:'0px',width:'100%',border:'1px solid #f5f5f5'}}>
                                              <div style={{display:'flex',alignItems:'center'}}>
                                                  <SiCashapp size={35} style={{color:'#fff07d',background:'#fffce6',border:'2px solid transparent',padding:'8px',borderRadius:'5px'}} />
                                                  <BoldText1 val={'Deals'} marginfun={'0px 0px 0px 10px'} />
                                              </div>
                                              <div style={{marginTop:'10px',display:'flex',justifyContent:'space-between'}}>
                                              <SmallText val={'TOTAL DEALS'} col="#4e4f4e" fontWeight={'800'} marginfun={'5px 0px'} /> 
                                              <SmallText marginfun={'5px 0px'} val={(parseInt(analytics_data?.deals_total).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'))}
                                                      fontWeight='800'
                                                      fontSize={'14px'} />
                                              </div>

                                          </div>
                                      </div>

                                      <div className="col-sm">
                                          <div style={{margin:'30px 0px 0px 0px',padding:'4px 10px',paddingLeft:'10px',borderRadius:'0px',width:'100%',border:'1px solid #f5f5f5'}}>
                                              <div style={{margin:'2px 0px',display:'flex',justifyContent:'space-between'}}>
                                              <div style={{display:'flex',alignItems:'center'}}>
                                              <MdOutlineFolderShared size={35} style={{color:'#95e075',background:'#eaffe0',border:'2px solid transparent',padding:'5px',borderRadius:'5px'}} />
                                              <SmallText val={'SHARED CONTACTS'}fontSize={'12px'}   col="#4e4f4e" fontWeight={'800'} marginfun={'0px 10px'} /> 
                                              </div>
                                              <SmallText marginfun={'5px 0px'} val={(parseInt(analytics_data?.shared_contact_total).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'))}
                                                      fontWeight='800'
                                                      fontSize={'14px'} />
                                              </div>

                                          </div>
                                          <div style={{margin:'3px 0px',padding:'4px 10px',borderRadius:'0px',paddingLeft:'10px',width:'100%',border:'1px solid #f5f5f5'}}>
                                          <div style={{margin:'2px 0px',display:'flex',justifyContent:'space-between'}}>
                                              <div style={{display:'flex',alignItems:'center'}}>
                                              <MdOutlineAssignment size={35} style={{color:'#64c7cc',background:'#e8feff',border:'2px solid transparent',padding:'5px',borderRadius:'5px'}} />
                                              <SmallText val={'ASSIGNED LEADS'} fontSize={'12px'} col="#4e4f4e" fontWeight={'800'} marginfun={'0px 10px'} /> 
                                              </div>
                                              <SmallText marginfun={'5px 0px'} fontSize={'14px'} val={(parseInt(analytics_data?.assigned_lead_total).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'))}
                                                      fontWeight='800'
                                                      />
                                              </div>

                                          </div>
                                      </div>
                                  </div>  
                              </div>

                              <div className='section_2_analytics_outer' style={{border:'1px solid #f5f5f5',height:'380px',padding:'20px 0px',width:'98%'}}>  
                              <BoldText1 val={current_graph_range1 === 'Custom analytics' ? `Report From ${moment(date1.start_date).format('ll')} - ${moment(date1.end_date).format('ll')}` : current_graph_range1} marginfun={'0px 0px 20px 10px'} />

                              <div className='section_2_analytics'>
                                
                                  <div className='section_2-1' style={{border:'1px solid #f5f5f5',}}>
                                  <SmallText val={"CONTACT'S"} col="#000" fontWeight={'800'} marginfun={'10px 20px'} /> 

                                    <div style={{width:'100%'}}>
                                    <AreaChart
                                        width={window.innerWidth/2.16}
                                        height={250}
                                        data={graph1val}
                                        margin={{
                                          top: 10,
                                          right: 30,
                                          left: 0,
                                          bottom: 0
                                        }}
                                      >
                                        <XAxis dataKey="name" className="xaxies" style={{background:'red'}} />
                                        <YAxis  className="xaxies" />
                                        <Tooltip style={{background:'black'}} />
                                        <Area type="monotone" strokeWidth={'2px'} dataKey="count" stroke="#31537d" fill="#83a2c9" />
                                      </AreaChart>
                                    </div>
                                  </div>
                                  <div className='section_2-2' >
                                    <div style={{border:'1px solid #f5f5f5',}}>
                                        <SmallText val={"LEAD'S"} col="#000" fontWeight={'800'} marginfun={'10px 20px'} /> 

                                        <BarChart width={450} height={100} data={graph2val}>
                                        <XAxis dataKey="name" className="xaxies" style={{background:'red'}} />
                                              <YAxis  className="xaxies" />
                                            <Bar dataKey="count" fill="#ffc9c2" stroke='#ff8a7a' strokeWidth={'2px'} />
                                        </BarChart>
                                    </div>
                                    <div style={{border:'1px solid #f5f5f5',marginTop:'20px'}}>
                                        <SmallText val={"DEAL'S"} col="#000" fontWeight={'800'} marginfun={'10px 20px'} /> 

                                        <BarChart width={450} height={100} data={graph3val}>
                                        <XAxis dataKey="name" className="xaxies" style={{background:'red'}} />
                                              <YAxis  className="xaxies" />
                                            <Bar dataKey="count" fill="#fff7a3" stroke='#fff154' strokeWidth={'2px'} />
                                        </BarChart>
                                    </div>
                                  </div>
                              </div>
                              </div>
            </div>
          </Drawer>

            <div style={{width:'90%',margin:'auto'}}>


                 <h5 style={{fontWeight:'800'}}>Analytics</h5>

                 <Tabs
                    defaultActiveKey={current_tab}
                    size="small"
                    style={{ width: '100%', margin: '10px auto', marginBottom: '0px', position: 'sticky', top: '80px' }}
                    onChange={(v) => setactivetab(v)}>

                    <TabPane tab="My Report" key="1">  
                              <div style={{display:'flex',justifyContent:'space-between',height:'30px',margin:'20px 0px'}}>
                                <div>
                                <BoldHeadText val={'Analytics Dashboard'} marginfun={'0px 0px 0px 0px'} />
                                <SmallText val={'Check your crm account progress in this analytics section'} col="#bfbfbf" fontSize={'12px'} fontWeight={'800'} marginfun={'5px 0px'} /> 
                                </div>

                    
                              <div style={{display:'flex',alignItems:'center'}}>
                                  <DatePicker placeholder='Start Date' value={date?.start_date === '' ? null : moment(date?.start_date)} onChange={(v,v1)=>{setdate({...date,start_date:v1})}} onOk={()=>{}} />
                                  <h6 style={{width:'10px'}}></h6>
                                  <DatePicker placeholder='End Date' value={date?.end_date === '' ? null : moment(date?.end_date)} onChange={(v,v1)=>{setdate({...date,end_date:v1})}} onOk={()=>{}} />

                                  <h6 style={{width:'10px'}}></h6>
                                  <PrimaryButton btn_name={'APPLY FILTER'} onClick={getanalyticscustomrange} />
                                  <h6 style={{width:'10px'}}></h6>
                                  <div style={{display:'flex',alignItems:'center',marginRight:'20px',border:'1px solid #ededed',padding:'-2px 4px',borderRadius:'4px'}}>
                                          <h6 style={{marginBottom:'0px',fontSize:'12px',color:'#d1d1d1',marginLeft:'10px',fontWeight:'800'}}>Show :</h6>

                                          <Select value={type} bordered={false} style={{borderColor:'white',fontWeight:'700',fontSize:'12px' }} onChange={(v)=>{settype(v);getanalytics1(v);setdate({start_date:'',end_date:''})}} >
                                              {stage.map((s)=>(
                                              <Option value={s}>{s}</Option>
                                              ))}
                                          </Select>
                                  </div>
                                </div>
                              </div>
                          
                              <div className='section_1_analytics'>

                                  <div className='row' style={{width:'100%'}} >
                                      <div className="col-sm">
                                          <div style={{margin:'30px 0px',padding:'15px',borderRadius:'0px',width:'100%',border:'1px solid #f5f5f5'}}>
                                              <div style={{display:'flex',alignItems:'center'}}>
                                                  <RiContactsBookFill size={35} style={{background:'#eef4ff',border:'2px solid transparent',padding:'8px',borderRadius:'5px',color:'#5997ff'}} />
                                                  <BoldText1 val={'Contacts'} marginfun={'0px 0px 0px 10px'} />
                                              </div>
                                              <div style={{marginTop:'10px',display:'flex',justifyContent:'space-between'}}>
                                              <SmallText val={'TOTAL CONTACTS'} col="#4e4f4e" fontWeight={'800'} marginfun={'5px 0px'} /> 
                                              <SmallText marginfun={'5px 0px'} val={parseInt(analytics_data?.contacts_total).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                                                      fontWeight='800'
                                                      fontSize={'14px'} />
                                              </div>
                                          </div>
                                      </div>

                                      <div className="col-sm">
                                          <div style={{margin:'30px 0px',padding:'15px',borderRadius:'0px',width:'100%',border:'1px solid #f5f5f5'}}>
                                              <div style={{display:'flex',alignItems:'center'}}>
                                                  <MdLeaderboard size={35} style={{color:'#ffa8b2',background:'#fdf4f5',border:'2px solid transparent',padding:'8px',borderRadius:'5px'}} />
                                                  <BoldText1 val={'Leads'} marginfun={'0px 0px 0px 10px'} />
                                              </div>
                                              <div style={{marginTop:'10px',display:'flex',justifyContent:'space-between'}}>
                                              <SmallText val={'TOTAL LEADS'} col="#4e4f4e" fontWeight={'800'} marginfun={'5px 0px'} /> 
                                              <SmallText marginfun={'5px 0px'} val={(parseInt(analytics_data?.leads_total).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'))}
                                                      fontWeight='800'
                                                      fontSize={'14px'} />
                                              </div>

                                          </div>
                                      </div>

                                      <div className="col-sm">
                                          <div style={{margin:'30px 0px',background:'#fff',padding:'15px',borderRadius:'0px',width:'100%',border:'1px solid #f5f5f5'}}>
                                              <div style={{display:'flex',alignItems:'center'}}>
                                                  <SiCashapp size={35} style={{color:'#fff07d',background:'#fffce6',border:'2px solid transparent',padding:'8px',borderRadius:'5px'}} />
                                                  <BoldText1 val={'Deals'} marginfun={'0px 0px 0px 10px'} />
                                              </div>
                                              <div style={{marginTop:'10px',display:'flex',justifyContent:'space-between'}}>
                                              <SmallText val={'TOTAL DEALS'} col="#4e4f4e" fontWeight={'800'} marginfun={'5px 0px'} /> 
                                              <SmallText marginfun={'5px 0px'} val={(parseInt(analytics_data?.deals_total).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'))}
                                                      fontWeight='800'
                                                      fontSize={'14px'} />
                                              </div>

                                          </div>
                                      </div>

                                      <div className="col-sm">
                                          <div style={{margin:'30px 0px 0px 0px',padding:'4px 10px',paddingLeft:'10px',borderRadius:'0px',width:'100%',border:'1px solid #f5f5f5'}}>
                                              <div style={{margin:'2px 0px',display:'flex',justifyContent:'space-between'}}>
                                              <div style={{display:'flex',alignItems:'center'}}>
                                              <MdOutlineFolderShared size={35} style={{color:'#95e075',background:'#eaffe0',border:'2px solid transparent',padding:'5px',borderRadius:'5px'}} />
                                              <SmallText val={'SHARED CONTACTS'}fontSize={'12px'}   col="#4e4f4e" fontWeight={'800'} marginfun={'0px 10px'} /> 
                                              </div>
                                              <SmallText marginfun={'5px 0px'} val={(parseInt(analytics_data?.shared_contact_total).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'))}
                                                      fontWeight='800'
                                                      fontSize={'14px'} />
                                              </div>

                                          </div>
                                          <div style={{margin:'3px 0px',padding:'4px 10px',borderRadius:'0px',paddingLeft:'10px',width:'100%',border:'1px solid #f5f5f5'}}>
                                          <div style={{margin:'2px 0px',display:'flex',justifyContent:'space-between'}}>
                                              <div style={{display:'flex',alignItems:'center'}}>
                                              <MdOutlineAssignment size={35} style={{color:'#64c7cc',background:'#e8feff',border:'2px solid transparent',padding:'5px',borderRadius:'5px'}} />
                                              <SmallText val={'ASSIGNED LEADS'} fontSize={'12px'} col="#4e4f4e" fontWeight={'800'} marginfun={'0px 10px'} /> 
                                              </div>
                                              <SmallText marginfun={'5px 0px'} fontSize={'14px'} val={(parseInt(analytics_data?.assigned_lead_total).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'))}
                                                      fontWeight='800'
                                                      />
                                              </div>

                                          </div>
                                      </div>
                                  </div>  
                              </div>

                              <div className='section_2_analytics_outer' style={{border:'1px solid #f5f5f5',height:'380px',padding:'20px 0px',width:'98%'}}>  
                              <BoldText1 val={current_graph_range === 'Custom analytics' ? `Report From ${moment(date.start_date).format('ll')} - ${moment(date.end_date).format('ll')}` : current_graph_range} marginfun={'0px 0px 20px 10px'} />

                              <div className='section_2_analytics'>
                                
                                  <div className='section_2-1' style={{border:'1px solid #f5f5f5',}}>
                                  <SmallText val={"CONTACT'S"} col="#000" fontWeight={'800'} marginfun={'10px 20px'} /> 

                                    <div style={{width:'100%'}}>
                                    <AreaChart
                                        width={window.innerWidth/2}
                                        height={250}
                                        data={graph1val}
                                        margin={{
                                          top: 10,
                                          right: 30,
                                          left: 0,
                                          bottom: 0
                                        }}
                                      >
                                        {/* <CartesianGrid strokeDasharray="3 3" /> */}
                                        <XAxis dataKey="name" className="xaxies" style={{background:'red'}} />
                                        <YAxis  className="xaxies" />
                                        <Tooltip style={{background:'black'}} />
                                        <Area type="monotone" strokeWidth={'2px'} dataKey="count" stroke="#31537d" fill="#83a2c9" />
                                      </AreaChart>
                                    {/* <ReactApexCharts  options={options} series={[ser]}/> */}
                                    </div>
                                  </div>
                                  <div className='section_2-2' >
                                    <div style={{border:'1px solid #f5f5f5',}}>
                                        <SmallText val={"LEAD'S"} col="#000" fontWeight={'800'} marginfun={'10px 20px'} /> 

                                        <BarChart width={500} height={100} data={graph2val}>
                                        <XAxis dataKey="name" className="xaxies" style={{background:'red'}} />
                                              <YAxis  className="xaxies" />
                                            <Bar dataKey="count" fill="#ffc9c2" stroke='#ff8a7a' strokeWidth={'2px'} />
                                        </BarChart>
                                    </div>
                                    <div style={{border:'1px solid #f5f5f5',marginTop:'20px'}}>
                                        <SmallText val={"DEAL'S"} col="#000" fontWeight={'800'} marginfun={'10px 20px'} /> 

                                        <BarChart width={500} height={100} data={graph3val}>
                                        <XAxis dataKey="name" className="xaxies" style={{background:'red'}} />
                                              <YAxis  className="xaxies" />
                                            <Bar dataKey="count" fill="#fff7a3" stroke='#fff154' strokeWidth={'2px'} />
                                        </BarChart>
                                    </div>
                                    {/* <ReactApexCharts options={options1} series={series1} type='bar' /> */}
                                  </div>
                              </div>
                              </div>
                    </TabPane>

                    <TabPane tab="Team Report" key="2">  
                      <div style={{display:'flex',justifyContent:'space-between',height:'30px',margin:'20px 0px'}}>
                        <div>
                        <BoldHeadText val={'Team Analytics Dashboard'} marginfun={'0px 0px 0px 0px'} />
                        <SmallText val={'Check your team crm account progress in this analytics section'} col="#bfbfbf" fontSize={'12px'} fontWeight={'800'} marginfun={'5px 0px'} /> 
                        </div>

            
                      <div style={{display:'flex',alignItems:'center'}}>
                          <DatePicker placeholder='Start Date' value={date?.start_date === '' ? null : moment(date?.start_date)} onChange={(v,v1)=>{setdate({...date,start_date:v1})}} onOk={()=>{}} />
                          <h6 style={{width:'10px'}}></h6>
                          <DatePicker placeholder='End Date' value={date?.end_date === '' ? null : moment(date?.end_date)} onChange={(v,v1)=>{setdate({...date,end_date:v1})}} onOk={()=>{}} />

                          <h6 style={{width:'10px'}}></h6>
                          <PrimaryButton btn_name={'APPLY FILTER'} onClick={getTeamReportCustomize} />
                          <h6 style={{width:'10px'}}></h6>
                          <div style={{display:'flex',alignItems:'center',marginRight:'20px',border:'1px solid #ededed',padding:'-2px 4px',borderRadius:'4px'}}>
                                  <h6 style={{marginBottom:'0px',fontSize:'12px',color:'#d1d1d1',marginLeft:'10px',fontWeight:'800'}}>Show :</h6>

                                  <Select value={type} bordered={false} style={{borderColor:'white',fontWeight:'700',fontSize:'12px' }} onChange={(v)=>{settype(v);getanalytics1(v);setdate({start_date:'',end_date:''})}} >
                                      {stage.map((s)=>(
                                      <Option value={s}>{s}</Option>
                                      ))}
                                  </Select>
                          </div>
                        </div>
                      </div>
              
                      <div className='section_1_analytics'>

                        <div className='row' style={{width:'100%'}} >
                            <div className="col-sm">
                                <div style={{margin:'30px 0px',padding:'15px',borderRadius:'0px',width:'100%',border:'1px solid #f5f5f5'}}>
                                    <div style={{display:'flex',alignItems:'center'}}>
                                        <RiContactsBookFill size={35} style={{background:'#eef4ff',border:'2px solid transparent',padding:'8px',borderRadius:'5px',color:'#5997ff'}} />
                                        <BoldText1 val={'Contacts'} marginfun={'0px 0px 0px 10px'} />
                                    </div>
                                    <div style={{marginTop:'10px',display:'flex',justifyContent:'space-between'}}>
                                    <SmallText val={'TOTAL CONTACTS'} col="#4e4f4e" fontWeight={'800'} marginfun={'5px 0px'} /> 
                                    <SmallText marginfun={'5px 0px'} val={parseInt(departmentreport?.count?.contacts_count).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                                            fontWeight='800'
                                            fontSize={'14px'} />
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm">
                                <div style={{margin:'30px 0px',padding:'15px',borderRadius:'0px',width:'100%',border:'1px solid #f5f5f5'}}>
                                    <div style={{display:'flex',alignItems:'center'}}>
                                        <MdLeaderboard size={35} style={{color:'#ffa8b2',background:'#fdf4f5',border:'2px solid transparent',padding:'8px',borderRadius:'5px'}} />
                                        <BoldText1 val={'Leads'} marginfun={'0px 0px 0px 10px'} />
                                    </div>
                                    <div style={{marginTop:'10px',display:'flex',justifyContent:'space-between'}}>
                                    <SmallText val={'TOTAL LEADS'} col="#4e4f4e" fontWeight={'800'} marginfun={'5px 0px'} /> 
                                    <SmallText marginfun={'5px 0px'} val={(parseInt(departmentreport?.count?.leads_count).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'))}
                                            fontWeight='800'
                                            fontSize={'14px'} />
                                    </div>

                                </div>
                            </div>

                            <div className="col-sm">
                                <div style={{margin:'30px 0px',background:'#fff',padding:'15px',borderRadius:'0px',width:'100%',border:'1px solid #f5f5f5'}}>
                                    <div style={{display:'flex',alignItems:'center'}}>
                                        <SiCashapp size={35} style={{color:'#fff07d',background:'#fffce6',border:'2px solid transparent',padding:'8px',borderRadius:'5px'}} />
                                        <BoldText1 val={'Deals'} marginfun={'0px 0px 0px 10px'} />
                                    </div>
                                    <div style={{marginTop:'10px',display:'flex',justifyContent:'space-between'}}>
                                    <SmallText val={'TOTAL DEALS'} col="#4e4f4e" fontWeight={'800'} marginfun={'5px 0px'} /> 
                                    <SmallText marginfun={'5px 0px'} val={(parseInt(departmentreport?.count?.deals_count).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'))}
                                            fontWeight='800'
                                            fontSize={'14px'} />
                                    </div>

                                </div>
                            </div>

                            <div className="col-sm">
                                <div style={{margin:'30px 0px 0px 0px',padding:'4px 10px',paddingLeft:'10px',borderRadius:'0px',width:'100%',border:'1px solid #f5f5f5'}}>
                                    <div style={{margin:'2px 0px',display:'flex',justifyContent:'space-between'}}>
                                    <div style={{display:'flex',alignItems:'center'}}>
                                    <MdOutlineFolderShared size={35} style={{color:'#95e075',background:'#eaffe0',border:'2px solid transparent',padding:'5px',borderRadius:'5px'}} />
                                    <SmallText val={'SHARED CONTACTS'}fontSize={'12px'}   col="#4e4f4e" fontWeight={'800'} marginfun={'0px 10px'} /> 
                                    </div>
                                    <SmallText marginfun={'5px 0px'} val={(parseInt(departmentreport?.count?.shared_contacts).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'))}
                                            fontWeight='800'
                                            fontSize={'14px'} />
                                    </div>

                                </div>
                                <div style={{margin:'3px 0px',padding:'4px 10px',borderRadius:'0px',paddingLeft:'10px',width:'100%',border:'1px solid #f5f5f5'}}>
                                <div style={{margin:'2px 0px',display:'flex',justifyContent:'space-between'}}>
                                    <div style={{display:'flex',alignItems:'center'}}>
                                    <MdOutlineAssignment size={35} style={{color:'#64c7cc',background:'#e8feff',border:'2px solid transparent',padding:'5px',borderRadius:'5px'}} />
                                    <SmallText val={'ASSIGNED LEADS'} fontSize={'12px'} col="#4e4f4e" fontWeight={'800'} marginfun={'0px 10px'} /> 
                                    </div>
                                    <SmallText marginfun={'5px 0px'} fontSize={'14px'} val={(parseInt(departmentreport?.count?.shared_leads).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'))}
                                            fontWeight='800'
                                            />
                                    </div>

                                </div>
                            </div>
                        </div>  
                      </div>
                       
                      <div className='section_2_analytics_outer' style={{border:'1px solid #f5f5f5',height:'380px',padding:'20px 0px',width:'98%'}}>  
                              <BoldText1 val={current_graph_range === 'Custom analytics' ? `Report From ${moment(date.start_date).format('ll')} - ${moment(date.end_date).format('ll')}` : current_graph_range} marginfun={'0px 0px 20px 10px'} />

                              <div className='section_2_analytics'>
                                
                                  <div className='section_2-1' style={{border:'1px solid #f5f5f5',}}>
                                  <SmallText val={"CONTACT'S"} col="#000" fontWeight={'800'} marginfun={'10px 20px'} /> 

                                    <div style={{width:'100%'}}>
                                    <AreaChart
                                        width={window.innerWidth/2}
                                        height={250}
                                        data={graph1val}
                                        margin={{
                                          top: 10,
                                          right: 30,
                                          left: 0,
                                          bottom: 0
                                        }}
                                      >
                                        {/* <CartesianGrid strokeDasharray="3 3" /> */}
                                        <XAxis dataKey="name" className="xaxies" style={{background:'red'}} />
                                        <YAxis  className="xaxies" />
                                        <Tooltip style={{background:'black'}} />
                                        <Area type="monotone" strokeWidth={'2px'} dataKey="count" stroke="#31537d" fill="#83a2c9" />
                                      </AreaChart>
                                    {/* <ReactApexCharts  options={options} series={[ser]}/> */}
                                    </div>
                                  </div>
                                  <div className='section_2-2' >
                                    <div style={{border:'1px solid #f5f5f5',}}>
                                        <SmallText val={"LEAD'S"} col="#000" fontWeight={'800'} marginfun={'10px 20px'} /> 

                                        <BarChart width={500} height={100} data={graph2val}>
                                        <XAxis dataKey="name" className="xaxies" style={{background:'red'}} />
                                              <YAxis  className="xaxies" />
                                            <Bar dataKey="count" fill="#ffc9c2" stroke='#ff8a7a' strokeWidth={'2px'} />
                                        </BarChart>
                                    </div>
                                    <div style={{border:'1px solid #f5f5f5',marginTop:'20px'}}>
                                        <SmallText val={"DEAL'S"} col="#000" fontWeight={'800'} marginfun={'10px 20px'} /> 

                                        <BarChart width={500} height={100} data={graph3val}>
                                        <XAxis dataKey="name" className="xaxies" style={{background:'red'}} />
                                              <YAxis  className="xaxies" />
                                            <Bar dataKey="count" fill="#fff7a3" stroke='#fff154' strokeWidth={'2px'} />
                                        </BarChart>
                                    </div>
                                    {/* <ReactApexCharts options={options1} series={series1} type='bar' /> */}
                                  </div>
                              </div>
                      </div> 
                      {current_tab === '2' &&
                      <>
                      {
                      keys?.map(k=>(
                        <div style={{marginBottom:'25px',marginTop:'30px'}}>
                          <SmallText val={'Team Performance'} col="#000" fontWeight={'800'} fontSize={'16px'} marginfun={'10px 10px'} textTransform={'capitalize'} />
                        <div className='row' style={{margin:'0px 0px'}}>
                        {departmentreport?.data !== undefined && departmentreport?.data[k]?.map((f,i)=>(
                          <div className='col-sm-6 shadow-2-strong' style={{border:'1px solid #f5f5f5',borderRadius:'5px',width:'18%',margin:'4px',padding:'10px',position:'relative'}}>
                            
                            <div style={{display:'flex',alignItems:'center'}}>
                            <Avatar  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} variant="dot" sx={{ width: 24, height: 24,bgcolor:arr[Math.floor(Math.random() * arr?.length) + 1],color:'#000',fontWeight:'800' }} style={{fontSize:'11px',textTransform:'capitalize'}}>{f?.user_name.slice(0,2)}</Avatar>
                            <SmallText val={f?.user_name} col="#000" fontWeight={'600'} fontSize={'14px'} marginfun={'5px 10px'} textTransform={'capitalize'}/> 

                            <VscGraphLine size={24} style={{position:'absolute',right:'0px',top:'0px',backgroundColor:arr[Math.floor(Math.random() * arr?.length - 1) + 1],padding:'4px',borderRadius:'0px 2px 0px 10px',cursor:'pointer'}} onClick={()=>{setdrawer(!drawer);getsingleuserreport(f);setdate1({start_date:'',end_date:''})}} />
                            </div>
                            <div className='row' style={{borderTop:'1px solid #e2e2e2',marginTop:'20px'}}>
                                <div className='col-sm-4'>
                                  <SmallText val={'Contact'} col="#808080" fontWeight={'500'} fontSize={'13px'} marginfun={'5px 10px'} textTransform={'capitalize'}  alignfun={'center'} /> 
                                  <SmallText val={f?.contacts_count} col="#000" fontWeight={'800'} fontSize={'14px'} marginfun={'5px 10px'} textTransform={'capitalize'} alignfun={'center'}/> 
                                </div>

                                <div className='col-sm-4'>
                                  <SmallText val={'Lead'} col="#808080" fontWeight={'500'} fontSize={'13px'} marginfun={'5px 10px'} textTransform={'capitalize'}  alignfun={'center'} /> 
                                  <SmallText val={f?.leads_count} col="#000" fontWeight={'800'} fontSize={'14px'} marginfun={'5px 10px'} textTransform={'capitalize'} alignfun={'center'}/> 
                                </div>

                                <div className='col-sm-4'>
                                  <SmallText val={'Deal'} col="#808080" fontWeight={'500'} fontSize={'13px'} marginfun={'5px 10px'} textTransform={'capitalize'}  alignfun={'center'} /> 
                                  <SmallText val={f?.deals_count} col="#000" fontWeight={'800'} fontSize={'14px'} marginfun={'5px 10px'} textTransform={'capitalize'} alignfun={'center'}/> 
                                </div>
                            </div> 
                            
                          </div>
                      ))
                        }
                        </div>
                        
                        </div>  
                      ))
                    }
                    </>}
                    </TabPane>

                    <TabPane tab="FCPL Report" key="3">              
                          <div style={{display:'flex',justifyContent:'space-between',height:'30px',margin:'20px 0px'}}>
                            <div>
                            <BoldHeadText val={'FCPL Analytics Dashboard'} marginfun={'0px 0px 0px 0px'} />
                            <SmallText val={'Check your team crm account progress in this analytics section'} col="#bfbfbf" fontSize={'12px'} fontWeight={'800'} marginfun={'5px 0px'} /> 
                            </div>

                
                          <div style={{display:'flex',alignItems:'center'}}>
                              <DatePicker placeholder='Start Date' value={date?.start_date === '' ? null : moment(date?.start_date)} onChange={(v,v1)=>{setdate({...date,start_date:v1})}} onOk={()=>{}} />
                              <h6 style={{width:'10px'}}></h6>
                              <DatePicker placeholder='End Date' value={date?.end_date === '' ? null : moment(date?.end_date)} onChange={(v,v1)=>{setdate({...date,end_date:v1})}} onOk={()=>{}} />

                              <h6 style={{width:'10px'}}></h6>
                              <PrimaryButton btn_name={'APPLY FILTER'} onClick={getanalyticscustomrange} />
                              <h6 style={{width:'10px'}}></h6>
                              <div style={{display:'flex',alignItems:'center',marginRight:'20px',border:'1px solid #ededed',padding:'-2px 4px',borderRadius:'4px'}}>
                                      <h6 style={{marginBottom:'0px',fontSize:'12px',color:'#d1d1d1',marginLeft:'10px',fontWeight:'800'}}>Show :</h6>

                                      <Select value={type} bordered={false} style={{borderColor:'white',fontWeight:'700',fontSize:'12px' }} onChange={(v)=>{settype(v);getanalytics1(v);setdate({start_date:'',end_date:''})}} >
                                          {stage.map((s)=>(
                                          <Option value={s}>{s}</Option>
                                          ))}
                                      </Select>
                              </div>
                            </div>
                          </div>
                    
                          <div className='section_1_analytics'>

                              <div className='row' style={{width:'100%'}} >
                                  <div className="col-sm">
                                      <div style={{margin:'30px 0px',padding:'15px',borderRadius:'0px',width:'100%',border:'1px solid #f5f5f5'}}>
                                          <div style={{display:'flex',alignItems:'center'}}>
                                              <RiContactsBookFill size={35} style={{background:'#eef4ff',border:'2px solid transparent',padding:'8px',borderRadius:'5px',color:'#5997ff'}} />
                                              <BoldText1 val={'Contacts'} marginfun={'0px 0px 0px 10px'} />
                                          </div>
                                          <div style={{marginTop:'10px',display:'flex',justifyContent:'space-between'}}>
                                          <SmallText val={'TOTAL CONTACTS'} col="#4e4f4e" fontWeight={'800'} marginfun={'5px 0px'} /> 
                                          <SmallText marginfun={'5px 0px'} val={parseInt(fcplreport?.count?.contacts_count).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                                                  fontWeight='800'
                                                  fontSize={'14px'} />
                                          </div>
                                      </div>
                                  </div>

                                  <div className="col-sm">
                                      <div style={{margin:'30px 0px',padding:'15px',borderRadius:'0px',width:'100%',border:'1px solid #f5f5f5'}}>
                                          <div style={{display:'flex',alignItems:'center'}}>
                                              <MdLeaderboard size={35} style={{color:'#ffa8b2',background:'#fdf4f5',border:'2px solid transparent',padding:'8px',borderRadius:'5px'}} />
                                              <BoldText1 val={'Leads'} marginfun={'0px 0px 0px 10px'} />
                                          </div>
                                          <div style={{marginTop:'10px',display:'flex',justifyContent:'space-between'}}>
                                          <SmallText val={'TOTAL LEADS'} col="#4e4f4e" fontWeight={'800'} marginfun={'5px 0px'} /> 
                                          <SmallText marginfun={'5px 0px'} val={(parseInt(fcplreport?.count?.leads_count).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'))}
                                                  fontWeight='800'
                                                  fontSize={'14px'} />
                                          </div>

                                      </div>
                                  </div>

                                  <div className="col-sm">
                                      <div style={{margin:'30px 0px',background:'#fff',padding:'15px',borderRadius:'0px',width:'100%',border:'1px solid #f5f5f5'}}>
                                          <div style={{display:'flex',alignItems:'center'}}>
                                              <SiCashapp size={35} style={{color:'#fff07d',background:'#fffce6',border:'2px solid transparent',padding:'8px',borderRadius:'5px'}} />
                                              <BoldText1 val={'Deals'} marginfun={'0px 0px 0px 10px'} />
                                          </div>
                                          <div style={{marginTop:'10px',display:'flex',justifyContent:'space-between'}}>
                                          <SmallText val={'TOTAL DEALS'} col="#4e4f4e" fontWeight={'800'} marginfun={'5px 0px'} /> 
                                          <SmallText marginfun={'5px 0px'} val={(parseInt(fcplreport?.count?.deals_count).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'))}
                                                  fontWeight='800'
                                                  fontSize={'14px'} />
                                          </div>

                                      </div>
                                  </div>

                                  <div className="col-sm">
                                      <div style={{margin:'30px 0px 0px 0px',padding:'4px 10px',paddingLeft:'10px',borderRadius:'0px',width:'100%',border:'1px solid #f5f5f5'}}>
                                          <div style={{margin:'2px 0px',display:'flex',justifyContent:'space-between'}}>
                                          <div style={{display:'flex',alignItems:'center'}}>
                                          <MdOutlineFolderShared size={35} style={{color:'#95e075',background:'#eaffe0',border:'2px solid transparent',padding:'5px',borderRadius:'5px'}} />
                                          <SmallText val={'SHARED CONTACTS'}fontSize={'12px'}   col="#4e4f4e" fontWeight={'800'} marginfun={'0px 10px'} /> 
                                          </div>
                                          <SmallText marginfun={'5px 0px'} val={(parseInt(fcplreport?.count?.shared_contacts).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'))}
                                                  fontWeight='800'
                                                  fontSize={'14px'} />
                                          </div>

                                      </div>
                                      <div style={{margin:'3px 0px',padding:'4px 10px',borderRadius:'0px',paddingLeft:'10px',width:'100%',border:'1px solid #f5f5f5'}}>
                                      <div style={{margin:'2px 0px',display:'flex',justifyContent:'space-between'}}>
                                          <div style={{display:'flex',alignItems:'center'}}>
                                          <MdOutlineAssignment size={35} style={{color:'#64c7cc',background:'#e8feff',border:'2px solid transparent',padding:'5px',borderRadius:'5px'}} />
                                          <SmallText val={'ASSIGNED LEADS'} fontSize={'12px'} col="#4e4f4e" fontWeight={'800'} marginfun={'0px 10px'} /> 
                                          </div>
                                          <SmallText marginfun={'5px 0px'} fontSize={'14px'} val={(parseInt(fcplreport?.count?.shared_leads).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'))}
                                                  fontWeight='800'
                                                  />
                                          </div>

                                      </div>
                                  </div>
                              </div>  
                          </div>

                          <div className='section_2_analytics_outer' style={{border:'1px solid #f5f5f5',height:'380px',padding:'20px 0px',width:'98%'}}>  
                              <BoldText1 val={current_graph_range === 'Custom analytics' ? `Report From ${moment(date.start_date).format('ll')} - ${moment(date.end_date).format('ll')}` : current_graph_range} marginfun={'0px 0px 20px 10px'} />

                              <div className='section_2_analytics'>
                                
                                  <div className='section_2-1' style={{border:'1px solid #f5f5f5',}}>
                                  <SmallText val={"CONTACT'S"} col="#000" fontWeight={'800'} marginfun={'10px 20px'} /> 

                                    <div style={{width:'100%'}}>
                                    <AreaChart
                                        width={window.innerWidth/2}
                                        height={250}
                                        data={graph1val}
                                        margin={{
                                          top: 10,
                                          right: 30,
                                          left: 0,
                                          bottom: 0
                                        }}
                                      >
                                        {/* <CartesianGrid strokeDasharray="3 3" /> */}
                                        <XAxis dataKey="name" className="xaxies" style={{background:'red'}} />
                                        <YAxis  className="xaxies" />
                                        <Tooltip style={{background:'black'}} />
                                        <Area type="monotone" strokeWidth={'2px'} dataKey="count" stroke="#31537d" fill="#83a2c9" />
                                      </AreaChart>
                                    {/* <ReactApexCharts  options={options} series={[ser]}/> */}
                                    </div>
                                  </div>
                                  <div className='section_2-2' >
                                    <div style={{border:'1px solid #f5f5f5',}}>
                                        <SmallText val={"LEAD'S"} col="#000" fontWeight={'800'} marginfun={'10px 20px'} /> 

                                        <BarChart width={500} height={100} data={graph2val}>
                                        <XAxis dataKey="name" className="xaxies" style={{background:'red'}} />
                                              <YAxis  className="xaxies" />
                                            <Bar dataKey="count" fill="#ffc9c2" stroke='#ff8a7a' strokeWidth={'2px'} />
                                        </BarChart>
                                    </div>
                                    <div style={{border:'1px solid #f5f5f5',marginTop:'20px'}}>
                                        <SmallText val={"DEAL'S"} col="#000" fontWeight={'800'} marginfun={'10px 20px'} /> 

                                        <BarChart width={500} height={100} data={graph3val}>
                                        <XAxis dataKey="name" className="xaxies" style={{background:'red'}} />
                                              <YAxis  className="xaxies" />
                                            <Bar dataKey="count" fill="#fff7a3" stroke='#fff154' strokeWidth={'2px'} />
                                        </BarChart>
                                    </div>
                                    {/* <ReactApexCharts options={options1} series={series1} type='bar' /> */}
                                  </div>
                              </div>
                          </div>
                         {current_tab === '3' && 
                          <>
                          <p style={{height:'20px'}}></p>
                          {
                          keys?.map(k=>(
                            <div style={{marginBottom:'25px'}}>
                              <SmallText val={k} col="#000" fontWeight={'700'} fontSize={'15px'} marginfun={'10px 10px'} textTransform={'capitalize'} />
                              <p style={{borderTop:'1px solid #dbdbdb'}}></p>
                            <div className='row' style={{margin:'0px 0px'}}>
                              {console.log('fcplreport?.data[k]',fcplreport)}
                            { fcplreport?.data !== undefined &&  fcplreport?.data[k]?.map((f,i)=>(
                              <div className='col-sm-6 shadow-2-strong' style={{border:'1px solid #f5f5f5',borderRadius:'5px',width:'18%',margin:'4px',padding:'10px',position:'relative'}}>
                                
                                <div style={{display:'flex',alignItems:'center'}}>
                                <Avatar  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} variant="dot" sx={{ width: 24, height: 24,bgcolor:arr[Math.floor(Math.random() * arr?.length) + 1],color:'#000',fontWeight:'800' }} style={{fontSize:'11px',textTransform:'capitalize'}}>{f?.user_name.slice(0,2)}</Avatar>
                                <SmallText val={f?.user_name} col="#000" fontWeight={'600'} fontSize={'14px'} marginfun={'5px 10px'} textTransform={'capitalize'}/> 

                                <VscGraphLine size={24} style={{position:'absolute',right:'0px',top:'0px',backgroundColor:arr[Math.floor(Math.random() * arr?.length - 1) + 1],padding:'4px',borderRadius:'0px 2px 0px 10px',cursor:'pointer'}} onClick={()=>{setdrawer(!drawer);getsingleuserreport(f);setdate1({start_date:'',end_date:''})}} />
                                </div>
                                <div className='row' style={{borderTop:'1px solid #e2e2e2',marginTop:'20px'}}>
                                    <div className='col-sm-4'>
                                      <SmallText val={'Contact'} col="#808080" fontWeight={'500'} fontSize={'13px'} marginfun={'5px 10px'} textTransform={'capitalize'}  alignfun={'center'} /> 
                                      <SmallText val={f?.contacts_count} col="#000" fontWeight={'800'} fontSize={'14px'} marginfun={'5px 10px'} textTransform={'capitalize'} alignfun={'center'}/> 
                                    </div>

                                    <div className='col-sm-4'>
                                      <SmallText val={'Lead'} col="#808080" fontWeight={'500'} fontSize={'13px'} marginfun={'5px 10px'} textTransform={'capitalize'}  alignfun={'center'} /> 
                                      <SmallText val={f?.leads_count} col="#000" fontWeight={'800'} fontSize={'14px'} marginfun={'5px 10px'} textTransform={'capitalize'} alignfun={'center'}/> 
                                    </div>

                                    <div className='col-sm-4'>
                                      <SmallText val={'Deal'} col="#808080" fontWeight={'500'} fontSize={'13px'} marginfun={'5px 10px'} textTransform={'capitalize'}  alignfun={'center'} /> 
                                      <SmallText val={f?.deals_count} col="#000" fontWeight={'800'} fontSize={'14px'} marginfun={'5px 10px'} textTransform={'capitalize'} alignfun={'center'}/> 
                                    </div>
                                </div> 
                                
                              </div>
                          ))
                           }
                            </div>
                            
                            </div>  
                          ))
                        }
                        </>}
                    </TabPane>

            </Tabs>          
               
             



            </div>
        </div>
    )
}

export default Analytics