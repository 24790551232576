import { createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'


import { combineReducers } from "redux";
import AuthReducer  from './reducers/AuthReducer';
import MobileFooterReducer from './reducers/MobileFooterReducer';
import BdReducer from './reducers/BdReducer'
import ThemeReducer from './reducers/ThemeReducer';


import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";


// reducer methods


const rootReducers = combineReducers({
    Auth:AuthReducer,
    Footer:MobileFooterReducer,
    BD:BdReducer,
    Theme:ThemeReducer
})

const persistConfig = {
    key: "root",
    storage: storage,
    stateReconciler: autoMergeLevel2, // see "Merge Process" section for details.
};

const pReducer = persistReducer(persistConfig,rootReducers)

export const store = createStore(
    pReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

export const persistor = persistStore(store);

