import React, { useState, useEffect } from 'react'
import { Input } from 'antd';
import Main from '../../base/Main';
import InputField from '../../../components/inputcomponents/InputField';
import { Validators } from '../../../components/Validators/Validators'
import DropDown from '../../../components/inputcomponents/DropDown';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getLead } from '../functions/Apis'
import { useLocation, useParams } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast'
import { authHeader } from '../../../helpers/usertoken/UserToken';
import PrimaryButton, { SecondaryButton } from '../../../components/buttons/PrimaryButton';
import { useSelector } from 'react-redux'
import { Modal, Row, Col, Select, Checkbox } from 'antd'
import { name_reg_string, name_reg_validation } from '../../../helpers/constants/ConstantReg';

const { Option } = Select;
const { TextArea } = Input;

function CreateLead() {

    const roles = useSelector(state => state.Auth.roles)
    const department = useSelector(state => state.Auth.department)
    const navigate = useNavigate()


    const { lead_id } = useParams()



    const { state, pathname } = useLocation()

    const [departement_arr, setdepartement_arr] = useState([])
    const [department_id, setdepartment_id] = useState('')
    const [dataerr, setdataerr] = useState({ lead_name_err: {}, lead_title_err: {}, phoneerr: {}, email_err: {}, closing_date_err: {}, lead_owner_err: {}, company_name_err: {}, website_err: {}, company_email_err: {}, company_phone_err: {}, lead_source_err: {}, lead_status_err: {}, industry_err: {}, street_err: {}, city_err: {}, state_err: {}, pin_code_err: {}, country_err: {}, requirement_err: {}, description_err: {}, contact_name_err: {}, lead_Status_err: {}, lead_operators_err: {}, remark_err: {} })
    const [dataBool, setDataBool] = useState(false)
    const [data, setdata] = useState({
        lead_owner: state?.datas?.contact_owner?.name,
        lead_title: '',
        lead_name: '',
        lead_operators: '',
        phone: state?.datas?.phone,
        email: state?.datas?.email,
        company_name: state?.datas?.company_name,
        website: state?.datas?.website,
        company_email: state?.datas?.company_email,
        company_phone: state?.datas?.company_phone,
        lead_source: state?.datas?.contact_source?.source_name,
        lead_status: '',
        industry: state?.datas?.industry?.industry_name,
        street: state?.datas?.street,
        city: state?.datas?.city,
        state: state?.datas?.state,
        pin_code: state?.datas?.pin_code,
        country: state?.datas?.country,
        measurement: [],
        requirement: '',
        zone: '',
        description: '',
        remark: '',
        contact_name: state?.datas?.contact_name,
        contact_id: state?.datas?.id ? state?.datas?.id : state?.datas?._id
    })
    const [edit, setedit] = useState(false)
    const [lead_status, setlead_status] = useState([])
    const [visible, setvisible] = useState(false)
    const [transactionActive, setTransactionActive] = useState(false)


    let lead_status_basic_gallery_options = [{ id: 'Attempted to Contact', value: 'Attempted to Contact' }, { id: 'Contact in Future', value: 'Contact in Future' }, { id: 'Contacted', value: 'Contacted' }, { id: 'Junk lead', value: 'Junk lead' }, { id: 'Lost lead', value: 'Lost lead' }, { id: 'Not Contacted', value: 'Not Contacted' }, { id: 'Pre Qualified', value: 'Pre Qualified' }, { id: 'Not Qualified', value: 'Not Qualified' }, { id: 'Hold', value: 'Hold' }]
    let lead_status_basic_fms_options = [{ id: 'Meeting/Discussion', value: 'Meeting/Discussion' }, { id: 'Assessment', value: 'Assessment' }, { id: ' Proposal sent', value: ' Proposal sent' }, { id: 'Negotiation', value: 'Negotiation' }, { id: 'Final closure', value: 'Final closure' }, { id: 'Agreement', value: 'Agreement' }, { id: 'Hold', value: 'Hold' }]
    let lead_status_basic_project_options = [{ id: 'BD', value: 'BD' }, { id: 'Design', value: 'Design' }, { id: 'QS', value: 'QS' }, { id: 'MEP', value: 'MEP' }, { id: 'BOQ Review', value: 'BOQ Review' }, { id: 'Contact in Future', value: 'Contact in Future' }, { id: 'Closed', value: 'Closed' }, { id: 'Lost', value: 'Lost' }, { id: 'Junk Lead', value: 'Junk Lead' }, { id: 'Hold', value: 'Hold' }]
    let lead_status_basic_trans_options = [{ id: 'RFQ', value: 'RFQ' }, { id: 'Options sent/Provided', value: 'Options sent/Provided' }, { id: 'Inspection', value: 'Inspection' }, { id: 'Meeting/Discussion', value: 'Meeting/Discussion' }, { id: 'LOI', value: 'LOI' }, { id: 'Agreement/Registration', value: 'Agreement/Registration' }, { id: 'Collection', value: 'Collection' }, { id: 'Lead Lost', value: 'Lead Lost' }, { id: 'Negotiation', value: 'Negotiation' }, { id: 'Hold', value: 'Hold' }]
    let lead_status_basic_hr_options = [{ id: 'Follow up', value: 'Follow up' }, { id: 'Waiting for requirement', value: 'Waiting for requirement' }, { id: 'Closed', value: 'Closed' }, { id: 'lost', value: 'lost' }, { id: 'Hold', value: 'Hold' }]

    useEffect(() => {
        if ((roles.includes('db_head') || roles.includes('db_user') || roles.includes('db_sub_user') || roles.includes('admin')) && (department[0].department_name === 'MD Office' || department[0].department_name === 'Database Team') && !lead_id) {
            getDepartements()
            setvisible(true)
        } else {
            setdepartment_id(department[0]?.id)
        }

        if (!lead_id) {
            if (department[0].department_name === 'Transaction Team') {
                setlead_status(lead_status_basic_trans_options)
                setTransactionActive(true)
            } else if (department[0].department_name === 'Project Team') {
                setlead_status(lead_status_basic_project_options)
            } else if (department[0].department_name === 'Hr Team') {
                setlead_status(lead_status_basic_hr_options)
            } else if (department[0].department_name === 'Gallery Team') {
                setlead_status(lead_status_basic_gallery_options)
            } else if (department[0].department_name === 'FMS Team') {
                setlead_status(lead_status_basic_fms_options)
            }
        }
    }, [])

    useEffect(async () => {
        if (lead_id && pathname.split('/')[1]) {

            const departement_arr_created = []

            await axios.get('department/get', authHeader)
                .then((res) => {
                    res.data.datas.filter(d => d?.department_name !== 'Database Team' && d?.department_name !== 'Media Team' && d?.department_name !== 'MD Office').forEach(d => {
                        let data = {}
                        data['label'] = d.department_name
                        data['value'] = d.department_name
                        data['id'] = d.id
                        departement_arr_created.push(data)
                    })
                    //    console.log('departement_arr_created',departement_arr_created)
                    setdepartement_arr(departement_arr_created)

                })
            setedit(true)
            const leadDatas = await getLead(lead_id)



            let department = await departement_arr_created?.find(v => v.id === leadDatas[0].department_id)

            if (department?.value === 'Transaction Team') {
                setlead_status(lead_status_basic_trans_options)
                setTransactionActive(true)
            } else if (department?.value === 'Project Team') {
                setlead_status(lead_status_basic_project_options)
            } else if (department?.value === 'Hr Team') {
                setlead_status(lead_status_basic_hr_options)
            }

            setdata({
                lead_owner: leadDatas[0]?.contact_id?.contact_owner.name,
                lead_title: leadDatas[0].lead_title,
                lead_name: leadDatas[0].lead_name,
                phone: leadDatas[0]?.contact_id?.phone,
                email: leadDatas[0]?.contact_id?.email,
                company_name: leadDatas[0]?.contact_id?.company_name,
                website: leadDatas[0]?.contact_id?.website,
                company_email: leadDatas[0]?.contact_id?.company_email,
                company_phone: leadDatas[0]?.contact_id?.company_phone,
                lead_source: leadDatas[0]?.contact_id?.contact_source?.source_name,
                lead_status: leadDatas[0].lead_status,
                industry: leadDatas[0]?.contact_id?.industry?.industry_name,
                street: leadDatas[0]?.contact_id?.street,
                city: leadDatas[0]?.contact_id?.city,
                state: leadDatas[0]?.contact_id?.state,
                pin_code: leadDatas[0]?.contact_id?.pin_code,
                country: leadDatas[0]?.contact_id?.country,
                description: leadDatas[0].description,
                contact_name: leadDatas[0]?.contact_id?.contact_name,
                contact_id: leadDatas[0]?.contact_id?.id,
                measurement: leadDatas[0]?.measurement?.split(','),
                requirement: leadDatas[0]?.requirement,
                zone: leadDatas[0]?.zone,
                remark: leadDatas[0]?.remarks,
            })
        } else {
            setedit(false)
        }
    }, [])

    async function getDepartements() {
        await axios.get('department/get', authHeader)
            .then((res) => {
                const departement_arr_created = []
                res.data.datas.filter(d => d?.department_name !== 'Database Team' && d?.department_name !== 'Media Team' && d?.department_name !== 'MD Office').forEach(d => {
                    let data = {}
                    data['label'] = d.department_name
                    data['value'] = d.department_name
                    data['id'] = d.id
                    departement_arr_created.push(data)
                })
                //    console.log('departement_arr_created',departement_arr_created)
                setdepartement_arr(departement_arr_created)

            })
    }

    function handleChange({ key, value }) {
        if (key === 'lead_name') {
            setdataerr({ ...dataerr, lead_name_err: {} })
        } else if (key === 'lead_title') {
            setdataerr({ ...dataerr, lead_title_err: {} })
        } else if (key === 'lead_status') {
            setdataerr({ ...dataerr, lead_status_err: {} })
        } else if (key === 'remark') {
            setdataerr({ ...dataerr, remark_err: {} })
        }
        setdata({ ...data, [key]: value })
    }

    function handleChangefun(v, v1) {
        if (departement_arr.find(d => d.id === v)?.value === 'Transaction Team') {
            setTransactionActive(true)
            setlead_status(lead_status_basic_trans_options)
        } else if (departement_arr.find(d => d.id === v)?.value === 'Project Team') {
            setlead_status(lead_status_basic_project_options)
        } else if (departement_arr.find(d => d.id === v)?.value === 'Hr Team') {
            setlead_status(lead_status_basic_hr_options)
        } else if (departement_arr.find(d => d.id === v)?.value === 'FMS Team') {
            setlead_status(lead_status_basic_fms_options)
        } else if (departement_arr.find(d => d.id === v)?.value === 'Gallery Team') {
            setlead_status(lead_status_basic_gallery_options)
        }
        setdepartment_id(v)
        setvisible(false)
    }

    function addlead() {
        if (!data.lead_name && data.lead_name.length < 3) {
            setdataerr({ ...dataerr, lead_name_err: { error: true, message: 'Lead name should be atleast 3 characters' } })
        } else if (!name_reg_validation(data.lead_name)) {
            setdataerr({ ...dataerr, lead_name_err: { error: true, message: `Lead name should contains only ${name_reg_string}` } })
        } else if (!data.lead_title) {
            setdataerr({ ...dataerr, lead_title_err: { error: true, message: 'This field is required' } })
        } else if (!name_reg_validation(data.lead_title)) {
            setdataerr({ ...dataerr, lead_title_err: { error: true, message: `Lead title should contains only ${name_reg_string}` } })
        } else if (!data.lead_status) {
            setdataerr({ ...dataerr, lead_status_err: { error: true, message: 'This field is required' } })
        } else if (data.lead_status && data.lead_status === 'Lead Lost' && !data.remark) {
            setdataerr({ ...dataerr, remark_err: { error: true, message: 'This field is required' } })
        }
        else {
            let sendData = {
                contact_id: data.contact_id,
                lead_name: data.lead_name,
                lead_title: data.lead_title,
                lead_operators: data.lead_operators,
                lead_status: data.lead_status,
                department_id: department_id,
                requirement: data.requirement,
                zone: data.zone,
                description: data.description
            }
            if (department[0]?.department_name === 'Transaction Team' || departement_arr.find(d => d.id === department_id)?.value === 'Transaction Team') {
                sendData['measurement'] = data.measurement.join(',')
                sendData['requirement'] = data.requirement
                sendData['zone'] = data.zone
            }

            if (data.lead_status === 'Lead Lost') {
                sendData['remarks'] = data.remark
            }

            axios.post('lead/create', sendData, authHeader).then((res) => {
                toast.success('Added Successfully')
                setdata({ lead_title: '', lead_name: '', lead_status: '', description: '' })
                setTimeout(() => {
                    navigate('/view_leads')
                }, 1000)
            }).catch((err) => {
                if (err.response.status === 422) {
                    toast.error(err?.response?.data?.errors?.lead_name)
                }
                // console.log('err here',err)
            })
        }

    }

    function updateLead() {
        if (!data.lead_name && data.lead_name.length < 3) {
            setdataerr({ ...dataerr, lead_name_err: { error: true, message: 'Lead name should be atleast 3 characters' } })
        } else if (!name_reg_validation(data.lead_name)) {
            setdataerr({ ...dataerr, lead_name_err: { error: true, message: `Lead name should contains only ${name_reg_string}` } })
        } else if (!data.lead_title) {
            setdataerr({ ...dataerr, lead_title_err: { error: true, message: 'This field is required' } })
        } else if (!name_reg_validation(data.lead_title)) {
            setdataerr({ ...dataerr, lead_title_err: { error: true, message: `Lead title should contains only ${name_reg_string}` } })
        } else if (!data.lead_status) {
            setdataerr({ ...dataerr, lead_status_err: { error: true, message: 'This field is required' } })
        } else if (data.lead_status && data.lead_status === 'Lead Lost' && !data.remark) {
            setdataerr({ ...dataerr, remark_err: { error: true, message: 'This field is required' } })
        }
        else {
            if (data.lead_status === 'Lead Lost' && !data.remark) {
                setdataerr({ ...dataerr, remark_err: { error: true, message: 'This field is required' } })
            } else {
                let sendData = {
                    contact_id: data.contact_id,
                    lead_name: data.lead_name,
                    lead_title: data.lead_title,
                    lead_operators: data.lead_operators,
                    lead_status: data.lead_status,
                    description: data.description,
                }
                if (department[0]?.department_name === 'Transaction Team' || departement_arr.find(d => d.id === department_id)?.value === 'Transaction Team') {
                    sendData['measurement'] = data.measurement.join(',')
                    sendData['requirement'] = data.requirement
                    sendData['zone'] = data.zone
                }
                if (data.lead_status === 'Lead Lost') {
                    sendData['remarks'] = data.remark
                }
                axios.put(`lead/update/${lead_id}`, sendData, authHeader).then((res) => {
                    setDataBool(true)
                    toast.success('Updated Successfully')
                    setTimeout(() => {
                        setDataBool(false)
                        navigate(-1)
                    }, 1000)
                }).catch((err) => {
                    toast.error('Opps Somenthing went wrong!')
                })
            }
        }

    }

    function selectedvalue(v) {
        if (v?.id) {
            setdataerr({ ...dataerr, [v.nameerr]: {} })
            setdata({ ...data, [v.name]: v.id })
        }
    }

    function goback() {
        setdata({})
        setdataerr({})
        navigate(-1)
    }

    function setmeasumentfun(v) {
        let temp_arr = [...data.measurement]
        // temp_arr.push('seater')

        // console.log('temp_arr',temp_arr)
        // console.log('temp_arr.includes(v)',temp_arr.includes(v))
        // console.log('temp_arr.findIndex(v)',temp_arr.findIndex(e=>e===v))
        // console.log('temp_arr.splice(temp_arr.findIndex(v),1)',temp_arr.splice(temp_arr.findIndex(e=>e===v),1))
        // console.log('temp_arr',temp_arr)

        if (temp_arr?.includes(v)) {
            temp_arr = temp_arr.splice(temp_arr.splice(temp_arr.findIndex(e => e === v)), 1)
            setdata({ ...data, measurement: temp_arr })
        } else {
            temp_arr.push(v)
            setdata({ ...data, measurement: temp_arr })
        }

    }


    console.log('data in leads', data)

    return (
        <Main>
            <Toaster v-if={dataBool} />
            <Modal visible={visible} footer={false} onCancel={() => { setvisible(false); navigate(-1) }}>
                <Row>
                    <Col span={24}>
                        <div style={{ width: '96%', margin: ' 10px auto', }}>
                            <h6 style={{ fontSize: '12px', fontWeight: '600' }}>Select Department</h6>

                            <Select
                                showSearch
                                placeholder="Select Department"
                                optionFilterProp="children"
                                onChange={(v) => handleChangefun(v, 'department_id')}
                                size='medium'
                                filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                style={{ width: '100%' }}
                            >
                                {
                                    departement_arr.map((d) => (
                                        <Option key={d.id} value={d.id}><div>{d.value}</div></Option>
                                    ))
                                }
                            </Select>
                        </div>
                    </Col>
                </Row>
            </Modal>

            <div style={{ width: '90%', margin: 'auto', marginBottom: '70px' }}>
                <div className="mt-3" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', position: 'sticky', top: '40px', marginTop: '40px', height: '80px', zIndex: 1, background: 'white' }}>
                    <div>
                        <h5 style={{ marginBottom: '6px' }}>{!edit ? 'Create Lead' : 'Edit Lead'}</h5>
                        <p style={{ fontSize: '14px', fontWeight: '400' }}>{!edit ? "Use the below form to create a New Lead" : "Use the below form to edit a existing Lead"}</p>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'flex-end' }}>

                        <div onClick={goback}>
                            <SecondaryButton btn_name="Cancel" />
                        </div>

                        <div onClick={(edit) ? updateLead : addlead}>
                            <PrimaryButton btn_name={edit ? "Update" : "Save"} />
                        </div>

                    </div>
                </div>

                <div className="row mt-4">
                    <h6>Lead Information</h6>
                    <div className="col-md-6">

                        <div style={{ margin: '10px 0px', width: '70%' }}>
                            <p style={{ marginBottom: '8px', fontSize: '14px' }}>Lead Name</p>
                            <InputField
                                type="text"
                                priority="high"
                                onChange={handleChange}
                                name={'lead_name'}
                                error_obj={dataerr.lead_name_err}
                                value={data.lead_name}
                                validators={[{ check: Validators.required, message: 'This field is required' }, { check: Validators.minimum_length_required, message: 'Lead name should be atleast 3 characters' }]}

                            />

                        </div>
                    </div>


                    <div className="col-md-6">
                        <div style={{ margin: '10px 0px', width: '70%' }}>
                            {/* {console.log('kp test na ',departement_arr,department_id,department_id !== '' && departement_arr.length !== 0 &&  departement_arr?.find((d=>d?.id === department_id)?.value === 'Project Team'))} */}
                            <p style={{ marginBottom: '8px', fontSize: '14px' }}>{departement_arr?.find(d => d?.id === department_id)?.value === 'Project Team' ? 'Project Scope' : 'Title'}</p>
                            <InputField
                                type="text"
                                priority="high"
                                onChange={handleChange}
                                name={'lead_title'}
                                error_obj={dataerr.lead_title_err}
                                value={data.lead_title}
                                validators={[{ check: Validators.required, message: 'This field is required' }, { check: Validators.minimum_length_required, message: 'Lead title should be atleast 3 characters' }]}

                            />
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div style={{ margin: '10px 0px', width: '70%' }}>
                            <p style={{ marginBottom: '8px', fontSize: '14px' }}>Lead Status </p>
                            <DropDown
                                onChange={(v) => selectedvalue(v)}
                                priority={'high'}
                                name='lead_status'
                                nameerr='lead_status_err'
                                value={data.lead_status}
                                error_obj={dataerr.lead_status_err}
                                options={lead_status}
                            />
                        </div>
                    </div>
                    {
                        transactionActive &&
                        <>
                            <div className="col-md-6">
                                <div style={{ margin: '10px 0px', width: '70%' }}>
                                    <p style={{ marginBottom: '8px', fontSize: '14px' }}>Measurement </p>

                                    <Row>
                                        <Col span={8}>
                                            <Checkbox value="sqft" checked={data?.measurement?.includes('sqft')} onChange={() => setmeasumentfun('sqft')} >Sqft</Checkbox>
                                        </Col>
                                        <Col span={8}>
                                            <Checkbox value="seater" checked={data?.measurement?.includes('seater')} onChange={() => setmeasumentfun('seater')} >Seater</Checkbox>
                                        </Col>
                                    </Row>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div style={{ margin: '10px 0px', width: '70%' }}>
                                    <p style={{ marginBottom: '8px', fontSize: '14px' }}>Lead Operators</p>
                                    <InputField
                                        type="text"
                                        onChange={handleChange}
                                        name={'lead_operators'}
                                        error_obj={dataerr.lead_operators_err}
                                        s value={data.lead_operators}
                                    />
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div style={{ margin: '10px 0px', width: '70%' }}>
                                    <p style={{ marginBottom: '8px', fontSize: '14px' }}>Requirement</p>
                                    <InputField
                                        type="text"
                                        onChange={handleChange}
                                        name={'requirement'}
                                        error_obj={dataerr.requirement_err}
                                        value={data.requirement}
                                    />
                                </div>
                            </div>



                            <div className="col-md-6">
                                <div style={{ margin: '10px 0px', width: '70%' }}>
                                    <p style={{ marginBottom: '8px', fontSize: '14px' }}>Zone</p>
                                    <Select
                                        showSearch
                                        value={data.zone}
                                        placeholder="Select Zone"
                                        optionFilterProp="children"
                                        onChange={(v) => handleChange({ key: 'zone', value: v })}
                                        size='medium'
                                        filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        style={{ width: '100%' }}
                                    >
                                        <Option value="North"><div>North</div></Option>
                                        <Option value="South"><div>South</div></Option>
                                        <Option value="West"><div>West</div></Option>
                                        <Option value="East"><div>East</div></Option>
                                        <Option value="Central"><div>Central</div></Option>
                                    </Select>
                                </div>
                            </div>

                        </>
                    }


                    {data.lead_status === 'Lead Lost' &&
                        <div className="col-md-6" >
                            <div style={{ margin: '10px 0px', width: '70%' }}>
                                <p style={{ marginBottom: '8px', fontSize: '14px' }}>Reason for Lead Lost</p>
                                <InputField
                                    type="text"
                                    priority="high"
                                    onChange={handleChange}
                                    name={'remark'}
                                    error_obj={dataerr.remark_err}
                                    value={data.remark}
                                    validators={[{ check: Validators.required, message: 'This field is required' }, { check: Validators.minimum_length_required, message: 'Remark should be atleast 3 characters' }]}
                                />
                            </div>
                        </div>}

                    <div className="col-md-6">
                        <p style={{ marginBottom: '8px', fontSize: '14px' }}>Description</p>
                        <TextArea style={{ height: 20, width: '70.1%', marginLeft: '0%' }} onChange={e => setdata({ ...data, description: e.target.value })} name={'description'} value={data.description} />
                    </div>




                    <h6 style={{ marginTop: '20px' }}>Information from selected Contact </h6>


                    <div className="col-md-6">


                        <div style={{ margin: '10px 0px', width: '70%' }}>
                            <p style={{ marginBottom: '8px', fontSize: '14px' }}>Contact Name</p>
                            <InputField
                                name={'contact_name'}
                                value={data.contact_name}
                            />
                        </div>

                        <div style={{ margin: '10px 0px', width: '70%' }}>
                            <p style={{ marginBottom: '8px', fontSize: '14px' }}>Phone</p>
                            <InputField
                                name={'phone'}
                                value={data.phone}

                            />
                        </div>
                        <div style={{ margin: '10px 0px', width: '70%' }}>
                            <p style={{ marginBottom: '8px', fontSize: '14px' }}>Email</p>
                            <InputField

                                name={'email'}
                                value={data.email}

                            />
                        </div>

                        <div style={{ margin: '10px 0px', width: '70%' }}>
                            <p style={{ marginBottom: '8px', fontSize: '14px' }}>Lead Owner</p>
                            <InputField

                                name={'lead_owner'}
                                value={data.lead_owner}

                            />
                        </div>

                        <div style={{ margin: '10px 0px', width: '70%' }}>
                            <p style={{ marginBottom: '8px', fontSize: '14px' }}>Lead Source </p>
                            <InputField
                                name={'lead_source'}
                                value={data.lead_source}
                            />
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div style={{ margin: '10px 0px', width: '70%' }}>
                            <p style={{ marginBottom: '8px', fontSize: '14px' }}>Company</p>
                            <InputField

                                name={'company_name'}
                                value={data.company_name}

                            />
                        </div>

                        <div style={{ margin: '10px 0px', width: '70%' }}>
                            <p style={{ marginBottom: '8px', fontSize: '14px' }}>Website</p>
                            <InputField
                                name={'website'}
                                value={data.website}
                            />
                        </div>

                        <div style={{ margin: '10px 0px', width: '70%' }}>
                            <p style={{ marginBottom: '8px', fontSize: '14px' }}>Company Email</p>
                            <InputField
                                name={'company_email'}
                                value={data.company_email}
                            />
                        </div>

                        <div style={{ margin: '10px 0px', width: '70%' }}>
                            <p style={{ marginBottom: '8px', fontSize: '14px' }}>Company Phone</p>
                            <InputField
                                name={'company_phone'}
                                value={data.company_phone}
                            />
                        </div>



                        <div style={{ margin: '10px 0px', width: '70%' }}>
                            <p style={{ marginBottom: '8px', fontSize: '14px' }}>Industry</p>
                            <InputField
                                name={'industry'}
                                value={data.industry}
                            />
                        </div>

                    </div>

                </div>

                <div className="row mt-3">
                    <h6>Address Information</h6>
                    <div className="col-md-6" >
                        <div style={{ width: '70%', margin: '10px 0px', }}>
                            <p style={{ marginBottom: '8px', fontSize: '14px' }}>Street</p>
                            <InputField
                                name={'street'}
                                value={data.street}
                            />
                        </div>
                    </div>


                    <div className="col-md-6" >
                        <div style={{ width: '70%', margin: '10px 0px', }}>
                            <p style={{ marginBottom: '8px', fontSize: '14px' }}>City</p>
                            <InputField
                                name={'city'}
                                value={data.city}
                            />
                        </div>
                    </div>


                    <div className="col-md-6" >
                        <div style={{ width: '70%', margin: '10px 0px', }}>
                            <p style={{ marginBottom: '8px', fontSize: '14px' }}>State</p>
                            <InputField
                                name={'state'}
                                value={data.state}
                            />
                        </div>
                    </div>


                    <div className="col-md-6" >
                        <div style={{ width: '70%', margin: '10px 0px', }}>
                            <p style={{ marginBottom: '8px', fontSize: '14px' }}>Country</p>
                            <InputField
                                name={'country'}
                                value={data.country}
                            />
                        </div>
                    </div>

                    <div className="col-md-6" >
                        <div style={{ width: '70%', margin: '10px 0px', }}>
                            <p style={{ marginBottom: '8px', fontSize: '14px' }}>Pin code</p>
                            <InputField
                                name={'pin_code'}
                                value={data.pin_code}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Main>
    )
}

export default CreateLead
