import { DEPARTMENTS, DESIGNATIONS, DEVICE_TOKEN, LOGIN, LOGOUT, PHONE, ROLES, USER_INFO } from "../actionTypes/AuthAction"



export const login = (v) => {
    console.log("v herer anna",v)
    return {
        type: LOGIN,
        payload: v
    }
}

export const logout = (v) => {
    return {
        type: LOGOUT,
        payload: v
    }
}

export const user_info = (v) => {
    return {
        type: USER_INFO,
        payload: v
    }
}

export const roles = (v) => {
    return {
        type: ROLES,
        payload: v
    }
}

export const departments = (v) => {
    return {
        type: DEPARTMENTS,
        payload: v
    }
}

export const designations = (v) => {
    return {
        type: DESIGNATIONS,
        payload: v
    }
}

export const phone_number = (v) => {
    return {
        type: PHONE,
        payload: v
    }
}

export const device_token = (v) => {
    return {
        type: DEVICE_TOKEN,
        payload: v
    }
}