import React, { useState } from 'react'
import FmsDashboardMainRoot from '../FmsDashboardMainRoot'
import { BoldText1, SmallText } from '../../../helpers/constants/ConstantsText'
import axios from 'axios'
import { authHeader } from '../../../helpers/usertoken/UserToken'
import { useEffect } from 'react'
import PrimaryButton, { SecondaryButton } from '../../../components/buttons/PrimaryButton'
import { Drawer, Input, Modal, Pagination } from 'antd'
import { theme_color } from '../../../helpers/constants/ConstantColor'
import {AiOutlineInfoCircle} from 'react-icons/ai'
import {IoIosClose} from 'react-icons/io'
import toast from 'react-hot-toast'
import {BiEditAlt} from 'react-icons/bi'
import { MdDeleteOutline } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { MdOutlineRefresh } from 'react-icons/md'
import { AiOutlineFileExcel } from "react-icons/ai";
import { local_base_url } from '../../../App'
import fileDownload from "js-file-download";


function ScanCheckList() {

  const roles = useSelector(state => state.Auth.roles)

  const [search,setsearch] = useState('')

  const AuthHeader = {headers:{
    'Content-Type': 'application/json',
  }}


  const [data,setdata] = useState({})  
  const [pagination,setpagination] = useState({})
  const [page,setpage] = useState(1)
  const [modal,setmodal] = useState(false)
  const [modalvisible,setmodalvisible] = useState(false)
  const [name,setname] = useState('')
  const [selectedData,setselectedData] = useState({})
  const [error,seterror] = useState('')

  useEffect(()=>{
    getData()
  },[page])

  function setpagefuun(v){
    setpage(v)
  }

  async function getData(){
    await axios.get(`fms/scancheck_list/get?page=${page}`,authHeader)
    .then((res)=>{
       setdata(res?.data)
       setpagination(res?.data?.pagiantion)
    }).catch((err)=>{
    })
  }  

  async function submitform(){
    if(!name){
        seterror('The Name Field is required*')
    }else{
        if(selectedData?._id === undefined){
            await axios.post(`fms/scancheck_list/create`,{name:name},authHeader)
            .then((res)=>{
               getData()
            //    setmodal(false)
               toast.success("Created Successfully")
               setname('')
               seterror('')
            }).catch((err)=>{
            })        
        }else{
            await axios.put(`fms/scancheck_list/update/${selectedData?._id}`,{name:name},authHeader)
            .then((res)=>{
               getData()
               setmodal(false)
               toast.success("Updated Successfully")
               setname('')
               seterror('')
            }).catch((err)=>{
            })
        }
    }
  }

  function deletefunc(){
    axios.delete(`fms/scancheck_list/delete/${selectedData._id}`,authHeader)
    .then((res)=>{
        getData()
        setmodalvisible(false)
        toast.success("Deleted Successfully")
        setname('')
        seterror('')
    }).catch((err)=>{
    // console.log('err data here na',err)
    })
  }

  function searchData(){
    if(search?.length === 0){
        getData()
    }else{
        axios.get(`fms/scancheck_list/search?search_text=${search}`,AuthHeader)
        .then((res)=>{
            setpagination(res?.data?.pagination)
            setdata(res?.data)
        }).catch((err)=>{
        })
    }    
  }

  async function downloadExcel(){   
    await axios.get(`fms/scancheck_list/downloadExcel`,authHeader)
    .then((res)=>{
        let path = res.data.path.replace('public/','')
        handleDownload(`${local_base_url}${path}`,path.split('/')[path.split('/')?.length - 1])
        // console.log("res?.data",res?.data)


        // getdata('1')
    })
//    console.log("download Excel Function")
    }

    const handleDownload = (url, filename) => {
        // console.log('url',url,'filename',filename)

        axios.get(url, {
            responseType: 'blob',
        })
            .then((res) => {
                fileDownload(res.data, filename)
                toast.success("Clients Excel Downloaded") 
            })
    }

  return (
    <FmsDashboardMainRoot>
    <div style={{width:'97%'}}>


    <Modal visible={modalvisible} footer={false} closable={false} onCancel={() => setmodalvisible(false)} style={{ maxWidth: '280px' }} centered>
                    <div style={{ maxWidth: '200px', }}>
                    <IoIosClose  onClick={() => setmodalvisible(false)}size={20} style={{position:'absolute',top:'20px',cursor:'pointer',right:'25px'}} />
                        <h6 style={{ padding: '1%', fontWeight: '700', fontSize: '13px' }}>Delete Check List</h6>
                        <h6 style={{ fontSize: '11px', width: '112%', wordBreak: 'break-word', padding: '8px', background: '#def0ff', border: '1px solid #236499', borderRadius: '2px' }}><strong>Note:</strong>do you really want to delete the selected check list option once deleted cannot be retrieved back</h6>
                        {/* <Row style={{ width: '300px', margin: 'auto', display: 'flex' }}>
                            <BoldText1 val={'Are You sure ?'} />
                            <SmallText val={'do you really want to delete the selected contact '} />
                        </Row> */}

                        <h6 style={{fontSize:'12px'}}>Name : {selectedData?.name} </h6>
                        <div style={{ display:'flex',width:'100%',marginLeft:'90px',justifyItems:'flex-end'}} >
                            <SecondaryButton btn_name={' Cancel '} width="100%" onClick={()=>{setselectedData({});setmodalvisible(false)}}/>
                            <PrimaryButton btn_name={' Delete'}  width="100%"  onClick={deletefunc}/>
                        </div>
                    </div>
              </Modal>
               
              <Drawer width={250} visible={modal} footer={false} closable={false} maskClosable={true}>
            <div style={{position:'relative'}}>
            <IoIosClose  onClick={() => setmodal(false)}size={20} style={{position:'absolute',top:'-20px',cursor:'pointer',right:'-10px'}} />
                <h6 style={{background:'#eee',padding:'4px',fontSize:'11px',fontWeight:'400',marginTop:'10px'}}>Use the below form to create or update the client points for performance</h6>


                <div style={{marginBottom:'10px'}}>
                <div style={{width:'150px'}}>
                <SmallText fontWeight={'600'} val={'Name'}  />
                </div>
                <Input type="text" style={{borderLeft:`4px solid ${theme_color}`}}  value={name} onChange={(e)=>{setname(e.target.value);seterror('')}} />
                </div>

                <div style={{display:'flex',flexDirection:'row !important'}}>
                {(error) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error}</p>
                </div>

                


                <div style={{display:'flex'}}>
                    <PrimaryButton btn_name={'Save'} onClick={submitform}/>
                    <SecondaryButton btn_name={'Close'} onClick={()=>setmodal(false)}/>
                </div>


            </div>

              </Drawer>
       
        <div style={{borderBottom:'1px solid #eee',width:'50%',display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between'}}>
        <BoldText1 val={`Scan CheckList (${pagination?.total !== undefined ? pagination?.total : data?.datas?.length})`} />

        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
        <Input type="text" style={{marginRight:0,width:'40%'}} value={search} onChange={(e)=>setsearch(e.target.value)} onKeyPress={(e)=>e.key === 'Enter' && searchData()} />
        <MdOutlineRefresh onClick={()=>{getData();setsearch('')}} size={30} style={{marginLeft:'5px',marginRight:'5px',background:'#eee',padding:'4px'}}/>
        {(roles.includes('admin') || roles.includes('fms_hr'))  && 
        <AiOutlineFileExcel onClick={()=>{downloadExcel();}} size={30} style={{marginLeft:'5px',marginRight:'5px',background:'#eee',padding:'4px'}} />}
        <PrimaryButton btn_name={'Add CheckList'} onClick={()=>{setmodal(true);setname('');setselectedData({})}} />
        </div> 
        </div>


        <div  style={{width:'50%',marginTop:'10px'}}>
            <div className='mateial_table_header'>
                <p style={{ width: '10%',fontSize:'14px', color: 'black',marginRight:'2%' }}>SL NO</p>
                <p style={{ width: '72%',fontSize:'14px', marginRight: '2%' }}>Name</p>
                <p style={{ width: '10%',fontSize:'14px',}}>Action</p>
            </div> 
            {data?.datas?.map((d,i)=>(
                <div className='mateial_table_body'>
                    <p style={{ minWidth: '8%',maxWidth:'8%',color: "#000", wordBreak: 'break-word', fontWeight: '700',marginRight:'2%',marginLeft:'2%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis' }}>{page == 1 ?  i+1 : (pagination?.limit * (page-1)) + (i+1)}</p>
                    <p style={{ minWidth: '72%',maxWidth:'72%', color: "#0594f2", wordBreak: 'break-word', fontWeight: '700',marginRight:'2%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis' }}>{d?.name}</p>
                    <p style={{ minWidth: '8%',maxWidth:'8%',color: "#000", wordBreak: 'break-word', fontWeight: '700',marginRight:'2%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis' }}>
                        <BiEditAlt  onClick={()=>{setselectedData(d);setmodal(true);setname(d?.name)}} style={{marginRight:'20%'}}/>
                        {(roles?.includes('admin') || roles?.includes('fms_hr')) &&
                        <MdDeleteOutline onClick={()=>{setselectedData(d);setmodalvisible(true)}} />}
                    </p>
                </div>    
            ))}
             <div style={{marginTop:'40px',display:'flex',justifyContent:'center'}}> 
                {pagination?.total > pagination?.limit &&
                    <Pagination current={page} size="small" total={pagination?.total} onChange={(v) => setpagefuun(v)}  pageSize={pagination?.limit} />
                }
                </div>
        </div>

    </div>
    </FmsDashboardMainRoot>    
  )
}

export default ScanCheckList