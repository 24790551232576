import React,{useState} from 'react'
import { Input } from 'antd';
import { Button } from 'antd';
import bgImg from '../../../images/background.svg'
import Inputerror from '../../../components/errors/Inputerror';
import axios from 'axios';
import { authHeader } from '../../../helpers/usertoken/UserToken'
import toast, { Toaster }  from 'react-hot-toast'


function Forgot(){

    const [email,setemail] = useState('')
    const [emailerr,setemailerr] = useState('')
     
    const regex =new RegExp(
        '^(([^<>()\\[\\]\\\\.,;:\\s@]+(\\.[^<>()\\[\\]\\\\.,;:\\s@]+)*)|(.+))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
    )

    function handleChange(e){
        if(regex.test(email)){
            setemailerr('')
        }
        setemail(e.target.value)
    }


    function send_verification_link(){
          if(!email){
              setemailerr('FCPL Email is required')
          }else if(!regex.test(email)){
              setemailerr('Enter a valid email')
          }else{
              setemailerr('')
              delete authHeader.headers.Authorization
              let sendData = {
                  user_id: email
              }
              axios.post('user/send-reset-password-link', sendData, authHeader).then((res)=>{
                  setemail('')
                  toast.success('A reset link has been sent to your mail')
              }).catch((err)=>{
                  toast.error(err?.response?.data?.errors)
              })
          }
    }


    return(
        <div style={{display:'grid',width:'100%',height:'100%',justifyContent:'center',alignContent:'center',background:`#fff`,zIndex:0,position:'fixed',left:'0px',top:'0px',backgroundSize:'cover'}}>
            <Toaster />
            <div style={{background:'white',display:'flex',width:'60%',margin:'auto',boxShadow:'0px 0px 13px #f5f5f5'}} > 
                <div style={{width:'100%',padding:'0 0%',maxHeight:'350px'}} className="p-4" >
                    <h4 style={{fontWeight:'800',fontSize:'15px',marginTop:'20px'}}>Forgot Password</h4>
                    <h6 style={{fontSize:'13px'}}>Enter your registered Email , to change  FCPL CRM account password.</h6>
                    <h6 style={{fontWeight:'500',fontSize:'12px',marginTop:'20px'}}>Email Id</h6>
                    <Input size="medium" placeholder="Enter your Fcpl Email" class="input" value={email} className="mt-0" onChange={handleChange} />
                    {/* <p>{emailerr}</p> */}
                    <Inputerror message={emailerr} />
                    <Button onClick={() => send_verification_link()} style={{fontSize:'13px',fontWeight:'800',background:'#2064b3'}} className='btn  btn-primary mt-3'  type="primary"  size={'medium'} block>SEND LINK</Button>

                    {/* <Button type="primary" className="mt-4" size={'large'} block onClick={()=>send_verification_link()}>SEND LINK</Button> */}
                {/* <p type="link" size={'large'} className="text-center mt-4 fw-medium" style={{color:'#589afc',cursor:'pointer',fontWeight:'600'}} onClick={()=>window.location.pathname = "/login"} >Remember Password ?Login</p> */}

                </div>  
                {/* <div style={{flex:0.4,overflow:'hidden',width:'40%',borderLeft:'1px solid #f0f0f0',display:'grid',justifyContent:'center',alignContent:'center',alignItems:'center',textAlign:'center',padding:'0 20px',}}>
                    <img alt="side-img" src="https://accounts.zoho.com/v2/components/images/recovery_illustration2x.png"  style={{width:'200px',objectFit:'contain',margin:'auto'}}/>
                    <h6 style={{marginBottom:'0px'}}>Forgot Your Password</h6>
                    <p style={{fontSize:'14px',width:'86%',wordWrap:'break-word',margin:'auto',marginTop:'10px',textAlign:'center',lineHeight:''}}>Did you forgot your password? experience update password feature to your FCPL account provided by FCPL CRM.</p>
                </div> */}
            </div>
            <p style={{ width: '100%', textAlign: 'center', bottom: '10px', position: 'absolute',fontSize:'12px' }}>© 2022, Fidelitus Corp Pvt. Ltd. All Rights Reserved.</p>

        </div>
    )
}


export default Forgot