import { DEPARTMENTS, DESIGNATIONS, DEVICE_TOKEN, LOGIN, LOGOUT, PHONE, ROLES, USER_INFO } from '../actionTypes/AuthAction'

const initialstate = {
    login: false,
    access_token: localStorage.getItem("access_token"),
    refresh_token: null,
    device_token: null,
    name: null,
    email: null,
    id: null,
    phone: null,
    fcpl_id: null,
    roles: [],
    designation: {},
    department: [],
    accounts_added: [],
}


const AuthReducer = (state = initialstate, action) => {
    switch (action.type) {
        case LOGIN:
            if (action.payload) {
                state.access_token = action.payload
                state.login = true
            }
            return { ...state };
        // return {...state,access_token:localStorage.getItem("access_token"),login:true};
        case LOGOUT:
            state.login = false
            state.access_token = null
            return { ...state };
        case USER_INFO:
            console.log('USER_INFO', action.payload)
            state.name = action.payload.name
            state.email = action.payload.email
            state.id = action.payload.id
            state.fcpl_id = action.payload.employee_id
            return { ...state };
        case ROLES:
            state.roles = action.payload
            return { ...state };
        case DEPARTMENTS:
            state.department = action.payload
            return { ...state };
        case DESIGNATIONS:
            state.designation = action.payload
            return { ...state };
        case PHONE:
            state.phone = action.payload
            return { ...state }
        case DEVICE_TOKEN:
            state.device_token = action.payload
            return { ...state }
        default:
            return state
    }

}

export default AuthReducer