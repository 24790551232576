export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const PASSWORD_RESET = 'PASSWORD_RESET'
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'

export const ROLES = 'ROLES'
export const DEPARTMENTS = 'DEPARTMENTS'
export const DESIGNATIONS = 'DESIGNATIONS'

export const PHONE = 'PHONE'

export const USER_INFO = 'USER_INFO'


export const DEVICE_TOKEN = 'DEVICE_TOKEN'