import React, { useEffect, useState } from 'react'
import FmsDashboardMainRoot from '../FmsDashboardMainRoot'
import { Col, Drawer, Input,Row,TimePicker } from 'antd'
import { useLocation } from 'react-router-dom'
import axios from 'axios'
import { authHeader } from '../../../helpers/usertoken/UserToken'
import PrimaryButton, { SecondaryButton } from '../../../components/buttons/PrimaryButton'
import { SmallText } from '../../../helpers/constants/ConstantsText'
import { theme_color } from '../../../helpers/constants/ConstantColor'
import { Toaster, toast } from 'react-hot-toast'
import { AiOutlineEdit,AiOutlineDelete } from 'react-icons/ai'
import moment from 'moment/moment'
import Inputerror from '../../../components/errors/Inputerror'


function AttendanceOptionsCE() {

  const {state} = useLocation()

  const [data,setdata] = useState({name:'',start_time:'',end_time:''})
  const [error,seterror] = useState({name:'',start_time:'',end_time:''})
  const [datas,setdatas] = useState([])
  const [modal,setmodal] = useState(false)




  useEffect(()=>{
    getData()
  },[])

  function getData(){
    if(state === 'Shifts'){
      axios.get(`fms/attendanceshift/get`,authHeader)
      .then((res)=>{
        setdatas(res?.data?.datas)
        setdata({name:'',start_time:'',end_time:''})
        seterror({name:'',start_time:'',end_time:''})
      }).catch((err)=>{
  
      })
    }else if(state === 'Stages'){
      axios.get(`fms/attendancestage/get`,authHeader)
      .then((res)=>{
        setdatas(res?.data?.datas)
        setdata({name:'',start_time:'',end_time:''})
        seterror({name:'',start_time:'',end_time:''})
      }).catch((err)=>{
  
      })
    }else if(state === 'Years'){
      axios.get(`fms/attendanceyear/get`,authHeader)
      .then((res)=>{
        setdatas(res?.data?.datas)
        setdata({name:'',start_time:'',end_time:''})
        seterror({name:'',start_time:'',end_time:''})
      }).catch((err)=>{
  
      })
    }
  }

  function submitForm(){
    if(!data?.name){
       seterror({...error,name:'This Field is required*'})
    }else if(!data.start_time && state !== 'Stages' && state !== 'Years'){
      seterror({...error,start_time:'This Field is required*'})
    }else if(!data.end_time && state !== 'Stages' && state !== 'Years'){
      seterror({...error,end_time:'This Field is required*'})
    }else{
      if(data?._id !== undefined && data?._id !== null){
        if(state === 'Shifts'){
          axios.put(`fms/attendanceshift/update/${data?._id}`,data,authHeader)
          .then((res)=>{
            getData()
            setdata({name:'',start_time:'',end_time:''})
            seterror({name:'',start_time:'',end_time:''})
            setmodal(false)
            toast.success('Updated Successfully')
          }).catch((err)=>{
      
          })
        }else if(state === 'Stages'){
          axios.put(`fms/attendancestage/update/${data?._id}`,data,authHeader)
          .then((res)=>{
            getData()
            setdata({name:'',start_time:'',end_time:''})
            seterror({name:'',start_time:'',end_time:''})
            setmodal(false)
            toast.success('Updated Successfully')
          }).catch((err)=>{
      
          })
        }else if(state === 'Years'){
            axios.put(`fms/attendanceyear/update/${data?._id}`,data,authHeader)
            .then((res)=>{
              getData()
              setmodal(false)
              setdata({name:'',start_time:'',end_time:''})
              seterror({name:'',start_time:'',end_time:''})
              toast.success('Updated Successfully')
            }).catch((err)=>{
        
            })
        }
      }else{ 
        if(state === 'Shifts'){
          axios.post(`fms/attendanceshift/create`,data,authHeader)
          .then((res)=>{
            getData()
            setdata({name:'',start_time:'',end_time:''})
            seterror({name:'',start_time:'',end_time:''})
            setmodal(false)
            toast.success('Created Successfully')
          }).catch((err)=>{
      
          })
        }else if(state === 'Stages'){
          const fd = new FormData()
          fd.append('name',data?.name)
          console.log("data",data)
          axios.post(`fms/attendancestage/create`,fd,authHeader)
          .then((res)=>{
            getData()
            setmodal(false)
            setdata({name:'',start_time:'',end_time:''})
            seterror({name:'',start_time:'',end_time:''})
            toast.success('Created Successfully')
          }).catch((err)=>{
      
          })
        }else if(state === 'Years'){
          axios.post(`fms/attendanceyear/create`,data,authHeader)
          .then((res)=>{
            getData()
            setmodal(false)
            setdata({name:'',start_time:'',end_time:''})
            seterror({name:'',start_time:'',end_time:''})
            toast.success('Created Successfully')
          }).catch((err)=>{
      
          })
        }
      }
    }
  }


  function deleteForm(v){
    if(state === 'Shifts'){
      axios.delete(`fms/attendanceshift/delete/${v}`,authHeader)
      .then((res)=>{
        setmodal(false)
        getData()
        toast.success('Deleted Successfully')
      }).catch((err)=>{
  
      })
    }else if(state === 'Stages'){
      axios.delete(`fms/attendancestage/delete/${v}`,authHeader)
      .then((res)=>{
        setmodal(false)
        getData()
        toast.success('Deleted Successfully')
      }).catch((err)=>{
  
      })
    }else if(state === 'Years'){
      axios.delete(`fms/attendanceyear/delete/${v}`,authHeader)
      .then((res)=>{
        setmodal(false)
        getData()
        toast.success('Deleted Successfully')
      }).catch((err)=>{
  
      })
    }
  }


  

  return (
    <FmsDashboardMainRoot>
      <Toaster />
        <Drawer width={300}  closable={false} open={modal} footer={false} maskClosable={true}>
            <div style={{position:'relative'}}>
               <h6 style={{fontSize:'11px',background:'#eee',padding:'5px'}}>Use the below form to create or update the {state}</h6>
               
                <div style={{marginBottom:'10px'}}>
                <div style={{width:'150px'}}>
                <SmallText fontWeight={'600'} val={'Name '}  />
                </div>
                <Input type="text" style={{borderLeft:`4px solid ${theme_color}`}}  value={data.name} onChange={(e)=>{setdata({...data,name:e.target.value});seterror({...error,name:''})}} />
                </div>
                <Inputerror message={error?.name} />
                {state !== 'Stages' && state !== 'Years' &&
                <>
                <div style={{marginBottom:'10px'}}>
                <div style={{width:'150px'}}>
                <SmallText fontWeight={'600'} val={'Start Time '}  />
                </div>
                <Input type="text" style={{borderLeft:`4px solid ${theme_color}`}}  value={data.start_time} onChange={(e)=>{setdata({...data,start_time:e.target.value});seterror({...error,start_time:''})}} />
                {/* <TimePicker placeholder=''  format={'hh:mm'} style={{borderLeft:`4px solid ${theme_color}`,width:'100%'}}  value={data?.start_time} onChange={(e)=>{setdata({...data,start_time:e});seterror({...error,start_time:''})}} /> */}
                </div>
                <Inputerror message={error?.start_time} />

                <div style={{marginBottom:'10px'}}>
                <div style={{width:'150px'}}>
                <SmallText fontWeight={'600'} val={'End Time '}  />
                </div>
                <Input type="text" style={{borderLeft:`4px solid ${theme_color}`}}  value={data.end_time} onChange={(e)=>{setdata({...data,end_time:e.target.value});seterror({...error,end_time:''})}} />
                {/* <TimePicker placeholder=''  format={'hh:mm'} style={{borderLeft:`4px solid ${theme_color}`,width:'100%'}}  value={data.end_time} onChange={(e)=>{setdata({...data,end_time:e});seterror({...error,end_time:''})}} /> */}
                </div>
                <Inputerror message={error?.end_time} />
                </>}


                <div style={{display:'flex',alignItems:'center',justifyContent:'flex-end'}}>

                  <SecondaryButton btn_name={'Cancel'} onClick={()=>setmodal(false)} />
                  <PrimaryButton btn_name={'Save'} onClick={()=>submitForm()} />
                </div>
           
            </div> 
        </Drawer>
        <div style={{width:'97%'}}>
          <div style={{borderBottom:'1px solid #eee',display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between'}}>
            <h6 style={{fontWeight:'700',fontSize:'14px',marginBottom:'0px'}}>{state}</h6>
            <PrimaryButton btn_name={`Add ${state}`} onClick={()=>{setmodal(true);setdata({name:'',start_time:'',end_time:''})}} />
          </div>

          <Row style={{marginTop:'10px'}}>

              {datas?.map((d)=>( 
              <Col span={4} style={{border:'1px solid #eee',position:'relative',marginRight:'1%',padding:'4px'}}>
                <div>
                    <AiOutlineDelete onClick={()=>deleteForm(d?._id)} size={12} style={{position:'absolute',right:'22px',top:'4px'}} />
                    <AiOutlineEdit onClick={()=>{setdata({...d});setmodal(true)}} size={12} style={{position:'absolute',right:'4px',top:'4px'}} />
                    <h6 style={{fontSize:'11px'}}>Name : <span style={{fontWeight:'600'}}>{d?.name}</span></h6>  
                    {state !== 'Stages' && state !== 'Years' && <h6 style={{fontSize:'11px'}}>Time : <span style={{fontWeight:'600'}}>{d?.start_time} / {d?.end_time}</span></h6> }
                </div>
              </Col>
              ))}
          </Row>
        </div>
    </FmsDashboardMainRoot>
  )
}

export default AttendanceOptionsCE