import React, { useState, useEffect } from 'react'
import MobileTableHeader from '../../../components/bdcomponenttable/mobileComponents/MobileTableHeader'
import SubViewLeads from './SubVIewLeads'
import { useNavigate } from 'react-router-dom'
import { Modal, Select, Row, Col, Tag, Button, Input, Drawer, message } from 'antd'
import PrimaryButton from '../../../components/buttons/PrimaryButton'
import { getContacts } from '../functions/Apis.js'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { authHeader } from '../../../helpers/usertoken/UserToken'
import { IoClose } from 'react-icons/io5'
import DateField from '../../../components/inputcomponents/DateField';
import Loader from '../../../components/Loaders/Loader'
import { main_bg } from '../../../components/constants/Constants1'


import { Timeline } from '@mui/lab';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';

import { IoCreateOutline } from 'react-icons/io5'
import { AiOutlineClockCircle, AiOutlineEdit } from 'react-icons/ai'
import { BiTransfer } from 'react-icons/bi'
import moment from 'moment';
import toast, { Toaster } from 'react-hot-toast';

const { Option } = Select;
const { TextArea } = Input;

function ViewLead() {

    const roles = useSelector(state => state.Auth.roles)
    const department = useSelector(state => state.Auth.department)

    const [loader, setloader] = useState(false)

    const [contacts, setContacts] = useState([])
    const [contactId, setContactId] = useState('')
    const [leads, setLeads] = useState([])
    const [Assigneddatas, setAssignedDatas] = useState([])
    const [pagination, setpagination] = useState({})
    const [page, setpage] = useState(1)


    const [remark, setremark] = useState()

    const [reminder, setReminders] = useState([])


    const [departement_arr, setdepartement_arr] = useState([])
    const [selected_department, setselected_department] = useState({ department_name: '', department_id: '' })
    const [selected_department1, setselected_department1] = useState({ department_name: '', department_id: '' })
    const [selected_employeee, setselected_employeee] = useState({ name: '', id: '' })
    const [selected_assigned_lead, setselected_assigned_lead] = useState({})


    const [hod_arr, sethod_arr] = useState([])
    const [managers_arr, setmanagers_arr] = useState([])
    const [employee_arr, setemployee_arr] = useState([])

    const [assign_data, setassign_data] = useState({ department_id: '', hod_id: '', manager_id: '', employee_id: '', lead_id: '', remarks: '', cancellation_remark: '', status: false, hod_name: '', manager_name: '', employee_name: '' })



    const [isModalVisible, setIsModalVisible] = useState(false);
    const [visible, setVisible] = useState(false);
    const [visible1, setVisible1] = useState(false);
    const [visible2, setVisible2] = useState(false);

    const [visible4, setVisible4] = useState(false);
    const [visible5, setVisible5] = useState(false);
    const [visible6, setVisible6] = useState(false);
    const [remarkvisible6, setremarkvisible6] = useState(false);


    const [selected_lead, setselected_lead] = useState()
    const [assigned_lead, setassigned_lead] = useState('')
    const [data, setdata] = useState({})

    const [lead_tracker, setlead_tracker] = useState([])
    const [task_tracker, settask_tracker] = useState([])
    const [meeting_tracker, setmeeting_tracker] = useState([])




    const [search_lead, setsearch_lead] = useState({ search_text: '', lead_stage: [], from_date: '', to_date: '', filter_status: false })
    const [tempsearch_lead, settempsearch_lead] = useState({ search_text: '', lead_stage: [], from_date: '', to_date: '' })



    const [count, setcount] = useState('0')

    const [lead_status, setlead_status] = useState([])

    let lead_status_basic_gallery_options = [{ id: 'Attempted to Contact', value: 'Attempted to Contact' }, { id: 'Contact in Future', value: 'Contact in Future' }, { id: 'Contacted', value: 'Contacted' }, { id: 'Junk lead', value: 'Junk lead' }, { id: 'Lost lead', value: 'Lost lead' }, { id: 'Not Contacted', value: 'Not Contacted' }, { id: 'Pre Qualified', value: 'Pre Qualified' }, { id: 'Not Qualified', value: 'Not Qualified' }, { id: 'Hold', value: 'Hold' }]
    let lead_status_basic_fms_options = [{ id: 'Meeting/Discussion', value: 'Meeting/Discussion' }, { id: 'Assessment', value: 'Assessment' }, { id: ' Proposal sent', value: ' Proposal sent' }, { id: 'Negotiation', value: 'Negotiation' }, { id: 'Final closure', value: 'Final closure' }, { id: 'Agreement', value: 'Agreement' }, { id: 'Hold', value: 'Hold' }]
    let lead_status_basic_project_options = [{ id: 'BD', value: 'BD' }, { id: 'Design', value: 'Design' }, { id: 'QS', value: 'QS' }, { id: 'MEP', value: 'MEP' }, { id: 'BOQ Review', value: 'BOQ Review' }, { id: 'Contact in Future', value: 'Contact in Future' }, { id: 'Closed', value: 'Closed' }, { id: 'Lost', value: 'Lost' }, { id: 'Junk Lead', value: 'Junk Lead' }, { id: 'Hold', value: 'Hold' }]
    let lead_status_basic_trans_options = [{ id: 'RFQ', value: 'RFQ' }, { id: 'Options sent/Provided', value: 'Options sent/Provided' }, { id: 'Inspection', value: 'Inspection' }, { id: 'Meeting/Discussion', value: 'Meeting/Discussion' }, { id: 'LOI', value: 'LOI' }, { id: 'Agreement/Registration', value: 'Agreement/Registration' }, { id: 'Collection', value: 'Collection' }, { id: 'Lead Lost', value: 'Lead Lost' }, { id: 'Negotiation', value: 'Negotiation' }, { id: 'Hold', value: 'Hold' }]
    let lead_status_basic_hr_options = [{ id: 'Follow up', value: 'Follow up' }, { id: 'Waiting for requirement', value: 'Waiting for requirement' }, { id: 'Closed', value: 'Closed' }, { id: 'lost', value: 'lost' }, { id: 'Hold', value: 'Hold' }]


    const [department_id, setdepartment_id] = useState('')
    const [current_tab, setcurrent_tab] = useState('1')



    const navigate = useNavigate()


    useEffect(() => {
        setactivetab('1')
        if (roles.includes('db_head') && roles.includes('db_user') && roles.includes('db_sub_user') && roles.includes('admin')) {
            setlead_status([])
            getDepartements()
            setdepartment_id('')
        } else {
            if (department[0].department_name === 'Transaction Team') {
                setlead_status(lead_status_basic_trans_options)
            } else if (department[0].department_name === 'Project Team') {
                setlead_status(lead_status_basic_project_options)
            } else if (department[0].department_name === 'Hr Team') {
                setlead_status(lead_status_basic_hr_options)
            } else if (department[0].department_name === 'Gallery Team') {
                setlead_status(lead_status_basic_gallery_options)
            } else if (department[0].department_name === 'FMS Team') {
                setlead_status(lead_status_basic_fms_options)
            }
            setdepartment_id(department[0].id)
        }
    }, [])


    useEffect(async () => {
        getleads(1)
    }, [])

    function leaddetailfunc(v) {
        navigate(`/lead_detail/${v._id ? v._id : v}`, { state: { datas: v } })
    }
    function getleads(v) {
        setpage(v)
        setloader(true)
        axios.get(`lead/get/${v}`, authHeader)
            .then((res) => {
                setloader(false)
                setLeads(res.data.datas)
                setpagination(res.data.pagination)
            })
    }
    function getDepartements() {
        axios.get('department/get', authHeader)
            .then((res) => {
                const departement_arr_created = []
                res.data.datas.forEach(d => {
                    let data = {}
                    data['label'] = d.department_name
                    data['value'] = d.department_name
                    data['id'] = d.id
                    departement_arr_created.push(data)
                })
                setdepartement_arr(departement_arr_created)
            })
    }
    async function gotoleadcreatefun() {
        navigate('/create_lead')
    }
    function editleadfunc(v) {
        navigate('/edit_lead/' + v)
    }
    function handleChange(value) {
        navigate('/create_lead', { state: { datas: contacts.filter(contact => contact.id === value)[0] } })
    }
    function handleChange1({ key, value }) {
        settempsearch_lead({ ...tempsearch_lead, [key]: value })
        setsearch_lead({ ...search_lead, [key]: value })
        // if (tempsearch_lead.from_date && tempsearch_lead.to_date) {
        //     setsearch_lead({ ...search_lead, from_date: tempsearch_lead.from_date, to_date: tempsearch_lead.to_date })
        // }
    }
    function convert_tolead() {
        if (contactId) {
            navigate('/create_lead', { state: { datas: contacts.filter(contact => contact.id === contactId)[0] } })
        }
    }
    function gotodealcreatefunc(v) {
        navigate(`/create_deal`, { state: { datas: v } })
    }
    function applyfilter() {
        if (search_lead.search_text || search_lead.lead_stage || search_lead.from_date || search_lead.to_date) {
            setloader(true)
            axios.get(`lead/search?search_text=${search_lead.search_text}&status=${search_lead.lead_stage}&from_date=${search_lead.from_date}&to_date=${search_lead.to_date}&page=${1}`, authHeader)
                .then((res) => {
                    setloader(false)
                    setsearch_lead({ ...search_lead, filter_status: true })
                    setpagination(res.data.pagination)
                    setLeads(res.data.datas)
                    settempsearch_lead({ search_text: '', lead_stage: '', from_date: '', to_date: '' })
                    setVisible(false)
                })
        } else {
            setVisible(false)
            setloader(false)
            toast.info('select any filter option before applying ')
        }
    }
    async function resetfilter() {
        setsearch_lead({ search_text: '', lead_stage: [], from_date: '', to_date: '', filter_status: false })
        settempsearch_lead({ search_text: '', lead_stage: [], from_date: '', to_date: '' })
        setselected_department({ ...selected_department, department_id: '', department_name: '' })
        getleads()
        setVisible(false)

    }
    async function addleadfunc() {
        let contactDatas = await getContacts()
        setContacts(contactDatas)
        setIsModalVisible(true)
    }
    // assign db_head 
    function handleChangefun(v, v1, v2) {
        if (v2 === '1') {
            setselected_department1({ ...selected_department1, department_id: departement_arr.find((d) => d.id === v).id, department_name: departement_arr.find((d) => d.id === v)?.value })
            if (departement_arr.find((d) => d.id === v)?.value === 'Transaction Team') {
                setlead_status(lead_status_basic_trans_options)
            } else if (departement_arr.find((d) => d.id === v)?.value === 'Project Team') {
                setlead_status(lead_status_basic_project_options)
            } else if (departement_arr.find((d) => d.id === v)?.value === 'Hr Team') {
                setlead_status(lead_status_basic_hr_options)
            }
        } else {
            if (roles.includes('db_head') || roles.includes('db_user') || roles.includes('db_sub_user') || roles.includes('admin')) {
                getRoles(v)
                setselected_employeee({ ...selected_employeee, id: v, name: hod_arr.find(e => e.id === v).name })
                setassign_data({ ...assign_data, [v1]: v, hod_name: hod_arr?.find((h) => h.id === v)?.name })

            } else if (roles.includes('hod') || roles.includes('manager')) {
                getteamemployees()
                roles.includes('hod') ?
                    setassign_data({ ...assign_data, [v1]: v, employee_name: employee_arr?.find((e) => e.id === v)?.name })
                    :
                    setassign_data({ ...assign_data, [v1]: v, employee_name: employee_arr?.find((e) => e.id === v)?.name })
            }
        }
    }
    function getRoles(v) {
        axios.get(`user/get-users-by-department-role/${v}/hod`, authHeader)
            .then((res) => {
                const hod_arr_created = []
                res?.data?.datas?.forEach(d => {
                    let data = {}
                    data['name'] = `${d?.name}(${d?.user_roles[0]?.roles[0]?.role_name})`
                    data['id'] = d?._id
                    hod_arr_created.push(data)
                })
                sethod_arr(hod_arr_created)

            })
    }
    function getteamemployees() {
        axios.get(`user/get-users-by-departmentId/${department_id}`, authHeader)
            .then((res) => {
                let managers_arr_created = []
                let employee_arr_created = []
                res.data.datas.forEach((u) => {
                    u.user_roles[0]?.roles.forEach((r) => {
                        if (r?.role_name === 'manager') {
                            let obj = {}
                            obj['name'] = u.name + `(${r?.role_name})`
                            obj['id'] = u._id
                            managers_arr_created.push(obj)
                        } else if (r?.role_name !== 'manager' && r?.role_name !== 'hod' && r?.role_name !== 'admin') {
                            let obj = {}
                            obj['name'] = u.name + `(${r?.role_name})`
                            obj['id'] = u._id
                            employee_arr_created.push(obj)
                            managers_arr_created.push(obj)
                        }
                    })
                })
                setmanagers_arr(managers_arr_created)
                setemployee_arr(employee_arr_created)
            }).catch((err) => {
            })
    }
    function assignLeadfunc() {
        if (!selected_lead) {
            toast.info('select lead for assign')
        } else {
            if (roles.includes('db_head') || roles.includes('db_user') || roles.includes('db_sub_user') || roles.includes('admin')) {
                setdepartment_id('')
                getDepartements()
                let selected_lead_obj = leads.find(l => l._id === selected_lead)
                let lead_department = departement_arr.find(d => d?.id === selected_lead_obj?.department_id)
                setselected_department({ ...selected_department, department_name: lead_department?.value, department_id: lead_department?.id })
                getRoles(selected_lead_obj?.department_id)

            }
            else if (department_id && (roles.includes('hod') || roles.includes('manager'))) {
                getteamemployees()
            } else {
                getteamemployees()
            }
            setVisible2(true)
        }
    }
    function reassignLeadfunc() {
        if (!assigned_lead) {
            toast.info('select lead for assign')
        } else {
            if (roles.includes('db_head') || roles.includes('db_user') || roles.includes('db_sub_user') || roles.includes('admin')) {
                getDepartements()
            }
            else if (department_id && (roles.includes('hod') || roles.includes('manager'))) {
                getteamemployees()
            }
            setVisible2(true)
        }
    }
    //get Assigned by me lead
    async function getassignedLeads(v) {
        if (roles.includes('db_head') || roles.includes('db_user') || roles.includes('db_sub_user') || roles.includes('hod') || roles.includes('manager') || roles.includes('admin')) {
            setloader(true)
            await axios.get(`assign/get-assigned-by-me/${v}`, authHeader)
                .then((res) => {
                    setloader(false)
                    setAssignedDatas(res.data.datas.reverse())
                    setpagination(res.data.pagination)
                })
                .catch((err) => {
                    setloader(false)
                })
        }
        // else if((roles.includes('hod') || roles.find(r=>r==='Manager') || roles.find(r=>r !=='db_head')))){
        //     axios.get(`assign/assigned-to-hod`,authHeader)
        //     .then((res)=>{
        //         setAssignedDatas(res.data.datas)
        //     })
        //     .catch((err)=>{
        //         console.log('err me na',err)
        //     })
        // }

    }
    //get Assigned to me lead 
    async function getassignedtomeLeads(v) {
        setloader(true)
        await axios.get(`assign/assigned-to-me/${v}`, authHeader)
            .then((res) => {
                setloader(false)
                setAssignedDatas(res.data.datas.reverse())
                setpagination(res.data.pagination)
            })
            .catch((err) => {
                setloader(false)
            })
    }
    //assigned lead to hod by db team
    function approvelead() {
        if (roles.includes('admin') || roles.includes('db_head') || roles.includes('db_user') || roles.includes('db_sub_user')) {

            if (!assign_data.hod_id) {
                toast.info('select hod to assign lead')
            } else {
                const send_data = {}
                send_data['lead_id'] = selected_lead
                send_data['department_id'] = selected_department.department_id
                send_data['user_id'] = assign_data.hod_id
                send_data['remarks'] = assign_data.remarks
                setloader(true)
                axios.post(`assign/assign-lead`, send_data, authHeader)
                    .then((res) => {
                        setloader(false)
                        setVisible2(false)
                        setassign_data({ ...assign_data, department_id: '', hod_id: '', lead_id: '' })
                        if (res.data.status) {
                            setselected_lead('')
                            toast.success('Lead assigned successfully')
                            getassignedtomeLeads(1)
                            setassign_data({ ...assign_data, hod_id: '', hod_name: null, remarks: '' })
                            setselected_employeee({ id: '', name: '' })
                        }
                    }).catch(err => {
                        setVisible2(false)
                        setloader(false)

                        if (err.response.status === 422) {
                            setloader(false)

                            setselected_lead('')
                            toast.error('Lead already has been assigned')
                            setassign_data({ ...assign_data, hod_id: '', hod_name: null, remarks: '' })
                            setselected_employeee({ id: '', name: '' })
                            getassignedtomeLeads(1)
                        }
                    })
            }

        } else if (roles.includes('hod') || roles.includes('manager')) {

            if (!assign_data.manager_id) {
                toast.info('select employee/manager to assign lead')
            } else {
                let data = {}
                data['lead_id'] = current_tab === '1' ? selected_lead : selected_assigned_lead?.assigned_lead_id?.lead_id?.id
                data['department_id'] = department_id
                data['user_id'] = assign_data.manager_id
                data['remarks'] = assign_data.remarks

                setloader(true)
                axios.post(`assign/assign-lead`, data, authHeader)
                    .then((res) => {
                        setloader(false)
                        setVisible2(false)
                        setassign_data({ ...assign_data, department_id: '', hod_id: '', hod_name: null, lead_id: '', manager_id: '', employee_name: null })
                        if (res.data.status) {
                            setselected_lead('')
                            toast.success('Lead assigned successfully')
                            getassignedtomeLeads()
                        }
                    }).catch(err => {
                        setloader(false)
                        setVisible2(false)
                        if (err?.response?.status === 422) {
                            setselected_lead('')
                            toast.error('Lead already has been assigned')
                            getassignedtomeLeads()
                        }
                    })
            }
        }
    }
    function setactivetab(v) {
        setcurrent_tab(v)
        if (v === '1') {
            getleads(1)
        } else if (v === '2') {
            getassignedtomeLeads(1)
        }
    }
    function setactivetab1(v) {
        if (v === '1') {
            getassignedtomeLeads(1)
        } else if (v === '2') {
            getassignedLeads(1)
        }
    }
    async function acceptassignedlead(v, v1) {
        if (v1 === 1) {
            setVisible4(true)
            setselected_lead(v)
        } else if (v1 === 2) {
            setVisible5(true)
        }
    }
    async function confirmingapproval(v) {
        let send_data = {
            status: true
        }
        axios.put(`assign/update-assign/${v._id}`, send_data, authHeader)
            .then((res) => {
                getassignedtomeLeads(1)
                setVisible4(false)
            }).catch((err) => {
            })
    }
    async function getReminder(id) {
        await axios.get(`reminder/get?type=${'Lead'}&type_id=${id}`, authHeader)
            .then((res) => {
                setReminders(res.data.datas.reverse())
            })
    }
    function handleChangeContact(v) {
        setContactId(v)
    }
    function setremarkfunc(v) {
        setremark(v)
        setremarkvisible6(true)
    }
    async function tracker(d) {
        await axios.get(`lead/get/${d?.assigned_lead_id?.lead_id?.id}`, authHeader).then((res) => {
            setdata(res.data.datas[0])
        })

        await axios.get(`activity/get-timeline/Lead/${d?.assigned_lead_id?.lead_id?.id}`, authHeader)
            .then((res) => {
                setlead_tracker(res.data.datas)
                let tracker_arrange = []
                let tracker_arrange1 = []
                res.data.datas.sub.filter(t => t.type === 'Task' && t.history === '<p>Task created</p>' && t.event === 'create').forEach(e1 => {
                    tracker_arrange.push(e1)
                    if (res.data.datas.sub.filter(t1 => t1.type === 'Task' && t1.event === 'update' && t1.type_id === e1.type_id).length > 0) {
                        res.data.datas.sub.filter(t1 => t1.type === 'Task' && t1.event === 'update' && t1.type_id === e1.type_id).forEach(e => {
                            tracker_arrange.push(e)
                        })
                    }
                })
                settask_tracker(tracker_arrange)

                res.data.datas.sub.filter(t => t.type === "Meeting" && t.history === '<p>Meeting created</p>' && t.event === 'create').forEach(e1 => {
                    tracker_arrange1.push(e1)
                    if (res.data.datas.sub.filter(t1 => t1.type === 'Meeting' && t1.event === 'update' && t1.type_id === e1.type_id).length > 0) {
                        res.data.datas.sub.filter(t1 => t1.type === 'Meeting' && t1.event === 'update' && t1.type_id === e1.type_id).forEach(e => {
                            tracker_arrange1.push(e)
                        })
                    }
                })
                setmeeting_tracker(tracker_arrange1)
                setVisible6(true)
            }).catch((err) => {
            })
    }

    function setleadstage(v) {
        let current_search_lead = [...search_lead.lead_stage]
        if (current_search_lead?.includes(v)) {
            current_search_lead.splice(current_search_lead?.findIndex(v1 => v1 === v), 1)
        } else {
            current_search_lead.push(v)
        }
        console.log('current_search_lead', current_search_lead)
        setsearch_lead({ ...search_lead, lead_stage: current_search_lead })
    }

    return (
        <div style={{ width: '100%', height: '100%' }}>
            <Toaster />

            <Drawer title="Basic Drawer" placement="bottom" onClose={() => setVisible6(false)} visible={visible6} height={window.innerHeight / 1.3} width={window.innerWidth} closable={false} headerStyle={{ display: 'none' }}  >
                <h4 style={{ fontSize: '20px', fontWeight: '800' }}>TimeLine <AiOutlineClockCircle size={22} style={{ margin: '0px 5px' }} /> </h4>
                <Timeline style={{ marginTop: '30px', width: '100%' }} >

                    {lead_tracker?.base?.filter((l => l.type === 'Lead' && (l?.history?.includes('Converted from contact ')))).map(t => (
                        <TimelineItem>
                            <TimelineOppositeContent color="text.secondary">
                                <h6 style={{ fontSize: '12px', color: '#0ca7fa', fontWeight: 'bold' }}> {moment(data?.contact_id?.createdAt).format('lll')}</h6>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                {t.event === 'convert' && <IoCreateOutline size={20} color="#171636" />}
                                {/* {t.event === 'update' && <AiOutlineEdit size={20} color="#171636"/>} */}
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent style={{ maxWidth: '155px', backgroundColor: '#fff' }}>
                                <h6 style={{ fontSize: '12px', fontWeight: '600', marginBottom: '0px', paddingBottom: '0px' }}>Contact Created</h6>
                                <span style={{ marginleft: '0px', fontSize: '11px', color: '#0087f5', fontWeight: '700', cursor: 'pointer', paddingLeft: '0px', marginTop: '0px', paddingBottom: '2px' }} onClick={() => navigate(`/contact_detail/${t?.history.split(':')[1]}`)}>View Details</span>
                                <h6 style={{ fontSize: '11px', fontWeigt: '600', color: '#858585', marginBottom: '0px', paddingBottom: '0px' }}>{t?.user_id?.name}</h6>
                            </TimelineContent>
                        </TimelineItem>
                    ))}

                    {lead_tracker?.base?.filter((l => l.type === 'Lead' && (l?.history?.includes('Converted from contact ')))).map(t => (
                        <TimelineItem>
                            <TimelineOppositeContent color="text.secondary">
                                <h6 style={{ fontSize: '12px', color: '#0ca7fa', fontWeight: 'bold' }}> {moment(t?.createdAt).format('lll')}</h6>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                {t.event === 'convert' && <BiTransfer size={20} color="#171636" />}
                                {/* {t.event === 'update' && <AiOutlineEdit size={20} color="#171636"/>} */}
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent style={{ maxWidth: '155px', backgroundColor: '#fff' }}>
                                <h6 style={{ fontSize: '12px', fontWeight: '600', marginBottom: '0px', paddingBottom: '0px' }} >{t?.history.split(':')[0]}</h6>
                                <span style={{ marginleft: '0px', fontSize: '11px', color: '#0087f5', fontWeight: '700', cursor: 'pointer', paddingLeft: '0px', marginTop: '0px', paddingBottom: '2px' }} onClick={() => navigate(`/contact_detail/${t?.history.split(':')[1]}`)}>View Details</span>
                                <h6 style={{ fontSize: '11px', fontWeigt: '600', color: '#858585', marginBottom: '0px', paddingBottom: '0px' }}>{t?.user_id?.name}</h6>
                            </TimelineContent>
                        </TimelineItem>
                    ))}

                    {lead_tracker?.base?.filter((l => l.type === 'Lead' && (l?.history?.includes('<p>Lead Created</p>')))).map(t => (
                        <TimelineItem>
                            <TimelineOppositeContent color="text.secondary">
                                <h6 style={{ fontSize: '12px', color: '#0ca7fa', fontWeight: 'bold' }}> {moment(t?.createdAt).format('lll')}</h6>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                {t.event === 'create' && <IoCreateOutline size={20} color="#171636" />}
                                {t.event === 'update' && <AiOutlineEdit size={20} color="#171636" />}
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent style={{ maxWidth: '155px', backgroundColor: '#fff' }}>
                                <h6 style={{ fontSize: '12px', fontWeight: '600', marginBottom: '0px', paddingBottom: '0px' }} dangerouslySetInnerHTML={{ __html: t?.history }}></h6>
                                {/* <span style={{marginleft:'0px',fontSize:'11px',color:'#0087f5',fontWeight:'700',cursor:'pointer',paddingLeft:'0px',marginTop:'0px',paddingBottom:'2px'}} onClick={()=>navigate(`/contact_detail/${t?.history.split(':')[1]}`)}>View Details</span> */}
                                <h6 style={{ fontSize: '11px', fontWeigt: '600', color: '#858585', marginBottom: '0px', paddingBottom: '0px' }}>{t?.user_id?.name}</h6>
                            </TimelineContent>
                        </TimelineItem>
                    ))}

                    {lead_tracker?.base?.filter((l => l.event === "assign_lead")).map(t => (
                        <TimelineItem>
                            <TimelineOppositeContent color="text.secondary">
                                <h6 style={{ fontSize: '12px', color: '#0ca7fa', fontWeight: 'bold' }}> {moment(t?.createdAt).format('lll')}</h6>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                {t.event === 'assign_lead' && <IoCreateOutline size={20} color="#171636" />}
                                {/* {t.event === 'update' && <AiOutlineEdit size={20} color="#171636"/>} */}
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent style={{ maxWidth: '155px', backgroundColor: '#fff' }}>
                                <h6 style={{ fontSize: '12px', fontWeight: '600', marginBottom: '0px', paddingBottom: '0px' }} dangerouslySetInnerHTML={{ __html: t?.history }}></h6>
                                {/* <span style={{marginleft:'0px',fontSize:'11px',color:'#0087f5',fontWeight:'700',cursor:'pointer',paddingLeft:'0px',marginTop:'0px',paddingBottom:'2px'}} onClick={()=>navigate(`/contact_detail/${t?.history.split(':')[1]}`)}>View Details</span> */}
                                <h6 style={{ fontSize: '11px', fontWeigt: '600', color: '#858585', marginBottom: '0px', paddingBottom: '0px' }}>{t?.user_id?.name}</h6>
                            </TimelineContent>
                        </TimelineItem>
                    ))}

                    {lead_tracker?.base?.filter((l => l.type === 'Lead' && l.event === 'update')).map(t => (
                        <TimelineItem>
                            <TimelineOppositeContent color="text.secondary">
                                <h6 style={{ fontSize: '12px', color: '#0ca7fa', fontWeight: 'bold' }}> {moment(t?.createdAt).format('lll')}</h6>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                {t.event === 'create' && <IoCreateOutline size={20} color="#171636" />}
                                {t.event === 'update' && <AiOutlineEdit size={20} color="#171636" />}
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent style={{ maxWidth: '155px', backgroundColor: '#fff' }}>
                                <h6 style={{ fontSize: '12px', fontWeight: '600', marginBottom: '0px', paddingBottom: '0px' }} dangerouslySetInnerHTML={{ __html: t?.history }}></h6>
                                {/* <span style={{marginleft:'0px',fontSize:'11px',color:'#0087f5',fontWeight:'700',cursor:'pointer',paddingLeft:'0px',marginTop:'0px',paddingBottom:'2px'}} onClick={()=>navigate(`/contact_detail/${t?.history.split(':')[1]}`)}>View Details</span> */}
                                <h6 style={{ fontSize: '11px', fontWeigt: '600', color: '#858585', marginBottom: '0px', paddingBottom: '0px' }}>{t?.user_id?.name}</h6>
                            </TimelineContent>
                        </TimelineItem>
                    ))}

                    {lead_tracker?.sub?.filter((l => l.type === 'Deal')).map(t => (
                        <TimelineItem>
                            <TimelineOppositeContent color="text.secondary">
                                <h6 style={{ fontSize: '12px', color: '#0ca7fa', fontWeight: 'bold' }}> {moment(t?.createdAt).format('lll')}</h6>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                {t.event === 'create' && <IoCreateOutline size={20} color="#171636" />}
                                {t.event === 'convert' && <BiTransfer size={20} color="#171636" />}
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent style={{ maxWidth: '155px', backgroundColor: '#fff' }}>
                                <h6 style={{ fontSize: '12px', fontWeight: '600', marginBottom: '0px', paddingBottom: '0px' }} >{t?.history?.replace(/(<([^>]+)>)/gi, "").split(':')[0]}</h6>
                                {t.event === 'create' ?
                                    <>
                                        {lead_tracker?.sub?.find((l => l.type === 'Deal' && l.history.includes('Converted from lead'))) &&
                                            <span style={{ marginleft: '0px', fontSize: '11px', color: '#0087f5', fontWeight: '700', cursor: 'pointer', paddingLeft: '0px', marginTop: '0px', paddingBottom: '2px' }} onClick={() => navigate(`/deal_detail/${lead_tracker?.sub?.find((l => l.type === 'Deal' && l.history.includes('Converted from lead')))?.type_id}`)}>View Details</span>
                                        }
                                    </>
                                    :
                                    <span style={{ marginleft: '0px', fontSize: '11px', color: '#0087f5', fontWeight: '700', cursor: 'pointer', paddingLeft: '0px', marginTop: '0px', paddingBottom: '2px' }} onClick={() => navigate(`/deal_detail/${t?.type_id}`)}>View Details</span>
                                }
                                <h6 style={{ fontSize: '11px', fontWeigt: '600', color: '#858585', marginBottom: '0px', paddingBottom: '0px' }}>{t?.user_id?.name}</h6>
                            </TimelineContent>
                        </TimelineItem>
                    ))}

                    {task_tracker.map(t => (
                        <TimelineItem>
                            <TimelineOppositeContent color="text.secondary">
                                <h6 style={{ fontSize: '12px', color: '#0ca7fa', fontWeight: 'bold' }}> {moment(t?.createdAt).format('lll')}</h6>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                {t.event === 'create' && <IoCreateOutline size={20} color="#171636" />}
                                {t.event === 'update' && <AiOutlineEdit size={20} color="#171636" />}
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent style={{ maxWidth: '155px', backgroundColor: '#fff' }}>
                                <h6 style={{ fontSize: '12px', fontWeight: '900', marginBottom: '0px', paddingBottom: '2px' }} dangerouslySetInnerHTML={{ __html: t?.history }}></h6>
                                <h6 style={{ fontSize: '11px', fontWeigt: '600', color: '#858585', marginBottom: '0px', paddingBottom: '0px' }}>{t?.user_id?.name}</h6>
                            </TimelineContent>
                        </TimelineItem>
                    ))}

                    {meeting_tracker?.map(t => (
                        <TimelineItem>
                            <TimelineOppositeContent color="text.secondary">
                                <h6 style={{ fontSize: '12px', color: '#0ca7fa', fontWeight: 'bold' }}> {moment(t?.createdAt).format('lll')}</h6>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                {t.event === 'create' && <IoCreateOutline size={20} color="#171636" />}
                                {t.event === 'update' && <AiOutlineEdit size={20} color="#171636" />}
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent style={{ maxWidth: '155px', backgroundColor: '#fff' }}>
                                <h6 style={{ fontSize: '12px', fontWeight: '900', marginBottom: '0px', paddingBottom: '2px' }} dangerouslySetInnerHTML={{ __html: t?.history }}></h6>
                                <h6 style={{ fontSize: '11px', fontWeigt: '600', color: '#858585', marginBottom: '0px', paddingBottom: '0px' }}>{t?.user_id?.name}</h6>
                            </TimelineContent>
                        </TimelineItem>
                    ))}
                </Timeline>
            </Drawer>


            {loader && <Loader />}

            <Drawer placement={'bottom'} visible={visible} height={'300px'} width={'85%'} closable={false} maskClosable={false} bodyStyle={{ background: '#fff', margin: '5%', padding: '0px' }} className='scrollbarhider'>
                <div>
                    <div style={{ zIndex: 100, position: 'relative', background: '#fff' }}>
                        <div style={{ alignItems: 'center', margin: '0px 0px', marginBottom: '20px' }}>
                            <h5 style={{ fontWeight: '800', textAlign: 'center' }}>Filter</h5>

                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', background: '#f0f0f0', borderRadius: 4, padding: 5, position: 'absolute', left: '0px', top: '0px' }} onClick={() => setVisible(false)}>
                            <IoClose size={12} color='black' />
                        </div>

                        <h6 style={{ position: 'absolute', right: '0px', top: '0px', fontWeight: '800', color: '#d9d9d9' }} onClick={resetfilter}>Reset</h6>
                    </div>

                    <div style={{ width: '100%', overflow: 'scroll' }} className='scrollbarhider'>

                        <div style={{ margin: '10px 0px', paddingBottom: '10px', marginTop: '10px' }}>
                            <div>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <h6 style={{ fontWeight: '600', fontSize: '14px', opacity: 1 }}>Add Search Query</h6>
                                </div>

                                {search_lead.search_text ?
                                    <div style={{ display: 'flex', alignItems: 'center', background: '#f8f9fa', padding: '12px', borderRadius: '2px', maxWidth: '100%', position: 'relative' }}>
                                        <h6 style={{ marginBottom: '0px', fontSize: '12px' }}>{search_lead.search_text.slice(0, 20)}{search_lead.search_text.length > 20 && '...'}</h6>
                                        <IoClose style={{ marginLeft: '10px', cursor: 'pointer', position: 'absolute', right: '10px' }} onClick={() => setsearch_lead({ ...search_lead, search_text: '' })} />
                                    </div>
                                    :
                                    <div style={{ display: 'flex', width: '100%', border: '1px solid black', height: '40px', borderRadius: '4px', borderColor: '#f5f5f5', overflow: 'hidden', borderRightColor: '#fff', backgroundColor: '#fafafa' }}>
                                        <Input type={"text"} style={{ width: '100%', margin: 'auto', marginLeft: '0%', padding: '5px', border: 'none', background: '#fafafa', boxShadow: 'none' }} value={tempsearch_lead.search_text} onChange={e => settempsearch_lead({ ...tempsearch_lead, search_text: e.target.value })} />
                                        <div style={{ marginTop: '-10px' }}>
                                            <Button style={{ fontWeight: '800', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '28px 12px', background: `${main_bg}`, color: '#fff' }} onClick={() => setsearch_lead({ ...search_lead, search_text: tempsearch_lead.search_text })} >+</Button>
                                            {/* <PrimaryButton btn_name ="+" style={{fontWeight:'800'}} padding='8px 12px' onClick={()=>setsearch_lead({...search_lead,search_text:tempsearch_lead.search_text})} /> */}
                                        </div>
                                    </div>}
                            </div>
                        </div>


                        <div style={{ margin: '10px 0px', paddingBottom: '10px' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <h6 style={{ fontWeight: '600', fontSize: '14px', opacity: 1 }}>Date Interval</h6>
                                {/* {step === '3' ? <HiChevronUp size={22}  color="#c7c7c7"  onClick={()=>setstep('')} /> : <HiChevronDown size={22} color="#c7c7c7"  onClick={()=>setstep('2')}/>} */}
                            </div>

                            <div>
                                <div>
                                    {tempsearch_lead.from_date && tempsearch_lead.to_date ?
                                        <div style={{ width: '100%', background: '#fafafa', padding: '2px', height: '40px', borderRadius: '5px', position: 'relative' }}>
                                            <p style={{ paddingBottom: '0px', marginTop: '8px', paddingLeft: '10px' }}>{tempsearch_lead.from_date} - {tempsearch_lead.to_date}</p>
                                            <IoClose style={{ marginLeft: '10px', cursor: 'pointer', position: 'absolute', right: '10px', top: '12px' }} onClick={() => settempsearch_lead({ ...tempsearch_lead, from_date: '', to_date: '' })} />
                                        </div>
                                        :

                                        <Row style={{ width: '100%', margin: 'auto', display: 'flex' }}>
                                            <Col span={12} style={{ background: 'white', padding: '0px 5px', width: '100%' }}>
                                                <h6 style={{ fontSize: '12px', color: 'gray' }}>From Date</h6>
                                                {/* {tempsearch_deal.from_date ? <Tag style={{fontWeight:'600',fontSize:'13px',margin:'1.5%',padding:'4px 6px'}}>{tempsearch_deal.from_date}</Tag> : */}
                                                <DateField
                                                    onChangefun={handleChange1}
                                                    name={'from_date'}
                                                    type="1"
                                                    size={'large'}
                                                    style={{ width: '100%', margin: 'auto', marginLeft: '0%', padding: '5px', border: 'none', background: '#fafafa', boxShadow: 'none' }}
                                                    date_value={tempsearch_lead.from_date}
                                                />
                                                {/* } */}
                                            </Col>

                                            <Col span={12} style={{ background: 'white', padding: '0px 5px' }}>
                                                <h6 style={{ fontSize: '12px', color: 'gray' }}>To Date</h6>
                                                {/* {tempsearch_deal.to_date ? <Tag style={{fontWeight:'600',fontSize:'13px',margin:'1.5%',padding:'4px 6px'}}>{tempsearch_deal.to_date}</Tag> : */}
                                                <DateField
                                                    name={'to_date'}
                                                    type="1"
                                                    size={'large'}
                                                    onChangefun={handleChange1}
                                                    date_value={tempsearch_lead.to_date}
                                                    style={{ width: '100%' }} />
                                                {/* } */}
                                            </Col>

                                        </Row>}
                                </div>
                            </div>
                        </div>


                        {(!roles.includes('admin') && !roles.includes('db_head') && !roles.includes('db_user')) &&
                            <div style={{ margin: '10px 0px', paddingBottom: '10px' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <h6 style={{ fontWeight: '600', fontSize: '14px', opacity: 1 }}>Deal Stage</h6>
                                </div>

                                <div>
                                    <Row style={{ width: '100%', margin: 'auto' }}>
                                        {lead_status?.map((s, i) => (
                                            <>
                                                <Tag onClick={() => setleadstage(s.value)} color={search_lead.lead_stage.includes(s.value) ? "#0e68bf" : "default"} style={{ fontWeight: '600', fontSize: '13px', margin: '1.5%', padding: '4px 6px' }}>{s.value}</Tag>
                                            </>
                                        ))}
                                    </Row>
                                </div>
                            </div>
                        }

                        <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', width: '100%' }} onClick={applyfilter}>
                                <Button size='large' style={{ background: `${main_bg}`, border: 'none', borderRadius: '2px', color: 'white', fontWeight: '500', display: 'flex', justifyContent: 'center', justifyItems: 'center', width: '100%' }}>Apply Filter</Button>
                            </div>
                        </div>



                    </div>
                </div>
            </Drawer>

            {/* Contact Picker modal  */}
            <Modal visible={isModalVisible} style={{ maxWidth: '320px' }} onCancel={() => setIsModalVisible(false)} centered footer={false}>
                <h1 style={{ fontSize: '16px', fontWeight: 'bold' }}>Choose Contact</h1>

                <Select
                    showSearch
                    placeholder="Select Contact"
                    optionFilterProp="children"
                    onChange={handleChangeContact}
                    size='large'
                    filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    style={{ width: '100%' }}
                >
                    {
                        contacts.map((contact) => (
                            <>
                                <Option key={contact.id} value={contact.id}><div>{contact.contact_name}-<strong>{contact.company_name}</strong></div></Option>
                            </>
                        ))
                    }
                </Select>

                <div>
                    <PrimaryButton btn_name="Convert to Lead" onClick={() => convert_tolead()}></PrimaryButton>
                </div>

            </Modal>

            {/* select contact to create lead  */}
            <Modal visible={visible1} onCancel={() => setVisible1(false)} centered footer={false} style={{ maxWidth: '280px' }}>
                <h1 style={{ fontSize: '16px', fontWeight: 'bold' }}>Select Contact </h1>

                <Select
                    showSearch
                    placeholder="Select Contact"
                    optionFilterProp="children"
                    onChange={handleChange}
                    size='large'
                    filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    style={{ width: '100%' }}
                >
                    {
                        contacts.map((contact) => (
                            <Option key={contact.id} value={contact.id}><div>{contact.contact_name}</div></Option>
                        ))
                    }
                </Select>

                <div onClick={() => convert_tolead()}>
                    <PrimaryButton btn_name="Convert to Lead" ></PrimaryButton>
                </div>

            </Modal>

            {/* assign created lead to members */}
            <Modal visible={visible2} onCancel={() => setVisible2(false)} footer={false} width={300} style={{ width: '200px' }} centered>
                <h6>Assigned Lead </h6>
                <Row>
                    <Col span={24}>
                        <div style={{ width: '96%', margin: ' 10px auto', }}>
                            {(roles.find(r => r === 'admin') || roles.find(r => r === 'db_head') || roles.find(r => r === 'db_user') || roles.find(r => r === 'db_sub_user')) && <h6 style={{ fontSize: '12px' }}>Select Department</h6>}
                            {(roles.includes('admin') || roles.includes('db_head') || roles.find(r => r === 'db_user') || roles.find(r => r === 'db_sub_user')) &&
                                <>
                                    {(roles.includes === 'admin' || roles.includes === 'db_head' || roles.includes === 'db_user' || roles.includes === 'db_sub_user' || department[0].department_name === 'Database Team') ?
                                        <>
                                            <Select
                                                value={selected_department.department_name}
                                                disabled
                                                showSearch
                                                placeholder="Select Department"
                                                optionFilterProp="children"
                                                onChange={(v) => handleChangefun(v, 'department_id')}
                                                size='medium'
                                                filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                style={{ width: '100%' }}
                                            >
                                                {
                                                    departement_arr.map((d) => (
                                                        <Option disabled
                                                            key={d.id} value={d.id}><div>{d.value}</div></Option>
                                                    ))
                                                }
                                            </Select>
                                        </>

                                        :

                                        <Select
                                            value={selected_department.department_name}
                                            disabled
                                            showSearch
                                            placeholder="Select Department"
                                            optionFilterProp="children"
                                            onChange={(v) => handleChangefun(v, 'department_id')}
                                            size='medium'
                                            filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            style={{ width: '100%' }}
                                        >
                                            {
                                                departement_arr.map((d) => (
                                                    <Option
                                                        key={d.id} value={d.id}><div>{d.value}</div></Option>
                                                ))
                                            }
                                        </Select>

                                    }
                                </>
                            }
                        </div>
                    </Col>

                    {(roles.includes('admin') || roles.includes('db_head') || roles.includes('db_user') || roles.includes('db_sub_user')) &&
                        <Col span={24}>
                            <div style={{ width: '96%', margin: 'auto' }}>
                                <h6 style={{ fontSize: '12px' }}>Select HOD</h6>

                                <Select
                                    showSearch
                                    value={selected_employeee.name}
                                    placeholder="Select HOD"
                                    optionFilterProp="children"
                                    onChange={(v) => handleChangefun(v, 'hod_id')}
                                    size='medium'
                                    filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    style={{ width: '100%' }}
                                >
                                    {
                                        hod_arr.map((h) => (
                                            <Option key={h?.id} value={h?.id}><div>{h?.name}</div></Option>
                                        ))
                                    }
                                </Select>
                            </div>

                        </Col>
                    }

                    {(roles.includes('hod') && !roles.includes('db_head') && !roles.includes('db_user') && !roles.includes('db_sub_user')) && !roles.includes('admin') &&
                        <Col span={24}>
                            <div style={{ width: '96%', margin: 'auto' }}>
                                <h6 style={{ fontSize: '12px' }}>Select Employee/Manager</h6>

                                <Select
                                    showSearch
                                    placeholder="Select Employee/Manager"
                                    optionFilterProp="children"
                                    onChange={(v) => handleChangefun(v, 'manager_id')}
                                    size='medium'
                                    filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    style={{ width: '100%' }}
                                >
                                    {
                                        managers_arr.map((h) => (
                                            <Option key={h?.id} value={h?.id}><div>{h?.name}</div></Option>
                                        ))
                                    }
                                </Select>
                            </div>

                        </Col>}

                    {(roles.includes('manager') && !roles.includes('hod') && !roles.includes('db_head') && !roles.includes('db_user') && !roles.includes('db_sub_user')) && !roles.includes('admin') &&
                        <Col span={24}>
                            <div style={{ width: '96%', margin: 'auto' }}>
                                <h6 style={{ fontSize: '12px' }}>Select Employee/Manager</h6>

                                <Select
                                    showSearch
                                    placeholder="Select Employee/Manager"
                                    optionFilterProp="children"
                                    onChange={(v) => handleChangefun(v, 'manager_id')}
                                    size='medium'
                                    filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    style={{ width: '100%' }}
                                >
                                    {
                                        employee_arr?.map((h) => (
                                            <Option key={h?.id} value={h?.id}><div>{h?.name}</div></Option>
                                        ))
                                    }
                                </Select>
                            </div>

                        </Col>}

                    <Col span={24} >
                        <div style={{ width: '96%', margin: 'auto', marginTop: '10px' }}>
                            <h6 style={{ fontSize: '12px' }}>Remarks</h6>
                            <TextArea rows={4} value={assign_data.remarks} onChange={(e) => setassign_data({ ...assign_data, remarks: e.target.value })} />
                        </div>
                    </Col>

                    {/* <div style={{width:'96%',margin:'auto'}}>
                     <h6 style={{fontSize:'12px'}}>Add Note</h6>
                     <TextArea />
                     </div> */}

                </Row>



                <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', marginRight: '5px' }} onClick={approvelead}>
                    <PrimaryButton btn_name="Assign lead" />
                </div>

                {/* <div style={{display:'flex',alignItems:'flex-end',justifyContent:'flex-end',marginRight:'5px'}} onClick={approvelead1}>
                 <PrimaryButton btn_name="Assign lead" /> 
                 </div> */}
            </Modal>

            <Modal visible={visible4} style={{ maxWidth: '280px' }} footer={null} centered onCancel={() => setVisible4(false)}>
                <div style={{ borderTopWidth: '4px', borderTopColor: '#1c89ff' }}>

                    <h4 style={{ fontSize: '15px', fontWeight: '900' }}>Are you sure</h4>
                    <h3 style={{ fontSize: '13px', marginTop: '10px' }}>Do you really want to accept the lead assigned to you </h3>
                    <div style={{ borderLeftWidth: '8px', borderLeftColor: '#2bfc39', background: '#e8ffea', padding: '10px' }}>
                        <h3 style={{ fontSize: '12px' }}>Be sure before confirmation this action cannot be reverted back </h3>
                    </div>

                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                        <Button style={{ width: '100%', background: '#fff', color: 'black', marginRight: '2%' }} onClick={() => { setselected_lead(); setVisible4(false) }}>Cancel</Button>
                        <Button style={{ width: '100%', background: `${main_bg}`, borderRadius: '3px', overflow: 'hidden', color: 'white', marginLeft: '2%' }} onClick={() => { confirmingapproval(selected_lead); setselected_lead() }}>Accept</Button>
                    </div>
                </div>
            </Modal>

            <Modal visible={visible5} style={{ maxWidth: '280px' }} footer={null} centered onCancel={() => { setselected_lead(); setVisible5(false) }}>
                <div style={{ borderTopWidth: '4px', borderTopColor: '#1c89ff' }}>

                    <h4 style={{ fontSize: '15px', fontWeight: '900' }}>Are you sure</h4>
                    <h3 style={{ fontSize: '13px', marginTop: '10px' }}>Do you really want to reject the lead assigned to you </h3>
                    <div style={{ borderLeftWidth: '8px', borderLeftColor: '#ffeae8', background: '#ffeae8', padding: '10px' }}>
                        <h3 style={{ fontSize: '12px' }}>Be sure before confirmation this action cannot be reverted back </h3>
                    </div>

                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                        <Button style={{ width: '100%', background: '#fff', color: 'black', marginRight: '2%' }} onClick={() => { setselected_lead(); setVisible5(false) }}>Cancel</Button>
                        <Button style={{ width: '100%', background: '#db240f', color: 'white', marginLeft: '2%', borderRadius: '3px', overflow: 'hidden' }}>I'm Sure</Button>
                    </div>
                </div>
            </Modal>

            <Modal visible={remarkvisible6} style={{ maxWidth: '300px' }} footer={null} centered onCancel={() => setremarkvisible6(false)}>
                <div style={{ borderTopWidth: '4px', borderTopColor: '#1c89ff' }}>

                    <h4 style={{ fontSize: '15px', fontWeight: '900' }}>Remark for Assigned Lead </h4>
                    <h3 style={{ fontSize: '12px', marginTop: '10px', color: '#4f4f4f', padding: '4px' }}>{remark}</h3>
                    <div style={{ borderLeftWidth: '8px', borderLeftColor: '#ffeae8', background: '#ffeae8', padding: '10px' }}>
                        <h3 style={{ fontSize: '12px' }}> <strong> Note :</strong> This is the remark added for the assign lead </h3>
                    </div>

                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                        <Button style={{ width: '100%', background: '#fff', color: 'black', marginRight: '2%', display: 'hidden', opacity: 0 }} >Cancel</Button>
                        <Button style={{ width: '100%', background: `${main_bg}`, color: 'white', marginLeft: '2%', border: 'none', maxWidth: '80px' }} onClick={() => setremarkvisible6(false)}>Close</Button>
                    </div>
                </div>
            </Modal>

            <div style={{ width: '100%', height: '100%', backgroundColor: 'white' }}>
                <MobileTableHeader setIsModalVisible={() => { addleadfunc(); setIsModalVisible(true) }} assigned_lead={assigned_lead} roles={roles} setvisiblefun2={() => assignLeadfunc()} setvisiblefun21={() => reassignLeadfunc()} selected_lead={selected_lead} btn_val="Add Lead" type="Lead" addfun={() => gotoleadcreatefun()} length={count} />
            </div>

            <div style={{ backgroundColor: 'white', width: '100%', minWidth: '100vw', height: '100%' }}>
                <SubViewLeads
                    leaddetailfunc={v => leaddetailfunc(v)}
                    pagination={pagination}
                    leads={leads}
                    page={page}
                    search_lead={search_lead}
                    acceptassignedlead={(v, v1) => acceptassignedlead(v, v1)}
                    assigned_lead={assigned_lead}
                    setassignedlead={(v) => setassigned_lead(v)}
                    setselected_assigned_lead={(v) => setselected_assigned_lead(v)}
                    setactivetab={v => setactivetab(v)}
                    setactivetab1={v => setactivetab1(v)}
                    Assigneddatas={Assigneddatas}
                    roles={roles}
                    setvisiblefun={() => setVisible(true)}
                    selected_lead={selected_lead}
                    setselected_lead_fun={(v) => setselected_lead(v)}
                    editlead={(v) => editleadfunc(v)}
                    setlength={(v) => setcount(v)}
                    gotodealcreatefunc={(v) => gotodealcreatefunc(v)}
                    getreminderfun={(v) => getReminder(v)}
                    reminder={reminder}
                    remarkfunc={(v) => setremarkfunc(v)}
                    tracker={v => tracker(v)}
                    setpage={v => setpage(v)}
                    paginateddata={(v) => getleads(v)}
                    paginateddata1={(v) => getassignedLeads(v)}
                    paginateddata2={(v) => getassignedtomeLeads(v)} />
            </div>
        </div>
    )
}

export default ViewLead
