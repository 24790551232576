import React from 'react'
import './FooterMain.css'
import { Row, Col } from 'antd'
import { FaFacebookSquare, FaWhatsappSquare } from 'react-icons/fa'
import { AiFillInstagram, AiFillYoutube } from 'react-icons/ai'
import { ImLinkedin } from 'react-icons/im'

function FooterMain() {
  return (
    <div style={{backgroundColor: '#292961', padding: '30px 0px',color:'white' }} className="footer-main-color">
      <Row style={{ width: '70%', margin: 'auto', padding: '20px 0px' }}>

        <Col xs={24} xl={8} className="section_footer">
          <h6 className="footer_heading">Apps</h6>
          <p className="footer_text">FCPL CRM </p>
          <p className="footer_text">FCPL MOBILE APP </p>
        </Col>

        <Col xs={24} xl={8} className="section_footer">
          <h6 className="footer_heading">Future Works</h6>
          <p className="footer_text">Project Managment Software </p>
          <p className="footer_text">Hr services Tool </p>
        </Col>


        <Col xs={24} xl={8} className="section_footer">
          <h6 className="footer_heading">Contact</h6>
          <p className="footer_text">+91 9845062227</p>
          <p className="footer_text">Fidelitus Corp,
            Brigade Software Park,
            No. 42, Ground Floor, B Block,
            27th Cross, BSK 2nd Stage,
            Bangalore – 560070, Karnataka.</p>
          <p className="footer_text link">info@fidelituscorp.com</p>
          <ul className="ul_list">
            <li><FaFacebookSquare size={20} color="#757575" /></li>
            <li><AiFillInstagram size={23} color="#757575" /></li>
            <li><ImLinkedin size={19} color="#757575" /></li>
            <li><FaWhatsappSquare size={20} color="#757575" /></li>
            <li><AiFillYoutube size={26} color="#757575" /></li>
          </ul>
        </Col>

        {/* <Col xs={24} xl={7}>
          <h6 className="footer_heading">About</h6>
        </Col> */}
      </Row>




      <div className="footer_policy">
        <div className="footer_policy_section1">
          <p>Copyright © 2022 Fcpl Inc. All rights reserved.</p>

          <p className="l1">Privacy Policy</p>
          <p className="l1">Terms of Use</p>
        </div>
        <p>India</p>
      </div>
    </div>
  )
}

export default FooterMain