import React from 'react'
import npp from '../../../images/nopermission.webp.jpg'
function PageNotFound(){
    return(
        <div style={{display:'grid',justifyContent:'center',alignItems:'center',marginTop:'100px',maxHeight:'90vh'}}>
              <img src={npp} />
              <h6 style={{textAlign:'center',fontSize:'22px',fontWeight:'500'}}>Permission Denied </h6>
              <h6 style={{textAlign:'center',color:'#dedede'}}>I hope you don't have permission to access this route</h6>
        </div>
    )
}


export default PageNotFound