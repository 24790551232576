import { ACTIVETAB } from '../actionTypes/BdAction';

const initialstate = {
    activetab : '1'
}


const BdReducer = (state = initialstate,action)=>{
    switch (action.type) {
        case ACTIVETAB:
            state.activetab = action.payload
            return {...state};
        default:
            return state    
    }
    
}

export default BdReducer