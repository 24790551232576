import React, { useEffect,useState } from 'react';
import { BoldText1 } from '../../../helpers/constants/ConstantsText';
import FmsDashboardMainRoot from '../FmsDashboardMainRoot';
import { useSelector } from 'react-redux';
import { Avatar } from '@material-ui/core';
import { theme_color } from '../../../helpers/constants/ConstantColor';
import '../../../components/fonts/fonts.css'
import './FmsComponents.css'
import axios from 'axios';
import { authHeader } from '../../../helpers/usertoken/UserToken';
import { GrCompliance } from "react-icons/gr";
import { LuBuilding } from "react-icons/lu";
import { GoShieldLock } from "react-icons/go";
import { FiUserCheck } from "react-icons/fi";
import { FiUserMinus } from "react-icons/fi";
import { CgMoreVerticalR } from "react-icons/cg";
import { RiShieldUserLine } from "react-icons/ri";
import { LuTicket } from "react-icons/lu";
import { TbTicketOff } from "react-icons/tb";
import { GrScan } from "react-icons/gr";
import ReactApexChart from 'react-apexcharts';


function FmsDashboardMain(){


    const name = useSelector(state=>state.Auth.name)
    const [dashboard,setdashboard] = useState({})

    const [chartOptions1,setchartOptions1] = useState({
      series: [],
  
      chart: {
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded',
          dataLabels: {
            position: 'top', // top, center, bottom
          },
          
        },
      },
      dataLabels: {
        enabled: false
      },
      grid:{
        show: true,
        borderColor: '#fafafa',
      },
    
      stroke: {
        // show: true,
        width: 1,
        
        curve: 'smooth',
        colors: ['transparent']
      },
      xaxis: {
        categories:[],
      },
      yaxis: {
        title: {
          text: 'Count'
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return  val
          }
        }
      },
      fill:['#027cd1']
    })


    useEffect(()=>{
        getdata()
    },[])

    function getdata(){
        axios.get('fms/basicemployee/dashboard_data',authHeader)
        .then((res)=>{
          let series = []
          let category = []
          let deployeed_count = res?.data?.data?.deployeed_count?.filter((d)=>d?.count > 0)
          deployeed_count.forEach((d)=>{
            series.push(d?.count)
            category.push(d?.client_name !== undefined ? d?.client_name : '')
          })

          // console.log("series here",series)
          console.log("category here",category)

          setchartOptions1({
            ...chartOptions1,
            series:[{name:'count',data:series}],
            xaxis:{
              categories:category
            }
          })
          setdashboard(res?.data?.data)
        }).catch((err)=>{
          if(err?.response?.status == 401){
            // dispatch(crm_logout())
          }
        })
      }


      // console.log("chartOptions1",chartOptions1)


    return(
          <FmsDashboardMainRoot>
            <div style={{width:'98%'}}>
              <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between',cursor:'pointer'}}>
              <h6 style={{fontWeight:'bolder',fontSize:'14px',margin:'0px 12px',fontFamily:'Archivo Black',}}>DASHBOARD</h6>
                <div style={{display:'flex',flexDirection:'row',alignItems:'center',}}>
                <Avatar style={{width:22,height:22,fontSize:10,background:theme_color,fontWeight:'bold',marginLeft:'5px'}}>{name.slice(0,1)}</Avatar>
                <h6 style={{fontSize:'11.5px',fontWeight:'600',marginBottom:'0px',marginLeft:'10px'}}>{name}</h6>
                </div>
              </div>

              <div className='row mt-3 m-2 mt-4 ' style={{border:'1px solid #f5f5f5'}}>

                <div className='col-md-3' style={{borderRadius:'3px',display:'flex',alignItems:'center',width:'16%',borderRight:'1px solid #f5f5f5',padding:'15px 0px',margin:'auto'}}>
                    <div style={{marginLeft:'10px',display:'flex',flexDirection:'row'}}>
                      <GrCompliance size={24} color={'#6bbf8d'} />
                      <div style={{marginLeft:'10px'}}>
                        <BoldText1 val="Compliance Form" marginfun={'0px'} fontWeight='500' fontSize={'12px'} col = '#616161'/>
                        <h6 style={{fontFamily:'Archivo Black',fontWeight:'bolder',marginBottom:'0px',marginTop:'5px'}}>{dashboard?.complaince_form}</h6>
                      </div>
                    </div>
                </div>

                <div className='col-md-3' style={{display:'flex',alignItems:'center',width:'16%',borderRight:'1px solid #f5f5f5',padding:'15px 0px',margin:'auto'}}>
                    <div style={{marginLeft:'10px',display:'flex',flexDirection:'row'}}>
                      <GrCompliance size={24} color={'#e66c6c'} />
                      <div style={{marginLeft:'10px'}}>
                        <BoldText1 val="Non Compliance Form" marginfun={'0px'} fontWeight='500' fontSize={'12px'} col = '#616161'/>
                        <h6 style={{fontFamily:'Archivo Black',fontWeight:'bolder',marginBottom:'0px',marginTop:'5px'}}>{dashboard?.non_compliance_form}</h6>
                      </div>
                    </div>
                </div>

                <div className='col-md-3' style={{display:'flex',alignItems:'center',width:'16%',borderRight:'1px solid #f5f5f5',padding:'15px 0px',margin:'auto'}}>
                    <div style={{marginLeft:'10px',display:'flex',flexDirection:'row'}}>
                      <LuBuilding size={24} color={'#6f96de'} />
                      <div style={{marginLeft:'10px'}}>
                        <BoldText1 val="Active Clients" marginfun={'0px'} fontWeight='500' fontSize={'12px'} col = '#616161'/>
                        <h6 style={{fontFamily:'Archivo Black',fontWeight:'bolder',marginBottom:'0px',marginTop:'5px'}}>{dashboard?.active_clients}</h6>
                      </div>
                    </div>
                </div>

                <div className='col-md-3' style={{display:'flex',alignItems:'center',width:'16%',borderRight:'1px solid #f5f5f5',padding:'15px 0px',margin:'auto'}}>
                    <div style={{marginLeft:'10px',display:'flex',flexDirection:'row'}}>
                      <LuBuilding size={24} color={'#97bf32'} />
                      <div style={{marginLeft:'10px'}}>
                        <BoldText1 val="InActive Clients" marginfun={'0px'} fontWeight='500' fontSize={'12px'} col = '#616161'/>
                        <h6 style={{fontFamily:'Archivo Black',fontWeight:'bolder',marginBottom:'0px',marginTop:'5px'}}>{dashboard?.in_active_clients}</h6>
                      </div>
                    </div>
                </div>

             

                <div className='col-md-3' style={{display:'flex',alignItems:'center',width:'16%',borderRight:'1px solid #f5f5f5',padding:'15px 0px',margin:'auto'}}>
                    <div style={{marginLeft:'10px',display:'flex',flexDirection:'row'}}>
                      <FiUserCheck size={24} color={'#88d1cc'} />
                      <div style={{marginLeft:'10px'}}>
                        <BoldText1 val="Active Employee" marginfun={'0px'} fontWeight='500' fontSize={'12px'} col = '#616161'/>
                        <h6 style={{fontFamily:'Archivo Black',fontWeight:'bolder',marginBottom:'0px',marginTop:'5px'}}>{dashboard?.active_employee}</h6>
                      </div>
                    </div>
                </div>

                <div className='col-md-3' style={{display:'flex',alignItems:'center',width:'16%',padding:'15px 0px',margin:'auto'}}>
                    <div style={{marginLeft:'10px',display:'flex',flexDirection:'row'}}>
                      <FiUserMinus size={24} color={'#cfd164'} />
                      <div style={{marginLeft:'10px'}}>
                        <BoldText1 val="InActive Employee" marginfun={'0px'} fontWeight='500' fontSize={'12px'} col = '#616161'/>
                        <h6 style={{fontFamily:'Archivo Black',fontWeight:'bolder',marginBottom:'0px',marginTop:'5px'}}>{dashboard?.exit_employee}</h6>
                      </div>
                    </div>
                </div>

               

              </div>

              <div className='row m-2 ' style={{border:'1px solid #f5f5f5'}}>

              <div className='col-md-3' style={{display:'flex',alignItems:'center',width:'16%',borderRight:'1px solid #f5f5f5',padding:'15px 0px',margin:'auto'}}>
                    <div style={{marginLeft:'10px',display:'flex',flexDirection:'row'}}>
                      <GoShieldLock size={24} color={'#e3dd84'} />
                      <div style={{marginLeft:'10px'}}>
                        <BoldText1 val="Employee Designation" marginfun={'0px'} fontWeight='500' fontSize={'12px'} col = '#616161'/>
                        <h6 style={{fontFamily:'Archivo Black',fontWeight:'bolder',marginBottom:'0px',marginTop:'5px'}}>{dashboard?.employee_designations}</h6>
                      </div>
                    </div>

              </div>

              <div className='col-md-3' style={{display:'flex',alignItems:'center',width:'16%',borderRight:'1px solid #f5f5f5',padding:'15px 0px',margin:'auto'}}>
                    <div style={{marginLeft:'10px',display:'flex',flexDirection:'row'}}>
                      <RiShieldUserLine size={24} color={'#ffc98c'} />
                      <div style={{marginLeft:'10px'}}>
                        <BoldText1 val="FmsIn House Employee" marginfun={'0px'} fontWeight='500' fontSize={'12px'} col = '#616161'/>
                        <h6 style={{fontFamily:'Archivo Black',fontWeight:'bolder',marginBottom:'0px',marginTop:'5px'}}>{dashboard?.fms_in_house_employee}</h6>
                      </div>
                    </div>
              </div>

              <div className='col-md-3' style={{display:'flex',alignItems:'center',width:'16%',borderRight:'1px solid #f5f5f5',padding:'15px 0px',margin:'auto'}}>
                  <div style={{marginLeft:'10px',display:'flex',flexDirection:'row'}}>
                    <CgMoreVerticalR size={24} color={'#ad6250'} />
                    <div style={{marginLeft:'10px'}}>
                      <BoldText1 val="FMS Roles" marginfun={'0px'} fontWeight='500' fontSize={'12px'} col = '#616161'/>
                      <h6 style={{fontFamily:'Archivo Black',fontWeight:'bolder',marginBottom:'0px',marginTop:'5px'}}>{dashboard?.fms_roles}</h6>
                    </div>
                  </div>
              </div>

              <div className='col-md-3' style={{display:'flex',alignItems:'center',width:'16%',borderRight:'1px solid #f5f5f5',padding:'15px 0px',margin:'auto'}}>
                  <div style={{marginLeft:'10px',display:'flex',flexDirection:'row'}}>
                    <LuTicket size={24} color={'#31a4eb'} />
                    <div style={{marginLeft:'10px'}}>
                      <BoldText1 val="Complaint Pending" marginfun={'0px'} fontWeight='500' fontSize={'12px'} col = '#616161'/>
                      <h6 style={{fontFamily:'Archivo Black',fontWeight:'bolder',marginBottom:'0px',marginTop:'5px'}}>{dashboard?.fms_tickets_pending}</h6>
                    </div>
                  </div>
              </div>

              <div className='col-md-3' style={{display:'flex',alignItems:'center',width:'16%',borderRight:'1px solid #f5f5f5',padding:'15px 0px',margin:'auto'}}>
                  <div style={{marginLeft:'10px',display:'flex',flexDirection:'row'}}>
                    <TbTicketOff size={24} color={'#9e40e6'} />
                    <div style={{marginLeft:'10px'}}>
                      <BoldText1 val="Complaint Resolved" marginfun={'0px'} fontWeight='500' fontSize={'12px'} col = '#616161'/>
                      <h6 style={{fontFamily:'Archivo Black',fontWeight:'bolder',marginBottom:'0px',marginTop:'5px'}}>{dashboard?.fms_tickets_resolved}</h6>
                    </div>
                  </div>
              </div>

              <div className='col-md-3' style={{display:'flex',alignItems:'center',width:'16%',padding:'15px 0px',margin:'auto'}}>
                  <div style={{marginLeft:'10px',display:'flex',flexDirection:'row'}}>
                    <GrScan size={24} color={'#3247bf'} />
                    <div style={{marginLeft:'10px'}}>
                      <BoldText1 val="Checkpoint Scan" marginfun={'0px'} fontWeight='500' fontSize={'12px'} col = '#616161'/>
                      <h6 style={{fontFamily:'Archivo Black',fontWeight:'bolder',marginBottom:'0px',marginTop:'5px'}}>{dashboard?.client_scan}</h6>
                    </div>
                  </div>
              </div>
               
              
              </div>

              <div className={`m-2`} style={{border:'1px solid #f5f5f5',width:'100%'}}>
                <h1 style={{fontSize:'13px',fontWeight:'bolder',padding:'10px 20px'}}>Client Based Deployment</h1>
                <ReactApexChart options={chartOptions1} size={'10'} series={chartOptions1.series} type="bar"  height={240} />
              </div>
              

            </div>

          </FmsDashboardMainRoot>
    )
}


export default FmsDashboardMain