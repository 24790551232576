import React, { useEffect, useState } from 'react'
import { Input, Checkbox, Button } from 'antd';
import { VscEye, VscEyeClosed } from 'react-icons/vsc'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { login, roles, user_info, departments, designations, phone_number } from '../../../redux/actionPayload/AuthPayload';
import { useNavigate } from 'react-router-dom'
import Inputerror from '../../../components/errors/Inputerror';
import toast, { Toaster }  from 'react-hot-toast'

function Login() {

    const [password_visible, setpassword_visible] = useState(true)
    const [data, setdata] = useState({ user_id: '', password: '', fcm_token: '' })
    const [error, seterror] = useState({ fcpl_iderr: '', password_err: '' })

    const device_token = useSelector(state => state.Auth.device_token)

    // console.log('device_token', device_token)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const authheader = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
    }

    useEffect(() => {
        if (data.user_id.length > 1) {
            seterror({ ...error, fcpl_iderr: '' })
        }

    }, [])

    function signinfun() {
        if (!data.user_id) {
            seterror({ ...error, fcpl_iderr: 'This field is required' })
        } 
        // else if (data.user_id.slice(0, 5).toLowerCase() !== 'fcpl_' && data.user_id.slice(0, 4).toLowerCase() !== 'fms_') {
        //     seterror({ ...error, fcpl_iderr: 'The fcpl_id should start with fcpl_ or FCPL_ or FMS_ or fms_' })
        // } 
        else if (!data.password) {
            seterror({ ...error, fcpl_iderr: '', password_err: 'Password field is required' })
        } else {
            seterror({ ...error, fcpl_iderr: '', password_err: '' })
            data['fcm_token'] = device_token
            axios.post('fms/fms_in_house_employee/inhouse_employee_login',data,authheader)
            .then((res) => {
                        if (res.status === 200) {
                            let data = res.data
                            getmobilenumber(res.data.id, res.data.token)
                            let add_data = {
                                name: res.data.name,
                                email: res.data.official_email,
                                id: res.data.id,
                                employee_id: res.data.employee_id,
                            }
                            localStorage.setItem("access_token", res.data.token)
                            dispatch(user_info(add_data))
                            dispatch(login(res.data.token))
                            dispatch(departments(res.data.department))
                            dispatch(designations(res.data.designation))
                            dispatch(roles(res.data.roles))
                            let r = res.data.roles

                            if(res.data.appAccess){
                                navigate('/fms/fms_dashboard')
                            } 
                            // console.log('roles na here',r)
                            // // console.log('kpp test',(!r.includes('admin') && !r.includes('hod') && !r.includes('manager') && !r.includes('db_head') && !r.includes('db_user') && !r.includes('db_sub_user') && !r.includes('bd_user')))
                            // // console.log('kpp test 1',(r.includes('fms_user') || r.includes('fms_hr')))
                            // if (r.includes('admin') || r.includes('hod') || r.includes('manager') || r.includes('db_head') || r.includes('db_user') || r.includes('db_sub_user') || r.includes('bd_user') || r.includes('fms_user') || r.includes('fms_hr')) {
                            //     if(r.includes('admin') || r.includes('hod') || r.includes('manager') || r.includes('db_head') || r.includes('db_user') || r.includes('db_sub_user') || r.includes('bd_user')){
                            //         navigate('/bd_dashboard')
                            //     }else if((!r.includes('admin') && !r.includes('hod') && !r.includes('manager') && !r.includes('db_head') && !r.includes('db_user') && !r.includes('db_sub_user') && !r.includes('bd_user'))  && r.includes('fms_user') || r.includes('fms_hr')){
                            //         navigate('/fms/fms_dashboard')
                            //     }
                            // } else if (r.includes('controller') && !r.includes('admin') && !r.includes('hod') && !r.includes('manager') && !r.includes('db_head') && !r.includes('db_user') && !r.includes('db_sub_user')) {
                            //     navigate('/controller_dashboard')
                            // }
                        }
                    }).catch((err) => {
                                if (err?.response?.data?.errors) {
                                    toast.error(err?.response?.data?.errors)
                                }
                                console.log('err me', err.response)
                            })
            // axios.post('user/login', data, authheader)
            //     .then((res) => {
            //         if (res.status === 200) {
            //             getmobilenumber(res.data.id, res.data.token)
            //             let add_data = {
            //                 name: res.data.name,
            //                 email: res.data.official_email,
            //                 id: res.data.id,
            //                 employee_id: res.data.employee_id,
            //             }
            //             localStorage.setItem("access_token", res.data.token)
            //             dispatch(user_info(add_data))
            //             dispatch(login(res.data.token))
            //             dispatch(departments(res.data.department))
            //             dispatch(designations(res.data.designation))
            //             dispatch(roles(res.data.roles))
            //             let r = res.data.roles
            //             console.log('roles na here',r)
            //             // console.log('kpp test',(!r.includes('admin') && !r.includes('hod') && !r.includes('manager') && !r.includes('db_head') && !r.includes('db_user') && !r.includes('db_sub_user') && !r.includes('bd_user')))
            //             // console.log('kpp test 1',(r.includes('fms_user') || r.includes('fms_hr')))
            //             if (r.includes('admin') || r.includes('hod') || r.includes('manager') || r.includes('db_head') || r.includes('db_user') || r.includes('db_sub_user') || r.includes('bd_user') || r.includes('fms_user') || r.includes('fms_hr')) {
            //                 if(r.includes('admin') || r.includes('hod') || r.includes('manager') || r.includes('db_head') || r.includes('db_user') || r.includes('db_sub_user') || r.includes('bd_user')){
            //                     navigate('/bd_dashboard')
            //                 }else if((!r.includes('admin') && !r.includes('hod') && !r.includes('manager') && !r.includes('db_head') && !r.includes('db_user') && !r.includes('db_sub_user') && !r.includes('bd_user'))  && r.includes('fms_user') || r.includes('fms_hr')){
            //                     navigate('/fms/fms_dashboard')
            //                 }
            //             } else if (r.includes('controller') && !r.includes('admin') && !r.includes('hod') && !r.includes('manager') && !r.includes('db_head') && !r.includes('db_user') && !r.includes('db_sub_user')) {
            //                 navigate('/controller_dashboard')
            //             }
            //         }
            //     })
            //     .catch((err) => {
            //         if (err?.response?.data?.errors) {
            //             toast.error(err?.response?.data?.errors)
            //         }
            //         console.log('err me', err.response)
            //     })
        }
    }

    function handleChange(e) {
        seterror({ ...error, fcpl_iderr: '' })
        if (e.target.name === 'user_id') {
            seterror({ ...error, fcpl_iderr: '' })
            // if (e.target.value.slice(0, 5).toLowerCase() === 'fcpl_' || data.user_id.slice(0, 4).toLowerCase() === 'fms_') {
            //     seterror({ ...error, fcpl_iderr: '' })
            // } else if (data.user_id.slice(0, 5).toLowerCase() !== 'fcpl_' && data.user_id.slice(0, 4).toLowerCase() !== 'fms_') {
            //     seterror({ ...error, fcpl_iderr: 'The fcpl_id should start with fcpl_ or FCPL_ or fms_ or FMS_' })
            // } 
            // else {
            //     seterror({ ...error, fcpl_iderr: '' })
            // }
        }
        setdata({ ...data, [e.target.name]: e.target.value })
    }

    function getmobilenumber(v, v1) {
        let authheader = {
            headers: {
                'content-type': 'application.json',
                'Authorization': `Bearer ${v1}`
            }
        }
        axios.get(`user/get/${v}`, authheader)
            .then((res) => {
                console.log('logged in here', res.data)
                dispatch(phone_number(res?.data?.datas[0]?.official_phone))
            })
    }

    // `url(${bgImg})`
    // display: 'grid', width: '100%', height: '100%', justifyContent: 'center', alignContent: 'center', background:'white', position: 'fixed', zIndex: 0, left: '0px', top: '0px', backgroundSize: 'cover' 
    return (
        <div style={{ display: 'grid', width: '100%', height: '100%', justifyContent: 'center', alignContent: 'center', background:`fff`, position: 'fixed', zIndex: 0, left: '0px', top: '0px', backgroundSize: 'cover' }}>
            <Toaster />
            {/* <div style={{display:'flex',alignItems:'center',background:'blue',justifyItems:'center'}}>
                <div style={{flex:0.4,width:'100%',justifyContent:'center',background:'red'}}>
                    <div style={{minWidth:'80%',maxWidth:'80%',marginLeft:'10%',padding:'10%',background:'#fff'}}>
                        <h1>Hello</h1>
                        </div>
                </div>
                <div style={{flex:0.6,background:'red',height:'100vh'}}>
                <h1>Hello</h1>
                    
                </div>
            </div> */}

            <div style={{ background: 'white', display: 'flex', width: '100%', height: '100%', margin: 'auto', boxShadow: '0px 0px 13px #f5f5f5', zIndex: 1 }} >
                <div style={{ width: '300px',height:'400px', padding: '0 0%', }} className="p-4" >
                    <h4 style={{fontWeight:'800',fontSize:'15px',marginTop:'20px'}}>SIGN IN</h4>
                    <h6 style={{fontSize:'13px'}}>To access FCPL CRM</h6>
                    <div style={{ width: '100%' }} className="mt-3">
                        <h6 style={{fontWeight:'500',fontSize:'12px'}}>FCPL Id</h6>
                        <Input
                            name="user_id"
                            size="medium"
                            placeholder=""
                            style={{ width: '100%', zIndex: 10 }}
                            class="input"
                            onChange={handleChange} />
                    </div>
                    <Inputerror message={error.fcpl_iderr} />
                    <div style={{ width: '100%', position: 'relative' }} className="mt-2">
                    <h6 style={{fontWeight:'500',fontSize:'12px'}}>Password</h6>

                        <Input
                            name="password"
                            size="medium"
                            placeholder=""
                            style={{ width: '100%', zIndex: 10 }}
                            type={password_visible ? "password" : "text"}
                            class="input"
                            onChange={handleChange} />
                        <Inputerror message={error.password_err} />
                        {password_visible ? <VscEyeClosed size={14} style={{ position: 'absolute', right: '10px', zIndex: '100', top: '33px', color: 'grey' }} onClick={() => setpassword_visible(false)} /> : <VscEye size={14} style={{ position: 'absolute', right: '10px', zIndex: '100', top: '32px', color: 'grey' }} onClick={() => setpassword_visible(true)} />}
                    </div>
                    <Checkbox style={{ marginTop: '20px' }} >{'Remember me'}</Checkbox>
                    <Button onClick={() => signinfun()} style={{fontSize:'13px',fontWeight:'800',background:'#2064b3'}} className='btn  btn-primary mt-3'  type="primary"  size={'medium'} block>SIGN IN</Button>

                    <h6 style={{ marginBottom: '10px',fontSize:'10px',marginTop:'10px',textAlign:'center',marginTop:'30px' }}>Don't worry if you forgot your password </h6>

                    <p type="link" size={'large'} onClick={() => window.location.pathname = "/forgot_password"} className="text-center mt-3 fw-bold" style={{ color: '#589afc', cursor: 'pointer',fontSize:'13px' }}>Forgot Password ?</p>
                </div>
                {/* <div style={{ flex: 0.4, overflow: 'hidden', width: '25%', borderLeft: '1px solid #f0f0f0', display: 'grid', justifyContent: 'center', alignContent: 'center', alignItems: 'center', textAlign: 'center', padding: '0 20px', }}>
                    <img alt="side-alt" src="https://accounts.zoho.com/v2/components/images/passwordless_illustration2x.png" style={{ width: '200px', objectFit: 'contain', margin: 'auto' }} />
                    <h6 style={{ marginBottom: '0px' }}>One Step Login</h6>
                    <p style={{ fontSize: '14px', width: '80%', margin: 'auto', marginTop: '10px', textAlign: 'center', lineHeight: '' }}>Move away from risky passwords and experience one-tap access to your FCPL account.</p>
                </div> */}
            </div>

            <p style={{ width: '100%', textAlign: 'center', bottom: '10px', position: 'absolute',fontSize:'12px' }}>© 2022, Fidelitus Corp Pvt. Ltd. All Rights Reserved.</p>

        </div>
    )
}


export default Login