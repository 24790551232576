import React from 'react'
import { Tabs } from 'antd';
import TableView1 from '../../../components/bdcomponenttable/mobileComponents/listview_mobile/TableView1';
import { IoFlask } from 'react-icons/io5'
import { data_not_found } from '../../../helpers/Constants';

const { TabPane } = Tabs;

function SubViewContactTabs({ data, editContact, setactivetab1, setactivetab, current_tab, setvisiblefun, pagination, page, selected_contact, setselected_contact_func, paginateddata2, gotoleadcreatefunc, reminder, getreminderfun, detailfun }) {

    return (
        <div style={{ width: '100%', height: '100%' }}>
            <Tabs defaultActiveKey={current_tab} style={{ width: '95%', margin: 'auto' }} onChange={(v) => setactivetab(v)}>
                <TabPane
                    tab={
                        <span>
                            My Contacts
                        </span>
                    }
                    key="1"
                    onTabClick={(v) => setactivetab('1')}
                >

                    {data.length > 0 ?
                        <div style={{ width: '100%', overflowY: 'scroll' }}>
                            <h6 style={{ fontSize: '13px', color: '#677078' }}> <strong style={{ color: 'black' }}>Total Contacts ({pagination.total})</strong></h6>
                            <TableView1
                                reminder={reminder}
                                getreminderfun={v => getreminderfun(v)}
                                data={data}
                                detailfun={(v) => detailfun(v)}
                                editContact={editContact}
                                type={'Contact'}
                                selected_contact={selected_contact}
                                setselected_contact_func={(v) => setselected_contact_func(v)}
                                gotoleadcreatefunc={(v) => gotoleadcreatefunc(v)}
                            />
                        </div>
                        :
                        <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                            <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '130px', objectFit: 'contain' }} />
                            <h4 style={{ fontSize: '14px', fontWeight: '700', marginTop: '10px', textAlign: 'center' }}>{"You don't have any contact created yet."}</h4>
                            <h6 style={{ color: '#747474', fontSize: '12px', maxWidth: '100%', textAlign: 'center', margin: 'auto' }}>Visit a contact page and press on a add contact icon. Then, you can find the contact on this page.</h6>
                        </div>
                    }

                    {pagination.total > 20 &&
                        <div style={{ width: '100%', display: 'grid', alignItems: 'center', position: 'fixed', bottom: '0px', paddingBottom: '10px', paddingTop: '20px', marginLeft: '-10px', background: 'white' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '38%', justifyContent: 'space-between', alignSelf: 'center', justifySelf: 'center' }}>
                                {page === 1 ?
                                    <h6 style={{ color: '#d6d6d6', fontWeight: '600' }}>{'< Prev'}</h6>
                                    :
                                    <h6 style={{ color: '#0073ba', fontWeight: '600' }} onClick={() => paginateddata2(page - 1)}>{'< Prev'}</h6 >
                                }
                                <h6 style={{ fontWeight: '800' }}> {page} </h6>
                                {pagination.totalPages === page ?
                                    <h6 style={{ color: '#d6d6d6', fontWeight: '600' }}>{'Next >'}</h6>
                                    :
                                    <h6 style={{ color: '#0073ba', fontWeight: '600' }} onClick={() => paginateddata2(page + 1)}>{'Next >'}</h6>
                                }
                            </div>
                        </div>
                    }

                    <div style={{ position: 'fixed', right: '30px', width: '40px', bottom: '50px', backgroundColor: '#222f3e', padding: 8, borderRadius: 20 }}>
                        <IoFlask size={24} color="#fff" onClick={() => setvisiblefun()} />
                    </div>



                    {/* <CardView1  data={data} type="Active" func={func} editContact={editContact} /> */}

                </TabPane>
                <TabPane
                    tab={
                        <span>
                            Shared Contacts
                        </span>
                    }
                    key="2"
                    onTabClick={(v) => setactivetab('2')}

                >
                    <Tabs defaultActiveKey="1" style={{ width: '100%', margin: 'auto', marginTop: '-20px' }} onChange={(v) => setactivetab1(v)}>
                        <TabPane
                            tab={
                                <span>
                                    Shared To Me
                                </span>
                            }
                            key="1"
                            onTabClick={() => setactivetab1('1')}
                        >
                            {data.length > 0 ?
                                <div style={{ width: '100%', paddingBottom: '60px' }}>
                                    <h6 style={{ fontSize: '13px', color: '#677078' }}> <strong style={{ color: 'black' }}>Share Contacts ({pagination.total})</strong> </h6>
                                    <TableView1
                                        reminder={reminder}
                                        getreminderfun={v => getreminderfun(v)}
                                        data={data}
                                        detailfun={(v) => detailfun(v)}
                                        selected_contact={selected_contact}
                                        editContact={editContact}
                                        type={'Shared To Me'}
                                        setselected_contact_func={(v) => setselected_contact_func(v)}
                                    />
                                </div>
                                :
                                <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                                    <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '130px', objectFit: 'contain' }} />
                                    <h4 style={{ fontSize: '14px', fontWeight: '700', marginTop: '10px', textAlign: 'center' }}>{"No contact has been shared yet."}</h4>
                                    <h6 style={{ color: '#747474', fontSize: '12px', maxWidth: '100%', textAlign: 'center', margin: 'auto' }}>Visit a friend and ask him to share a contact you required.</h6>
                                </div>
                            }
                            {pagination.total > 20 &&
                                <div style={{ width: '100%', display: 'grid', alignItems: 'center', position: 'fixed', bottom: '0px', paddingBottom: '10px', paddingTop: '20px', marginLeft: '-10px', background: 'white' }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', width: '38%', justifyContent: 'space-between', alignSelf: 'center', justifySelf: 'center' }}>
                                        {page === 1 ?
                                            <h6 style={{ color: '#d6d6d6', fontWeight: '600' }}>{'< Prev'}</h6>
                                            :
                                            <h6 style={{ color: '#0073ba', fontWeight: '600' }} onClick={() => paginateddata2(page - 1)}>{'< Prev'}</h6 >
                                        }
                                        <h6 style={{ fontWeight: '800' }}> {page} </h6>
                                        {pagination.totalPages === page ?
                                            <h6 style={{ color: '#d6d6d6', fontWeight: '600' }}>{'Next >'}</h6>
                                            :
                                            <h6 style={{ color: '#0073ba', fontWeight: '600' }} onClick={() => paginateddata2(page + 1)}>{'Next >'}</h6>
                                        }
                                    </div>
                                </div>
                            }
                        </TabPane>
                        <TabPane
                            tab={
                                <span>
                                    Shared By Me
                                </span>
                            }
                            key="2"
                            onTabClick={() => setactivetab1('2')}
                        >
                            {data.length > 0 ?
                                <div style={{ width: '100%', paddingBottom: '60px' }}>
                                    <h6 style={{ fontSize: '13px', color: '#677078' }}> <strong style={{ color: 'black' }}>Shared Contacts ({pagination.total})</strong></h6>
                                    <TableView1
                                        data={data}
                                        reminder={reminder}
                                        getreminderfun={v => getreminderfun(v)}
                                        detailfun={(v) => detailfun(v)}
                                        editContact={editContact}
                                        type={'Shared By Me'} />


                                    {pagination.total > 20 &&
                                        <div style={{ width: '100%', display: 'grid', alignItems: 'center', position: 'fixed', bottom: '0px', paddingBottom: '10px', paddingTop: '20px', marginLeft: '-10px', background: 'white' }}>
                                            <div style={{ display: 'flex', flexDirection: 'row', width: '38%', justifyContent: 'space-between', alignSelf: 'center', justifySelf: 'center' }}>
                                                {page === 1 ?
                                                    <h6 style={{ color: '#d6d6d6', fontWeight: '600' }}>{'< Prev'}</h6>
                                                    :
                                                    <h6 style={{ color: '#0073ba', fontWeight: '600' }} onClick={() => paginateddata2(page - 1)}>{'< Prev'}</h6 >
                                                }
                                                <h6 style={{ fontWeight: '800' }}> {page} </h6>
                                                {pagination.totalPages === page ?
                                                    <h6 style={{ color: '#d6d6d6', fontWeight: '600' }}>{'Next >'}</h6>
                                                    :
                                                    <h6 style={{ color: '#0073ba', fontWeight: '600' }} onClick={() => paginateddata2(page + 1)}>{'Next >'}</h6>
                                                }
                                            </div>
                                        </div>}
                                </div>

                                :
                                <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                                    <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '130px', objectFit: 'contain' }} />
                                    <h4 style={{ fontSize: '14px', fontWeight: '700', marginTop: '10px', textAlign: 'center' }}>{"You don't have any contact shared yet."}</h4>
                                    <h6 style={{ color: '#747474', fontSize: '12px', maxWidth: '100%', textAlign: 'center', margin: 'auto' }}>Visit a contact page and press on a share icon ans share the contact to your friend. Then, you can find the contact on this page.</h6>
                                </div>
                            }
                        </TabPane>
                    </Tabs>
                </TabPane>
            </Tabs>


        </div>
    )
}

export default SubViewContactTabs
