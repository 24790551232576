import React from 'react'

import { useState, useEffect } from 'react';
import ReactMaterialTable from '../../../components/table_design/ReactMaterialTable'
import { getLeads, getContacts } from '../functions/Apis.js'
import { useNavigate } from 'react-router-dom';
import PrimaryButton, { PrimaryButtonAdd, PrimaryButtonFilter } from '../../../components/buttons/PrimaryButton'

import { bd_active_tab } from '../../../redux/actionPayload/BdPayload';
import { useDispatch } from 'react-redux';
import { Modal, Select, Drawer } from 'antd'
import toast, { Toaster } from 'react-hot-toast'
import { Dropdown, Checkbox, Input, Menu, Tabs } from 'antd'
import axios from 'axios'
import { authHeader } from '../../../helpers/usertoken/UserToken';
import { HiOutlineFilter } from 'react-icons/hi'
import { GrFormAdd } from 'react-icons/gr'
import { Row, Col } from 'antd';
import DateField from '../../../components/inputcomponents/DateField';
import { IoClose } from 'react-icons/io5'
import { useSelector } from 'react-redux';
import Loader from '../../../components/Loaders/Loader';


import { Timeline } from '@mui/lab';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';

import { IoCreateOutline } from 'react-icons/io5'
import { AiOutlineClockCircle, AiOutlineEdit } from 'react-icons/ai'
import { BiTransfer } from 'react-icons/bi'
import moment from 'moment';
import { data_not_found } from '../../../helpers/Constants';
import { IoIosClose } from 'react-icons/io';

const { Option } = Select;
const { TabPane } = Tabs;
const { TextArea } = Input;


function ViewLead() {

    const [loader, setloader] = useState(false)
    const roles = useSelector(state => state.Auth.roles)
    const department = useSelector(state => state.Auth.department)
    const [data, setdata] = useState({})

    const [search_lead, setsearch_lead] = useState({ search_text: '', lead_stage: [], from_date: '', to_date: '', filter_status: false })
    const [tempsearch_lead, settempsearch_lead] = useState({ search_text: '', lead_stage: [], from_date: '', to_date: '' })

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [AssignModalVisible, setAssignModalVisible] = useState(false);
    const [ReferModalVisible, setReferModalVisible] = useState(false);
    const [datepickervisible, setdatepickervisible] = useState(false);
    const [cancellationModalVisible, setcancellationModalVisible] = useState(false);

    const [visible, setVisible] = useState(false);

    const [datas, setDatas] = useState([])
    const [Assigneddatas, setAssignedDatas] = useState([])

    const [contacts, setContacts] = useState([])



    const [pagination, setpagination] = useState({})
    const [contactId, setContactId] = useState('')
    const [page, setpage] = useState('1')


    const [departement_arr, setdepartement_arr] = useState([])
    const [hod_arr, sethod_arr] = useState([])
    const [managers_arr, setmanagers_arr] = useState([])
    const [employee_arr, setemployee_arr] = useState([])

    const [assign_data, setassign_data] = useState({ department_id: '', hod_id: '', manager_id: '', employee_id: '', lead_id: '', remarks: '', cancellation_remark: '', status: false, hod_name: '', manager_name: '', employee_name: '' })

    const [selected_lead, setselected_lead] = useState('')
    const [assigned_lead, setassigned_lead] = useState('')
    const [selected_assigned_lead, setselected_assigned_lead] = useState({})
    const [department_id, setdepartment_id] = useState('')
    const [selected_department, setselected_department] = useState({ department_name: '', department_id: '' })
    const [selected_department1, setselected_department1] = useState({ department_name: '', department_id: '' })
    const [selected_employeee, setselected_employeee] = useState({ name: '', id: '' })
    const [reminder, setReminders] = useState([])


    const [modalVisible, setmodalVisible] = useState(false)
    const [departmentmodal,setdepartmentmodal] = useState(false)




    const [cancellation_remark, setcancellation_remark] = useState('')


    const [cancelling_lead, setcancelling_lead] = useState({})


    const [current_tab, setcurrent_tab] = useState('1')
    const [current_tab1, setcurrent_tab1] = useState('1')

    const [lead_tracker, setlead_tracker] = useState([])
    const [task_tracker, settask_tracker] = useState([])
    const [meeting_tracker, setmeeting_tracker] = useState([])

    const navigate = useNavigate()
    const dispatch = useDispatch()



    let keys_arr = ['Lead Name', 'Company', 'Email', 'Phone', 'Lead Status', 'Lead Source', 'Lead Owner']
    const label_arr = ['lead_name', 'company_name', 'email', 'phone', 'lead_status', 'lead_source', 'lead_owner']


    const [lead_status, setlead_status] = useState([])

    let lead_status_basic_gallery_options = [{ id: 'Attempted to Contact', value: 'Attempted to Contact' }, { id: 'Contact in Future', value: 'Contact in Future' }, { id: 'Contacted', value: 'Contacted' }, { id: 'Junk lead', value: 'Junk lead' }, { id: 'Lost lead', value: 'Lost lead' }, { id: 'Not Contacted', value: 'Not Contacted' }, { id: 'Pre Qualified', value: 'Pre Qualified' }, { id: 'Not Qualified', value: 'Not Qualified' }, { id: 'Hold', value: 'Hold' }]
    let lead_status_basic_fms_options = [{ id: 'Meeting/Discussion', value: 'Meeting/Discussion' }, { id: 'Assessment', value: 'Assessment' }, { id: ' Proposal sent', value: ' Proposal sent' }, { id: 'Negotiation', value: 'Negotiation' }, { id: 'Final closure', value: 'Final closure' }, { id: 'Agreement', value: 'Agreement' }, { id: 'Hold', value: 'Hold' }]
    let lead_status_basic_project_options = [{ id: 'BD', value: 'BD' }, { id: 'Design', value: 'Design' }, { id: 'QS', value: 'QS' }, { id: 'MEP', value: 'MEP' }, { id: 'BOQ Review', value: 'BOQ Review' }, { id: 'Contact in Future', value: 'Contact in Future' }, { id: 'Closed', value: 'Closed' }, { id: 'Lost', value: 'Lost' }, { id: 'Junk Lead', value: 'Junk Lead' }, { id: 'Hold', value: 'Hold' }]
    let lead_status_basic_trans_options = [{ id: 'RFQ', value: 'RFQ' }, { id: 'Options sent/Provided', value: 'Options sent/Provided' }, { id: 'Inspection', value: 'Inspection' }, { id: 'Meeting/Discussion', value: 'Meeting/Discussion' }, { id: 'LOI', value: 'LOI' }, { id: 'Agreement/Registration', value: 'Agreement/Registration' }, { id: 'Collection', value: 'Collection' }, { id: 'Lead Lost', value: 'Lead Lost' }, { id: 'Negotiation', value: 'Negotiation' }, { id: 'Hold', value: 'Hold' }]
    let lead_status_basic_hr_options = [{ id: 'Follow up', value: 'Follow up' }, { id: 'Waiting for requirement', value: 'Waiting for requirement' }, { id: 'Closed', value: 'Closed' }, { id: 'lost', value: 'lost' }, { id: 'Hold', value: 'Hold' }]



    useEffect(() => {
        setloader(true)
        if (roles.includes('db_head') || roles.includes('db_user') || roles.includes('admin')) {
            setloader(false)
            setdepartment_id('')
            setlead_status([])
            getDepartements()
        } else {
            if (department[0].department_name === 'Transaction Team') {
                setloader(false)
                setlead_status(lead_status_basic_trans_options)
                setselected_department1({ ...selected_department1, department_name: department[0].department_name, department_id: department[0].id })
            } else if (department[0].department_name === 'Project Team') {
                setloader(false)
                setlead_status(lead_status_basic_project_options)
                setselected_department1({ ...selected_department1, department_name: department[0].department_name, department_id: department[0].id })
            } else if (department[0].department_name === 'Hr Team') {
                setloader(false)
                setlead_status(lead_status_basic_hr_options)
                setselected_department1({ ...selected_department1, department_name: department[0].department_name, department_id: department[0].id })
            } else if (department[0].department_name === 'Gallery Team') {
                setloader(false)
                setlead_status(lead_status_basic_gallery_options)
                setselected_department1({ ...selected_department1, department_name: department[0].department_name, department_id: department[0].id })
            } else if (department[0].department_name === 'FMS Team') {
                setloader(false)
                setlead_status(lead_status_basic_fms_options)
                setselected_department1({ ...selected_department1, department_name: department[0].department_name, department_id: department[0].id })
            }
        }
    }, [])

    const menu = (
        <Menu style={{ width: '300px' }}>
            <h6 style={{ marginLeft: '14px', fontSize: '14px', fontWeight: '500',marginTop:'10px' }}>Add Search Query</h6>
            <div style={{ display: 'flex', width: '90%',marginTop:'-10px' }}>
                <Input type={"text"} style={{ width: '95%', margin: 'auto', marginLeft: '5%' }} value={tempsearch_lead.search_text} onChange={e => settempsearch_lead({ ...tempsearch_lead, search_text: e.target.value })} />
                <PrimaryButton btn_name="+" padding='5px 10px' onClick={() => setsearch_lead({ ...search_lead, search_text: tempsearch_lead.search_text })} />
            </div>
        </Menu>
    );

    const menu1 = (
        <Menu style={{ width: '400px', marginTop: '10px' }}>
            <h6 style={{ padding: '2%', fontWeight: '500', fontSize: '13px' }}>Choose an option</h6>
            <Row style={{ width: '90%', margin: 'auto' }}>
                {lead_status.map((s) => (
                    <Col span={12} style={{ background: 'white', padding: '0px 5px', display: 'flex' }} onClick={() => setleadstage(s.value)}>
                        <Checkbox style={{ marginRight: '5px' }} checked={search_lead?.lead_stage?.includes(s.value)} />
                        <p style={{ fontWeight: '600', fontSize: '13px' }}>{s.value}</p>
                    </Col>
                ))}
            </Row>
        </Menu>
    )


    useEffect(async () => {
        setloader(true)
        let leadDatas = await getLeads('1')
        setloader(false)
        setDatas(leadDatas.datas)
        setpagination(leadDatas.pagination)
        getDepartements()
        // getassignedLeads()
    }, [])
    function getDepartements() {
        setloader(true)
        axios.get('department/get', authHeader)
            .then((res) => {
                setloader(false)
                const departement_arr_created = []
                res.data.datas.forEach(d => {
                    let data = {}
                    data['label'] = d.department_name
                    data['value'] = d.department_name
                    data['id'] = d.id
                    departement_arr_created.push(data)
                })
                setdepartement_arr(departement_arr_created)
            })
    }
    // Lead detail add convert 
    function leaddetailfunc(v) {
        navigate(`/lead_detail/${v._id}`, { state: { datas: v } })
        dispatch(bd_active_tab('2'))
    }
    function assignedleaddetailfunc(id) {
        navigate(`/lead_detail/${id}`, { state: { datas: id } })

    }
    async function addleadfunc() {
        let contactDatas = await getContacts()
        setContacts(contactDatas)
        setIsModalVisible(true)
    }
    function convert_tolead() {
        if (contactId) {
            navigate('/create_lead', { state: { datas: contacts.filter(contact => contact.id === contactId)[0] } })
        }
        else {
            toast.error('Please Select Contact!')
        }
    }
    function handleChangeContact(v) {
        setContactId(v)
    }
    function handleChange({ key, value }) {
        settempsearch_lead({ ...tempsearch_lead, [key]: value })
    }
    const handleCancel = () => {
        setIsModalVisible(false)
    };
    const paginateddata = (v) => {
        setloader(true)
        axios.get(`lead/get/${v}`, authHeader)
            .then((res) => {
                setloader(false)
                setDatas(res.data.datas)
                setpagination(res.data.pagination)
            })
    }
    // Date picker methods 
    function setdatepickervisiblefun() {
        setdatepickervisible(false)
        setsearch_lead({ ...search_lead, from_date: '', to_date: '' })
    }
    function setselectedDateRange() {
        if (!tempsearch_lead.from_date || !tempsearch_lead.to_date) {
            toast.error('From date or To date might be missing')
        } else {
            settempsearch_lead({ ...tempsearch_lead, from_date: '', to_date: '' })
            setsearch_lead({ ...search_lead, from_date: tempsearch_lead.from_date, to_date: tempsearch_lead.to_date })
            setdatepickervisible(false)
        }
    }
    async function setactivetab(v) {
        console.log('v here na kp',v)
        setcurrent_tab(v)
        if (v === '1') {
            let leadDatas = await getLeads('1')
            setDatas(leadDatas.datas)
            setpagination(leadDatas.pagination)
            getDepartements()
        } else if (v === '2') {
            setactivetab1('1')
            getassignedtomeLeads(1)
        } else if (v === '3') {
            //   getdeals()
        }
    }
    async function setactivetab1(v) {
        setcurrent_tab1(v)
        if (v === '1') {
            getassignedtomeLeads(1)
        } else if (v === '2') {
            getassignedLeads(1)
        }
    }
    // Assign lead 
    function assignLeadfunc() {
        if (!selected_lead) {
            toast.error('select lead for assign')
        } else {
            if (roles.includes('db_head') || roles.includes('db_user') || roles.includes('db_sub_user') || roles.includes('admin')) {
                setdepartment_id('')
                getDepartements()
                let selected_lead_obj = datas.find(l => l._id === selected_lead)
                let lead_department = departement_arr.find(d => d?.id === selected_lead_obj?.department_id)
                setselected_department({ ...selected_department, department_name: lead_department?.value, department_id: lead_department?.id })
                getRoles(selected_lead_obj?.department_id)
            }
            else if (department_id && (roles.includes('hod') || roles.includes('manager'))) {
                getteamemployees()
            } else {
                getteamemployees()
            }
            setAssignModalVisible(true)
        }
    }
    function reassignLeadfunc() {
        if (!assigned_lead) {
            toast.error('select lead for assign')
        } else {
            if (roles.includes('db_head') || roles.includes('db_user') || roles.includes('db_sub_user') || roles.includes('admin')) {
                // getDepartements()
                let assigned_lead_department = departement_arr.find((d) => d?.id === selected_assigned_lead?.assigned_lead_id?.department_id)
                getteamemployees(assigned_lead_department?.id)
                setselected_department({ department_name: assigned_lead_department?.value, department_id: assigned_lead_department?.id })
                console.log('setselected_assigned_lead', selected_assigned_lead)

            }
            else if (department_id && (roles.includes('hod') || roles.includes('manager'))) {
                getteamemployees()
            }
            setAssignModalVisible(true)
        }
    }
    //get Assigned by me lead
    async function getassignedLeads(v) {
        setpage(v)
        if (roles.includes('db_head') || roles.includes('db_user') || roles.includes('db_sub_user') || roles.includes('hod') || roles.includes('manager') || roles.includes('admin')) {
            setloader(true)
            await axios.get(`assign/get-assigned-by-me/${v}`, authHeader)
                .then((res) => {
                    setloader(false)
                    setAssignedDatas(res.data.datas.reverse())
                    setpagination(res.data.pagination)
                })
                .catch((err) => {
                    setloader(false)
                })
        }
    }
    //get Assigned to me lead 
    async function getassignedtomeLeads(v) {
        setpage(v)
        setloader(true)
        await axios.get(`assign/assigned-to-me/${v}`, authHeader)
            .then((res) => {
                setloader(false)
                setAssignedDatas(res.data.datas.reverse())
                setpagination(res.data.pagination)
            })
            .catch((err) => {
            })
    }
    // assign db_head 
    function handleChangefun(v, v1, v2) {
        if (v2 === '1') {
            setdepartmentmodal(false)
            setselected_department1({ ...selected_department1, department_id: departement_arr.find((d) => d.id === v).id, department_name: departement_arr.find((d) => d.id === v)?.value })
            if (departement_arr.find((d) => d.id === v)?.value === 'Transaction Team') {
                setlead_status(lead_status_basic_trans_options)
            } else if (departement_arr.find((d) => d.id === v)?.value === 'Project Team') {
                setlead_status(lead_status_basic_project_options)
            } else if (departement_arr.find((d) => d.id === v)?.value === 'Hr Team') {
                setlead_status(lead_status_basic_hr_options)
            }
            setmodalVisible(false)
        } else {
            if (roles.includes('db_head') || roles.includes('db_user') || roles.includes('db_sub_user') || roles.includes('admin')) {
                getRoles(v)
                setselected_employeee({ ...selected_employeee, id: v, name: hod_arr.find(e => e.id === v).name })
                setassign_data({ ...assign_data, [v1]: v, hod_name: hod_arr?.find((h) => h.id === v)?.name })

            } else if (roles.includes('hod') || roles.includes('manager')) {
                getteamemployees()
                roles.includes('hod') ?
                    setassign_data({ ...assign_data, [v1]: v, employee_name: employee_arr?.find((e) => e.id === v)?.name })
                    :
                    setassign_data({ ...assign_data, [v1]: v, employee_name: employee_arr?.find((e) => e.id === v)?.name })
            }
        }
    }
    //assigned lead to hod by db team
    function approvelead() {
        if (roles.includes('admin') || roles.includes('db_head') || roles.includes('db_user') || roles.includes('db_sub_user')) {

            if (!assign_data.hod_id) {
                toast.error('select hod to assign lead')
            } else {
                const send_data = {}
                send_data['lead_id'] = selected_lead
                send_data['department_id'] = selected_department.department_id
                send_data['user_id'] = assign_data.hod_id
                send_data['remarks'] = assign_data.remarks
                setloader(true)
                axios.post(`assign/assign-lead`, send_data, authHeader)
                    .then((res) => {
                        setloader(false)
                        setassign_data({ ...assign_data, department_id: '', hod_id: '',hod_name:'', lead_id: '',remarks:'' })
                        if (res.data.status) {
                            setAssignModalVisible(false)
                            setselected_lead('')
                            toast.success('Lead assigned successfully')
                            getassignedtomeLeads(page)
                            // setassign_data({ ...assign_data, hod_id: '', hod_name: null, remarks: '' })
                            setselected_employeee({ id: '', name: '' })
                        }
                    }).catch(err => {
                        setloader(false)
                        if (err.response.status === 422) {
                            setAssignModalVisible(false)
                            setselected_lead('')
                            toast.error('Lead already has been assigned')
                            setassign_data({ ...assign_data, hod_id: '', hod_name: null, remarks: '' })
                            setselected_employeee({ id: '', name: '' })
                            getassignedtomeLeads(page)
                        }
                    })
            }

        } else if (roles.includes('hod') || roles.includes('manager')) {

            if (!assign_data.manager_id) {
                toast.error('select employee/manager to assign lead')
            } else {
                let data = {}
                data['lead_id'] = current_tab === '1' ? selected_lead : selected_assigned_lead?.assigned_lead_id?.lead_id?.id
                data['department_id'] = department_id
                data['user_id'] = assign_data.manager_id
                data['remarks'] = assign_data.remarks

                setloader(true)
                axios.post(`assign/assign-lead`, data, authHeader)
                    .then((res) => {
                        setloader(false)
                        setassign_data({ ...assign_data, department_id: '', hod_id: '', hod_name: null, lead_id: '', manager_id: '', employee_name: null,remarks:'' })
                        if (res.data.status) {
                            setAssignModalVisible(false)
                            setselected_lead('')
                            toast.success('Lead assigned successfully')
                            getassignedtomeLeads(page)
                        }
                    }).catch(err => {
                        setloader(false)
                        if (err?.response?.status === 422) {
                            setAssignModalVisible(false)
                            setselected_lead('')
                            toast.error('Lead already has been assigned')
                            getassignedtomeLeads(page)
                        }
                    })
            }
        }
    }
    //get departments
    function getDepartements() {
        axios.get('department/get', authHeader)
            .then((res) => {
                const departement_arr_created = []
                res.data.datas.forEach(d => {
                    if (department[0]?.department_name === d?.department_name) {
                        setdepartment_id(d?.id)
                    }
                    let data = {}
                    data['label'] = d.department_name
                    data['value'] = d.department_name
                    data['id'] = d.id
                    departement_arr_created.push(data)
                })
                setdepartement_arr(departement_arr_created)
            })
    }
    //get reminders
    async function getReminder(id) {
        await axios.get(`reminder/get?type=${'Lead'}&type_id=${id}`, authHeader).then((res) => {
            setReminders(res.data.datas.reverse())
        })
    }
    //get users by role
    function getRoles(v) {
        axios.get(`user/get-users-by-department-role/${v}/hod`, authHeader)
            .then((res) => {

                const hod_arr_created = []
                res?.data?.datas?.forEach(d => {
                    let data = {}
                    data['name'] = `${d?.name}`
                    data['id'] = d?._id
                    hod_arr_created.push(data)
                })
                sethod_arr(hod_arr_created)
            })
    }
    //get manager and employee by department id
    function getteamemployees(v) {
        axios.get(`user/get-users-by-departmentId/${v ? v : department_id}`, authHeader)
            .then((res) => {
                let managers_arr_created = []
                let employee_arr_created = []
                res.data.datas.forEach((u) => {
                    u.user_roles.forEach((r) => {
                        if (r.roles[0].role_name === 'manager') {
                            let obj = {}
                            obj['name'] = u.name
                            obj['id'] = u._id
                            managers_arr_created.push(obj)
                        } else if (r?.roles[0].role_name !== 'manager' && r?.roles[0].role_name !== 'hod' && r?.roles[0].role_name !== 'admin' && r?.roles[0].role_name !== 'controller') {
                            let obj = {}
                            obj['name'] = u.name
                            obj['id'] = u._id
                            employee_arr_created.push(obj)
                            managers_arr_created.push(obj)
                        }
                    })
                })

                setmanagers_arr(managers_arr_created)
                setemployee_arr(employee_arr_created)
            }).catch((err) => {
            })
    }
    function applyfilter() {
        if (search_lead.search_text || search_lead.lead_stage.length !== 0 || search_lead.from_date || search_lead.to_date) {
            axios.get(`lead/search?search_text=${search_lead.search_text}&status=${search_lead?.lead_stage.length > 0 ? search_lead?.lead_stage?.join(',') : ''}&from_date=${search_lead.from_date}&to_date=${search_lead.to_date}&page=${1}`, authHeader)
                .then((res) => {
                    setsearch_lead({ ...search_lead, filter_status: true })
                    setpagination(res.data.pagination)
                    setDatas(res.data.datas)
                    settempsearch_lead({ ...tempsearch_lead, search_text: '' })
                })
        } else {
            toast.error('select any filter option before applying ')
        }
    }
    async function resetfilter() {
        setsearch_lead({ search_text: '', lead_stage: '', from_date: '', to_date: '', filter_status: false })
        settempsearch_lead({ search_text: '', lead_stage: '', from_date: '', to_date: '' })
        setselected_department({ ...selected_department, department_id: '', department_name: '' })
        let leadDatas = await getLeads('1')
        setDatas(leadDatas.datas)
        setpagination(leadDatas.pagination)

    }
    async function refferrleadfunc() {
        setReferModalVisible(true)
    }
    //accept assign lead func
    async function acceptassignedlead(v, v1) {
        if (v1 === 1) {
            let send_data = {
                status: true
            }
            axios.put(`assign/update-assign/${v._id}`, send_data, authHeader)
                .then((res) => {
                    getassignedtomeLeads(page)
                }).catch((err) => {
                })
        } else if (v1 === 2) {
            setcancelling_lead(v)
            setcancellationModalVisible(true)
        }
    }
    //reject assign lead func
    function rejectassignedlead() {
        let v = cancelling_lead
        if (!cancellation_remark) {
            toast.error('Cancellation reason is required')
        } else {
            let send_data = {
                cancellation_reason: cancellation_remark,
                status: false
            }
            axios.put(`assign/update-assign/${v._id}`, send_data, authHeader)
                .then((res) => {
                    setcancellationModalVisible(false)
                    getassignedtomeLeads(page)
                }).catch((err) => {
                })
        }

    }
    async function tracker(d) {
        await axios.get(`lead/get/${d?.assigned_lead_id?.lead_id?.id}`, authHeader).then((res) => {
            setdata(res.data.datas[0])
        })

        await axios.get(`activity/get-timeline/Lead/${d?.assigned_lead_id?.lead_id?.id}`, authHeader)
            .then((res) => {
                setlead_tracker(res.data.datas)
                let tracker_arrange = []
                let tracker_arrange1 = []
                res.data.datas.sub.filter(t => t.type === 'Task' && t.history === '<p>Task created</p>' && t.event === 'create').forEach(e1 => {
                    tracker_arrange.push(e1)
                    if (res.data.datas.sub.filter(t1 => t1.type === 'Task' && t1.event === 'update' && t1.type_id === e1.type_id).length > 0) {
                        res.data.datas.sub.filter(t1 => t1.type === 'Task' && t1.event === 'update' && t1.type_id === e1.type_id).forEach(e => {
                            tracker_arrange.push(e)
                        })
                    }
                })
                settask_tracker(tracker_arrange)

                res.data.datas.sub.filter(t => t.type === "Meeting" && t.history === '<p>Meeting created</p>' && t.event === 'create').forEach(e1 => {
                    tracker_arrange1.push(e1)
                    if (res.data.datas.sub.filter(t1 => t1.type === 'Meeting' && t1.event === 'update' && t1.type_id === e1.type_id).length > 0) {
                        res.data.datas.sub.filter(t1 => t1.type === 'Meeting' && t1.event === 'update' && t1.type_id === e1.type_id).forEach(e => {
                            tracker_arrange1.push(e)
                        })
                    }
                })
                setmeeting_tracker(tracker_arrange1)
                setVisible(true)
            }).catch((err) => {
            })
    }
    function setleadstage(v) {
        let current_search_lead = [...search_lead.lead_stage]
        if (current_search_lead?.includes(v)) {
            current_search_lead.splice(current_search_lead?.findIndex(v1 => v1 === v), 1)
        } else {
            current_search_lead.push(v)
        }
        console.log('current_search_lead', current_search_lead)
        setsearch_lead({ ...search_lead, lead_stage: current_search_lead })
    }


    return (
        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '40px', backgroundColor: 'white', width: '100%', }}>

            <Drawer title="Basic Drawer" placement="right" onClose={() => setVisible(false)} visible={visible} width="400px" closable={false} headerStyle={{ display: 'none' }}  >
                <h4 style={{ fontSize: '20px', fontWeight: '800' }}>TimeLine <AiOutlineClockCircle size={22} style={{ margin: '0px 5px' }} /> </h4>
                <Timeline style={{ marginTop: '30px', width: '100%' }} >

                    {lead_tracker?.base?.filter((l => l.type === 'Lead' && (l?.history?.includes('Converted from contact ')))).map(t => (
                        <TimelineItem>
                            <TimelineOppositeContent color="text.secondary">
                                <h6 style={{ fontSize: '12px', color: '#0ca7fa', fontWeight: 'bold' }}> {moment(data?.contact_id?.createdAt).format('lll')}</h6>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                {t.event === 'convert' && <IoCreateOutline size={20} color="#171636" />}
                                {/* {t.event === 'update' && <AiOutlineEdit size={20} color="#171636"/>} */}
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent style={{ maxWidth: '155px', backgroundColor: '#fff' }}>
                                <h6 style={{ fontSize: '12px', fontWeight: '600', marginBottom: '0px', paddingBottom: '0px' }}>Contact Created</h6>
                                <span style={{ marginleft: '0px', fontSize: '11px', color: '#0087f5', fontWeight: '700', cursor: 'pointer', paddingLeft: '0px', marginTop: '0px', paddingBottom: '2px' }} onClick={() => navigate(`/contact_detail/${t?.history.split(':')[1]}`)}>View Details</span>
                                <h6 style={{ fontSize: '11px', fontWeigt: '600', color: '#858585', marginBottom: '0px', paddingBottom: '0px' }}>{t?.user_id?.name}</h6>
                            </TimelineContent>
                        </TimelineItem>
                    ))}

                    {lead_tracker?.base?.filter((l => l.type === 'Lead' && (l?.history?.includes('Converted from contact ')))).map(t => (
                        <TimelineItem>
                            <TimelineOppositeContent color="text.secondary">
                                <h6 style={{ fontSize: '12px', color: '#0ca7fa', fontWeight: 'bold' }}> {moment(t?.createdAt).format('lll')}</h6>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                {t.event === 'convert' && <BiTransfer size={20} color="#171636" />}
                                {/* {t.event === 'update' && <AiOutlineEdit size={20} color="#171636"/>} */}
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent style={{ maxWidth: '155px', backgroundColor: '#fff' }}>
                                <h6 style={{ fontSize: '12px', fontWeight: '600', marginBottom: '0px', paddingBottom: '0px' }} >{t?.history.split(':')[0]}</h6>
                                <span style={{ marginleft: '0px', fontSize: '11px', color: '#0087f5', fontWeight: '700', cursor: 'pointer', paddingLeft: '0px', marginTop: '0px', paddingBottom: '2px' }} onClick={() => navigate(`/contact_detail/${t?.history.split(':')[1]}`)}>View Details</span>
                                <h6 style={{ fontSize: '11px', fontWeigt: '600', color: '#858585', marginBottom: '0px', paddingBottom: '0px' }}>{t?.user_id?.name}</h6>
                            </TimelineContent>
                        </TimelineItem>
                    ))}

                    {lead_tracker?.base?.filter((l => l.type === 'Lead' && (l?.history?.includes('<p>Lead Created</p>')))).map(t => (
                        <TimelineItem>
                            <TimelineOppositeContent color="text.secondary">
                                <h6 style={{ fontSize: '12px', color: '#0ca7fa', fontWeight: 'bold' }}> {moment(t?.createdAt).format('lll')}</h6>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                {t.event === 'create' && <IoCreateOutline size={20} color="#171636" />}
                                {t.event === 'update' && <AiOutlineEdit size={20} color="#171636" />}
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent style={{ maxWidth: '155px', backgroundColor: '#fff' }}>
                                <h6 style={{ fontSize: '12px', fontWeight: '600', marginBottom: '0px', paddingBottom: '0px' }} dangerouslySetInnerHTML={{ __html: t?.history }}></h6>
                                {/* <span style={{marginleft:'0px',fontSize:'11px',color:'#0087f5',fontWeight:'700',cursor:'pointer',paddingLeft:'0px',marginTop:'0px',paddingBottom:'2px'}} onClick={()=>navigate(`/contact_detail/${t?.history.split(':')[1]}`)}>View Details</span> */}
                                <h6 style={{ fontSize: '11px', fontWeigt: '600', color: '#858585', marginBottom: '0px', paddingBottom: '0px' }}>{t?.user_id?.name}</h6>
                            </TimelineContent>
                        </TimelineItem>
                    ))}

                    {lead_tracker?.base?.filter((l => l.event === "assign_lead")).map(t => (
                        <TimelineItem>
                            <TimelineOppositeContent color="text.secondary">
                                <h6 style={{ fontSize: '12px', color: '#0ca7fa', fontWeight: 'bold' }}> {moment(t?.createdAt).format('lll')}</h6>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                {t.event === 'assign_lead' && <IoCreateOutline size={20} color="#171636" />}
                                {/* {t.event === 'update' && <AiOutlineEdit size={20} color="#171636"/>} */}
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent style={{ maxWidth: '155px', backgroundColor: '#fff' }}>
                                <h6 style={{ fontSize: '12px', fontWeight: '600', marginBottom: '0px', paddingBottom: '0px' }} dangerouslySetInnerHTML={{ __html: t?.history }}></h6>
                                {/* <span style={{marginleft:'0px',fontSize:'11px',color:'#0087f5',fontWeight:'700',cursor:'pointer',paddingLeft:'0px',marginTop:'0px',paddingBottom:'2px'}} onClick={()=>navigate(`/contact_detail/${t?.history.split(':')[1]}`)}>View Details</span> */}
                                <h6 style={{ fontSize: '11px', fontWeigt: '600', color: '#858585', marginBottom: '0px', paddingBottom: '0px' }}>{t?.user_id?.name}</h6>
                            </TimelineContent>
                        </TimelineItem>
                    ))}

                    {lead_tracker?.base?.filter((l => l.type === 'Lead' && l.event === 'update')).map(t => (
                        <TimelineItem>
                            <TimelineOppositeContent color="text.secondary">
                                <h6 style={{ fontSize: '12px', color: '#0ca7fa', fontWeight: 'bold' }}> {moment(t?.createdAt).format('lll')}</h6>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                {t.event === 'create' && <IoCreateOutline size={20} color="#171636" />}
                                {t.event === 'update' && <AiOutlineEdit size={20} color="#171636" />}
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent style={{ maxWidth: '155px', backgroundColor: '#fff' }}>
                                <h6 style={{ fontSize: '12px', fontWeight: '600', marginBottom: '0px', paddingBottom: '0px' }} dangerouslySetInnerHTML={{ __html: t?.history }}></h6>
                                {/* <span style={{marginleft:'0px',fontSize:'11px',color:'#0087f5',fontWeight:'700',cursor:'pointer',paddingLeft:'0px',marginTop:'0px',paddingBottom:'2px'}} onClick={()=>navigate(`/contact_detail/${t?.history.split(':')[1]}`)}>View Details</span> */}
                                <h6 style={{ fontSize: '11px', fontWeigt: '600', color: '#858585', marginBottom: '0px', paddingBottom: '0px' }}>{t?.user_id?.name}</h6>
                            </TimelineContent>
                        </TimelineItem>
                    ))}

                    {lead_tracker?.sub?.filter((l => l.type === 'Deal')).map(t => (
                        <TimelineItem>
                            <TimelineOppositeContent color="text.secondary">
                                <h6 style={{ fontSize: '12px', color: '#0ca7fa', fontWeight: 'bold' }}> {moment(t?.createdAt).format('lll')}</h6>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                {t.event === 'create' && <IoCreateOutline size={20} color="#171636" />}
                                {t.event === 'convert' && <BiTransfer size={20} color="#171636" />}
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent style={{ maxWidth: '155px', backgroundColor: '#fff' }}>
                                <h6 style={{ fontSize: '12px', fontWeight: '600', marginBottom: '0px', paddingBottom: '0px' }} >{t?.history?.replace(/(<([^>]+)>)/gi, "").split(':')[0]}</h6>
                                {t.event === 'create' ?
                                    <>
                                        {lead_tracker?.sub?.find((l => l.type === 'Deal' && l.history.includes('Converted from lead'))) &&
                                            <span style={{ marginleft: '0px', fontSize: '11px', color: '#0087f5', fontWeight: '700', cursor: 'pointer', paddingLeft: '0px', marginTop: '0px', paddingBottom: '2px' }} onClick={() => navigate(`/deal_detail/${lead_tracker?.sub?.find((l => l.type === 'Deal' && l.history.includes('Converted from lead')))?.type_id}`)}>View Details</span>
                                        }
                                    </>
                                    :
                                    <span style={{ marginleft: '0px', fontSize: '11px', color: '#0087f5', fontWeight: '700', cursor: 'pointer', paddingLeft: '0px', marginTop: '0px', paddingBottom: '2px' }} onClick={() => navigate(`/deal_detail/${t?.type_id}`)}>View Details</span>
                                }
                                <h6 style={{ fontSize: '11px', fontWeigt: '600', color: '#858585', marginBottom: '0px', paddingBottom: '0px' }}>{t?.user_id?.name}</h6>
                            </TimelineContent>
                        </TimelineItem>
                    ))}

                    {task_tracker.map(t => (
                        <TimelineItem>
                            <TimelineOppositeContent color="text.secondary">
                                <h6 style={{ fontSize: '12px', color: '#0ca7fa', fontWeight: 'bold' }}> {moment(t?.createdAt).format('lll')}</h6>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                {t.event === 'create' && <IoCreateOutline size={20} color="#171636" />}
                                {t.event === 'update' && <AiOutlineEdit size={20} color="#171636" />}
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent style={{ maxWidth: '155px', backgroundColor: '#fff' }}>
                                <h6 style={{ fontSize: '12px', fontWeight: '900', marginBottom: '0px', paddingBottom: '2px' }} dangerouslySetInnerHTML={{ __html: t?.history }}></h6>
                                <h6 style={{ fontSize: '11px', fontWeigt: '600', color: '#858585', marginBottom: '0px', paddingBottom: '0px' }}>{t?.user_id?.name}</h6>
                            </TimelineContent>
                        </TimelineItem>
                    ))}

                    {meeting_tracker?.map(t => (
                        <TimelineItem>
                            <TimelineOppositeContent color="text.secondary">
                                <h6 style={{ fontSize: '12px', color: '#0ca7fa', fontWeight: 'bold' }}> {moment(t?.createdAt).format('lll')}</h6>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                {t.event === 'create' && <IoCreateOutline size={20} color="#171636" />}
                                {t.event === 'update' && <AiOutlineEdit size={20} color="#171636" />}
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent style={{ maxWidth: '155px', backgroundColor: '#fff' }}>
                                <h6 style={{ fontSize: '12px', fontWeight: '900', marginBottom: '0px', paddingBottom: '2px' }} dangerouslySetInnerHTML={{ __html: t?.history }}></h6>
                                <h6 style={{ fontSize: '11px', fontWeigt: '600', color: '#858585', marginBottom: '0px', paddingBottom: '0px' }}>{t?.user_id?.name}</h6>
                            </TimelineContent>
                        </TimelineItem>
                    ))}
                </Timeline>
            </Drawer>


            {loader && <Loader />}
            <Modal visible={modalVisible} onCancel={() => setmodalVisible(false)} style={{ maxWidth: '300px', }} centered footer={false}>
                
                <Row>
               <IoIosClose  onClick={() => setmodalVisible(false)}size={20} style={{position:'absolute',top:'20px',cursor:'pointer',right:'25px'}} />

                    <Col span={24}>
                        <div style={{ width: '96%', margin: ' 10px auto', }}>
                            <h6 style={{ fontSize: '12px', fontWeight: '600' }}>Select Department </h6>

                            <Select
                                showSearch
                                placeholder="Select Department"
                                optionFilterProp="children"
                                value={selected_department.department_id}
                                onChange={(v) => handleChangefun(v, 'department_id', '1')}
                                size='medium'
                                filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                style={{ width: '100%' }}
                            >
                                {
                                    departement_arr.map((d) => (
                                        <Option key={d.id} value={d.id}><div>{d.value}</div></Option>
                                    ))
                                }
                            </Select>
                        </div>
                    </Col>
                </Row>
            </Modal>

            <Modal visible={departmentmodal} onCancel={() => setdepartmentmodal(false)} closable={false} style={{ maxWidth: '300px', }} centered footer={false}>
                <div style={{maxWidth:'300px'}}>
                <IoIosClose  onClick={() => setdepartmentmodal(false)} size={20} style={{position:'absolute',top:'20px',cursor:'pointer',right:'25px',}} />

                <Row>

                <Col span={24}>
                    <div style={{ width: '96%', margin: ' 10px auto', }}>

                    <h6 style={{ fontSize: '12px', fontWeight: '600' }}>Select Departement </h6>

                    <Select
                        showSearch
                        placeholder="Select Department"
                        optionFilterProp="children"
                        value={selected_department.department_id}
                        onChange={(v) => handleChangefun(v, 'department_id', '1')}
                        size='medium'
                        filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        style={{ width: '100%' }}
                    >
                        {
                        departement_arr.map((d) => (
                            <Option key={d.id} value={d.id}><div>{d.value}</div></Option>
                        ))
                        }
                    </Select>
                    </div>
                </Col>
                </Row>
                </div>
            </Modal>

            <Toaster />
            {roles.find(r => r === 'admin')  || roles.find(r => r === 'hod') || roles.find(r => r === 'db_head') || roles.find(r => r === 'db_user') || roles.find(r => r === 'db_sub_user') || roles.find(r => r === 'manager') ?
                <Tabs
                    defaultActiveKey="1"
                    size="small"
                    activeKey={current_tab}
                    style={{ width: '90%', margin: '10px auto', marginBottom: '0px', position: 'sticky', top: '80px' }}
                    onChange={(v) => setactivetab(v)}>

                    <TabPane tab="My Leads" key="1">
                        <div style={{ width: '100%', margin: '0px auto', paddingTop: '0px' }}>

                            <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between', margin: '0px 0px', padding: '0px 0px', background: 'transparent',marginTop:'-10px' }}>
                                <h6 style={{ marginBottom: '0px', fontWeight: 'bolder',fontSize:'15px' }}>Total Leads ({pagination?.total})</h6>

                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    {/* Option Buttons on selected lead  */}
                                    {selected_lead !== '' && (roles.find(r => r === 'admin') || roles.find(r => r === 'hod') || roles.find(r => r === 'db_head') || roles.find(r => r === 'db_user') || roles.find(r => r === 'manager')) &&
                                        <div style={{ display: 'flex' }}>

                                            {/* Assign lead btn   */}
                                            <div onClick={() => assignLeadfunc()} style={{ marginRight: '10px' }}>
                                                <PrimaryButtonAdd btn_name="Assign Lead" />
                                            </div>

                                            {/*referr lead button visible based on department except DB Team  */}
                                            {(department[0]?.department_name === 'Transaction Team' ||
                                                department[0]?.department_name === 'Project Team' ||
                                                department[0]?.department_name === 'Fms Team') &&

                                                <div onClick={() => refferrleadfunc()} style={{ marginRight: '10px' }}>
                                                    <PrimaryButtonAdd btn_name="Refer Lead" />
                                                </div>}
                                        </div>
                                    }

                                    {/* Create lead Button  */}
                                    <div onClick={() => addleadfunc()}>
                                        <PrimaryButtonAdd btn_name="Create Lead" />
                                    </div>
                                </div>
                            </div>


                            {/* Filter options  */}
                            <div style={{ display: 'flex', marginTop: '-10px' }}>
                                <div style={{ display: 'flex', alignItems: 'center', marginLeft: '0px', padding: '5px 0px' }}>
                                    <HiOutlineFilter size={24} style={{ marginRight: '10px' }} />
                                    <h6 style={{ marginBottom: '0px', marginRight: '15px', fontSize: '14px', fontWeight: 'bolder' }}>Filter Lead By</h6>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        

                                    {selected_department1.department_id === '' ?
                                            <button type='button' className='btn  btn-light btn-sm m-1' onClick={() => setdepartmentmodal(true)} >
                                            <GrFormAdd color="white" size={18} style={{ marginRight: '6px',marginTop:'-3px', color: 'white' }} />
                                            <span>Select Department</span>
                                            </button>
                                            :
                                            <div style={{ display: 'flex', alignItems: 'center', background: '#f8f9fa', padding: '8px', borderRadius: '2px', marginLeft: '2px' }}>
                                            <h6 style={{ marginBottom: '0px', fontSize: '12px' }}>{departement_arr.find(d=>d.id == selected_department1.department_id)?.value?.slice(0, 20)}</h6>
                                            {(!roles.includes('hod') && (roles.includes('admin') || roles.includes('db_head') || roles.includes('db_user'))) && <IoClose style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => { setselected_department1({ ...selected_department1, department_id:'' }) }} />}
                                            </div>
                                        }

                                        {selected_department1.department_id &&
                                            <>
                                                {search_lead?.lead_stage?.length === 0 ?
                                                    <Dropdown overlay={menu1} trigger={['click']}>
                                                        <button type='button' className='btn  btn-light btn-sm m-1'   >
                                                            <GrFormAdd color="white" size={18} style={{ marginRight: '6px', color: 'white' }} />
                                                            <span>Add Lead status</span>
                                                        </button>
                                                    </Dropdown>
                                                    :
                                                    <Dropdown overlay={menu1} trigger={['click']}>
                                                        <div style={{ display: 'flex', alignItems: 'center', background: '#f8f9fa', padding: '8px', borderRadius: '2px', marginLeft: '5px', cursor: 'pointer' }}>
                                                            <h6 style={{ marginBottom: '0px', fontSize: '12px' }}>{search_lead?.lead_stage[0]}{search_lead?.lead_stage?.length > 1 && `  +${search_lead?.lead_stage?.length - 1}`}</h6>
                                                            <IoClose style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => setsearch_lead({ ...search_lead, lead_stage: [] })} />
                                                        </div>
                                                    </Dropdown>
                                                }
                                            </>
                                        }
                                       
                                        {search_lead.search_text === '' ?
                                            <Dropdown overlay={menu} trigger={['click']}>
                                                <button type='button' className='btn  btn-light btn-sm m-1'   >
                                                    <GrFormAdd color="white" size={18} style={{ marginRight: '6px', color: 'white' }} />
                                                    <span>Add Search filter</span>
                                                </button>
                                            </Dropdown>
                                            :
                                            <div style={{ display: 'flex', alignItems: 'center', background: '#f8f9fa', padding: '8px', borderRadius: '2px' }}>
                                                <h6 style={{ marginBottom: '0px', fontSize: '12px' }}>{search_lead.search_text.slice(0, 20)}{search_lead.search_text.length > 20 && '...'}</h6>
                                                <IoClose style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => setsearch_lead({ ...search_lead, search_text: '' })} />
                                            </div>

                                        }

                                        


                                        {search_lead.from_date === '' || search_lead.to_date === '' ?
                                            <div>
                                                <button type='button' className='btn  btn-light btn-sm m-1' onClick={() => setdatepickervisible(true)}   >
                                                    <GrFormAdd color="white" size={18} style={{ marginRight: '6px', color: 'white' }} />
                                                    <span>Date interval</span>
                                                </button>

                                            </div>
                                            :
                                            <div style={{ display: 'flex', alignItems: 'center', background: '#f8f9fa', padding: '8px', borderRadius: '2px', margin: '0px 5px' }}>
                                                <h6 style={{ marginBottom: '0px', fontSize: '12px' }}>{search_lead.from_date + ':' + search_lead.to_date}</h6>
                                                <IoClose style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => setsearch_lead({ ...search_lead, from_date: '', to_date: '' })} />
                                            </div>
                                        }


                                        <div style={{ marginRight: '5px' }} onClick={applyfilter}>
                                            <PrimaryButtonFilter btn_name="Apply Filter" padding={'2px 8px'} />
                                        </div>
                                        <div style={{ marginRight: '5px' }} onClick={resetfilter}>
                                            <PrimaryButtonFilter btn_name="Reset Filter" padding={'2px 8px'} />
                                        </div>

                                    </div>
                                </div>
                            </div>


                            {datas.length > 0 ?
                                <div style={{ width: '100%', overflowY: 'scroll' }} >
                                    {
                                        (datas.length ? <ReactMaterialTable
                                            headers={keys_arr}
                                            data={datas}
                                            type="assign_lead"
                                            dataLabels={label_arr}
                                            func={(v) => leaddetailfunc(v)}
                                            setpagefuun={v => paginateddata(v)}
                                            pagination={pagination}
                                            selected_lead={selected_lead}
                                            setselected_lead_fun={(v) => setselected_lead(v)}
                                            getreminderfun={(v) => getReminder(v)}
                                            reminder={reminder} /> : '')
                                    }
                                </div>
                                :
                                <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                                    <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '200px', objectFit: 'contain' }} />
                                    <h4 style={{ fontSize: '18px', fontWeight: '700', marginTop: '10px', textAlign: 'center' }}>{search_lead.filter_status ? 'No data Found' : "No leads created yet."}</h4>
                                    <h6 style={{ color: '#747474', fontSize: '13px', maxWidth: '85%', textAlign: 'center', margin: 'auto' }}>{search_lead.filter_status ? 'No data found with provided search options ' : "Visit a contact page and press on a convert icon. Then, you can find the lead on this page."}</h6>
                                </div>

                            }
                        </div>
                    </TabPane>

                    {/* Assign Leads Come here  */}
                    <TabPane tab="Assign Leads" key="2">

                        <Tabs

                            defaultActiveKey="1"
                            activeKey={current_tab1}
                            size="small"
                            style={{ width: '100%', margin: '10px auto', marginBottom: '0px', marginTop: '-10px' }}
                            onChange={(v) => setactivetab1(v)}
                        >
                            <TabPane tab="Leads Assigned To Me" key="1">

                                {Assigneddatas?.length > 0 ?
                                    <div style={{ width: '100%', margin: '0px auto', paddingTop: '0px' }}>
                                        <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between', margin: '0px 0px', padding: '0px 0px', background: 'transparent', }}>
                                            <h6 style={{ marginBottom: '0px', fontWeight: '600', fontSize: '14px' }}>Assigned Leads To Me ({pagination?.total})</h6>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>

                                                {assigned_lead !== '' &&
                                                    <div style={{ display: 'flex' }}>
                                                        <div onClick={() => reassignLeadfunc()} style={{ marginRight: '10px' }}>
                                                            <PrimaryButtonAdd btn_name="Assign Lead" />
                                                        </div>

                                                        {department[0].department_name === 'Transaction Team' ||
                                                            department[0].department_name === 'Project Team' ||
                                                            department[0].department_name === 'Fms Team' &&

                                                            <div onClick={() => refferrleadfunc()} style={{ marginRight: '10px' }}>
                                                                <PrimaryButtonAdd btn_name="Refer Lead" />
                                                            </div>}

                                                    </div>
                                                }

                                            </div>
                                        </div>


                                        <div style={{ width: '100%', marginTop: '15px' }} >
                                            {
                                                (Assigneddatas?.length ? <ReactMaterialTable
                                                    headers={keys_arr}
                                                    data={Assigneddatas}
                                                    type="assign_lead1"
                                                    role_stage={roles.find(r => r === 'db_head')}
                                                    dataLabels={label_arr}
                                                    func={(v) => assignedleaddetailfunc(v)}
                                                    setpagefuun={v => getassignedtomeLeads(v)}
                                                    pagination={pagination}
                                                    selected_lead={assigned_lead}
                                                    setselected_lead_fun={(v) => setassigned_lead(v)}
                                                    setselected_assigned_lead={(v) => setselected_assigned_lead(v)}
                                                    getreminderfun={(v) => getReminder(v)}
                                                    reminder={reminder}
                                                    acceptassignedlead={(v, v1) => acceptassignedlead(v, v1)} /> : '')
                                            }
                                        </div>
                                    </div>

                                    :
                                    <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                                        <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '200px', objectFit: 'contain' }} />
                                        <h4 style={{ fontSize: '18px', fontWeight: '700', marginTop: '10px', textAlign: 'center' }}>No Leads assigned .</h4>
                                        <h6 style={{ color: '#747474', fontSize: '13px', maxWidth: '70%', textAlign: 'center', margin: 'auto' }}>No leads have been assigned to you by any team member yet try again later you might find some leads.</h6>
                                    </div>

                                }
                            </TabPane>

                            <TabPane tab="Leads Assigned By Me" key="2">
                                {Assigneddatas.length > 0 ?
                                    <div style={{ width: '100%', margin: '0px auto', paddingTop: '0px' }}>
                                        <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between', margin: '0px 0px', padding: '0px 0px', background: 'transparent' }}>
                                            <h6 style={{ marginBottom: '0px', fontWeight: '600', fontSize: '14px' }}>Assigned Leads By Me ({pagination?.total})</h6>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>

                                                {selected_lead !== '' &&
                                                    <div style={{ display: 'flex' }}>
                                                        <div onClick={() => assignLeadfunc()} style={{ marginRight: '10px' }}>
                                                            <PrimaryButtonAdd btn_name="Assign Lead" />
                                                        </div>
                                                        {department[0].department_name === 'Transaction Team' ||
                                                            department[0].department_name === 'Project Team' ||
                                                            department[0].department_name === 'Fms Team' &&

                                                            <div onClick={() => refferrleadfunc()} style={{ marginRight: '10px' }}>
                                                                <PrimaryButtonAdd btn_name="Refer Lead" />
                                                            </div>}

                                                    </div>
                                                }

                                            </div>
                                        </div>


                                        <div style={{ width: '100%', marginTop: '15px' }} >
                                            {
                                                <ReactMaterialTable
                                                    headers={keys_arr}
                                                    data={Assigneddatas}
                                                    type="assign_lead2"
                                                    role_stage={roles.includes('db_head')}
                                                    dataLabels={label_arr}
                                                    func={(v) => assignedleaddetailfunc(v)}
                                                    setpagefuun={v => getassignedLeads(v)}
                                                    pagination={pagination}
                                                    selected_lead={assigned_lead}
                                                    setselected_lead_fun={(v) => setassigned_lead(v)}
                                                    getreminderfun={(v) => getReminder(v)}
                                                    reminder={reminder}
                                                    tracker={v => tracker(v)}
                                                />
                                            }
                                        </div>
                                    </div>

                                    :
                                    <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                                        <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '200px', objectFit: 'contain' }} />
                                        <h4 style={{ fontSize: '18px', fontWeight: '700', marginTop: '10px', textAlign: 'center' }}>You have not assigned lead yet.</h4>
                                        <h6 style={{ color: '#747474', fontSize: '13px', maxWidth: '80%', textAlign: 'center', margin: 'auto' }}>Visit a lead page and press on a assign icon. Then, you can find the assigned lead on this page.</h6>
                                    </div>

                                }

                            </TabPane>

                        </Tabs>

                    </TabPane>


                    {/* Referred leads come here  */}
                    <TabPane tab="Referred Leads" key="3">

                        {[].length > 0 ?
                            <>
                                <h1>Referred Leads will come here</h1>
                            </>

                            :
                            <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                                <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '200px', objectFit: 'contain' }} />
                                <h4 style={{ fontSize: '18px', fontWeight: '700', marginTop: '10px', textAlign: 'center' }}>You don't have any referred lead .</h4>
                                <h6 style={{ color: '#747474', fontSize: '13px', maxWidth: '80%', textAlign: 'center', margin: 'auto' }}>Visit a lead page and select on a lead and press on reffer. Then, you can find the referred lead on this page.</h6>

                            </div>

                        }
                    </TabPane>
                </Tabs>
                :
                //  Normal user Tabs 
                <Tabs
                    defaultActiveKey="1"
                    size="small"
                    style={{ width: '90%', margin: '10px auto', marginBottom: '0px' }}
                    onChange={(v) => setactivetab(v)}>

                    <TabPane tab="My Leads" key="1">

                        {datas.length > 0 ?

                            <div style={{ width: '100%', margin: '0px auto', paddingTop: '5px' }}>
                                <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between', margin: '0px 0px', padding: '0px 0px', background: 'transparent',marginTop:'-13px' }}>
                                    <h6 style={{ marginBottom: '0px',fontSize:'15px', fontWeight: 'bolder' }}>Total Leads ({pagination?.total})</h6>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>

                                        {selected_lead !== '' &&
                                            <div onClick={() => refferrleadfunc()} style={{ marginRight: '10px' }}>
                                                <PrimaryButtonAdd btn_name="Refer Lead" />
                                            </div>
                                        }
                                        <div onClick={() => addleadfunc()}>
                                            <PrimaryButtonAdd btn_name="Create Lead" />
                                        </div>
                                    </div>
                                </div>


                                <div style={{ display: 'flex' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', marginLeft: '0px', padding: '5px 10px' }}>
                                        <HiOutlineFilter size={24} style={{ marginRight: '10px' }} />
                                        <h6 style={{ marginBottom: '0px', marginRight: '15px', fontSize: '14px', fontWeight: 'bolder' }}>Filter Lead By</h6>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>


                                            {search_lead.search_text === '' ?
                                                <Dropdown overlay={menu} trigger={['click']}>
                                                    <button type='button' className='btn  btn-light btn-sm m-1'   >
                                                        <GrFormAdd color="white" size={18} style={{ marginRight: '6px', color: 'white' }} />
                                                        <span>Add Search filter</span>
                                                    </button>
                                                </Dropdown>
                                                :
                                                <div style={{ display: 'flex', alignItems: 'center', background: '#f8f9fa', padding: '8px', borderRadius: '2px' }}>
                                                    <h6 style={{ marginBottom: '0px', fontSize: '12px' }}>{search_lead.search_text.slice(0, 20)}{search_lead.search_text.length > 20 && '...'}</h6>
                                                    <IoClose style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => setsearch_lead({ ...search_lead, search_text: '' })} />
                                                </div>

                                            }

                                            {search_lead.lead_stage === '' ?
                                                <Dropdown overlay={menu1} trigger={['click']}>
                                                    <button type='button' className='btn  btn-light btn-sm m-1'   >
                                                        <GrFormAdd color="white" size={18} style={{ marginRight: '6px', color: 'white' }} />
                                                        <span>Add Lead Stage</span>
                                                    </button>
                                                </Dropdown>
                                                :
                                                <div style={{ display: 'flex', alignItems: 'center', background: '#f8f9fa', padding: '8px', borderRadius: '2px', marginLeft: '5px' }}>
                                                    <h6 style={{ marginBottom: '0px', fontSize: '12px' }}>{search_lead.lead_stage.slice(0, 20)}{search_lead.lead_stage.length > 20 && '...'}</h6>
                                                    <IoClose style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => setsearch_lead({ ...search_lead, lead_stage: '' })} />
                                                </div>
                                            }


                                            {search_lead.from_date === '' || search_lead.to_date === '' ?
                                                <div>
                                                    <button type='button' className='btn  btn-light btn-sm m-1' onClick={() => setdatepickervisible(true)}   >
                                                        <GrFormAdd color="white" size={18} style={{ marginRight: '6px', color: 'white' }} />
                                                        <span>Date interval</span>
                                                    </button>

                                                </div>
                                                :
                                                <div style={{ display: 'flex', alignItems: 'center', background: '#f8f9fa', padding: '8px', borderRadius: '2px', margin: '0px 5px' }}>
                                                    <h6 style={{ marginBottom: '0px', fontSize: '12px' }}>{search_lead.from_date + ':' + search_lead.to_date}</h6>
                                                    <IoClose style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => setsearch_lead({ ...search_lead, from_date: '', to_date: '' })} />
                                                </div>
                                            }


                                            <div style={{ marginRight: '5px' }} onClick={applyfilter}>
                                                <PrimaryButtonFilter btn_name="Apply Filter" padding={'2px 8px'} />
                                            </div>
                                            <div style={{ marginRight: '5px' }} onClick={resetfilter}>
                                                <PrimaryButtonFilter btn_name="Reset Filter" padding={'2px 8px'} />
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div style={{ width: '100%', }} >
                                    {
                                        (datas.length ? <ReactMaterialTable
                                            headers={keys_arr}
                                            data={datas}
                                            type="assign_lead"
                                            dataLabels={label_arr}
                                            func={(v) => leaddetailfunc(v)}
                                            setpagefuun={v => paginateddata(v)}
                                            pagination={pagination}
                                            selected_lead={selected_lead}
                                            setselected_lead_fun={(v) => setselected_lead(v)}
                                            getreminderfun={(v) => getReminder(v)}
                                            reminder={reminder} /> : '')
                                    }
                                </div>
                            </div>
                            :
                            <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                                <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '200px', objectFit: 'contain' }} />
                                <h4 style={{ fontSize: '18px', fontWeight: '700', marginTop: '10px', textAlign: 'center' }}>You don't have any lead created yet.</h4>
                                <h6 style={{ color: '#747474', fontSize: '13px', maxWidth: '80%', textAlign: 'center', margin: 'auto' }}>Visit a contact page and press on a convert icon. Then, you can find the lead on this page.</h6>
                            </div>

                        }

                    </TabPane>

                    <TabPane tab="Assign Leads" key="2">
                        {Assigneddatas.length > 0 ?
                            <>
                                <h6 style={{ margin: '15px 0px', fontWeight: '600', fontSize: '14px',marginTop:'-5px' }}>Assigned Leads To Me  ({Assigneddatas?.length})</h6>
                                {
                                    (Assigneddatas.length ? <ReactMaterialTable headers={keys_arr} data={Assigneddatas} type="assign_lead3" dataLabels={label_arr} func={(v) => assignedleaddetailfunc(v)} setpagefuun={v => paginateddata(v)} pagination={pagination} selected_lead={selected_lead} setselected_lead_fun={(v) => setselected_lead(v)} getreminderfun={(v) => getReminder(v)} reminder={reminder} acceptassignedlead={(v, v1) => acceptassignedlead(v, v1)} noshare={true} /> : '')
                                }
                            </>

                            :
                            <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                                <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '200px', objectFit: 'contain' }} />
                                <h4 style={{ fontSize: '18px', fontWeight: '700', marginTop: '10px', textAlign: 'center' }}>You have not assigned lead yet.</h4>
                                <h6 style={{ color: '#747474', fontSize: '13px', maxWidth: '80%', textAlign: 'center', margin: 'auto' }}>Visit a lead page and press on a assign icon. Then, you can find the assigned lead on this page.</h6>
                            </div>
                        }
                    </TabPane>

                    <TabPane tab="Referred Leads" key="3">
                        {[].length > 0 ?
                            <>
                                <h6 style={{ margin: '10px 0px', fontWeight: '600', fontSize: '14px' }}>Referred Leads To Me  ({Assigneddatas?.length})</h6>

                                {
                                    ([].length ? <ReactMaterialTable headers={keys_arr} data={[]} type="assign_lead1" dataLabels={label_arr} func={(v) => leaddetailfunc(v)} setpagefuun={v => paginateddata(v)} pagination={pagination} selected_lead={selected_lead} setselected_lead_fun={(v) => setselected_lead(v)} getreminderfun={(v) => getReminder(v)} reminder={reminder} /> : '')
                                }
                            </>

                            :
                            <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                                <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '200px', objectFit: 'contain' }} />
                                <h4 style={{ fontSize: '18px', fontWeight: '700', marginTop: '10px', textAlign: 'center' }}>You don't have any referred lead .</h4>
                                <h6 style={{ color: '#747474', fontSize: '13px', maxWidth: '80%', textAlign: 'center', margin: 'auto' }}>Visit a lead page and select on a lead and press on reffer. Then, you can find the referred lead on this page.</h6>

                            </div>

                        }
                    </TabPane>

                </Tabs>
            }

            {/* Date picker modal  */}
            <Modal visible={datepickervisible} footer={false} onCancel={() => setdatepickervisiblefun()} closable={false} style={{ top: '21%', maxWidth: '350px' }}>
                <div style={{ maxWidth: '200px', }}>
                <IoIosClose  onClick={() => setdatepickervisiblefun()}size={20} style={{position:'absolute',top:'20px',cursor:'pointer',right:'25px'}} />
                    <h6 style={{ padding: '2%', fontWeight: '500', fontSize: '13px' }}>Select Date range option</h6>
                    <Row style={{ width: '300px', margin: 'auto', display: 'flex' }}>
                        <Col span={12} style={{ background: 'white', padding: '0px 5px', width: '100%' }}>
                            <h6 style={{ fontSize: '12px' }}>From Date</h6>
                            <DateField
                                onChangefun={handleChange}
                                name={'from_date'}
                                type="1"
                                date_value={tempsearch_lead.from_date}
                                style={{ width: '100%' }} />
                        </Col>

                        <Col span={12} style={{ background: 'white', padding: '0px 5px' }}>
                            <h6 style={{ fontSize: '12px' }}>To Date</h6>
                            <DateField
                                name={'to_date'}
                                type="1"
                                onChangefun={handleChange}
                                date_value={tempsearch_lead.to_date}
                                style={{ width: '100%' }} />
                        </Col>

                        <div style={{ marginLeft: '5px' }} onClick={setselectedDateRange}>
                            <PrimaryButton btn_name={'Apply'} />
                        </div>
                    </Row>
                </div>
            </Modal>

            {/* Contact picker modal */}
            <Modal visible={isModalVisible} closable={false} onCancel={handleCancel} style={{maxWidth:'300px'}} centered footer={false}>
                <h1 style={{ fontSize: '15px',  }}>Choose Contact</h1>
                <IoIosClose  onClick={handleCancel}size={20} style={{position:'absolute',top:'20px',cursor:'pointer',right:'18px'}} />
                <Select
                    showSearch
                    placeholder="Select Contact"
                    optionFilterProp="children"
                    onChange={handleChangeContact}
                    size='medium'
                    filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    style={{ width: '100%' }}
                >
                    {
                        contacts.map((contact) => (
                            <>
                                <Option key={contact.id} value={contact.id}><div>{contact.contact_name}-<strong>{contact.company_name}</strong></div></Option>
                            </>
                        ))
                    }
                </Select>

                <div>
                    <PrimaryButton btn_name="Convert to Lead" onClick={() => convert_tolead()}></PrimaryButton>
                </div>

            </Modal>

            {/* Assigned picker modal */}
            <Modal visible={AssignModalVisible} closable={false} onCancel={() => setAssignModalVisible(false)} footer={false} width={300} style={{ width: '200px' }} centered>
                <h6 style={{fontSize:'15px'}}>Assigned Lead </h6>
                <IoIosClose  onClick={() => setAssignModalVisible(false)}size={20} style={{position:'absolute',top:'20px',cursor:'pointer',right:'18px'}} />

                <Row>
                    <Col span={24}>
                        <div style={{ width: '96%', margin: ' 10px auto', }}>
                            {(roles.find(r => r === 'admin') || roles.find(r => r === 'db_head') || roles.find(r => r === 'db_user') || roles.find(r => r === 'db_sub_user')) && <h6 style={{ fontSize: '12px' }}>Select Department</h6>}
                            {(roles.includes('admin') || roles.includes('db_head') || roles.includes('db_user') || roles.find(r => r === 'db_sub_user')) &&
                                <>
                                    {(roles.includes === 'admin' || roles.includes === 'db_head' || roles.includes === 'db_user' || roles.includes === 'db_sub_user' || department[0].department_name === 'Database Team' || department[0].department_name === 'Media Team') ?
                                        <>
                                            <Select
                                                value={selected_department.department_name}
                                                disabled
                                                showSearch
                                                placeholder="Select Department"
                                                optionFilterProp="children"
                                                onChange={(v) => handleChangefun(v, 'department_id')}
                                                size='medium'
                                                filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                style={{ width: '100%' }}
                                            >
                                                {
                                                    departement_arr.map((d) => (
                                                        <Option disabled
                                                            key={d.id} value={d.id}><div>{d.value}</div></Option>
                                                    ))
                                                }
                                            </Select>
                                        </>

                                        :

                                        <Select
                                            value={selected_department.department_name}
                                            disabled
                                            showSearch
                                            placeholder="Select Department"
                                            optionFilterProp="children"
                                            onChange={(v) => handleChangefun(v, 'department_id')}
                                            size='medium'
                                            filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            style={{ width: '100%' }}
                                        >
                                            {
                                                departement_arr.map((d) => (
                                                    <Option
                                                        key={d.id} value={d.id}><div>{d.value}</div></Option>
                                                ))
                                            }
                                        </Select>

                                    }
                                </>
                            }
                        </div>
                    </Col>

                    {(roles.includes('admin') || roles.includes('db_head') || roles.includes('db_user') || roles.includes('db_sub_user')) &&
                        <Col span={24}>
                            <div style={{ width: '96%', margin: 'auto' }}>
                                <h6 style={{ fontSize: '12px' }}>Select HOD</h6>

                                <Select
                                    showSearch
                                    value={assign_data?.hod_name}
                                    placeholder="Select HOD"
                                    optionFilterProp="children"
                                    onChange={(v) => handleChangefun(v, 'hod_id')}
                                    size='medium'
                                    filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    style={{ width: '100%',textTransform:'capitalize' }}
                                >
                                    {
                                        hod_arr.map((h) => (
                                            <Option key={h?.id} value={h?.id}><div style={{fontWeight:'500',fontSize:'13px',textTransform:'capitalize'}}>{h?.name}</div></Option>
                                        ))
                                    }
                                </Select>
                            </div>

                        </Col>
                    }

                    {(roles.includes('hod') && !roles.includes('db_head') && !roles.includes('db_user') && !roles.includes('db_sub_user')) && !roles.includes('admin') &&
                        <Col span={24}>
                            <div style={{ width: '96%', margin: 'auto' }}>
                                <h6 style={{ fontSize: '12px' }}>Select Employee/Manager</h6>

                                <Select
                                    showSearch
                                    value={assign_data?.employee_name}
                                    placeholder="Select Employee/Manager"
                                    optionFilterProp="children"
                                    onChange={(v) => handleChangefun(v, 'manager_id')}
                                    size='medium'
                                    filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    style={{ width: '100%' }}
                                >
                                    {
                                        managers_arr.map((h) => (
                                            <Option key={h?.id} value={h?.id}><div style={{fontWeight:'500',fontSize:'13px',textTransform:'capitalize'}}>{h?.name}</div></Option>
                                        ))
                                    }
                                </Select>
                            </div>

                        </Col>}

                    {(roles.includes('manager') && !roles.includes('hod') && !roles.includes('db_head') && !roles.includes('db_user') && !roles.includes('db_sub_user')) && !roles.includes('admin') &&
                        <Col span={24}>
                            <div style={{ width: '96%', margin: 'auto' }}>
                                <h6 style={{ fontSize: '12px' }}>Select Employee/Manager</h6>

                                <Select
                                    showSearch
                                    value={assign_data?.employee_name}
                                    placeholder="Select Employee/Manager"
                                    optionFilterProp="children"
                                    onChange={(v) => handleChangefun(v, 'manager_id')}
                                    size='medium'
                                    filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    style={{ width: '100%' }}
                                >
                                    {
                                        employee_arr?.map((h) => (
                                            <Option key={h?.id} value={h?.id}><div style={{fontWeight:'500',fontSize:'13px',textTransform:'capitalize'}}>{h?.name}</div></Option>
                                        ))
                                    }
                                </Select>
                            </div>

                        </Col>}

                    <Col span={24} >
                        <div style={{ width: '96%', margin: 'auto', marginTop: '10px' }}>
                            <h6 style={{ fontSize: '12px' }}>Remarks</h6>
                            <TextArea rows={4} value={assign_data.remarks} style={{fontSize:'13px'}} onChange={(e) => setassign_data({ ...assign_data, remarks: e.target.value })} />
                        </div>
                    </Col>

                    <div style={{ width: '96%', margin: 'auto', marginTop: '10px' }}>
                        <p style={{ fontSize: '10px', padding: '10px', backgroundColor: '#def0ff',border:'1px solid #236499', fontWeight: '700' }}><strong>Note:</strong> Please add remark so the assigned user to get know what steps needed to take care for this assigned lead</p>
                    </div>

                </Row>



                <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', marginRight: '5px' }} onClick={approvelead}>
                    <PrimaryButton btn_name="Assign lead" />
                </div>

                {/* <div style={{display:'flex',alignItems:'flex-end',justifyContent:'flex-end',marginRight:'5px'}} onClick={approvelead1}>
                 <PrimaryButton btn_name="Assign lead" /> 
                 </div> */}
            </Modal>

            <Modal visible={ReferModalVisible} onCancel={() => setReferModalVisible(false)} footer={false} width={300} style={{ width: '200px' }} centered>
                <h6>Referred Lead </h6>

                <Row>
                    <Col span={24}>
                        <div style={{ width: '96%', margin: ' 10px auto', }}>
                            <h6 style={{ fontSize: '12px' }}>Select Department</h6>

                            <Select
                                showSearch
                                placeholder="Select Department"
                                optionFilterProp="children"
                                onChange={(v) => handleChange(v, 'department_id')}
                                size='medium'
                                disabled
                                filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                value={'Database Team'}
                                style={{ width: '100%' }}
                            >
                                {
                                    departement_arr.map((d) => (
                                        <Option key={d.id} value={d.id}><div>{d.value}</div></Option>
                                    ))
                                }
                            </Select>
                        </div>
                    </Col>

                    <Col span={24}>
                        <div style={{ width: '96%', margin: 'auto' }}>
                            <h6 style={{ fontSize: '12px' }}>Refer Note <span style={{ fontSize: '12px', color: 'gray' }}>(* add team you want to referr and what they are required)</span> </h6>

                            <TextArea />
                        </div>

                    </Col>
                </Row>



                <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', marginRight: '5px' }}>
                    <PrimaryButton btn_name="Refer lead" />
                </div>

            </Modal>

            <Modal visible={cancellationModalVisible} onCancel={() => setcancellationModalVisible(false)} footer={false} width={300} style={{ width: '180px', maxWidth: '280px', height: '200px' }} centered>
                <h6>Cancellation reason </h6>

                <Row>
                    <Col span={24}>
                        <div style={{ width: '96%', margin: ' 10px auto', }}>
                            <TextArea value={cancellation_remark} onChange={(e) => setcancellation_remark(e.target.value)} />
                        </div>
                    </Col>
                </Row>



                <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', marginRight: '5px' }} onClick={rejectassignedlead}>
                    <PrimaryButton btn_name="Submit " />
                </div>

            </Modal>




        </div>
    )
}

export default ViewLead
