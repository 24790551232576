import React, { useEffect, useState } from 'react'
import { Select, Input } from 'antd'
import {AiOutlineInfoCircle} from 'react-icons/ai';
import { SmallText } from '../../../../helpers/constants/ConstantsText'
import axios from 'axios';
import FmsDashboardMainRoot from '../../FmsDashboardMainRoot';
import TextArea from 'antd/lib/input/TextArea'
import PrimaryButton, { SecondaryButton } from '../../../../components/buttons/PrimaryButton';
import { useLocation, useNavigate } from 'react-router-dom';
import { Toaster, toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';

import { authHeader } from '../../../../helpers/usertoken/UserToken';

function WorkAssignedCE() {

  const {state,pathname} = useLocation()
  const navigate = useNavigate()
  const token = useSelector(state=>state.Auth.access_token)


  const AuthHeader = {headers:{
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
  }}

  const [loader,setloader] = useState(false)

  const path = pathname?.split('/')[pathname?.split('/')?.length - 1]
  const roles = useSelector(state=>state.Auth.roles)

  const [fmsEmployee,setfmsEmployee] = useState([])

  const [data,setdata] = useState({
    assigned_employee:[],
    title:'',
    description:'',
    dead_line:'',
    status:'',
    remarks:'',
  })  



    const [error,seterror] = useState({
      assigned_employee:'',
      title:'',
      description:'',
      dead_line:'',
      status:'',
      remarks:'',
    })  

    useEffect(()=>{
        if(path === 'edit'){
          let arr = []
          state.assigned_employee?.forEach((d)=>{
            arr.push({value:d?._id,label:d?.name})
          }) 
            setdata({...state,assigned_employee:arr,dead_line:state?.dead_line !== '' ?state?.dead_line?.slice(0,10):''})
        }
    },[])

 

    function submitData(){
      if(!data?.title){
        seterror({...error,title:'This Field is required*'})
      }else if(!data?.description){
        seterror({...error,description:'This Field is required*'})
      }else if(!data?.dead_line){
        seterror({...error,dead_line:'This Field is required*'})
      }else if(data?.assigned_employee.length === 0){
        seterror({...error,assigned_employee:'This Field is required*'})
      }else{
        submit()
      }
    }


    function submit(){
        if(path === 'create'){
            
            let send_Data = {...data}
            let arr = []
            data?.assigned_employee?.map((e)=>{
              arr?.push(e?.value)
            })
            send_Data['assigned_employee'] = arr
            send_Data['status'] = 'Pending'

            axios.post('fms/work_assigned/create',send_Data,AuthHeader)
            .then((res)=>{
                if(res.status === 201){
                    toast.success('Team Work Created Successfully')
                    resetForm()
                }
            }).catch((err)=>{
            })
        }else if(path === 'edit'){
            let send_Data = {...data}
            let arr = []
            data?.assigned_employee?.map((e)=>{
              arr?.push(e?.value)
            })
            send_Data['assigned_employee'] = arr
            
            axios.put(`fms/work_assigned/update/${state?._id}`,send_Data,AuthHeader)
            .then((res)=>{
                if(res.status === 200){
                    toast.success('Team Work Updated Successfully')
                    resetForm()
                }
            }).catch((err)=>{
            })
        }
    }

  function resetForm(){
     setdata({
      assigned_employee:[],
      title:'',
      description:'',
      dead_line:'',
      status:'',
      remarks:'',
     })
     seterror({
      assigned_employee:'',
      title:'',
      description:'',
      dead_line:'',
      status:'',
      remarks:'',
     })
     navigate(-1)
  }

  async function searchuser(v){ 
    await axios.get(`fms/fms_in_house_employee/search?search=${v}`,authHeader)
        .then((res)=>{
            let arr = []
            res?.data?.datas?.forEach(d => {
                arr.push({label:`${d?.name}[Employee Id : ${d?.employee_id}]`,value:d?._id})
            });
            setfmsEmployee(arr)
            return arr
        }).catch((err)=>{
        })
  }        

  function handleChange1(v){  
       let arr = []
       v?.forEach((v2)=>{
        let employee = fmsEmployee?.find((v1)=>v1?.value === v2)
        let employee1 = data?.assigned_employee?.find((v1)=>v1?.value === v2)
        if(employee !== null && employee !== undefined){
          arr?.push(employee)
        }
        if(employee1 !== null && employee1 !== undefined){
          arr?.push(employee1)
        }
       }) 
       setdata({...data,assigned_employee:arr})
  }

  const optionsArr = [{label:'Pending',value:'Pending'},{label:'Progress',value:'Progress'},{label:'Done',value:'Done'},{label:'Completed',value:'Completed'},{label:'Not Completed',value:'Not Completed'}]

 

  return (
    <FmsDashboardMainRoot>
        <Toaster />

       
        {!loader &&
        <div >
            <h6>Create/Edit Team Work</h6>
            <h6 style={{fontSize:'10px',background:'#3d7aba',fontWeight:'600',color:'#fff',padding:6,width:'300px'}}>Use the below form to create or update the team work created by you.</h6>

                {/* <SmallText fontWeight={'600'} val={'Type'}  /> */}
                {/* <Select readOnly={path === 'edit'} value={data?.type} style={{borderLeft:'4px solid #292961',width:'300px'}}  options={options} onChange={(v)=>setdata({...data,type:v})}  /> */}

                <div style={{display:'flex',marginTop:'4px'}}>
                    {(error?.type) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.type}</p>
                </div>

                <div style={{display:'flex',width:'800px',marginTop:'10px'}}>
                <div style={{width:'300px'}}>
                <SmallText fontWeight={'600'} val={'Title'}  />
                <Input readOnly={path === 'edit'} type="text"  style={{borderLeft:'4px solid #292961',marginBottom:'10px'}}  value={data.title} onChange={(e)=>{setdata({...data,title:e.target.value});seterror({...error,title:''})}} />

                <div style={{display:'flex',marginTop:'4px'}}>
                    {(error?.title) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                    <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.title}</p>
                </div>

                <div  style={{margin:'0px 0px'}}>
                  <SmallText marginfun={'0px 0px 10px 0px'} fontWeight={'600'} val={'Description'}  />
                  <TextArea readOnly={path === 'edit'} style={{borderLeft:'4px solid #292961',}} value={data?.description} onChange={(e)=>{setdata({...data,description:e.target.value});seterror({...error,description:''})}} />  
                  <div  style={{display:'flex',marginTop:'4px'}}>
                      {(error?.description) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                      <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.description}</p>
                  </div>
                </div> 

                <div style={{marginTop:'10px'}}>
                  <SmallText fontWeight={'600'} val={'DeadLine'}  />
                  <Input readOnly={path === 'edit'} type="date"  style={{borderLeft:'4px solid #292961',marginBottom:'10px'}}  value={data.dead_line} onChange={(e)=>{setdata({...data,dead_line:e.target.value});seterror({...error,dead_line:''})}} />

                  <div style={{display:'flex',marginTop:'4px'}}>
                      {(error?.dead_line) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                      <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.dead_line}</p>
                  </div>
                </div>


                <SmallText marginfun={'0px 0px 10px 0px'} fontWeight={'600'} val={'Assigned Employee'}  />

                <Select 
                    showSearch
                    cacheOptions
                    mode="multiple"
                    filterOption={false}
                    allowClear={true}
                    value={data?.assigned_employee}
                    onSearch={searchuser}
                    placeholder="" 
                    closeMenuOnSelect={true}
                    onChange={(v)=>handleChange1(v,'employee')}
                    options={fmsEmployee}
                    style={{ width: '100%',borderLeft:'4px solid #292961',marginRight:'5px' }}
                  />

                {path === 'edit' &&
                <>
                <div  style={{margin:'10px 0px'}}>
                  <SmallText marginfun={'0px 0px 10px 0px'} fontWeight={'600'} val={'Remarks'}  />
                  <TextArea style={{borderLeft:'4px solid #292961',}} value={data?.remarks} onChange={(e)=>{setdata({...data,remarks:e.target.value});seterror({...error,remarks:''})}} />  
                  <div  style={{display:'flex',marginTop:'4px'}}>
                      {(error?.remarks) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                      <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.remarks}</p>
                  </div>
                </div>
                

                <SmallText marginfun={'0px 0px 10px 0px'} fontWeight={'600'} val={'Assigned Employee'}  />

                <Select 
                    value={data?.status}
                    placeholder="" 
                    onChange={(v)=>setdata({...data,status:v})}
                    options={optionsArr}
                    style={{ width: '100%',borderLeft:'4px solid #292961',marginRight:'5px' }}
                  />
                </>
                }

               
                </div> 

                
                </div>

                <div style={{display:'flex',marginTop:'10px',flexDirection:'row'}}>
                 <PrimaryButton btn_name={'Save'} onClick={submitData}/>
                 <h6 style={{width:'5px'}}></h6>
                 <SecondaryButton btn_name={'Cancel'} onClick={()=>navigate(-1)} />
                </div>



        </div>}
    </FmsDashboardMainRoot>
  )
}

export default WorkAssignedCE