import React, { useEffect, useState } from 'react'
import { Select, Input } from 'antd'
import {AiOutlineInfoCircle} from 'react-icons/ai';
import { SmallText } from '../../../../helpers/constants/ConstantsText'
import axios from 'axios';
import FmsDashboardMainRoot from '../../FmsDashboardMainRoot';
import TextArea from 'antd/lib/input/TextArea'
import PrimaryButton, { SecondaryButton } from '../../../../components/buttons/PrimaryButton';
import { useLocation, useNavigate } from 'react-router-dom';
import { Toaster, toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import {
    setKey,
    fromLatLng,
  
  } from "react-geocode";
import { theme_color } from '../../../../helpers/constants/ConstantColor';

function JobCardCE() {


  const options = [{label:'Inside Job Card',value:'Inside Job Card'},{label:'Outside Job Card',value:'Outside Job Card'}]

  const {state,pathname} = useLocation()
  const navigate = useNavigate()
  const token = useSelector(state=>state.Auth.access_token)


  const AuthHeader = {headers:{
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
  }}

  const [loader,setloader] = useState(false)
 

  const path = pathname?.split('/')[pathname?.split('/')?.length - 1]
  const roles = useSelector(state=>state.Auth.roles)

  const [data,setdata] = useState({
    latitude:'',
    longitude:'',
    address:'',
    image:'',
    title:'',
    description:'',
    location:'',
    type:'Inside Job Card',
    contact_person:'',
    mobile:'',
    email:'',
    date:'',
  })  



    const [error,seterror] = useState({
        latitude:'',
        longitude:'',
        address:'',
        image:'',
        title:'',
        description:'',
        location:'',
        type:'',
        contact_person:'',
        mobile:'',
        email:'',
        date:'',
    })  

    useEffect(()=>{
        if(path === 'edit'){
            if(state?.type == 'Inside Job Card'){
               let send_Data = {...data,...state}
               setdata({...send_Data})
            }else{
                setloader(true)
                if (navigator.geolocation) {
                    setKey('AIzaSyBmoP0njHe6YMjbWNaPHZDWWdRpWhJAjIw')
                    navigator.geolocation.getCurrentPosition(success, errorfun);
                }
            }
        }else{
              
            if(state == '2'){
                setloader(true)
                if (navigator.geolocation) {
                    setKey('AIzaSyBmoP0njHe6YMjbWNaPHZDWWdRpWhJAjIw')
                    navigator.geolocation.getCurrentPosition(success, errorfun);
                }
            }else if(state == '1'){
                setdata({...data,type:state == '1' ? 'Inside Job Card' : 'Outside Job Card'})

            }
        }
    },[])

    function success(position) {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;

        fromLatLng(latitude, longitude)
            .then(({ results }) => {
                setloader(false)
                const address = results[0].formatted_address;
                if(path === 'edit'){
                  setdata({...state,exit_latitude:latitude,exit_longitude:longitude,exit_address:address});
                }else{
                  setdata({...data,latitude, longitude,type:state == '1' ? 'Inside Job Card' : 'Outside Job Card',address});
                }
            }).catch((err)=>{
                setloader(false)
                if(path === 'edit'){
                  setdata({...state,exit_latitude:latitude,exit_longitude:longitude,exit_address:"Location Not Availble From Geocode"});
                }else{
                  setdata({...data,latitude, longitude,type:state == '1' ? 'Inside Job Card' : 'Outside Job Card',address:"Location Not Availble From Geocode"});
                }
            })

    
      
    }
    
    function errorfun() {
        console.log("Unable to retrieve your location");
    }

    function submitData(){
        if(data?.type === 'Inside Job Card'){
            if(!data?.title){
                seterror({...error,title:'This Field is required *'})
            }else if(!data?.description){
                seterror({...error,description:'This Field is required *'})
            }else{
                submit()
            }
        }else if(data?.type === 'Outside Job Card'){
            if(!data?.title){
                seterror({...error,title:'This Field is required *'})
            }else if(!data?.description){
                seterror({...error,description:'This Field is required *'})
            }else if(!data?.location){
                seterror({...error,location:'This Field is required *'})
            }else{
                submit()
            }
        }
    }


    function submit(){
        if(path === 'create'){
            
            let send_Data = {...data}

            axios.post('fms/job_card/create',send_Data,AuthHeader)
            .then((res)=>{
                if(res.status === 201){
                    toast.success('Job Card Created Successfully')
                    resetForm()
                }
            }).catch((err)=>{
            })
        }else if(path === 'edit'){
            let send_Data = {...data}
            
            axios.put(`fms/job_card/update/${state?._id}`,send_Data,AuthHeader)
            .then((res)=>{
                if(res.status === 200){
                    toast.success('Job Card Updated Successfully')
                    resetForm()
                }
            }).catch((err)=>{
            })
        }
    }

  function resetForm(){
     setdata({
        latitude:'',
        longitude:'',
        address:'',
        image:'',
        title:'',
        description:'',
        location:'',
        type:'',
        contact_person:'',
        mobile:'',
        email:'',
        date:'',
     })
     seterror({
        latitude:'',
        longitude:'',
        address:'',
        image:'',
        title:'',
        description:'',
        location:'',
        type:'',
        contact_person:'',
        mobile:'',
        email:'',
        date:'',
     })
     navigate(-1)
  }

 

  return (
    <FmsDashboardMainRoot>
        <Toaster />

        {loader && 
         
         <div style={{padding:5,width:'300px',background:'#0e68bf'}}>
            <h6 style={{fontSize:'12px',}}>Wait for few seconds we are capturing your location to upload outside job card</h6>
         </div>
        }
        {!loader &&
        <div >
            <h6>Create/Edit Job Card</h6>
            <h6 style={{fontSize:'10px',background:'#3d7aba',fontWeight:'600',color:'#fff',padding:6,width:'300px'}}>Use the below form to create or update the job card created by you.</h6>

                {/* <SmallText fontWeight={'600'} val={'Type'}  /> */}
                {/* <Select readOnly={path === 'edit'} value={data?.type} style={{borderLeft:'4px solid #292961',width:'300px'}}  options={options} onChange={(v)=>setdata({...data,type:v})}  /> */}

                <div style={{display:'flex',marginTop:'4px'}}>
                    {(error?.type) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.type}</p>
                </div>

                <div style={{display:'flex',width:'800px',marginTop:'10px'}}>
                <div style={{width:'300px'}}>
                <SmallText fontWeight={'600'} val={'Title'}  />
                <Input readOnly={path === 'edit'} type="text"  style={{borderLeft:'4px solid #292961',marginBottom:'10px'}}  value={data.title} onChange={(e)=>{setdata({...data,title:e.target.value});seterror({...error,title:''})}} />

                <div style={{display:'flex',marginTop:'4px'}}>
                    {(error?.title) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                    <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.title}</p>
                </div>

                

                <div  style={{margin:'0px 0px'}}>
                <SmallText marginfun={'0px 0px 10px 0px'} fontWeight={'600'} val={'Description'}  />
                 <TextArea readOnly={path === 'edit'} style={{borderLeft:'4px solid #292961',}} value={data?.description} onChange={(e)=>{setdata({...data,description:e.target.value});seterror({...error,description:''})}} />  
                 <div  style={{display:'flex',marginTop:'4px'}}>
                    {(error?.description) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                    <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.description}</p>
                </div>
                </div> 

                {data?.type === 'Outside Job Card' && ['fms_crm_head', 'admin', 'fms_hr']?.filter((f)=>roles?.includes(f))?.length > 0 &&
                <>
                <div  style={{margin:'0px 0px'}}>
                <SmallText marginfun={'0px 0px 10px 0px'} fontWeight={'600'} val={'Latitude'}  />
                 <Input  readOnly={true} style={{borderLeft:'4px solid #292961',}} value={data?.latitude} onChange={(e)=>{setdata({...data,latitude:e.target.value});seterror({...error,latitude:''})}} />  
                 <div  style={{display:'flex',marginTop:'4px'}}>
                    {(error?.latitude) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                    <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.latitude}</p>
                </div>
                </div> 


                <div  style={{margin:'0px 0px'}}>
                <SmallText marginfun={'0px 0px 10px 0px'} fontWeight={'600'} val={'Longitude'}  />
                 <Input readOnly={true} style={{borderLeft:'4px solid #292961',}} value={data?.longitude} onChange={(e)=>{setdata({...data,longitude:e.target.value});seterror({...error,longitude:''})}} />  
                 <div  style={{display:'flex',marginTop:'4px'}}>
                    {(error?.longitude) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                    <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.longitude}</p>
                </div>
                </div>

                <div  style={{margin:'0px 0px'}}>
                <SmallText marginfun={'0px 0px 10px 0px'} fontWeight={'600'} val={'Address'}  />
                 <TextArea readOnly={true} style={{borderLeft:'4px solid #292961',}} value={data?.address} onChange={(e)=>{setdata({...data,address:e.target.value});seterror({...error,address:''})}} />  
                 <div  style={{display:'flex',marginTop:'4px'}}>
                    {(error?.address) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                    <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.address}</p>
                </div>
                </div> 

                <div  style={{margin:'0px 0px'}}>
                <SmallText marginfun={'0px 0px 10px 0px'} fontWeight={'600'} val={'Exit Latitude'}  />
                 <Input  readOnly={true} style={{borderLeft:'4px solid #292961',}} value={data?.exit_latitude} onChange={(e)=>{setdata({...data,exit_latitude:e.target.value});seterror({...error,exit_latitude:''})}} />  
                 <div  style={{display:'flex',marginTop:'4px'}}>
                    {(error?.exit_latitude) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                    <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.exit_latitude}</p>
                </div>
                </div> 


                <div  style={{margin:'0px 0px'}}>
                <SmallText marginfun={'0px 0px 10px 0px'} fontWeight={'600'} val={'Exit Longitude'}  />
                 <Input readOnly={true} style={{borderLeft:'4px solid #292961',}} value={data?.exit_longitude} onChange={(e)=>{setdata({...data,exit_longitude:e.target.value});seterror({...error,exit_longitude:''})}} />  
                 <div  style={{display:'flex',marginTop:'4px'}}>
                    {(error?.exit_longitude) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                    <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.exit_longitude}</p>
                </div>
                </div>

                <div  style={{margin:'0px 0px'}}>
                <SmallText marginfun={'0px 0px 10px 0px'} fontWeight={'600'} val={'Exit Address'}  />
                 <TextArea readOnly={true} style={{borderLeft:'4px solid #292961',}} value={data?.exit_address} onChange={(e)=>{setdata({...data,exit_address:e.target.value});seterror({...error,exit_address:''})}} />  
                 <div  style={{display:'flex',marginTop:'4px'}}>
                    {(error?.exit_address) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                    <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.exit_address}</p>
                </div>
                </div> 
                </>}
                
               {data?.type === 'Outside Job Card' &&
                <>
                <div  style={{margin:'0px 0px'}}>
                <SmallText marginfun={'0px 0px 10px 0px'} fontWeight={'600'} val={'Location'}  />
                 <TextArea readOnly={path === 'edit'} style={{borderLeft:'4px solid #292961',}} value={data?.location} onChange={(e)=>{setdata({...data,location:e.target.value});seterror({...error,location:''})}} />  
                 <div  style={{display:'flex',marginTop:'4px'}}>
                    {(error?.location) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                    <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.location}</p>
                </div>
                </div> 

                <SmallText fontWeight={'600'} val={'Contact Person'}  />
                <Input readOnly={path === 'edit'} type="text"  style={{borderLeft:'4px solid #292961',marginBottom:'10px'}}  value={data.contact_person} onChange={(e)=>{setdata({...data,contact_person:e.target.value});seterror({...error,contact_person:''})}} />

                <div style={{display:'flex',marginTop:'4px'}}>
                    {(error?.contact_person) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                    <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.contact_person}</p>
                </div>

                <SmallText fontWeight={'600'} val={'Mobile'}  />
                <Input readOnly={path === 'edit'} type="text"  style={{marginBottom:'10px'}}  value={data.mobile} onChange={(e)=>{setdata({...data,mobile:e.target.value});seterror({...error,mobile:''})}} />

                <div style={{display:'flex',marginTop:'4px'}}>
                    {(error?.mobile) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                    <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.mobile}</p>
                </div>

                <SmallText fontWeight={'600'} val={'Email'}  />
                <Input readOnly={path === 'edit'} type="text"  style={{marginBottom:'10px'}}  value={data.email} onChange={(e)=>{setdata({...data,email:e.target.value});seterror({...error,email:''})}} />

                <div style={{display:'flex',marginTop:'4px'}}>
                    {(error?.email) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                    <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.email}</p>
                </div>
                
                </>}
 



                
                {path === 'edit' &&
                    <div  style={{margin:'10px 0px'}}>
                    <SmallText  marginfun={'10px 0px 10px 0px'} fontWeight={'600'} val={'Remarks'}  />

                    <TextArea style={{borderLeft:'4px solid #292961',}} value={data?.remarks} onChange={(e)=>{setdata({...data,remarks:e.target.value});seterror({...error,remarks:''})}} />  
                    <div style={{display:'flex',marginTop:'4px'}}>
                        {(error?.remarks) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                        <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.remarks}</p>
                    </div>
                </div>} 

               
               
                </div> 

                
                </div>

                {!roles?.includes('fms_data_view') &&
                <div style={{display:'flex',flexDirection:'row'}}>
                 <PrimaryButton btn_name={'Save'} onClick={submitData}/>
                 <h6 style={{width:'5px'}}></h6>
                 <SecondaryButton btn_name={'Cancel'} onClick={()=>navigate(-1)} />
                </div>}



        </div>}
    </FmsDashboardMainRoot>
  )
}

export default JobCardCE