import React,{useState} from 'react'
import InputField from '../../../components/inputcomponents/InputField'
import PrimaryButton from '../../../components/buttons/PrimaryButton'
import {IoMdClose} from 'react-icons/io'
import {message} from 'antd'
import axios from 'axios'
import { authHeader } from '../../../helpers/usertoken/UserToken'
import {useNavigate} from 'react-router-dom'

function UpdatePassword(){

    
    const [data,setdata] =  useState({password:'',password_confirmation:''})
    const navigate = useNavigate()



    function handlechange({key,value}){
      setdata({...data,[key]:value})
    }
    
    function updatepassword(){
        if(data.password.length < 8){
            message.error('New Password should be atleast 8 characters')
        }else if(data.password_confirmation !== data.password){
            message.error('Password did not match')
        }else{
            axios.put(`user/change-password`,data,authHeader)
            .then((res)=>{
                if(res.data.status){
                    message.success('Password updated successfully')
                    setdata({...data,password:'',password_confirmation:''})
                }
            }).catch((err)=>{
                if(err?.response?.status === 403){
                    message.error(err?.response?.data?.msg)
                }
            })
        }
    }

    return(
        <div style={{display:'grid',height:'100vh',justifyContent:'center'}}>
            <div style={{marginTop:'90px',position:'relative'}}>

              <h4 style={{color:'#292961',textAlign:'center',fontWeight:'bold'}}>FCPL CRM </h4>
              <IoMdClose style={{position:'absolute',right:'0px',top:'-20px',cursor:'pointer'}} onClick={()=>navigate(-1)} />
               <div style={{width:'350px',padding:'30px',height:'320px',marginTop:'30px',border:'1px solid #ddd',borderRadius:'4px'}}>
               <h6 style={{fontSize:'20px',fontWeight:'bold',marginBottom:'2px'}}>Change Password</h6>
               <p style={{color:'gray',fontSize:'12px'}}>Use the below form to change your password for FCPL CRM Account </p>


               <div>
                  <div style={{marginBottom:'10px'}}>
                    <h6 style={{fontSize:'12px',fontWeight:'600'}}>New Password</h6>
                   <InputField 
                   priority='high'
                   name="password"
                   value={data.password}
                   onChange={handlechange}
                   placeholder={'Enter your new password'}/>
                  </div>

                  <div style={{marginBottom:'10px'}}>
                    <h6 style={{fontSize:'12px',fontWeight:'600'}}>Confirm Password</h6>
                  <InputField 
                  name="password_confirmation"
                   priority='high'
                   value={data.password_confirmation}
                   onChange={handlechange}
                   placeholder={'Re-enter your new password'}/>
                   </div>
                    
                    <div onClick={updatepassword}>
                   <PrimaryButton 
                    btn_name ="Change Password"/>
                    </div>

               </div> 
               </div>    
        </div>


        </div>
    )
}


export default UpdatePassword