import React,{useEffect, useState} from "react";
import './MessageBox.css'
import {IoClose} from 'react-icons/io5';
import {IoMdAttach,IoMdImages} from 'react-icons/io';
import {BiMessageEdit} from 'react-icons/bi';
import {HiMinus,HiDownload} from 'react-icons/hi';
import {Upload} from 'antd';

import { useSelector } from "react-redux";
import axios from "axios";
import { authHeader } from "../../helpers/usertoken/UserToken";
import moment from "moment";
import { base_url } from "../../App";


const { Dragger } = Upload;




function MessageBox({type_id,comment_type}){

  const theme_color = useSelector(state=>state.Theme.theme_color)


  useEffect(()=>{
    getmessages()
  },[])

   const getmessages=()=>{

    const data = {
      comment_type:comment_type,
      type_id:type_id
    }
    authHeader['Content-Type'] = 'multiplart/form-data'

    axios.get(`comments/get/${type_id}/${comment_type}`,data,authHeader)
    .then((res)=>{
      setmessages(res.data.data)
      // console.log('res data here',res.data)
    })
   }

    const [message_box,setmessage_box] = useState(true)

    const [messages,setmessages]= useState([])

    const id = useSelector(state => state.Auth.id)


    // console.log('ia mama',id)

    const [message,setmessage] = useState({
      comment_summary:'',
      comment_attachment:'',
      type_id:type_id,
      comment_type:comment_type,
      user_id:id,
    })

    const handleUpload = (v) => {
      setmessage({...message,comment_attachment:v.fileList[v.fileList.length - 1].originFileObj});
    } 


    const send = (e) => {
      e.preventDefault();
      const fd = new FormData()

      // console.log('message here',message)
      fd.append('comment_summary',message.comment_summary)
      fd.append('comment_attachment',message.comment_attachment)
      fd.append('type_id',message.type_id)
      fd.append('comment_type',message.comment_type)
      fd.append('user_id',message.user_id)

      authHeader['Content-Type'] = 'multiplart/form-data'


      axios.post(`comments/create`,fd,authHeader)
      .then((res)=>{
        setmessage({...message,comment_summary:'',comment_attachment:''});
        getmessages()
        // console.log('res data here',res.data)
      }).catch((err)=>{
        // console.log('err data here',err)
      })
    }

    // console.log('chatmodal',visible)


    // console.log('messages',messages)

    return(
        <div style={{width:'100%',height:'100%'}}>
       
      
         <div className={!message_box ? "messagebox_outer" : "messagebox_outer1" }>
             <div className="chatbox_header" style={{background:theme_color}}>
                <h6><BiMessageEdit size={23} color="white" /> Messages </h6>
                <div style={{width:'60px',display:'flex',justifyContent:'space-evenly'}}>
                    <HiMinus  size={18} color="white" className="icon_in_chat" onClick={()=>setmessage_box(!message_box)}/>
                    {/* <IoReloadCircleOutline  size={18} color="white" className="icon_in_chat" style={{marginLeft:'10px'}} onClick={()=>setmessage_box(!message_box)}/> */}
                    <IoClose size={18} color="white" style={{marginLeft:'10px'}} className="icon_in_chat"   onClick={()=>setmessage_box(!message_box)}/>
                </div>
             </div>

           {!message_box && <>

    
             <div className="message_section"> 
              {messages?.map((m)=>(
              <div key={m} className={id === m?.user_id?._id ? "message_box_send" : "message_box_receive"}>
                <p className={id === m?.user_id?._id ? "arrow_up_message1" : "arrow_up_message"}></p>
               <h4>{m.user_id.name}</h4>
                
                {m.comment_attachment !== '' &&
                <div style={{height: m.comment_attachment.split('.')[m.comment_attachment.split('.').length  - 1] === ('webp'|| 'png' || 'jpg' ||'jpeg' || 'svg' || 'gif')? '100px' :'25px' ,position:'relative'}}> 
                <HiDownload size={16} style={{background:'black',position:'absolute',right:0,color:'white',padding:'4px',opacity:0.5,cursor:'pointer'}} />
                {(m?.comment_attachment?.split('.')[m?.comment_attachment?.split('.')?.length  - 1] === ('webp' || 'png' || 'jpg' ||'jpeg' || 'svg' || 'gif' )) ? 
                <img src={`${base_url}${m?.comment_attachment}`} style={{width:'100%',height:'100px',objectFit:'cover'}} alt="image_here" />
                 :
                <h6 style={{background:id === m?.user_id?._id ?'#b8e6bc' : '#f5f5f5',padding:'2px'}}>{m.comment_attachment.split('/')[m.comment_attachment.split('/').length - 1]}</h6>
                 }
                </div> 
                }
               
                 <h6>{m.comment_summary}</h6>
                 <span>{moment(m.createdAt).format('lll')}</span>
              </div>
              ))}

             
             {message.comment_attachment !== '' &&
             <div className="comment_Attachment"> 
              <h6>1 file selected</h6>
              <IoClose size={18} onClick={()=>setmessage({...message,comment_attachment:''})} />
              </div>}
            
             </div>

             <div className="message_footer_chat" style={{position:'relative'}}>
                <form style={{maxHeight:'50px'}}>
                <input style={{width:'100%',zIndex:1000}} type={'text'} value={message.comment_summary}  onKeyPress={(e)=>e.key === 'Enter' && send} onChange={(e)=>setmessage({...message,comment_summary:e.target.value})} placeholder="Type a message" onSubmit={send} className="input_style" />
                <button type="submit" onClick={send} style={{display:'none'}}>send</button>
                </form>
                {/* <input type={'submit'} style={{opacity:0}} onClick={send} /> */}
                <p style={{position:'fixed',bottom:'-10px',fontSize:'12px',right:'10px',fontWeight:'800',color:'#bebebe'}}>Enter To Submit</p>
                
                {/* <div style={{background:'red',position:'absolute',bottom:'0px',left:'10px'}}> */}
                <Dragger onChange={handleUpload}
                            beforeUpload={() => false}
                            showUploadList={false}
                            height='34px'

                            // left:'-150px',bottom:'-20px'}}
                            
                            style={{background:'transparent',border:'none',padding:'0px',width:'100px',margin:'0px'}}>

                <IoMdAttach size={18} style={{marginLeft:'-40px',opacity:1,padding:'0px',marginTop:'-20px'}} className="icon_in_chat"  />
                <IoMdImages size={18} style={{marginLeft:'10px',padding:'0px',marginTop:'-20px'}} className="icon_in_chat"  />
                        </Dragger>
                        {/* </div>         */}
            </div>
            </>}
         </div>
         
         </div>
    )
}

export default MessageBox