import React,{useState,useEffect} from 'react'
import { Input,DatePicker } from 'antd';
import InputField from '../../../components/inputcomponents/InputField'
import {Validators} from '../../../components/Validators/Validators'
import Inputerror from '../../../components/errors/Inputerror';
import {IoMenu} from 'react-icons/io5'

import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import axios from 'axios'
import PrimaryButton,{SecondaryButton} from '../../../components/buttons/PrimaryButton';
import { authHeader } from '../../../helpers/usertoken/UserToken';
import RootNavbar from '../../mobileNavbar/RootNavbar';
import { main_bg } from '../../../components/constants/Constants1';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment'
import Loader from '../../../components/Loaders/Loader';
import toast,{Toaster} from 'react-hot-toast';

// import { colourOptions } from '../data';

const animatedComponents = makeAnimated();


function Onboard() {

    const {id} = useParams()

    const navigate = useNavigate()


    const [loader,setloader] = useState(false)
    const [departement_arr, setdepartement_arr] = useState([])
    const [designation_arr, setdesignation_arr] = useState([])
    const [roles_arr, setroles_arr] = useState([])
    const [data, setdata] = useState({name:'',employee_id:'',department_id:[],designation_id:{},official_phone:'',official_email:'',dob:'',password:'',roles:[],image:'',edit:false})
    const [dataerr, setdataerr] =  useState({nameerr:{},employee_id_err:{},department_id_err:{},designation_id_err:{},official_phone_err:{},official_email_err:{},dob_err:{},password_err:{},roles_err:{}})
     
    const [visible,setvisible] = useState(false)

    
    useEffect(() => {
       setloader(true)
       axios.get('department/get',authHeader)
             .then((res)=>{
                const departement_arr_created = []
                res.data.datas.forEach(d=>{
                    let data = {}
                    data['label'] = d.department_name
                    data['value'] = d.department_name
                    data['id'] = d.id
                    departement_arr_created.push(data)
               })
               setdepartement_arr(departement_arr_created)
               setloader(false)
            })
        axios.get('designation/get',authHeader)
             .then((res)=>{
                const designation_arr_created = []

                res.data.datas.forEach(d=>{
                    let data = {}
                    data['label'] = d.designation_name
                    data['value'] = d.designation_name
                    data['id'] = d.id
                    designation_arr_created.push(data)
               })
               setdesignation_arr(designation_arr_created)
               setloader(false)
            })
        axios.get('role/get',authHeader)
             .then((res)=>{
                const role_arr_created = []
                res.data.datas.forEach(d=>{
                     let data = {}
                     data['label'] = d.role_name
                     data['value'] = d.display_name
                     data['id'] = d.id
                     role_arr_created.push(data)
                })
                setroles_arr(role_arr_created)
                setloader(false)
            })
        if(id){
            setloader(true)
            axios.get(`user/get/${id}`,authHeader)
            .then((res)=>{
                setloader(false)
                console.log('res data here',res.data.datas[0])
                let data_set = res?.data?.datas[0]

                let department_selected_arr = []
                let roles_selected_arr = []
                data_set?.department_id?.forEach(d=>{
                    let obj = {}
                    obj['label'] = d?.department_name
                    obj['value'] = d?.department_name
                    obj['id'] = d?._id
                    department_selected_arr.push(obj)
                })
                data_set?.user_roles.map((r)=>{
                    let obj1 = {}
                    obj1['label'] = r?.roles[0]?.display_name
                    obj1['value'] = r?.roles[0]?.display_name
                    obj1['id'] = r?.roles[0]?._id
                    roles_selected_arr.push(obj1)
                })

                console.log('roles_selected_arr',roles_selected_arr)


                let selected_designation = {}
                    selected_designation['label'] = data_set?.designation_id[0]?.designation_name
                    selected_designation['value'] = data_set?.designation_id[0]?.designation_name
                    selected_designation['id'] = data_set?.designation_id[0]?._id
                    
                
                if(res.data.status){
                    setdata({...data,
                        name:data_set.name,
                        employee_id:data_set.employee_id,
                        official_phone:data_set.official_phone,
                        official_email:data_set.official_email,
                        dob:data_set.dob,
                        department_id:department_selected_arr,
                        designation_id:selected_designation,
                        roles:roles_selected_arr,
                        edit:true
                    })
                }
            })
        }    
    
    
    },[])
    
    function handleChange({key,value}){
        if( key === 'name'){
            setdataerr({...dataerr,nameerr:{}})
        }else if(key === 'official_phone'){
            setdataerr({...dataerr,official_phone_err:{}})
        }else if(key === 'official_email'){
            setdataerr({...dataerr,official_email_err:{}})
        }
        setdata({...data,[key]:value})
    }

    function handleChange1(selectedOptions,datakey_name){   

        const array = [] 
        // console.log('selectedOptions',selectedOptions)
        if(selectedOptions){
            selectedOptions.forEach((t) => {
                array.push(t);
              });
        }
        setdata({...data,[datakey_name]:array})
    }

    function handleChange2(selectedOptions,datakey_name){
        setdata({...data,[datakey_name]:selectedOptions})
    }

    function setdob(v,v1){
        setdata({...data,dob:v1})
    }

    function cancel_onboarding(){
        setdata({...data,name:'',employee_id:'',department_id:[],designation_id:{},official_phone:'',official_email:'',dob:'',password:'',roles:[],image:''})
        setdataerr({...dataerr,nameerr:{},employee_id_err:{},department_id_err:{},designation_id_err:{},official_phone_err:{},official_email_err:{},dob_err:{},password_err:{},roles_err:{}})
    }

    function onBoardEmployee(){       
        if(!data.name){
            setdataerr({...dataerr,nameerr:{error:true,message:'This field is required'}}) 
        }else if(!data.employee_id){
            setdataerr({...dataerr,employee_id_err:{error:true,message:'This field is required'}}) 
        }else if(data.employee_id.toLowerCase().slice(0,5) !== 'fcpl_' ){
            setdataerr({...dataerr,employee_id_err:{error:true,message:'Please Enter a valid FCPL_ID'}}) 
        }else if(!data.official_phone){
            setdataerr({...dataerr,official_phone_err:{error:true,message:'This field is required'},employee_id_err:{}}) 
        }else if(!data.official_email){
            setdataerr({...dataerr,official_email_err:{error:true,message:'This field is required'}}) 
        }else if(data.department_id.length === 0){
            setdataerr({...dataerr,department_id_err:{error:true,message:'This field is required'}})
        }else if(data.designation_id.length === 0){
            setdataerr({...dataerr,designation_id_err:{error:true,message:'This field is required'}})
        }else if(!data.dob){
            setdataerr({...dataerr,dob_err:{error:true,message:'This field is required'}})
        }else if(!data.roles){
            setdataerr({...dataerr,roles_err:{error:true,message:'This field is required'}})
        }else{ 
            let departement_id_arr = []
            let roles_id_arr = []

            let password = data.name.slice(0,4).toUpperCase().concat(data.employee_id.slice(5,).concat(Math.random().toString(36).slice(2)))

            data.department_id.forEach(d=>{
                departement_id_arr.push(d.id)
            })

            data.roles.forEach(r=>{
                roles_id_arr.push(r.id)
            })

            if(!data.edit){
            setloader(true)
            let send_data = {
            'name':data.name,
            'employee_id':data.employee_id,
            'department_id':departement_id_arr,
            'designation_id':data.designation_id.id,
            'official_phone':data.official_phone,
            'official_email':data.official_email,
            'dob':data.dob,
            'password':password,
            'roles':roles_id_arr,
            'image':''
            }
         
            axios.post('user/create',send_data,authHeader)
            .then((res)=>{
                setloader(false)
                toast.success('CRM onboarding was successfull')
            }).catch((err)=>{
                setloader(false)
                if(err.response.status === 422){
                    toast.error(err.response.data.errors.employee_id);
                }
            }) 
           }else if(data.edit){

            let send_data = {
                'name':data.name,
                'employee_id':data.employee_id,
                'department_id':departement_id_arr,
                'designation_id':data.designation_id.id,
                'official_phone':data.official_phone,
                'official_email':data.official_email,
                'dob':data.dob,
                'roles':roles_id_arr,
                'image':''
                }
            setloader(true)
            axios.put(`user/update/${id}`,send_data,authHeader)
            .then((res)=>{
                setloader(false)
                toast.success('user profile updated successfully')
                navigate(-1)
                setdata({...data,dob:'',official_phone:'',designation_id:{value:'',label:''},name:'',employee_id:'',official_email:'',department_id:[],roles:[]})
            }).catch((err)=>{
                setloader(false)
                if(err?.response?.status === 422){  
                if(err?.response?.data?.errors?.employee_id){
                    toast.error(err?.response?.data?.errors?.employee_id);
                }else if(err?.response?.data?.errors?.official_email){
                    toast.error(err?.response?.data?.errors?.official_email);
                }      
                }
            })  
        }  
        }
    }


    return (
         <div style={{width:'100%'}}>
            <Toaster/>
           {loader && <Loader />  }  
           <RootNavbar visible={visible} func={()=>setvisible(false)} /> 
           <div style={{display:'flex',alignItems:'center',minHeight:'45px',maxHeight:'45px',background:`${main_bg}`,position:'sticky',top:'0px',zIndex:100}} onClick={()=>setvisible(true)}>
            <IoMenu size={30} style={{marginRight:5,marginLeft:5,color:'white',padding:5,borderRadius:4}} />
            <h6 style={{fontSize:'15px',fontWeight:'600',marginBottom:'0px',color:'#fff'}}>Onboard Employee</h6>
           </div>   
           <div>         
          <div style={{width:'90%',margin:'auto'}}>
            <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',zIndex:10}}>
            
            <div style={{marginTop:'20px'}}>
                <h5 style={{fontWeight:'800',fontSize:'18px'}}>Onboard Page</h5>
                <p style={{fontSize:'13px',fontWeight:'500'}}>Use the below form to Onboarding user to CRM</p>
            </div>
            </div>

          
            <div className="row " style={{marginTop:'-15px'}}>
                <div className="col-md-6 col-sm-6" style={{margin:'10px 0px'}}>
                 <p style={{marginBottom:'8px',fontSize:'14px'}}>Employee Name</p>  
                <InputField 
                    placeholder={'Employee Name'}
                    type="text"
                    priority={'high'}
                    size="large"
                    onChange={handleChange}
                    name={'name'}
                    value={data.name}
                    error_obj={dataerr.nameerr}
                    validators = {[{check:Validators.required,message:'This field is required'},{check:Validators.minimum_length_required,message:'Employee name  should be atleast 3 characters'}]}

                 />
                </div>
                <div className="col-md-6 col-sm-6" style={{margin:'10px 0px'}}>
                 <p style={{marginBottom:'8px',fontSize:'14px'}}>FCPL_ID</p>  
                <Input 
                    placeholder={'FCPL_ID'}
                    value={data.employee_id} 
                    size="large" 
                    style={{borderLeft:'4px solid #292961',borderRadius:'5px'}} 
                    onChange={(e)=>setdata({...data,employee_id:e.target.value})}/>
                <Inputerror message={dataerr?.employee_id_err?.message} />
                </div>
                <div className="col-md-6 col-sm-6" style={{margin:'10px 0px'}}>
                 <p style={{marginBottom:'8px',fontSize:'14px'}}>Official Phone Number</p>
                 <InputField 
                    placeholder={'Official Phone Number'}
                    type="number"
                    priority={'high'}
                    size="large"
                    onChange={handleChange}
                    name={'official_phone'}
                    value={data.official_phone}
                    error_obj={dataerr.official_phone_err}
                    validators = {[{check:Validators.required,message:'This field is required'},{check:Validators.number_required,message:'Enter a valid 10 digit phone number'}]}
                 />
                {/* <Input size="large" placeholder="Basic usage" /> */}
                </div>
                <div className="col-md-6 col-sm-6" style={{margin:'10px 0px'}}>
                 <p style={{marginBottom:'8px',fontSize:'14px'}}>Official Email ID</p>  
                 <InputField 
                    placeholder={'Official Email ID'}
                    type="email"
                    priority={'high'}
                    size="large"
                    onChange={handleChange}
                    name={'official_email'}
                    value={data.official_email}
                    error_obj={dataerr.official_email_err}
                    validators = {[{check:Validators.required,message:'This field is required'},{check:Validators.email,message:'Enter a valid email id'}]}
                 />
                {/* <Input size="large" placeholder="Basic usage" /> */}
                </div>

                <div className="col-md-6 col-sm-6" style={{margin:'10px 0px'}}>
                 <p style={{marginBottom:'8px',fontSize:'14px'}}>Date of Birth</p>  
                 <DatePicker 
                    value={data?.dob !== '' ? moment(data.dob) : null}
                    size="large"  
                    format={'YYYY-MM-DD'}  
                    onChange={setdob} 
                    style={{width:'100%',borderLeft:'4px solid #292961',borderRadius:'5px'}} 
                 />
                </div>

                <div className="col-md-6 col-sm-6" style={{margin:'10px 0px',zIndex:200}}>
                 <p style={{marginBottom:'8px',fontSize:'14px'}}>Departement</p>  
                 <Select 
                    value={data.department_id}
                    isMulti
                    placeholder="Select a Departement" 
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    onChange={(v)=>handleChange1(v,'department_id')}
                    options={departement_arr}
                    style={{ width: '100%',borderLeft:'4px solid #292961',borderRadius:'5px',boxShadow:'none' }}
                  >
                </Select>
                <Inputerror message={dataerr?.department_id_err?.message} />
                </div>
                <div className="col-md-6 col-sm-6" style={{margin:'10px 0px',zIndex:150}}>
                 <p style={{marginBottom:'8px',fontSize:'14px'}}>Designation</p>  
                 <Select 
                    value={data.designation_id}
                    placeholder="Select a Designation" 
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    onChange={(v)=>handleChange2(v,'designation_id')}
                    options={designation_arr}
                    style={{ width: '100%',borderLeft:'4px solid #292961',borderRadius:'5px',boxShadow:'none',zIndex:100 }}
                  >
                </Select>
                <Inputerror message={dataerr?.designation_id_err?.message} />
                </div>
               
                <div className="col-md-6 col-sm-6" style={{margin:'10px 0px',zIndex:100}}>
                 <p style={{marginBottom:'8px',fontSize:'14px'}}>Assign Roles</p>  
                 <Select
                    placeholder="Assign Roles" 
                    value={data.roles}
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    isMulti
                    onChange={(v)=>handleChange1(v,'roles')}
                    options={roles_arr}
                    style={{ width: '100%',borderLeft:'4px solid #292961',borderRadius:'5px',boxShadow:'none' }}
                >
                </Select>
                </div>

                <div style={{display:'flex',zIndex:10,paddingBottom:'60px'}}>
                    <div style={{width: data.edit ? '200px' : '150px',zIndex:1}} onClick={()=>{onBoardEmployee()}}>
                    <PrimaryButton  btn_name = {data.edit ? `Update On Board Employee` : `On Board Employee`}  />
                    </div>
                    <div onClick={()=>cancel_onboarding()}>
                    <SecondaryButton btn_name = 'cancel' />
                    </div>
                </div>

            </div>
            </div>
        </div>
        </div>
    )
}

export default Onboard
