import React, { useState } from 'react'
import { Input, Checkbox, Button, message } from 'antd';
import { VscEye, VscEyeClosed } from 'react-icons/vsc'
import Inputerror from '../../../components/errors/Inputerror';
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { login, roles, user_info, departments, designations } from '../../../redux/actionPayload/AuthPayload';
import { useNavigate } from 'react-router-dom'
import { main_bg } from '../../../components/constants/Constants1';

function Login() {

    const [password_visible, setpassword_visible] = useState(true)
    const [data, setdata] = useState({ user_id: '', password: '' })
    const [error, seterror] = useState({ fcpl_iderr: '', password_err: '' })

    const device_token = useSelector(state => state.Auth.device_token)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const authheader = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
    }

    function signinfun() {
        if (!data.user_id) {
            message.error('This field is required')
        } else if (data.user_id.slice(0, 5).toLowerCase() !== 'fcpl_') {
            message.error('The fcpl_id should start with fcpl_ or FCPL_')
        } else if (!data.password) {
            message.error('Password field is required')
        } else {
            seterror({ ...error, fcpl_iderr: '', password_err: '' })
            // navigate('/bd_dashboard') 
            data['fcm_token'] = device_token
            axios.post('user/login', data, authheader)
                .then((res) => {
                    if (res.status === 200) {
                        console.log('res data here', res.data)
                        seterror({ ...error, fcpl_iderr: '', password_err: '' })
                        setdata({ ...data, user_id: '', password: '' })
                        let add_data = {
                            name: res.data.name,
                            email: res.data.official_email,
                            id: res.data.id,
                            employee_id: res.data.employee_id
                        }
                        // dispatch(user_info(add_data))
                        // dispatch(login(res.data.token))
                        // dispatch(roles(res.data.roles))
                        localStorage.setItem("access_token", res.data.token)
                        dispatch(user_info(add_data))
                        dispatch(login(res.data.token))
                        dispatch(departments(res.data.department))
                        dispatch(designations(res.data.designation))
                        dispatch(roles(res.data.roles))
                        let r = res.data.roles
                        // console.log('roles na here',r)
                        if (r.includes('admin') || r.includes('hod') || r.includes('manager') || r.includes('db_head') || r.includes('db_user') || r.includes('db_sub_user') || r.includes('bd_user')) {
                            navigate('/bd_dashboard')
                        } else if (r.includes('controller') && !r.includes('admin') && !r.includes('hod') && !r.includes('manager') && !r.includes('db_head') && !r.includes('db_user') && !r.includes('db_sub_user')) {
                            navigate('/controller_dashboard')
                        }
                    }
                }).catch((err) => {
                    if (err?.response?.data?.errors) {
                        message.error(err?.response?.data?.errors)
                    }
                    // console.log('err',err)
                })
        }
    }

    function handleChange(e) {
        if (e.target.name === 'user_id') {
            if (data.user_id.slice(0, 5).toLowerCase() !== 'fcpl_') {
                seterror({ ...error, fcpl_iderr: 'The fcpl_id should start with fcpl_ or FCPL_' })
            } else {
                seterror({ ...error, fcpl_iderr: '' })
            }
        }
        setdata({ ...data, [e.target.name]: e.target.value })
    }


    return (
        <div style={{ display: 'grid', background: 'white', width: '100%', height: '100vh', maxHeight: '100vh' }}>
            <div style={{ background: 'white', display: 'flex', width: '100%', margin: 'auto' }} >
                <div style={{ width: '100%' }} className="p-3" >
                    <h4 style={{ fontWeight: '900' }}>SIGN IN</h4>
                    <h6>To access Fide CRM</h6>
                    <div style={{ width: '100%' }} className="mt-3">
                        <Input name="user_id" value={data.user_id} size="large" placeholder="Enter your FCPL ID" onChange={handleChange} style={{ width: '100%', zIndex: 10, border: error.fcpl_iderr && '1px solid red', boxShadow: error.fcpl_iderr && 'none' }} className="input" />
                        <Inputerror message={error.fcpl_iderr} />

                    </div>
                    <div style={{ width: '100%', position: 'relative' }} className="mt-4">
                        <Input name="password" size="large" placeholder="Enter your FCPL password" onChange={handleChange} style={{ width: '100%', zIndex: 10, border: error.password_err && '1px solid red', boxShadow: error.password_err && 'none' }} type={password_visible ? "password" : "text"} class="input" />
                        <Inputerror message={error.password_err} />
                        {password_visible ? <VscEyeClosed size={20} style={{ position: 'absolute', right: '10px', zIndex: '100', top: '10px', color: 'grey' }} onClick={() => setpassword_visible(false)} /> : <VscEye size={18} style={{ position: 'absolute', right: '10px', zIndex: '100', top: '13px', color: 'grey' }} onClick={() => setpassword_visible(true)} />}
                    </div>
                    <Checkbox style={{ marginTop: '20px' }} >{'Remember me'}</Checkbox>
                    <Button type="primary" className="mt-4" size={'large'} style={{ backgroundColor: `${main_bg}`, boxShadow: 'none', borderColor: `${main_bg}` }} block onClick={() => signinfun()}>SIGN IN</Button>
                    <p type="link" size={'large'} className="text-center mt-4 fw-medium" style={{ color: '#589afc', cursor: 'pointer' }} onClick={() => window.location.pathname = "/forgot_password"}>Forgot Password ?</p>
                </div>
            </div>
            <p style={{ width: '100%', fontSize: '12px', textAlign: 'center', bottom: '10px', position: 'absolute' }}>© 2022, Fidelitus Corp Pvt. Ltd. All Rights Reserved.</p>


        </div>
    )
}


export default Login