import React from 'react'
import { Tooltip,Tag } from 'antd'
// import Tooltip from '@mui/material/Tooltip';

import { IoMailOutline, IoCloseOutline } from 'react-icons/io5'
import { IoMdClose } from 'react-icons/io'
import { FaWhatsapp } from 'react-icons/fa'
import { MdOutlineCall } from 'react-icons/md'
import { RiSpyFill, RiHome5Fill } from 'react-icons/ri'
import { BiLogOut } from 'react-icons/bi'
import { useSelector, useDispatch } from 'react-redux'
import { AiTwotoneLock, AiOutlineLogout } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import './Profile.css'
import { Switch } from 'antd';
import IconButton from '@mui/material/IconButton';
// import Tooltip from '@mui/material/Tooltip';
import { GoCheck } from 'react-icons/go';
// import { dispatch } from 'react-hot-toast/dist/core/store'

// import Icon 
function Profile({ func, logoutfun, mobile }) {

    const name = useSelector(state => state.Auth.name)
    const roles = useSelector(state => state.Auth.roles)
    const department = useSelector(state => state.Auth.department)
    const email = useSelector(state => state.Auth.email)
    const designation = useSelector(state => state.Auth.designation)
    const fcpl_id = useSelector(state => state.Auth.fcpl_id)
    const phone = useSelector(state => state.Auth.phone)


    const navigate = useNavigate()

    const dispatch = useDispatch()




    function funccall() {
        func()
    }
    return (
        <div style={{ margin: '0px', padding: '0px' }}>
            <div style={{ margin: '0px 0px', paddingBottom: '20px', borderBottom: '1px solid #f5f5f5' }} >
                <IoMdClose size={20} style={{ position: 'absolute', right: '10px', top: '15px', cursor: 'pointer' }} onClick={() => funccall()} />
                <div style={{ display: 'flex', alignItems: 'start' }}>
                    <h6 style={{ backgroundColor: '#ff952b', color: 'black', fontWeight: 'bold', height: '60px', width: '60px', textAlign: 'center', alignSelf: 'center', justifyContent: 'center', display: 'grid', alignItems: 'center', marginBottom: '0px', borderRadius: '50px', cursor: 'pointer', fontSize: '24px', marginTop: '-5px', textTransform: 'capitalize' }}>{name.slice(0, 1)}</h6>
                    <div style={{ marginLeft: '20px' }}>
                        <h6 style={{ fontSize: '25px', marginBottom: '1px', fontWeight: '800' }}>{name}</h6>
                        <h6 style={{ fontSize: '16px', marginTop: '3px', background: '#292961', color: 'white', padding: '4px', textAlign: 'center', borderRadius: '2px' }}>{fcpl_id}</h6>
                    </div>
                </div>
            </div>
            <div style={{ margin: '10px 0px', paddingBottom: '20px', borderBottom: '1px solid #f5f5f5' }} >

                <div style={{ display: 'flex', justifyContent: 'space-between', width: '40%', alignItems: 'center', alignContent: 'center', marginTop: '0px' }}>
                    <Tooltip placement="bottom" color={'black'} title={email} > <IoMailOutline style={{ cursor: 'pointer', color: 'grey' }} size={24} /></Tooltip>
                    <Tooltip placement="bottom" color={'black'} title={`+91 ${phone}`} ><FaWhatsapp style={{ cursor: 'pointer', color: 'grey' }} size={24} /></Tooltip>
                    <Tooltip placement="bottom" color={'black'} title={`+91 ${phone}`} > <MdOutlineCall style={{ cursor: 'pointer', color: 'grey' }} size={24} /></Tooltip>
                </div>
            </div>

            <div style={{ margin: '10px 0px', paddingBottom: '20px', borderBottom: '1px solid #f5f5f5' }} >

                <div style={{ marginLeft: '0px', marginTop: '10px', }}>
                    <h5 style={{ color: 'grey', fontSize: '16px' }}><RiHome5Fill size={20} style={{ background: '#292961', color: 'white', width: '27px', height: '27px', padding: '5px', borderRadius: '4px', marginRight: '10px' }} />{department[0]?.department_name}</h5>
                </div>

                <h6 style={{ fontSize: '16px', margin: '20px 0px 10px 0px' }}> <RiSpyFill size={20} style={{ background: '#292961', color: 'white', width: '27px', height: '27px', padding: '5px', borderRadius: '4px', marginRight: '10px' }} />

                    <Tag>{designation?.designation_name}</Tag>
                </h6>



            </div>

            <div style={{ marginLeft: '0px', marginTop: '10px', }} onClick={() => navigate('/update-password')}>
                <h5 style={{ color: 'grey', fontSize: '16px' }}><AiTwotoneLock size={20} style={{ background: '#292961', color: 'white', width: '27px', height: '27px', padding: '5px', borderRadius: '4px', marginRight: '10px' }} />{'Update Password'}</h5>
            </div>

            <div style={{ position: 'fixed', bottom: '10px' }}>
                <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', marginBottom: '10px' }} onClick={() => logoutfun()}>
                    <BiLogOut size={24} style={{ background: '#292961', color: 'white', width: '27px', height: '27px', padding: '5px', borderRadius: '4px', marginRight: '10px' }} />
                    <h6 style={{ marginBottom: '0px', marginLeft: '0px', fontSize: '16px' }}>Log out</h6>
                </div>
                <p style={{ marginBottom: '0px', fontSize: '13px' }}>Do you need additional assistance?
                    <p>Our friendly support team  <span style={{ color: '#5076f2' }}>tech@fidelituscorp.com</span> is here to help you</p> </p>
            </div>




            {/* PROFILE SECTION */}
            {/* <div>
                <div style={{ background: '#f5f5f5', borderBottom: '1px solid #ededed' }}>
                    <div style={{ width: '90%', margin: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', alignItems: 'center', padding: '20px 0px', cursor: 'pointer' }} onClick={()=>dispatch(logoutfun())}>
                            <AiOutlineLogout size={15} />
                            <h6 style={{ fontSize: '13px', marginBottom: '0px', marginLeft: '10px' }}>Sign Out</h6>
                        </div>

                        <Tooltip title="Close" arrow >
                            <IoCloseOutline  onClick={()=>funccall()} style={{ cursor: 'pointer' }} size={20} />
                        </Tooltip>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row', width: '80%', margin: '10px auto' }}>
                        <img src="https://fidecrmfiles.s3.amazonaws.com/noprofile.png" alt="noprofile" style={{ width: '60px', height: '60px', border: '3px solid white', borderRadius: '30px' }} />


                        <div style={{ marginLeft: '20px',width:'90%' }}>
                            <h6 style={{ fontWeight: '700', marginBottom: '5px' }}>{name}</h6>
                            <h6 style={{ fontSize: '11px', fontWeight: 450, color: '#9f9f9f', marginBottom: '3px' }}>{email}</h6>
                            <h6 style={{ fontSize: '11px', fontWeight: 450, color: '#9f9f9f', marginBottom: '3px' }}>Fcpl User ID: {6002121212}</h6>
                            <div style={{
                                display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '60%'
                            }}>
                                <span style={{ fontSize: '12px', fontWeight: '400', color: '#3d86f6', cursor: 'pointer' }
                                } > Add Account</span>
                                <span style={{ width: '3px', height: '3px', borderRadius: '5px', background: '#9f9f9f', opacity: 1, cursor: 'pointer' }}></span>
                                <span style={{ fontSize: '12px', fontWeight: '400', color: '#3d86f6', cursor: 'pointer' }}>Log Out</span>
                            </div>
                        </div>
                    </div>





                </div>

                <div style={{ width: '90%', margin: '20px auto', padding: 'auto 20px', display: 'flex', borderTop: '1px solid #ebebeb', borderBottom: '1px solid #ebebeb' }}>
                    <div style={{ padding: '15px 0px' }}>
                        <h6 style={{ fontWeight: '700', fontSize: '14px' }}>Themes</h6>
                        <div style={{ width: '120%', }}>
                            <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0px' }}>
                                <h6 style={{ marginRight: '20px', fontSize: '14px', fontWeight: 400, marginBottom: '0px' }}>Solid</h6>
                                <div style={{ width: '90%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', width: '24px', height: '24px', borderRadius: '50%', overflow: 'hidden', cursor: 'pointer' }}>
                                        <p style={{ background: '#261e1b', width: '30px', height: '60px' }}></p>
                                        <p style={{ background: '#ff6400', width: '30px', height: '60px' }}></p>
                                    </div>

                                    <div style={{ display: 'flex', alignItems: 'center', width: '24px', height: '24px', borderRadius: '50%', overflow: 'hidden', cursor: 'pointer' }}>
                                        <p style={{ background: '#001f30', width: '30px', height: '60px' }}></p>
                                        <p style={{ background: '#00a7f5', width: '30px', height: '60px' }}></p>
                                    </div>

                                    <div style={{ display: 'flex', alignItems: 'center', width: '24px', height: '24px', borderRadius: '50%', overflow: 'hidden', cursor: 'pointer' }}>
                                        <p style={{ background: '#01252c', width: '30px', height: '60px' }}></p>
                                        <p style={{ background: '#04c5af', width: '30px', height: '60px' }}></p>
                                    </div>

                                    <div style={{ display: 'flex', alignItems: 'center', width: '24px', height: '24px', borderRadius: '50%', overflow: 'hidden', cursor: 'pointer' }}>
                                        <p style={{ background: '#230001', width: '30px', height: '60px' }}></p>
                                        <p style={{ background: '#ec1915', width: '30px', height: '60px' }}></p>
                                    </div>

                                    <div style={{ display: 'flex', alignItems: 'center', width: '24px', height: '24px', borderRadius: '50%', overflow: 'hidden', cursor: 'pointer' }}>
                                        <p style={{ background: '#061e0c', width: '30px', height: '60px' }}></p>
                                        <p style={{ background: '#06b554', width: '30px', height: '60px' }}></p>
                                    </div>

                                    <div style={{ display: 'flex', alignItems: 'center', width: '24px', height: '24px', borderRadius: '50%', overflow: 'hidden', cursor: 'pointer' }}>
                                        <p style={{ background: '#232737', width: '30px', height: '60px' }}></p>
                                        <p style={{ background: '#277af7', width: '30px', height: '60px' }}></p>
                                    </div>
                                </div>
                            </div>

                            <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0px', }}>
                                <h6 style={{ marginRight: '20px', fontSize: '14px', fontWeight: 400, marginBottom: '0px', padding: '10px 0px' }}>Light</h6>

                                <div style={{ width: '90%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <div className="active_theme">
                                        <div style={{ display: 'flex', alignItems: 'center', width: '24px', height: '24px', borderRadius: '50%', overflow: 'hidden', cursor: 'pointer' }} className="active_theme_children">
                                            <p style={{ background: '#eaeaea', width: '30px', height: '60px' }}></p>
                                            <p style={{ background: '#ff6400', width: '30px', height: '60px' }}></p>
                                            <GoCheck style={{ position: 'absolute', display: 'flex', alignItems: 'center', alignContent: 'center', alignSelf: 'center', marginLeft: '5px', justifyContent: 'center', background: '#fff', borderRadius: '50%', opacity: 0.7 }} />

                                        </div>
                                    </div>

                                    <div style={{ display: 'flex', alignItems: 'center', width: '24px', height: '24px', borderRadius: '50%', overflow: 'hidden', cursor: 'pointer' }}>
                                        <p style={{ background: '#eaeaea', width: '30px', height: '60px' }}></p>
                                        <p style={{ background: '#00a7f5', width: '30px', height: '60px' }}></p>
                                    </div>

                                    <div style={{ display: 'flex', alignItems: 'center', width: '24px', height: '24px', borderRadius: '50%', overflow: 'hidden', cursor: 'pointer' }}>
                                        <p style={{ background: '#eaeaea', width: '30px', height: '60px' }}></p>
                                        <p style={{ background: '#04c5af', width: '30px', height: '60px' }}></p>
                                    </div>

                                    <div style={{ display: 'flex', alignItems: 'center', width: '24px', height: '24px', borderRadius: '50%', overflow: 'hidden', cursor: 'pointer' }}>
                                        <p style={{ background: '#eaeaea', width: '30px', height: '60px' }}></p>
                                        <p style={{ background: '#ec1915', width: '30px', height: '60px' }}></p>
                                    </div>

                                    <div style={{ display: 'flex', alignItems: 'center', width: '24px', height: '24px', borderRadius: '50%', overflow: 'hidden', cursor: 'pointer' }}>
                                        <p style={{ background: '#eaeaea', width: '30px', height: '60px' }}></p>
                                        <p style={{ background: '#06b554', width: '30px', height: '60px' }}></p>
                                    </div>

                                    <div style={{ display: 'flex', alignItems: 'center', width: '24px', height: '24px', borderRadius: '50%', overflow: 'hidden', cursor: 'pointer' }}>
                                        <p style={{ background: '#eaeaea', width: '30px', height: '60px' }}></p>
                                        <p style={{ background: '#277af7', width: '30px', height: '60px' }}></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ marginLeft: '70px', padding: '15px 0px' }}>
                        <h6 style={{ fontWeight: '700', fontSize: '14px' }}>Day/Night</h6>


                        <Switch defaultChecked />
                    </div>
                </div>
            </div> */}
        </div >
    )
}

export default Profile
