import React, { useEffect, useState } from 'react'
import { BoldText1 } from '../../../helpers/constants/ConstantsText'
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import FmsDashboardMainRoot from '../FmsDashboardMainRoot';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { authHeader } from '../../../helpers/usertoken/UserToken';
import { useSelector } from 'react-redux';
import { Col, Row,Tag,Pagination } from 'antd';
import {AiOutlineEdit,AiOutlineDelete} from 'react-icons/ai';
import moment from 'moment';
import { Toaster, toast } from 'react-hot-toast';


function AssignClients() {

  const navigate = useNavigate()

  const roles = useSelector(state=>state.Auth.roles)
  const [data,setdata] = useState([])
  const [pagination,setpagination] = useState({})
  const [page,setpage] = useState(1)

  const user = useSelector(state=>state.Auth)
  // console.log("user",user)
  // console.log("roles",roles)

  useEffect(()=>{
    getData()
  },[])


  async function getData(){ 
    await axios.get(`fms/fmsclients/assigned_clients_list`,authHeader)
     .then((res)=>{
      setdata(res?.data?.datas)
      setpagination(res?.data?.pagination)
        
     }).catch((err)=>{
     })
   }

   async function deleteData(v){
    await axios.delete(`fms/fmsclients/delete_assigned_client/${v}`,authHeader)
    .then((res)=>{
      console.log("res data here",res?.data)
      getData()
      toast.success(res?.data?.message)
    }).catch((err)=>{
    })
   }

   function setpagefuun(v){
    setpage(v)
   }


  return (
    <FmsDashboardMainRoot>
      <Toaster />
        <div style={{width:'98%'}}>
            <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between',borderBottom:'1px solid #eee'}}>
            <BoldText1 val={`Assigned Clients (${pagination?.total})`} />
            <PrimaryButton btn_name={'Assign Client'} onClick={()=>navigate('/fms/assigned_clients_ce')} />
            </div>

            <Row style={{marginTop:'10px'}}>
              {data?.map((d)=>(
              <Col span={4} style={{border:'1px solid #eee',padding:'5px',margin:'2px',position:'relative'}}>
                 <AiOutlineDelete onClick={()=>deleteData(d?._id)} fontSize={11} style={{position:'absolute',right:20,top:2,background:'white',cursor:'pointer'}} />
                 <AiOutlineEdit onClick={()=>navigate('/fms/assigned_clients_ce',{state:d})} fontSize={11} style={{position:'absolute',right:2,top:2,background:'white',cursor:'pointer'}} />
                 <h6 style={{fontSize:'12.5px',marginTop:'15px'}}>{d?.assigned_to == 'InHouse-Employee' ? 'Field Officer' : 'Deployed Officer'} : <span style={{fontWeight:'600',fontSize:'11px'}}>{d?.assigned_to == 'InHouse-Employee' && d?.field_officer?.name}{d?.assigned_to == 'Deployed Employee' && d?.deployed_officer?.name}</span> </h6>
                 <h6 style={{fontSize:'11px'}}>Assigned Clients:</h6>
                 {d?.client?.map((c)=>(
                  <Tag style={{fontSize:'10px'}}>{c?.client_name}</Tag>
                 ))}

                 <h6 style={{fontSize:'8px',background:'#fafafa',padding:4,marginTop:10}}>Created At : <span style={{marginLeft:'4px',fontWeight:'600'}}>{moment(d?.createdAt)?.format('LL')}</span></h6>
              </Col>
              ))}
            </Row>

            <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
                {pagination?.total > pagination?.limit &&
                    <Pagination current={page} size="small" total={pagination?.total} onChange={(v) => setpagefuun(v)}  pageSize={pagination?.limit} />
                }
            </div>




        </div>
    </FmsDashboardMainRoot>
  )
}

export default AssignClients