import React from 'react'
import './PrimaryButton.css'
import { AiOutlinePlus } from 'react-icons/ai'
import { HiFilter, HiChevronDown } from 'react-icons/hi'
import { MdAssignmentInd } from 'react-icons/md'

export function SecondaryButton({ btn_name, padding,onClick }) {
    return (
        <div>
            <button type='button' style={{ padding: padding && padding }} className='btn btn-primary btn-sm secondarybtn' onClick={onClick}>{btn_name}</button>
        </div>
    )
}



function PrimaryButton({ btn_name, padding, onClick }) {

    return (
        <div>
            <button type='button' style={{ padding: padding && padding }} className='btn  btn-primary btn-sm primarybtn' onClick={onClick}>{btn_name}</button>
        </div>
    )
}


export function PrimaryButtonAdd({ btn_name, padding, color }) {

    return (
        <div>
            <button type='button' style={{ padding: padding && padding, color: color }} className='btn  btn-primary btn-sm primarybtn'>
                <AiOutlinePlus color="white" size={18} style={{ marginRight: '6px' }} />
                <span>{btn_name}</span>
            </button>
        </div>
    )
}

export function PrimaryButtonAssign({ btn_name, padding, color, icon_color }) {
    return (
        <div>
            <button type='button' style={{ padding: padding && padding,color:color }} className='btn  btn-primary btn-sm primarybtn'  >
                <MdAssignmentInd color="white" size={18} style={{ marginRight: '6px' }} />
                <span>{btn_name}</span>
            </button>
        </div>
    )
}


export function PrimaryButtonFilter({ btn_name, padding }) {

    return (
        <div>
            <button type='button' style={{ padding: padding && padding }} className='btn  btn-primary btn-sm primarybtn' >
                <HiFilter color="white" size={18} style={{ marginRight: '6px' }} />
                <span>{btn_name}</span>
            </button>
        </div>
    )
}


export function PrimaryButtonMail({ btn_name, padding }) {
    return (
        <div>
            <button type='button' style={{ padding: padding && padding, width: '100%', position: 'relative', overflow: 'hidden' }} className='btn  btn-primary btn-sm primarybtn' >
                <span style={{ fontWeight: '600', marginLeft: '-30px' }}>{btn_name}</span>
                <HiChevronDown color="white" size={35} style={{ marginLeft: '5px', background: '#0d5faf', position: 'absolute', fontSize: '10px', right: 0, top: 0, padding: '0px 10px', height: '100%' }} />
            </button>
        </div>
    )
}



export default PrimaryButton
