import React, { useEffect, useState } from 'react'
import { Input } from 'antd';
import InputField from '../../../components/inputcomponents/InputField'
import { Validators } from '../../../components/Validators/Validators'
import DateField from '../../../components/inputcomponents/DateField';
import DropDown from '../../../components/inputcomponents/DropDown';
import PrimaryButton, { SecondaryButton } from '../../../components/buttons/PrimaryButton';
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useSelector } from "react-redux";
import { authHeader } from "../../../helpers/usertoken/UserToken";
import axios from "axios";
import toast, { Toaster } from 'react-hot-toast'
import { name_reg_string, name_reg_validation } from '../../../helpers/constants/ConstantReg';


const { TextArea } = Input;

function CreateDeal() {
    const { deal_id } = useParams()
    const { pathname } = useLocation()

    const { state } = useLocation()


    const navigate = useNavigate()

    const rd = state?.datas


    const name = useSelector(state => state.Auth.name)
    const id = useSelector(state => state.Auth.id)

    const [data, setdata] = useState({ ...rd, deal_owner: name, deal_name: rd?.lead_name, deal_title: '', deal_Stage: '', deal_type: '', closing_date: '', description: '', id: '' })
    const [edit, setedit] = useState(false)

    const [dataerr, setdataerr] = useState({
        deal_owner_err: {},
        deal_name_err: {},
        title_err: {},
        closing_date_err: {},
        stage_err: {},
        type_err: {},
        description_err: {}
    })

    const [dealStage, setdealStage] = useState([])

    const dealStage_proj =
        [
            {
                id: "Execution",
                value: "Execution"
            },
            {
                id: "Handing Over",
                value: "Handing Over"
            },
            {
                id: "Payment Pending",
                value: "Payment Pending"
            },
            {
                id: "Invoice raised",
                value: "Invoice raised"
            },
            {
                id: "Closed",
                value: "Closed"
            },
        ]
    const dealStage_trans =
        [
            {
                id: "Payment Received",
                value: "Payment Received"
            },
            {
                id: "Payment Pending",
                value: "Payment Pending"
            },
            {
                id: "Agreement/Registered",
                value: "Agreement/Registered"
            },
            {
                id: "Invoice Raised",
                value: "Invoice Raised"
            },
        ]
    const dealStage_hr =
        [
            {
                id: "Payment Received",
                value: "Payment Received"
            },
            {
                id: "Payment Pending",
                value: "Payment Pending"
            },
            {
                id: "Deal Closed",
                value: "Deal Closed"
            },
            {
                id: "Service stopped",
                value: "Service stopped"
            },
        ]
    const dealStage_fms =
        [
            {
                id: "Agreement Renewal",
                value: "Agreement Renewal"
            },
            {
                id: "Organic Growth",
                value: "Organic Growth"
            },
            {
                id: "Payment Received",
                value: "Payment Received"
            },
            {
                id: "Payment Pending",
                value: "Payment Pending"
            },
            {
                id: "Invoice Raised",
                value: "Invoice Raised"
            },
        ]
    const dealStage_gallery =
        [
            {
                id: "Payment Received",
                value: "Payment Received"
            },
            {
                id: "Payment Pending",
                value: "Payment Pending"
            },
            {
                id: "Invoise Raised",
                value: "Invoise Raised"
            },
            {
                id: "Work In Progess",
                value: "Work In Proges"
            },
        ]

    const dealType = [{
        id: "New Business",
        value: "New Business"
    },
    {
        id: "Existing Business",
        value: "Existing Business"
    }]



    useEffect(async () => {
        if (deal_id && pathname.split('/')[1] === 'edit_deal') {
            setedit(true)
            axios.get(`deal/get/${deal_id}`, authHeader).then(async (res) => {
                const singleData = res.data.datas[0];
                const departement_arr_created = []
                await axios.get('department/get', authHeader)
                    .then((res) => {
                        res.data.datas.forEach(d => {
                            let data = {}
                            data['label'] = d.department_name
                            data['value'] = d.department_name
                            data['id'] = d.id
                            departement_arr_created.push(data)
                        })
                    })

                let lead_department = departement_arr_created?.find(d => d.id === singleData?.lead_id?.department_id)

                if (lead_department?.value === 'Transaction Team') {
                    setdealStage(dealStage_trans)
                } else if (lead_department?.value === 'Project Team') {
                    setdealStage(dealStage_proj)
                } else if (lead_department?.value === 'Hr Team') {
                    setdealStage(dealStage_hr)
                } else if (lead_department?.value === 'FMS Team') {
                    setdealStage(dealStage_fms)
                } else if (lead_department?.value === 'Gallery Team') {
                    setdealStage(dealStage_gallery)
                }

                setdata({
                    deal_name: singleData?.deal_name,
                    closing_date: singleData?.closing_date,
                    deal_title: singleData?.deal_title,
                    deal_stage: singleData?.deal_stage,
                    deal_owner: singleData?.deal_owner?.name,
                    deal_type: singleData?.deal_type,
                    description: singleData?.description,
                    contact_id: singleData?.lead_id?.contact_id,
                });
            })
        } else {
            const departement_arr_created = []
            await axios.get('department/get', authHeader)
                .then((res) => {
                    res.data.datas.forEach(d => {
                        let data = {}
                        data['label'] = d.department_name
                        data['value'] = d.department_name
                        data['id'] = d.id
                        departement_arr_created.push(data)
                    })
                })



            let lead_department = departement_arr_created?.find(d => d.id === rd?.department_id)

            console.log('lead_department',rd)


            if (lead_department?.value === 'Transaction Team') {
                setdealStage(dealStage_trans)
            } else if (lead_department?.value === 'Project Team') {
                setdealStage(dealStage_proj)
            } else if (lead_department?.value === 'Hr Team') {
                setdealStage(dealStage_hr)
            } else if (lead_department?.value === 'FMS Team') {
                setdealStage(dealStage_fms)
            } else if (lead_department?.value === 'Gallery Team') {
                setdealStage(dealStage_gallery)
            }
            setedit(false)
        }
    }, [])

    function handleChange({ key, value }) {
        if (key === 'deal_name') {
            setdataerr({ ...dataerr, deal_name_err: {} })
        } else if (key === 'deal_owner') {
            setdataerr({ ...dataerr, deal_owner_err: {} })
        } else if (key === 'deal_title') {
            setdataerr({ ...dataerr, title_err: {} })
        } else if (key === 'deal_stage') {
            setdataerr({ ...dataerr, stage_err: {} })
        } else if (key === 'deal_type') {
            setdataerr({ ...dataerr, type_err: {} })
        } else if (key === 'description') {
            setdataerr({ ...dataerr, description_err: {} })
        }
        setdata({ ...data, [key]: value })

    }

    function adddealfunc() {
        navigate('/view_deals')
    }

    function adddeal() {
        if (!data.deal_owner) {
            setdataerr({ ...dataerr, deal_owner_err: { error: true, message: 'This field is required' } })
        } else if (!data.deal_name) {
            setdataerr({ ...dataerr, deal_owner_err: {}, deal_name_err: { error: true, message: 'This field is required' } })
        } else if (!name_reg_validation(data.deal_name)) {
            setdataerr({ ...dataerr, deal_owner_err: {}, deal_name_err: { error: true, message: `Deal name should contains only ${name_reg_string}` } })
        } else if (!data.closing_date) {
            setdataerr({ ...dataerr, deal_owner_err: {}, deal_name_err: {}, closing_date_err: { error: true, message: 'This field is required' } })
        } else if (!data.deal_title) {
            setdataerr({ ...dataerr, deal_owner_err: {}, deal_name_err: {}, closing_date_err: {}, title_err: { error: true, message: 'This field is required' } })
        } else if (!name_reg_validation(data.deal_title)) {
            setdataerr({ ...dataerr, deal_owner_err: {}, deal_name_err: {}, closing_date_err: {}, title_err: { error: true, message: `Title should contains only ${name_reg_string}` } })
        } else if (!data.deal_stage) {
            setdataerr({ ...dataerr, deal_owner_err: {}, deal_name_err: {}, closing_date_err: {}, title_err: {}, stage_err: { error: true, message: 'This field is required' } })
        } else if (!data.deal_type) {
            setdataerr({ ...dataerr, deal_owner_err: {}, deal_name_err: {}, closing_date_err: {}, title_err: {}, stage_err: {}, type_err: { error: true, message: 'This field is required' } })
        } else {
            let set_Data = {
                deal_owner: id,
                deal_name: data.deal_name,
                deal_title: data.deal_title,
                closing_date: data.closing_date,
                deal_stage: data.deal_stage,
                deal_type: data.deal_type,
                description: data.description,
                lead_id: state?.datas?._id
            }
            axios.post(`deal/create`, set_Data, authHeader).then((res) => {
                toast.success('Added Successfully')
                adddealfunc()
            }).catch(err => {
                if (err.response.status === 422) {
                    if (err?.response?.data?.errors?.lead_id) {
                        toast.error('Selected lead has been already converted to deal')
                    }
                }
                // message.error(err.response.)
            })
        }

    }

    function editdeal() {
        if (!data.deal_owner) {
            setdataerr({ ...dataerr, deal_owner_err: { error: true, message: 'This field is required' } })
        } else if (!data.deal_name) {
            setdataerr({ ...dataerr, deal_owner_err: {}, deal_name_err: { error: true, message: 'This field is required' } })
        } else if (!name_reg_validation(data.deal_name)) {
            setdataerr({ ...dataerr, deal_owner_err: {}, deal_name_err: { error: true, message: `Deal name should contains only ${name_reg_string}` } })
        } else if (!data.closing_date) {
            setdataerr({ ...dataerr, deal_owner_err: {}, deal_name_err: {}, closing_date_err: { error: true, message: 'This field is required' } })
        } else if (!data.deal_title) {
            setdataerr({ ...dataerr, deal_owner_err: {}, deal_name_err: {}, closing_date_err: {}, title_err: { error: true, message: 'This field is required' } })
        } else if (!name_reg_validation(data.deal_title)) {
            setdataerr({ ...dataerr, deal_owner_err: {}, deal_name_err: {}, closing_date_err: {}, title_err: { error: true, message: `Title should contains only ${name_reg_string}` } })
        } else if (!data.deal_stage) {
            setdataerr({ ...dataerr, deal_owner_err: {}, deal_name_err: {}, closing_date_err: {}, title_err: {}, stage_err: { error: true, message: 'This field is required' } })
        } else if (!data.deal_type) {
            setdataerr({ ...dataerr, deal_owner_err: {}, deal_name_err: {}, closing_date_err: {}, title_err: {}, stage_err: {}, type_err: { error: true, message: 'This field is required' } })
        } else {
            let set_Data = {
                id: deal_id,
                deal_owner: id,
                deal_name: data.deal_name,
                deal_title: data.deal_title,
                closing_date: data.closing_date,
                deal_stage: data.deal_stage,
                deal_type: data.deal_type,
                description: data.description,
                lead_id: state?.datas?.id
            }
            axios.put(`deal/update/${deal_id}`, set_Data, authHeader).then((res) => {
                toast.success('Updated Successfully')
                navigate(-1)
            })
        }
    }

    function selectedvalue(v) {
        if (v?.id) {
            setdataerr({ ...dataerr, [v.nameerr]: {} })
            setdata({ ...data, [v.name]: v.id });
        }
    }

    function goback() {
        setdata({})
        setdataerr({})
        navigate(-1)
    }


    return (
        <div style={{ width: '90%', margin: 'auto', marginBottom: '70px' }}>
            <Toaster />

            <div style={{ position: 'sticky', top: '40px', marginTop: '60px', background: 'white', zIndex: 100 }}>
                <div className="mt-3" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        <h5 style={{ marginBottom: '6px' }}>{!edit ? 'Create Deal' : 'Edit Deal'}</h5>
                        <p style={{ fontSize: '14px', fontWeight: '400' }}>{!edit ? "Let's create a new Deal" : "Let's update a existing Deal"}</p>
                    </div>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-end',
                        justifyContent: 'flex-end'
                    }}>

                        <div onClick={() => goback()}>
                            <SecondaryButton btn_name="Cancel" />
                        </div>


                        <div onClick={(edit) ? editdeal : adddeal}>
                            <PrimaryButton btn_name={edit ? 'Update' : 'Save'} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mt-5">
                <h6>Deal Information</h6>
                <div className="col-md-6">
                    <div className="col-md-12" style={{ margin: '10px 0px', width: '70%' }}>
                        <p style={{ marginBottom: '8px', fontSize: '14px' }}>Deal Name</p>
                        <InputField
                            type="text"
                            priority="high"
                            onChange={handleChange}
                            name={'deal_name'}
                            error_obj={dataerr.deal_name_err}
                            value={data.deal_name}
                            validators={[{
                                check: Validators.required,
                                message: 'This field is required'
                            }, {
                                check: Validators.minimum_length_required,
                                message: 'Deal name should be atleast 3 characters'
                            }, {
                                check: Validators.string_matchValidator,
                                message: `Deal name should contains only ${name_reg_string}`
                            }]}

                        />
                    </div>
                    <div className="col-md-12" style={{ margin: '10px 0px', width: '70%' }}>
                        <p style={{ marginBottom: '8px', fontSize: '14px' }}>Title</p>
                        <InputField
                            type="text"
                            priority="high"
                            onChange={handleChange}
                            name={'deal_title'}
                            error_obj={dataerr.title_err}
                            value={data.deal_title}
                            validators={[{
                                check: Validators.required,
                                message: 'This field is required'
                            }, {
                                check: Validators.minimum_length_required,
                                message: 'Title should be atleast 3 characters'
                            }]}

                        />
                    </div>

                    <div className="col-md-12" style={{ margin: '10px 0px', width: '70%' }}>
                        <p style={{ marginBottom: '8px', fontSize: '14px' }}>Deal Owner</p>
                        <InputField
                            priority="high"
                            name={'deal_owner'}
                            value={data.deal_owner}

                        />
                    </div>

                    <div className="col-md-12" style={{ margin: '10px 0px', width: '70%' }}>

                        <p style={{ marginBottom: '8px', fontSize: '14px' }}>Description </p>
                        <TextArea style={{ height: 20, width: '100%', marginLeft: '0%' }}
                            onChange={(e) => setdata({ ...data, description: e.target.value })}
                            name={'description'}
                            error_obj={dataerr.description_err}
                            value={data.description}
                        />
                    </div>

                </div>
                <div className="col-md-6">

                    <div className="col-md-12" style={{ margin: '10px 0px', width: '70%' }}>
                        <p style={{ marginBottom: '8px', fontSize: '14px' }}>Closing Date </p>
                        <DateField
                            priority={'high'}
                            name={'closing_date'}
                            onChangefun={handleChange}
                            error_obj={dataerr.closing_date_err}
                            date_value={data.closing_date}
                            validators={[{
                                check: Validators.required,
                                message: 'This field is required'
                            }, {
                                check: Validators.minimum_length_required,
                                message: 'Title should be atleast 3 characters'
                            }]}

                        />
                    </div>

                    <div className="col-md-12" style={{ margin: '10px 0px', width: '70%' }}>
                        <p style={{ marginBottom: '8px', fontSize: '14px' }}>Stage </p>
                        <DropDown
                            onChange={(v) => selectedvalue(v)}
                            priority={'high'}
                            name='deal_stage'
                            nameerr='stage_err'
                            error_obj={dataerr.stage_err}
                            dropdownValue={data.deal_stage}
                            value={data.deal_stage}
                            options={dealStage}
                        />

                    </div>

                    <div className="col-md-12" style={{ margin: '10px 0px', width: '70%' }}>
                        <p style={{ marginBottom: '8px', fontSize: '14px' }}>Type </p>
                        <DropDown
                            onChange={(v) => selectedvalue(v)}
                            priority={'high'}
                            name='deal_type'
                            nameerr='type_err'
                            error_obj={dataerr.type_err}
                            dropdownValue={data.deal_type}
                            value={data.deal_type}
                            options={dealType}
                        />
                    </div>
                </div>
            </div>


            <div className='row mt-3'>
                <h6>Information from selected Lead</h6>
                <div className="col-md-6">
                    <div className="col-md-12" style={{ margin: '10px 0px', width: '70%' }}>
                        <p style={{ marginBottom: '8px', fontSize: '14px' }}>Company</p>
                        <InputField
                            name={'company_name'}
                            value={data?.contact_id?.company_name}
                        />
                    </div>
                </div>

                <div className="col-md-6">

                    <div className="col-md-12" style={{ margin: '10px 0px', width: '70%' }}>
                        <p style={{ marginBottom: '8px', fontSize: '14px' }}>Company Phone </p>
                        <InputField
                            name={'company_phone'}
                            value={data?.contact_id?.company_phone}

                        />
                    </div>
                </div>

                <div className="col-md-6">

                    <div className="col-md-12" style={{ margin: '10px 0px', width: '70%' }}>
                        <p style={{ marginBottom: '8px', fontSize: '14px' }}>Company Email </p>
                        <InputField
                            name={'company_email'}
                            value={data?.contact_id?.company_email}

                        />
                    </div>
                </div>

                <div className="col-md-6">

                    <div className="col-md-12" style={{ margin: '10px 0px', width: '70%' }}>
                        <p style={{ marginBottom: '8px', fontSize: '14px' }}>Website </p>
                        <InputField
                            name={'website'}
                            value={data?.contact_id?.website}
                        />
                    </div>
                </div>

                <div className="col-md-6">

                    <div className="col-md-12" style={{ margin: '10px 0px', width: '70%' }}>
                        <p style={{ marginBottom: '8px', fontSize: '14px' }}>Industry </p>
                        <InputField
                            name={'industry'}
                            value={data?.contact_id?.industry?.industry_name}
                        />
                    </div>
                </div>

                <div className="col-md-6">

                    <div className="col-md-12" style={{ margin: '10px 0px', width: '70%' }}>
                        <p style={{ marginBottom: '8px', fontSize: '14px' }}>Phone</p>
                        <InputField
                            name={'phone'}
                            value={data?.contact_id?.phone}
                        />
                    </div>
                </div>

                <div className="col-md-6">

                    <div className="col-md-12" style={{ margin: '10px 0px', width: '70%' }}>
                        <p style={{ marginBottom: '8px', fontSize: '14px' }}>Deal Source </p>
                        <InputField
                            name={'contact_source'}
                            value={data?.contact_id?.contact_source?.source_name}
                        />
                    </div>
                </div>

                <div className="col-md-6">

                    <div className="col-md-12" style={{ margin: '10px 0px', width: '70%' }}>
                        <p style={{ marginBottom: '8px', fontSize: '14px' }}>Email</p>
                        <InputField
                            name={'email'}
                            value={data?.contact_id?.email}
                        />
                    </div>
                </div>
            </div>
            <div className="row mt-3">
                <h6>Address Information</h6>
                <div className="col-md-6">
                    <div style={{ width: '70%', margin: '10px 0px', }}>
                        <p style={{ marginBottom: '8px', fontSize: '14px' }}>Street</p>
                        <InputField
                            name={'street'}
                            value={data?.contact_id?.street}
                        />
                    </div>
                </div>


                <div className="col-md-6">
                    <div style={{ width: '70%', margin: '10px 0px', }}>
                        <p style={{ marginBottom: '8px', fontSize: '14px' }}>City</p>
                        <InputField
                            name={'city'}
                            value={data?.contact_id?.city}
                        />
                    </div>
                </div>


                <div className="col-md-6">
                    <div style={{ width: '70%', margin: '10px 0px', }}>
                        <p style={{ marginBottom: '8px', fontSize: '14px' }}>State</p>
                        <InputField
                            name={'state'}
                            value={data?.contact_id?.state}
                        />
                    </div>
                </div>


                <div className="col-md-6">
                    <div style={{ width: '70%', margin: '10px 0px', }}>
                        <p style={{ marginBottom: '8px', fontSize: '14px' }}>Country</p>
                        <InputField
                            name={'country'}
                            value={data?.contact_id?.country}
                        />
                    </div>
                </div>

                <div className="col-md-6">
                    <div style={{ width: '70%', margin: '10px 0px', }}>
                        <p style={{ marginBottom: '8px', fontSize: '14px' }}>Pin code</p>
                        <InputField
                            name={'pin_code'}
                            value={data?.contact_id?.pin_code}
                        />
                    </div>
                </div>
            </div>


        </div>
    )
}

export default CreateDeal
