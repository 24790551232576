

export const gray_col1 = '#d5d8db'
export const gray_col_light1 = '#a4abb1'
export const gray_dark_1 = '#2d3034'



// previous theme_color = #292961
export const theme_color = '#222f3e'

export const link_color = '#3d86f6'

export const dark_pallete = [
                 {
                    id:'1',
                    color1:'#261e1b',
                    color2:'#ff6400',
                 },
                 {
                    id:'2',
                    color1:'#001f30',
                    color2:'#00a7f5'
                 },
                 {
                    id:'3',
                    color1:'#001f30',
                    color2:'#04c5af'
                 },
                 {
                    id:'4',
                    color1:'#230001',
                    color2:'#ec1915'
                 },
                 {
                    id:'5',
                    color1:'#061e0c',
                    color2:'#06b554'
                 },
                 {
                    id:'6',
                    color1:'#232737',
                    color2:'#277af7'
                 },
]

export const light_pallete = [
    {
        id:'7',         
        color1:'#eaeaea',
        color2:'#ff6400'
     },
     {
        id:'8',         
        color1:'#eaeaea',
        color2:'#00a7f5'
     },
     {
        id:'9',         
        color1:'#eaeaea',
        color2:'#04c5af'
     },
     {
        id:'10',         
        color1:'#eaeaea',
        color2:'#ec1915'
     },
     {
        id:'11',        
        color1:'#eaeaea',
        color2:'#06b554'
     },
     {
        id:'12',        
        color1:'#eaeaea',
        color2:'#277af7'
     },
]