import React, { useState, useEffect } from 'react'
import MobileTableHeader from '../../../components/bdcomponenttable/mobileComponents/MobileTableHeader'
import { Modal, Input, DatePicker } from 'antd';
import InputField from '../../../components/inputcomponents/InputField';
import { Validators } from '../../../components/Validators/Validators'
import DropDown from '../../../components/inputcomponents/DropDown';
import { useSelector } from 'react-redux'
import axios from 'axios'
import { authHeader } from '../../../helpers/usertoken/UserToken';
import moment from 'moment'
import Inputerror from '../../../components/errors/Inputerror';
import PrimaryButton, { SecondaryButton } from '../../../components/buttons/PrimaryButton';
import TableView1 from '../../../components/bdcomponenttable/mobileComponents/listview_mobile/TableView1';
import Loader from '../../../components/Loaders/Loader'
import { data_not_found } from '../../../helpers/Constants';
import toast,{Toaster} from 'react-hot-toast';

function ViewMettings() {

    const { TextArea } = Input;

    const user_id = useSelector(state => state.Auth.id)
    const [loader, setloader] = useState(false)

    const [data, setdata] = useState({ title: '', related_to: '', contact: '', contact_id: '', date_time: '', location: '', description: '', id: '', edit: false })
    const [dataerr, setdataerr] = useState({ titleerr: {}, related_to_err: '', contacterr: {}, date_time_err: {}, location_err: {}, description_err: {} })
    const [modalvisible, setmodalvisible] = useState(false)
    const [reminder, setReminders] = useState([])


    const [meeting_data, setmeeting_data] = useState([])

    const [related_to_data, setrelated_to_data] = useState([])

    const [remindar_data, setremindar_data] = useState({ on_date: '', on_time: '', repeat_type: '', notify: '' })
    const [remindar_data_err, setremindar_data_err] = useState({ on_date_err: {}, on_time_err: {}, repeat_type_err: {}, notify_err: {} })



    useEffect(() => {
        getallmeetings()
    }, [])

    function getallmeetings() {
        setloader(true)
        axios.get('meeting/get', authHeader)
            .then((res) => {
                setloader(false)
                setmeeting_data(res.data.datas)
            })
    }

    async function getReminder(id) {
        await axios.get(`reminder/get?type=${'Meeting'}&type_id=${id}`, authHeader).then((res) => {
            setReminders(res.data.datas.reverse())
        })


    }



    const related_to = [
        {
            value: 'Lead',
            id: 'Lead'
        },
        {
            value: 'Deal',
            id: 'Deal'
        },
        {
            value: 'Contact',
            id: 'Contact'
        },
    ]

    function handleChange({ key, value }) {
        if (key === 'title') {
            setdataerr({ ...dataerr, titleerr: {} })
        } else if (key === 'from_date') {
            setdataerr({ ...dataerr, from_date_err: {} })
        } else if (key === 'to_date') {
            setdataerr({ ...dataerr, to_date_err: {} })
        } else if (key === 'location') {
            setdataerr({ ...dataerr, location_err: {} })
        } else if (key === 'on_date') {
            setremindar_data_err({ ...remindar_data_err, on_date_err: {} })
        } else if (key === 'on_time') {
            setremindar_data_err({ ...remindar_data_err, on_time_err: {} })
        } else if (key === 'repeat_type') {
            setremindar_data_err({ ...remindar_data_err, repeat_type_err: {} })
        } else if (key === 'notify') {
            setremindar_data_err({ ...remindar_data_err, notify_err: {} })
        }
        if (key !== 'on_date' && key !== 'on_time' && key !== 'repeat_type' && key !== 'notify') {
            setdata({ ...data, [key]: value })
        } else {
            setremindar_data({ ...remindar_data, [key]: value })
        }
    }

    function addMetting() {
        if (!data.title) {
            setdataerr({ ...dataerr, titleerr: { error: true, message: 'This field is required' } })
        } else if (!data.related_to) {
            setdataerr({ ...dataerr, related_to_err: { error: true, message: 'This field is required' } })
        } else if (!data.contact) {
            setdataerr({ ...dataerr, contacterr: { error: true, message: 'This field is required' } })
        } else if (!data.date_time) {
            setdataerr({ ...dataerr, date_time_err: { error: true, message: 'This field is required' } })
        } else if (!data.location) {
            setdataerr({ ...dataerr, location_err: { error: true, message: 'This field is required' } })
        } else {
            // if(show_remindar){
            //      if(!remindar_data.on_date){
            //           setremindar_data_err({...remindar_data_err,on_date_err:{error:true,message:'This field is required'}})
            //      }else if(!remindar_data.on_time){
            //           setremindar_data_err({...remindar_data_err,on_time_err:{error:true,message:'This field is required'}})
            //      }else if(!remindar_data.repeat_type){
            //           setremindar_data_err({...remindar_data_err,repeat_type_err:{error:true,message:'This field is required'}})
            //      }else if(!remindar_data.notify){
            //           setremindar_data_err({...remindar_data_err,notify_err:{error:true,message:'This field is required'}})
            //      }else{
            //         alert('waiting for api creation')
            //      }
            // }else{
            // console.log('iam here 7')

            const send_data = {}
            send_data['title'] = data.title
            send_data['related_to'] = data.related_to
            send_data['contact_name'] = data.contact_id
            send_data['date_time'] = data.date_time
            send_data['location'] = data.location
            send_data['description'] = data.description
            send_data['host'] = user_id


            if (!data.edit) {
                axios.post('meeting/create', send_data, authHeader)
                    .then((res) => {
                        resetform()
                        setmodalvisible(false)
                        toast.success('Meeting Created Successfully')
                        getallmeetings()
                    })
            } else if (data.edit) {
                axios.put(`meeting/update/${data.id}`, send_data, authHeader)
                    .then((res) => {
                        resetform()
                        setmodalvisible(false)
                        toast.success('Meeting Updated Successfully')
                        getallmeetings()
                    })
            }


            // }
        }
    }

    function seteditmeeting(v) {
        if (v?.related_to === 'Deal') {
            setdata({
                ...data,
                title: v?.title,
                related_to: v?.related_to,
                contact: v?.contact_name?.deal_name,
                contact_id: v?.contact_name?.id,
                date_time: v?.date_time,
                location: v?.location,
                description: v?.description,
                id: v?.id,
                edit: true,
                contact: v?.contact_name?.deal_name,
            })
            getDeals()
            setmodalvisible(true)
        } else if (v?.related_to === 'Lead') {
            setdata({
                ...data,
                title: v?.title,
                related_to: v?.related_to,
                contact: v?.contact_name?.deal_name,
                contact_id: v?.contact_name?.id,
                date_time: v?.date_time,
                location: v?.location,
                description: v?.description,
                id: v?.id,
                edit: true,
                contact: v?.contact_name?.lead_name,
            })
            getLeads()
            setmodalvisible(true)
        } else if (v?.related_to === 'Contact') {
            setdata({
                ...data,
                title: v?.title,
                related_to: v?.related_to,
                contact: v?.contact_name?.deal_name,
                contact_id: v?.contact_name?.id,
                date_time: v?.date_time,
                location: v?.location,
                description: v?.description,
                id: v?.id,
                edit: true,
                contact: v?.contact_name?.contact_name
            })
            getContacts()
            setmodalvisible(true)

        }
    }

    function getLeads() {
        axios.get('lead/get', authHeader)
            .then((res) => {
                const temp_arr = []
                res.data.datas.forEach(e => {
                    const data = {}
                    data['id'] = e._id
                    data['value'] = e.lead_name
                    temp_arr.push(data)
                })

                setrelated_to_data(temp_arr)
            })
    }
    function getDeals() {
        axios.get('deal/get', authHeader)
            .then((res) => {
                const temp_arr = []
                res.data.datas.forEach(e => {
                    const data = {}
                    data['id'] = e.id
                    data['value'] = e.deal_name
                    temp_arr.push(data)
                })

                setrelated_to_data(temp_arr)
            })
    }
    function getContacts() {
        axios.get('contact/get', authHeader)
            .then((res) => {
                const temp_arr = []
                res.data.datas.forEach(e => {
                    const data = {}
                    data['id'] = e.id
                    data['value'] = e.contact_name
                    temp_arr.push(data)
                })

                setrelated_to_data(temp_arr)
            })
    }
    function selectedvalue(v) {
        if (data.edit && v?.id !== data.related_to) {
            setdata({ ...data, contact: '', contact_id: '' })
        }
        if (v?.id && !data.edit) {
            setdataerr({ ...dataerr, [v.nameerr]: {} })
            setdata({ ...data, [v.name]: v?.id, [v.id_name]: v?.id })
            if (v?.id === 'Deal') {
                getDeals()
            } else if (v?.id === 'Lead') {
                getLeads()
            } else if (v?.id === 'Contact') {
                getContacts()
            }
        } if (v?.id !== data.related_to && data.edit) {
            if (v?.id !== data.related_to && v?.name === 'related_to') {
                setdata({ ...data, contact_id: '', contact: '', [v.name]: v?.id, [v.id_name]: v?.id })
                if (v?.id === 'Deal') {
                    getDeals()
                } else if (v?.id === 'Lead') {
                    getLeads()
                } else if (v?.id === 'Contact') {
                    getContacts()
                }
            } else {
                setdataerr({ ...dataerr, [v.nameerr]: {} })
                setdata({ ...data, [v.name]: v?.id, [v.id_name]: v?.id })
                if (v?.id === 'Deal') {
                    getDeals()
                } else if (v?.id === 'Lead') {
                    getLeads()
                } else if (v?.id === 'Contact') {
                    getContacts()
                }
            }
        }
    }
    function resetform() {
        setmodalvisible(false)
        setdata({ ...data, title: '', related_to: '', contact: '', from_date: '', from_time: '', location: '', description: '' })
        setdataerr({ ...dataerr, titleerr: {}, related_to_err: '', contacterr: {}, from_date_err: {}, from_time_err: {}, location_err: {}, description_err: {} })
    }
    function cancelmettingcreatefunc() {
        setmodalvisible(false)
        resetform()
    }
    function handledatechange(v, v1) {
        setdata({ ...data, date_time: v1 })
    }
    function handleok(v, v1) {
        // console.log('handleok',v,v1)
    }
    function addnewmeeting() {
        setmodalvisible(true)
        setdata({ ...data, edit: false })
    }
    function disabledDate(current, anna) {
        return current && current < moment().startOf('day');

    }







    return (
        <div style={{ marginTop: '0vh', }}>
            {loader && <Loader />}

            <Toaster/>

            <Modal
                visible={modalvisible}
                centered
                onOk={() => addMetting()}
                onCancel={() => cancelmettingcreatefunc()}
                footer={null}
                style={{ maxWidth: '94%' }}
            >
                <div className="row" style={{ overflow: 'scroll' }}>

                    <h4 style={{ fontSize: '16px', fontWeight: '900' }}>{data.edit ? 'Update' : 'Create'} Meeting </h4>
                    <div className="col-12">
                        <div style={{ margin: '10px 0px' }}>
                            <p style={{ marginBottom: '8px', fontSize: '14px' }}>Title</p>
                            <InputField
                                type="text"
                                priority="high"
                                onChange={handleChange}
                                name={'title'}
                                error_obj={dataerr.titleerr}
                                value={data.title}
                                validators={[{ check: Validators.required, message: 'This field is required' }, { check: Validators.minimum_length_required, message: 'Title should be atleast 3 characters' }]}

                            />
                        </div>
                    </div>

                    <div className="col-6">
                        <div style={{ margin: '10px 0px' }}>
                            <p style={{ marginBottom: '8px', fontSize: '14px' }}>Related To</p>
                            <DropDown
                                onChange={(v) => selectedvalue(v)}
                                priority={'high'}
                                name='related_to'
                                id_name='related_to'
                                value={data.related_to}
                                nameerr='related_to_err'
                                options={related_to}
                                error_obj={dataerr.related_to_err}

                            />
                        </div>
                    </div>
                    <div className="col-6">
                        <div style={{ margin: '10px 0px' }}>
                            <p style={{ marginBottom: '8px', fontSize: '14px' }}>{data.related_to} Name</p>

                            <DropDown
                                value={data.contact}
                                onChange={(v) => selectedvalue(v)}
                                priority={'high'}
                                name='contact'
                                id_name='contact_id'
                                nameerr='contacterr'
                                options={related_to_data}
                                error_obj={dataerr.contacterr}
                            />
                        </div>
                    </div>
                    <div className="col-6">
                        <div style={{ margin: '10px 0px', width: '100%', alignItems: 'center' }}>
                            <p style={{ fontSize: '14px', marginRight: '10px', marginBottom: '5px' }}>Date</p>
                            <DatePicker
                                disabledDate={disabledDate}
                                showTime
                                onChange={handledatechange} onOk={handleok}
                                format="YYYY-MM-DD HH:mm"
                                value={data.date_time !== '' ? moment(data.date_time) : null}
                            />

                            <Inputerror message={dataerr.date_time_err.message} />


                        </div>
                    </div>
                    <div className="col-6">
                        <div style={{ margin: '10px 0px' }}>
                            <p style={{ marginBottom: '8px', fontSize: '14px' }}>Location</p>
                            <InputField
                                type="text"
                                priority="high"
                                onChange={handleChange}
                                name={'location'}
                                error_obj={dataerr.location_err}
                                value={data.location}
                                validators={[{ check: Validators.required, message: 'This field is required' }, { check: Validators.minimum_length_required, message: 'Location field should be required' }]}

                            />
                        </div>
                    </div>

                    <div className="col-12">
                        <div style={{ margin: '10px 0px' }}>
                            <p style={{ marginBottom: '8px', fontSize: '14px' }}>Description</p>
                            <TextArea value={data.description} onChange={(e) => setdata({ ...data, description: e.target.value })} size="medium" style={{ width: '100%', outline: 'none', boxShadow: 'none' }} />
                        </div>
                    </div>



                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                    <div onClick={resetform}>
                        <SecondaryButton padding='6px 12px' btn_name="cancel" />
                    </div>
                    <div onClick={addMetting}>
                        <PrimaryButton padding='6px 12px' btn_name={data.edit ? "Update" : "Save"} />
                    </div>

                </div>

            </Modal>
            {/* <div style={{paddingLeft:'10px',display:'flex',padding:'4px 10px',alignItems:'center',background:'#292961'}}>
                 <HiArrowNarrowLeft size={20} color="white" onClick={()=>goback()}/> 
                 <h6 style={{color:'white',paddingLeft:'20px',marginTop:'10px'}} >Mettings</h6>
          </div> */}

            <div style={{ marginTop: '0vh' }}>
                <MobileTableHeader btn_val="Add Meeting" type="Meeting" addfun={() => addnewmeeting()} />
                {meeting_data.length > 0 ?
                    <div style={{ width: '96%', margin: 'auto', marginTop: '20px', }}>
                        <h6 style={{ fontSize: '13px', color: '#677078' }}> <strong style={{ color: 'black' }}>Total Meetings ({meeting_data.length})</strong></h6>
                        {/* <div style={{width:'100%',maxHeight:'85vh',overflowX:'scroll',marginTop:'20px'}}> */}
                        <TableView1
                            data={meeting_data}
                            type={'Meeting'}
                            reminder={reminder}
                            getreminderfun={v => getReminder(v)}
                            editmeeting={v => seteditmeeting(v)}
                        />
                        {/* </div>  */}
                    </div>
                    :
                    <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                        <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '130px', objectFit: 'contain' }} />
                        <h4 style={{ fontSize: '16px', fontWeight: '700', marginTop: '10px', textAlign: 'center' }}>{"You don't have any Meeting created yet."}</h4>
                        <h6 style={{ color: '#747474', fontSize: '12px', maxWidth: '80%', textAlign: 'center', margin: 'auto' }}>{"Click on the create meeting."}</h6>
                    </div>}
            </div>
        </div>
    )
}

export default ViewMettings
