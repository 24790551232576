import React from 'react'
import {AiOutlineInfoCircle} from 'react-icons/ai'

function Inputerror({message}) {
    
    return (
        <>
        {(message !== '' && message !== undefined) ?
         <div style={{display:'flex',alignItems:'center',marginTop:'3px'}}>
             {message?.length > 0 && <AiOutlineInfoCircle size={13} style={{color:'red'}}/>  }
              <p style={{marginBottom:'0px',fontSize:'12px',color:'red',paddingLeft:'3px'}}>{message}</p>
        </div> 
        :
        <div>
        </div>
        }
        </>
        )
        
}

export default Inputerror
