import React, { useState, useEffect } from 'react';
import PrimaryButton, { PrimaryButtonFilter } from '../../../components/buttons/PrimaryButton';
import axios from 'axios'
import { authHeader } from '../../../helpers/usertoken/UserToken';
import { FiEdit } from 'react-icons/fi'
import { Dropdown, Menu } from 'antd'
import { useNavigate } from 'react-router-dom';
import { Tag, Modal, Select } from 'antd';
import { useSelector } from 'react-redux';
import { Tabs, Input } from 'antd';
import { HiOutlineFilter } from 'react-icons/hi'
import { GrFormAdd } from 'react-icons/gr'
import { IoClose } from 'react-icons/io5'
import { Pagination } from 'antd'
import { FaUserShield } from 'react-icons/fa'
import { BiPlus, BiMinus } from 'react-icons/bi'
import {BsFileEarmarkLock2} from 'react-icons/bs'
import toast, { Toaster } from "react-hot-toast";
import { data_not_found, data_not_found_img_width } from '../../../helpers/Constants';

import {IoIosClose} from 'react-icons/io'
import { AiOutlineDelete } from 'react-icons/ai';


const { TabPane } = Tabs;
const { Option } = Select;
function OnboardEmployee() {

  const [activetab,setactivetab] = useState('1')
  const [roles_arr, setroles_arr] = useState([])
  const [employee, setemployee] = useState([])
  const [inactive_employee, setinactive_employee] = useState([])

  const [singleemployee, setsingleemployee] = useState({})
  const [singleemployeeRoles, setsingleemployeeRoles] = useState([])
  const [tempsingleemployeeRoles, settempsingleemployeeRoles] = useState([])

  const [inputroles, setinputroles] = useState([])
  const [selectedroles, setselectedroles] = useState([])

  const [visible, setvisible] = useState(false)
  const [visible1, setvisible1] = useState(false)
  const [updatepasswordmodal,setupdatepasswordmodal] = useState(false)
  const [page, setpage] = useState('1')
  const [pagination, setpagination] = useState({})
  const [password,setpassword] = useState('')

  const [add_btn, setadd_btn] = useState(false)



  const [tab, settab] = useState('0')

  const [search_employee, setsearch_employee] = useState({ employee_id: '', })
  const [tempsearch_employee, settempsearch_employee] = useState({ employee_id: '', })


  const name = useSelector(state => state.Auth.name)
  const theme_color = useSelector(state=>state.Theme.theme_color)



  const navigate = useNavigate()

  const colors = ['magenta', 'red', 'volcano', 'orange', 'gold', 'lime', 'green', 'cyan', 'blue', 'geekblue']


  console.log('inactive_employee',inactive_employee)


  useEffect(() => {
    if(activetab === '1'){
      getActiveOnboardedEmployee()
    }else{
      getInActiveOnboardedEmployee()
    }
  }, [page])

  useEffect(()=>{
    setpage(1)
    setactivetab('1')
    getRoles()
    getActiveOnboardedEmployee(1)
  },[])





  function getRoles() {
    axios.get('role/get', authHeader)
      .then((res) => {
        const role_arr_created = []
        res.data.datas.forEach(d => {
          let data = {}
          data['label'] = d.role_name
          data['value'] = d.display_name
          data['id'] = d.id
          role_arr_created.push(data)
        })
        setroles_arr(role_arr_created)

      })
  }

  function getActiveOnboardedEmployee(v) {
    // console.log('v na na nan',v)
    axios.get(`user/get/${v?v:page}/true`, authHeader)
      .then((res) => {
        // console.log('amma here',res.data)
        setpagination(res.data.pagination)
        setemployee(res.data.datas)
      })
  }

  function getInActiveOnboardedEmployee(v) {
    axios.get(`user/get/${v?v:page}/false`, authHeader)
      .then((res) => {
        setpagination(res.data.pagination)
        setinactive_employee(res.data.datas)
      })
  }

  function UpdateOnboardedData(v) {
    //  console.log('UpdateOnboardedData',v)
    navigate(`/on_board_user_update/${v}`)
  }

  function DeleteOnboarderData(v){
    axios.delete(`user/delete/${v?v:page}`, authHeader)
    .then((res) => {
      toast.success('Deleted successfully')

      if(activetab === '1'){
        getActiveOnboardedEmployee()
      }else{
        getInActiveOnboardedEmployee()
      }
    })
  }

  function updateactivestatus(v, v1) {
    settab(v1)
    setsingleemployee(v)
    if (v1 === '3') {
      let dat = v.user_roles[0]?.roles.map((r) => {
        let data = {}
        data['label'] = r.role_name
        data['value'] = r.display_name
        data['id'] = r._id
      })
      setsingleemployeeRoles(v.user_roles[0]?.roles)
      settempsingleemployeeRoles(v)
      setvisible1(true)
      getActiveOnboardedEmployee()
    } else {
      // console.log('anna me',v1)
      setvisible(true)
    }
  }

  function updateuseractivestatus(v) {

    let departement_id_arr = []
    let roles_id_arr = []


    // let dat1 =singleemployee?.department_id?.forEach(d => {
    //   departement_id_arr.push(d._id)
    // })

    // let dat2 =singleemployee?.user_roles[0]?.roles?.forEach(r => {
    //   roles_id_arr.push(r._id)
    // })

    let send_data = {
      'name': singleemployee.name,
      'employee_id': singleemployee.employee_id,
      'department_id': departement_id_arr,
      'designation_id': singleemployee.designation_id[0]._id,
      'official_phone': singleemployee.official_phone,
      'official_email': singleemployee.official_email,
      'dob': singleemployee.dob,
      'roles': roles_id_arr,
      'image': '',
      'isActive': activetab === '1' ? false : true
    }

    // console.log('send_data',send_data)
    axios.put(`user/update/${singleemployee._id}`, send_data, authHeader)
      .then((res) => {
        if (res.data.datas) {
          toast.success('Updated successfully')
          setvisible(false)
          setsingleemployee({})
          activetab === '1' ?
          getActiveOnboardedEmployee() : getInActiveOnboardedEmployee()
        }
        //  console.log('res data',res.data)
      }).catch((err) => {
        //  console.log('err',err)
      })
  }


  function updateuserpassword(v){
    setsingleemployee(v)
    setupdatepasswordmodal(true)
  }

  const menu = (
    <Menu style={{ width: '350px', marginTop: '12px', padding: '10px' }}>
      <h6 style={{ marginLeft: '14px', fontSize: '14px', fontWeight: '500' }}>Add Employee Id</h6>
      <div style={{ display: 'flex', width: '90%' }}>
        <Input type={"text"} style={{ width: '95%', margin: 'auto', marginLeft: '5%' }} value={tempsearch_employee.employee_id} onChange={e => settempsearch_employee({ ...tempsearch_employee, employee_id: e.target.value })} />
        <PrimaryButton btn_name="+" padding='5px 10px' onClick={() => setsearch_employee({ ...search_employee, employee_id: tempsearch_employee.employee_id })} />
      </div>
    </Menu>
  )

  function handleRole(v) {
    setinputroles(v)
    //  console.log('handleRole',v)
  }

 
  function applyfilter() {
    if (search_employee.employee_id && search_employee.employee_id.toLowerCase().slice(0, 5) === 'fcpl_' || search_employee.employee_id && search_employee.employee_id.toLowerCase().slice(0, 4) === 'fms_') {
      axios.get(`user/search?search=${search_employee.employee_id}&page=1`, authHeader)
        .then((res) => {
          setpagination(res.data.pagination)
          setemployee(res.data.datas.filter(d => d.isActive === true))
          setinactive_employee(res.data.datas.filter(d => d.isActive === false))
          settempsearch_employee({ ...tempsearch_employee, employee_id: '' })
        }).catch((err) => {
          //  console.log('err',err)
        })
    } else {
      toast.error('Enter a valid Fcpl Id for filter')
    }
  }

  function resetfilter() {
    setsearch_employee({ ...search_employee, employee_id: '' })
    getActiveOnboardedEmployee()
  }

  function setpagefun(v) {
    setpage(v)
  }

  function AddRole() {
    setadd_btn(false)
    //  console.log('inputroles',inputroles)
    if (inputroles.length > 0) {
      setselectedroles([])
      let temp = []
      roles_arr.forEach((r) => {
        inputroles.forEach((i) => {
          if (i === r.id) {
            temp.push(r)
            //  console.log('r is',r)
          } else {
            // console.log('no match')

          }
        })
      })

      // console.log('temp',temp)
      setselectedroles(temp)
    } else {
      toast.error('select role for adding')
    }
  }


  function updaterole() {
    // console.log('singleemployeeRoles',selectedroles,'tempsingleemployeeRoles',tempsingleemployeeRoles)
    let roles = []

    // tempsingleemployeeRoles.forEach((r)=>{
    //     roles.push(r._id)
    // })
    selectedroles.forEach((r) => {
      if (!roles.includes(r.id)) {
        roles.push(r.id)
      }
    })

    // console.log('roles',roles,'singleemployee',singleemployee)

    let send_data = {}
    send_data['user_id'] = singleemployee._id
    send_data['roles'] = roles

    axios.put(`user/assign-user-roles`, send_data, authHeader)
      .then((res) => {
        getActiveOnboardedEmployee()
        setvisible1(false)

        // console.log('res data',res.data)
      })
    // console.log('iam updating my roles')
  }

  function updatepasswordfun(){

    if(password.length < 8){
      toast.error('password should be atleast 8 characters')
    }else{
      let send_data = {
        user_id : singleemployee._id,
        password:password
    } 

     axios.post(`user/change-password-admin`,send_data,authHeader)
     .then((res)=>{
      setpassword('')
      setupdatepasswordmodal(false)
      setsingleemployee({})
       toast.success(res.data.message)
     }).catch((err)=>{
      // console.log('err here',err.response)
     })
    }
    
  }

  function setactivetabfunc(v){
      setpage(1)
      if(v === '1'){
        getActiveOnboardedEmployee(1)
      }else{
        getInActiveOnboardedEmployee(1)
      }
      setactivetab(v)
  }




  return (
    <div style={{ width: '90%', margin: 'auto', marginTop: '50px', }}>
      <Toaster />


      <Modal visible={visible1} onCancel={() =>{setselectedroles([]);setvisible1(false)}} onClose={() =>{setselectedroles([]); setvisible1(false)}} closable={false} centered footer={false} style={{ maxWidth: '300px', minHeight: '400px', maxHeight: '400px', overflow: 'hidden' }}>
       
        <IoIosClose  onClick={() => setvisible1(false)}size={20}  style={{position:'absolute',top:'20px',cursor:'pointer',right:'20px'}} />
        <div style={{ width: '300px', }}>
          <h1 style={{ color: 'black', fontSize: '16px', fontWeight: '800', marginTop: '10px', marginLeft: '0px' }}>Assign Roles</h1>

          <div style={{ width: '100%', margin: 'auto' }}>
            <h6 style={{ color: 'black', fontSize: '12px', fontWeight: '500', marginTop: '10px' }}>Roles Provided</h6>
            {tempsingleemployeeRoles?.user_roles?.map((r) => (
              r.roles.map(r1 => (
                <Tag style={{ marginBottom: '5px' }} key={r1?.id} color={`${colors[Math.floor(Math.random() * colors.length)]}`}>{r1.role_name}</Tag>

              ))

            ))}
          </div>

          <div style={{ width: '90%',  }}>
            <h6 style={{ color: 'black', fontSize: '12px', fontWeight: '500', marginTop: '10px' }}>Roles Selected({selectedroles.length})</h6>
            {selectedroles?.map((r) => (
              <Tag key={r.id} style={{ marginBottom: '5px' }} color={`${colors[Math.floor(Math.random() * colors.length)]}`}>{r.value}</Tag>
            ))}
          </div>

          <div>
            <div style={{ display: 'flex', flexDirection: 'row', width: '85%', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px' }}>
              <h6 style={{ color: 'black', fontSize: '13px', fontWeight: '500', marginTop: '10px' }}>Add Roles</h6>
              {!add_btn ?
                <BiPlus  style={{cursor:'pointer'}} size={18} onClick={() => setadd_btn(true)} />
                :
                <BiMinus style={{cursor:'pointer'}} size={18} onClick={() => setadd_btn(false)} />
              }
            </div>
            {add_btn &&
              <>
                <Select
                  style={{ width: '84%' }}
                  mode="multiple"
                  onChange={handleRole}
                >
                  {roles_arr.map((r) => (
                    <Option value={r.id} key={r.id}>{r.value}</Option>
                  ))}

                </Select>

                <div onClick={AddRole}>
                  <PrimaryButton btn_name={'Add Role'} />
                </div>
              </>
            }

            <div onClick={updaterole}>
              <PrimaryButton btn_name={'Update Role'} />
            </div>
          </div>


        </div>
      </Modal>

      <Modal visible={visible} onCancel={() => setvisible(false)} onClose={() => setvisible(false)} closable={false} centered footer={false} style={{ maxWidth: '300px', overflow: 'hidden' }}>

        <div style={{ width: '300px', margin: '-24px', padding: '0px' }}>
      <IoIosClose  onClick={() => setvisible(false)}size={20} style={{position:'absolute',top:'10px',cursor:'pointer',right:'10px'}} />

          <div style={{ height: '200px', width: '100%', borderTopLeftRadius: '2px', borderTopRightRadius: '2px', padding: '10px', color: 'white', borderBottomLeftRadius: '40%', marginTop: '20px' }}>
            {/* <h6 style={{ fontWeight: 'bold', color: 'black', fontSize: '12px', marginBottom: '0px', padding: '5px', borderRadius: '2px', background: '#f0fdff', border: '1px solid #0bb9d4', fontWeight: '500' }}>Note: By clicking the confirm button below the user is no longer can access FCPL CRM. </h6> */}

            <h1 style={{ color: 'black', fontSize: '16px', fontWeight: '400', marginTop: '10px', marginLeft: '5px' }}>Hello <span style={{ fontWeight: 'bolder' }}>{name}</span></h1>
            <h6 style={{ fontSize: '11px', width: '98%', marginLeft: '5px', lineHeight: '1.2rem' }}>Are you sure to {activetab === '1' ? 'inactive' : 'active'} the  employee(<strong>{singleemployee.name}</strong>)
              of employeed id <strong>{singleemployee.employee_id}</strong> )
            <h6 style={{ fontSize: '11px', width: '100%', marginLeft: '0px',marginTop:'5px',borderLeft:`4px solid ${activetab === '1' ? '#850c04' : '#1d6e04'}`, lineHeight: '1rem',background:activetab === '1' ? '#ffcfcc' : '#deffd4',padding:'10px' }}>Be sure before confirm because user account will be in supspended stage no longer he/she can access this application.</h6>  
              {/* who has a designation of (<strong>{singleemployee?.designation_id[0]?.designation_name}</strong>) */}
              {/* and have a roles of {singleemployee?.user_roles[0]?.roles?.map((r)=><Tag color={`${colors[Math.floor(Math.random() * colors.length)]}`}>{r.role_name}</Tag>)}  */}
            </h6>


          </div>

          <div style={{ width: '94%',margin:'auto',marginLeft:'15px',marginTop:'-10px', background: 'white', display: 'flex',  justifyContent: 'space-between' }}>
            <div style={{ marginRight: '10px',width:'46%',cursor:'pointer' }} onClick={() => setvisible(false)}>
                <p style={{border:'1px solid #eee',textAlign:'center',padding:'6px',fontWeight:'800',fontSize:'12px',borderRadius:'4px'}}>Cancel</p>
              {/* <PrimaryButton btn_name="confirm" /> */}
            </div>

            <div style={{ marginRight: '10px',width:'46%',cursor:'pointer' }} onClick={() => updateuseractivestatus()}>
                <p style={{border:'1px solid #eee',textAlign:'center',padding:'6px',fontWeight:'800',fontSize:'12px',background:activetab === '1' ? '#ffcfcc' : '#deffd4',borderRadius:'4px'}}>Confirm</p>
              {/* <PrimaryButton btn_name="confirm" /> */}
            </div>
          </div>


          {/* <div style={{ width: '100%', height: '40%', background: 'white', display: 'flex', alignItems: 'flex-end', justifySelf: 'flex-end', justifyContent: 'flex-end' }}>
            <div style={{ marginRight: '10px' }} onClick={() => updateuseractivestatus()}>
              <PrimaryButton btn_name="confirm" />
            </div>
          </div> */}

        </div>
      </Modal>

      <Modal visible={updatepasswordmodal} closable={false} onCancel={() =>{setpassword(''); setupdatepasswordmodal(false)}} onClose={() =>{setpassword('');setupdatepasswordmodal(false)}} centered footer={false} style={{ maxWidth: '300px', minHeight: '480px', maxHeight: '480px', overflow: 'hidden' }}>
      <IoIosClose  onClick={() =>{setpassword('');setupdatepasswordmodal(false)}}size={20} style={{position:'absolute',top:'10px',cursor:'pointer',right:'10px'}} />

        <div style={{ width: '300px', margin: '-24px', padding: '0px' }}>
          <div style={{ height: '220px', width: '100%', borderTopLeftRadius: '2px', borderTopRightRadius: '2px', padding: '10px', color: 'white', borderBottomLeftRadius: '40%', marginTop: '20px' }}>
            
            {/* <div style={{display:'flex',alignItems:'center',justifyItems:'center',marginTop:'-25px',marginLeft:'5px'}}>
            <img src='https://cdn-icons-png.flaticon.com/512/6582/6582854.png' style={{width:'50px',alignItems:'center',justifySelf:'center'}} />
            </div> */}
            <h1 style={{ color: 'black', fontSize: '14px', fontWeight: '400', marginTop: '10px', marginLeft: '5px',}}><span style={{ fontWeight: 'bolder' }}>{"Update Password"}</span></h1>
            <h6 style={{ fontSize: '11px', width: '98%', marginLeft: '5px', lineHeight: '1rem',background:'#d6ffdb',padding:'10px' }}>Are you sure to  update the <b>password</b> for the employee who is having a name 
              of <strong>({singleemployee.name})</strong> who is having a emplyee_id of <strong>({singleemployee.employee_id})</strong>
            
            </h6>


            <Input
            style={{width:'98%',marginLeft:'2%'}}
              type="text"
              priority="high"
              onChange={(e)=>setpassword(e.target.value)}
              name={'Password'}
              value={password}
             
            />
            
            <div style={{ marginRight: '10px',marginLeft:'2%',width:'100%',paddingBottom:'40px' }} onClick={() => updatepasswordfun()}>
              <PrimaryButton btn_name="Update Password" />
            </div>

          </div>
          

        </div>
      </Modal>

      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <h6 style={{ fontWeight: '600',fontSize:'15px' }}>Onboarded Employee</h6>
        <div onClick={() => navigate('/on_board')}>
          <PrimaryButton btn_name="+ Onboard Employee" />
        </div>
      </div>


      <Tabs defaultActiveKey={activetab} onChange={(v)=>setactivetabfunc(v)} style={{ marginTop: '-15px' }}>

        <TabPane tab="Active Employee's" key="1">
          <div style={{ width: '100%', margin: 'auto', marginBottom: '100px' }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '5px', marginTop: '-10px', }}>
              <h6 style={{ marginBottom: '0px', fontWeight: '500', fontSize: '14px' }}>Active Employees ({pagination.total})</h6>
              <div style={{ display: 'flex', alignItems: 'center', padding: '0px', marginLeft: '0px', padding: '5px 0px' }}>
                <HiOutlineFilter size={24} style={{ marginRight: '10px' }} />
                <h6 style={{ marginBottom: '0px', marginRight: '15px', fontSize: '14px', fontWeight: 'bolder' }}>Filter Employee </h6>
                <div style={{ display: 'flex', alignItems: 'center' }}>

                  {search_employee.employee_id === '' ?
                    <Dropdown overlay={menu} trigger={['click']}>
                      <button type='button' className='d-flex btn  btn-light btn-sm m-1'   >
                        <GrFormAdd color="white" size={18} style={{ marginRight: '6px',marginTop:'2px', color: 'white' }} />
                        <span>Add Search filter</span>
                      </button>
                    </Dropdown>
                    :
                    <div style={{ display: 'flex', alignItems: 'center', background: '#f8f9fa', padding: '8px', borderRadius: '2px' }}>
                      <h6 style={{ marginBottom: '0px', fontSize: '12px' }}>{search_employee.employee_id}</h6>
                      <IoClose style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => setsearch_employee({ ...search_employee, employee_id: '' })} />
                    </div>

                  }
                </div>

                <div style={{ marginRight: '5px' }} onClick={applyfilter}>
                  <PrimaryButtonFilter btn_name="Apply Filter" padding={'2px 8px'} />
                </div>
                <div style={{ marginRight: '5px' }} onClick={resetfilter}>
                  <PrimaryButtonFilter btn_name="Reset Filter" padding={'2px 8px'} />
                </div>
              </div>
            </div>
            {employee.length > 0 ?
              <div>
                <div className='mateial_table_header'>
                  <p style={{ width: '5%', fontWeight: '800', color: 'black' }}>ID</p>
                  <p style={{ width: '8%' }}>FCPL ID</p>
                  <p style={{ width: '15%' }}>Name</p>
                  <p style={{ width: '20%', marginRight: '1%' }}>Email</p>
                  <p style={{ width: '15%', marginRight: '1%' }}>Department</p>
                  <p style={{ width: '10%', marginRight: '1%' }}>Designation</p>
                  <p style={{ width: '15%' }}>Roles</p>
                  <p style={{ width: '5%' }}>Active</p>
                  <p style={{ width: '10%',marginLeft:'10px' }}>Actions</p>
                </div>

                {employee?.map((d, i) => (
                  <div key={d._id} className={'mateial_table_body'}>
                    <p style={{ width: '5%', fontWeight: '500', color: 'black' }}>{page > 1 ? ((page - 1) * 10) + (i + 1) : (i + 1)}</p>
                    <p style={{ width: '8%', fontWeight: '500', wordBreak: 'break-word', fontWeight: 'bolder',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis', }}>{d?.employee_id}</p>
                    <p style={{ width: '15%', wordBreak: 'break-word',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis', }}>{d?.name}</p>
                    <p style={{ width: '20%', wordBreak: 'break-word', marginRight: '1%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis', }}>{d?.official_email}</p>
                    {/* <Dropdown overlay={menu}  trigger={["click"]} onClick={()=>setsingleemployee(d)} placement={'bottomRight'} arrow={true} > */}
                    <p style={{ width: '15%', wordBreak: 'break-word', marginRight: '1%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis', }}><Tag color={`${colors[Math.floor(Math.random() * colors.length)]}`}>{d?.department_id.map(d => d.department_name)}</Tag></p>
                    {/* </Dropdown> */}
                    <p style={{ width: '10%', wordBreak: 'break-word', marginRight: '1%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis', }}>{d?.designation_id[0]?.designation_name}</p>
                    <p style={{ width: '15%', wordBreak: 'break-word', fontWeight: 'bolder',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis', }}>{d?.user_roles?.map((r) => r.roles.map((r) => <Tag style={{ marginBottom: '2px' }} color={`${colors[Math.floor(Math.random() * colors.length)]}`}>{r.role_name}  </Tag>))}</p>
                    <p style={{ width: '5%', wordBreak: 'break-word', paddingLeft: '10px' }}>
                      {/* <Checkbox checked={d?.isActive} onClick={() => updateactivestatus(d, '0')} /> */}
                      <p className={d?.isActive  ? 'checked_here2': 'checked_here'} onClick={()=>d?.isActive && updateactivestatus(d,'0') }>
                        {d?.isActive && <p className='checked_here1' style={{background:theme_color}}></p>}
                      </p>
                      
                      </p>
                    <p style={{ width: '10%',display:'flex', paddingLeft: '10px',justifyContent:'space-between' }}><AiOutlineDelete size={16} onClick={() => DeleteOnboarderData(d._id)} />  <FiEdit size={16} onClick={() => UpdateOnboardedData(d._id)} />   <BsFileEarmarkLock2 size={16} style={{ marginLeft: '5px' }} onClick={()=>updateuserpassword(d)}/> <FaUserShield size={16} style={{ marginLeft: '5px' }} onClick={() => updateactivestatus(d, '3')} /></p>
                  </div>
                ))}

                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
                  {pagination.total > 10 &&
                    <Pagination current={page} size="small" total={pagination.total} onChange={(v) => setpagefun(v)} showSizeChanger pageSize={10} />
                  }
                </div>
              </div>
              :
              <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: data_not_found_img_width, objectFit: 'contain' }} />
                <h4 style={{ fontSize: '18px', fontWeight: '700', marginTop: '10px', textAlign: 'center' }}>{"No active users found."}</h4>
                <h6 style={{ color: '#747474', fontSize: '13px', maxWidth: '100%', textAlign: 'center', margin: 'auto' }}>{"No active users found in the current page"}</h6>
              </div>
            }
          </div>


        </TabPane>

        <TabPane tab="InActive Employee's" key="2">
          <div style={{ width: '100%', margin: 'auto', marginBottom: '0px' }}>
            <h6 style={{ marginBottom: '24px', fontWeight: '500', fontSize: '14px',marginTop:'11.5px' }}>InActive Employees ({pagination.total})</h6>

            {inactive_employee.length > 0 ?
              <>
                <div className='mateial_table_header'>
                  <p style={{ width: '5%', fontWeight: '800', color: 'black' }}>ID</p>
                  <p style={{ width: '8%' }}>FCPL ID</p>
                  <p style={{ width: '15%' }}>Name</p>
                  <p style={{ width: '20%', marginRight: '1%' }}>Email</p>
                  <p style={{ width: '15%', marginRight: '1%' }}>Department</p>
                  <p style={{ width: '10%', marginRight: '1%' }}>Designation</p>
                  <p style={{ width: '15%' }}>Roles</p>
                  <p style={{ width: '5%' }}>Active</p>
                  <p style={{ width: '7%' }}>Actions</p>
                </div>

                {inactive_employee.map((d, i) => (
                  <div key={d._id} className={'mateial_table_body'}>
                    <p style={{ width: '5%', fontWeight: '500', color: 'black' }}>{page > 1 ? ((page - 1) * 10) + (i + 1) : (i + 1)}</p>
                    <p style={{ width: '8%', fontWeight: '500', wordBreak: 'break-word', fontWeight: 'bolder',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis', }}>{d?.employee_id}</p>
                    <p style={{ width: '15%', wordBreak: 'break-word',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis', }}>{d?.name}</p>
                    <p style={{ width: '20%', wordBreak: 'break-word', marginRight: '1%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis', }}>{d?.official_email}</p>
                    <p style={{ width: '15%', wordBreak: 'break-word', marginRight: '1%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis', }} onClick={() => setsingleemployee(d)}><Tag color={`${colors[Math.floor(Math.random() * colors.length)]}`}>{d?.department_id.map(d => d.department_name)}</Tag></p>
                    <p style={{ width: '10%', wordBreak: 'break-word', marginRight: '1%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis', }}>{d?.designation_id[0]?.designation_name}</p>
                    <p style={{ width: '15%', wordBreak: 'break-word', fontWeight: 'bolder',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis', }}>{d?.user_roles[0]?.roles.map((r) => <Tag style={{ marginBottom: '2px' }} color={`${colors[Math.floor(Math.random() * colors.length)]}`}>{r.role_name}</Tag>)}</p>
                    <p style={{ width: '5%', wordBreak: 'break-word', paddingLeft: '10px' }}>
                      {/* <Checkbox checked={d?.isActive} onClick={() => updateactivestatus(d, '1')} /> */}
                      <p style={{ width: '5%', wordBreak: 'break-word', paddingLeft: '10px' }}>
                      {/* <Checkbox checked={d?.isActive} onClick={() => updateactivestatus(d, '0')} /> */}
                      <p className={d?.isActive  ? 'checked_here2': 'checked_here'} onClick={()=>!d?.isActive && updateactivestatus(d,'1') }>
                        {d?.isActive && <p className='checked_here1' style={{background:theme_color}}></p>}
                      </p>
                      
                      </p>
                    </p>
                    <p style={{ width: '7%', paddingLeft: '10px' }}><FiEdit size={15} onClick={() => UpdateOnboardedData(d._id)} /></p>
                  </div>
                ))}

                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
                  {pagination.total > 10 &&
                    <Pagination current={page} size="small" total={pagination.total} onChange={(v) => setpagefun(v)} showSizeChanger pageSize={10} />
                  }
                </div>
              </>

              :

              <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: data_not_found_img_width, objectFit: 'contain' }} />
                <h4 style={{ fontSize: '18px', fontWeight: '700', marginTop: '10px', textAlign: 'center' }}>{"No in-active users found."}</h4>
                <h6 style={{ color: '#747474', fontSize: '13px', maxWidth: '100%', textAlign: 'center', margin: 'auto' }}>{"No in-active users found in the current page"}</h6>
              </div>
            }
          </div>






        </TabPane>

      </Tabs>


    </div>

  )
}

export default OnboardEmployee;
