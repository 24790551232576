import React, { useState, useEffect } from 'react';
import PrimaryButton, { PrimaryButtonFilter } from '../../../components/buttons/PrimaryButton';
import axios from 'axios'
import { authHeader } from '../../../helpers/usertoken/UserToken';
import { FiEdit } from 'react-icons/fi'
import { Dropdown, Menu } from 'antd'
import { useNavigate } from 'react-router-dom';
import { Tag, Modal, Select } from 'antd';
import { useSelector } from 'react-redux';
import { Tabs, Input } from 'antd';
import { HiOutlineFilter } from 'react-icons/hi'
import { GrFormAdd } from 'react-icons/gr'
import { IoClose } from 'react-icons/io5'
import { Pagination } from 'antd'
import {BsFileEarmarkLock2} from 'react-icons/bs'
import toast, { Toaster } from "react-hot-toast";
import { data_not_found, data_not_found_img_width } from '../../../helpers/Constants';

import {IoIosClose} from 'react-icons/io'
import FmsDashboardMainRoot from '../FmsDashboardMainRoot';


const { TabPane } = Tabs;
const { Option } = Select;

function FmsInHouseEmployee() {

  const [activetab,setactivetab] = useState('1')
  const [roles_arr, setroles_arr] = useState([])
  const [employee, setemployee] = useState([])
  const [inactive_employee, setinactive_employee] = useState([])

  const [singleemployee, setsingleemployee] = useState({})
  const [singleemployeeRoles, setsingleemployeeRoles] = useState([])
  const [tempsingleemployeeRoles, settempsingleemployeeRoles] = useState([])

  const [inputroles, setinputroles] = useState([])
  const [selectedroles, setselectedroles] = useState([])

  const [visible, setvisible] = useState(false)
  const [visible1, setvisible1] = useState(false)
  const [updatepasswordmodal,setupdatepasswordmodal] = useState(false)
  const [page, setpage] = useState('1')
  const [pagination, setpagination] = useState({})
  const [password,setpassword] = useState('')

  const [add_btn, setadd_btn] = useState(false)



  const [tab, settab] = useState('0')

  const [search_employee, setsearch_employee] = useState({ employee_id: '', })
  const [tempsearch_employee, settempsearch_employee] = useState({ employee_id: '', })


  const name = useSelector(state => state.Auth.name)
  const theme_color = useSelector(state=>state.Theme.theme_color)



  const navigate = useNavigate()

  const colors = ['magenta', 'red', 'volcano', 'orange', 'gold', 'lime', 'green', 'cyan', 'blue', 'geekblue']


  console.log('inactive_employee',inactive_employee)


  useEffect(() => {
    if(activetab === '1'){
      getActiveOnboardedEmployee()
    }else{
      getInActiveOnboardedEmployee()
    }
  }, [page])

  useEffect(()=>{
    setpage(1)
    setactivetab('1')
    getActiveOnboardedEmployee(1)
  },[])





 

 async function getActiveOnboardedEmployee(v) {
    // console.log('v na na nan',v)
   await axios.get(`fms/fms_in_house_employee/get-active-or-nonactive/active/${page}`, authHeader)
      .then((res) => {
        // console.log('amma here',res.data)
        setpagination(res.data.pagination)
        setemployee(res.data.datas)
      })
  }

  function getInActiveOnboardedEmployee(v) {
    axios.get(`fms/fms_in_house_employee/get-active-or-nonactive/inactive/${page}`, authHeader)
      .then((res) => {
        setpagination(res.data.pagination)
        setinactive_employee(res.data.datas)
      })
  }

  function UpdateOnboardedData(v) {
    //  console.log('UpdateOnboardedData',v)
    navigate(`edit/${v}`)
  }

  function updateactivestatus(v, v1) {
    settab(v1)
    setsingleemployee(v)
    if (v1 === '3') {
      let dat = v.user_roles[0]?.roles.map((r) => {
        let data = {}
        data['label'] = r.role_name
        data['value'] = r.display_name
        data['id'] = r._id
      })
      setsingleemployeeRoles(v.user_roles[0]?.roles)
      settempsingleemployeeRoles(v)
      setvisible1(true)
      getActiveOnboardedEmployee()
    } else {
      // console.log('anna me',v1)
      setvisible(true)
    }
  }

  


  function updateuserpassword(v){
    setsingleemployee(v)
    setupdatepasswordmodal(true)
  }

  const menu = (
    <Menu style={{ width: '350px', marginTop: '12px', padding: '10px' }}>
      <h6 style={{ marginLeft: '14px', fontSize: '14px', fontWeight: '500' }}>Add Employee Id</h6>
      <div style={{ display: 'flex', width: '90%' }}>
        <Input type={"text"} style={{ width: '95%', margin: 'auto', marginLeft: '5%' }} value={tempsearch_employee.employee_id} onChange={e => settempsearch_employee({ ...tempsearch_employee, employee_id: e.target.value })} />
        <PrimaryButton btn_name="+" padding='5px 10px' onClick={() => setsearch_employee({ ...search_employee, employee_id: tempsearch_employee.employee_id })} />
      </div>
    </Menu>
  )

  function handleRole(v) {
    setinputroles(v)
    //  console.log('handleRole',v)
  }

 
  function applyfilter() {
    if (search_employee.employee_id) {
      axios.get(`fms/fms_in_house_employee/search?search=${search_employee.employee_id}&page=1&activetab=${activetab}`, authHeader)
        .then((res) => {
          setpagination(res.data.pagination)
          if(activetab === '1'){
            setemployee(res.data.datas.filter(d => d.isActive === true))
            setinactive_employee(res.data.datas.filter(d => d.isActive === false))
            settempsearch_employee({ ...tempsearch_employee, employee_id: '' })
          }else{
          setemployee(res.data.datas.filter(d => d.isActive === true))
          setinactive_employee(res.data.datas.filter(d => d.isActive === false))
          settempsearch_employee({ ...tempsearch_employee, employee_id: '' })
          }
        }).catch((err) => {
          //  console.log('err',err)
        })
    } else {
      toast.error('Enter a valid FMS ID for filter')
    }
  }

  function resetfilter() {
    setsearch_employee({ ...search_employee, employee_id: '' })
    getActiveOnboardedEmployee()
  }

  function setpagefun(v) {
    setpage(v)
  }

  function AddRole() {
    setadd_btn(false)
    //  console.log('inputroles',inputroles)
    if (inputroles.length > 0) {
      setselectedroles([])
      let temp = []
      roles_arr.forEach((r) => {
        inputroles.forEach((i) => {
          if (i === r.id) {
            temp.push(r)
            //  console.log('r is',r)
          } else {
            // console.log('no match')

          }
        })
      })

      // console.log('temp',temp)
      setselectedroles(temp)
    } else {
      toast.error('select role for adding')
    }
  }


  function updatepasswordfun(){

    if(password.length < 8){
      toast.error('password should be atleast 8 characters')
    }else{
      let send_data = {
        user_id : singleemployee._id,
        password:password
    } 

     axios.post(`fms/fms_in_house_employee/change-password-admin`,send_data,authHeader)
     .then((res)=>{
      setpassword('')
      setupdatepasswordmodal(false)
      setsingleemployee({})
       toast.success(res.data.message)
     }).catch((err)=>{
      // console.log('err here',err.response)
     })
    }
    
  }

  function setactivetabfunc(v){
      setpage(1)
      if(v === '1'){
        getActiveOnboardedEmployee(1)
      }else{
        getInActiveOnboardedEmployee(1)
      }
      setactivetab(v)
  }





  return (
    <FmsDashboardMainRoot>

    <div style={{ width: '95%',  marginTop: '-10px', }}>
      <Toaster />


      


      <Modal visible={updatepasswordmodal} closable={false} onCancel={() =>{setpassword(''); setupdatepasswordmodal(false)}} onClose={() =>{setpassword('');setupdatepasswordmodal(false)}} centered footer={false} style={{ maxWidth: '300px', minHeight: '480px', maxHeight: '480px', overflow: 'hidden' }}>
      <IoIosClose  onClick={() =>{setpassword('');setupdatepasswordmodal(false)}}size={20} style={{position:'absolute',top:'10px',cursor:'pointer',right:'10px'}} />

        <div style={{ width: '300px', margin: '-24px', padding: '0px' }}>
          <div style={{ height: '220px', width: '100%', borderTopLeftRadius: '2px', borderTopRightRadius: '2px', padding: '10px', color: 'white', borderBottomLeftRadius: '40%', marginTop: '20px' }}>
            
            {/* <div style={{display:'flex',alignItems:'center',justifyItems:'center',marginTop:'-25px',marginLeft:'5px'}}>
            <img src='https://cdn-icons-png.flaticon.com/512/6582/6582854.png' style={{width:'50px',alignItems:'center',justifySelf:'center'}} />
            </div> */}
            <h1 style={{ color: 'black', fontSize: '14px', fontWeight: '400', marginTop: '10px', marginLeft: '5px',}}><span style={{ fontWeight: 'bolder' }}>{"Update Password"}</span></h1>
            <h6 style={{ fontSize: '11px', width: '98%', marginLeft: '5px', lineHeight: '1rem',background:'#d6ffdb',padding:'10px' }}>Are you sure to  update the <b>password</b> for the employee who is having a name 
              of <strong>({singleemployee.name})</strong> who is having a emplyee_id of <strong>({singleemployee.employee_id})</strong>
            
            </h6>


            <Input
            style={{width:'98%',marginLeft:'2%'}}
              type="text"
              priority="high"
              onChange={(e)=>setpassword(e.target.value)}
              name={'Password'}
              value={password}
             
            />
            
            <div style={{ marginRight: '10px',marginLeft:'2%',width:'100%',paddingBottom:'40px' }} onClick={() => updatepasswordfun()}>
              <PrimaryButton btn_name="Update Password" />
            </div>

          </div>
          

        </div>
      </Modal>

      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <h6 style={{ fontWeight: '600',fontSize:'15px' }}>Onboarded Employee</h6>
        <div onClick={() => navigate('create')}>
          <PrimaryButton btn_name="+ Onboard Employee" />
        </div>
      </div>


      <Tabs defaultActiveKey={activetab} onChange={(v)=>setactivetabfunc(v)} style={{ marginTop: '-15px' }}>

        <TabPane tab="Active Employee's" key="1">
          <div style={{ width: '100%', margin: 'auto', marginBottom: '100px' }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '5px', marginTop: '-10px', }}>
              <h6 style={{ marginBottom: '0px', fontWeight: '500', fontSize: '14px' }}>Active Employees ({pagination.total})</h6>
              <div style={{ display: 'flex', alignItems: 'center', padding: '0px', marginLeft: '0px', padding: '5px 0px' }}>
                <HiOutlineFilter size={24} style={{ marginRight: '10px' }} />
                <h6 style={{ marginBottom: '0px', marginRight: '15px', fontSize: '14px', fontWeight: 'bolder' }}>Filter Employee </h6>
                <div style={{ display: 'flex', alignItems: 'center' }}>

                  {search_employee.employee_id === '' ?
                    <Dropdown overlay={menu} trigger={['click']}>
                      <button type='button' className='d-flex btn  btn-light btn-sm m-1'   >
                        <GrFormAdd color="black" size={18} style={{ marginRight: '6px',marginTop:'2px', color: 'black' }} />
                        <span>Add Search filter</span>
                      </button>
                    </Dropdown>
                    :
                    <div style={{ display: 'flex', alignItems: 'center', background: '#f8f9fa', padding: '8px', borderRadius: '2px' }}>
                      <h6 style={{ marginBottom: '0px', fontSize: '12px' }}>{search_employee.employee_id}</h6>
                      <IoClose style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => setsearch_employee({ ...search_employee, employee_id: '' })} />
                    </div>

                  }
                </div>

                <div style={{ marginRight: '5px' }} onClick={applyfilter}>
                  <PrimaryButtonFilter btn_name="Apply Filter" padding={'2px 8px'} />
                </div>
                <div style={{ marginRight: '5px' }} onClick={resetfilter}>
                  <PrimaryButtonFilter btn_name="Reset Filter" padding={'2px 8px'} />
                </div>
              </div>
            </div>
            {employee.length > 0 ?
              <div>
                <div className='mateial_table_header'>
                  <p style={{ maxWidth:'5.5%',minWidth: '5.5%', fontWeight: '800', color: 'black' }}>ID</p>
                  <p style={{ maxWidth:'8.5%',minWidth: '8.5%' }}>FMS ID</p>
                  <p style={{ maxWidth:'12.5%',minWidth: '12.5%' }}>Name</p>
                  <p style={{ maxWidth:'12.5%',minWidth: '12.5%' }}>Phone</p>
                  <p style={{ maxWidth:'20.5%',minWidth: '20.5%', }}>Email</p>
                  <p style={{ maxWidth:'10.5%',minWidth: '10.5%', }}>Designation</p>
                  <p style={{ maxWidth:'15.5%',minWidth: '15.5%' }}>Roles</p>
                  <p style={{ maxWidth:'5.5%',minWidth: '5.5%' }}>Active</p>
                  <p style={{ maxWidth:'10.5%',minWidth: '10.5%', }}>Actions</p>
                </div>

                {employee?.map((d, i) => (
                  <div key={d._id} className={'mateial_table_body'}>
                    <p style={{ minWidth: '5%',maxWidth:'5%', fontWeight: '500', color: 'black' }}>{page > 1 ? ((page - 1) * pagination?.limit) + (i + 1) : (i + 1)}</p>
                    <p style={{ minWidth: '8%',maxWidth:'8%', fontWeight: '500', wordBreak: 'break-word', fontWeight: 'bolder',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',textTransform:'uppercase' }}>{d?.employee_id}</p>
                    <p style={{ minWidth: '12%',maxWidth:'12%', wordBreak: 'break-word',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis', }}>{d?.name}</p>
                    <p style={{ minWidth: '12%',maxWidth:'12%', wordBreak: 'break-word',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis', }}>{d?.official_phone}</p>
                    <p style={{ minWidth: '20%',maxWidth:'20%', wordBreak: 'break-word', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis', }}>{d?.official_email}</p>
                    <p style={{ minWidth: '10%',maxWidth:'10%', wordBreak: 'break-word', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis', }}>{d?.designation_id?.designation_name}</p>
                    <p style={{ minWidth: '15%',maxWidth:'15%', wordBreak: 'break-word', fontWeight: 'bolder',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis', }}>{d?.roles?.map((r) =>  <Tag style={{ marginBottom: '2px',textTransform:'uppercase',fontSize:'8px' }} color={`${colors[Math.floor(Math.random() * colors.length)]}`}>{r.display_name}  </Tag>)}</p>
                    <p style={{ minWidth: '5%', maxWidth:'5%',wordBreak: 'break-word',marginLeft:10 }}><p className={d?.isActive  ? 'checked_here2': 'checked_here'} onClick={()=>d?.isActive && updateactivestatus(d,'0') }>{d?.isActive && <p className='checked_here1' style={{background:theme_color}}></p>}</p></p>
                    <p style={{ minWidth: '10%',maxWidth:'10%', display:'flex', marginLeft:'0px' }}>
                      <FiEdit size={16} onClick={() => UpdateOnboardedData(d._id)} />  
                      <BsFileEarmarkLock2 size={16} style={{ marginLeft: '15px' }} onClick={()=>updateuserpassword(d)}/> 
                      </p>
                  </div>
                ))}



                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
                  {pagination.total > pagination?.limit &&
                    <Pagination showSizeChanger={false} current={page} size="small" total={pagination.total} onChange={(v) => setpagefun(v)}  pageSize={pagination?.limit} />
                  }
                </div>
              </div>
              :
              <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: data_not_found_img_width, objectFit: 'contain' }} />
                <h4 style={{ fontSize: '18px', fontWeight: '700', marginTop: '10px', textAlign: 'center' }}>{"No active users found."}</h4>
                <h6 style={{ color: '#747474', fontSize: '13px', maxWidth: '100%', textAlign: 'center', margin: 'auto' }}>{"No active users found in the current page"}</h6>
              </div>
            }
          </div>


        </TabPane>

        <TabPane tab="InActive Employee's" key="2">
          <div style={{ width: '100%', margin: 'auto', marginBottom: '0px' }}>

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '5px', marginTop: '-10px', }}>
              <h6 style={{ marginBottom: '0px', fontWeight: '500', fontSize: '14px' }}>InActive Employees ({pagination.total})</h6>
              <div style={{ display: 'flex', alignItems: 'center', padding: '0px', marginLeft: '0px', padding: '5px 0px' }}>
                <HiOutlineFilter size={24} style={{ marginRight: '10px' }} />
                <h6 style={{ marginBottom: '0px', marginRight: '15px', fontSize: '14px', fontWeight: 'bolder' }}>Filter Employee </h6>
                <div style={{ display: 'flex', alignItems: 'center' }}>

                  {search_employee.employee_id === '' ?
                    <Dropdown overlay={menu} trigger={['click']}>
                      <button type='button' className='d-flex btn  btn-light btn-sm m-1'   >
                        <GrFormAdd color="black" size={18} style={{ marginRight: '6px',marginTop:'2px', color: 'black' }} />
                        <span>Add Search filter</span>
                      </button>
                    </Dropdown>
                    :
                    <div style={{ display: 'flex', alignItems: 'center', background: '#f8f9fa', padding: '8px', borderRadius: '2px' }}>
                      <h6 style={{ marginBottom: '0px', fontSize: '12px' }}>{search_employee.employee_id}</h6>
                      <IoClose style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => setsearch_employee({ ...search_employee, employee_id: '' })} />
                    </div>

                  }
                </div>

                <div style={{ marginRight: '5px' }} onClick={applyfilter}>
                  <PrimaryButtonFilter btn_name="Apply Filter" padding={'2px 8px'} />
                </div>
                <div style={{ marginRight: '5px' }} onClick={resetfilter}>
                  <PrimaryButtonFilter btn_name="Reset Filter" padding={'2px 8px'} />
                </div>
              </div>
            </div>

            {inactive_employee.length > 0 ?
              <>
                <div className='mateial_table_header'>
                <p style={{ maxWidth:'5.5%',minWidth: '5.5%', fontWeight: '800', color: 'black' }}>ID</p>
                  <p style={{ maxWidth:'8.5%',minWidth: '8.5%' }}>FMS ID</p>
                  <p style={{ maxWidth:'12.5%',minWidth: '12.5%' }}>Name</p>
                  <p style={{ maxWidth:'12.5%',minWidth: '12.5%' }}>Phone</p>
                  <p style={{ maxWidth:'20.5%',minWidth: '20.5%', }}>Email</p>
                  <p style={{ maxWidth:'10.5%',minWidth: '10.5%', }}>Designation</p>
                  <p style={{ maxWidth:'15.5%',minWidth: '15.5%' }}>Roles</p>
                  <p style={{ maxWidth:'5.5%',minWidth: '5.5%' }}>Active</p>
                  <p style={{ maxWidth:'10.5%',minWidth: '10.5%', }}>Actions</p>
                </div>

                {inactive_employee?.map((d, i) => (
                  <div key={d._id} className={'mateial_table_body'}>
                  <p style={{ minWidth: '5%',maxWidth:'5%', fontWeight: '500', color: 'black' }}>{page > 1 ? ((page - 1) * pagination?.limit) + (i + 1) : (i + 1)}</p>
                  <p style={{ minWidth: '8%',maxWidth:'8%', fontWeight: '500', wordBreak: 'break-word', fontWeight: 'bolder',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',textTransform:'uppercase' }}>{d?.employee_id}</p>
                  <p style={{ minWidth: '12%',maxWidth:'12%', wordBreak: 'break-word',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis', }}>{d?.name}</p>
                  <p style={{ minWidth: '12%',maxWidth:'12%', wordBreak: 'break-word',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis', }}>{d?.official_phone}</p>
                  <p style={{ minWidth: '20%',maxWidth:'20%', wordBreak: 'break-word', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis', }}>{d?.official_email}</p>
                  <p style={{ minWidth: '10%',maxWidth:'10%', wordBreak: 'break-word', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis', }}>{d?.designation_id?.designation_name}</p>
                  <p style={{ minWidth: '15%',maxWidth:'15%', wordBreak: 'break-word', fontWeight: 'bolder',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis', }}>{d?.roles?.map((r) =>  <Tag style={{ marginBottom: '2px',textTransform:'uppercase',fontSize:'8px' }} color={`${colors[Math.floor(Math.random() * colors.length)]}`}>{r.display_name}  </Tag>)}</p>
                  <p style={{ minWidth: '5%', maxWidth:'5%',wordBreak: 'break-word',marginLeft:10 }}><p className={d?.isActive  ? 'checked_here2': 'checked_here'} onClick={()=>d?.isActive && updateactivestatus(d,'0') }>{d?.isActive && <p className='checked_here1' style={{background:theme_color}}></p>}</p></p>
                  <p style={{ minWidth: '10%',maxWidth:'10%', display:'flex', marginLeft:'0px' }}>
                    <FiEdit size={16} onClick={() => UpdateOnboardedData(d._id)} />  
                    </p>
                </div>
                ))}

                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
                  {pagination.total > pagination?.limit &&
                    <Pagination  showSizeChanger={false} current={page} size="small" total={pagination.total} onChange={(v) => setpagefun(v)} pageSize={pagination?.limit} />
                  }
                </div>
              </>

              :

              <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: data_not_found_img_width, objectFit: 'contain' }} />
                <h4 style={{ fontSize: '18px', fontWeight: '700', marginTop: '10px', textAlign: 'center' }}>{"No in-active users found."}</h4>
                <h6 style={{ color: '#747474', fontSize: '13px', maxWidth: '100%', textAlign: 'center', margin: 'auto' }}>{"No in-active users found in the current page"}</h6>
              </div>
            }
          </div>






        </TabPane>

      </Tabs>


    </div>
    </FmsDashboardMainRoot>

  )
}

export default FmsInHouseEmployee;
