import React, { useEffect } from 'react'
import Inputerror from '../errors/Inputerror'
import { Select } from 'antd';


function DropDown({ priority, error_obj, onChange, name, id_name, nameerr, options, value, size }) {

    // const [dropdown, setdropdown] = useState(false)
    // const [selected,setselected] = useState({})

    useEffect(() => {
        window.addEventListener('click', handleclick)
        return () => {
            window.removeEventListener('click', handleclick)
        }
    }, [])

    function handleChange(id) {
        // console.log('id is u know',id)
        onChange({ id, name, nameerr, id_name })
    }

    function handleclick() {

    }

    function onSearch() {

    }




    return (
        <div style={{ width: '100%' }}>
            <Select
                size={size ? 'large' : 'middle'}
                showSearch
                onSearch={onSearch}
                style={{ width: '100%', borderLeft: priority !== 'low' ? '4px solid #292961' : '', borderRadius: '4px' }}
                value={value} onChange={handleChange}
                filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }>
                {
                    options?.map((option) => <option key={option.id} value={option.id}>{option.value}</option>)
                }
            </Select>
            <Inputerror message={error_obj?.message} />
        </div>
    )
}

export default DropDown