import React,{useState,useEffect} from 'react'
import FmsDashboardMainRoot from '../FmsDashboardMainRoot'
import { BoldText1 } from '../../../helpers/constants/ConstantsText';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import axios from 'axios';
import {BiEditAlt} from 'react-icons/bi'
import Tooltip from '@mui/material/Tooltip';
import { data_not_found } from '../../../helpers/Constants';
import { authHeader } from '../../../helpers/usertoken/UserToken';
import { Pagination, Tabs, Tag } from 'antd'
import Avatar from '@mui/material/Avatar';
import { base_url, local_base_url } from '../../../App';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import {FaWpforms} from 'react-icons/fa';
import {RiNewspaperLine} from 'react-icons/ri';
import {MdDeleteOutline,MdOutlineRefresh} from 'react-icons/md'
import toast,{ Toaster } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { MdExitToApp } from 'react-icons/md';
import {BsSearch} from 'react-icons/bs';
import fileDownload from "js-file-download";

const { TabPane } = Tabs;


function FmsEmployeeOverallList(){


    const navigate = useNavigate()
    const roles = useSelector(state => state.Auth.roles)


    const [search,setsearch] = useState('')
   
    const [page,setpage] = useState(1)
    const [data,setdata] = useState([])
    const [pagination,setpagination] = useState({})

    useEffect(()=>{
        getdata()
    },[page])



    function getdata(){
      axios.get(`fms/basicemployee/get_all?page=${page}&search=${search}`,authHeader)
      .then((res)=>{
        setdata(res.data.datas)
        setpagination(res.data.pagination)
      })
    }

   
   
   
    function deleterecord(v){
        axios.delete(`fms/basicemployee/delete/${v}`,authHeader)
        .then((res)=>{
            toast.success(res.data.message);
            getdata('1')
        })
    }

    function setpagefuun(v){
        setpage(v)
    }

    function applyFilter(){
        setpage(1)
        axios.get(`fms/basicemployee/get_all?page=${1}&search=${search}`,authHeader)
        .then((res)=>{
          setdata(res.data.datas)
          setpagination(res.data.pagination)
        })
    }

    function resetFilter(){
        setsearch('')
        setpage(1)
        axios.get(`fms/basicemployee/get_all?page=${1}&search=${''}`,authHeader)
        .then((res)=>{
          setdata(res.data.datas)
          setpagination(res.data.pagination)
        })
    }

   
   



    return(
        <FmsDashboardMainRoot> 

            <Toaster />
            <div style={{width:'96%'}}>

             <div style={{display:'flex',alignItems:'center',width:'100%',justifyContent:'space-between'}}>
              <BoldText1 val={`FMS Employees`} />
              <div className='flex' style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <input value={search} onChange={(e)=>{setsearch(e.target.value)}} type='text' style={{border:'1px solid #eee',outline:'0',padding:'3px'}} /> 
                        <BsSearch onClick={applyFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'7px'}}/>
                        <MdOutlineRefresh onClick={resetFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'4px'}}/>
                    </div>
              </div>   
             </div>
                

              <div style={{borderTop:'1px solid lightgray',marginTop:'5px'}}>
              
                  

                    {data.length > 0 ? 
                    <div style={{ border: '0px solid lightgray',marginTop:'10px', overflowY: 'scroll',width:'100%' }}>
                        <div className='mateial_table_header'>
                            <p className='checked_here' style={{opacity:0}} ></p> 
                            <p style={{fontSize:'12px',minWidth: '3%', color: 'black',marginLeft:'-10px',marginRight:'10px' }}>Sl No</p>
                            <p style={{fontSize:'12px',minWidth: '5%', marginRight: '0.5%',marginLeft:'10PX' }}>Avatar</p>
                            <p style={{fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>Employee ID</p>
                            <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Employee Name</p>
                            <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Mobile No</p>
                            <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Designation</p>
                            <p style={{fontSize:'12px',minWidth: '12%', marginRight: '1%' }}>Site Name</p>
                            <p style={{fontSize:'12px',minWidth: '6%', marginRight: '0.5%' }}>Status</p>
                            <p style={{fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>DOJ</p>
                            <p style={{fontSize:'12px',minWidth: '12%', marginRight: '0.5%' }}>Created At</p>
                            <p style={{fontSize:'12px',minWidth: '8%' }}>Actions</p>
                        </div>


                        {data.map((d, i) => (
                            <div key={i} className={'mateial_table_body'} style={{maxWidth:'100%',marginLeft:'0%'}}>
                                <p   className='checked_here' style={{opacity:0}}>
                                </p>
                                <p style={{fontSize:'12px', minWidth:'4%',color: "#000", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',paddingLeft:'-20px',marginLeft:'-15px',marginRight:'15px' }} >{page > 1 ? (page - 1) * 15 + i + 1 : i+1}</p>
                                <p style={{fontSize:'10px !important', minWidth: '5%',maxWidth:'5%', wordBreak: 'break-word', marginRight: '0.5%',textTransform:'uppercase', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-10px' }}><Avatar  sx={{  width: 18, height: 18,fontSize:11,textTransform:'uppercase' }} alt={d?.name} src={`${base_url}${d?.photo}`} /></p>
                                <Tooltip  title={d?.employee_id}><p onClick={()=>navigate(`/fms/fms_employee/${d._id}`)} style={{fontSize:'12px', minWidth: '8%',maxWidth:'8%', color: "#0594f2", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',fontSize:'13px',textOverflow:'ellipsis',marginLeft:'0px',marginRight:'0.5%' }}>{d?.employee_id == '' ? 'Not Added' : d?.employee_id}</p></Tooltip>
                                <Tooltip  title={d?.name}><p style={{fontSize:'12px', minWidth: '10%',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginRight:'0.5%'}}>{d?.name}</p></Tooltip>
                                <Tooltip  title={d?.mobile_no}><p style={{fontSize:'12px', minWidth: '11.9%' ,maxWidth:'11.9%',marginLeft:'10px', wordBreak: 'break-word',marginLeft:'2px',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{d?.mobile_no}</p></Tooltip>
                                <Tooltip  title={d?.designation?.designation_name}><p style={{fontSize:'12px', minWidth: '10%',textAlign:'start',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-25px' }}>{d?.designation?.designation_name}</p></Tooltip>
                                <Tooltip  title={d?.department_branch_were_deployed?.site_name}><p style={{fontSize:'12px', minWidth: '11.5%',maxWidth:'11.5%',paddingRight:'10px', wordBreak: 'break-word', marginRight: '1%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px',marginRight: '1%'  }}>{d?.department_branch_were_deployed?.site_name}</p></Tooltip>
                                {/* <Tooltip  title={d?.gender}><p style={{fontSize:'12px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'10px' }}>{d?.gender}</p></Tooltip> */}
                                {/* <Tooltip  title={d.is_rejected ? 'Rejected' : 'Approved'}><p style={{fontSize:'11px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'10px' }}>{d.is_rejected ?  <Tag style={{fontSize:'9px'}} color="red">Rejected</Tag> : <Tag style={{fontSize:'9px'}} color="green">Approved</Tag>}</p></Tooltip> */}
                                <Tooltip  title={d.is_rejected ? 'Rejected' : 'Approved'}>
                                    <p style={{fontSize:'11px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'10px' }}>
                                     {d?.terminated  ? <Tag style={{fontSize:'9px'}} color={"red"}>{ 'Terminated'}</Tag> 
                                       :
                                     <>
                                       {d.is_rejected ? 
                                         <Tag style={{fontSize:'9px'}} color="red">Rejected</Tag> 
                                         :
                                         <Tag style={{fontSize:'9px'}} color={d?.is_approved ? "green" : "blue"}>{d?.is_approved ? 'Approved' : 'Pending'}</Tag>}  
                                    </>}
                                        </p></Tooltip>
                            <p style={{fontSize:'12px', minWidth: '8%', marginRight: '0.5%',fontWeight:'800' }}><span style={{fontSize:'10px'}}>{moment(d?.date_of_joining).format('ll')}</span></p>
                            <p style={{ minWidth: '12%', marginRight: '0.5%',fontWeight:'800',fontSize:'10px !important',}}><span style={{fontSize:'10px'}}>{(d?.createdAt !== null && d?.createdAt !== '' && d?.createdAt !== undefined) ? moment(d?.createdAt).format('lll') : 'Not Added'}</span> </p>
                            {/* <p style={{ minWidth: '12%', marginRight: '0.5%',fontWeight:'800',fontSize:'10px !important',}}><span style={{fontSize:'10px'}}>{moment(d?.createdAt).format('lll')}</span> </p> */}
                            {!roles.includes('fms_data_view') &&
                                
                                <p style={{ width: '8%', display: 'flex',alignItems:'center',paddingLeft:'0px',marginLeft:'0px',marginLeft:'0%'}}>
                                    <Tooltip title='Exit Form'>
                                    <span>
                                    <MdExitToApp onClick={()=>navigate(`/fms/exit_employee_step`,{state:d})} size={14} style={{ marginRight:'8px' }}  />
                                    </span>
                                    </Tooltip>
                                    <Tooltip title='Edit'>
                                    <span>
                                    <BiEditAlt onClick={()=>navigate(`/fms/epi_form/edit/${d._id}`,{data_send:d})} size={14} style={{ marginRight:'8px' }}  />
                                    </span>
                                    </Tooltip>
                                    <Tooltip title='Form (F)'>
                                    <span>
                                    <FaWpforms onClick={()=>{d?.formf?.length == 0 ? navigate(`/fms/form_f_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/form_f_form/update/${d._id}`,{state:{datas:d}})}} size={14} style={{ marginRight:'8px' }}  />
                                    </span>
                                    </Tooltip>
                                    <Tooltip title='Statement Form'>
                                    <span>
                                    <RiNewspaperLine onClick={()=>{d?.formf?.length == 0 ? navigate(`/fms/statement_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/statement_form/update/${d._id}`,{state:{datas:d}})}} size={14}   />
                                    </span>
                                    </Tooltip>
                                    {(roles.includes('admin')) &&
                                    <Tooltip title='Delete Record'>
                                    <span>
                                    <MdDeleteOutline onClick={()=>deleterecord(d?._id)} size={18}   style={{marginLeft:'6px'}} />
                                    </span>
                                    </Tooltip>}
                                </p>}
                            </div>
                        ))}

                    <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
                        {pagination.total > 15 &&
                            <Pagination current={page} size="small" total={pagination.total} onChange={(v) => setpagefuun(v)}  pageSize={15} />
                        }
                    </div>

                    </div> 
                    :
                    <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                        <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
                        <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any clients created yet"}</h4>
                        <h4 style={{ fontSize: '12px', fontWeight: '500', marginTop: '2px', textAlign: 'center',opacity:'0.8' }}>{"To see clients details click on the add button and create a new client form and submit"}</h4>
                    </div>}
               </div>  
            </div>


        </FmsDashboardMainRoot>
    )
}


export default FmsEmployeeOverallList