import React, { useState, useEffect } from 'react'
import { IoIosArrowBack } from 'react-icons/io'
import { main_bg } from '../../constants/Constants1'
import { useParams, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { authHeader } from '../../../helpers/usertoken/UserToken'
import { Drawer, Row, Col, Button, message, Modal, Upload } from 'antd'
import { BsFillPlusCircleFill } from 'react-icons/bs'
import { IoClose } from 'react-icons/io5'
import file from '../../../images/file.png'
import moment from 'moment'
import { FiDownload } from 'react-icons/fi'
import { MdAutoDelete } from 'react-icons/md'
import fileDownload from "js-file-download";
import { base_url } from '../../../App'
import { FaFileArchive } from 'react-icons/fa'
import { data_not_found } from '../../../helpers/Constants'

function Attachmentsui() {


    const { Dragger } = Upload;

    const { type, id } = useParams()

    const navigate = useNavigate()

    const [data, setdata] = useState([])
    const [single_data, setsingle_data] = useState({})
    const [visible, setvisible] = useState(false)
    const [visible1, setvisible1] = useState(false)

    const [selectedAttachment, setselectedAttachment] = useState()




    useEffect(() => {
        getdata()
    }, [])


    function getdata() {
        if (type === 'Contact') {
            axios.get(`activity/get-attachement?type=${type}&type_id=${id}`, authHeader).then((res) => {
                setdata(res.data.datas)
            })
        } else if (type === 'Lead') {
            axios.get(`activity/get-attachement?type=${type}&type_id=${id}`, authHeader).then((res) => {
                setdata(res.data.datas)
            })
        } else if (type === 'Deal') {
            axios.get(`activity/get-attachement?type=${type}&type_id=${id}`, authHeader).then((res) => {
                setdata(res.data.datas)
            })
        }
    }

    function adddata(v) {
        console.log('value here are na', v,type)
        if (type === 'Contact') {
            authHeader['Content-Type'] = 'multiplart/form-data'
            let fd = new FormData()
            fd.append('attachment_type', type)
            fd.append('type_id', id)
            fd.append('file', v.fileList[0].originFileObj)
            axios.post('activity/create-attachment', fd, authHeader)
            .then((res) => {
                console.log('res.data',res.data)
                getdata()
                setvisible(false)
            }).catch((err)=>{
                console.log('err data here',err)
            })
        } else if (type === 'Lead') {
            authHeader['Content-Type'] = 'multiplart/form-data'
            let fd = new FormData()
            fd.append('attachment_type', type)
            fd.append('type_id', id)
            fd.append('file', v.fileList[v.fileList.length - 1].originFileObj)
            axios.post('activity/create-attachment', fd, authHeader).then((res) => {
                getdata()
                setvisible(false)
            })
        } else if (type === 'Deal') {
            authHeader['Content-Type'] = 'multiplart/form-data'
            let fd = new FormData()
            fd.append('attachment_type', type)
            fd.append('type_id', id)
            fd.append('file', v.fileList[v.fileList.length - 1].originFileObj)
            axios.post('activity/create-attachment', fd, authHeader).then((res) => {
                getdata()
                setvisible(false)
            })
        }
    }

    function deletedata() {
        if (type === 'Contact') {
            axios.delete(`activity/delete-attachment/${selectedAttachment.id}`, authHeader).then((res) => {
                getdata()
                message.success('Attachment deleted successfully')
                setselectedAttachment()
                setvisible1(false)
            }).catch((err) => {
                message.error('Opps something went wrong!')
            })
        } else if (type === 'Lead') {
            axios.delete(`activity/delete-attachment/${selectedAttachment.id}`, authHeader).then((res) => {
                getdata()
                message.success('Attachment deleted successfully')
                setselectedAttachment()
                setvisible1(false)
            }).catch((err) => {
                message.error('Opps something went wrong!')
            })
        } else if (type === 'Deal') {
            axios.delete(`activity/delete-attachment/${selectedAttachment.id}`, authHeader).then((res) => {
                getdata()
                message.success('Attachment deleted successfully')
                setselectedAttachment()
                setvisible1(false)
            }).catch((err) => {
                message.error('Opps something went wrong!')
            })
        }
    }

    const handleDownload = (url, filename) => {
        axios.get(url, { responseType: 'blob', })
            .then((res) => {
                message.success(`${filename} downloaded successfully`)
                fileDownload(res.data, filename)
            })
    }

    return (
        <div>

            <Modal visible={visible1} footer={false} onCancel={() => setvisible1(false)} style={{ maxWidth: '320px' }} centered>
                <div style={{ maxWidth: '100%', }}>
                    <h6 style={{ fontWeight: '900', fontSize: '15px' }}>Are You Sure </h6>
                    <h6 style={{ fontWeight: '400', fontSize: '13px' }}>are you sure want to delete the select attachment with attachment name <strong>{selectedAttachment?.file}</strong> </h6>
                    <h6 style={{ fontSize: '13px', width: '100%', wordBreak: 'break-word', borderRadius: '4px' }}>{single_data.summary}</h6>


                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                        <Button style={{ width: '100%', background: '#fff', color: 'black', marginRight: '2%' }} onClick={() => { setselectedAttachment(); setvisible1(false) }}>Cancel</Button>
                        <Button style={{ width: '100%', background: `${main_bg}`, color: 'white', marginLeft: '2%', borderColor: 'transparent' }} onClick={() => { deletedata() }}>I'm Sure</Button>
                    </div>
                </div>
            </Modal>


            <Drawer placement={'bottom'} visible={visible} height={'40%'} width={'85%'} closable={false} maskClosable={false} bodyStyle={{ background: '#fff', maxHeight: '280px', margin: '5%', padding: '0px', borderTopLeftRadius: '20px', borderTopRightRadius: '20px', overflow: 'hidden' }}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {/* <h6 style={{ width: '80px', height: '6px', borderRadius: '20px', opacity: 0.6, background: '#d9d9d9', display: 'flex', justifyContent: 'center', justifySelf: 'center', alignSelf: 'center' }}></h6> */}
                </div>
                <div style={{ alignItems: 'center', margin: '3px 40px', marginBottom: '20px' }}>
                    <h5 style={{ fontWeight: '800', textAlign: 'left', marginTop: '5px' }}>Add Attachment</h5>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', background: '#f0f0f0', borderRadius: 4, padding: 5, position: 'absolute', left: '20px', top: '28px' }} onClick={() => setvisible(false)}>
                    <IoClose size={12} color='black' />
                </div>




                <Dragger onChange={adddata}
                    beforeUpload={() => false}
                    showUploadList={false}
                    style={{ width: '100%', maxHeight: '200px', background: '#f7f9ff', border: '1px dotted #f0f0f0' }}
                >
                    <p className="ant-upload-drag-icon">
                        <FaFileArchive size={50} style={{ opacity: 0.5 }} />
                    </p>
                    <p className="ant-upload-text" style={{ fontSize: '14px', fontWeight: '700' }}>Click or drag file to upload</p>
                </Dragger>
            </Drawer>

            <div style={{ display: 'flex', alignItems: 'center', backgroundColor: `${main_bg}`, padding: '10px', height: '50px', position: 'sticky', top: '0px',zIndex:100 }}>
                <IoIosArrowBack size={24} color="#fff" onClick={() => navigate(-1)} />
                <h6 style={{ fontSize: '18px', fontWeight: '500', color: '#fff', marginBottom: '0px', marginLeft: '20px' }}>Attachments</h6>
            </div>


            {data?.length > 0 ?
                <div style={{ width: '96%', margin: 'auto' }}>
                    <h6 style={{ padding: '10px', fontWeight: '800', fontSize: '13px' }}> ({data.length}) Attachments Added</h6>
                    <Row>
                        {data.map((d) => (
                            <Col span={24} key={d?.id} onClick={() => { setsingle_data(d) }}>
                                <div style={{ display: 'flex', flexDirection: 'row', margin: '5px 0px' }}>
                                    <img src={file} alt="1" style={{ width: '30px', height: '30px', objectFit: 'contain' }} />
                                    <div style={{ marginLeft: '15px' }}>
                                        <h6 style={{ fontWeight: '400', fontSize: '13px', }}>{d?.file?.slice(14, 35)}.{d?.file?.split('.')[d?.file?.split('.')?.length - 1]}</h6>
                                        <h6 style={{ fontSize: '11px', marginTop: '-3px' }}>{moment(d.createdAt).format('LLL')}</h6>
                                    </div>
                                    <div style={{ position: 'absolute', right: '10px', opacity: 0.8 }}>
                                        <MdAutoDelete size={20} style={{ marginRight: '20px', opacity: 0.8 }} onClick={() => { setselectedAttachment(d); setvisible1(true) }} />
                                        <FiDownload size={20} onClick={() => handleDownload(`${base_url}${d?.file}`, d?.file.split('/')[d?.file.split('/').length - 1])} />
                                    </div>
                                </div>
                            </Col>
                        ))}

                    </Row>
                </div>
                :
                <div style={{ display: 'grid', alignItems: 'center', justifyItems: 'center', justifyContent: 'center', marginTop: '30%' }}>
                    <img src={data_not_found} alt="1" style={{ width: '180px', height: '180px',marginLeft:'10px', objectFit: 'contain' }} />
                    <h5 style={{ fontSize: '15px', marginTop: '-20px', fontWeight: '800' }}>No attachments added yet</h5>
                    <h6 style={{fontSize:'13px',textAlign:'center',width:'85%'}}>Please click on the create icon and select file to upload them </h6>
                </div>
            }

            <div style={{ position: 'absolute', bottom: 20, display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                <BsFillPlusCircleFill size={44} onClick={() => setvisible(true)} />
            </div>
        </div>
    )
}

export default Attachmentsui