import React,{useState} from 'react'
import { Input } from 'antd';
import {validateInput} from '../Validators/Validators'
import {AiOutlineInfoCircle} from 'react-icons/ai'
import { useSelector } from 'react-redux';

function InputField({type,placeholder,error_obj,validators,onChange,name,nameerr,value,priority,size}) {
    const [error, setError] = useState({});
   
    const handleChange = (event) => {
        const {value} = event.target;
        setError(validateInput(validators, value));
        onChange({value:value,key:name,name_err:nameerr});

    };

    const theme_color = useSelector(state=>state.Theme.theme_color)



    return (
        <div>
            <Input size={size === null || size=== undefined ? "medium": size} value={value}    style={{borderLeft:priority === 'high' && `4px solid ${theme_color}`,borderRadius:'3px'}} type={type} placeholder={placeholder} onChange={handleChange}  />
            <div style={{display:'flex',alignItems:'center',marginTop:'3px'}}>
               {(error?.error || error_obj?.error) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
              <p style={{marginBottom:'0px',fontSize:'12px',color:'red',paddingLeft:'3px'}}>{error?.message || error_obj?.message }</p>
            </div> 
        </div>
    )
}

export default InputField
