import React,{useState,useEffect} from 'react';

import TextInput from '../../components/ItComponents/TextInput';
import {  Itheadings, ItSmallText, ItSmallText1 } from '../../helpers/constants/ConstantsText';
import ITMain from './ITMain';
import { gray_dark_1, theme_color } from '../../helpers/constants/ConstantColor';
import PrimaryButton, { PrimaryButtonAdd } from '../../components/buttons/PrimaryButton';


import {IoClose} from 'react-icons/io5'
import { SiBrandfolder } from 'react-icons/si';

import { Modal,Select} from 'antd';
import IconButton from '@mui/material/IconButton';
import Tooltip from "@material-ui/core/Tooltip";
import toast, { Toaster } from 'react-hot-toast'
import Dragger from "antd/lib/upload/Dragger";


import {
    createMuiTheme,
  } from "@material-ui/core/styles";
import axios from 'axios';
import { MdOutlineDelete } from 'react-icons/md';
import { BiEditAlt } from 'react-icons/bi';
import './Maincss.css';
import { base_url } from '../../App';



const { Option } = Select;

function Brand(){

    const AuthHeader = {headers:{
        'Content-Type': 'multipart/form-data',
    }}
    

    const theme = createMuiTheme({
        overrides: {
            MuiTooltip: {
            tooltip: {
                // fontSize: "2em",
                color: "black",
                backgroundColor: {gray_dark_1}
            }
            }
        }
    });


    const [modal,setmodal] = useState(false)
    const [brand,setbrand] = useState({_id:null,brand_name:'',brand_image:null,related_to:''})

    const [brand_arr,setbrand_arr] = useState([])



    const related_arr = [
        'LAPTOP',
        'MOUSE',
        'KEYBOARD',
        'CABLES',
        'HARDDISK'
    ]

    useEffect(()=>{
        getdata()
    },[])  
    
    function getdata(){
        axios.get('it/brand/get',brand)
        .then((res)=>{
            setbrand_arr(res.data.data)
        }).catch((err)=>{
            // console.log('err',err)
        })
    }

    function add_brand(){
        var fd = new FormData()
        fd.append('brand_name',brand.brand_name)
        fd.append('related_to',brand.related_to)
        brand?.brand_image?.originFileObj !== undefined && fd.append('image',brand.brand_image.originFileObj)    
        axios.post('it/brand/create',fd,AuthHeader)
        .then((res)=>{
            toast.success(res.data.message)
            setmodal(false)
            getdata()
        }).catch((err)=>{
        })
    }

    function update_brand(){
        var fd = new FormData()
        fd.append('brand_name',brand.brand_name)
        fd.append('related_to',brand.related_to)
        fd.append('method','_PUT')
        brand?.brand_image?.originFileObj !== undefined && fd.append('image',brand.brand_image.originFileObj)

        axios.post(`it/brand/update/${brand._id}`,fd,AuthHeader)
        .then((res)=>{
            toast.success(res.data.message)
            setmodal(false)
            getdata()
        }).catch((err)=>{
        })
    }

    function delete_brand(v){
        axios.delete(`it/brand/delete/${v._id}`)
        .then((res)=>{
            toast.success(res.data.message)
            getdata()
        }).catch((err)=>{
            // console.log('err here',err.response.data)
        })
    }

    const handleUpload = (v) => {
        setbrand({...brand,brand_image:v.fileList[0]})
    }



    return(
        <ITMain>
            <div style={{width:'95%'}}>
                <Toaster />

                <Modal visible={modal} footer={false} closable={false} width='270px'>
                    <div style={{width:'220px',position:'relative',height:'290px'}}>

                    <Tooltip title="Close" >
                            <IconButton style={{padding:'2px',marginTop:'-5px',position:'absolute',right:-10,top:-20}}>
                            <IoClose  onClick={()=>setmodal(!modal)} style={{ cursor: 'pointer',color:gray_dark_1, }} size={16} />
                            </IconButton>
                        </Tooltip>

                        <ItSmallText val={'Related To'} marginfun={'10px 0px 5px 0px'} />
                            <Select value={brand.related_to} onChange={(v)=>setbrand({...brand,related_to:v})} style={{width:'100%',borderLeft:`3px solid ${theme_color}`,borderRadius:'2px'}} >
                                {related_arr.map((r)=>(
                                    <Option key={r} value={r}>{r}</Option>
                                ))}
                            </Select>

                         
                        {/* <TextInput val={brand.brand_name} onchange={(e)=>setbrand({...brand,brand_name:e.target.value})} /> */}

                        <ItSmallText val={'Brand Name'} marginfun={'10px 0px 5px 0px'} />
                        <TextInput val={brand.brand_name} onchange={(e)=>setbrand({...brand,brand_name:e.target.value})} />

                        <ItSmallText val={'Brand LOGO'} marginfun={'10px 0px 5px 0px'} />
                        {brand.brand_image === undefined || brand.brand_image === null || brand.brand_image === "null"   ?
                        <Dragger style={{ marginTop: '10px',minHeight:'80px',maxHeight:'100px' }}
                            onChange={handleUpload}
                            beforeUpload={() => false}
                            showUploadList={false}>
                            
                            <div style={{display:'flex',height:'100px',alignItems:'center',flexDirection:'column'}}>
                            <img src={'https://cdn-icons-png.flaticon.com/512/2504/2504768.png'} style={{ width: '30px', objectFit: 'contain',alignItems:'center',display:'flex',justifyItems:'center',justifySelf:'center',alignSelf:'center' }} />
                            <p className="ant-upload-text" style={{fontWeight:'600',fontSize:'8px',marginTop:'10px'}}>Click or drag file to this area to upload</p>
                            <p className="ant-upload-hint" style={{fontSize:'6px'}}>
                                Support for a single file upload. Strictly used from uploading brand image
                            </p>
                            </div>
                        </Dragger>
                        :
                        <div>
                            {brand?.brand_image?.name?.length !== undefined && brand?.brand_image?.name?.length !== null ?
                            <div style={{ display: 'flex', alignItems: 'center', border: '1px solid lightgray', padding: '2px 10px', position: 'relative', marginTop: '10px' }}>
                                <img src={'https://cdn-icons-png.flaticon.com/512/2504/2504768.png'} style={{ width: '20px', objectFit: 'contain' }} />
                                <div style={{ marginLeft: '10px',marginTop:'5px' }}>
                                    <h6 style={{ fontSize: '8px', fontWeight: '600', marginBottom: '2px' }}>{brand?.brand_image?.name?.slice(0,25)}</h6>
                                    <h6 style={{ fontSize: '6px', color: 'gray' }}>{brand.brand_image?.size} bytes</h6>
                                </div>
                                <MdOutlineDelete size={15} style={{ position: 'absolute', right: '10px' }} onClick={()=>setbrand({...brand,brand_image:null})} />
                            </div>
                            :
                            <div style={{position:'relative',}}>
                                <IoClose  onClick={()=>setbrand({...brand,brand_image:null})} style={{ cursor: 'pointer',color:gray_dark_1,position:'absolute',right:0 }} size={16} />
                                <img src={`${base_url}${brand?.brand_image}`} style={{ width: '100px',height:'100px', objectFit: 'stretch' }} />
                            </div>  }  
                        </div>}  
                        <div onClick={brand._id === null ? add_brand : update_brand} style={{marginTop:'15px'}}>
                        <PrimaryButton btn_name={brand._id === null ? 'ADD BRAND' : "UPDATE BRAND"}  />
                        </div> 
                    </div>
                </Modal>
                <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',marginTop:'-10px',borderBottom:`1px solid #f2f2f2`}}>
                <Itheadings val={'Gadjet Brands'} />
                  <div onClick={()=>(setbrand({...brand,_id:null}),setmodal(true))}>
                 <PrimaryButtonAdd btn_name={'Add Brand'}  />
                 </div>
                </div>

            <div className='row'>
            {brand_arr.map((p)=>(
              <div className='col-3'>
              <div className='card_processor'  key={p._id} style={{display:'flex',alignItems:'center',margin:'15px 0px',cursor:'pointer',borderLeft:'4px solid #4079AC',paddingLeft:'10px',width:'100%'}}>
                <SiBrandfolder style={{backgroundColor:'#4079AC',color:'#fff',padding:'4px',borderRadius:'2px'}} size={20} />
                <ItSmallText1 val={`${p.brand_name} [ ${p.related_to} ]`} marginfun={'0px 0px 0px 10px'} />
                 
                <div style={{paddingLeft:'60px',display:'flex'}} className="delete_visible">
                <Tooltip title="Delete" >
                    <IconButton style={{marginTop:'-5px'}} onClick={()=>delete_brand(p)}>
                <MdOutlineDelete  size={16} style={{color:'#000'}} />
                </IconButton>
                </Tooltip>
                <Tooltip title="Edit" >
                    <IconButton style={{marginTop:'-5px',}} onClick={()=>(setbrand({_id:p?._id,brand_name:p.brand_name,related_to:p.related_to,brand_image:p.brand_image}),setmodal(true))}>
                <BiEditAlt  size={16} style={{color:'#000'}} />
                </IconButton>
                </Tooltip>
                </div>
              </div>
              </div>
            ))}
            </div> 

            </div>
        </ITMain>
    )
}


export default Brand