import React, { useState, useEffect } from 'react'
import { Input, Tag, Alert, Tooltip } from 'antd'
import Checkbox from 'antd/lib/checkbox/Checkbox'
import PrimaryButton, { SecondaryButton } from '../../../components/buttons/PrimaryButton'
// import InputField from '../../../components/inputcomponents/InputField'
import './EditProfile.css'
import axios from 'axios'
import { authHeader } from '../../../helpers/usertoken/UserToken'
import { useSelector } from 'react-redux'
// import Inputerror from '../../../components/errors/Inputerror'

function EditProfile() {

    const [user_data, setuser_data] = useState({})
    const [user_data_temp, setuser_data_temp] = useState({})
    const [message, setmessage] = useState('')
    const [error, seterror] = useState('')

    const userId = useSelector(state => state.Auth.id)

    useEffect(() => {
        getuserdata()
    }, [])

    function handleChange(e) {
        if (!user_data?.name) {
            seterror('User name field is required')
        } else if (user_data?.name?.length < 3) {
            seterror('User name should be atleast 3 characters')
        }
        setuser_data({ ...user_data, [e.target.name]: e.target.value })
    }

    function getuserdata() {
        axios.get(`user/get/${userId}`, authHeader)
            .then((res) => {
                console.log('user profile', res.data)
                setuser_data(res.data.datas[0])
                setuser_data_temp(res.data.datas[0])
            }).catch((err) => {
                console.log('user error', err)
            })
    }

    function updateprofile() {

        if (!user_data.name) {
            scrollToTop()
            seterror('This field is required')
        } else if (user_data.name.length < 3) {
            scrollToTop()
            seterror('Name should be atleast 3 character')
        }
        else {
            seterror('')
            let departement_id_arr = []

            user_data?.department_id?.forEach(d => {
                departement_id_arr.push(d.id)
            })


            let send_data = {
                'name': user_data.name,
                'employee_id': user_data.employee_id,
                'department_id': departement_id_arr,
                'designation_id': user_data.designation_id.id,
                'official_phone': user_data.official_phone,
                'official_email': user_data.official_email,
                'dob': user_data.dob,
                'image': ''
            }
            axios.put(`user/update/${userId}`, send_data, authHeader)
                .then((res) => {
                    setmessage('Profile updated successfully')
                    getuserdata()
                    scrollToTop()
                    // console.log('res data',res.data)
                }).catch((err) => {
                    // console.log('catch err',err)
                })
        }

    }

    function cancelupdateprofile() {
        setuser_data(user_data_temp)
        scrollToTop()
        setmessage('Account Restored successfully')
    }

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    return (
        <div style={{ width: '70%', margin: 'auto', marginTop: '20px', marginBottom: '20px', paddingTop: '50px' }}>

            <Alert message={message} style={{ border: 'none', padding: '20px 20px' }} type="info" showIcon closable />
            <div style={{ display: 'flex', borderBottom: '1px solid #efefef', padding: '15px 0px' }}>
                <div>
                    <h5 style={{ fontWeight: '700' }}>Public avatar</h5>
                    <p style={{ color: '#666', fontSize: '15px' }}>You can upload your avatar here to change it</p>
                </div>

                <div style={{ display: 'flex', paddingLeft: '40px' }}>
                    <img src="https://secure.gravatar.com/avatar/e36bf678dc8cb3b89f320c40bbb30fed?s=180&d=identicon" alt="1" style={{ width: '90px', height: '90px', borderRadius: '60%', border: '1px solid #e3e3e3' }} />
                    <div style={{ paddingLeft: '20px' }}>
                        <h6 style={{ fontWeight: '600' }}>Upload new avatar</h6>
                        <div style={{ position: 'relative' }}>
                            <input type="file" className='file_select' style={{ background: 'white' }} />
                            <p className='edit_profile_picture_btn'>Choose file...</p>
                        </div>
                        <p style={{ color: '#666', fontSize: '13px', marginTop: '10px' }}>The maximum file size allowed is 200KB.</p>
                    </div>
                </div>

            </div>


            <div style={{ display: 'flex', borderBottom: '1px solid #efefef', padding: '15px 0px' }}>
                <div>
                    <h5 style={{ fontWeight: '700' }}>Main settings</h5>
                    <p style={{ color: '#666', fontSize: '15px' }}>This information will appear on your profile</p>
                </div>

                <div style={{ paddingLeft: '60px', display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ width: '420px' }}>
                        <h6 className='heading_profile'>Full name <span style={{ color: 'red', fontSize: '10px', paddingLeft: '10px' }}>{error}</span> </h6>
                        <Input type="text" value={user_data.name} name="name" onChange={handleChange} />
                        <p style={{ color: '#666', fontSize: '13px' }}>Enter your name, so people you know can recognize you</p>
                        {/* <Inputerror message={error} /> */}
                        <h6 className='heading_profile'>Email</h6>
                        <Input disabled type="text"  value={user_data.official_email} style={{ color: 'black', background: 'white' }} />
                        <p style={{ color: '#666', fontSize: '13px', paddingTop: '4px' }}>We also use email for avatar detection if no avatar is uploaded</p>

                        <h6 className='heading_profile'>Phone</h6>
                        <Input type="text" value={user_data.official_phone} style={{ color: 'black', background: 'white' }} />
                        <p style={{ color: '#666', fontSize: '13px', paddingTop: '4px' }}>Offical Phone number provided by the company</p>
                        <h6 className='heading_profile'>Job title</h6>
                        <Input type="text" value={user_data?.designation_id?.designation_name} style={{ color: 'black', background: 'white' }} />
                        <h6 className='heading_profile' style={{ marginTop: '10px' }}>Organization</h6>
                        <Input type="text" value={'fidelituscorp'} style={{ color: 'black', background: 'white' }} />
                        <p style={{ color: '#666', fontSize: '13px', paddingTop: '4px' }}>Who you represent or work for</p>
                        <h6 className='heading_profile' style={{ marginTop: '10px' }}>Departements Assigned</h6>
                        {user_data?.department_id?.map((d) => (
                            <Tag key={d.id} style={{ cursor: 'pointer' }}>{d.department_name}</Tag>
                        ))}
                        <h6 className='heading_profile' style={{ marginTop: '10px' }}>Private profile</h6>
                        <Tooltip title="Option is default enabled you're not able to modifiy default setting">
                            <Checkbox checked>
                                <p style={{ fontSize: '12px', paddingTop: '4px' }}>Don't display activity-related personal information on your profile</p>
                            </Checkbox>
                        </Tooltip>
                    </div>
                    <div style={{ paddingLeft: '30px' }}>
                        <h6 className='heading_profile'>User ID</h6>
                        <Input type="text" disabled style={{ background: '#fafafa', color: 'grey', borderRadius: '4px', textTransform: 'uppercase' }} value={user_data.employee_id} />
                    </div>



                </div>

            </div>


            <div style={{ display: 'flex', marginTop: '0px', paddingTop: '10px', paddingBottom: '150px' }}>
                <div style={{ display: 'flex', position: 'relative', }} onClick={() => updateprofile()}>
                    <PrimaryButton btn_name="Update profile settings" />
                </div>
                <div style={{ display: 'flex', position: 'relative', width: '200px' }} onClick={() => cancelupdateprofile()}>
                    <SecondaryButton btn_name="Cancel" />
                </div>
            </div>


        </div>
    )
}

export default EditProfile
