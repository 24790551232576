import React from 'react'
import EditProfileWeb from './web/EditProfile'
import EditProfileAndroid from './android/EditProfile'
import { isMobile } from '../../helpers/mobileresize/Mobileresize'

const index1=()=>{
    if(isMobile){
        return (
            <div>
                <EditProfileAndroid />
            </div>
        )
    }else{
        return (
            <div>
                <EditProfileWeb />
            </div>
        )
    }
}

export default index1