import React, { useState, useEffect } from 'react';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import axios from 'axios'
import { authHeader } from '../../../helpers/usertoken/UserToken';
import { FiEdit } from 'react-icons/fi'
import { Drawer, Checkbox, message } from 'antd'
import { useNavigate } from 'react-router-dom';
import { Tag, Modal, Select, Button } from 'antd';
import { useSelector } from 'react-redux';
import { Tabs, Input } from 'antd';
import { IoClose, IoMailOutline, IoMenu } from 'react-icons/io5'
import { Pagination } from 'antd'
import { FaUserShield } from 'react-icons/fa'
import { BiPlus, BiMinus } from 'react-icons/bi'

import { FaIdCardAlt } from 'react-icons/fa'
import { FiUser } from 'react-icons/fi'
import { FcDepartment } from 'react-icons/fc'
import { BiHighlight } from 'react-icons/bi'
import { VscFolderActive } from 'react-icons/vsc'
import { MdPendingActions } from 'react-icons/md'
import { main_bg } from '../../../components/constants/Constants1';
import RootNavbar from '../../mobileNavbar/RootNavbar';
import { IoFlask } from 'react-icons/io5';

const { TabPane } = Tabs;
const { Option } = Select;
function OnboardEmployee() {

  const [roles_arr, setroles_arr] = useState([])
  const [employee, setemployee] = useState([])
  const [inactive_employee, setinactive_employee] = useState([])

  const [singleemployee, setsingleemployee] = useState({})
  const [singleemployeeRoles, setsingleemployeeRoles] = useState([])
  const [tempsingleemployeeRoles, settempsingleemployeeRoles] = useState([])

  const [inputroles, setinputroles] = useState([])
  const [selectedroles, setselectedroles] = useState([])

  const [visible, setvisible] = useState(false)
  const [visible1, setvisible1] = useState(false)
  const [visible2, setvisible2] = useState(false)
  const [visible3, setvisible3] = useState(false)

  const [page, setpage] = useState('1')
  const [pagination, setpagination] = useState({})

  const [add_btn, setadd_btn] = useState(false)



  const [tab, settab] = useState('0')

  const [search_employee, setsearch_employee] = useState({ employee_id: '', })
  const [tempsearch_employee, settempsearch_employee] = useState({ employee_id: '', })


  const name = useSelector(state => state.Auth.name)


  const navigate = useNavigate()

  const colors = ['magenta', 'red', 'volcano', 'orange', 'gold', 'lime', 'green', 'cyan', 'blue', 'geekblue']



  useEffect(() => {
    getOnboardedEmployee()
    getRoles()
  }, [page, selectedroles])


  function getRoles() {
    axios.get('role/get', authHeader)
      .then((res) => {
        const role_arr_created = []
        res.data.datas.forEach(d => {
          let data = {}
          data['label'] = d.role_name
          data['value'] = d.display_name
          data['id'] = d.id
          role_arr_created.push(data)
        })
        setroles_arr(role_arr_created)

      })
  }

  function getOnboardedEmployee() {
    axios.get(`user/get/${page}`, authHeader)
      .then((res) => {
        setpagination(res.data.pagination)
        setemployee(res.data.datas.filter(d => d.isActive === true))
        setinactive_employee(res.data.datas.filter(d => d.isActive === false))
      })
  }

  function UpdateOnboardedData(v) {
    navigate(`/on_board_user_update/${v}`)
  }

  function updateactivestatus(v, v1) {


    settab(v1)
    setsingleemployee(v)
    if (v1 === '3') {
      v.user_roles[0]?.roles.map((r) => {
        let data = {}
        data['label'] = r.role_name
        data['value'] = r.display_name
        data['id'] = r._id
      })
      setsingleemployeeRoles(v.user_roles[0]?.roles)
      settempsingleemployeeRoles(v)
      setvisible1(true)
      getOnboardedEmployee()
    } else {
      // console.log('anna me',v1)
      setvisible(true)
    }
  }

  function updateuseractivestatus(v) {

    let departement_id_arr = []
    let roles_id_arr = []


    singleemployee?.department_id?.forEach(d => {
      departement_id_arr.push(d._id)
    })

    singleemployee?.user_roles[0]?.roles?.forEach(r => {
      roles_id_arr.push(r._id)
    })

    let send_data = {
      'name': singleemployee.name,
      'employee_id': singleemployee.employee_id,
      'department_id': departement_id_arr,
      'designation_id': singleemployee.designation_id[0]._id,
      'official_phone': singleemployee.official_phone,
      'official_email': singleemployee.official_email,
      'dob': singleemployee.dob,
      'roles': roles_id_arr,
      'image': '',
      'isActive': tab === '0' ? false : true
    }

    axios.put(`user/update/${singleemployee._id}`, send_data, authHeader)
      .then((res) => {
        if (res.data.datas) {
          getOnboardedEmployee()
          message.success('Updated successfully')
          setvisible(false)
          setsingleemployee({})
        }
      }).catch((err) => {
      })
  }

  function handleRole(v) {
    setinputroles(v)
  }

  function applyfilter() {
    if (search_employee.employee_id && search_employee.employee_id.toLowerCase().slice(0, 5) === 'fcpl_') {
      axios.get(`user/search?search=${search_employee.employee_id}&page=1`, authHeader)
        .then((res) => {
          setpagination(res.data.pagination)
          setemployee(res.data.datas.filter(d => d.isActive === true))
          setinactive_employee(res.data.datas.filter(d => d.isActive === false))
          setvisible2(false)
          settempsearch_employee({ ...tempsearch_employee, employee_id: '' })

        }).catch((err) => {
        })
    } else {
      message.error('Enter a valid Fcpl Id for filter')
    }
  }

  function resetfilter() {
    setsearch_employee({ ...search_employee, employee_id: '' })
    settempsearch_employee({ ...tempsearch_employee, employee_id: '' })
    getOnboardedEmployee()
    setvisible2(false)
  }

  function setpagefun(v) {
    setpage(v)
  }

  function AddRole() {
    setadd_btn(false)
    if (inputroles.length > 0) {
      setselectedroles([])
      let temp = []
      roles_arr.forEach((r) => {
        inputroles.forEach((i) => {
          if (i === r.id) {
            temp.push(r)
            console.log('r is', r)
          } else {

          }
        })
      })

      console.log('temp', temp)
      setselectedroles(temp)
    } else {
      message.error('select role for adding')
    }
  }


  function cancelselectedrole(d) {
    let selected_roles_Arr = selectedroles
    selectedroles.forEach((r, i) => {
      if (r.id === d.id) {
        let role = selected_roles_Arr.splice(i, 1)
        console.log('role', role, 'index', i, 'selected_roles_Arr', selected_roles_Arr)
      }
    })
    setselectedroles(selected_roles_Arr)

  }


  function removeRolesProvided(v) {
    console.log('v is ', v)
  }

  function updaterole() {
    let roles = []

    // tempsingleemployeeRoles.forEach((r)=>{
    //     roles.push(r._id)
    // })
    selectedroles.forEach((r) => {
      if (!roles.includes(r.id)) {
        roles.push(r.id)
      }
    })

    console.log('roles', roles, 'singleemployee', singleemployee)

    let send_data = {}
    send_data['user_id'] = singleemployee._id
    send_data['roles'] = roles

    axios.put(`user/assign-user-roles`, send_data, authHeader)
      .then((res) => {
        getOnboardedEmployee()
        setvisible1(false)

        console.log('res data', res.data)
      })
    console.log('iam updating my roles')
  }




  return (
    <div>
      <RootNavbar visible={visible3} func={() => setvisible3(false)} />
      <div style={{ display: 'flex', alignItems: 'center', minHeight:'45px',maxHeight:'45px', background: `${main_bg}`, position: 'sticky', top: '0px', zIndex: 100 }} onClick={() => setvisible3(true)}>
        <IoMenu size={30} style={{ marginRight: 5, color: 'white', padding: 5, borderRadius: 4 }} />
        <h6 style={{ fontSize: '15px', fontWeight: '600', marginBottom: '0px', color: '#fff' }}>Onboard Employee</h6>
      </div>
      <div style={{ width: '96%', margin: 'auto', marginTop: '0px', zIndex: 10 }}>

        <Drawer placement={'bottom'} visible={visible2} height={'200px'} width={'85%'} closable={false} maskClosable={false} bodyStyle={{ background: '#fff', margin: '5%', padding: '0px', borderTopLeftRadius: '20px', borderTopRightRadius: '20px', overflow: 'hidden' }}>

        
          <div style={{ alignItems: 'center', margin: '0px 0px', marginBottom: '20px' }}>
            <h5 style={{ fontWeight: '800',fontSize:'14px', textAlign: 'center' }}>Filter</h5>

          </div>
          <div style={{ display: 'flex', alignItems: 'center', background: '#f0f0f0', borderRadius: 4, padding: 5, position: 'absolute', left: '20px', top: '14px' }} onClick={() => setvisible2(false)}>
            <IoClose size={12} color='black' />
          </div>



          <h6 style={{ position: 'absolute', right: '20px', top: '15px',fontSize:'13px', fontWeight: '800', color: '#d9d9d9' }} onClick={resetfilter}>Reset</h6>


          <div style={{ margin: '10px 0px', paddingBottom: '10px', marginTop: '15px' }}>
            <div>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h6 style={{ fontWeight: '600', fontSize: '14px', opacity: 1 }}>Add FCPL_ID</h6>
              </div>

              {search_employee.employee_id ?
                <div style={{ display: 'flex', alignItems: 'center', background: '#f8f9fa', padding: '12px', borderRadius: '2px', maxWidth: '100%', position: 'relative' }}>
                  <h6 style={{ marginBottom: '0px', fontSize: '12px' }}>{search_employee.employee_id.slice(0, 20)}{search_employee.employee_id.length > 20 && '...'}</h6>
                  <IoClose style={{ marginLeft: '10px', cursor: 'pointer', position: 'absolute', right: '10px' }} onClick={() => setsearch_employee({ ...search_employee, employee_id: '' })} />
                </div>
                :
                <div style={{ display: 'flex', width: '100%', border: '1px solid black', height: '40px', borderRadius: '4px', borderColor: '#f5f5f5', overflow: 'hidden', borderRightColor: '#fff', backgroundColor: '#fafafa' }}>
                  <Input type={"text"} style={{ width: '100%', margin: 'auto', marginLeft: '0%', padding: '5px', border: 'none', background: '#fafafa', boxShadow: 'none' }} value={tempsearch_employee.employee_id} onChange={e => settempsearch_employee({ ...tempsearch_employee, employee_id: e.target.value })} />
                  <div style={{ marginTop: '-10px' }}>
                    <Button style={{ fontWeight: '800', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '28px 12px', background: `${main_bg}`, color: '#fff' }} onClick={() => setsearch_employee({ ...search_employee, employee_id: tempsearch_employee.employee_id })} >+</Button>
                    {/* <PrimaryButton btn_name ="+" style={{fontWeight:'800'}} padding='8px 12px' onClick={()=>setsearch_lead({...search_lead,search_text:tempsearch_lead.search_text})} /> */}
                  </div>
                </div>}
            </div>
          </div>




          <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
            {/* <div style={{display:'flex',justifyContent:'center',marginTop:'10px',marginRight:'10px'}} onClick={resetfilter}>
                        <Button size='large' style={{background:'#f5f5f7',border:'none',borderRadius:'5px',color:'black',fontWeight:'500',display:'flex',justifyContent:'center',justifyItems:'center'}}>Reset Filter</Button>
                        </div> */}
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', width: '100%' }} onClick={applyfilter}>
              <Button size='large' style={{ background: `${main_bg}`, border: 'none', borderRadius: '5px', color: 'white', fontWeight: '500', display: 'flex', justifyContent: 'center', justifyItems: 'center', width: '100%' }}>Apply Filter</Button>
            </div>
          </div>




        </Drawer>




        <Modal visible={visible1} onCancel={() => setvisible1(false)} onClose={() => setvisible1(false)} centered footer={false} style={{ maxWidth: '300px', minHeight: '400px', maxHeight: '400px', overflow: 'hidden' }}>
          <div style={{ width: '300px', }}>
            <h1 style={{ color: 'black', fontSize: '16px', fontWeight: '400', marginTop: '10px', marginLeft: '0px' }}>Assign Roles</h1>

            <div style={{ width: '100%', margin: 'auto' }}>
              <h6 style={{ color: 'black', fontSize: '14px', fontWeight: '800', marginTop: '10px' }}>Roles Provided</h6>
              {tempsingleemployeeRoles?.user_roles?.map((r) => (
                r.roles.map(r1 => (
                  <Tag style={{ marginBottom: '2px' }} key={r1?.id} color={`${colors[Math.floor(Math.random() * colors.length)]}`}>{r1.role_name}<IoClose onClick={() => removeRolesProvided(r1)} style={{ color: 'black', marginLeft: '10px', cursor: 'pointer' }} /></Tag>

                ))

              ))}
            </div>

            <div style={{ width: '100%', margin: 'auto' }}>
              <h6 style={{ color: 'black', fontSize: '14px', fontWeight: '800', marginTop: '10px' }}>Roles Selected({selectedroles.length})</h6>
              {selectedroles?.map((r) => (
                <Tag key={r.id} style={{ marginBottom: '2px' }} color={`${colors[Math.floor(Math.random() * colors.length)]}`}>{r.value}<IoClose style={{ color: 'black', marginLeft: '10px', cursor: 'pointer' }} onClick={() => cancelselectedrole(r)} /></Tag>
              ))}
            </div>

            <div>
              <div style={{ display: 'flex', flexDirection: 'row', width: '85%', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px' }}>
                <h6 style={{ color: 'black', fontSize: '14px', fontWeight: '800', marginTop: '10px' }}>Add Roles</h6>
                {!add_btn ?
                  <BiPlus size={18} onClick={() => setadd_btn(true)} />
                  :
                  <BiMinus size={18} onClick={() => setadd_btn(false)} />
                }
              </div>
              {add_btn &&
                <>
                  <Select
                    style={{ width: '84%' }}
                    mode="multiple"
                    onChange={handleRole}
                  >
                    {roles_arr.map((r) => (
                      <Option value={r.id} key={r.id}>{r.value}</Option>
                    ))}

                  </Select>

                  <div onClick={AddRole}>
                    <PrimaryButton btn_name={'Add Role'} />
                  </div>
                </>
              }

              <div onClick={updaterole}>
                <PrimaryButton btn_name={'Update Role'} />
              </div>
            </div>


          </div>
        </Modal>

        <Modal visible={visible} onCancel={() => setvisible(false)} onClose={() => setvisible(false)} centered footer={false} style={{ maxWidth: '300px', minHeight: '400px', maxHeight: '400px', overflow: 'hidden' }}>
          <div style={{ width: '300px', margin: '-24px', padding: '0px' }}>
            <div style={{ height: '200px', width: '100%', borderTopLeftRadius: '2px', borderTopRightRadius: '2px', padding: '10px', color: 'white', borderBottomLeftRadius: '40%', marginTop: '40px' }}>
              <h6 style={{ fontWeight: 'bold', color: 'black', fontSize: '12px', marginBottom: '0px', padding: '5px', borderRadius: '2px', background: '#f0fdff', border: '1px solid #0bb9d4', fontWeight: '500' }}>Note: By clicking the confirm button below the user is no longer can access FCPL CRM. </h6>

              <h1 style={{ color: 'black', fontSize: '15px', fontWeight: '400', marginTop: '20px', marginLeft: '5px' }}>Hello <span style={{ fontWeight: 'bolder' }}>{name}</span></h1>
              <h6 style={{ fontSize: '11px', width: '98%', marginLeft: '5px', lineHeight: '1.3rem' }}>Are you sure want to update active status of the employee (<strong style={{ fontWeight: 'bolder' }}>{singleemployee.name}</strong>)
                who is having an fcpl_id of <strong style={{ fontWeight: 'bolder' }}>{singleemployee.employee_id}</strong>)
                and belongs to {(<Tag color={`${colors[Math.floor(Math.random() * colors.length)]}`}>{singleemployee?.department_id?.map(d => d.department_name)}</Tag>)}
                {/* who has a designation of (<strong>{singleemployee?.designation_id[0]?.designation_name}</strong>) */}
                {/* and have a roles of {singleemployee?.user_roles[0]?.roles?.map((r)=><Tag color={`${colors[Math.floor(Math.random() * colors.length)]}`}>{r.role_name}</Tag>)}  */}
              </h6>


            </div>
            <div style={{ width: '100%', height: '40%', background: 'white', display: 'flex', alignItems: 'flex-end', justifySelf: 'flex-end', justifyContent: 'flex-end' }}>
              <div style={{ marginRight: '10px' }} onClick={() => updateuseractivestatus()}>
                <PrimaryButton btn_name="confirm" />
              </div>
            </div>

          </div>
        </Modal>

        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '20px' }}>
          <h6 style={{ fontWeight: '800', fontSize: '14px' }}>Onboarded Employee({pagination.total})</h6>
          <div onClick={() => navigate('/on_board')}>
            <PrimaryButton btn_name="Add Employee" />
          </div>
        </div>

        <div style={{ position: 'fixed', right: '30px', width: '40px', bottom: '58px', backgroundColor: '#222f3e', padding: 8, borderRadius: 20 }}>
          <IoFlask size={24} color="#fff" onClick={() => setvisible2(true)} />
        </div>


        <Tabs defaultActiveKey={tab} onChange={(v) => settab(v)}>

          <TabPane tab="Active Employee's" key="0">
            <div style={{ width: '100%', margin: 'auto', marginBottom: '20px' }}>
              <h6 style={{ marginBottom: '16px', fontWeight: '500', fontSize: '14px' }}>Active Employees</h6>
              <div style={{ overflowX: 'scroll' }} className="hidescroll_bar">
                {employee.length > 0 ?
                  <table >
                    <tr >
                      <th style={{ minWidth: '70px', fontWeight: '800', color: 'black' }}><BiHighlight size={18} /> ID</th>
                      <th style={{ minWidth: '90px', fontWeight: '800', color: 'black' }}><FaIdCardAlt size={18} /> FCPL ID</th>
                      <th style={{ minWidth: '120px', fontWeight: '800', color: 'black' }}><FiUser size={18} /> Name</th>
                      <th style={{ minWidth: '150px', fontWeight: '800', color: 'black' }}><IoMailOutline size={18} /> Email</th>
                      <th style={{ minWidth: '150px', fontWeight: '800', color: 'black' }}><FcDepartment size={18} /> Department</th>
                      <th style={{ minWidth: '180px', fontWeight: '800', color: 'black' }}><BiHighlight size={18} /> Designation</th>
                      <th style={{ minWidth: '180px', fontWeight: '800', color: 'black' }}><BiHighlight size={18} /> Roles</th>
                      <th style={{ minWidth: '0px', fontWeight: '800', color: 'black' }}><VscFolderActive size={18} /> Active</th>
                      <th style={{ minWidth: '100px', fontWeight: '800', color: 'black' }}><MdPendingActions size={18} /> Actions</th>
                    </tr>

                    {employee.map((d, i) => (
                      <tr key={d._id} style={{ borderBottom: '1px solid #eaeded' }}>
                        <td style={{ minWidth: '70px', fontWeight: '800', color: 'black', paddingLeft: '10px' }}>{i + 1}</td>
                        <td style={{ minWidth: '90px', fontWeight: '500', wordBreak: 'break-word', fontWeight: 'bolder' }} className='active_link'>{d?.employee_id}</td>
                        <td style={{ minWidth: '120px', wordBreak: 'break-word' }} className='active_link'>{d?.name}</td>
                        <td style={{ minWidth: '150px', wordBreak: 'break-word', marginRight: '1%' }}>{d?.official_email}</td>
                        <td style={{ minWidth: '150px', wordBreak: 'break-word', marginRight: '1%' }}><Tag color={`${colors[Math.floor(Math.random() * colors.length)]}`}>{d?.department_id.map(d => d.department_name)}</Tag></td>
                        <td style={{ minWidth: '180px', wordBreak: 'break-word', marginRight: '1%' }} className='active_link'>{d?.designation_id[0]?.designation_name}</td>
                        <td style={{ minWidth: '180px', wordBreak: 'break-word', fontWeight: 'bolder' }}>{d?.user_roles?.map((r) => r.roles.map((r) => <Tag style={{ marginBottom: '2px' }} color={`${colors[Math.floor(Math.random() * colors.length)]}`}>{r.role_name}  </Tag>))}</td>
                        <td style={{ minWidth: '90px', wordBreak: 'break-word', paddingLeft: '10px' }}><Checkbox checked={d?.isActive} onClick={() => updateactivestatus(d, '0')} /></td>
                        <td style={{ minWidth: '100px', paddingLeft: '10px' }}><FiEdit size={20} style={{ marginRight: '10px' }} onClick={() => UpdateOnboardedData(d._id)} />  <FaUserShield size={20} style={{ marginLeft: '5px' }} onClick={() => updateactivestatus(d, '3')} /></td>
                      </tr>
                    ))}
                  </table>

                  :
                  <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                    <img src={"https://fidecrms3bucket.s3.amazonaws.com/nodatafound.svg"} alt="nodatafouund" style={{ margin: 'auto', width: '110px', objectFit: 'contain' }} />
                    <h4 style={{ fontSize: '14px', fontWeight: '700', marginTop: '10px', textAlign: 'center' }}>{"No active users found."}</h4>
                    <h6 style={{ color: '#747474', fontSize: '12px', maxWidth: '100%', textAlign: 'center', margin: 'auto' }}>{"No active users found in the current page"}</h6>
                  </div>
                }
              </div>
            </div>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: '0px auto', paddingBottom: '40px' }}>
              {pagination.total > 10 &&
                <Pagination defaultCurrent={1} size="medium" total={pagination.total} onChange={(v) => setpagefun(v)} showSizeChanger pageSize={10} />
              }
            </div>
          </TabPane>

          <TabPane tab="InActive Employee's" key="1">
            <div style={{ width: '100%', margin: 'auto', marginBottom: '20px' }}>
              <h6 style={{ marginBottom: '16px', fontWeight: '500', fontSize: '14px' }}>InActive Employees</h6>

              <div style={{ overflowX: 'scroll' }} className="hidescroll_bar">
                {inactive_employee.length > 0 ?
                  <table>
                    <tr>
                      <th style={{ minWidth: '70px', fontWeight: '800', color: 'black' }}><BiHighlight size={18} /> ID</th>
                      <th style={{ minWidth: '90px', fontWeight: '800', color: 'black' }}><FaIdCardAlt size={18} /> FCPL ID</th>
                      <th style={{ minWidth: '120px', fontWeight: '800', color: 'black' }}><FiUser size={18} /> Name</th>
                      <th style={{ minWidth: '150px', fontWeight: '800', color: 'black' }}><IoMailOutline size={18} /> Email</th>
                      <th style={{ minWidth: '150px', fontWeight: '800', color: 'black' }}><FcDepartment size={18} /> Department</th>
                      <th style={{ minWidth: '180px', fontWeight: '800', color: 'black' }}><BiHighlight size={18} /> Designation</th>
                      <th style={{ minWidth: '180px', fontWeight: '800', color: 'black' }}><BiHighlight size={18} /> Roles</th>
                      <th style={{ minWidth: '0px', fontWeight: '800', color: 'black' }}><VscFolderActive size={18} /> Active</th>
                      <th style={{ minWidth: '100px', fontWeight: '800', color: 'black' }}><MdPendingActions size={18} /> Actions</th>
                    </tr>

                    {inactive_employee.map((d, i) => (
                      <tr key={d._id} style={{ borderBottom: '1px solid #eaeded' }}>
                        <td style={{ minWidth: '70px', fontWeight: '800', color: 'black', paddingLeft: '10px' }}>{i + 1}</td>
                        <td style={{ minWidth: '90px', fontWeight: '500', wordBreak: 'break-word', fontWeight: 'bolder' }} className='active_link'>{d?.employee_id}</td>
                        <td style={{ minWidth: '120px', wordBreak: 'break-word' }} className='active_link'>{d?.name}</td>
                        <td style={{ minWidth: '150px', wordBreak: 'break-word', marginRight: '1%' }}>{d?.official_email}</td>
                        <td style={{ minWidth: '150px', wordBreak: 'break-word', marginRight: '1%' }}><Tag color={`${colors[Math.floor(Math.random() * colors.length)]}`}>{d?.department_id.map(d => d.department_name)}</Tag></td>
                        <td style={{ minWidth: '180px', wordBreak: 'break-word', marginRight: '1%' }} className='active_link'>{d?.designation_id[0]?.designation_name}</td>
                        <td style={{ minWidth: '180px', wordBreak: 'break-word', fontWeight: 'bolder' }}>{d?.user_roles?.map((r) => r.roles.map((r) => <Tag style={{ marginBottom: '2px' }} color={`${colors[Math.floor(Math.random() * colors.length)]}`}>{r.role_name}  </Tag>))}</td>
                        <td style={{ minWidth: '90px', wordBreak: 'break-word', paddingLeft: '10px' }}><Checkbox checked={d?.isActive} onClick={() => updateactivestatus(d, '1')} /></td>
                        <td style={{ minWidth: '100px', paddingLeft: '10px', opacity: 0.5 }}><FiEdit size={20} style={{ marginRight: '10px' }} />  <FaUserShield size={20} style={{ marginLeft: '5px' }} /></td>
                      </tr>
                    ))}
                  </table>

                  :

                  <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                    <img src={"https://fidecrms3bucket.s3.amazonaws.com/nodatafound.svg"} alt="nodatafouund" style={{ margin: 'auto', width: '110px', objectFit: 'contain' }} />
                    <h4 style={{ fontSize: '14px', fontWeight: '700', marginTop: '10px', textAlign: 'center' }}>{"No in-active users found."}</h4>
                    <h6 style={{ color: '#747474', fontSize: '12px', maxWidth: '100%', textAlign: 'center', margin: 'auto' }}>{"No in-active users found in the current page"}</h6>
                  </div>
                }
              </div>
            </div>

          </TabPane>

        </Tabs>


      </div>
    </div>
  )
}

export default OnboardEmployee;
