import React, { useState } from 'react'
import { Input } from 'antd';
import { Button } from 'antd';
import bgImg from '../../../images/background.svg'
// import Inputerror from '../../../components/errors/Inputerror';
import axios from 'axios';
import { authHeader } from '../../../helpers/usertoken/UserToken'
import toast, { Toaster } from 'react-hot-toast'
import { VscEye, VscEyeClosed } from 'react-icons/vsc'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { useSearchParams, useNavigate } from 'react-router-dom';

function ResetPassword() {

    const [password_visible, setpassword_visible] = useState({
        one:false,
        two:false
    })
    const [data, setData] = useState({ password: '', confirm_password: '' })
    const [passwordErr, setPasswordErr] = useState('')
    const [confirmPasswordErr, setConfirmPasswordErr] = useState('')

    const [searchParams] = useSearchParams();
    const token = searchParams.get('token')
    const navigate = useNavigate()




    function resetPassword() {
        if (data.password.length < 8) {
            setPasswordErr('Password should be atleast 8 characters')
        }
        else if (data.confirm_password !== data.password) {
            setPasswordErr('')
            setConfirmPasswordErr('Password did not match')
        }
        else {
            setConfirmPasswordErr('')
            delete authHeader.headers.Authorization
            axios.post(`user/reset-password?token=${token}`, data, authHeader).then((res) => {
                toast.success('Password Changed Successfully')
                navigate('/login')
            }).catch((err) => {
                toast.error('Oops something went wrong')
            })
        }
    }


    return (
        <div style={{ display: 'grid', width: '100%', height: '100%', justifyContent: 'center', alignContent: 'center', background: `#fff`, zIndex: 0, position: 'fixed', left: '0px', top: '0px', backgroundSize: 'cover' }}>
            <Toaster />
            <div style={{ background: 'white', display: 'flex', width: '60%', maxHeight: '330px', margin: 'auto', boxShadow: '0px 0px 13px #f5f5f5' }} >
                <div style={{  width: '100%', padding: '0 0%', }} className="p-4" >
                    <h4 style={{fontWeight:'800',fontSize:'15px',marginTop:'20px'}}>Reset Password</h4>
                    <h6 tyle={{fontSize:'13px'}}>Enter your password, to change  FCPL CRM account password.</h6>
                    <div style={{ width: '100%', position: 'relative' }} className="mt-3">
                        <Input
                            name="password"
                            size="medium"
                            placeholder="Enter new Password"
                            style={{ width: '100%', zIndex: 10 }}
                            type={!password_visible.one ? "password" : "text"}
                            class="input"
                            value={data.password}
                            onChange={(e) => setData({ ...data, password: e.target.value })} />
                        {
                            passwordErr ?
                                <div style={{ display: 'flex', alignItems: 'center', marginTop: '3px', position: 'absolute' }}>
                                    <AiOutlineInfoCircle size={13} style={{ color: 'red' }} />
                                    <p style={{ marginBottom: '0px', fontSize: '12px', color: 'red', paddingLeft: '3px' }}>{passwordErr}</p>
                                </div> : ''
                        }
                        {!password_visible.one ? <VscEyeClosed size={14} style={{ position: 'absolute', right: '10px', zIndex: '100', top: '10px', color: 'grey' }} onClick={() => setpassword_visible({...password_visible,one:true})} /> : <VscEye size={14} style={{ position: 'absolute', right: '10px', zIndex: '100', top: '9px', color: 'grey' }} onClick={() => setpassword_visible({...password_visible,one:false})} />}
                    </div>
                    <div style={{ width: '100%', position: 'relative' }} className="mt-4">
                        <Input
                            name="password"
                            size="medium"
                            placeholder="Confirm new Password"
                            style={{ width: '100%', zIndex: 10 }}
                            type={!password_visible.two ? "password" : "text"}
                            class="input"
                            value={data.confirm_password}
                            onChange={(e) => setData({ ...data, confirm_password: e.target.value })} />
                        {
                            confirmPasswordErr ?
                                <div style={{ display: 'flex', alignItems: 'center', marginTop: '3px', position: 'absolute' }}>
                                    <AiOutlineInfoCircle size={13} style={{ color: 'red' }} />
                                    <p style={{ marginBottom: '0px', fontSize: '12px', color: 'red', paddingLeft: '3px' }}>{confirmPasswordErr}</p>
                                </div> : ''
                        }
                        {!password_visible.two ? <VscEyeClosed size={14} style={{ position: 'absolute', right: '10px', zIndex: '100', top: '10px', color: 'grey' }} onClick={() => setpassword_visible({...password_visible,two:true})} /> : <VscEye size={14} style={{ position: 'absolute', right: '10px', zIndex: '100', top: '9px', color: 'grey' }} onClick={() => setpassword_visible({...password_visible,two:false})} />}
                    </div>

                    <Button onClick={() => resetPassword()} style={{fontSize:'13px',fontWeight:'800',background:'#2064b3'}} className='btn  btn-primary mt-3'  type="primary"  size={'medium'} block>Reset Password</Button>

                    {/* <Button type="primary" className="mt-4" size={'large'} block onClick={() => resetPassword()}>Reset Password</Button> */}

                </div>
                {/* <div style={{ flex: 0.4, overflow: 'hidden', width: '40%', borderLeft: '1px solid #f0f0f0', display: 'grid', justifyContent: 'center', alignContent: 'center', alignItems: 'center', textAlign: 'center', padding: '0 20px', }}>
                    <img alt="side-img" src="https://accounts.zoho.com/v2/components/images/recovery_illustration2x.png" style={{ width: '200px', objectFit: 'contain', margin: 'auto' }} />
                    <h6 style={{ marginBottom: '0px' }}>Forgot Your Password</h6>
                    <p style={{ fontSize: '14px', width: '86%', wordWrap: 'break-word', margin: 'auto', marginTop: '10px', textAlign: 'center', lineHeight: '' }}>Did you forget your password? experience update password feature to your FCPL account provided by FCPL CRM.</p>
                </div> */}
            </div>
            <p style={{ width: '100%', textAlign: 'center', bottom: '10px', position: 'absolute' }}>© 2022, Fidelitus Corp Pvt. Ltd. All Rights Reserved.</p>
        </div>
    )
}


export default ResetPassword