import React from 'react'
import FmsDashboardMainRoot from '../FmsDashboardMainRoot'
import { Row,Col } from 'antd'
import { BoldText1 } from '../../../helpers/constants/ConstantsText'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

function AttendanceOptionsRoot() {
  const navigation = useNavigate()


  

  
  return (
    <FmsDashboardMainRoot>
        <div style={{width:'97%'}}>
            <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between',borderBottom:'1px solid #eee'}}>
                <BoldText1 val={`Attendance Option`} />
            </div>    

             <Row style={{marginTop:'10px',cursor:'pointer'}}>
                <Col span={4} style={{border:'1px solid #eee',marginRight:'1%'}}>
                    <div style={{padding:'2px'}}>
                       <h6 style={{fontSize:'10px',background:'#fafafa',padding:'2px'}}>click on the below option name to add shifts for your employee attendance</h6>  
                       <h6 style={{fontSize:'12px',fontWeight:'800'}} onClick={()=>navigation('/fms/attendance_option_detail',{state:'Shifts'})}>Shifts</h6>
                    </div>         
                </Col>

                <Col span={4} style={{border:'1px solid #eee',marginRight:'1%'}}>
                <div style={{padding:'2px'}}>
                       <h6 style={{fontSize:'10px',background:'#fafafa',padding:'2px'}}>click on the below option name to add stage for your employee attendance</h6>  
                       <h6 style={{fontSize:'12px',fontWeight:'800'}} onClick={()=>navigation('/fms/attendance_option_detail',{state:'Stages'})}>Stage</h6>
                    </div>       
                </Col>
                <Col span={4} style={{border:'1px solid #eee',marginRight:'1%'}}>
                <div style={{padding:'2px'}}>
                       <h6 style={{fontSize:'10px',background:'#fafafa',padding:'2px'}}>click on the below option name to add year for your employee attendance</h6>  
                       <h6 style={{fontSize:'12px',fontWeight:'800'}} onClick={()=>navigation('/fms/attendance_option_detail',{state:'Years'})}>Year</h6>
                    </div>       
                </Col>

                <Col span={4} style={{border:'1px solid #eee',marginRight:'1%'}}>
                <div style={{padding:'2px'}}>
                       <h6 style={{fontSize:'10px',background:'#fafafa',padding:'2px'}}>click on the below option name to download attendance of your employee based on client</h6>  
                       <h6 style={{fontSize:'12px',fontWeight:'800'}} onClick={()=>navigation('/fms/employee_attendance_based_on_clients')}>Download</h6>
                    </div>       
                </Col>

             </Row>
        </div>
    </FmsDashboardMainRoot>
  )
}

export default AttendanceOptionsRoot