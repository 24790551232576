import React from 'react'
import { Drawer } from 'antd';
import { IconButton } from '@material-ui/core';
import { IoNewspaper, IoMenu } from 'react-icons/io5'

import { AiFillDashboard, AiFillContacts, AiTwotoneSetting, AiFillFileAdd, AiTwotoneBuild } from 'react-icons/ai'
import { MdInput, MdOutlineLogout, MdAssignmentInd } from 'react-icons/md'
import { RiProjectorFill, RiBuildingFill } from 'react-icons/ri'
import { BiTask } from 'react-icons/bi'
import { BsCalendar4Event } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
import { footer_Active_no } from '../../redux/actionPayload/MobileFooterPayload'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../redux/actionPayload/AuthPayload';
import { main_bg, act_col } from '../../components/constants/Constants1'
import { useLocation } from 'react-router-dom';
import { FaIndustry, FaShieldAlt, FaSteamSquare } from 'react-icons/fa'
import { SiCrowdsource } from 'react-icons/si'


function RootNavbar({ visible, func }) {
    const { pathname } = useLocation()
    const dispatch = useDispatch()

    let p = pathname

    const roles = useSelector(state => state.Auth.roles)
    const fcpl_id = useSelector(state => state.Auth.fcpl_id)

    const navigate = useNavigate()


    function setdrawer() {

    }

    function handleClick(v, path) {
        dispatch(footer_Active_no(v))
        navigate(path)
        func()
    }

    return (
        <div style={{ zIndex: 100 }}>
            <Drawer
                placement={'left'}
                closable={false}
                visible={visible}
                onClose={() => func()}
                key={'left'}
                width='85%'
                bodyStyle={{ background: `${main_bg}`, margin: '0px', padding: '0px', minHeight: '100vh', overflowX: 'hidden' }}
            >


                <div style={{ width: '100vw', maxWidth: '320px', minHeight: '100vh', background: `${main_bg}`, overflowX: 'hidden' }}>
                    <div style={{ background: `${main_bg}`, display: 'flex', alignItems: 'center', height: '45px' }}>
                        <IconButton onClick={() => setdrawer(false)}><IoMenu size={21} color="#fff" /></IconButton>
                        <h6 className="logo-head" style={{ marginBottom: '0px', fontSize: '14px', color: 'white', margin: '0px 20px', fontWeight: '900' }}>FCPL CRM</h6>
                    </div>

                    <div>
                        <div style={{ marginLeft: '10px', marginTop: '20px' }}>
                            <h6 style={{ color: 'white', fontSize: '13px', fontWeight: '900' }}>MENU</h6>
                            {(roles?.includes('db_head') ||
                                roles?.includes('db_user') ||
                                roles?.includes('db_sub_user') ||
                                roles?.includes('hod') ||
                                roles?.includes('manager') ||
                                roles?.includes('admin') ||
                                roles?.includes('bd_user')) &&
                                <div style={{ marginLeft: '12px' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', padding: '8px' }} onClick={() => handleClick(1, '/bd_dashboard')}>
                                        <AiFillDashboard size={22} color={p !== '/bd_dashboard' ? "grey" : `${act_col}`} />
                                        <h6 style={{ marginLeft: '20px', marginBottom: '0px', fontSize: '14px', opacity: 0.9, color: p !== '/bd_dashboard' ? "grey" : `#fff` }}>Dashboard</h6>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', padding: '8px' }} onClick={() => handleClick(2, '/view_contacts')}>
                                        <AiFillContacts size={22} color={p !== '/view_contacts' ? "grey" : `${act_col}`} />
                                        <h6 style={{ marginLeft: '20px', marginBottom: '0px', fontSize: '14px', opacity: 0.9, color: p !== '/view_contacts' ? "grey" : `#fff` }}>Contacts</h6>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', padding: '8px' }} onClick={() => handleClick(3, '/view_leads')}>
                                        <MdInput size={22} color={p !== '/view_leads' ? "grey" : `${act_col}`} />
                                        <h6 style={{ marginLeft: '20px', marginBottom: '0px', fontSize: '14px', opacity: 0.9, color: p !== '/view_leads' ? "grey" : `#fff` }}>Leads</h6>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', padding: '8px' }} onClick={() => handleClick(3, '/view_deals')}>
                                        <RiProjectorFill size={22} color={p !== '/view_deals' ? "grey" : `${act_col}`} />
                                        <h6 style={{ marginLeft: '20px', marginBottom: '0px', fontSize: '14px', opacity: 0.9, color: p !== '/view_deals' ? "grey" : `#fff` }}>Deals</h6>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', padding: '8px' }} onClick={() => handleClick(5, '/view_tasks')}>
                                        <BiTask size={22} color={p !== '/view_tasks' ? "grey" : `${act_col}`} />
                                        <h6 style={{ marginLeft: '20px', marginBottom: '0px', fontSize: '14px', opacity: 0.9, color: p !== '/view_tasks' ? "grey" : `#fff` }}>Tasks</h6>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', padding: '8px' }} onClick={() => handleClick(5, '/view_meetings')}>
                                        <BsCalendar4Event size={17} style={{ margin: '0px 2px' }} color={p !== '/view_meetings' ? "grey" : `${act_col}`} />
                                        <h6 style={{ marginLeft: '20px', marginBottom: '0px', fontSize: '14px', opacity: 0.9, color: p !== '/view_meetings' ? "grey" : `#fff` }}>Meetings</h6>
                                    </div>

                                    {(roles?.includes('admin') || roles?.includes('db_head') || roles?.includes('db_user') || roles?.includes('hod')) &&
                                        <div style={{ display: 'flex', alignItems: 'center', padding: '8px' }} onClick={() => handleClick(5, '/TeamCLD')}>
                                            <AiTwotoneBuild size={18} color={p !== '/TeamCLD' ? "grey" : `${act_col}`} />
                                            <h6 style={{ marginLeft: '20px', marginBottom: '0px', fontSize: '14px', opacity: 0.9, color: p !== '/TeamCLD' ? "grey" : `#fff` }}>Company CLD</h6>
                                        </div>}



                                </div>}

                            {roles.includes('controller') || roles.includes('admin') &&
                                <>
                                    <div style={{ display: 'flex', alignItems: 'center', padding: '8px' }} onClick={() => handleClick(1, '/controller_dashboard')}>
                                        <AiFillDashboard size={22} color={p !== '/controller_dashboard' ? "grey" : `${act_col}`} />
                                        <h6 style={{ marginLeft: '20px', marginBottom: '0px', fontSize: '14px', opacity: 0.9, color: p !== '/controller_dashboard' ? "grey" : `#fff` }}>C_Dashboard</h6>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', padding: '8px' }} onClick={() => handleClick(5, '/on_board')}>
                                        <IoNewspaper size={18} color={p !== '/on_board' ? "grey" : `${act_col}`} />
                                        <h6 style={{ marginLeft: '20px', marginBottom: '0px', fontSize: '14px', opacity: 0.9, color: p !== '/on_board' ? "grey" : `#fff` }}>Onboard Employee </h6>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', padding: '8px' }} onClick={() => handleClick(5, '/onboarded_employee')}>
                                        <MdAssignmentInd size={18} color={p !== '/onboarded_employee' ? "grey" : `${act_col}`} />
                                        <h6 style={{ marginLeft: '20px', marginBottom: '0px', fontSize: '14px', opacity: 0.9, color: p !== '/onboarded_employee' ? "grey" : `#fff` }}>Assign Roles</h6>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', padding: '8px' }} onClick={() => handleClick(5, '/departments')}>
                                        <RiBuildingFill size={18} color={p !== '/departments' ? "grey" : `${act_col}`} />
                                        <h6 style={{ marginLeft: '20px', marginBottom: '0px', fontSize: '14px', opacity: 0.9, color: p !== '/departments' ? "grey" : `#fff` }}>Department</h6>
                                    </div>

                                    <div style={{ display: 'flex', alignItems: 'center', padding: '8px' }} onClick={() => handleClick(5, '/designation')}>
                                        <FaSteamSquare size={18} color={p !== '/designation' ? "grey" : `${act_col}`} />
                                        <h6 style={{ marginLeft: '20px', marginBottom: '0px', fontSize: '14px', opacity: 0.9, color: p !== '/designation' ? "grey" : `#fff` }}>Designation</h6>
                                    </div>

                                    <div style={{ display: 'flex', alignItems: 'center', padding: '8px' }} onClick={() => handleClick(5, '/roles')}>
                                        <FaShieldAlt size={18} color={p !== '/roles' ? "grey" : `${act_col}`} />
                                        <h6 style={{ marginLeft: '20px', marginBottom: '0px', fontSize: '14px', opacity: 0.9, color: p !== '/roles' ? "grey" : `#fff` }}>Roles</h6>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', padding: '8px' }} onClick={() => handleClick(5, '/Add_DDR')}>
                                        <AiFillFileAdd size={18} color={p !== '/Add_DDR' ? "grey" : `${act_col}`} />
                                        <h6 style={{ marginLeft: '20px', marginBottom: '0px', fontSize: '14px', opacity: 0.9, color: p !== '/Add_DDR' ? "grey" : `#fff` }}>Add DDR</h6>
                                    </div>
                                </>}
                        </div>

                        {(fcpl_id === 'fcpl_240' || fcpl_id === 'fcpl_239') &&
                            <div style={{ marginLeft: '10px', marginTop: '10px', paddingTop: '20px' }}>
                                <h6 style={{ color: '#fff', fontSize: '13px', fontWeight: '900' }}>RK OPTIONS</h6>
                                <div style={{ marginLeft: '12px' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', padding: '8px' }} onClick={() => handleClick(1, '/add_industry')}>
                                        <FaIndustry size={20} color={p !== '/add_industry' ? "grey" : `${act_col}`} />
                                        <h6 style={{ marginLeft: '20px', marginBottom: '0px', fontSize: '14px', opacity: 0.9, color: "grey" }}>Industry</h6>
                                    </div>

                                    <div style={{ display: 'flex', alignItems: 'center', padding: '8px' }} onClick={() => handleClick(1, '/contact_source')}>
                                        <SiCrowdsource size={22} color={p !== '/contact_source' ? "grey" : `${act_col}`} />
                                        <h6 style={{ marginLeft: '20px', marginBottom: '0px', fontSize: '14px', opacity: 0.9, color: "grey" }}>Source</h6>
                                    </div>
                                </div>
                            </div>}

                        <div style={{ marginLeft: '10px', marginTop: '10px', paddingTop: '20px' }}>
                            <h6 style={{ color: '#fff', fontSize: '13px', fontWeight: '900' }}>OPTIONS</h6>
                            <div style={{ marginLeft: '12px' }}>
                                <div style={{ display: 'flex', alignItems: 'center', padding: '8px' }} onClick={() => dispatch(logout(''))}>
                                    <MdOutlineLogout size={22} color="grey" />
                                    <h6 style={{ marginLeft: '20px', marginBottom: '0px', fontSize: '14px', opacity: 0.9, color: "grey" }}>Logout</h6>
                                </div>
                            </div>
                        </div>
                    </div>

                    <h6 className="logo-head1" style={{ marginBottom: '0px', margin: '0px 0px', width: '80%', padding: '20px', fontSize: '10px', bottom: '0px', color: '#dedcdc', }}>This application is powered by <span style={{ color: '#007eb9', fontSize: 13, fontWeight: '900' }}>Fidelitus Corp Pvt Ltd</span></h6>



                </div>


            </Drawer>
        </div>
    )
}

export default RootNavbar