import React,{useState,useEffect} from 'react';
import { BoldHeadText,BoldText1,SmallText } from '../../../helpers/constants/ConstantsText';
import FmsDashboardMainRoot from '../FmsDashboardMainRoot';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import {useSelector} from 'react-redux';
import {Input,Select,DatePicker} from 'antd'
import PrimaryButton, { SecondaryButton } from '../../../components/buttons/PrimaryButton';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { FiEdit,FiTrash2 } from 'react-icons/fi';
import {  } from 'react-icons/fc';
import { data_not_found } from '../../../helpers/Constants';
import { IoClose } from 'react-icons/io5';
import moment from 'moment';
import axios from 'axios';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import {  useParams } from 'react-router-dom';
import toast, { Toaster } from "react-hot-toast";
import {useLocation,useNavigate} from 'react-router-dom';
import {BsArrowLeft} from 'react-icons/bs';



function FormF(){




    const { Option } = Select; 
    const { TextArea } = Input;
    const location = useLocation();

   const [drawer,set_drawer] = useState(false)

   const [nominee,setnominee] = useState({name:'',address:'',relationship:'',age:'',proportion_to_shared:''});
   const [err,seterr] = useState({name:'',address:'',relationship:'',age:'',proportion_to_shared:''});
   const [nomineeArr,setnomineeArr] = useState([]);


    const martial_Arr = [
        'Single',
        'Married',
        'Widow',
        'Widower'
    ]

    const steps = [
        {
          label: 'Basic Info',
          description: `Add a employee basic info for your FORM 'F'`,
        },
        {
          label: 'Nominee(s)',
          description:
            'Add the nominees for the employee',
        },
    ] 

    const [activeStep, setActiveStep] = useState(0);
    const [data,setdata] = useState({to:'',name:'',date:'',fms_employee:''})
    const [err1,seterr1] = useState({to:'',name:'',date:'',fms_employee:''})


    const theme_color = useSelector(state=>state.Theme.theme_color)

    const relationship_Arr = [
        'Mother',
        'Father',
        'Cousin',
        'Brother',
        'Sister',
        'Child',
        'Spouse',
        'Neighbour',
        'Other'
       ]
       
    const { id } = useParams();
    const navigate = useNavigate()


    useEffect(()=>{
        let type = window.location.pathname.split('/')[window.location.pathname.split('/').length - 2]

        // console.log('sent_data',sent_data.formf)
        if(type == 'update'){
            let sent_data = location.state.datas;
            // console.log('sent_data?.nominees',sent_data?.nominess)
            setdata({...data,
                date:sent_data?.formf[0]?.date,
                id:sent_data?.formf[0]?._id,
                to:sent_data?.formf[0]?.to,
                fms_employee:sent_data?.formf[0]?.fms_employee,
                name:sent_data?.formf[0]?.name,
                })

            let arr = []
            for (let i = 0; i< sent_data?.nominess?.length;i++){
                let obj = {
                    id:i+1,
                    name:sent_data?.nominess[i].name,
                    address:sent_data?.nominess[i].address,
                    relationship:sent_data?.nominess[i].relationship,
                    age:sent_data?.nominess[i].age,
                    proportion_to_shared:sent_data?.nominess[i].proportion_to_shared,
                } 
                arr.push(obj)
            }
            setnomineeArr(arr)    
            // console.log('iam in update stage')
        }else{
            let sent_data = location.state.datas;



            setdata({
                to:`${sent_data.name} ${sent_data.present_address}`,
                fms_employee:'',
                name:sent_data?.name,
                date:'',
                id:''
            })
            // console.log('iam not in update stage')
        }
        // console.log('window.location.pathname',window.location.pathname)
    },[]);   

   
    function addnominee(){
        if(nominee.name === ''){
            seterr({...err,name:'Name Field is required'})
        }else if(nominee.address === ''){
            seterr({...err,address:'Address Field is required'})
        }else if(nominee.relationship === ''){
            seterr({...err,relationship:'Relationship Field is required'})
        }else if(nominee.age === ''){
            seterr({...err,age:'Age Field is required'})
        }else if(nominee.proportion_to_shared === ''){
            seterr({...err,proportion_to_shared:'Propotion Field is required'})
        }else{
            if(nominee.id !== ''){
                let previous_nominees = [...nomineeArr]
                let index = nomineeArr.findIndex(e=>e.id == nominee.id)
                let updated = previous_nominees.splice(index,1,nominee)
                setnomineeArr(previous_nominees)
            }else{
                let previous_nominees = [...nomineeArr]
                let nominee_present = {...nominee}
                if(nomineeArr.length  == 0){
                    nominee_present['id'] = 0
                }else{
                    nominee_present['id'] = nomineeArr?.length
                }
                setnomineeArr([...previous_nominees,nominee_present])
            }
           
            setnominee({name:'',address:'',relationship:'',age:'',proportion_to_shared:'',id:''})
        }
       
    }


    // console.log('nomineeArr nomineeArr nomineeArr',nomineeArr)


    // console.log('data',data)


    function saveFormF(){

        let send_data = {
            fms_employee:id,
            to:data.to,
            name:data.name,
            date:data.date,
            nominees:nomineeArr
        }

        const AuthHeader = {headers:{
            'Content-Type': 'application/json',
        }}
        

        if(data.id !== ''){
            send_data['_id'] = data.id
            axios.post(`fms/formf/update/${data.id}`,send_data,AuthHeader)
            .then((res)=>{
                toast.success(res.data.message);
                wholereset()
                console.log('res.data here',res.data)
            }).catch((err)=>{
                console.log('err update',err.response)
                if(err?.response?.status == 403){
                    toast.error(err?.response?.data?.message)
                }
            })
        }else{
            axios.post('fms/formf/create',send_data,AuthHeader)
            .then((res)=>{
                toast.success(res.data.message);
                // wholereset()
                console.log('res.data here',res.data)
            }).catch((err)=>{
                if(err.response.status == 403){
                    toast.error(err?.response?.data?.message)
                }
            })
        }


        
    }

    function resetform(){
           if(activeStep == 0){
            setdata({...data,to:'',name:'',date:''})
           }else if(activeStep == 1){
            setnomineeArr([])
            // setActiveStep(0)
           }
    }

    function wholereset(){
        // setdata({to:'',name:'',date:'',fms_employee:''})
        // setnomineeArr([])
        // setActiveStep(0)
        setTimeout(() => {
        navigate(-1)
        }, 1500);
        
    }

    function nextstep(){
        if(activeStep == 0){
            if(data.to === ''){
             seterr1({...err1,to:'This Field is required'})
            }else if(data.name === ''){
             seterr1({...err1,name:'This Field is required'})
            }else if(data.date === ''){
             seterr1({...err1,date:'Date Field is required'})
            }else{
                setActiveStep(1)
            }
        }else if(activeStep == 1){
            saveFormF()
        }
    }

    function deletenominee(d){
        // console.log('deletenominee',d)
        let updated = nomineeArr.filter((l)=>l.id !== d.id)
        // console.log('updated',updated)
        setnomineeArr(updated)
    }

    function editnominee(d){
        setnominee({...nominee,
            id:d.id,
            name:d.name,
            address:d.address,
            relationship:d.relationship,
            age:d.age,
            proportion_to_shared:d.proportion_to_shared
        })
        set_drawer(true)
    }

    return(
        <FmsDashboardMainRoot>

            <span style={{display:'flex',cursor:'pointer',marginBottom:'20px'}} onClick={()=>navigate(-1)}>
                <BsArrowLeft style={{marginRight:'5px'}}/>
                <SmallText val={'Back'} />
            </span>

 <Toaster />
<BoldText1 val={'FORM(F) Information'} />
              <p style={{borderBottom:'1px solid #eee'}}></p>
            
            <SwipeableDrawer
                anchor='right'
                style={{minWidth:'200px',maxWidth:'200px',zIndex:10}}
                    open={drawer}
                    onClose={()=>set_drawer(!drawer)}
                > 
                        <div style={{width:'300px',padding:'30px'}}> 

                           <IoClose  style={{position:'absolute',top:'10px',right:'10px',cursor:'pointer'}} onClick={()=>set_drawer(false)} />
                           <div>
                            <BoldHeadText  val="Nominee(s) Form" fontSize={'15px'} fontWeight='900'/>
                            <p style={{borderBottom:'1px solid #eee'}}></p>

                             <div style={{marginBottom:'10px'}}>
                                <div style={{width:'150px'}}>
                                <SmallText fontWeight={'600'} val={'Name '}  />
                                </div>
                                <Input type="text" style={{borderLeft:`4px solid ${theme_color}`}}  value={nominee.name} onChange={(e)=>{setnominee({...nominee,name:e.target.value});seterr({...err,name:''})}} />
                                <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                                {(err?.name) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{err?.name  }</p>
                                </div>
                                </div>

                                <div style={{marginBottom:'10px'}}>
                                <div style={{width:'150px'}}>
                                <SmallText fontWeight={'600'} val={'Address'}  />
                                </div>
                                <TextArea maxLength={200} type="text" style={{borderLeft:`4px solid ${theme_color}`}}  value={nominee.address} onChange={(e)=>{setnominee({...nominee,address:e.target.value});seterr({...err,address:''})}} />
                                <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                                {(err?.address) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{err?.address}</p>
                                </div>
                                </div>


                                <div style={{marginBottom:'10px'}}>
                                <div style={{width:'150px'}}>
                                <SmallText fontWeight={'600'} val={'Relationship'}  />
                                </div>
                                <Select  style={{borderLeft:`4px solid ${theme_color}`,width:'100%',zIndex:100,fontSize:'13px',fontWeight:'600'}} value={nominee.relationship}  onChange={(e)=>{setnominee({...nominee,relationship:e});seterr({...err,relationship:''})}} >
                                {relationship_Arr?.map((l)=>(
                                    <Option style={{zIndex:100,marginBottom:'0px',fontWeight:'500',fontSize:'13px'}} key={l} value={l}>{l}</Option>
                                    ))}
                                </Select>   
                                <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                                {(err?.relationship) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{err?.relationship  }</p>
                                </div>
                                {/* <Input  type="text" style={{borderLeft:`4px solid ${theme_color}`}}  value={nominee.relationship} onChange={(e)=>setnominee({...nominee,relationship:e.target.value})} /> */}
                                </div>

                                <div style={{marginBottom:'10px'}}>
                                <div style={{width:'150px'}}>
                                <SmallText fontWeight={'600'} val={'Age'}  />
                                </div>
                                <Input  type="text" style={{borderLeft:`4px solid ${theme_color}`}}  value={nominee.age} onChange={(e)=>{setnominee({...nominee,age:e.target.value});seterr({...err,age:''})}} />
                                <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                                {(err?.age) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{err?.age  }</p>
                                </div>
                                </div>


                                <div style={{marginBottom:'10px'}}>
                                <div style={{width:'150px'}}>
                                <SmallText fontWeight={'600'} val={'Proportion to be shared'}  />
                                </div>
                                <Input type="text" style={{borderLeft:`4px solid ${theme_color}`}}  value={nominee.proportion_to_shared} onChange={(e)=>{setnominee({...nominee,proportion_to_shared:e.target.value});seterr({...err,proportion_to_shared:''})}} />
                                <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                                {(err?.proportion_to_shared) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{err?.proportion_to_shared  }</p>
                                </div>
                                </div>

                        
                            <PrimaryButton btn_name={'Continue'} onClick={addnominee} />
                            

                           </div>

                          
                        </div>
              </SwipeableDrawer>

            <div style={{display:'flex',minHeight:'90vh'}}>
            <div style={{borderRight:'1px solid #eee',marginRight:'40px',paddingRight:'30px'}}>
              <Stepper activeStep={activeStep} orientation="vertical" >
                {steps.map((step, index) => (
                <Step key={step.label}>
                    <StepLabel StepIconProps={{style: {theme_color}}} >
                    <div style={{marginTop:'30px',maxWidth:'150px'}}>
                    <BoldHeadText fontWeight={'900'} val={step.label} marginfun={'2px 0px'} fontSize={'13px'} />
                    <SmallText val = {step.description}  fontSize={'11px'}/>
                    </div>  
                    </StepLabel>
                </Step>
                ))}
              </Stepper>
            </div> 



            <div> 
                {activeStep == 0 &&
                <div>
                <div>
              <div style={{display:'flex',justifyContent:'space-between'}}>
                <div style={{width:'300px',marginTop:'20px'}}> 


                <BoldHeadText fontWeight={'900'} fontSize='15px' val={'Basic Info'} />
                <SmallText val={"Add a employee basic info for your FORM 'F'"} />
                <p style={{height:'6px'}}></p>

                <div style={{marginBottom:'10px'}}>
                <div style={{width:'150px'}}>
                <SmallText fontWeight={'600'} val={'To'} />
                </div>
                <TextArea type="text"  style={{borderLeft:`4px solid ${theme_color}`}} value={data.to} onChange={(e)=>setdata({...data,to:e.target.value})} />
                <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                {(err1?.to) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{err1?.to}</p>
                </div>
                </div>


                <div style={{marginBottom:'10px'}}>
                <div style={{width:'150px'}}>
                <SmallText fontWeight={'600'} val={'Name'} />
                </div>
                <Input type="text"  style={{borderLeft:`4px solid ${theme_color}`}} value={data.name} onChange={(e)=>setdata({...data,name:e.target.value})} />
                <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                {(err1?.name) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{err1?.name}</p>
                </div>
                </div>


                <div style={{marginBottom:'10px'}}>
                <div style={{width:'150px'}}>
                <SmallText fontWeight={'600'} val={'Date'} />
                </div>
                <DatePicker style={{width:'100%',borderLeft:`4px solid ${theme_color}`}}  placeholder='' value={data.date !== '' ? moment(data.date) : ''} onChange={(v,v1)=>{setdata({...data,date:v1});seterr1({...err1,date:''})}}/>
                <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                {(err1?.date) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{err1?.date}</p>
                </div>
                </div>

                
                <div style={{marginLeft:'0px',display:'flex',width:'100px',justifyContent:'space-between'}}>
                    <PrimaryButton btn_name="Next" onClick={()=>nextstep()} />
                    <SecondaryButton btn_name={"Clear"}  onClick={resetform} />
                </div> 

                
                
                </div>


                



                </div>
                </div>

                </div>}
                {activeStep == 1 &&
                 <div>
                <span style={{display:'flex',cursor:'pointer'}} onClick={()=>setActiveStep(0)}>
                <BsArrowLeft style={{marginRight:'5px'}}/>
                <SmallText val={'Back'} />
                </span>
                 <div style={{display:'flex',justifyContent:'space-between'}}>
                 <div style={{marginTop:'0px'}}> 
                 <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between'}}>
                 <BoldHeadText fontWeight={'900'} fontSize='15px' val={'Nominees Details '} />
                 <PrimaryButton btn_name="Add Nominee" onClick={()=>set_drawer(true)} />
                 </div>
                 {nomineeArr.length > 0 ? 
                     <div style={{ border:'0px solid lightgray',width:'740px'}}>
                         <div className='mateial_table_header'>
                             <p style={{minWidth: '10%',color:'black',display:'flex',textAlign:'start'}}> Sl No</p>
                             <p style={{minWidth: '15%',marginRight:'2%'}}>Name</p>
                             <p style={{minWidth: '30%',marginRight:'2%'}}>Address</p>
                             <p style={{minWidth: '14%'}}>Relationship</p>
                             <p style={{minWidth: '8%'}}>Age</p>
                             <p style={{minWidth: '12%'}}>Proportion</p>
                             <p style={{minWidth: '10%'}}>Option</p>
                         </div>
  
                 {nomineeArr.map((d,i) => (
                     <div key={d.id} className={'mateial_table_body'}>
                       
                         <p style={{minWidth: '10%', color: "#0594f2",wordBreak:'break-word',fontWeight:'700'}}>{i+1}</p>
                         <p style={{minWidth: '15%',wordBreak:'break-word',marginRight:'2%',marginLeft:'-1%'}}>{d?.name}</p>
                         <p style={{minWidth: '30%',wordBreak:'break-word',marginRight:'2%'}}>{d?.address}</p>
                         <p style={{minWidth: '14%',wordBreak:'break-word',marginLeft:'5px'}}>{d?.relationship}</p>
                         <p style={{minWidth: '8%',wordBreak:'break-word',marginLeft:'-5px'}}>{d?.age}</p>
                         <p style={{minWidth: '12%',wordBreak:'break-word',marginLeft:'-5px'}}>{d?.proportion_to_shared}</p>
                         <p style={{minWidth: '10%',display:'flex',marginLeft:'-5px'}}>
                             <FiEdit size={15} style={{marginRight:'10px'}} onClick={()=>editnominee(d)} /> 
                             <FiTrash2 size={15} onClick={()=>deletenominee(d)} />
                         </p>
   
                     </div>
                 ))}
  
  
  
                
                     </div>
                     :
                     <div style={{display:'grid',justifyContent:'center',alignItems:'center',marginTop:'100px',alignSelf:'center'}}>
                     <img src={data_not_found} alt="nodatafouund" style={{margin:'auto',width:'120px',objectFit:'contain'}} />
                     <BoldText1 val={'No data added till now'} alignfun='center' />
                     <SmallText alignfun='center' val={'Click on a add family details Button. Then, you can find the data added on this page.'} />
                     </div> 
                  }
                 
             
              <div style={{marginLeft:'0px',display:'flex',width:'100px',justifyContent:'space-between'}}>
               <PrimaryButton btn_name="Next"  onClick={saveFormF}/>
               <SecondaryButton btn_name={"Clear"} onClick={resetform} />
               </div>
                </div>
                </div>
                 </div>
                }
            </div> 
            </div>

        </FmsDashboardMainRoot>
    )
}

export default FormF;