import React from 'react'
import em from '../../../images/expire_msg.webp'


function LinkExpire(){
    return(
        <div style={{display:'grid',justifyContent:'center',alignItems:'center',marginTop:'100px'}}>
              <img src={em} />
              <h6 style={{textAlign:'center',fontSize:'22px',fontWeight:'500',marginTop:'-120px'}}>Link Expired </h6>
              <h6 style={{textAlign:'center',color:'#dedede',marginTop:'-75px'}}>This link as expired </h6>
        </div>
    )
}


export default LinkExpire