
export const light_colors_arr = [
    // '#36ff6b',
    // '#5efffa',
    // '#5e8eff',
    // '#af69ff',
    // '#ff78e8',
    // '#ff5e74',
    // '#c3ff87',
    // '#ffed4d',
    // '#ffa994',
    // '#ff524d',
    '#FFF1E6'
]





export const banner_img_url = 'https://fidecrmfiles.s3.amazonaws.com/banner_lap.png'
export const fcpl_white_logo = 'https://fidecrmfiles.s3.amazonaws.com/fcpl_white_logo.png'
export const mobile_img_url = 'https://fidecrmfiles.s3.amazonaws.com/mobile_view.png'



export const data_not_found = 'https://fidecrmfiles.s3.amazonaws.com/nodatafound.svg'
export const no_profile ='https://fidecrmfiles.s3.amazonaws.com/nodatafound.svg'




export const data_not_found_img_width = '200px'