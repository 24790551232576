import React from 'react'
import { isMobile } from '../../helpers/mobileresize/Mobileresize'
import WebNotification from './web/index'
import MobileNotification from './android/index'

const index = () =>{
    if(isMobile){
        return(
            <div>
                <MobileNotification />
            </div>
        )
    }else{
        return(
            <div>
                <WebNotification />
            </div>
        )
    }
}

export default index