const regex = {
    email: new RegExp(
        '^(([^<>()\\[\\]\\\\.,;:\\s@]+(\\.[^<>()\\[\\]\\\\.,;:\\s@]+)*)|(.+))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
    ),
    number: new RegExp('^[0-9]+$'),
    character: new RegExp('^[a-zA-Z0-9_\\-\\.,/&@ ]+$'),
    string_allowed_character: new RegExp('^[a-zA-Z0-9_\\-\\.,/&@ ]+$')
};

export class Validators {
    static email(value, message) {
        if (value) {
            // isValid(false)
            const result = regex.email.test(value);
            if (!result) return { error: true, message };
        }
        // isValid(true)
        return false;
    }

    static required(value, message) {
        if (value.length === 0) {
            // console.log('value',value,'value length',value.length)
            // isValid(false)
            return { error: true, message };
        }
        // isValid(true)
        // console.log('value',value,'value length',value.length)
        return false;
    }

    static characterValidator(value, message) {
        if (value) {
            const result = regex.character.test(value);
            if (!result) {
                // isValid(false)
                return { error: true, message };
            } else {
                // isValid(true)
            }

        }
        return false
    }

    static string_matchValidator(value, message, errors) {
        if (value) {
            const result = regex.string_allowed_character.test(value);
            if (!result) {
                // isValid(false)
                return { error: true, message }
            } else {
                // isValid(true)
            }
        }
        return false
    }

    static minimum_length_required(value, message, errors) {
        if (value.length < 3) {
            // isValid(false)
            return { error: true, message };
        } else {
            // isValid(true)
        }
        return false;
    }

    static number_required(value, message, errors) {
        if (/^\d+$/.test(value) === false) {
            // isValid(false)
            return { error: true, message };
        } else if (value.length !== 10) {
            // isValid(false)
            return { error: true, message };
        } else {
            // isValid(true)
        }
        // console.log('value',value,'value length',value.length)
        return false;
    }

    static pin_code_required(value, message, errors) {
        if (/^\d+$/.test(value) === false) {
            // isValid(false)
            return { error: true, message };
        } else if (value.length !== 6) {
            // isValid(false)
            return { error: true, message };
        } else {
            // isValid(true)
        }
        return false;
    }

    static only_character_and_space(value, message, errors) {
        if (regex.character.test(value) === false) {
            // isValid(false)
            return { error: true, message };
        } else {
            // isValid(true)
        }
        return false;
    }

}

export const validateInput = (validators, value) => {
    if (validators && validators.length) {
        for (let i = 0; i < validators.length; i++) {
            // console.log('anna',validators[i].check)
            const error = validators[i].check(value, validators[i].message, validators[i].isValid);
            if (error) {
                // console.log('error krishna',error,validators[i].message)
                return error;
            }
        }
    }
    return false;
};


export const validateEmail = (validators, value) => {
    if (validators && validators.length) {
        for (let i = 0; i < validators.length; i++) {
            const error = validators[i].check(value, validators[i].message, validators[i].isValid);
            if (error) {
                // console.log('error krishna',error,validators[i].message)
                return error;
            }
        }
    }
}

export const validateName = (validators, value) => {
    if (validators && validators.length) {
        for (let i = 0; i < validators.length; i++) {
            const error = validators[i].check(value, validators[i].message, validators[i].isValid);
            if (error) {
                // console.log('error krishna',error,validators[i].message)
                return error;
            }
        }
    }
}

