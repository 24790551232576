import React,{useState,useEffect} from 'react'
import ITMain from './ITMain'
import { Drawer,Select, Tooltip } from 'antd'
import { Itheadings, ItSmallText, SmallText } from '../../helpers/constants/ConstantsText'
import TextInput from '../../components/ItComponents/TextInput'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import axios from 'axios'
import { gray_dark_1, theme_color } from '../../helpers/constants/ConstantColor'
import PrimaryButton, { PrimaryButtonAdd } from '../../components/buttons/PrimaryButton'
import toast, { Toaster } from 'react-hot-toast'
import { IconButton } from '@mui/material'
import { BiEditAlt } from 'react-icons/bi'
import { MdOutlineDelete } from 'react-icons/md'
import { base_url } from '../../App'
import { IoClose } from 'react-icons/io5'


const { Option } = Select;

function AssetsAssign() {

  const [data,setdata] = useState({id:null,user_id:'',laptop:'',mouse:'',keyboard:'',adapters:'',harddisk:''})  
  const [error,seterror] = useState({user_id:'',laptop:'',mouse:'',keyboard:'',adapters:'',harddisk:''})  
  const [attributes,setattributes] = useState([])
  const [modal,setmodal] = useState(false)

  const [arr_data,setarr_data] = useState([]) 
  const [pagiantion,setpagination] = useState() 

  useEffect(()=>{
    getdata()
    getattributes()
  },[])



  const getdata = () =>{
    axios.get('it/user_assets/get')
    .then((res)=>{
        setpagination(res.data.pagination)
        setarr_data(res.data.datas)
    }).catch((err)=>{
        // console.log('err',err)
    })
  }

  const getattributes = () =>{
    axios.get('it/user_assets/get_attribute')
    .then((res)=>{
        let datas = res.data.data
        let laptop = []
        let mouse = []
        let keyboard = []
        let harddisk = []
        let adapters = []
        datas.laptop.forEach((l)=>{
            laptop.push({id:l._id,name:l.laptop_id})
        })
        datas.keyboard.forEach((l)=>{
            keyboard.push({id:l._id,name:l.keyboard_id})
        })
        datas.mouse.forEach((l)=>{
            mouse.push({id:l._id,name:l.model_id})
        })
        datas.harddisk.forEach((l)=>{
            harddisk.push({id:l._id,name:l.disk_capacity})
        })
        datas.adapters.forEach((l)=>{
            console.log('l.adapter_name',l.adapter_name)
            adapters.push({id:l._id,name:l?.adapter_name})
        })

        setattributes({laptop,mouse,keyboard,harddisk,adapters})
    }).catch((err)=>{
        // console.log('err',err)
    })
  }


  function getFields(input, field) {
    console.log('input',input)
    console.log('field',field)
    if(input.length >= 1){
        var output = [];
        for (var i=0; i < input.length ; ++i)
            output.push(input[i][field]);
        return output;
    } 
    return []
   
  }

  const addasset = () =>{
    let laptop = []
    data.laptop.forEach((l)=>{
        laptop.push(l.id)
    })

    let send_data = {
        user_id:data?.user_id,
        adapters:data?.adapters?.id,
        harddisk:data?.harddisk?.id,
        keyboard:data?.keyboard?.id,
        laptop:laptop,
        mouse:data?.mouse?.id,
    }

    axios.post('it/user_assets/create',send_data)
    .then((res)=>{
        toast.success(res.data.message)
        getdata()
        setdata({id:null,user_id:'',laptop:'',mouse:'',keyboard:'',adapters:'',harddisk:''})
        setmodal(false)
    }).catch((err)=>{
        // console.log('err.data',err.response)
    })
  }

  const updateasset = () =>{
    let laptop = []
    data.laptop.forEach((l)=>{
        laptop.push(l.id)
    })

    let send_data = {
        _id:data?.id,
        user_id:data?.user_id,
        adapters:data?.adapters?.id,
        harddisk:data?.harddisk?.id,
        keyboard:data?.keyboard?.id,
        laptop:laptop,
        mouse:data?.mouse?.id,
        'method':'_PUT'
    }

    axios.put(`it/user_assets/update/${data.id}`,send_data)
    .then((res)=>{
        console.log('res.data here',res.data)
        toast.success(res.data.message)
        getdata()
        setdata({id:null,user_id:'',laptop:'',mouse:'',keyboard:'',adapters:'',harddisk:''})
        setmodal(false)
    }).catch((err)=>{
        console.log('err.data',err.response.data)
    })
  }

  const editassets = (v) => {
     let adapters = {}
     let harddisk = {}
     let laptop = []
     let mouse = {}
     let keyboard = {}

     v.adapters.forEach((a)=>{
        adapters = {id:a._id,name:a.adapter_name} 
     })

     v.keyboard.forEach((a)=>{
        keyboard = {id:a._id,name:a.keyboard_id} 
     })

     v.harddisk.forEach((a)=>{
        harddisk = {id:a._id,name:a.disk_capacity}
     })

     v.laptop.forEach((a)=>{
        laptop.push({id:a._id,name:a.laptop_id})
     })

     v.mouse.forEach((a)=>{
        mouse = {id:a.id,name:a.model_id}
     })
   
     setdata({...data,id:v._id,user_id:v?.user_id?._id,adapters,harddisk,laptop,mouse,keyboard})
     setmodal(true)
  }

  const deleteassets = (v) =>{
    axios.delete(`it/user_assets/delete/${v}`)
    .then((res)=>{
        getdata()
        toast.success(res.data.message)
        setdata({id:null,user_id:'',laptop:'',mouse:'',keyboard:'',adapters:'',harddisk:''})
        setmodal(false)
    }).catch((err)=>{
        // console.log('err.data',err.response)
    })
  }

  const handleChange = (v,v1) => {
    console.log('v here',v,'v1 here',v1)
     if(v1 === 'laptop'){
        let laptop = [...data.laptop]
        
        // if(v.length > 0 && laptop.length > 1){
        //     laptop.push(attributes.laptop.find(v2=> v2.id == v[v?.length-1]))
        // }else{
        //     laptop = []
        // }
        setdata({...data,laptop:laptop})
     }
  }


  const search_user =async (v)=>{
    if(v.length > 2){
         axios.get(`it/user_assets/search_user?search_text=${v}`)
        .then((res)=>{
            console.log("user",res.data)
        })
    }
  }

  
  return (
    <ITMain>
       <Toaster />
      

      <Drawer visible={modal} closable={false} width={300} >
       

        <div style={{marginLeft:'-5px'}}>

            <Tooltip title="Close" >
                <IconButton style={{padding:'2px',marginTop:'-5px',position:'absolute',right:-10,top:-10}}>
                    <IoClose  onClick={()=>setmodal(!modal)} style={{ cursor: 'pointer',color:gray_dark_1, }} size={16} />
                </IconButton>
            </Tooltip>
           <p style={{fontSize:'10px',fontWeight:'500',background:'#c9ffe2',padding:'4px',marginTop:'0px'}}>Use the below form to create or update the keyboard for your employees</p>

            <ItSmallText val={'User'} fontSize={12.5} marginfun={'5px 0px 5px 0px'}/>
            <Select value={data.user_id} mode="multiple" onSearch={(v)=>search_user(v)} onChange={(v)=>{handleChange(v,'laptop');seterror({...error,laptop:''})}} style={{width:'100%',borderLeft:`3px solid ${theme_color}`,borderRadius:'2px'}} >
                    {attributes?.laptop?.map((r)=>(
                        <Option key={r} value={r?.id} style={{textTransform:'capitalize'}}>{r?.name}</Option>
                    ))}
            </Select>
            <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                {(error?.stage) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.stage  }</p>
            </div>

            {/* setdata({...data,laptop:attributes.laptop.find((b)=>b?.id == v)}) */}
            <ItSmallText val={'Laptop'} fontSize={12.5} marginfun={'5px 0px 5px 0px'} />
            <Select value={getFields(data.laptop,'name')} mode="multiple" onChange={(v)=>{handleChange(v,'laptop');seterror({...error,laptop:''})}} style={{width:'100%',borderLeft:`3px solid ${theme_color}`,borderRadius:'2px'}} >
                    {attributes?.laptop?.map((r)=>(
                        <Option key={r} value={r?.id} style={{textTransform:'capitalize'}}>{r?.name}</Option>
                    ))}
            </Select>

            <ItSmallText val={'Mouse'} fontSize={12.5} marginfun={'5px 0px 5px 0px'} />
            <Select value={data.mouse?.name} onChange={(v)=>{setdata({...data,mouse:attributes.mouse.find((b)=>b?.id == v)});seterror({...error,mouse:''})}} style={{width:'100%',borderLeft:`3px solid ${theme_color}`,borderRadius:'2px'}} >
                    {attributes?.mouse?.map((r)=>(
                        <Option key={r} value={r?.id} style={{textTransform:'capitalize'}}>{r?.name}</Option>
                    ))}
            </Select>


            <ItSmallText val={'Keyboard'} fontSize={12.5} marginfun={'5px 0px 5px 0px'} />
            <Select value={data.keyboard?.name} onChange={(v)=>{setdata({...data,keyboard:attributes.keyboard.find((b)=>b?.id == v)});seterror({...error,keyboard:''})}} style={{width:'100%',borderLeft:`3px solid ${theme_color}`,borderRadius:'2px'}} >
                    {attributes?.keyboard?.map((r)=>(
                        <Option key={r} value={r?.id} style={{textTransform:'capitalize'}}>{r?.name}</Option>
                    ))}
            </Select>


            <ItSmallText val={'Adapters'} fontSize={12.5} marginfun={'5px 0px 5px 0px'} />
            <Select value={data.adapters?.name} onChange={(v)=>{setdata({...data,adapters:attributes.adapters.find((b)=>b?.id == v)});seterror({...error,adapters:''})}} style={{width:'100%',borderLeft:`3px solid ${theme_color}`,borderRadius:'2px'}} >
                    {attributes?.adapters?.map((r)=>(
                        <Option key={r} value={r?.id} style={{textTransform:'capitalize'}}>{r?.name}</Option>
                    ))}
            </Select>

            <ItSmallText val={'HardDisk'} fontSize={12.5} marginfun={'5px 0px 5px 0px'} />
            <Select value={data.harddisk?.name} onChange={(v)=>{setdata({...data,harddisk:attributes.harddisk.find((b)=>b?.id == v)});seterror({...error,harddisk:''})}} style={{width:'100%',borderLeft:`3px solid ${theme_color}`,borderRadius:'2px'}} >
                    {attributes?.harddisk?.map((r)=>(
                        <Option key={r} value={r?.id} style={{textTransform:'capitalize'}}>{r?.name}</Option>
                    ))}
            </Select>


            <div style={{marginLeft:'-5px'}} onClick={data.id === null ? addasset : updateasset}>
                <PrimaryButton btn_name={data.id === null ? 'ASSIGN ASSETS' : "UPDATE ASSETS"}   />
            </div>
        </div>  
      </Drawer>


      

      <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',marginTop:'-10px',borderBottom:`1px solid #f2f2f2`,maxHeight:'50px'}}>
                <Itheadings val={`Assign Asset (${pagiantion?.total})`} />
                
                <div style={{display:'flex',alignItems:'center',flexDirection:'row'}}>
                 
                    <div style={{width:'240px'}} onClick={()=>{setmodal(true)}} > 
                    <PrimaryButtonAdd btn_name={'Add Asset'}  />
                    </div>
                </div>

                

                 
                </div>
                
                <div style={{backgroundColor:'#fff',width:'98%'}}>
            
                        <div className='row'>
                        {arr_data?.map((p)=>(
                        <div className='col-md-3'>
                        <div className='card_processor '  key={p._id} style={{display:'flex',margin:'5px 0px',cursor:'pointer',paddingLeft:'10px',width:'100%',borderRadius:'2px',border:'0.5px solid #ddd',padding:'10px',display:'flex',position:'relative'}}>
                            {/* <span style={{position:'absolute',right:0,top:0,fontSize:'8px',padding:'2px 10px',textAlign:'center',fontWeight:'800',background:(p.device_stage.stage !== 'damaged' &&  p.device_stage.stage !== 'under-service') ? '#72dba1' : '#c4655c',borderBottomLeftRadius:'21px',textTransform:'uppercase'}}>{p.device_stage.stage}</span> */}
                            <img src={`${base_url}${p.image}`} style={{minWidth:'80px',maxWidth:'80px',height:'60px',objectFit:'contain'}} />
                            <div style={{paddingTop:'1px',marginLeft:'3%'}}>
                            <SmallText fontWeight={'600'} fontSize={13} val={`${p?.user?.brand_name} laptop`} marginfun={'0px 0px 0px 0px'} />
                            <SmallText fontWeight={'500'} fontSize={10} val={`Model Id : ${p?.model_id}`} marginfun={'0px 0px 0px 0px'} />
                            <SmallText fontWeight={'500'} fontSize={10} val={`Type  : ${p?.type?.type}`} marginfun={'0px 0px 0px 0px'} />
                            <div style={{display:'flex',marginLeft:'-10px',marginTop:'5px'}} >
                            <Tooltip title="Delete" onClick={()=>deleteassets(p._id)}>
                                <IconButton style={{marginTop:'-5px'}} >
                            <MdOutlineDelete  size={16} style={{color:'#000'}} />
                            </IconButton>
                            </Tooltip>
                            <Tooltip title="Edit"  onClick={()=>editassets(p)}>
                                <IconButton style={{marginTop:'-5px',}} >
                            <BiEditAlt  size={16} style={{color:'#000'}} />
                            </IconButton>
                            </Tooltip>
                            </div>
                            </div>
                        </div>
                        </div>
                        ))}
                        </div>
                        </div>

    </ITMain>
  )
}

export default AssetsAssign