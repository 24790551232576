import React,{useState} from 'react'
import FmsDashboardMainRoot from '../FmsDashboardMainRoot'
import axios from "axios";
import Dragger from "antd/lib/upload/Dragger";
import toast,{ Toaster } from 'react-hot-toast';
import { authHeader } from '../../../helpers/usertoken/UserToken';
import readXlsxFile from 'read-excel-file'
import { MdOutlineDeleteOutline } from 'react-icons/md'

import PrimaryButton, { PrimaryButtonAdd, PrimaryButtonFilter } from '../../../components/buttons/PrimaryButton'
import { Input } from "antd";
import { BoldText1 } from '../../../helpers/constants/ConstantsText';

function UploadExcel() {

    const [file,setfile] = useState(null)
    const [loader, setloader] = useState(false)
    const [employee_id, setemployee_id] = useState('')
    const [xl_valid, setxl_valid] = useState(false)


    const handleUpload = (v) => {
        setfile(v.fileList[0].originFileObj)

        
        readXlsxFile(v.fileList[0].originFileObj).then((rows) => {

            // if (rows[0]?.sort()?.join(',') === xl_temp_format?.sort()?.join(',')) {
        toast.success('EXCEL format validated')
        //         setxl_valid(true)
        //     } else {
        //         toast.success('EXCEL format invalid')
        //         setxl_valid(false)

        //     }
        })

    }
    const reselectfile = () => {
        setfile()
    }
    function uploadxl() {
        if (file?.name?.split('.')[file?.name?.split('.').length - 1] === 'xlsx') {
            authHeader['headers']['Content-Type'] = 'multipart/form-data'
            const data = new FormData()
            data.append('file', file)
            setloader(true)
            axios.post(`fms/basicemployee/excel-import`, data, authHeader)
                .then((res) => {
                    if (res.status === 201) {
                        toast.success(res.data.msg)
                    } else if (res.status === 206) {
                        toast.success('Partially data uploaded')
                    }
                    setloader(false)
                    setfile()
                    setemployee_id('')
                })
        } else {
            toast.error('Invalid file format unable to upload')
        }

    }

  return (
    <FmsDashboardMainRoot>
        <div style={{width:'96%'}}>
            <div style={{display:'flex',borderBottom:'1px solid #eee',alignItems:'center',width:'100%',justifyContent:'space-between'}}>
            <BoldText1 val={`Upload Excel`} />
            </div>    

                <div style={{margin:'30px 0PX'}}>
                    <Toaster />
                   <div style={{width:'300px'}}>

                    {file === undefined || file === null  ?
                        <Dragger style={{ marginTop: '10px' }}
                            onChange={handleUpload}
                            beforeUpload={() => false}
                            showUploadList={false}>
                            
                            <div style={{display:'flex',alignItems:'center',flexDirection:'column'}}>
                            <img src={'https://cdn-icons-png.flaticon.com/512/2504/2504768.png'} style={{ width: '70px', objectFit: 'contain',alignItems:'center',display:'flex',justifyItems:'center',justifySelf:'center',alignSelf:'center' }} />
                            <p className="ant-upload-text" style={{fontWeight:'600',fontSize:'14px',marginTop:'10px'}}>Click or drag file to this area to upload</p>
                            <p className="ant-upload-hint" style={{fontSize:'13px'}}>
                                Support for a single file upload. Strictly used from uploading company data
                            </p>
                            </div>
                        </Dragger>
                        :
                        <div>
                            <div style={{ display: 'flex', alignItems: 'center', border: '1px solid lightgray', padding: '2px 10px', position: 'relative', marginTop: '20px' }}>
                                <img src={'https://cdn-icons-png.flaticon.com/512/2504/2504768.png'} style={{ width: '20px', objectFit: 'contain' }} />
                                <div style={{ marginLeft: '10px' }}>
                                    <h6 style={{ fontSize: '14px', fontWeight: '600', marginBottom: '2px' }}>{file?.name}</h6>
                                    <h6 style={{ fontSize: '10px', color: 'gray' }}>{file?.size} bytes</h6>
                                </div>
                                <MdOutlineDeleteOutline size={22} style={{ position: 'absolute', right: '10px' }} onClick={reselectfile} />
                            </div>

                            <div onClick={uploadxl}>
                                <PrimaryButton btn_name={'Upload Xl'} />
                            </div>  
                        </div>}
                        </div>
                        </div>

        </div>
    </FmsDashboardMainRoot>
  )
}

export default UploadExcel