import React from 'react';
import ItDashboard from '../dashboard/It_dashboard/web/It_dashboard';


function ITMain(props){
    return(
        <div style={{display:'flex',flexDirection:'row',marginTop:'0px',paddingTop:'0px'}}>
        <div style={{flex:0.03,boxSizing:'border-box',width:'100%'}}>
          <ItDashboard />
        </div> 
        <div style={{flex:0.97,boxSizing:'border-box',width:'100%',maxHeight:'100vh',overflowY:'scroll',marginLeft:'190px',paddingTop:'55px',}}>
            {props.children}
        </div>

      </div>
    )
}

export default ITMain