import React, { useEffect, useState } from 'react'
import FmsDashboardMainRoot from '../FmsDashboardMainRoot'
import { useLocation, useNavigate } from 'react-router-dom'
import { base_url, local_base_url } from '../../../App'
import moment from 'moment'
import axios from 'axios'
import { authHeader } from '../../../helpers/usertoken/UserToken'
import { Col, Row,Input,Select } from 'antd'
import { SmallText } from '../../../helpers/constants/ConstantsText'
import { theme_color } from '../../../helpers/constants/ConstantColor'
import PrimaryButton, { SecondaryButton } from '../../../components/buttons/PrimaryButton'
import { useSelector } from 'react-redux'
import Inputerror from '../../../components/errors/Inputerror'
import { Toaster, toast } from 'react-hot-toast'
import {BsGrid3X2} from 'react-icons/bs'
import {AiOutlineBorderlessTable,AiOutlineDownload} from 'react-icons/ai'
import fileDownload from "js-file-download";

function AddAttendanceForSelectedEmployee() {

  const user = useSelector(state=>state.Auth)
  const {state} = useLocation()


  // console.log("state",state)
  const navigation = useNavigate()
  const { TextArea } = Input;


  const [shifts,setshifts] = useState([])
  const [stages,setstages] = useState([])
  const [year,setyear] = useState([])
  const [OT,setOT] = useState(0)
  const [step,setstep] = useState(1)
  const [day_start,setday_start] = useState(0)

  const [monthyear,setmonthyear] = useState({month:'',year:''})

  const [attendance,setattendance] = useState({})
  const [days,setdays] = useState()
  const [add_attendance,setadd_attendance] = useState({year:'',month:'',date:'',field_officer:'',shift:[],employee_stage:'',modified_by:'',modified_reason:'',ot:false,ot_hrs:''})
  const [add_attendance_error,setadd_attendance_error] = useState({year:'',month:'',date:'',field_officer:'',shift:'',employee_stage:'',modified_by:'',modified_reason:'',ot_hrs:''})
  

  // const []

  var month = [{id:0,value:"January"},{id:1,value:"February"},{id:2,value:"March"},{id:3,value:"April"},{id:4,value:"May"},{id:5,value:"June"},{id:6,value:"July"},{id:7,value:"August"},{id:8,value:"September"},{id:9,value:"October"},{id:10,value:"November"},{id:11,value:"December"}];
  var week = ['Sunday','Moday','Tuesday','Wednesday','Thursday','Friday','Saturday']

  useEffect(()=>{
    getOptions()
    setmonthyear({year:new Date().getFullYear(),month:new Date().getMonth()})
    setadd_attendance({...add_attendance,date:new Date( (Date.now())).toISOString()?.slice(0,10),year:new Date().getFullYear(),month:new Date().getMonth()})
    daysInMonth(new Date().getMonth(),new Date().getFullYear())
    getAttendance()
  },[])


  useEffect(()=>{
    getDatabasedOnSelected()
  },[monthyear])

  
  // console.log("date",)

  function getDatabasedOnSelected(){
    daysInMonth(monthyear?.month,monthyear?.year)
    setday_start(new Date(monthyear?.year,monthyear?.month,1).getDay())
    axios.post(`fms/basicemployee/get_attendance`,{year:monthyear?.year,month:monthyear?.month + 1,fms_employee:state._id},authHeader)
    .then((res)=>{
       setattendance(res?.data?.data[0])
       let hours = 0
       let  d = res?.data?.data[0]
       d?.attendance?.forEach((a)=>{
        if(a?.ot){
         hours += parseInt(a?.ot_hrs)
        }
       })
       setOT(hours)


      //  console.log("d?.attendance",d?.attendance)
       if(d?.attendance?.length > 0){
              let today = new Date().toISOString().slice(0,10) + 'T00:00:00.000Z'
              const check_data_exist = d?.attendance?.find(f=>f.date == today)

              // console.log("check_data_exist 111",check_data_exist)
              if(check_data_exist !== undefined && shifts?.length > 0 && stages?.length > 0){
                let arr = []

              
                check_data_exist?.shift?.forEach((d)=>{
                    arr.push(shifts?.find(s=>s.label === d))
                })

                setadd_attendance({
                  ...add_attendance,
                  date:check_data_exist?.date?.slice(0,10),
                  shift:arr,
                  stage:stages?.find(f=>f.label === check_data_exist?.employee_stage),
                  modified_reason:check_data_exist?.modified_reason,
                  ot:check_data_exist?.ot,
                  ot_hrs:check_data_exist?.ot_hrs,
                  id:check_data_exist?._id
                })
              }
      }
      //  console.log("add_attendance 111",add_attendance)


      
    })
  }

 async function getAttendance(){

    setday_start(new Date(new Date().getFullYear(),new Date().getMonth(),1).getDay())
      await  axios.post(`fms/basicemployee/get_attendance`,{year:new Date().getFullYear(),month:new Date().getMonth() + 1,fms_employee:state._id},authHeader)
      .then((res)=>{


         setattendance(res?.data?.data[0])

         let hours = 0
         let  d = res?.data?.data[0]

        //  console.log("d here okk na",d)
         d?.attendance?.forEach((a)=>{
          if(a?.ot){
          hours += parseInt(a?.ot_hrs)
          }
        })
        setOT(hours)

        if(d?.attendance !== undefined &&  d?.attendance?.length > 0 ){
          let today = new Date().toISOString().slice(0,10) + 'T00:00:00.000Z'
          const check_data_exist = d?.attendance?.find(f=>f.date == today)

          // console.log("today",today)
          // console.log("check_data_exist",check_data_exist)
          if(check_data_exist !== undefined && shifts?.length > 0 && stages?.length > 0){
           let arr = []
           check_data_exist?.shift?.forEach((d)=>{
               arr.push(shifts?.find(s=>s.label === d))
           })
   
           setadd_attendance({
             ...add_attendance,
             date:check_data_exist?.date?.slice(0,10),
             shift:arr,
             stage:stages?.find(f=>f.label === check_data_exist?.employee_stage),
             modified_reason:check_data_exist?.modified_reason,
             ot:check_data_exist?.ot,
             ot_hrs:check_data_exist?.ot_hrs,
             id:check_data_exist?._id
           })
           }
          }    
        })
  }

  async function getOptions(){
    await  axios.get(`fms/attendanceshift/get`,authHeader)
      .then((res)=>{
        let arr = []
        res?.data?.datas?.filter((d)=>{
          // arr.push({value:d?._id,label:`${d?.name} [ST : ${d?.start_time} - ET : ${d?.end_time} ]`})
          arr.push({value:d?._id,label:`${d?.name}`})
        })
        setshifts(arr)
        getAttendance()
      }).catch((err)=>{
  
      })

     await axios.get(`fms/attendancestage/get`,authHeader)
      .then((res)=>{
        let arr = []
        res?.data?.datas?.filter((d)=>{
          arr.push({value:d?._id,label:`${d?.name}`})
        })
        setstages(arr)
        getAttendance()
      }).catch((err)=>{
  
      })

      await  axios.get(`fms/attendanceyear/get`,authHeader)
      .then((res)=>{
        let arr = []
        res?.data?.datas?.filter((d)=>{
          arr.push({value:d?._id,label:`${d?.name}`})
        })
        setyear(arr)
        getAttendance()
      }).catch((err)=>{
  
      })
  }

  function daysInMonth (month, year) {
    setdays(new Date(year, month, 0).getDate())
  }


  function addAttendance(){
    if(!add_attendance?.date){
      setadd_attendance_error({...add_attendance_error,date:'This Field is required'})
    }else if(add_attendance?.shift?.length === 0){
      setadd_attendance_error({...add_attendance_error,shift:'This Field is required'})
    }else if(add_attendance?.stage?.label === undefined){
      setadd_attendance_error({...add_attendance_error,stage:'This Field is required'})
    }else if(add_attendance?.ot  && !add_attendance?.ot_hrs){
      setadd_attendance_error({...add_attendance_error,ot_hrs:'This Field is required'})
    }else{
      const send_data = {
        date:add_attendance?.date,
        fmsemployee:state?._id,
        shift:add_attendance.shift?.map((m)=>m.label),
        employee_stage:add_attendance?.stage?.label,
        field_officer:user?.id,
        ot:add_attendance?.ot,
        ot_hrs:add_attendance?.ot_hrs,
        client:(state?.department_branch_were_deployed?._id !== '' && state?.department_branch_were_deployed?._id !== undefined)  ? state?.department_branch_were_deployed?._id  : 'Not Exist'
      }
      axios.post(`fms/basicemployee/add_attendance`,send_data,authHeader)
      .then((res)=>{
        if(res?.status === 201){
          toast.success(res?.data?.message)
          setadd_attendance({...add_attendance,year:'',month:'',date:'',field_officer:'',shift:[],stage:null,modified_by:'',modified_reason:'',ot:false,ot_hrs:''})
          setadd_attendance_error({...add_attendance_error,year:'',month:'',date:'',field_officer:'',shift:[],stage:'',modified_by:'',modified_reason:'',ot_hrs:''})
          getAttendance()
        }
      }).catch((err)=>{

      })
      }
  }

  function updateAttendance(){
    if(!add_attendance?.date){
      setadd_attendance_error({...add_attendance_error,date:'This Field is required'})
    }else if(add_attendance?.shift?.length === 0){
      setadd_attendance_error({...add_attendance_error,shift:'This Field is required'})
    }else if(add_attendance?.stage?.label === undefined){
      setadd_attendance_error({...add_attendance_error,stage:'This Field is required'})
    }else if(add_attendance?.ot  && !add_attendance?.ot_hrs){
      setadd_attendance_error({...add_attendance_error,ot_hrs:'This Field is required'})
    }else if(!add_attendance?.modified_reason){
      setadd_attendance_error({...add_attendance_error,modified_reason:'This Field is required'})
    }else{
      const send_data = {
        date:add_attendance?.date,
        fmsemployee:state?._id,
        shift:add_attendance.shift?.map((m)=>m.label),
        employee_stage:add_attendance?.stage?.label,
        ot:add_attendance?.ot,
        ot_hrs:add_attendance?.ot_hrs,
        modified_by:user?.id,
        modified_reason:add_attendance?.modified_reason,
        // client:(state?.department_branch_were_deployed?.client_name !== '' && state?.department_branch_were_deployed?.client_name !== undefined)  ? state?.department_branch_were_deployed?.client_name  : 'Not Exist'
      }
      axios.post(`fms/basicemployee/update_attendance/${add_attendance?.id}`,send_data,authHeader)
      .then((res)=>{
        if(res?.status === 201){
          toast.success(res?.data?.message)
          setadd_attendance({...add_attendance,year:'',month:'',date:'',field_officer:'',shift:[],stage:null,modified_by:'',modified_reason:'',ot:false,ot_hrs:''})
          setadd_attendance_error({...add_attendance_error,year:'',month:'',date:'',field_officer:'',shift:[],stage:'',modified_by:'',modified_reason:'',ot_hrs:''})
          getAttendance()
        }
      }).catch((err)=>{

      })
      }
  }


  function addshifts(e){
    let arr = []
    e?.forEach(k=>{
        arr?.push(shifts?.find((s)=>s?.value === k))
    })
    setadd_attendance({...add_attendance,shift:arr})
  }


  function editcheck(i){
    if(attendance?.attendance_finalized){
      toast.success("Attendance data has been finalized can't modify")
    }else{
      const date = new Date(monthyear?.year,monthyear?.month,i+2).toISOString()?.slice(0,10)+'T00:00:00.000Z'

      if(new Date(date) < new Date()){
       
        if(date >= (state?.date_of_joining + 'T00:00:00.000Z')){
          const check_data_exist = attendance?.attendance?.find(f=>f.date == date)
          if(check_data_exist !== undefined){
           let arr = []
           check_data_exist?.shift?.forEach((d)=>{
               arr.push(shifts?.find(s=>s.label === d))
           })
   
           setadd_attendance({
             ...add_attendance,
             date:check_data_exist?.date?.slice(0,10),
             shift:arr,
             stage:stages?.find(f=>f.label === check_data_exist?.employee_stage),
             modified_reason:check_data_exist?.modified_reason,
             ot:check_data_exist?.ot,
             ot_hrs:check_data_exist?.ot_hrs,
             id:check_data_exist?._id
           })
          }else{
           setadd_attendance({
             date:date?.slice(0,10),field_officer:'',shift:[],employee_stage:'',modified_by:'',modified_reason:'',ot:false,ot_hrs:''
           })  
          }
        }else{
        toast.error('Select Date less than User DOJ')
        }
      }else{
        toast.error('Dont Update Future')
      }
    }
  }

  // console.log("monthyear",monthyear)
  async function downloadReport(){
    axios.get(`fms/basicemployee/getEmployeeReport?month=${monthyear?.month + 1}&year=${monthyear?.year}&fms_employee=${state?._id}`,authHeader)
    .then((res)=>{
      let path = res.data.path.replace('public/','')
      handleDownload(`${local_base_url}${path}`,path.split('/')[path.split('/')?.length - 1])
    })
    .catch((err)=>{

    })  
  }

  const handleDownload = (url, filename) => {
    // console.log('url',url,'filename',filename)
    axios.get(url, {
        responseType: 'blob',
    })
        .then((res) => {
            fileDownload(res.data, filename)
            toast.success("Employee Excel Downloaded") 
        })
  }


  return (
    <FmsDashboardMainRoot>
      <Toaster />
        <div style={{width:'97%',display:'flex',flexDirection:'row'}}>

            <div  style={{width:'20%',minHeight:'90vh',borderRight:'1px solid #eee'}}>
              <h6 style={{fontWeight:'900',fontSize:'14px',paddingBottom:'4px',marginRight:'10px',borderBottom:'1px solid #eee'}}> <span style={{minWidth:'10px',minHeight:'10px',background:'#ddd'}}></span> Employee Details</h6>
               
               <div style={{display:'flex',borderBottom:'1px solid #eee',padding:'10px 0px'}}>
                <img src={`${base_url}${state?.photo}`} style={{width:'30px',height:'30px',borderRadius:'50%',objectFit:'cover',}} /> 
                <div style={{marginLeft:'10px'}}>
                  <h6 style={{marginBottom:'0px',fontSize:'13px'}}> <span style={{color:'#000',fontWeight:'700'}}>{state?.name}</span> / <span style={{color:'#000',fontWeight:'700'}}>{state?.employee_id}</span></h6>
                  <h6 style={{marginBottom:'0px',fontSize:'12px',color:'#bbb'}}>Designation :  <span style={{color:'#000'}}>{state?.designation[0]?.designation_name} </span> </h6>
                </div>
                </div>


                <h6 style={{marginBottom:'0px',fontSize:'11px',marginTop:'15px'}}>Date of Joining : <span style={{color:'#000',fontWeight:'700'}}>{moment(state?.date_of_joining)?.format('LL')}</span> </h6>
                <h6 style={{marginBottom:'0px',fontSize:'11px',marginTop:'4px'}}>Created At : <span style={{color:'#000',fontWeight:'700'}}>{moment(state?.createdAt)?.format('LLL')}</span> </h6>


               <div style={{opacity:attendance?.attendance_finalized ? 0.2 : 1,marginTop:'40px',borderTop:'1px solid #eee'}}>
                <h6 style={{fontWeight:'900',fontSize:'14px',marginTop:'15px',paddingBottom:'4px',marginRight:'10px',}}> <span style={{minWidth:'10px',minHeight:'10px',background:'#ddd'}}></span> Add Attendance</h6>


                <div style={{marginTop:'8px',width:'92%'}}>

                <div style={{width:'150px'}}>
                    <SmallText fontWeight={'600'} val={'Attendance Date'} />
                    </div>
                    {/* readOnly={true} */}
                    <Input  readOnly={true} maxLength={150} type="text"  value={add_attendance?.date} onChange={(e)=>setadd_attendance({...add_attendance,date:e.target.value})} />
                </div>

                <Inputerror message={add_attendance_error?.date} />



                <div style={{marginTop:'8px',width:'92%'}}>
                <div style={{width:'100%'}}>
                  <SmallText fontWeight={'600'} val={'Shift'} />
                  <Select mode="multiple" options={shifts} style={{borderLeft:`4px solid ${theme_color}`,width:'100%',zIndex:100,fontSize:'13px',fontWeight:'600'}} value={add_attendance?.shift}  onChange={(e)=>{addshifts(e)}} >
                  </Select>  
                </div>
                </div>

                <Inputerror message={add_attendance_error?.shift} />

                <div style={{marginTop:'8px',width:'92%'}}>
                <div style={{width:'100%'}}>
                  <SmallText fontWeight={'600'} val={'Stage'} />
                  <Select options={stages} style={{borderLeft:`4px solid ${theme_color}`,width:'100%',zIndex:100,fontSize:'13px',fontWeight:'600'}} value={add_attendance?.stage !== '' ? add_attendance?.stage?.label : null }  onChange={(e)=>{setadd_attendance({...add_attendance,stage:stages?.find((s)=>s?.value === e)})}} >
                  </Select>  
                </div>
                </div>
                <Inputerror message={add_attendance_error?.employee_stage} />




                <div style={{marginTop:'8px',marginRight:'10px',display:'flex'}}>
                <p className={add_attendance?.ot ? 'checked_here2': 'checked_here'} style={{marginRight:'10px'}} onClick={()=>setadd_attendance({...add_attendance,ot:!add_attendance.ot}) }>
                 {add_attendance?.ot && <p className='checked_here1' style={{background:theme_color}}></p>}
                </p>
                <SmallText fontWeight={'600'} val={'Over Time'}  />
                </div>

                
                {add_attendance?.ot &&
                <div style={{marginTop:'8px',width:'92%'}}>
                <div style={{width:'150px'}}>
                    <SmallText fontWeight={'600'} val={'OT Hrs'} />
                    </div>
                    <Input maxLength={150} type="number"  value={add_attendance?.ot_hrs} onChange={(e)=>setadd_attendance({...add_attendance,ot_hrs:e.target.value})} />
                </div>}

                <Inputerror message={add_attendance_error?.ot_hrs} />






                
                {(add_attendance?.id !== undefined && add_attendance?.id !== null) &&
                <div style={{marginTop:'8px',width:'92%'}}>
                <div style={{width:'150px'}}>
                    <SmallText fontWeight={'600'} val={'Modified Reason'} />
                    </div>
                    <TextArea  maxLength={150} type="text"  value={add_attendance?.modified_reason} onChange={(e)=>setadd_attendance({...add_attendance,modified_reason:e.target.value})} />
                </div>}

                <Inputerror message={add_attendance_error?.modified_reason} />


            

                <div style={{display:'flex'}}>
                  <PrimaryButton btn_name={'SAVE'} onClick={(add_attendance?.id !== undefined && add_attendance?.id !== null) ? updateAttendance : addAttendance} />
                  <SecondaryButton btn_name={'CANCEL'} onClick={()=>navigation(-1)} />
                </div>
                </div>

            </div>
            <div style={{width:'80%',margin:'0px 10px'}}>
              <div style={{margin:'5px 0px 30px 0px',display:'flex',alignItems:'center',justifyContent:'space-between',borderBottom:'1px solid #eee',paddingBottom:'10px'}}>
              <h6 style={{fontWeight:'600',fontSize:'14px'}}>{month[monthyear?.month]?.value} {monthyear?.year}</h6>


              <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>

              <div style={{width:'80px',border:'1px solid #eee',borderRadius:'2px',marginRight:'10px',height:'28px'}}>
              <Select value={monthyear?.year} showArrow={false} style={{width:'100%',fontSize:'14px',fontWeight:'800'}} size='small' options={year} onSelect={(v)=>{setmonthyear({...monthyear,year:year?.find(y=>y.value === v)?.label})}} bordered={false} /> 
              </div>
              <div style={{width:'80px',border:'1px solid #eee',borderRadius:'2px',marginRight:'10px',height:'28px'}}>
              <Select value={month[monthyear?.month]?.value} showArrow={false} style={{width:'100%',fontSize:'14px',fontWeight:'800'}} size='small' options={month} bordered={false} onSelect={(v)=>{setmonthyear({...monthyear,month:month?.find(y=>y.value === v)?.id})}} /> 
              </div>

              <div style={{display:'flex'}}>
                <h6 style={{marginRight:'10px',display:'flex',flexDirection:'row',fontSize:'10px'}}> <span style={{minWidth:'10px',minHeight:'3px',background:'#abffc1',color:'#abffc1',marginRight:'5px'}}>a</span> Present </h6>
                <h6 style={{marginRight:'10px',display:'flex',flexDirection:'row',fontSize:'10px'}}><span style={{minWidth:'10px',minHeight:'3px',background:'#ffc1b8',color:'#ffc1b8',marginRight:'5px'}}>a</span> Absent/Leave </h6>
                <h6 style={{marginRight:'10px',display:'flex',flexDirection:'row',fontSize:'10px'}}><span style={{minWidth:'10px',minHeight:'3px',background:'#ffebb5',color:'#ffebb5',marginRight:'5px'}}>a</span>Week OFF </h6>
              </div>

              <div style={{background:'#222f3e',border:'1px solid #eee',display:'flex'}} >
                
                
                <AiOutlineBorderlessTable onClick={()=>setstep(1)}  size={20} style={{background:step == 1 ? '#fff' : '#222f3e',color:step == 1 ? 'black' : '#fff',padding:'4px',cursor:'pointer'}}/>
                <BsGrid3X2 size={20} onClick={()=>setstep(2)} style={{background:step == 2 ? '#fff' : '#222f3e',color:step == 2 ? 'black' : '#fff',padding:'4px',cursor:'pointer'}} />
              </div>
              {/* {attendance?.attendance_finalized && */}
               <AiOutlineDownload onClick={downloadReport} size={20} style={{background:'#222f3e',color:'#fff',padding:'4px',cursor:'pointer',marginLeft:'5px',borderRadius:'2px'}}  />
              {/* } */}
              </div>
              </div>
              <Row  style={{marginTop:'50px'}}>
                {new Array(31 + (step === 1 ? day_start : 0)).fill('')?.map((d,i)=>(
                  <>
                  {/* <h1 style={{fontSize:'8px'}}>{(i+day_start) <= days ? '1' : '2'}</h1> */}
                  {step === 1 ?
                  <Col onClick={()=>editcheck(i-day_start)} span={3.4} style={{
                    background:(i < day_start || (i - day_start) >= days) ? '#fafafa' : attendance?.attendance?.filter((d)=>new Date(d?.date).getDate() - 1 === i-day_start)?.length > 0 ? (attendance?.attendance?.filter((d)=>new Date(d?.date).getDate() - 1 === i - day_start )[0].employee_stage === 'P' || attendance?.attendance?.filter((d)=>new Date(d?.date).getDate() - 1 === i -day_start )[0].employee_stage === 'OT') ? '#abffc1' : (attendance?.attendance?.filter((d)=>new Date(d?.date).getDate() - 1 === i-day_start )[0].employee_stage === 'A' ||  attendance?.attendance?.filter((d)=>new Date(d?.date).getDate() - 1 === i - day_start)[0]?.employee_stage === 'L') ? '#ffc1b8' : attendance?.attendance?.filter((d)=>new Date(d?.date).getDate() - 1 === i-day_start )[0].employee_stage === 'WO' ?  '#ffebb5' : '#fff' : '#fff' ,
                    width:'14.25%',
                    height:'90px',
                    padding:'2px',
                    cursor:'pointer',
                    borderRight: (![6,13,20,27,34]?.includes(i) && i!== (days + day_start)) && '1px solid #eee', 
                    borderBottom: ![30]?.includes(i-day_start) &&'1px solid #eee',
                    position:'relative'}}>
                 {[0,1,2,3,4,5,6]?.includes(i) &&  
                  <h6 style={{fontSize:'10px',background:'#fafafa',textTransform:'uppercase',position:'absolute',marginTop:'-30px'}}>{week[i]}</h6>
                  }

                  
                   {i >= day_start && <span style={{fontSize:'12px',fontWeight:'500'}}> <span style={{fontWeight:'600'}}>{i <= (days+day_start) && (i+1) - day_start}</span>  {i === day_start && month[monthyear?.month]?.value} {i === days + day_start - 1 && month[monthyear?.month]?.value} </span>}

         
                 

                  
                   <>
                   {attendance?.attendance?.filter((d)=>new Date(d?.date).getDate() - 1 === i-day_start )?.length > 0 &&
                    <div style={{padding:'5px 5px'}}>
                   {(attendance?.attendance?.filter((d)=>new Date(d?.date).getDate() - 1 === i-day_start )[0].ot && attendance?.attendance?.filter((d)=>new Date(d?.date).getDate() - 1 === i-day_start )[0].ot_hrs) && <span  style={{fontSize:'10px',padding:'0px 4px',background:'#fff',position:'absolute',right:0,top:0}}>OT : {attendance?.attendance?.filter((d)=>new Date(d?.date).getDate() - 1 === i-day_start )[0]?.ot_hrs} hrs</span>}

                   {attendance?.attendance?.filter((d)=>new Date(d?.date).getDate() - 1 === i-day_start )?.length > 0 &&  attendance?.attendance?.filter((d)=>new Date(d?.date).getDate() - 1 === i-day_start )[0]?.added_by === 'InHouse-Employee' && 
                    <>
                    <span style={{fontSize:'11px',marginBottom:'0px',marginTop:'-3px'}}>Field Officer :</span>  
                    <h6 style={{fontWeight:'600',fontSize:'10px',marginBottom:'0px',marginTop:'-3px'}}>{attendance?.attendance?.filter((d)=>new Date(d?.date).getDate() - 1 === i-day_start )?.length > 0 &&  attendance?.attendance?.filter((d)=>new Date(d?.date).getDate() - 1 === i-day_start )[0]?.field_officer?.name} / {attendance?.attendance?.filter((d)=>new Date(d?.date).getDate() - 1 === i-day_start )?.length > 0 &&  attendance?.attendance?.filter((d)=>new Date(d?.date).getDate() - 1 === i-day_start )[0]?.field_officer?.employee_id}</h6>
                    </>}
                    {attendance?.attendance?.filter((d)=>new Date(d?.date).getDate() - 1 === i-day_start )?.length > 0 &&  attendance?.attendance?.filter((d)=>new Date(d?.date).getDate() - 1 === i-day_start )[0]?.added_by === 'Deployed Employee' && 
                    <>
                    <span style={{fontSize:'11px',marginBottom:'0px',marginTop:'-3px'}}>Deployed Officer :</span>  
                    <h6 style={{fontWeight:'600',fontSize:'10px',marginBottom:'0px',marginTop:'-3px'}}>{attendance?.attendance?.filter((d)=>new Date(d?.date).getDate() - 1 === i-day_start )?.length > 0 &&  attendance?.attendance?.filter((d)=>new Date(d?.date).getDate() - 1 === i-day_start )[0]?.deployed_officer?.name} / {attendance?.attendance?.filter((d)=>new Date(d?.date).getDate() - 1 === i-day_start )?.length > 0 &&  attendance?.attendance?.filter((d)=>new Date(d?.date).getDate() - 1 === i-day_start )[0]?.deployed_officer?.employee_id}</h6>
                    </>}

                    <h6 style={{fontSize:'9px',marginTop:'0px'}}>{ attendance?.attendance?.filter((d)=>new Date(d?.date).getDate() - 1 === i-day_start )?.length > 0 &&  moment(attendance?.attendance?.filter((d)=>new Date(d?.date).getDate() - 1 === i-day_start )[0]?.createdAt)?.format('lll')}</h6>
                    </div>}
                    </>
                  </Col>  
                  :
                  <>
                  <Col style={{ background:i < (days) ? attendance?.attendance?.filter((d)=>new Date(d?.date).getDate() - 1 === i )?.length > 0 ? attendance?.attendance?.filter((d)=>new Date(d?.date).getDate() - 1 === i )[0].employee_stage === 'P' ? '#abffc1' : (attendance?.attendance?.filter((d)=>new Date(d?.date).getDate() - 1 === i )[0].employee_stage === 'A' || attendance?.attendance?.filter((d)=>new Date(d?.date).getDate() - 1 === i )[0].employee_stage === 'L') ? '#ffc1b8' : attendance?.attendance?.filter((d)=>new Date(d?.date).getDate() - 1 === i )[0].employee_stage === 'WO' ? '#ffebb5' : '#fff' : '#fff': '',width:'14%',height:'35px',display:'flex',alignItems:'center',justifyContent:'center',fontWeight:'400',fontSize:'11px',padding:'2px',cursor:i < (days) ? (new Date(state?.date_of_joining)?.toISOString()?.slice(0,10) >= new Date(parseInt(monthyear?.year) ,parseInt(monthyear?.month),i+1).toISOString()?.slice(0,10)) ? !(new Date()?.toISOString()?.slice(0,10) <= new Date(parseInt(monthyear?.year) ,parseInt(monthyear?.month),i+1).toISOString()?.slice(0,10)) ?  'pointer' : 'not-allowed' : 'not-allowed' : 'not-allowed',borderTop:'1px solid #eee',borderRight:(i == 23 || i == 30) && '1px solid #eee',borderBottom:![1,2,3,4,5,6,7]?.includes(i+1) && '1px solid #eee',borderLeft:'1px solid #eee',position:'relative'}} span={1}>
                    <h6 style={{fontSize:'8px',fontWeight:'900px',position:'absolute',top:0,left:0,padding:'2px'}}>{i+1}</h6>
                  {attendance?.attendance?.filter((d)=>new Date(d?.date).getDate() - 1 === i )?.length > 0 && attendance?.attendance?.filter((d)=>new Date(d?.date).getDate() - 1 === i )[0]?.employee_stage}
                  </Col>
                  </>
                   }
                  </>
                ))}
              </Row>

            {step == 2 &&
              <>
              <h6 style={{marginTop:'10px',fontSize:'13px'}}>Over All Report</h6>
              <h6 style={{marginTop:'2px',fontSize:'11px'}}>No of Days Worked : <span style={{fontWeight:'800'}}> {attendance?.attendance?.filter((f)=>f?.employee_stage === 'P')?.length} </span></h6>
              <h6 style={{marginTop:'2px',fontSize:'11px'}}>No of Days WeekOff : <span style={{fontWeight:'800'}}> {attendance?.attendance?.filter((f)=>f?.employee_stage === 'WO')?.length} </span> </h6>
              <h6 style={{marginTop:'2px',fontSize:'11px'}}>OT in Hours : <span style={{fontWeight:'800'}}> {OT} Hrs </span> </h6>
              <h6 style={{marginTop:'2px',fontSize:'11px'}}>No of Days Total : <span style={{fontWeight:'800'}}>{attendance?.attendance?.filter((f)=>f?.employee_stage === 'P')?.length + attendance?.attendance?.filter((f)=>f?.employee_stage === 'WO')?.length}</span> </h6>
              </>} 
            </div>
        </div>
        
    </FmsDashboardMainRoot>
  )
}

export default AddAttendanceForSelectedEmployee