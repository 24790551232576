import React,{useState,useEffect} from 'react'
import FmsDashboardMainRoot from '../FmsDashboardMainRoot'
import { BoldText1, SmallText } from '../../../helpers/constants/ConstantsText'
import { useLocation } from 'react-router-dom'
import axios from 'axios'
import { authHeader } from '../../../helpers/usertoken/UserToken'
import {Input,Drawer, Select,Tag,Pagination,Row,Col} from 'antd'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import PrimaryButton, { SecondaryButton } from '../../../components/buttons/PrimaryButton'
import Inputerror from '../../../components/errors/Inputerror'
import {AiOutlineCreditCard, AiOutlineEdit} from 'react-icons/ai'
import { Toaster, toast } from 'react-hot-toast'
import {MdOutlineRefresh} from 'react-icons/md'
import { BsFilterSquare } from "react-icons/bs";

function EmployeeBasedOnClient() {

  const {state} = useLocation()
  const [search,setsearch] = useState({text:'',designation:''})
  const user = useSelector(state=>state.Auth)

  const [page,setpage] = useState(1)
  const [data,setdata] = useState([])
  const [fmsemployee_designation,setfmsemployee_designation] = useState([])
  const [employee_selected,setemployee_selected] = useState([])
  const [pagination,setpagination] = useState({})
  const theme_color = useSelector(state=>state.Theme.theme_color)
  const [shifts,setshifts] = useState([])
  const [stages,setstages] = useState([])
  const [add_attendance,setadd_attendance] = useState({year:'',month:'',date:'',field_officer:'',shift:[],employee_stage:'',modified_by:'',modified_reason:'',ot:false,ot_hrs:''})
  const [add_attendance_error,setadd_attendance_error] = useState({year:'',month:'',date:'',field_officer:'',shift:'',employee_stage:'',modified_by:'',modified_reason:'',ot_hrs:''})
  const [visible,setvisible] = useState(false)
  const [monthyear,setmonthyear] = useState({month:'',year:'',days:''})
  const [day_start,setday_start] = useState(0)
  const [date,setdate] = useState(new Date().getDate())
  const navigation = useNavigate()
//   const moment 


// console.log("new Date().getDay()",new Date().getDay())



  useEffect(()=>{
    // setday_start(new Date(monthyear?.year,monthyear?.month,1).getDay())
    getemployeeBasedClient()
    getOptions()
  },[page])
 
  async function getemployeeBasedClient(){
    if(monthyear?.month === '' && monthyear?.year === ''){
      setmonthyear({month:new Date().getMonth() + 1,year:new Date().getFullYear(),days:new Date(new Date().getFullYear(), new Date().getMonth(), 0).getDate()})
      setday_start(new Date(new Date().getFullYear(),new Date().getMonth() + 1,1).getDay())
    }
    await axios.get(`fms/fmsclients/employee_based_on_client/${state}?page=${page}`,authHeader)
    .then((res)=>{
        setpagination(res?.data?.pagination)
        setdata(res?.data?.datas)
    }).catch((err)=>{
    })
  }  

  function getOptions(){
    axios.get(`fms/attendanceshift/get`,authHeader)
    .then((res)=>{
      let arr = []
      res?.data?.datas?.filter((d)=>{
        // arr.push({value:d?._id,label:`${d?.name} [ST : ${d?.start_time} - ET : ${d?.end_time} ]`})
        arr.push({value:d?._id,label:`${d?.name}`})
      })
      setshifts(arr)
    }).catch((err)=>{

    })

    axios.get(`fms/attendancestage/get`,authHeader)
    .then((res)=>{
      let arr = []
      res?.data?.datas?.filter((d)=>{
        arr.push({value:d?._id,label:`${d?.name}`})
      })
      setstages(arr)
    }).catch((err)=>{

    })
   }

  async function handle_employee_select(v){
    if(employee_selected?.length < 16){
     let array = [...employee_selected]
     if(array?.length > 0){
      if(array?.find(f=>f?._id === v?._id) !== undefined){
        let index = array.findIndex(e=>e?._id == v?._id)
        array.splice(index,1)
      }else{
        array?.push(v)
      }
     }else{
      array?.push(v)
    }
    setemployee_selected(array)
   }else{
    toast.error('Maximum selection reached')
   }
  }

  function addshifts(e){
    let arr = []
    e?.forEach(k=>{
        arr?.push(shifts?.find((s)=>s?.value === k))
    })
    setadd_attendance({...add_attendance,shift:arr})
    setadd_attendance_error({...add_attendance_error,shift:''})
  }

  function submitData(){
     if(add_attendance?.shift?.length === 0){
      setadd_attendance_error({...add_attendance_error,shift:'This Field is required*'})
     }else if(add_attendance?.stage?.label === undefined){
      setadd_attendance_error({...add_attendance_error,stage:'This Field is required*'})
     }else{
          let send_data = {
            date:new Date(monthyear?.year,monthyear?.month-1,date+1)?.toISOString()?.slice(0,10),
            field_officer:user.id,
            users:employee_selected?.map((m)=>m._id),
            employee_stage:add_attendance?.stage?.label,
            shift:add_attendance?.shift?.map((m)=>m?.label),
            client:state
          }


          axios.post(`fms/basicemployee/add_attendance_mass`,send_data,authHeader)
          .then((res)=>{
            if(res?.status === 201){
              setvisible(false)
              getemployeeBasedClient()
              setemployee_selected([])
              setadd_attendance({...add_attendance,shift:[],stage:''})
              toast.success('Data Updated Successfully')
            }
          }).catch((err)=>{
      
          })
     }
  }

  function setpagefuun(v){
    setpage(v)
  }

  async function searchData(){
    setpage(1)
    await axios.get(`fms/fmsclients/employee_based_on_client/${state}?page=${1}&search=${search.text}&designation=${search.designation}`,authHeader)
    .then((res)=>{
        setpagination(res?.data?.pagination)
        setdata(res?.data?.datas)
    }).catch((err)=>{
    })
  }  

  async function searchdesignation(e){
    if(e?.length > 2){
        await axios.get(`fms/fmsdesignationonboardemployee/search?search_text=${e}`,authHeader)
        .then((res)=>{
            let arr = []
            res.data.datas.forEach((d)=>{
                let obj = {
                    value : d._id,
                    label:d.designation_name
                }
                arr.push(obj)
            })
            setfmsemployee_designation(arr)

            return arr.filter((i) =>
                i.label.toLowerCase().includes(e.toLowerCase())
                );

        }).catch((err)=>{
        })
    }
  }



  async function resetfilter(){
    setpage(1)
    setsearch({...search,text:'',designation:''})
    await axios.get(`fms/fmsclients/employee_based_on_client/${state}?page=${1}&search=${''}&designation=${''}`,authHeader)
    .then((res)=>{
        setpagination(res?.data?.pagination)
        setdata(res?.data?.datas)
    }).catch((err)=>{
    })
  }

  function selectAllData(){
    let array = []
    data.forEach((d)=>{
      array?.push(d)
    })

    setemployee_selected(array)
  }



  // console.log("date",new Date(monthyear.year,monthyear.month,date))
  return (
    <FmsDashboardMainRoot>
        <Toaster />
        <Drawer open={visible}  closable={false}>

            <div>
                <h5 style={{fontSize:'16px'}}>Add / Update Attendance Daily</h5>
                <h6 style={{fontSize:'11px'}}>Use the below for mt ocreate or update the daily attendance of the employee</h6>
                
                <div style={{width:'340px',marginTop:20}}>
                <SmallText fontWeight={'500'} val={'Date'}  />

                {/* onClick={()=>setdate(i+1)} */}
                <Row style={{marginTop:'20px'}}>
                  {new Array(monthyear?.days)?.fill('')?.map((d,i)=>(
                 <Col span={2} style={{cursor:'pointer'}} >
                    <h6 style={{border:'1px solid #eee',width:'100%',fontSize:'11px',padding:'5px',background:date === i+1 ? 
                    '#ccc' : '#fff',textAlign:'center' }}> {i+1}</h6>
                 </Col>
                 ))}
                </Row> 
                </div>
                
                <div style={{width:'400px',marginTop:20}}>
                <SmallText fontWeight={'500'} val={'Employee Selected'}  />
                {employee_selected?.map((e)=>(
                  <Tag>{e?.name}</Tag>
                ))}
                {/* <Select options={[{label:'P',value:'P'},{label:'A',value:'A'},{label:'OT',value:'OT'},{label:'W/O',value:'W/O'}]} style={{width:'80%'}} /> */}
                </div>
               
                <div style={{display:'flex',flexDirection:'row',width:'96%'}}>
                <div style={{marginTop:'8px',width:'50%',marginRight:'10px'}}>
                <div style={{width:'100%'}}>
                  <SmallText fontWeight={'600'} val={'Shift'} />
                  <Select mode="multiple" options={shifts} style={{borderLeft:`4px solid ${theme_color}`,width:'100%',zIndex:100,fontSize:'13px',fontWeight:'600'}} value={add_attendance?.shift}  onChange={(e)=>{addshifts(e)}} >
                  </Select>  
                </div>
                <Inputerror message={add_attendance_error?.shift} />

                </div>


                <div style={{marginTop:'8px',width:'50%'}}>
                <div style={{width:'100%'}}>
                  <SmallText fontWeight={'600'} val={'Stage'} />
                  <Select options={stages} style={{borderLeft:`4px solid ${theme_color}`,width:'100%',zIndex:100,fontSize:'13px',fontWeight:'600'}} value={add_attendance?.stage !== '' ? add_attendance?.stage?.label : null }  onChange={(e)=>{setadd_attendance({...add_attendance,stage:stages?.find((s)=>s?.value === e)});setadd_attendance_error({...add_attendance_error,stage:''})}} >
                  </Select>  
                </div>
                <Inputerror message={add_attendance_error?.stage} />
                </div>
                </div>

                
                <div style={{display:'flex',flexDirection:'row',marginTop:'10px'}}>
                <PrimaryButton btn_name={'Add'} onClick={submitData} />
                <SecondaryButton btn_name={'Close'} onClick={()=>setvisible(false)}/>
                </div>

            </div>

        </Drawer>
        <div style={{width:'97%'}}>
            <div style={{borderBottom:'1px solid #eee',minHeight:'52px',display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between'}}>
            <BoldText1 val={`Employee List (${pagination?.total})`} />


            <div style={{display:'flex',alignItems:'center',justifySelf:'flex-end',justifyContent:'flex-end',width:'auto'}}>
            <Select placeholder='Search Designation' onClear={()=>setsearch({...search,designation:''})} allowClear={true} filterOption={false} options={fmsemployee_designation} showSearch={true} onSearch={(e)=>{searchdesignation(e)}}  style={{width:'120px',zIndex:100,fontSize:'13px',fontWeight:'600',marginRight:'10px'}}  value={data?.designation}  onChange={(e)=>{setsearch({...search,designation:e});}} />

            <Input placeholder='Search Employee' type="text" style={{marginRight:'8px',width:'130px'}} value={search?.text} onChange={(e)=>setsearch({...search,text:e.target.value})} />
            
            <BsFilterSquare onClick={searchData} size={28} style={{marginLeft:'5px',marginRight:'5px',background:theme_color,color:'white',padding:'6px'}} />
            <MdOutlineRefresh onClick={resetfilter} size={30} style={{marginLeft:'5px',marginRight:'10px',background:'#eee',padding:'4px'}} />


            {employee_selected?.length > 0 &&
            <div style={{width:'130px'}}>
               <PrimaryButton btn_name={'Add Attendance'} onClick={()=>setvisible(true)} />
            </div>
            }
            </div> 
            </div>



              <h1 onClick={()=>selectAllData()} style={{fontSize:'11px',fontWeight:'bold',marginTop:'5px',cursor:'pointer'}}> <span style={{textDecorationLine:'underline',}}>Select All </span>   {employee_selected?.length > 0  && ` /  Total Selected - ${employee_selected?.length}`}</h1>
               <div style={{display:'flex',flexDirection:'row',borderTop:'1px solid #eee',marginTop:'10px',borderBottom:'1px solid #eee'}}>
                <h6 style={{width:'4%',fontSize:'11px',fontWeight:'700',padding:'6px 4px',marginBottom:'0px',borderRight:'1px solid #eee'}}></h6>
                <h6 style={{width:'12%',fontSize:'11px',fontWeight:'700',padding:'6px 4px',marginBottom:'0px',borderRight:'1px solid #eee'}}>Employee Name</h6>
                <h6 style={{width:'8%',fontSize:'11px',fontWeight:'700',padding:'6px 4px',marginBottom:'0px',borderRight:'1px solid #eee'}}>Employee Id</h6>
                <h6 style={{width:'8%',fontSize:'11px',fontWeight:'700',padding:'6px 4px',marginBottom:'0px',borderRight:'1px solid #eee'}}>DOJ</h6>
                <h6 style={{width:'10%',fontSize:'11px',fontWeight:'700',padding:'6px 4px',marginBottom:'0px',borderRight:'1px solid #eee'}}>Designation</h6>
                <h6 style={{width:'10%',fontSize:'11px',fontWeight:'700',padding:'6px 4px',marginBottom:'0px',borderRight:'1px solid #eee'}}> Stage</h6>
                <h6 style={{width:'20%',fontSize:'11px',fontWeight:'700',padding:'6px 4px',marginBottom:'0px',borderRight:'1px solid #eee'}}> Shift</h6>
                <h6 style={{width:'10%',fontSize:'11px',fontWeight:'700',padding:'6px 4px',marginBottom:'0px',borderRight:'1px solid #eee'}}> OT </h6>
                <h6 style={{width:'8%',fontSize:'11px',fontWeight:'700',padding:'6px 4px',marginBottom:'0px',borderRight:'1px solid #eee'}}> OT Hrs</h6>
                <h6 style={{width:'8%',fontSize:'11px',fontWeight:'700',padding:'6px 4px',marginBottom:'0px'}}> Action </h6>
               </div>
                {data?.map((d1)=>(
                
                <div  style={{display:'flex',flexDirection:'row',borderBottom:'1px solid #eee'}}>
                  <h6 style={{width:'4%',fontSize:'11px',marginBottom:'0px',borderRight:'1px solid #eee'}}>
                  <div style={{marginTop:'4px',marginLeft:'15px',display:'flex'}}>
                    <p className={employee_selected?.find((f)=>f?._id === d1?._id) !== undefined ? 'checked_here2': 'checked_here'}  onClick={()=>handle_employee_select(d1)}>
                    {employee_selected?.find((f)=>f?._id === d1?._id) !== undefined && <p className='checked_here1' style={{background:theme_color}}></p>}
                    </p>
                  </div>
                  </h6>
                  <h6 style={{width:'12%',fontSize:'11px',padding:'6px 4px',marginBottom:'0px',borderRight:'1px solid #eee'}}>{d1?.name}</h6>
                  <h6 style={{width:'8%',fontSize:'11px',padding:'6px 4px',marginBottom:'0px',borderRight:'1px solid #eee'}}>{d1?.employee_id}</h6>
                  <h6 style={{width:'8%',fontSize:'11px',padding:'6px 4px',marginBottom:'0px',borderRight:'1px solid #eee'}}>{d1?.date_of_joining}</h6>
                  <h6 style={{width:'10%',fontSize:'11px',padding:'6px 4px',marginBottom:'0px',borderRight:'1px solid #eee'}}>{d1?.designation?.display_name}</h6>
                  <h6 style={{width:'10%',fontSize:'11px',padding:'6px 4px',marginBottom:'0px',borderRight:'1px solid #eee'}}> {d1?.attendance?.length !== 0 ? d1?.attendance[0]?.employee_stage : 'NA'}</h6>
                  <h6 style={{width:'20%',fontSize:'11px',padding:'6px 4px',marginBottom:'0px',borderRight:'1px solid #eee'}}> {d1?.attendance?.length !== 0 ? d1?.attendance[0]?.shift?.map((s)=>(<Tag size={'small'} style={{fontSize:'8px'}}>{s}</Tag>)) : 'NA'}</h6>
                  <h6 style={{width:'10%',fontSize:'11px',padding:'6px 4px',marginBottom:'0px',borderRight:'1px solid #eee'}}> { d1?.attendance?.length !== 0 ? <p className={ d1?.attendance[0].ot ? 'checked_here2': 'checked_here'}>
                    {d1?.attendance[0].ot && <p className='checked_here1' style={{background:theme_color}}></p>}
                    </p>  : 'NA'} </h6>
                  <h6 style={{width:'8%',fontSize:'11px',padding:'6px 4px',marginBottom:'0px',borderRight:'1px solid #eee'}}> { d1?.attendance?.length !== 0 ? d1?.attendance[0].ot_hrs : 'NA'}</h6>
                  <h6  style={{width:'8%',fontSize:'11px',padding:'6px 4px',marginBottom:'0px',color:'#000',marginLeft:'5px'}}> 
                    <AiOutlineCreditCard onClick={()=>navigation('/fms/employee_based_on_clients/add_attendance',{state:d1})}  size={15} />
                    <AiOutlineEdit style={{marginLeft:'10px'}} onClick={()=>navigation(`/fms/fms_employee/${d1?._id}`)} />
                  </h6>
                </div>
                ))
                }

            <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
                {pagination.total > pagination?.limit &&
                    <Pagination current={page} size="small" total={pagination.total} onChange={(v) => setpagefuun(v)}  pageSize={pagination?.limit} />
                }
            </div>

            {/* </Row> */}


        </div>
    </FmsDashboardMainRoot>
  )
}

export default EmployeeBasedOnClient