import React from 'react'


function MettingDetail() {

    return (
        <div>
            <h2>Meeting Detail Page</h2>
        </div>
    )
}

export default MettingDetail
