import React,{useState} from 'react';
import { BoldHeadText,BoldText1,SmallText } from '../../../helpers/constants/ConstantsText';
import FmsDashboardMainRoot from '../FmsDashboardMainRoot';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import {useSelector} from 'react-redux';
import {Input,Select,DatePicker,Button,Upload} from 'antd'
import PrimaryButton, { SecondaryButton } from '../../../components/buttons/PrimaryButton';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { CloseOutlined, UploadOutlined } from '@mui/icons-material';
import { FiEdit,FiTrash2 } from 'react-icons/fi';
import { data_not_found } from '../../../helpers/Constants';
import { IoClose } from 'react-icons/io5';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import {BsArrowLeft} from 'react-icons/bs';
import toast, { Toaster } from "react-hot-toast";
import {useLocation,useNavigate} from 'react-router-dom';

import moment from 'moment';
import axios from 'axios';
import { useEffect } from 'react';
import { authHeader } from '../../../helpers/usertoken/UserToken';


function StatementForm(){

    const { Option } = Select; 
    const { TextArea } = Input;

    const location = useLocation();
    const navigate = useNavigate()


    let send_data = location.state.datas;


    // console.log('send_data here okk',send_data)

    const [drawer,set_drawer] = useState(false)

    const [witness,setwitness] = useState({id:'',name:'',address:'',signature:null});
    const [signature_main,setsignature_main] = useState(null)
    const [image,setimage] = useState()
    const [witnessArr,setwitnessArr] = useState([]);


    const martial_Arr = [
        'Single',
        'Married',
        'Widow',
        'Widower'
      ]

    const steps = [
        {
          label: 'Statment Info',
          description: `Add a emplyee statement info for your database`,
        },
        {
          label: 'Declaration of Witness',
          description:
            'Add the witness for the declarion',
        },
    ] 

    const [activeStep, setActiveStep] = useState(0);
    const [data,setdata] = useState({name_of_employee_in_full:'',sex:'',religion:'',martial_status:'',department_branch_were_deployed:'',department_branch_were_deployed_id:'',post_held_with_ticket:'',date_of_appointment:'',permanent_address:'',village:'',thana:'',post_office:'',district:'',state:'',place:'',date:'',signature:null})
    const [err,seterr] = useState({name_of_employee_in_full:'',sex:'',religion:'',martial_status:'',department_branch_were_deployed:'',post_held_with_ticket:'',date_of_appointment:'',permanent_address:'',village:'',thana:'',post_office:'',district:'',state:'',place:'',date:'',signature:null})


    const theme_color = useSelector(state=>state.Theme.theme_color)


    useEffect(()=>{

        // console.log('send_data?.department_branch_were_deployed[0]',send_data?.department_branch_were_deployed)
        if(send_data.statement._id == null  || send_data.statement._id == undefined){
            setdata({...data,
                id:'',
                name_of_employee_in_full:send_data.name,
                sex:send_data.gender,
                martial_status:send_data.martial_status,
                permanent_address:send_data.permanent_address,
                department_branch_were_deployed:send_data?.department_branch_were_deployed?.site_name,
                department_branch_were_deployed_id:send_data?.department_branch_were_deployed?._id
            })

        }else{
           let d = send_data.statement
           setdata({...data,
            name_of_employee_in_full:d.name_of_employee_in_full,

            sex:d.sex,
            religion:d.religion,
            martial_status:d.martial_status,
            department_branch_were_deployed:d.department_branch_were_deployed?.site_name,
            department_branch_were_deployed_id:d.department_branch_were_deployed?._id,
            post_held_with_ticket:d.post_held_with_ticket,
            date_of_appointment:d.date_of_appointment,
            permanent_address:d.permanent_address,
            village:d.village,
            thana:d.thana,
            post_office:d.post_office,
            district:d.district,
            state:d.state,
            place:d.place,
            date:d.date,
            signature:d.signature == '' ? null : d.signature,
            id:d._id
        })

        }
        getwitness()

    },[])

    const handleUpload = (name,type,v) => {
        // console.log('v',v,'name',name,'type',type)
        // console.log('v.fileList[v.fileList.length - 1].originFileObj',v.fileList[v.fileList.length - 1].originFileObj)
       if(type == 1){
        setdata({...data,[name]:v.fileList[v.fileList.length - 1].originFileObj});
       }else{
        if(witness.id === ''){
            setwitness({...witness,signature:v.fileList[v.fileList.length - 1].originFileObj});
        }else{
            setwitness({...witness,signature:null})
            setsignature_main(v.fileList[v.fileList.length - 1].originFileObj)
        }
       }
    }

    function getwitness(){
        axios.get(`fms/witnessform/get/${send_data._id}`,authHeader)
        .then((res)=>{
            setwitnessArr(res.data.data)
            console.log('res data here getwitness',res.data)
        }).catch((err)=>{
            // console.log('err data here',err.response)
        })
    }

    function addwitness(){
        let fd = new FormData()
        console.log('witness.signature',witness.signature)
        fd.append('name',witness.name)
        fd.append('address',witness.address)
        witness?.signature?.name !== undefined && fd.append('signature',witness?.signature)
        fd.append('fms_employee',send_data._id)


        const AuthHeader = {headers:{
            'Content-Type': 'multipart/form-data',
        }}

       console.log('addwitness',fd)  

        axios.post('fms/witnessform/create',fd ,AuthHeader).then((res)=>{
            // console.log('res.data here',res?.data)
            toast.success(res.data.message);
            getwitness()
        }).catch((err)=>{
            console.log('err',err?.response)
        })
        // let previous_witness = [...witnessArr]
        // setwitnessArr([...previous_witness,witness])
        // setwitness({name:'',address:'',signature:null})
    }

    function submitform(){
        if(data.name_of_employee_in_full === ''){
            seterr({...err,name_of_employee_in_full:'This Field is required'})
        }else if(data.sex === ''){
            seterr({...err,sex:'This Field is required'})
        }else if(data.religion === ''){
            seterr({...err,religion:'This Field is required'})
        }else if(data.martial_status === ''){
            seterr({...err,martial_status:'This Field is required'})
        }else if(data.post_held_with_ticket === ''){
            seterr({...err,post_held_with_ticket:'This Field is required'})
        }else if(data.date_of_appointment === ''){
            seterr({...err,date_of_appointment:'This Field is required'})
        }else if(data.permanent_address === ''){
            seterr({...err,permanent_address:'This Field is required'})
        }else if(data.place === ''){
            seterr({...err,place:'This Field is required'})
        }else if(data.date === ''){
            seterr({...err,date:'This Field is required'})
        }else{
        let fd = new FormData()
        fd.append('fms_employee',send_data?._id)
        fd.append('name_of_employee_in_full',data.name_of_employee_in_full)
        fd.append('sex',data.sex)
        fd.append('religion',data.religion)
        fd.append('martial_status',data.martial_status)
        fd.append('post_held_with_ticket',data.post_held_with_ticket)
        fd.append('date_of_appointment',data.date_of_appointment)
        fd.append('department_branch_were_deployed',data.department_branch_were_deployed_id)
        fd.append('permanent_address',data.permanent_address)
        fd.append('village',data.village)
        fd.append('thana',data.thana)
        fd.append('post_office',data.post_office)
        fd.append('district',data.district)
        fd.append('state',data.state)
        fd.append('place',data.place)
        fd.append('date',data.date)
        fd.append('witness',witnessArr)

        let arr = []

        for(let i=0;i<witnessArr.length;i++){
            arr.push(witnessArr[i].signature)
            // fd.append(`signature[${i}]`,witnessArr[i].signature)
        }
        fd.append('signature',arr)


        const AuthHeader = {headers:{
            'Content-Type': 'multipart/form-data',
        }}
        
        
        axios.post('fms/statementform/create',fd,AuthHeader)
        .then((res)=>{
            toast.success(res.data.message);
            resetform();
        }).catch((err)=>{
            if(err?.response?.status == 403){
                toast.error(err?.response?.data?.message)
            }
            // console.log('err here',err)
        })
    }
    }

    function submitform1(){
        let fd = new FormData()
        fd.append('id',data.id)
        fd.append('fms_employee',send_data._id)
        fd.append('name_of_employee_in_full',data.name_of_employee_in_full)
        fd.append('sex',data.sex)
        fd.append('religion',data.religion)
        fd.append('martial_status',data.martial_status)
        fd.append('post_held_with_ticket',data.post_held_with_ticket)
        fd.append('date_of_appointment',data.date_of_appointment)
        fd.append('department_branch_were_deployed',data.department_branch_were_deployed_id)
        fd.append('permanent_address',data.permanent_address)
        fd.append('village',data.village)
        fd.append('thana',data.thana)
        fd.append('post_office',data.post_office)
        fd.append('district',data.district)
        fd.append('state',data.state)
        fd.append('place',data.place)
        fd.append('date',data.date)
        fd.append('signature',data.signature)
       
        const AuthHeader = {headers:{
            'Content-Type': 'multipart/form-data',
        }}


        
        
        axios.post(`fms/statementform/update/${data.id}`,fd,AuthHeader)
        .then((res)=>{
            toast.success(res.data.message);
            resetform();
        }).catch((err)=>{
            if(err?.response?.status == 403){
                toast.error(err?.response?.data?.message)
            }
        })
    }

    function submitformfun(){
        // console.log('triggered here',activeStep)
        if(activeStep == 0){
            if(data.name_of_employee_in_full === ''){
                seterr({...err,name_of_employee_in_full:'This Field is required'})
            }else if(data.sex === ''){
                seterr({...err,sex:'This Field is required'})
            }else if(data.religion === ''){
                seterr({...err,religion:'This Field is required'})
            }else if(data.martial_status === ''){
                seterr({...err,martial_status:'This Field is required'})
            }else if(data.post_held_with_ticket === ''){
                seterr({...err,post_held_with_ticket:'This Field is required'})
            }else if(data.date_of_appointment === ''){
                seterr({...err,date_of_appointment:'This Field is required'})
            }else if(data.permanent_address === ''){
                seterr({...err,permanent_address:'This Field is required'})
            }else if(data.place === ''){
                seterr({...err,place:'This Field is required'})
            }else if(data.date === ''){
                seterr({...err,date:'This Field is required'})
            }else{
                seterr({...err,date:''})
                setActiveStep(1)
            }    
        }else {
            data.id == '' ? submitform() : submitform1()
        }
    }

    function resetform(){
        setTimeout(() => {
        navigate(-1)
        }, 1500);
        setdata({name_of_employee_in_full:'',sex:'',religion:'',martial_status:'',department_branch_were_deployed:'',post_held_with_ticket:'',date_of_appointment:'',permanent_address:'',village:'',thana:'',post_office:'',district:'',state:'',place:'',date:'',signature:null,id:''})
        
    }

    function clearform(){
        if(activeStep  == 1){
            if(send_data.statement._id == null  || send_data.statement._id == undefined){
                setdata({...data,
                    id:'',
                    name_of_employee_in_full:send_data.name,
                    sex:send_data.gender,
                    martial_status:send_data.martial_status,
                    permanent_address:send_data.permanent_address,
                    department_branch_were_deployed:send_data?.department_branch_were_deployed?.site_name,
                    department_branch_were_deployed_id:send_data?.department_branch_were_deployed?._id
                })
    
            }else{
               let d = send_data.statement
               setdata({...data,
                name_of_employee_in_full:d.name_of_employee_in_full,
    
                sex:d.sex,
                religion:d.religion,
                martial_status:d.martial_status,
                department_branch_were_deployed:d.department_branch_were_deployed?.site_name,
                department_branch_were_deployed_id:d.department_branch_were_deployed?._id,
                post_held_with_ticket:d.post_held_with_ticket,
                date_of_appointment:d.date_of_appointment,
                permanent_address:d.permanent_address,
                village:d.village,
                thana:d.thana,
                post_office:d.post_office,
                district:d.district,
                state:d.state,
                place:d.place,
                date:d.date,
                signature:d.signature == '' ? null : d.signature,
                id:d._id
            })
            }
        }else{
            setwitnessArr([])
            setwitness({name:'',address:'',signature:null})
        }
    }

    function deletewitness(id){
        axios.delete(`fms/witnessform/delete/${id}` ,authHeader).then((res)=>{
            toast.success(res.data.message);
            getwitness()
        })
    }

    function updatewitness(){
        let fd = new FormData()
        fd.append('id',witness.id)
        fd.append('name',witness.name)
        fd.append('address',witness.address)
        signature_main?.name !== undefined && signature_main?.name !== undefined && fd.append('signature',signature_main)
        fd.append('fms_employee',send_data._id)
        
        const AuthHeader = {headers:{
            'Content-Type': 'multipart/form-data',
        }}

        axios.post(`fms/witnessform/update/${witness.id}`,fd ,AuthHeader).then((res)=>{
            toast.success(res.data.message);
            set_drawer(!drawer)
            getwitness();
            // getwitness()
        })

    }

    function editwitness(d){
        console.log('d',d)
        setwitness({id:d._id,name:d.name,address:d.address,signature:d.signature != '' ? d.signature : null})
        set_drawer(true)
    }


    return(
        <FmsDashboardMainRoot>
            <Toaster />

            <span style={{display:'flex',cursor:'pointer',marginBottom:'20px'}} onClick={()=>navigate(-1)}>
                <BsArrowLeft style={{marginRight:'5px'}}/>
                <SmallText val={'Back'} />
            </span>
            
            <SwipeableDrawer
                anchor='right'
                style={{minWidth:'200px',maxWidth:'200px',zIndex:10}}
                    open={drawer}
                    onClose={()=>set_drawer(!drawer)}
                > 
                        <div style={{width:'300px',padding:'30px'}}> 
                        <IoClose   style={{position:'absolute',top:'10px',right:'10px',cursor:'pointer'}} onClick={()=>set_drawer(false)}/>
                           <div>
                            <BoldHeadText  val="Witness Form" fontSize={'15px'} fontWeight='900'/>
                            <p style={{borderBottom:'1px solid #eee'}}></p>

                             <div style={{marginBottom:'10px'}}>
                                <div style={{width:'150px'}}>
                                <SmallText fontWeight={'600'} val={'Name '}  />
                                </div>
                                <Input type="text" style={{borderLeft:`4px solid ${theme_color}`}}  value={witness.name} onChange={(e)=>setwitness({...witness,name:e.target.value})} />
                                </div>

                              <div style={{marginBottom:'10px'}}>
                                <div style={{width:'150px'}}>
                                <SmallText fontWeight={'600'} val={'Address'}  />
                                </div>
                                <TextArea maxLength={200} type="text" style={{borderLeft:`4px solid ${theme_color}`}}  value={witness.address} onChange={(e)=>setwitness({...witness,address:e.target.value})} />
                                </div>


                              <div style={{marginTop:'10px',}}>
                                <div style={{width:'150px'}}>
                                <SmallText fontWeight={'600'} val={'Signature'} />
                                </div>

                                {/* <h6>{signature_main?.name}</h6>
                                <h6>{witness.signature}</h6>
                                <h1>kp : {signature_main === null ? (witness?.id !== '' ?   witness.signature :  witness?.signature?.name) : signature_main?.name}</h1>

                                <h2>test {JSON.stringify(witness.signature === null)}</h2>
                                <h2>test1 {JSON.stringify(witness.signature === '')}</h2>
                                 */}
                                {/* <h2>test2 {JSON.stringify(signature_main === null)}</h2>
                                <h2>test2 {JSON.stringify(signature_main)}</h2> */}

                                {(witness.signature === null || witness.signature === '') && (signature_main == '' || signature_main == null)  ?
                                <Upload showUploadList={false} onChange={(v)=>handleUpload('signature',2,v)} style={{maxWidth:'100px !important',background:'red'}}>
                                    <Button style={{width:'240px',display:'flex',alignSelf:'flex-end',justifyContent:'end'}} icon={<UploadOutlined   style={{borderLeft:'1px solid #eee',color:'#999',paddingLeft:'0px'}}/>}></Button>
                                </Upload>
                                :
                                <Button  onClick={()=>setwitness({...witness,signature:''})} style={{width:'240px',display:'flex',alignItems:'center',fontSize:'10px',marginBottom:'0px'}} ><p style={{marginBottom:'0px', wordBreak: 'break-word',whiteSpace:'nowrap',width:'90%',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px',textAlign:'left',color:'black'}}>{signature_main === null ? (witness?.id !== '' ?   witness.signature :  witness?.signature?.name) : signature_main?.name}</p><CloseOutlined size={15}  style={{borderLeft:'1px solid #eee',color:'#999',paddingLeft:'0px',position:'absolute',right:'0px'}}/></Button>
                                }
                               </div> 
                        
                            {(witnessArr.length  < 2 || witness.id != '')  &&
                            <PrimaryButton btn_name={'Continue'} onClick={witness.id == '' ? addwitness : updatewitness} />}
                           </div>

                          
                        </div>
              </SwipeableDrawer>


              <BoldText1 val={'Statement Information'} />
              <p style={{borderBottom:'1px solid #eee'}}></p>

            <div style={{display:'flex'}}>
            <div style={{borderRight:'1px solid #eee',marginRight:'40px',paddingRight:'30px'}}>
              <Stepper activeStep={activeStep} orientation="vertical" >
                {steps.map((step, index) => (
                <Step key={step.label}>
                    <StepLabel StepIconProps={{style: {theme_color}}} >
                    <div style={{marginTop:'30px',maxWidth:'150px'}}>
                    <BoldHeadText fontWeight={'900'} val={step.label} marginfun={'2px 0px'} fontSize={'13px'} />
                    <SmallText val = {step.description}  fontSize={'11px'}/>
                    </div>  
                    </StepLabel>
                </Step>
                ))}
              </Stepper>
              </div> 



            <div> 
           

                {activeStep == 0 &&
                <div>
                <div  style={{display:'flex',}}>
                <div>
              <div style={{display:'flex',justifyContent:'space-between'}}>
                <div style={{width:'300px',marginTop:'20px'}}> 


                <BoldHeadText fontWeight={'900'} fontSize='15px' val={'Basic Info'} />
                <p style={{height:'6px'}}></p>

                <div style={{marginBottom:'10px'}}>
                <div style={{width:'150px'}}>
                <SmallText fontWeight={'600'} val={'Employee Name'} />
                </div>
                <Input type="text"  style={{borderLeft:`4px solid ${theme_color}`}} value={data.name_of_employee_in_full} onChange={(e)=>{setdata({...data,name_of_employee_in_full:e.target.value});seterr({...err,name_of_employee_in_full:''})}} />
                <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                {(err?.name_of_employee_in_full) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{err?.name_of_employee_in_full  }</p>
                </div>
                </div>

                <div style={{marginBottom:'10px'}}>
                <div style={{width:'150px'}}>
                <SmallText fontWeight={'600'} val={'Gender (M/F/O)'} />
                </div>
                <Select  style={{borderLeft:`4px solid ${theme_color}`,width:'100%',zIndex:100,fontSize:'13px',fontWeight:'600'}} value={data.sex}  onChange={(e)=>{setdata({...data,sex:e});seterr({...err,sex:''})}} >
                            {martial_Arr?.map((l)=>(
                                <Option style={{zIndex:100,marginBottom:'0px',fontWeight:'500',fontSize:'13px'}} key={l} value={l}>{l}</Option>
                                ))}
                            </Select>  
                            <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                {(err?.sex) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{err?.sex  }</p>
                </div>
                </div>

                <div style={{marginBottom:'10px'}}>
                <div style={{width:'150px'}}>
                <SmallText fontWeight={'600'} val={'Religion'}  />
                </div>
                <Input type="text" style={{borderLeft:`4px solid ${theme_color}`}}  value={data.religion} onChange={(e)=>{setdata({...data,religion:e.target.value});seterr({...err,religion:''})}} />
                <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                {(err?.religion) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{err?.religion  }</p>
                </div>
                </div>

                <div style={{marginBottom:'10px'}}>
                <div style={{width:'150px'}}>
                    <SmallText fontWeight={'600'} val={'Martial Status'} />
                    </div>
                    <Select  style={{borderLeft:`4px solid ${theme_color}`,width:'100%',zIndex:100,fontSize:'13px',fontWeight:'600'}}  value={data.martial_status} onChange={(e)=>{setdata({...data,martial_status:e});seterr({...err,martial_status:''})}} >
                            {martial_Arr?.map((l)=>(
                                <Option style={{zIndex:100,marginBottom:'0px',fontWeight:'500',fontSize:'13px'}} key={l} value={l}>{l}</Option>
                                ))}
                            </Select>  
                            <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                {(err?.martial_status) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{err?.martial_status  }</p>
                </div>
                    {/* <Input type="text" style={{borderLeft:`4px solid ${theme_color}`}} value={data.name} onChange={(e)=>setdata({...data,name:e.target.value})} /> */}
                </div>


                <div style={{marginBottom:'10px'}}>
                <div>
                <SmallText fontWeight={'600'} val={'Department/Branch Were Deployed'} />
                </div>
                <Input type="text"  value={data.department_branch_were_deployed} />
                </div>

                <div style={{marginBottom:'10px'}}>
                <div >
                <SmallText fontWeight={'600'} val={'Post held with Ticket or Serial no,if any'} />
                </div>
                <Input type="text"  style={{borderLeft:`4px solid ${theme_color}`}} value={data.post_held_with_ticket} onChange={(e)=>{setdata({...data,post_held_with_ticket:e.target.value});seterr({...err,post_held_with_ticket:''})}} />
                <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                {(err?.post_held_with_ticket) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{err?.post_held_with_ticket  }</p>
                </div>
                </div>

                <div style={{marginBottom:'10px'}}>
                <div style={{width:'150px'}}>
                <SmallText fontWeight={'600'} val={'Date of Appointment'} />
                </div>
                <DatePicker style={{width:'100%',borderLeft:`4px solid ${theme_color}`}}  placeholder='' value={data.date_of_appointment !== '' ? moment(data.date_of_appointment) : ''} onChange={(v,v1)=>{setdata({...data,date_of_appointment:v1});seterr({...err,date_of_appointment:''})}}/>
                <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                {(err?.date_of_appointment) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{err?.date_of_appointment  }</p>
                </div>
                {/* <Input type="text"  value={data.name} onChange={(e)=>setdata({...data,name:e.target.value})} /> */}
                </div>

                

               

                <div style={{marginBottom:'10px'}}>
                <div style={{width:'150px'}}>
                <SmallText fontWeight={'600'} val={'Permanent Address'} />
                </div>
                <Input type="text" style={{borderLeft:`4px solid ${theme_color}`}} value={data.permanent_address} onChange={(e)=>{setdata({...data,permanent_address:e.target.value});seterr({...err,permanent_address:''})}} />
                <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                {(err?.permanent_address) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{err?.permanent_address  }</p>
                </div>
                </div>

                

               

                
                
                </div>


                



                </div>
                </div>

                <div style={{width:'300px',marginLeft:'50px',marginTop:'68px'}}>
                <div style={{marginBottom:'10px'}}>
                <div style={{width:'150px'}}>
                <SmallText fontWeight={'600'} val={'Village'} />
                </div>
                <Input type="text"  value={data.village} onChange={(e)=>setdata({...data,village:e.target.value})} />
                </div>

                <div style={{marginBottom:'10px'}}>
                <div style={{width:'150px'}}>
                <SmallText fontWeight={'600'} val={'Thana'} />
                </div>
                <Input type="text"  value={data.thana} onChange={(e)=>setdata({...data,thana:e.target.value})} />
                </div>

                <div style={{marginBottom:'10px'}}>
                <div style={{width:'150px'}}>
                <SmallText fontWeight={'600'} val={'Post Office'} />
                </div>
                <Input type="text"  value={data.post_office} onChange={(e)=>setdata({...data,post_office:e.target.value})} />
                </div>

                <div style={{marginBottom:'10px'}}>
                <div style={{width:'150px'}}>
                <SmallText fontWeight={'600'} val={'District'} />
                </div>
                <Input type="text"  value={data.district} onChange={(e)=>setdata({...data,district:e.target.value})} />
                </div>

                <div style={{marginBottom:'10px'}}>
                <div style={{width:'150px'}}>
                <SmallText fontWeight={'600'} val={'State'} />
                </div>
                <Input type="text"  value={data.state} onChange={(e)=>setdata({...data,state:e.target.value})} />
                </div>

                

                <div style={{marginBottom:'10px'}}>
                <div style={{width:'150px'}}>
                <SmallText fontWeight={'600'} val={'Place'} />
                </div>
                <Input type="text" style={{borderLeft:`4px solid ${theme_color}`}}  value={data.place} onChange={(e)=>{setdata({...data,place:e.target.value});;seterr({...err,place:''})}} />
                <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                {(err?.place) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{err?.place  }</p>
                </div>
                </div>

                <div style={{marginBottom:'10px'}}>
                <div style={{width:'150px'}}>
                <SmallText fontWeight={'600'} val={'Date'} />
                </div>
                <DatePicker style={{width:'100%',borderLeft:`4px solid ${theme_color}`}}  placeholder='' value={data.date !== '' ? moment(data.date) : ''} onChange={(v,v1)=>{setdata({...data,date:v1});seterr({...err,date:''})}}/>
                <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                {(err?.date) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{err?.date  }</p>
                </div>
                </div>

                {/* <div style={{marginTop:'10px',width:'100px'}}>
                                <div style={{width:'150px'}}>
                                <SmallText fontWeight={'600'} val={'Signature'} />
                                </div>
                                <div style={{width:'100px'}}>
                                {data.signature === null ?
                                <Upload showUploadList={false} onChange={(v)=>handleUpload('signature',1,v)} style={{width:'200px'}}>
                                    <Button style={{width:'300px',display:'flex',alignSelf:'flex-end',justifyContent:'end'}} icon={<UploadOutlined   style={{borderLeft:'1px solid #eee',color:'#999',paddingLeft:'0px'}}/>}></Button>
                                </Upload>
                                :
                                <Button onClick={()=>setdata({...data,signature:null})} style={{width:'300px',display:'flex',alignItems:'center',fontSize:'10px',marginBottom:'0px'}} ><p style={{marginBottom:'0px', wordBreak: 'break-word',whiteSpace:'nowrap',width:'90%',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px'}}>{data?.signature}</p> <CloseOutlined size={10}  style={{borderLeft:'1px solid #eee',color:'#999',paddingLeft:'0px',position:'absolute',right:'0px'}}/></Button>
                                }
                                </div>
                </div> */}
                 </div>

               
                 </div>
                 <div style={{marginLeft:'0px',display:'flex',width:'100px',justifyContent:'space-between'}}>
              <PrimaryButton btn_name="Next" onClick={submitformfun} />
              <SecondaryButton btn_name={"Clear"} onClick={()=>clearform()} />
              </div> 


                </div>}
                {activeStep == 1 &&
                 <div>
                    <span style={{display:'flex',cursor:'pointer'}} onClick={()=>setActiveStep(0)}>
                    <BsArrowLeft style={{marginRight:'5px'}}/>
                    <SmallText val={'Back'} />
                    </span>
                 <div style={{display:'flex',justifyContent:'space-between'}}>
                 <div style={{marginTop:'0px'}}> 
                 <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between'}}>
                 <BoldHeadText fontWeight={'900'} fontSize='15px' val={'Witness Details '} />
                 <PrimaryButton btn_name="Add Witness" onClick={()=>{set_drawer(true);setwitness({id:'',name:'',address:'',signature:null})}} />
                 </div>
                 {witnessArr.length > 0 ? 
                     <div style={{ border:'0px solid lightgray',overflowY:'scroll',width:'600px'}}>
                         <div className='mateial_table_header'>
                             <p style={{minWidth: '10%',color:'black'}}> Sl No</p>
                             <p style={{minWidth: '15%',marginRight:'2%'}}>Name</p>
                             <p style={{minWidth: '30%',marginRight:'2%'}}>Address</p>
                             <p style={{minWidth: '25%'}}>Signature</p>
                             <p style={{minWidth: '10%'}}>Option</p>
                         </div>
  
                 {witnessArr.map((d,i) => (
                     <div key={d.id} className={'mateial_table_body'}>
                       
                         <p style={{minWidth: '10%',maxWidth:'10%', color: "#0594f2",wordBreak:'break-word',fontWeight:'700'}}>{i+1}</p>
                         <p style={{minWidth: '15%',maxWidth: '15%',wordBreak:'break-word',marginRight:'2%',marginLeft:'-1%'}}>{d?.name}</p>
                         <p style={{minWidth: '30%',maxWidth:'30%',wordBreak:'break-word',marginRight:'2%'}}>{d?.address}</p>
                         <p style={{minWidth: '25%',maxWidth:'25%',wordBreak:'break-word',marginRight:'10px'}}><p style={{marginBottom:'0px', wordBreak: 'break-word',whiteSpace:'nowrap',width:'90%',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px'}}>{d?.signature == null ? 'Not Added' : d?.signature?.split('/')[d?.signature?.split('/').length - 1]}</p></p>
                         <p style={{minWidth: '10%',maxWidth:'10%',display:'flex',marginLeft:'-10px'}}>
                             <FiEdit size={15} style={{marginRight:'10px'}} onClick={()=>{editwitness(d);setsignature_main(null)}}/> 
                             <FiTrash2 size={15}  onClick={()=>deletewitness(d._id)}/>
                         </p>
   
                     </div>
                 ))}
  
  
  
                
                     </div>
                     :
                     <div style={{display:'grid',justifyContent:'center',alignItems:'center',marginTop:'100px',alignSelf:'center'}}>
                     <img src={data_not_found} alt="nodatafouund" style={{margin:'auto',width:'120px',objectFit:'contain'}} />
                     <BoldText1 val={'No data added till now'} alignfun='center' />
                     <SmallText alignfun='center' val={'Click on a add family details Button. Then, you can find the data added on this page.'} />
                     </div> 
                  }
                 
             
              <div style={{marginLeft:'0px',display:'flex',width:'100px',justifyContent:'space-between'}}>
               <PrimaryButton btn_name="Next"  onClick={()=>submitformfun()}/>
               <SecondaryButton btn_name={"Clear"} onClick={()=>{setwitnessArr([])}} />
               </div>
                </div>
                </div>
                 </div>
                }
            </div> 
            </div>

        </FmsDashboardMainRoot>
    )
}

export default StatementForm;