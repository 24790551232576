import React, { useState, useEffect } from 'react'
import { main_bg } from '../../../components/constants/Constants1';
import RootNavbar from '../../mobileNavbar/RootNavbar';
import { IoMenu } from 'react-icons/io5'
import { Drawer, Input, Tabs, Button, Select, Modal } from 'antd'
import { Row, Col, Tag } from 'antd';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { authHeader } from '../../../helpers/usertoken/UserToken';
import TableView1 from '../../../components/bdcomponenttable/mobileComponents/listview_mobile/TableView1';
import { IoFlask } from 'react-icons/io5'
import { IoClose } from 'react-icons/io5'
import DateField from '../../../components/inputcomponents/DateField';
import Loader from '../../../components/Loaders/Loader'


import { Timeline } from '@mui/lab';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';

import { IoCreateOutline } from 'react-icons/io5'
import { AiOutlineClockCircle, AiOutlineEdit } from 'react-icons/ai'
import { BiTransfer } from 'react-icons/bi'
import moment from 'moment';
import { data_not_found } from '../../../helpers/Constants';

// import { PrimaryButtonFilter } from '../../../components/buttons/PrimaryButton';


const { TabPane } = Tabs;
const { Option } = Select;


function TeamCLD() {

  const navigate = useNavigate()


  const [page, setpage] = useState(1)
  const [loader, setloader] = useState(false)

  const [visible, setvisible] = useState(false)
  const [visible1, setvisible1] = useState(false)
  const [visible2, setvisible2] = useState(false)
  const [visible3, setvisible3] = useState(false)
  const [visible4, setvisible4] = useState(false)
  const [visible5, setvisible5] = useState(false)

  const [md_team, setmd_team] = useState([])


  const [contacts, setcontacts] = useState([])
  const [remark, setremark] = useState()
  const [leads, setleads] = useState([])
  const [deals, setdeals] = useState([])
  const [assignleads, setassignleads] = useState([])

  const [data, setdata] = useState({})

  const [lead_tracker, setlead_tracker] = useState([])
  const [task_tracker, settask_tracker] = useState([])
  const [meeting_tracker, setmeeting_tracker] = useState([])

  const [datepickervisible, setdatepickervisible] = useState(false);
  const [remarkvisible6, setremarkvisible6] = useState(false);


  const [assign_data, setassign_data] = useState({ department_id: '', employee_id: '', type: '', type_name: '', department_name: '', employee_name: '' })
  const [md_data_filter, setmd_data_filter] = useState({ department_id: '', from_date: '', to_date: '', toDep: '', department_name: '', department_name1: '' })

  const [pagination, setpagination] = useState({})


  const department = useSelector(state => state.Auth.department)
  const roles = useSelector(state => state.Auth.roles)

  const [search_contact, setsearch_contact] = useState({ search_text: '', contact_status: '', from_date: '', to_date: '', department_id: '', employee_id: '' })
  const [tempsearch_contact, settempsearch_contact] = useState({ search_text: '', contact_status: '', from_date: '', to_date: '', department_id: '', employee_id: '' })

  const [search_lead, setsearch_lead] = useState({ search_text: '', lead_stage: [], from_date: '', to_date: '', filter_status: false, department_id: '', employee_id: '' })
  const [tempsearch_lead, settempsearch_lead] = useState({ search_text: '', lead_stage: [], from_date: '', to_date: '', department_id: '', employee_id: '' })

  const [search_deal, setsearch_deal] = useState({ search_text: '', deal_stage: '', from_date: '', to_date: '', filter_status: false, department_id: '', employee_id: '' })
  const [tempsearch_deal, settempsearch_deal] = useState({ search_text: '', deal_stage: '', from_date: '', to_date: '', department_id: '', employee_id: '' })

  const [departement_arr, setdepartement_arr] = useState([])

  const [lead_status_filter, setlead_status_filter] = useState()

  const [deal_status_filter, setdeal_status_filter] = useState()

  const [tab_active, settab_active] = useState('1')
  const [tab_active1, settab_active1] = useState('1')


  let lead_status_basic_gallery_options = [{ id: 'Attempted to Contact', value: 'Attempted to Contact' }, { id: 'Contact in Future', value: 'Contact in Future' }, { id: 'Contacted', value: 'Contacted' }, { id: 'Junk lead', value: 'Junk lead' }, { id: 'Lost lead', value: 'Lost lead' }, { id: 'Not Contacted', value: 'Not Contacted' }, { id: 'Pre Qualified', value: 'Pre Qualified' }, { id: 'Not Qualified', value: 'Not Qualified' }, { id: 'Hold', value: 'Hold' }]
  let lead_status_basic_fms_options = [{ id: 'Meeting/Discussion', value: 'Meeting/Discussion' }, { id: 'Assessment', value: 'Assessment' }, { id: ' Proposal sent', value: ' Proposal sent' }, { id: 'Negotiation', value: 'Negotiation' }, { id: 'Final closure', value: 'Final closure' }, { id: 'Agreement', value: 'Agreement' }, { id: 'Hold', value: 'Hold' }]
  let lead_status_basic_project_options = [{ id: 'BD', value: 'BD' }, { id: 'Design', value: 'Design' }, { id: 'QS', value: 'QS' }, { id: 'MEP', value: 'MEP' }, { id: 'BOQ Review', value: 'BOQ Review' }, { id: 'Contact in Future', value: 'Contact in Future' }, { id: 'Closed', value: 'Closed' }, { id: 'Lost', value: 'Lost' }, { id: 'Junk Lead', value: 'Junk Lead' }, { id: 'Hold', value: 'Hold' }]
  let lead_status_basic_trans_options = [{ id: 'RFQ', value: 'RFQ' }, { id: 'Options sent/Provided', value: 'Options sent/Provided' }, { id: 'Inspection', value: 'Inspection' }, { id: 'Meeting/Discussion', value: 'Meeting/Discussion' }, { id: 'LOI', value: 'LOI' }, { id: 'Agreement/Registration', value: 'Agreement/Registration' }, { id: 'Collection', value: 'Collection' }, { id: 'Lead Lost', value: 'Lead Lost' }, { id: 'Negotiation', value: 'Negotiation' }, { id: 'Hold', value: 'Hold' }]
  let lead_status_basic_hr_options = [{ id: 'Follow up', value: 'Follow up' }, { id: 'Waiting for requirement', value: 'Waiting for requirement' }, { id: 'Closed', value: 'Closed' }, { id: 'lost', value: 'lost' }, { id: 'Hold', value: 'Hold' }]

  const dealStage_proj =
    [
      {
        id: "Execution",
        value: "Execution"
      },
      {
        id: "Handing Over",
        value: "Handing Over"
      },
      {
        id: "Payment Pending",
        value: "Payment Pending"
      },
      {
        id: "Invoice raised",
        value: "Invoice raised"
      },
      {
        id: "Closed",
        value: "Closed"
      },
    ]
  const dealStage_trans =
    [
      {
        id: "Payment Received",
        value: "Payment Received"
      },
      {
        id: "Payment Pending",
        value: "Payment Pending"
      },
      {
        id: "Agreement/Registered",
        value: "Agreement/Registered"
      },
      {
        id: "Invoice Raised",
        value: "Invoice Raised"
      },
    ]
  const dealStage_hr =
    [
      {
        id: "Payment Received",
        value: "Payment Received"
      },
      {
        id: "Payment Pending",
        value: "Payment Pending"
      },
      {
        id: "Deal Closed",
        value: "Deal Closed"
      },
      {
        id: "Service stopped",
        value: "Service stopped"
      },
    ]
  const dealStage_fms =
    [
      {
        id: "Agreement Renewal",
        value: "Agreement Renewal"
      },
      {
        id: "Organic Growth",
        value: "Organic Growth"
      },
      {
        id: "Payment Received",
        value: "Payment Received"
      },
      {
        id: "Payment Pending",
        value: "Payment Pending"
      },
      {
        id: "Invoice Raised",
        value: "Invoice Raised"
      },
    ]
  const dealStage_gallery =
    [
      {
        id: "Payment Received",
        value: "Payment Received"
      },
      {
        id: "Payment Pending",
        value: "Payment Pending"
      },
      {
        id: "Invoise Raised",
        value: "Invoise Raised"
      },
      {
        id: "Work In Progess",
        value: "Work In Proges"
      },
    ]


  const type = [
    {
      'label': 'By Department',
      'value': 'By Department',
      'id': '2'
    },
    {
      'label': 'By Employee',
      'value': 'By Employee',
      'id': '3'
    },
  ]


  const [employee, setemployee] = useState([])
  const [modalstep, setmodalstep] = useState({ step1: false, step2: false, step3: false })


  const contact_status_filter = [
    {
      id: "Active",
      value: "Active"
    },
    {
      id: "In Active",
      value: "In Active"
    },
    {
      id: "Connected",
      value: "Connected"
    },
    {
      id: "Not Connected",
      value: "Not Connected"
    },
    {
      id: "Old Client",
      value: "Old Client"
    }]

  const [lead_status, setlead_status] = useState([])




  useEffect(() => {
    if (roles.includes('hod') && (!roles.includes('admin') && !roles.includes('db_head') && !roles.includes('db_user'))) {
      getcontactshod(1)
      getusersbydepartmentId(department[0].id)
      setassign_data({ ...assign_data, department_name: department[0]?.department_name })
      setsearch_contact({ ...search_contact, department_id: department[0]?.id })
      setsearch_lead({ ...search_lead, department_id: department[0]?.id })
      setsearch_deal({ ...search_deal, department_id: department[0]?.id })
    } else if (!roles.includes('hod') && ((roles.includes('admin') || roles.includes('db_head') || roles.includes('db_user')))) {
      getcontacts(1)
    }
    getDepartements()
  }, [])


  // console.log('assign_data',assign_data)

  function getcontacts(v) {
    setloader(true)
    setpage(v)
    axios.get(`contact/getallcontacts/${v}`, authHeader)
      .then((res) => {
        setpage(v)
        setloader(false)
        // console.log('res.data.datas contacts',res.data)
        setcontacts(res.data.datas)
        setpagination(res.data.pagination)
      })
  }
  function getleads(v) {
    setloader(true)
    setpage(v)
    axios.get(`lead/getallleads/${v}`, authHeader)
      .then((res) => {
        setloader(false)
        setleads(res.data.datas)
        setpagination(res.data.pagination)
      })
  }
  function getdeals(v) {
    setloader(true)
    setpage(v)
    axios.get(`deal/getalldeals/${v}`, authHeader)
      .then((res) => {
        setloader(false)
        setdeals(res.data.datas)
        setpagination(res.data.pagination)
      })
  }
  function getAssignedLeads(v) {
    setpage(v)
    axios.get(`assign/getall/${v}`, authHeader)
      .then((res) => {

        console.log('getAssignedLeads', res.data.datas[0])
        setassignleads(res.data.datas)
        setpagination(res.data.pagination)
      }).catch((err) => {
      })
  }
  function getAssignedLeadsHod(v) {
    setpage(v)
    axios.get(`assign/getallbydepartment/${department[0]?.id}/${v}`, authHeader)
      .then((res) => {
        setassignleads(res.data.datas)
        setpagination(res.data.pagination)
        console.log('res data here', res.data)
      }).catch((err) => {
        console.log('err me na', err)
      })
  }
  function getcontactshod(v, v1) {
    setpage(v)
    axios.get(`contact/search-all?type=${'okk '}&department_id=${department[0]?.id}&employee_id=${assign_data?.employee_id}&search_text=${search_contact?.search_text}&status=${search_contact?.contact_status}&from_date=${search_contact?.from_date}&to_date=${search_contact?.to_date}&page=${v}`, authHeader)
      .then((res) => {
        console.log('res data contact/search-all?', res.data)
        setvisible1(false)
        setsearch_contact({ ...search_contact, department_id: department[0]?.id, filter_status: true })
        setpagination(res.data.pagination)
        setcontacts(res.data.datas)
      })
  }
  function getleadshod(v, v1) {
    setpage(v)
    axios.get(`lead/search-all?type=${'okk'}&department_id=${department[0].id}&employee_id=${assign_data.employee_id}&status=${search_lead.lead_stage}&from_date=${search_lead.from_date}&to_date=${search_lead.to_date}&page=${v}`, authHeader)
      .then((res) => {
        setvisible2(false)
        setsearch_lead({ ...search_lead, department_id: department[0].id, filter_status: true })
        console.log('res filter', res.data)
        setpagination(res.data.pagination)
        setleads(res.data.datas)
      })
  }
  function getdealshod(v, v1) {
    setpage(v)
    axios.get(`deal/search-all?type=${'okk'}&department_id=${department[0].id}&employee_id=${assign_data.employee_id}&deal_stage=${search_deal.deal_stage}&from_date=${search_deal.from_date}&to_date=${search_deal.to_date}&page=${v}`, authHeader)
      .then((res) => {
        setvisible3(false)
        setsearch_deal({ ...search_deal, department_id: department[0].id, filter_status: true })
        console.log('res filter', res.data)
        setpagination(res.data.pagination)
        setdeals(res.data.datas)
      })
  }
  function getDepartements() {
    axios.get('department/get', authHeader)
      .then((res) => {
        const departement_arr_created = []
        res.data.datas.forEach(d => {
          let data = {}
          data['label'] = d.department_name
          data['value'] = d.department_name
          data['id'] = d.id
          departement_arr_created.push(data)
        })
        setdepartement_arr(departement_arr_created)

        let md_team = ['Database Team', 'MD Office', 'Media Team']
        let md_team_updated = departement_arr_created?.filter(d => md_team?.includes(d.value))
        setmd_team(md_team_updated)

      })
  }
  const paginateddata = (v) => {
    if (tab_active === '1') {
      setpage(v)
      axios.get(`contact/getallcontacts/${v}`, authHeader)
        .then((res) => {
          setcontacts(res.data.datas)
          setpagination(res.data.pagination)
        }).catch((err) => {
        })
    } else if (tab_active === '2') {
      getleads(v)
    } else if (tab_active === '3') {
      getdeals(v)
    }
  }
  function setactivetab(v) {
    setpage(1)
    setassign_data({ ...assign_data, department_name: '', department_id: '', employee_id: '', employee_name: '' })
    settempsearch_contact({ ...tempsearch_contact, from_date: '', to_date: '', search_text: '' })
    settempsearch_lead({ ...tempsearch_lead, from_date: '', to_date: '', search_text: '' })
    settempsearch_deal({ ...tempsearch_deal, from_date: '', to_date: '', search_text: '' })

    if (roles.includes('admin') || roles.includes('db_head') || roles.includes('db_user')) {
      setlead_status_filter([])
      setdeal_status_filter([])
    } else if (roles.includes('hod') && (!roles.includes('admin') && !roles.includes('db_head') && !roles.includes('db_user'))) {

      setassign_data({ ...assign_data, department_name: department[0]?.department_name, department_id: department[0]?.id, employee_id: '', employee_name: '' })

      if (departement_arr?.find(d => d?.id === department[0]?.id)?.value === 'Transaction Team') {
        setlead_status_filter(lead_status_basic_trans_options)
        setdeal_status_filter(dealStage_trans)
      } else if (departement_arr?.find(d => d?.id === department[0]?.id)?.value === 'Project Team') {
        setlead_status_filter(lead_status_basic_project_options)
        setdeal_status_filter(dealStage_proj)
      } else if (departement_arr?.find(d => d?.id === department[0]?.id)?.value === 'Hr Team') {
        setlead_status_filter(lead_status_basic_hr_options)
        setdeal_status_filter(dealStage_hr)
      } else if (departement_arr?.find(d => d?.id === department[0]?.id)?.value === 'FMS Team') {
        setlead_status_filter(lead_status_basic_fms_options)
        setdeal_status_filter(dealStage_fms)
      } else if (departement_arr?.find(d => d?.id === department[0]?.id)?.value === 'Gallery Team') {
        setlead_status_filter(lead_status_basic_gallery_options)
        setdeal_status_filter(dealStage_gallery)
      }
    }

    if (v === '1') {
      settab_active(v)
      setsearch_contact({ ...search_contact, department_id: '', employee_id: '', search_text: '', contact_status: '', from_date: '', to_date: '', filter_status: false })
      if (roles.includes('hod') && !roles.includes('admin') && !roles.includes('db_head') && !roles.includes('db_user')) {
        getcontactshod(1)
      } else if (!roles.includes('hod') && (roles.includes('admin') || roles.includes('db_head') || roles.includes('db_user'))) {
        getcontacts(1)
      }
    } else if (v === '2') {
      settab_active(v)
      setsearch_lead({ ...search_lead, department_id: roles.includes('hod') ? department[0].id : '', employee_id: '', search_text: '', lead_stage: '', from_date: '', to_date: '', filter_status: false })
      if (roles.includes('hod') && !roles.includes('admin') && !roles.includes('db_head') && !roles.includes('db_user')) {
        getleadshod(1)
      } else if (!roles.includes('hod') && (roles.includes('admin') || roles.includes('db_head') || roles.includes('db_user'))) {
        getleads(1)
      }
    } else if (v === '3') {
      settab_active(v)
      setsearch_deal({ ...search_deal, department_id: '', employee_id: '', search_text: '', deal_stage: '', from_date: '', to_date: '', filter_status: false })
      if (roles.includes('hod') && !roles.includes('admin') && !roles.includes('db_head') && !roles.includes('db_user')) {
        getdealshod(1)
      } else if (!roles.includes('hod') && (roles.includes('admin') || roles.includes('db_head') || roles.includes('db_user'))) {
        getdeals(1)
      }
    } else if (v === '4') {
      settab_active(v)
      if (roles.includes('hod') && !roles.includes('admin') && !roles.includes('db_head') && !roles.includes('db_user')) {
        getAssignedLeadsHod(1)
      } else if (!roles.includes('hod') && (roles.includes('admin') || roles.includes('db_head') || roles.includes('db_user'))) {
        getAssignedLeads(1)
      }
    } else if (v === '5') {
      setmd_data_filter({ ...md_data_filter, department_id: departement_arr?.find(d => d.value === 'MD Office')?.id, department_name: departement_arr?.find(d => d.value === 'MD Office')?.value })
      settab_active1('1')
      getmdleads(1)
    }
  }
  function setactivetab1(v) {
    setmd_data_filter({ ...md_data_filter, department_id: departement_arr?.find(d => d.value === 'MD Office')?.id, department_name: departement_arr?.find(d => d.value === 'MD Office')?.value, from_date: '', to_date: '', toDep: '', department_name1: '' })
    if (v === '1') {
      getmdleads(1)
      settab_active1(v)
    } else if (v === '2') {
      getmdassignedleads(1)
      settab_active1(v)
    }
  }
  function getusersbydepartmentId(v) {
    axios.get(`user/get-users-by-departmentId/${v}`, authHeader)
      .then((res) => {
        let temp_Arr = []
        res.data.datas.map((d) => {
          let obj = {}
          obj['label'] = d.name
          obj['value'] = d.name
          obj['id'] = d.employee_id
          let des = ''
          d.user_roles.forEach((u) => {
            des = des + u.roles[0].display_name + ','
          })
          obj['label'] = obj['label'] + `(${des})`
          obj['value'] = obj['value'] + `(${des})`
          temp_Arr.push(obj)
        })
        setemployee(temp_Arr)
        console.log('res data', res.data, 'temp_Arr', temp_Arr)
      }).catch((err) => {
        console.log('err', err)
      })
  }
  function getpaginatedcontactsbasedfilter(v) {

    // console.log('search_contact',search_contact)
    setpage(v)
    axios.get(`contact/search-all?type=junk&department_id=${assign_data?.department_id}&employee_id=${assign_data?.employee_id}&search_text=${search_contact?.search_text}&status=${search_contact?.contact_status}&from_date=${tempsearch_contact?.from_date}&to_date=${tempsearch_contact?.to_date}&page=${v}`, authHeader)
      .then((res) => {
        setvisible1(false)
        setsearch_contact({ ...search_contact, filter_status: true })
        setsearch_lead({ ...search_lead, filter_status: false })
        setsearch_deal({ ...search_deal, filter_status: false })
        setpagination(res.data.pagination)
        setcontacts(res.data.datas)
      })
  }
  function getpaginatedleadsbasedfilter(v) {
    setpage(v)
    axios.get(`lead/search-all?type=junk&department_id=${assign_data.department_id}&employee_id=${assign_data.employee_id}&search_text=${search_lead?.search_text}&status=${search_lead?.lead_stage?.join(',')}&from_date=${tempsearch_lead?.from_date}&to_date=${tempsearch_lead?.to_date}&page=${page}`, authHeader)
      .then((res) => {
        setvisible2(false)
        setsearch_contact({ ...search_contact, filter_status: false })
        setsearch_lead({ ...search_lead, filter_status: true })
        setsearch_deal({ ...search_deal, filter_status: false })
        setpagination(res.data.pagination)
        setleads(res.data.datas)
      })
  }
  function getpaginateddealsbasedfilter(v) {
    setpage(v)
    axios.get(`deal/search-all?type=junk&department_id=${assign_data?.department_id}&employee_id=${assign_data?.employee_id}&search_text=${search_deal?.search_text}&deal_stage=${search_deal?.deal_stage}&from_date=${tempsearch_deal?.from_date}&to_date=${tempsearch_deal?.to_date}&page=${v}`, authHeader)
      .then((res) => {
        setvisible3(false)
        setsearch_contact({ ...search_contact, filter_status: false })
        setsearch_lead({ ...search_lead, filter_status: false })
        setsearch_deal({ ...search_deal, filter_status: true })
        setpagination(res.data.pagination)
        setdeals(res.data.datas)
      })
  }
  function applyfilter() {
    setpage(1)
    if (!roles.includes('hod') && ((roles.includes('admin') || roles.includes('db_head') || roles.includes('db_user')))) {
      if (tab_active === '1') {
        getpaginatedcontactsbasedfilter(1)
      } else if (tab_active === '2') {
        getpaginatedleadsbasedfilter(1)
      } else if (tab_active === '3') {
        getpaginateddealsbasedfilter(1)
      } else if (tab_active1 === '1') {
        getmdleads(1)
      } else if (tab_active1 === '2') {
        getmdassignedleads(1)
      }
    }
    else if (roles.includes('hod') && ((!roles.includes('admin') && !roles.includes('db_head') && !roles.includes('db_user')))) {
      if (tab_active === '1') {
        getcontactshod(1)
      } else if (tab_active === '2') {
        getleadshod(1)
      } else if (tab_active === '3') {
        getdealshod(1)
      } else if (tab_active === '4') {
        getAssignedLeadsHod(1)
      }
    }
  }
  function resetfilter() {
    setpage(1)
    if (!roles.includes('hod') && ((roles.includes('admin') || roles.includes('db_head') || roles.includes('db_user')))) {
      setsearch_contact({ ...search_contact, search_text: '', contact_status: '', from_date: '', to_date: '', department_id: '', employee_id: '', filter_status: false })
      settempsearch_contact({ ...tempsearch_contact, search_text: '', contact_status: '', from_date: '', to_date: '', department_id: '', employee_id: '', })
      setassign_data({ ...assign_data, department_id: '', employee_id: '', type: '', type_name: '', department_name: '', employee_name: '' })

      setsearch_lead({ search_text: '', lead_stage: '', from_date: '', to_date: '', filter_status: false, department_id: '', employee_id: '', })
      settempsearch_lead({ search_text: '', lead_stage: '', from_date: '', to_date: '', department_id: '', employee_id: '', })
      setassign_data({ department_id: '', employee_id: '', type: '', type_name: '', department_name: '', employee_name: '' })

      setsearch_deal({ search_text: '', deal_stage: '', from_date: '', to_date: '', filter_status: false, department_id: '', employee_id: '', })
      settempsearch_deal({ search_text: '', deal_stage: '', from_date: '', to_date: '', department_id: '', employee_id: '', })
      setassign_data({ department_id: '', employee_id: '', type: '', type_name: '', department_name: '', employee_name: '' })

      if (tab_active === '1') {
        getcontacts(1)
        setvisible1(false)
      } else if (tab_active === '2') {
        getleads(1)
        setvisible2(false)
      } else if (tab_active === '3') {
        getdeals(1)
        setvisible3(false)
      }
    } else {
      if (tab_active === '1') {
        setsearch_contact({ ...search_contact, search_text: '', contact_status: '', from_date: '', to_date: '', filter_status: false, department_id: department[0].id, employee_id: '', })
        settempsearch_contact({ ...tempsearch_contact, search_text: '', contact_status: '', from_date: '', to_date: '', department_id: department[0].id, employee_id: '', })
        setassign_data({ ...assign_data, department_id: department[0].id, employee_id: '', type: '', type_name: '', department_name: department[0].department_name, employee_name: '' })
        getcontactshod(1)
        setvisible1(false)
      } else if (tab_active === '2') {
        setsearch_lead({ search_text: '', lead_stage: '', from_date: '', to_date: '', filter_status: false, department_id: department[0].id, employee_id: '', })
        settempsearch_lead({ search_text: '', lead_stage: '', from_date: '', to_date: '', department_id: department[0].id, employee_id: '', })
        setassign_data({ department_id: department[0].id, employee_id: '', type: '', type_name: '', department_name: department[0].department_name, employee_name: '' })
        getleadshod(1, 'reset')
        setvisible2(false)
      } else if (tab_active === '3') {
        setsearch_deal({ search_text: '', deal_stage: '', from_date: '', to_date: '', filter_status: false, department_id: department[0].id, employee_id: '', })
        settempsearch_deal({ search_text: '', deal_stage: '', from_date: '', to_date: '', department_id: department[0].id, employee_id: '', })
        setassign_data({ department_id: department[0].id, employee_id: '', type: '', type_name: '', department_name: department[0].department_name, employee_name: '' })
        getdealshod(1, 'reset')
        setvisible3(false)
      }
    }
  }
  function resetfilter1() {
    if (tab_active1 === '1') {
      setmd_data_filter({ ...md_data_filter, department_id: departement_arr?.find(d => d.value === 'MD Office')?.id, department_name: departement_arr?.find(d => d.value === 'MD Office')?.value, from_date: '', to_date: '', toDep: '', department_name1: null })
      getmdleads(1)
    } else if (tab_active1 === '2') {
      setmd_data_filter({ ...md_data_filter, department_id: departement_arr?.find(d => d.value === 'MD Office')?.id, department_name: departement_arr?.find(d => d.value === 'MD Office')?.value, from_date: '', to_date: '', toDep: '', department_name1: null })
      getmdassignedleads(1)
    }
  }
  function handleChange({ key, value, type }) {
    if (visible1) {
      settempsearch_contact({ ...tempsearch_contact, [key]: value })
    } else if (visible2) {
      settempsearch_lead({ ...tempsearch_lead, [key]: value })
    } else if (visible3) {
      settempsearch_deal({ ...tempsearch_deal, [key]: value })
    }
    console.log('value here', key, value)
  }
  // function setdatepickervisiblefun() {
  //   setdatepickervisible(false)
  //   setsearch_lead({ ...search_lead, from_date: '', to_date: '' })
  // }
  // function setselectedDateRange() {
  //   if (!tempsearch_lead.from_date || !tempsearch_lead.to_date) {
  //     message.info('From date or To date might be missing')
  //   } else {
  //     if (tab_active === '1') {
  //       settempsearch_lead({ ...tempsearch_lead, from_date: '', to_date: '' })
  //       setsearch_contact({ ...search_contact, from_date: tempsearch_lead.from_date, to_date: tempsearch_lead.to_date })
  //       setdatepickervisible(false)
  //     } else if (tab_active === '2') {
  //       settempsearch_lead({ ...tempsearch_lead, from_date: '', to_date: '' })
  //       setsearch_lead({ ...search_lead, from_date: tempsearch_lead.from_date, to_date: tempsearch_lead.to_date })
  //       setdatepickervisible(false)
  //     } else if (tab_active === '3') {
  //       settempsearch_lead({ ...tempsearch_lead, from_date: '', to_date: '' })
  //       setsearch_deal({ ...search_deal, from_date: tempsearch_lead.from_date, to_date: tempsearch_lead.to_date })
  //       setdatepickervisible(false)
  //     }

  //   }
  // }
  function handleChangefun(v, v1) {
    if (v1 === 'type') {
      setassign_data({ ...assign_data, [v1]: v, type_name: type.find((t) => t.id === v).value })
      setmodalstep({ ...modalstep, step1: false })
    } else if (v1 === 'department_id') {
      if (tab_active === '1') {
        setsearch_contact({ ...search_contact, department_id: v })
      } else if (tab_active === '2') {
        setsearch_lead({ ...search_lead, department_id: v })
        if (departement_arr?.find(d => d?.id === v)?.value === 'Transaction Team') {
          setlead_status_filter(lead_status_basic_trans_options)
        } else if (departement_arr?.find(d => d?.id === v)?.value === 'Project Team') {
          setlead_status_filter(lead_status_basic_project_options)
        } else if (departement_arr?.find(d => d?.id === v)?.value === 'Hr Team') {
          setlead_status_filter(lead_status_basic_hr_options)
        } else if (departement_arr?.find(d => d?.id === v)?.value === 'FMS Team') {
          setlead_status_filter(lead_status_basic_fms_options)
        } else if (departement_arr?.find(d => d?.id === v)?.value === 'Gallery Team') {
          setlead_status_filter(lead_status_basic_gallery_options)
        } else {
          setlead_status_filter([])
        }
      } else if (tab_active === '3') {
        setsearch_deal({ ...search_deal, department_id: v })
        if (departement_arr.find(d => d.id === v).value === 'Transaction Team') {
          setdeal_status_filter(dealStage_trans)
        } else if (departement_arr.find(d => d.id === v).value === 'Project Team') {
          setdeal_status_filter(dealStage_proj)
        } else if (departement_arr.find(d => d.id === v).value === 'Hr Team') {
          setdeal_status_filter(dealStage_hr)
        } else if (departement_arr.find(d => d.id === v).value === 'FMS Team') {
          setdeal_status_filter(dealStage_fms)
        } else if (departement_arr.find(d => d.id === v).value === 'Gallery Team') {
          setdeal_status_filter(dealStage_gallery)
        }
      }
      setassign_data({ ...assign_data, [v1]: v, department_name: departement_arr.find((d) => d.id === v)?.value })
      getusersbydepartmentId(v)
      setmodalstep({ ...modalstep, step2: false })
    } else if (v1 === 'employee_id') {
      if (tab_active === '1') {
        setsearch_contact({ ...search_contact, employee_id: v })
      } else if (tab_active === '2') {
        setsearch_lead({ ...search_lead, employee_id: v })
      } else if (tab_active === '2') {
        setsearch_deal({ ...search_deal, employee_id: v })
      }
      setassign_data({ ...assign_data, [v1]: v, employee_name: employee.find((d) => d.id === v)?.value })
      setmodalstep({ ...modalstep, step3: false })
    }
  }
  // function letstracklead(v) {
  //   console.log('letstracklead', v)
  //   axios.get(`assign/get-user-lead/${v}`, authHeader)
  //     .then((res) => {
  //       navigate('/track_lead', { state: res.data })

  //       console.log('res data', res.data)
  //     }).catch((err) => {
  //       console.log('err', err)
  //     })
  // }
  function setvisiblefun(v) {
    if (v === '1') {
      setvisible1(true)
    } else if (v === '2') {
      setvisible2(true)
    } else if (v === '3') {
      setvisible3(true)
    } else if (v === '5') {
      setvisible5(true)
    } else if (v === '6') {
      setvisible5(true)
    }
  }
  function setremarkfunc(v) {
    setremark(v)
    setremarkvisible6(true)
  }
  function getmdleads(v) {
    setpage(v)
    setloader(true)
    axios.get(`activity/get-dep-wise-leads?depId=${md_data_filter.department_id === '' ? departement_arr?.find(d => d.value === 'MD Office')?.id : md_data_filter.department_id}&from_date=${md_data_filter?.from_date}&to_date=${md_data_filter?.to_date}&toDep=${md_data_filter?.toDep}&page=${v}`, authHeader)
      .then((res) => {
        setleads(res.data.datas)
        setpagination(res.data.pagination)
        setvisible5(false)
        setloader(false)
      })
      .catch(err => {
        // console.log('err here na', err.response)
      })
  }
  function getmdassignedleads(v) {
    setpage(v)
    setloader(true)
    axios.get(`activity/get-dep-wise-leads-assigned?depId=${md_data_filter.department_id === '' ? departement_arr?.find(d => d.value === 'MD Office')?.id : md_data_filter.department_id}&from_date=${md_data_filter.from_date}&to_date=${md_data_filter.to_date}&toDep=${md_data_filter.toDep}&page=${v}`, authHeader)
      .then((res) => {
        setassignleads(res?.data?.datas)
        setpagination(res?.data.pagination)
        setvisible5(false)
        setloader(false)
      })
      .catch(err => {
      })
  }
  async function tracker(d) {
    await axios.get(`lead/get/${d}`, authHeader).then((res) => {
      setdata(res.data.datas[0])
    })

    await axios.get(`activity/get-timeline/Lead/${d}`, authHeader)
      .then((res) => {
        setlead_tracker(res.data.datas)
        let tracker_arrange = []
        let tracker_arrange1 = []
        res.data.datas.sub.filter(t => t.type === 'Task' && t.history === '<p>Task created</p>' && t.event === 'create').forEach(e1 => {
          tracker_arrange.push(e1)
          if (res.data.datas.sub.filter(t1 => t1.type === 'Task' && t1.event === 'update' && t1.type_id === e1.type_id).length > 0) {
            res.data.datas.sub.filter(t1 => t1.type === 'Task' && t1.event === 'update' && t1.type_id === e1.type_id).forEach(e => {
              tracker_arrange.push(e)
            })
          }
        })
        settask_tracker(tracker_arrange)

        res.data.datas.sub.filter(t => t.type === "Meeting" && t.history === '<p>Meeting created</p>' && t.event === 'create').forEach(e1 => {
          tracker_arrange1.push(e1)
          if (res.data.datas.sub.filter(t1 => t1.type === 'Meeting' && t1.event === 'update' && t1.type_id === e1.type_id).length > 0) {
            res.data.datas.sub.filter(t1 => t1.type === 'Meeting' && t1.event === 'update' && t1.type_id === e1.type_id).forEach(e => {
              tracker_arrange1.push(e)
            })
          }
        })
        setmeeting_tracker(tracker_arrange1)
        setvisible4(true)
      }).catch((err) => {
      })
  }
  function setleadstage(v) {
    let current_search_lead = [...tempsearch_lead.lead_stage]
    if (current_search_lead?.includes(v)) {
      current_search_lead.splice(current_search_lead?.findIndex(v1 => v1 === v), 1)
    } else {
      current_search_lead.push(v)
    }
    console.log('current_search_lead', current_search_lead)
    settempsearch_lead({ ...tempsearch_lead, lead_stage: current_search_lead })
    setsearch_lead({ ...search_lead, lead_stage: current_search_lead })
  }

  // console.log('assign_data',assign_data)
  // console.log('search_lead',search_lead)
  // console.log('search_deal',search_deal)


  // console.log('leads', leads, 'assignleads', assignleads)
  return (
    <div style={{ overflowY: 'hidden', scrollbarWidth: 0, height: '100%' }}>
      {loader && <Loader />}
      <RootNavbar visible={visible} func={() => setvisible(false)} />
      <div style={{ display: 'flex', alignItems: 'center', maxHeight: '45px', minHeight: '45px', zIndex: 100, background: `${main_bg}`, position: 'sticky', width: '100%', top: '0px' }} onClick={() => setvisible(true)}>
        <IoMenu size={30} style={{ marginRight: 5, marginLeft: 5, color: 'white', padding: 5, borderRadius: 4 }} />
        <h6 style={{ fontSize: '15px', fontWeight: '600', marginBottom: '0px', color: '#fff' }}>Company CLD</h6>
      </div>

      <Modal visible={remarkvisible6} style={{ maxWidth: '300px' }} footer={null} centered onCancel={() => setremarkvisible6(false)}>
        <div style={{ borderTopWidth: '4px', borderTopColor: '#1c89ff' }}>

          <h4 style={{ fontSize: '15px', fontWeight: '900' }}>Remark for Assigned Lead </h4>
          <h3 style={{ fontSize: '12px', marginTop: '10px', color: '#4f4f4f', padding: '4px' }}>{remark}</h3>
          <div style={{ borderLeftWidth: '8px', borderLeftColor: '#ffeae8', background: '#ffeae8', padding: '10px' }}>
            <h3 style={{ fontSize: '12px' }}> <strong> Note :</strong> This is the remark added for the assign lead </h3>
          </div>

          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
            <Button style={{ width: '100%', background: '#fff', color: 'black', marginRight: '2%', display: 'hidden', opacity: 0 }} >Cancel</Button>
            <Button style={{ width: '100%', background: `${main_bg}`, color: 'white', marginLeft: '2%', border: 'none' }} onClick={() => setremarkvisible6(false)}>Close</Button>
          </div>
        </div>
      </Modal>

      <Drawer placement={'bottom'} visible={visible1} height={'300px'} width={'85%'} closable={false} maskClosable={false} bodyStyle={{ background: '#fff', margin: '5%', padding: '0px' }}>

        <div style={{ zIndex: 100, position: 'sticky', top: '0px', background: '#fff' }}>
          <div style={{ alignItems: 'center', margin: '0px 0px', marginBottom: '20px' }}>
            <h5 style={{ fontWeight: '800', textAlign: 'center' }}>Filter</h5>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', background: '#f0f0f0', borderRadius: 4, padding: 5, position: 'absolute', left: '0px', top: '0px' }} onClick={() => setvisible1(false)}>
            <IoClose size={12} color='black' />
          </div>
          <h6 style={{ position: 'absolute', right: '0px', top: '0px', fontWeight: '800', color: '#d9d9d9' }} onClick={resetfilter}>Reset</h6>
        </div>



        <div style={{ margin: '10px 0px', paddingBottom: '10px', marginTop: '10px' }}>
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <h6 style={{ fontWeight: '600', fontSize: '14px', }}>Add Search Query</h6>
            </div>

            {search_contact.search_text ?
              <div style={{ display: 'flex', alignItems: 'center', background: '#f8f9fa', padding: '12px', borderRadius: '2px', maxWidth: '100%', position: 'relative' }}>
                <h6 style={{ marginBottom: '0px', fontSize: '12px' }}>{search_contact.search_text.slice(0, 20)}{search_contact.search_text.length > 20 && '...'}</h6>
                <IoClose style={{ marginLeft: '10px', cursor: 'pointer', position: 'absolute', right: '10px' }} onClick={() => setsearch_contact({ ...search_contact, search_text: '' })} />
              </div>
              :
              <div style={{ display: 'flex', width: '100%', border: '1px solid black', height: '40px', borderRadius: '4px', borderColor: '#f5f5f5', overflow: 'hidden', borderRightColor: '#fff', backgroundColor: '#fafafa' }}>
                <Input type={"text"} style={{ width: '100%', margin: 'auto', marginLeft: '0%', padding: '5px', border: 'none', background: '#fafafa', boxShadow: 'none' }} value={tempsearch_contact.search_text} onChange={e => settempsearch_contact({ ...tempsearch_contact, search_text: e.target.value })} />
                <div style={{ marginTop: '-10px' }}>
                  <Button style={{ fontWeight: '800', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '28px 12px', background: `${main_bg}`, color: '#fff' }} onClick={() => setsearch_contact({ ...search_contact, search_text: tempsearch_contact.search_text })}>+</Button>
                </div>
              </div>}
          </div>
        </div>


        <div style={{ margin: '10px 0px', paddingBottom: '10px', marginTop: '10px', width: '100%' }}>
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <h6 style={{ fontWeight: '600', fontSize: '14px', }}>Select Department</h6>
            </div>

            {search_contact.department_id === '' ?
              <Select
                showSearch
                placeholder="Select Department"
                optionFilterProp="children"
                onChange={(v) => handleChangefun(v, 'department_id')}
                size='large'
                value={assign_data.department_name}
                filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                style={{ width: '99%' }}
              >
                {
                  departement_arr.map((d) => (
                    <Option key={d.id} value={d.id}><div>{d.value}</div></Option>
                  ))
                }
              </Select>
              :
              <div style={{ display: 'flex', alignItems: 'center', background: '#f8f9fa', padding: '12px', borderRadius: '2px', maxWidth: '100%', position: 'relative' }}>
                <h6 style={{ marginBottom: '0px', fontSize: '12px' }}>{assign_data.department_name}</h6>
                {!roles.includes('hod') && <IoClose style={{ marginLeft: '10px', cursor: 'pointer', position: 'absolute', right: '10px' }} onClick={() => { setsearch_contact({ ...search_contact, department_id: '', employee_id: '' }); setassign_data({ ...assign_data, department_id: '', department_name: '', employee_id: '', employee_name: '' }) }} />}
              </div>
            }

          </div>
        </div>

        {search_contact.department_id &&
          <>
            <div style={{ margin: '10px 0px', paddingBottom: '10px', marginTop: '10px', width: '100%' }}>
              <div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <h6 style={{ fontWeight: '600', fontSize: '14px', }}>Select Employee</h6>
                </div>
                {assign_data.employee_id === '' ?

                  <Select
                    showSearch
                    placeholder="Select Employee"
                    optionFilterProp="children"
                    onChange={(v) => handleChangefun(v, 'employee_id')}
                    size='large'
                    value={assign_data.employee_name}
                    filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    style={{ width: '99%' }}
                  >
                    {
                      employee.map((d) => (
                        <Option key={d.id} value={d.id}><div>{d.value}</div></Option>
                      ))
                    }
                  </Select>
                  :
                  <div style={{ display: 'flex', alignItems: 'center', background: '#f8f9fa', padding: '14px', borderRadius: '2px', maxWidth: '99%', position: 'relative' }}>
                    <h6 style={{ marginBottom: '0px', fontSize: '12px' }}>{assign_data.employee_name}</h6>
                    <IoClose style={{ marginLeft: '10px', cursor: 'pointer', position: 'absolute', right: '10px' }} onClick={() => { setsearch_contact({ ...search_contact, employee_id: '' }); setassign_data({ ...assign_data, employee_id: '', employee_name: '' }) }} />
                  </div>
                }
              </div>
            </div>

          </>
        }

        <div style={{ margin: '10px 0px', paddingBottom: '10px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <h6 style={{ fontWeight: '600', fontSize: '14px', }}>Date Interval</h6>
            {/* {step === '3' ? <HiChevronUp size={22}  color="#c7c7c7"  onClick={()=>setstep('')} /> : <HiChevronDown size={22} color="#c7c7c7"  onClick={()=>setstep('2')}/>} */}
          </div>

          <div>
            <div>
              {tempsearch_contact.from_date && tempsearch_contact.to_date ?
                <div style={{ width: '100%', background: '#fafafa', padding: '2px', height: '40px', borderRadius: '5px', position: 'relative' }}>
                  <p style={{ paddingBottom: '0px', marginTop: '8px', paddingLeft: '10px' }}>{tempsearch_contact.from_date} - {tempsearch_contact.to_date}</p>
                  <IoClose style={{ marginLeft: '10px', cursor: 'pointer', position: 'absolute', right: '10px', top: '12px' }} onClick={() => settempsearch_contact({ ...tempsearch_contact, from_date: '', to_date: '' })} />

                </div>
                :
                <Row style={{ width: '100%', margin: 'auto', display: 'flex' }}>
                  <Col span={12} style={{ background: 'white', padding: '0px 5px', width: '100%' }}>
                    <h6 style={{ fontSize: '12px', color: 'gray' }}>From Date</h6>
                    {/* {tempsearch_contact.from_date ? <Tag style={{fontWeight:'600',fontSize:'13px',margin:'1.5%',padding:'4px 6px'}}>{tempsearch_contact.from_date}</Tag> : */}
                    <DateField
                      onChangefun={handleChange}
                      name={'from_date'}
                      type="1"
                      size={'large'}
                      style={{ width: '100%', margin: 'auto', marginLeft: '0%', padding: '5px', border: 'none', background: '#fafafa', boxShadow: 'none' }}
                      date_value={tempsearch_contact.from_date}
                    />
                    {/* } */}
                  </Col>

                  <Col span={12} style={{ background: 'white', padding: '0px 5px' }}>
                    <h6 style={{ fontSize: '12px', color: 'gray' }}>To Date</h6>
                    {/* {tempsearch_contact.to_date ? <Tag style={{fontWeight:'600',fontSize:'13px',margin:'1.5%',padding:'4px 6px'}}>{tempsearch_contact.to_date}</Tag> : */}
                    <DateField
                      name={'to_date'}
                      type="1"
                      size={'large'}
                      onChangefun={handleChange}
                      date_value={tempsearch_contact.to_date}
                      style={{ width: '100%' }} />
                    {/* } */}
                  </Col>

                </Row>}
            </div>
          </div>
        </div>


        <div style={{ margin: '10px 0px', paddingBottom: '10px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <h6 style={{ fontWeight: '600', fontSize: '14px', }}>Contact Status</h6>
          </div>

          <div>
            <Row style={{ width: '100%', margin: 'auto' }}>
              {contact_status_filter?.map((s, i) => (
                <>
                  <Tag onClick={() => setsearch_contact({ ...search_contact, contact_status: s.value })} color={search_contact.contact_status === s.value ? "#0e68bf" : "default"} style={{ fontWeight: '600', fontSize: '13px', margin: '1.5%', padding: '4px 6px' }}>{s.value}</Tag>
                </>
              ))}
            </Row>
          </div>
        </div>



        <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
          {/* <div style={{display:'flex',justifyContent:'center',marginTop:'10px',marginRight:'10px'}} onClick={resetfilter}>
                    <Button size='large' style={{background:'#f5f5f7',border:'none',borderRadius:'5px',color:'black',fontWeight:'500',display:'flex',justifyContent:'center',justifyItems:'center'}}>Reset Filter</Button>
                    </div> */}
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', width: '100%' }} onClick={applyfilter}>
            <Button size='large' style={{ background: `${main_bg}`, border: 'none', borderRadius: '5px', color: 'white', fontWeight: '500', display: 'flex', justifyContent: 'center', justifyItems: 'center', width: '100%' }}>Apply Filter</Button>
          </div>
        </div>
      </Drawer>

      <Drawer placement={'bottom'} visible={visible2} height={'300px'} width={'85%'} closable={false} maskClosable={false} bodyStyle={{ background: '#fff', margin: '5%', padding: '0px' }}>

        <div style={{ alignItems: 'center', margin: '0px 0px', marginBottom: '20px' }}>
          <h5 style={{ fontWeight: '800', textAlign: 'center' }}>Filter</h5>

        </div>
        <div style={{ display: 'flex', alignItems: 'center', background: '#f0f0f0', borderRadius: 4, padding: 5, position: 'absolute', left: '20px', top: '16px' }} onClick={() => setvisible2(false)}>
          <IoClose size={12} color='black' onClick={() => setvisible2(false)} />
        </div>

        <h6 style={{ position: 'absolute', right: '20px', top: '20px', fontWeight: '800', color: '#d9d9d9' }} onClick={resetfilter}>Reset</h6>



        <div style={{ height: '100%', overflow: 'scroll', paddingBottom: '100px' }}>

          <div style={{ margin: '10px 0px', paddingBottom: '10px', marginTop: '10px' }}>
            <div>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h6 style={{ fontWeight: '600', fontSize: '14px', }}>Add Search Query</h6>
              </div>

              {search_lead?.search_text ?
                <div style={{ display: 'flex', alignItems: 'center', background: '#f8f9fa', padding: '12px', borderRadius: '2px', maxWidth: '100%', position: 'relative' }}>
                  <h6 style={{ marginBottom: '0px', fontSize: '12px' }}>{search_lead?.search_text?.slice(0, 20)}{search_contact?.search_text?.length > 20 && '...'}</h6>
                  <IoClose style={{ marginLeft: '10px', cursor: 'pointer', position: 'absolute', right: '10px' }} onClick={() => setsearch_lead({ ...search_lead, search_text: '' })} />
                </div>
                :
                <div style={{ display: 'flex', width: '100%', border: '1px solid black', height: '40px', borderRadius: '4px', borderColor: '#f5f5f5', overflow: 'hidden', borderRightColor: '#fff', backgroundColor: '#fafafa' }}>
                  <Input type={"text"} style={{ width: '100%', margin: 'auto', marginLeft: '0%', padding: '5px', border: 'none', background: '#fafafa', boxShadow: 'none' }} value={tempsearch_lead.search_text} onChange={e => settempsearch_lead({ ...tempsearch_lead, search_text: e.target.value })} />
                  <div style={{ marginTop: '-10px' }}>
                    <Button style={{ fontWeight: '800', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '28px 12px', background: `${main_bg}`, color: '#fff' }} onClick={() => setsearch_lead({ ...search_lead, search_text: tempsearch_lead.search_text })}>+</Button>
                  </div>
                </div>}
            </div>
          </div>


          <div style={{ margin: '10px 0px', paddingBottom: '10px', marginTop: '10px', width: '100%' }}>
            <div>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h6 style={{ fontWeight: '600', fontSize: '14px', }}>Select Department</h6>
              </div>

              {search_lead.department_id === '' ?
                <Select
                  showSearch
                  placeholder="Select Department"
                  optionFilterProp="children"
                  onChange={(v) => handleChangefun(v, 'department_id')}
                  size='large'
                  value={assign_data.department_name}
                  filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  style={{ width: '99%' }}
                >
                  {
                    departement_arr.map((d) => (
                      <Option key={d.id} value={d.id}><div>{d.value}</div></Option>
                    ))
                  }
                </Select>
                :
                <div style={{ display: 'flex', alignItems: 'center', background: '#f8f9fa', padding: '12px', borderRadius: '2px', maxWidth: '100%', position: 'relative' }}>
                  <h6 style={{ marginBottom: '0px', fontSize: '12px' }}>{assign_data.department_name}</h6>
                  {!roles.includes('hod') && <IoClose style={{ marginLeft: '10px', cursor: 'pointer', position: 'absolute', right: '10px' }} onClick={() => { setsearch_lead({ ...search_lead, department_id: '', employee_id: '' }); setassign_data({ ...assign_data, department_id: '', department_name: '', employee_id: '', employee_name: '' }) }} />}
                </div>
              }

            </div>
          </div>

          {search_lead.department_id &&
            <>
              <div style={{ margin: '10px 0px', paddingBottom: '10px', marginTop: '10px', width: '100%' }}>
                <div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h6 style={{ fontWeight: '600', fontSize: '14px', }}>Select Employee</h6>
                  </div>
                  {assign_data.employee_id === '' ?

                    <Select
                      showSearch
                      placeholder="Select Employee"
                      optionFilterProp="children"
                      onChange={(v) => handleChangefun(v, 'employee_id')}
                      size='large'
                      value={assign_data.employee_name}
                      filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      style={{ width: '99%' }}
                    >
                      {
                        employee.map((d) => (
                          <Option key={d.id} value={d.id}><div>{d.value}</div></Option>
                        ))
                      }
                    </Select>
                    :
                    <div style={{ display: 'flex', alignItems: 'center', background: '#f8f9fa', padding: '14px', borderRadius: '2px', maxWidth: '99%', position: 'relative' }}>
                      <h6 style={{ marginBottom: '0px', fontSize: '12px' }}>{assign_data.employee_name}</h6>
                      <IoClose style={{ marginLeft: '10px', cursor: 'pointer', position: 'absolute', right: '10px' }} onClick={() => { setsearch_lead({ ...search_lead, employee_id: '' }); setassign_data({ ...assign_data, employee_id: '', employee_name: '' }) }} />
                    </div>
                  }
                </div>
              </div>

            </>
          }

          <div style={{ margin: '10px 0px', paddingBottom: '10px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <h6 style={{ fontWeight: '600', fontSize: '14px', }}>Date Interval</h6>
              {/* {step === '3' ? <HiChevronUp size={22}  color="#c7c7c7"  onClick={()=>setstep('')} /> : <HiChevronDown size={22} color="#c7c7c7"  onClick={()=>setstep('2')}/>} */}
            </div>

            <div>
              <div>
                {tempsearch_lead.from_date && tempsearch_lead.to_date ?
                  <div style={{ width: '100%', background: '#fafafa', padding: '2px', height: '40px', borderRadius: '5px', position: 'relative' }}>
                    <p style={{ paddingBottom: '0px', marginTop: '8px', paddingLeft: '10px' }}>{tempsearch_lead.from_date} - {tempsearch_lead.to_date}</p>
                    <IoClose style={{ marginLeft: '10px', cursor: 'pointer', position: 'absolute', right: '10px', top: '12px' }} onClick={() => settempsearch_lead({ ...search_lead, from_date: '', to_date: '' })} />

                  </div>
                  :
                  <Row style={{ width: '100%', margin: 'auto', display: 'flex' }}>
                    <Col span={12} style={{ background: 'white', padding: '0px 5px', width: '100%' }}>
                      <h6 style={{ fontSize: '12px', color: 'gray' }}>From Date</h6>
                      {/* {tempsearch_contact.from_date ? <Tag style={{fontWeight:'600',fontSize:'13px',margin:'1.5%',padding:'4px 6px'}}>{tempsearch_contact.from_date}</Tag> : */}
                      <DateField
                        onChangefun={handleChange}
                        name={'from_date'}
                        type="1"
                        size={'large'}
                        style={{ width: '100%', margin: 'auto', marginLeft: '0%', padding: '5px', border: 'none', background: '#fafafa', boxShadow: 'none' }}
                        date_value={tempsearch_lead.from_date}
                      />
                      {/* } */}
                    </Col>

                    <Col span={12} style={{ background: 'white', padding: '0px 5px' }}>
                      <h6 style={{ fontSize: '12px', color: 'gray' }}>To Date</h6>
                      {/* {tempsearch_contact.to_date ? <Tag style={{fontWeight:'600',fontSize:'13px',margin:'1.5%',padding:'4px 6px'}}>{tempsearch_contact.to_date}</Tag> : */}
                      <DateField
                        name={'to_date'}
                        type="1"
                        size={'large'}
                        onChangefun={handleChange}
                        date_value={tempsearch_lead.to_date}
                        style={{ width: '100%' }} />
                      {/* } */}
                    </Col>

                  </Row>}
              </div>
            </div>
          </div>


          {lead_status_filter?.length > 0 &&
            <div style={{ margin: '10px 0px', paddingBottom: '10px' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h6 style={{ fontWeight: '600', fontSize: '14px', }}>Lead Stage</h6>
              </div>

              <div>
                <Row style={{ width: '100%', margin: 'auto' }}>
                  {lead_status_filter?.map((s, i) => (
                    <>
                      <Tag onClick={() => setleadstage(s?.value)} color={lead_status?.lead_stage?.includes(s?.value) ? "#0e68bf" : "default"} style={{ fontWeight: '600', fontSize: '13px', margin: '1.5%', padding: '4px 6px' }}>{s?.value}</Tag>
                    </>
                  ))}
                </Row>
              </div>
            </div>}



          <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
            {/* <div style={{display:'flex',justifyContent:'center',marginTop:'10px',marginRight:'10px'}} onClick={resetfilter}>
                    <Button size='large' style={{background:'#f5f5f7',border:'none',borderRadius:'5px',color:'black',fontWeight:'500',display:'flex',justifyContent:'center',justifyItems:'center'}}>Reset Filter</Button>
                    </div> */}
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', width: '100%' }} onClick={applyfilter}>
              <Button size='large' style={{ background: `${main_bg}`, border: 'none', borderRadius: '5px', color: 'white', fontWeight: '500', display: 'flex', justifyContent: 'center', justifyItems: 'center', width: '100%' }}>Apply Filter</Button>
            </div>
          </div>
        </div>
      </Drawer>

      <Drawer placement={'bottom'} visible={visible3} height={'300px'} width={'85%'} closable={false} maskClosable={false} bodyStyle={{ background: '#fff', margin: '5%', padding: '0px' }}>

        <div style={{ alignItems: 'center', margin: '0px 0px', marginBottom: '20px' }}>
          <h5 style={{ fontWeight: '800', textAlign: 'center' }}>Filter</h5>

        </div>
        <div style={{ display: 'flex', alignItems: 'center', background: '#f0f0f0', borderRadius: 4, padding: 5, position: 'absolute', left: '20px', top: '18px' }} onClick={() => setvisible3(false)}>
          <IoClose size={12} color='black' onClick={() => setvisible3(false)} />
        </div>

        <h6 style={{ position: 'absolute', right: '20px', top: '22px', fontWeight: '800', color: '#d9d9d9' }} onClick={resetfilter}>Reset</h6>



        <div style={{ height: '100%', overflow: 'scroll', paddingBottom: '100px' }}>
          <div style={{ margin: '10px 0px', paddingBottom: '10px', marginTop: '10px' }}>
            <div>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h6 style={{ fontWeight: '600', fontSize: '14px', }}>Add Search Query</h6>
              </div>

              {search_deal?.search_text ?
                <div style={{ display: 'flex', alignItems: 'center', background: '#f8f9fa', padding: '12px', borderRadius: '2px', maxWidth: '100%', position: 'relative' }}>
                  <h6 style={{ marginBottom: '0px', fontSize: '12px' }}>{search_deal?.search_text?.slice(0, 20)}</h6>
                  <IoClose style={{ marginLeft: '10px', cursor: 'pointer', position: 'absolute', right: '10px' }} onClick={() => setsearch_deal({ ...search_deal, search_text: '' })} />
                </div>
                :
                <div style={{ display: 'flex', width: '100%', border: '1px solid black', height: '40px', borderRadius: '4px', borderColor: '#f5f5f5', overflow: 'hidden', borderRightColor: '#fff', backgroundColor: '#fafafa' }}>
                  <Input type={"text"} style={{ width: '100%', margin: 'auto', marginLeft: '0%', padding: '5px', border: 'none', background: '#fafafa', boxShadow: 'none' }} value={tempsearch_deal.search_text} onChange={e => settempsearch_deal({ ...tempsearch_deal, search_text: e.target.value })} />
                  <div style={{ marginTop: '-10px' }}>
                    <Button style={{ fontWeight: '800', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '28px 12px', background: `${main_bg}`, color: '#fff' }} onClick={() => setsearch_deal({ ...search_deal, search_text: tempsearch_deal.search_text })}>+</Button>
                  </div>
                </div>}
            </div>
          </div>


          <div style={{ margin: '10px 0px', paddingBottom: '10px', marginTop: '10px', width: '100%' }}>
            <div>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h6 style={{ fontWeight: '600', fontSize: '14px', }}>Select Department</h6>
              </div>

              {search_deal.department_id === '' ?
                <Select
                  showSearch
                  placeholder="Select Department"
                  optionFilterProp="children"
                  onChange={(v) => handleChangefun(v, 'department_id')}
                  size='large'
                  value={assign_data.department_name}
                  filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  style={{ width: '99%' }}
                >
                  {
                    departement_arr.map((d) => (
                      <Option key={d.id} value={d.id}><div>{d.value}</div></Option>
                    ))
                  }
                </Select>
                :
                <div style={{ display: 'flex', alignItems: 'center', background: '#f8f9fa', padding: '12px', borderRadius: '2px', maxWidth: '100%', position: 'relative' }}>
                  <h6 style={{ marginBottom: '0px', fontSize: '12px' }}>{assign_data.department_name}</h6>
                  {!roles.includes('hod') && <IoClose style={{ marginLeft: '10px', cursor: 'pointer', position: 'absolute', right: '10px' }} onClick={() => { setsearch_deal({ ...search_deal, department_id: '', employee_id: '' }); setassign_data({ ...assign_data, department_id: '', department_name: '', employee_id: '', employee_name: '' }) }} />}
                </div>
              }

            </div>
          </div>

          {search_deal.department_id &&
            <>
              <div style={{ margin: '10px 0px', paddingBottom: '10px', marginTop: '10px', width: '100%' }}>
                <div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h6 style={{ fontWeight: '600', fontSize: '14px', }}>Select Employee</h6>
                  </div>
                  {assign_data.employee_id === '' ?

                    <Select
                      showSearch
                      placeholder="Select Employee"
                      optionFilterProp="children"
                      onChange={(v) => handleChangefun(v, 'employee_id')}
                      size='large'
                      value={assign_data.employee_name}
                      filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      style={{ width: '99%' }}
                    >
                      {
                        employee.map((d) => (
                          <Option key={d.id} value={d.id}><div>{d.value}</div></Option>
                        ))
                      }
                    </Select>
                    :
                    <div style={{ display: 'flex', alignItems: 'center', background: '#f8f9fa', padding: '14px', borderRadius: '2px', maxWidth: '99%', position: 'relative' }}>
                      <h6 style={{ marginBottom: '0px', fontSize: '12px' }}>{assign_data.employee_name}</h6>
                      <IoClose style={{ marginLeft: '10px', cursor: 'pointer', position: 'absolute', right: '10px' }} onClick={() => { setsearch_deal({ ...search_deal, employee_id: '' }); setassign_data({ ...assign_data, employee_id: '', employee_name: '' }) }} />
                    </div>
                  }
                </div>
              </div>

            </>
          }

          <div style={{ margin: '10px 0px', paddingBottom: '10px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <h6 style={{ fontWeight: '600', fontSize: '14px', }}>Date Interval</h6>
              {/* {step === '3' ? <HiChevronUp size={22}  color="#c7c7c7"  onClick={()=>setstep('')} /> : <HiChevronDown size={22} color="#c7c7c7"  onClick={()=>setstep('2')}/>} */}
            </div>

            <div>
              <div>
                {tempsearch_deal.from_date && tempsearch_deal.to_date ?
                  <div style={{ width: '100%', background: '#fafafa', padding: '2px', height: '40px', borderRadius: '5px', position: 'relative' }}>
                    <p style={{ paddingBottom: '0px', marginTop: '8px', paddingLeft: '10px' }}>{tempsearch_deal.from_date} - {tempsearch_deal.to_date}</p>
                    <IoClose style={{ marginLeft: '10px', cursor: 'pointer', position: 'absolute', right: '10px', top: '12px' }} onClick={() => settempsearch_deal({ ...tempsearch_deal, from_date: '', to_date: '' })} />

                  </div>
                  :
                  <Row style={{ width: '100%', margin: 'auto', display: 'flex' }}>
                    <Col span={12} style={{ background: 'white', padding: '0px 5px', width: '100%' }}>
                      <h6 style={{ fontSize: '12px', color: 'gray' }}>From Date</h6>
                      {/* {tempsearch_contact.from_date ? <Tag style={{fontWeight:'600',fontSize:'13px',margin:'1.5%',padding:'4px 6px'}}>{tempsearch_contact.from_date}</Tag> : */}
                      <DateField
                        onChangefun={handleChange}
                        name={'from_date'}
                        type="1"
                        size={'large'}
                        style={{ width: '100%', margin: 'auto', marginLeft: '0%', padding: '5px', border: 'none', background: '#fafafa', boxShadow: 'none' }}
                        date_value={tempsearch_deal.from_date}
                      />
                      {/* } */}
                    </Col>

                    <Col span={12} style={{ background: 'white', padding: '0px 5px' }}>
                      <h6 style={{ fontSize: '12px', color: 'gray' }}>To Date</h6>
                      {/* {tempsearch_contact.to_date ? <Tag style={{fontWeight:'600',fontSize:'13px',margin:'1.5%',padding:'4px 6px'}}>{tempsearch_contact.to_date}</Tag> : */}
                      <DateField
                        name={'to_date'}
                        type="1"
                        size={'large'}
                        onChangefun={handleChange}
                        date_value={tempsearch_deal.to_date}
                        style={{ width: '100%' }} />
                      {/* } */}
                    </Col>

                  </Row>}
              </div>
            </div>
          </div>


          {deal_status_filter?.length > 0 &&
            <div style={{ margin: '10px 0px', paddingBottom: '10px' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h6 style={{ fontWeight: '600', fontSize: '14px', }}>Deal Stage</h6>
              </div>

              <div>
                <Row style={{ width: '100%', margin: 'auto' }}>
                  {deal_status_filter?.map((s, i) => (
                    <>
                      <Tag onClick={() => setsearch_deal({ ...search_deal, deal_stage: s.value })} color={search_deal?.deal_stage === s.value ? "#0e68bf" : "default"} style={{ fontWeight: '600', fontSize: '13px', margin: '1.5%', padding: '4px 6px' }}>{s.value}</Tag>
                    </>
                  ))}
                </Row>
              </div>
            </div>}



          <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
            {/* <div style={{display:'flex',justifyContent:'center',marginTop:'10px',marginRight:'10px'}} onClick={resetfilter}>
                    <Button size='large' style={{background:'#f5f5f7',border:'none',borderRadius:'5px',color:'black',fontWeight:'500',display:'flex',justifyContent:'center',justifyItems:'center'}}>Reset Filter</Button>
                    </div> */}
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', width: '100%' }} onClick={applyfilter}>
              <Button size='large' style={{ background: `${main_bg}`, border: 'none', borderRadius: '5px', color: 'white', fontWeight: '500', display: 'flex', justifyContent: 'center', justifyItems: 'center', width: '100%' }}>Apply Filter</Button>
            </div>
          </div>

        </div>
      </Drawer>

      <Drawer title="Basic Drawer" placement="bottom" onClose={() => setvisible4(false)} visible={visible4} height={window.innerHeight / 1.3} width={window.innerWidth} closable={false} headerStyle={{ display: 'none' }}  >
        <h4 style={{ fontSize: '20px', fontWeight: '800' }}>TimeLine <AiOutlineClockCircle size={22} style={{ margin: '0px 5px' }} /> </h4>
        <Timeline style={{ marginTop: '30px', width: '100%' }} >

          {lead_tracker?.base?.filter((l => l.type === 'Lead' && (l?.history?.includes('Converted from contact ')))).map(t => (
            <TimelineItem>
              <TimelineOppositeContent color="text.secondary">
                <h6 style={{ fontSize: '12px', color: '#0ca7fa', fontWeight: 'bold' }}> {moment(data?.contact_id?.createdAt).format('lll')}</h6>
              </TimelineOppositeContent>
              <TimelineSeparator>
                {t.event === 'convert' && <IoCreateOutline size={20} color="#171636" />}
                {/* {t.event === 'update' && <AiOutlineEdit size={20} color="#171636"/>} */}
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent style={{ maxWidth: '155px', backgroundColor: '#fff' }}>
                <h6 style={{ fontSize: '12px', fontWeight: '600', marginBottom: '0px', paddingBottom: '0px' }}>Contact Created</h6>
                <span style={{ marginleft: '0px', fontSize: '11px', color: '#0087f5', fontWeight: '700', cursor: 'pointer', paddingLeft: '0px', marginTop: '0px', paddingBottom: '2px' }} onClick={() => navigate(`/contact_detail/${t?.history.split(':')[1]}`)}>View Details</span>
                <h6 style={{ fontSize: '11px', fontWeigt: '600', color: '#858585', marginBottom: '0px', paddingBottom: '0px' }}>{t?.user_id?.name}</h6>
              </TimelineContent>
            </TimelineItem>
          ))}

          {lead_tracker?.base?.filter((l => l.type === 'Lead' && (l?.history?.includes('Converted from contact ')))).map(t => (
            <TimelineItem>
              <TimelineOppositeContent color="text.secondary">
                <h6 style={{ fontSize: '12px', color: '#0ca7fa', fontWeight: 'bold' }}> {moment(t?.createdAt).format('lll')}</h6>
              </TimelineOppositeContent>
              <TimelineSeparator>
                {t.event === 'convert' && <BiTransfer size={20} color="#171636" />}
                {/* {t.event === 'update' && <AiOutlineEdit size={20} color="#171636"/>} */}
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent style={{ maxWidth: '155px', backgroundColor: '#fff' }}>
                <h6 style={{ fontSize: '12px', fontWeight: '600', marginBottom: '0px', paddingBottom: '0px' }} >{t?.history.split(':')[0]}</h6>
                <span style={{ marginleft: '0px', fontSize: '11px', color: '#0087f5', fontWeight: '700', cursor: 'pointer', paddingLeft: '0px', marginTop: '0px', paddingBottom: '2px' }} onClick={() => navigate(`/contact_detail/${t?.history.split(':')[1]}`)}>View Details</span>
                <h6 style={{ fontSize: '11px', fontWeigt: '600', color: '#858585', marginBottom: '0px', paddingBottom: '0px' }}>{t?.user_id?.name}</h6>
              </TimelineContent>
            </TimelineItem>
          ))}

          {lead_tracker?.base?.filter((l => l.type === 'Lead' && (l?.history?.includes('<p>Lead Created</p>')))).map(t => (
            <TimelineItem>
              <TimelineOppositeContent color="text.secondary">
                <h6 style={{ fontSize: '12px', color: '#0ca7fa', fontWeight: 'bold' }}> {moment(t?.createdAt).format('lll')}</h6>
              </TimelineOppositeContent>
              <TimelineSeparator>
                {t.event === 'create' && <IoCreateOutline size={20} color="#171636" />}
                {t.event === 'update' && <AiOutlineEdit size={20} color="#171636" />}
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent style={{ maxWidth: '155px', backgroundColor: '#fff' }}>
                <h6 style={{ fontSize: '12px', fontWeight: '600', marginBottom: '0px', paddingBottom: '0px' }} dangerouslySetInnerHTML={{ __html: t?.history }}></h6>
                {/* <span style={{marginleft:'0px',fontSize:'11px',color:'#0087f5',fontWeight:'700',cursor:'pointer',paddingLeft:'0px',marginTop:'0px',paddingBottom:'2px'}} onClick={()=>navigate(`/contact_detail/${t?.history.split(':')[1]}`)}>View Details</span> */}
                <h6 style={{ fontSize: '11px', fontWeigt: '600', color: '#858585', marginBottom: '0px', paddingBottom: '0px' }}>{t?.user_id?.name}</h6>
              </TimelineContent>
            </TimelineItem>
          ))}

          {lead_tracker?.base?.filter((l => l.event === "assign_lead")).map(t => (
            <TimelineItem>
              <TimelineOppositeContent color="text.secondary">
                <h6 style={{ fontSize: '12px', color: '#0ca7fa', fontWeight: 'bold' }}> {moment(t?.createdAt).format('lll')}</h6>
              </TimelineOppositeContent>
              <TimelineSeparator>
                {t.event === 'assign_lead' && <IoCreateOutline size={20} color="#171636" />}
                {/* {t.event === 'update' && <AiOutlineEdit size={20} color="#171636"/>} */}
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent style={{ maxWidth: '155px', backgroundColor: '#fff' }}>
                <h6 style={{ fontSize: '12px', fontWeight: '600', marginBottom: '0px', paddingBottom: '0px' }} dangerouslySetInnerHTML={{ __html: t?.history }}></h6>
                {/* <span style={{marginleft:'0px',fontSize:'11px',color:'#0087f5',fontWeight:'700',cursor:'pointer',paddingLeft:'0px',marginTop:'0px',paddingBottom:'2px'}} onClick={()=>navigate(`/contact_detail/${t?.history.split(':')[1]}`)}>View Details</span> */}
                <h6 style={{ fontSize: '11px', fontWeigt: '600', color: '#858585', marginBottom: '0px', paddingBottom: '0px' }}>{t?.user_id?.name}</h6>
              </TimelineContent>
            </TimelineItem>
          ))}

          {lead_tracker?.base?.filter((l => l.type === 'Lead' && l.event === 'update')).map(t => (
            <TimelineItem>
              <TimelineOppositeContent color="text.secondary">
                <h6 style={{ fontSize: '12px', color: '#0ca7fa', fontWeight: 'bold' }}> {moment(t?.createdAt).format('lll')}</h6>
              </TimelineOppositeContent>
              <TimelineSeparator>
                {t.event === 'create' && <IoCreateOutline size={20} color="#171636" />}
                {t.event === 'update' && <AiOutlineEdit size={20} color="#171636" />}
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent style={{ maxWidth: '155px', backgroundColor: '#fff' }}>
                <h6 style={{ fontSize: '12px', fontWeight: '600', marginBottom: '0px', paddingBottom: '0px' }} dangerouslySetInnerHTML={{ __html: t?.history }}></h6>
                {/* <span style={{marginleft:'0px',fontSize:'11px',color:'#0087f5',fontWeight:'700',cursor:'pointer',paddingLeft:'0px',marginTop:'0px',paddingBottom:'2px'}} onClick={()=>navigate(`/contact_detail/${t?.history.split(':')[1]}`)}>View Details</span> */}
                <h6 style={{ fontSize: '11px', fontWeigt: '600', color: '#858585', marginBottom: '0px', paddingBottom: '0px' }}>{t?.user_id?.name}</h6>
              </TimelineContent>
            </TimelineItem>
          ))}

          {lead_tracker?.sub?.filter((l => l.type === 'Deal')).map(t => (
            <TimelineItem>
              <TimelineOppositeContent color="text.secondary">
                <h6 style={{ fontSize: '12px', color: '#0ca7fa', fontWeight: 'bold' }}> {moment(t?.createdAt).format('lll')}</h6>
              </TimelineOppositeContent>
              <TimelineSeparator>
                {t.event === 'create' && <IoCreateOutline size={20} color="#171636" />}
                {t.event === 'convert' && <BiTransfer size={20} color="#171636" />}
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent style={{ maxWidth: '155px', backgroundColor: '#fff' }}>
                <h6 style={{ fontSize: '12px', fontWeight: '600', marginBottom: '0px', paddingBottom: '0px' }} >{t?.history?.replace(/(<([^>]+)>)/gi, "").split(':')[0]}</h6>
                {t.event === 'create' ?
                  <>
                    {lead_tracker?.sub?.find((l => l.type === 'Deal' && l.history.includes('Converted from lead'))) &&
                      <span style={{ marginleft: '0px', fontSize: '11px', color: '#0087f5', fontWeight: '700', cursor: 'pointer', paddingLeft: '0px', marginTop: '0px', paddingBottom: '2px' }} onClick={() => navigate(`/deal_detail/${lead_tracker?.sub?.find((l => l.type === 'Deal' && l.history.includes('Converted from lead')))?.type_id}`)}>View Details</span>
                    }
                  </>
                  :
                  <span style={{ marginleft: '0px', fontSize: '11px', color: '#0087f5', fontWeight: '700', cursor: 'pointer', paddingLeft: '0px', marginTop: '0px', paddingBottom: '2px' }} onClick={() => navigate(`/deal_detail/${t?.type_id}`)}>View Details</span>
                }
                <h6 style={{ fontSize: '11px', fontWeigt: '600', color: '#858585', marginBottom: '0px', paddingBottom: '0px' }}>{t?.user_id?.name}</h6>
              </TimelineContent>
            </TimelineItem>
          ))}

          {task_tracker.map(t => (
            <TimelineItem>
              <TimelineOppositeContent color="text.secondary">
                <h6 style={{ fontSize: '12px', color: '#0ca7fa', fontWeight: 'bold' }}> {moment(t?.createdAt).format('lll')}</h6>
              </TimelineOppositeContent>
              <TimelineSeparator>
                {t.event === 'create' && <IoCreateOutline size={20} color="#171636" />}
                {t.event === 'update' && <AiOutlineEdit size={20} color="#171636" />}
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent style={{ maxWidth: '155px', backgroundColor: '#fff' }}>
                <h6 style={{ fontSize: '12px', fontWeight: '900', marginBottom: '0px', paddingBottom: '2px' }} dangerouslySetInnerHTML={{ __html: t?.history }}></h6>
                <h6 style={{ fontSize: '11px', fontWeigt: '600', color: '#858585', marginBottom: '0px', paddingBottom: '0px' }}>{t?.user_id?.name}</h6>
              </TimelineContent>
            </TimelineItem>
          ))}

          {meeting_tracker?.map(t => (
            <TimelineItem>
              <TimelineOppositeContent color="text.secondary">
                <h6 style={{ fontSize: '12px', color: '#0ca7fa', fontWeight: 'bold' }}> {moment(t?.createdAt).format('lll')}</h6>
              </TimelineOppositeContent>
              <TimelineSeparator>
                {t.event === 'create' && <IoCreateOutline size={20} color="#171636" />}
                {t.event === 'update' && <AiOutlineEdit size={20} color="#171636" />}
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent style={{ maxWidth: '155px', backgroundColor: '#fff' }}>
                <h6 style={{ fontSize: '12px', fontWeight: '900', marginBottom: '0px', paddingBottom: '2px' }} dangerouslySetInnerHTML={{ __html: t?.history }}></h6>
                <h6 style={{ fontSize: '11px', fontWeigt: '600', color: '#858585', marginBottom: '0px', paddingBottom: '0px' }}>{t?.user_id?.name}</h6>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </Drawer>

      <Drawer placement={'bottom'} visible={visible5} height={'300px'} width={'85%'} closable={false} maskClosable={false} bodyStyle={{ background: '#fff', margin: '5%', padding: '0px' }}>

        <div style={{ zIndex: 100, position: 'sticky', top: '0px', background: '#fff' }}>
          <div style={{ alignItems: 'center', margin: '0px 0px', marginBottom: '20px' }}>
            <h5 style={{ fontWeight: '800', textAlign: 'center' }}>Filter</h5>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', background: '#f0f0f0', borderRadius: 4, padding: 5, position: 'absolute', left: '0px', top: '0px' }} onClick={() => setvisible3(false)}>
            <IoClose size={12} color='black' onClick={() => setvisible5(false)} />
          </div>

          <h6 style={{ position: 'absolute', right: '0px', top: '0px', fontWeight: '800', color: '#d9d9d9' }} onClick={resetfilter1}>Reset</h6>
        </div>
        <div style={{ margin: '10px 0px', paddingBottom: '10px', marginTop: '10px' }}>



          <div style={{ margin: '10px 0px', paddingBottom: '10px', marginTop: '10px', width: '100%' }}>
            <div>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h6 style={{ fontWeight: '600', fontSize: '14px', }}>Select Department</h6>
              </div>

              {md_data_filter?.department_id === '' ?
                <Select
                  showSearch
                  placeholder="Select Department"
                  optionFilterProp="children"
                  onChange={(v) => { setmd_data_filter({ ...md_data_filter, department_id: v, department_name: md_team?.find(m => m.id === v)?.value }) }}
                  size='large'
                  value={md_data_filter?.department_name}
                  filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  style={{ width: '99%' }}
                >
                  {
                    md_team.map((d) => (
                      <Option key={d.id} value={d.id} ><div>{d.value}</div></Option>
                    ))
                  }
                </Select>
                :
                <div style={{ display: 'flex', alignItems: 'center', background: '#f8f9fa', padding: '12px', borderRadius: '2px', maxWidth: '100%', position: 'relative' }}>
                  <h6 style={{ marginBottom: '0px', fontSize: '12px' }}>{md_team?.find(v => v.id === md_data_filter.department_id)?.value}</h6>
                  <IoClose style={{ marginLeft: '10px', cursor: 'pointer', position: 'absolute', right: '10px' }} onClick={() => setmd_data_filter({ ...md_data_filter, department_id: '', department_name: null })} />
                </div>
              }

            </div>
          </div>



          <div style={{ margin: '10px 0px', paddingBottom: '10px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <h6 style={{ fontWeight: '600', fontSize: '14px', }}>Date Interval</h6>
              {/* {step === '3' ? <HiChevronUp size={22}  color="#c7c7c7"  onClick={()=>setstep('')} /> : <HiChevronDown size={22} color="#c7c7c7"  onClick={()=>setstep('2')}/>} */}
            </div>

            <div>
              <div>
                {md_data_filter?.from_date !== '' && md_data_filter?.to_date !== '' ?
                  <div style={{ width: '100%', background: '#fafafa', padding: '2px', height: '40px', borderRadius: '5px', position: 'relative' }}>
                    <p style={{ paddingBottom: '0px', marginTop: '8px', paddingLeft: '10px' }}>{md_data_filter?.from_date + ':' + md_data_filter?.to_date}</p>
                    <IoClose style={{ marginLeft: '10px', cursor: 'pointer', position: 'absolute', right: '10px', top: '12px' }} onClick={() => setmd_data_filter({ ...md_data_filter, from_date: '', to_date: '' })} />
                  </div>
                  :
                  <Row style={{ width: '100%', margin: 'auto', display: 'flex' }}>
                    <Col span={12} style={{ background: 'white', padding: '0px 5px', width: '100%' }}>
                      <h6 style={{ fontSize: '12px', color: 'gray' }}>From Date</h6>
                      {/* {tempsearch_contact.from_date ? <Tag style={{fontWeight:'600',fontSize:'13px',margin:'1.5%',padding:'4px 6px'}}>{tempsearch_contact.from_date}</Tag> : */}
                      <DateField
                        onChangefun={(v) => setmd_data_filter({ ...md_data_filter, from_date: v.value })}
                        name={'from_date'}
                        type="1"
                        size={'large'}
                        style={{ width: '100%', margin: 'auto', marginLeft: '0%', padding: '5px', border: 'none', background: '#fafafa', boxShadow: 'none' }}
                        date_value={md_data_filter?.from_date}
                      />
                      {/* } */}
                    </Col>

                    <Col span={12} style={{ background: 'white', padding: '0px 5px' }}>
                      <h6 style={{ fontSize: '12px', color: 'gray' }}>To Date</h6>
                      {/* {tempsearch_contact.to_date ? <Tag style={{fontWeight:'600',fontSize:'13px',margin:'1.5%',padding:'4px 6px'}}>{tempsearch_contact.to_date}</Tag> : */}
                      <DateField
                        name={'to_date'}
                        type="1"
                        size={'large'}
                        onChangefun={(v) => setmd_data_filter({ ...md_data_filter, to_date: v.value })}
                        date_value={md_data_filter?.to_date}
                        style={{ width: '100%' }} />
                      {/* } */}
                    </Col>

                  </Row>}
              </div>
            </div>
          </div>


          <div style={{ margin: '10px 0px', paddingBottom: '10px', marginTop: '10px', width: '100%' }}>
            <div>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h6 style={{ fontWeight: '600', fontSize: '14px', }}>Select Department</h6>
              </div>

              {md_data_filter?.toDep === '' ?
                <Select
                  showSearch
                  placeholder="Select Department"
                  optionFilterProp="children"
                  onChange={(v) => { setmd_data_filter({ ...md_data_filter, toDep: v, department_name1: departement_arr?.find(m => m.id === v)?.value }) }}
                  size='large'
                  value={md_data_filter?.department_name1}
                  filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  style={{ width: '99%' }}
                >
                  {
                    departement_arr.map((d) => (
                      <Option key={d.id} value={d.id}><div>{d.value}</div></Option>
                    ))
                  }
                </Select>
                :
                <div style={{ display: 'flex', alignItems: 'center', background: '#f8f9fa', padding: '12px', borderRadius: '2px', maxWidth: '100%', position: 'relative' }}>
                  <h6 style={{ marginBottom: '0px', fontSize: '12px' }}>{md_data_filter?.department_name1}</h6>
                  <IoClose style={{ marginLeft: '10px', cursor: 'pointer', position: 'absolute', right: '10px' }} onClick={() => setmd_data_filter({ ...md_data_filter, toDep: '', department_name1: null })} />
                </div>
              }

            </div>
          </div>



          <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', width: '100%' }} onClick={tab_active1 === '1' ? () => getmdleads(1) : () => getmdassignedleads(1)}>
              <Button size='large' style={{ background: `${main_bg}`, border: 'none', borderRadius: '5px', color: 'white', fontWeight: '500', display: 'flex', justifyContent: 'center', justifyItems: 'center', width: '100%' }}>Apply Filter</Button>
            </div>
          </div>
        </div>
      </Drawer>


      <div style={{ width: '96%', zIndex: 10, height: '100%', margin: 'auto' }}>
        <h6 style={{ fontWeight: '900', marginTop: '20px' }}>COMPANY CLD</h6>
        <Tabs
          defaultActiveKey={tab_active}
          size="small"
          style={{ borderTop: '1px solid #f2f2f2' }}
          onChange={(v) => setactivetab(v)}
        >
          <TabPane tab={`Contacts`} key="1">
            <h6 style={{ fontSize: '13px', color: '#677078' }}> <strong style={{ color: 'black' }}>({pagination.total})</strong> contacts found </h6>
            {contacts.length > 0 ?
              <div style={{ background: '#fff', scrollbarColor: '#fff', overflowY: 'scroll', paddingBottom: '50px' }}>
                <TableView1
                  data={contacts}
                  type={'Contact1'}
                  detailfun={(d) => navigate(`/contact_detail/${d?.id !== undefined ? d?.id : d?._id} `)}

                />

              </div>
              :
              <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '140px', objectFit: 'contain' }} />
                <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center' }}>{search_contact.filter_status ? 'No Contact found for that search criteria' : "You don't have any contact added yet."}</h4>
                <h6 style={{ color: '#747474', fontSize: '12px', maxWidth: '80%', textAlign: 'center', margin: 'auto' }}>{search_deal.filter_status ? 'Try other seach filter option for expected result' : "Visit a contact create page and press on a add contact button. Then, you can find the contact on this page."}</h6>
              </div>
            }

            <div style={{ position: 'fixed', right: '30px', width: '40px', bottom: '50px', backgroundColor: '#222f3e', padding: 8, borderRadius: 20 }}>
              <IoFlask size={24} color="#fff" onClick={() => setvisiblefun('1')} />
            </div>

            {pagination.total > 20 &&
              <div style={{ width: '100%', display: 'grid', alignItems: 'center', position: 'fixed', bottom: '0px', paddingBottom: '10px', paddingTop: '10px', marginLeft: '-10px', background: 'white' }}>
                <div style={{ display: 'flex', flexDirection: 'row', width: '38%', justifyContent: 'space-between', alignSelf: 'center', justifySelf: 'center' }}>
                  {page === 1 ?
                    <h6 style={{ color: '#d6d6d6', fontWeight: '600' }}>{'< Prev'}</h6>
                    :
                    <h6 style={{ color: '#0073ba', fontWeight: '600' }} onClick={() => contact_status_filter.filter_status ? getpaginatedcontactsbasedfilter(page - 1) : paginateddata(page - 1)}>{'< Prev'}</h6 >
                  }
                  <h6 style={{ fontWeight: '800' }}> {page} </h6>
                  {pagination.totalPages === page ?
                    <h6 style={{ color: '#d6d6d6', fontWeight: '600' }}>{'Next >'}</h6>
                    :
                    <h6 style={{ color: '#0073ba', fontWeight: '600' }} onClick={() => contact_status_filter.filter_status ? getpaginatedcontactsbasedfilter(page + 1) : paginateddata(page + 1)}>{'Next >'}</h6>
                  }
                </div>
              </div>}
          </TabPane>
          <TabPane tab={`Leads`} key="2">
            <h6 style={{ fontSize: '13px', color: '#677078' }}> <strong style={{ color: 'black' }}>({pagination.total})</strong> leads found </h6>
            {leads.length > 0 ?
              <div style={{ background: '#fff', scrollbarColor: '#fff', overflowY: 'scroll', paddingBottom: '50px' }}>
                <TableView1
                  data={leads}
                  type={'Leads1'}
                  detailfun={(d) => navigate(`/lead_detail/${d?.id || d?._id}`)}

                />



                {pagination.total > 20 &&
                  <div style={{ width: '100%', display: 'grid', alignItems: 'center', position: 'fixed', bottom: '0px', paddingBottom: '10px', paddingTop: '10px', marginLeft: '-10px', background: 'white' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '38%', justifyContent: 'space-between', alignSelf: 'center', justifySelf: 'center' }}>
                      {page === 1 ?
                        <h6 style={{ color: '#d6d6d6', fontWeight: '600' }}>{'< Prev'}</h6>
                        :
                        <h6 style={{ color: '#0073ba', fontWeight: '600' }} onClick={() => search_lead.filter_status ? getpaginatedleadsbasedfilter(page - 1) : paginateddata(page - 1)}>{'< Prev'}</h6 >
                      }
                      <h6 style={{ fontWeight: '800' }}> {page} </h6>
                      {pagination.totalPages === page ?
                        <h6 style={{ color: '#d6d6d6', fontWeight: '600' }}>{'Next >'}</h6>
                        :
                        <h6 style={{ color: '#0073ba', fontWeight: '600' }} onClick={() => search_lead?.filter_status ? getpaginatedleadsbasedfilter(page + 1) : paginateddata(page + 1)}>{'Next >'}</h6>
                      }
                    </div>
                  </div>}
              </div>
              :
              <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '140px', objectFit: 'contain' }} />
                <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center' }}>{search_lead.filter_status ? 'No Lead found for that search criteria' : "You don't have any lead created yet."}</h4>
                <h6 style={{ color: '#747474', fontSize: '12px', maxWidth: '80%', textAlign: 'center', margin: 'auto' }}>{search_lead.filter_status ? 'Try other seach filter option for expected result' : 'Visit a contact page and press on a convert icon. Then, you can find the deal on this page.'}</h6>
              </div>
            }

            <div style={{ position: 'fixed', right: '30px', width: '40px', bottom: '50px', backgroundColor: '#222f3e', padding: 8, borderRadius: 20 }}>
              <IoFlask size={24} color="#fff" onClick={() => setvisiblefun('2')} />
            </div>

          </TabPane>
          <TabPane tab={`Deals`} key="3">
            <h6 style={{ fontSize: '13px', color: '#677078' }}> <strong style={{ color: 'black' }}>({pagination.total})</strong> deals found </h6>
            {deals.length > 0 ?
              <div style={{ background: '#fff', scrollbarColor: '#fff', overflowY: 'scroll', paddingBottom: '50px' }}>
                <TableView1
                  data={deals}
                  type={'Deals1'}
                  detailfun={(d) => navigate(`/deal_detail/${d.id}`)}

                />



                {pagination.total > 20 &&
                  <div style={{ width: '100%', display: 'grid', alignItems: 'center', position: 'fixed', bottom: '0px', paddingBottom: '10px', paddingTop: '10px', marginLeft: '-10px', background: 'white' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '38%', justifyContent: 'space-between', alignSelf: 'center', justifySelf: 'center' }}>
                      {page === 1 ?
                        <h6 style={{ color: '#d6d6d6', fontWeight: '600' }}>{'< Prev'}</h6>
                        :
                        <h6 style={{ color: '#0073ba', fontWeight: '600' }} onClick={() => search_deal.filter_status ? getpaginatedcontactsbasedfilter(page - 1) : paginateddata(page - 1)}>{'< Prev'}</h6 >
                      }
                      <h6 style={{ fontWeight: '800' }}> {page} </h6>
                      {pagination.totalPages === page ?
                        <h6 style={{ color: '#d6d6d6', fontWeight: '600' }}>{'Next >'}</h6>
                        :
                        <h6 style={{ color: '#0073ba', fontWeight: '600' }} onClick={() => search_deal.filter_status ? getpaginatedcontactsbasedfilter(page + 1) : paginateddata(page + 1)}>{'Next >'}</h6>
                      }
                    </div>
                  </div>}
              </div>
              :
              <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '140px', objectFit: 'contain' }} />
                <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center' }}>{search_deal.filter_status ? 'No Deal found for that search criteria' : "you don't have any deal created yet."}</h4>
                <h6 style={{ color: '#747474', fontSize: '12px', maxWidth: '80%', textAlign: 'center', margin: 'auto' }}>{search_deal.filter_status ? 'Try other seach filter option for expected result' : 'Visit a lead page and press on a convert icon. Then, you can find the deal on this page.'}</h6>
              </div>
            }

            <div style={{ position: 'fixed', right: '30px', width: '40px', bottom: '50px', backgroundColor: '#222f3e', padding: 8, borderRadius: 20 }}>
              <IoFlask size={24} color="#fff" onClick={() => setvisiblefun('3')} />
            </div>

          </TabPane>
          <TabPane tab={`Assign Leads`} key="4">
            <h6 style={{ fontSize: '13px', color: '#677078' }}> <strong style={{ color: 'black' }}>({pagination.total})</strong> Assigned lead's Found </h6>
            {assignleads.length > 0 && tab_active === '4' ?
              <div style={{ background: '#fff', scrollbarColor: '#fff', overflowY: 'scroll', paddingBottom: '50px' }}>
                <TableView1
                  data={assignleads}
                  type={'assignleads'}
                  detailfun={(d) => navigate(`/deal_detail/${d.id}`)}
                  remarkfunc={(v) => setremarkfunc(v)}
                  tracker={v => tracker(v)}


                />

                {pagination.total > 20 &&
                  <div style={{ width: '100%', display: 'grid', alignItems: 'center', position: 'fixed', bottom: '0px', paddingBottom: '10px', paddingTop: '10px', marginLeft: '-10px', background: 'white' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '38%', justifyContent: 'space-between', alignSelf: 'center', justifySelf: 'center' }}>
                      {page === 1 ?
                        <h6 style={{ color: '#d6d6d6', fontWeight: '600' }}>{'< Prev'}</h6>
                        :
                        <h6 style={{ color: '#0073ba', fontWeight: '600' }} onClick={() => search_deal.filter_status ? getpaginatedcontactsbasedfilter(page - 1) : paginateddata(page - 1)}>{'< Prev'}</h6 >
                      }
                      <h6 style={{ fontWeight: '800' }}> {page} </h6>
                      {pagination.totalPages === page ?
                        <h6 style={{ color: '#d6d6d6', fontWeight: '600' }}>{'Next >'}</h6>
                        :
                        <h6 style={{ color: '#0073ba', fontWeight: '600' }} onClick={() => search_deal.filter_status ? getpaginatedcontactsbasedfilter(page + 1) : paginateddata(page + 1)}>{'Next >'}</h6>
                      }
                    </div>
                  </div>}
              </div>
              :
              <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '140px', objectFit: 'contain' }} />
                <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center' }}>{search_deal.filter_status ? 'No Assign leads found for that search criteria' : "you don't have any assigned leads created yet."}</h4>
                <h6 style={{ color: '#747474', fontSize: '12px', maxWidth: '80%', textAlign: 'center', margin: 'auto' }}>{search_deal.filter_status ? 'Try other seach filter option for expected result' : 'Visit a lead page and press on a convert icon. Then, you can find the deal on this page.'}</h6>
              </div>
            }

          </TabPane>
          {(roles.includes('admin') || roles.includes('db_head') || roles.includes('db_user')) &&
            <TabPane tab="MD Team Leads" key="5">
              <Tabs
                defaultActiveKey={tab_active1}
                size="small"
                style={{ width: '100%', margin: '10px auto', marginBottom: '0px', marginTop: '-10px', marginLeft: '5px' }}
                onChange={(v) => setactivetab1(v)}>
                <TabPane tab="All Leads" key="1">
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', }} >
                    <p style={{ paddingBottom: '0px', color: 'black', marginBottom: '10px' }}>{pagination.total} MD Lead's Found</p>
                  </div>

                  <div style={{ position: 'fixed', right: '30px', width: '40px', bottom: '50px', backgroundColor: '#222f3e', padding: 8, borderRadius: 20 }}>
                    <IoFlask size={24} color="#fff" onClick={() => setvisiblefun('5')} />
                  </div>
                  {leads.length > 0 ?
                    <>
                      <TableView1
                        data={leads}
                        type={'Leads2'}
                        detailfun={(d) => navigate(`/lead_detail/${d?.id || d?._id}`)}

                      />


                      {pagination.total > 20 &&
                        <div style={{ width: '100%', display: 'grid', alignItems: 'center', position: 'fixed', bottom: '0px', paddingBottom: '10px', paddingTop: '10px', marginLeft: '-10px', background: 'white' }}>
                          <div style={{ display: 'flex', flexDirection: 'row', width: '38%', justifyContent: 'space-between', alignSelf: 'center', justifySelf: 'center' }}>
                            {page === 1 ?
                              <h6 style={{ color: '#d6d6d6', fontWeight: '600' }}>{'< Prev'}</h6>
                              :
                              <h6 style={{ color: '#0073ba', fontWeight: '600' }} onClick={() => getmdleads(page - 1)}>{'< Prev'}</h6 >
                            }
                            <h6 style={{ fontWeight: '800' }}> {page} </h6>
                            {pagination.totalPages === page ?
                              <h6 style={{ color: '#d6d6d6', fontWeight: '600' }}>{'Next >'}</h6>
                              :
                              <h6 style={{ color: '#0073ba', fontWeight: '600' }} onClick={() => getmdleads(page + 1)}>{'Next >'}</h6>
                            }
                          </div>
                        </div>}


                    </>
                    :
                    <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                      <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '140px', objectFit: 'contain' }} />
                      <h4 style={{ fontSize: '14px', fontWeight: '700', marginTop: '10px', textAlign: 'center' }}>No Assigned Leads found.</h4>
                    </div>
                  }
                </TabPane>
                <TabPane tab="Assigned Leads" key="2">
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', }} >
                    <p style={{ paddingBottom: '0px', color: 'black' }}>{pagination?.total} MD Assigned lead's Found</p>

                  </div>

                  <div style={{ position: 'fixed', right: '30px', width: '40px', bottom: '50px', backgroundColor: '#222f3e', padding: 8, borderRadius: 20 }}>
                    <IoFlask size={24} color="#fff" onClick={() => setvisiblefun('6')} />
                  </div>

                  {assignleads.length > 0 ?
                    <>
                      <TableView1
                        data={assignleads}
                        type={'assignleads1'}
                        remarkfunc={(v) => setremarkfunc(v)}
                        tracker={v => tracker(v)}
                      />



                      {pagination.total > 20 &&
                        <div style={{ width: '100%', display: 'grid', alignItems: 'center', position: 'fixed', bottom: '0px', paddingBottom: '10px', paddingTop: '10px', marginLeft: '-10px', background: 'white' }}>
                          <div style={{ display: 'flex', flexDirection: 'row', width: '38%', justifyContent: 'space-between', alignSelf: 'center', justifySelf: 'center' }}>
                            {page === 1 ?
                              <h6 style={{ color: '#d6d6d6', fontWeight: '600' }}>{'< Prev'}</h6>
                              :
                              <h6 style={{ color: '#0073ba', fontWeight: '600' }} onClick={() => getmdassignedleads(page - 1)}>{'< Prev'}</h6 >
                            }
                            <h6 style={{ fontWeight: '800' }}> {page} </h6>
                            {pagination.totalPages === page ?
                              <h6 style={{ color: '#d6d6d6', fontWeight: '600' }}>{'Next >'}</h6>
                              :
                              <h6 style={{ color: '#0073ba', fontWeight: '600' }} onClick={() => getmdassignedleads(page + 1)}>{'Next >'}</h6>
                            }
                          </div>
                        </div>}

                    </>
                    :
                    <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                      <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '140px', objectFit: 'contain' }} />
                      <h4 style={{ fontSize: '14px', fontWeight: '700', marginTop: '10px', textAlign: 'center' }}>No MD Assigned Leads found.</h4>
                    </div>
                  }
                </TabPane>
              </Tabs>

            </TabPane>}
        </Tabs>
      </div>

    </div >
  )
}

export default TeamCLD