import React from 'react';
import FmsDashboard from '../dashboard/fms_dashboard/FmsDashboard';


function FmsDashboardMainRoot(props){
    return(
        <div style={{display:'flex',flexDirection:'row',marginTop:'0px',paddingTop:'0px'}}>
        <div style={{flex:0.03,boxSizing:'border-box',width:'100%',minHeight:'100%'}}>
          <FmsDashboard />
        </div> 
        <div style={{flex:0.97,boxSizing:'border-box',width:'100%',maxHeight:'100vh',overflowY:'scroll',marginLeft:'190px',paddingTop:'30px',}}>
            {props.children}
        </div>

      </div>
    )
}

export default FmsDashboardMainRoot