import React,{useEffect, useState} from 'react'
import FmsDashboardMainRoot from '../FmsDashboardMainRoot'
import axios from "axios";
import Dragger from "antd/lib/upload/Dragger";
import toast,{ Toaster } from 'react-hot-toast';
import { authHeader } from '../../../helpers/usertoken/UserToken';
import TextArea from 'antd/lib/input/TextArea'

import { MdOutlineDeleteOutline } from 'react-icons/md'

import PrimaryButton, { SecondaryButton } from '../../../components/buttons/PrimaryButton'
import { BoldText1, SmallText } from '../../../helpers/constants/ConstantsText';
import { useNavigate } from 'react-router-dom';

function FmsHrPolicy() {

    const navigator = useNavigate()

    const [data,setdata] = useState({docs1_english:'',docs1_kannada:'',docs1_hindi:'',docs2_english:'',docs2_kannada:'',docs2_hindi:'',docs1_english_text:'',docs1_kannada_text:'',docs1_hindi_text:'',docs1_1_english_text:'',docs1_1_kannada_text:'',docs1_1_hindi_text:'',docs2_english_text:'',docs2_kannada_text:'',docs2_hindi_text:''})
    const [loader, setloader] = useState(false)
    
    useEffect(()=>{
        getData()
    },[])

    console.log("data kp",data)


    const handleUpload = (v,e) => {

   
            authHeader['headers']['Content-Type'] = 'multipart/form-data'
            var fd = new FormData()
            fd.append('file',v?.fileList[v?.fileList.length - 1]?.originFileObj)
            axios.post(`fms/fmshrpolicy/upload_file`,fd,authHeader)
            .then((res)=>{
               setdata({...data,[e]:res?.data?.data})
            }).catch((err)=>{
                console.log("err data",err)
            })


        // setdata({...data,[e]:v.fileList[0].originFileObj})

    }
    const reselectfile = (v) => {
        setdata({...data,[v]:''})
    }
    function submitdata() {
       
            // authHeader['headers']['Content-Type'] = 'multipart/form-data'
            // const fd = new FormData()
            // if(data?.docs1_english?.name !== undefined){
            //     fd.append('docs1_english', data?.docs1_english)
            // }
            // if(data?.docs1_kannada?.name !== undefined){
            //     fd.append('docs1_kannada', data?.docs1_kannada)
            // }
            // if(data?.docs1_hindi?.name !== undefined){
            // fd.append('docs1_hindi', data?.docs1_hindi)
            // }
            // if(data?.docs2_english?.name !== undefined){
            // fd.append('docs2_english', data?.docs2_english)
            // }
            // if(data?.docs2_kannada?.name !== undefined){
            // fd.append('docs2_kannada', data?.docs2_kannada)
            // }
            // if(data?.docs2_hindi?.name !== undefined){
            // fd.append('docs2_hindi', data?.docs2_hindi)
            // }


            // fd.append('docs1_english_text', data?.docs1_english_text)
            // fd.append('docs1_kannada_text', data?.docs1_kannada_text)
            // fd.append('docs1_hindi_text', data?.docs1_hindi_text)
            // fd.append('docs1_1_english_text', data?.docs1_1_english_text)
            // fd.append('docs1_1_kannada_text', data?.docs1_1_kannada_text)
            // fd.append('docs1_1_hindi_text', data?.docs1_1_hindi_text)
            // fd.append('docs2_english_text', data?.docs2_english_text)
            // fd.append('docs2_kannada_text', data?.docs2_kannada_text)
            // fd.append('docs2_hindi_text', data?.docs2_hindi_text)

            
            setloader(true)


            let passData = {
                'docs1_english':data?.docs1_english,
                'docs1_kannada':data?.docs1_kannada,
                'docs1_hindi':data?.docs1_hindi,
                'docs2_english':data?.docs2_english,
                'docs2_kannada':data?.docs2_kannada,
                'docs2_hindi':data?.docs2_hindi,
                'docs1_english_text' : data?.docs1_english_text,
                'docs1_kannada_text' : data?.docs1_kannada_text,
                'docs1_hindi_text' : data?.docs1_hindi_text,
                'docs1_1_english_text' : data?.docs1_1_english_text,
                'docs1_1_kannada_text' : data?.docs1_1_kannada_text,
                'docs1_1_hindi_text' : data?.docs1_1_hindi_text,
                'docs2_english_text' : data?.docs2_english_text,
                'docs2_kannada_text' : data?.docs2_kannada_text,
                'docs2_hindi_text' : data?.docs2_hindi_text
            }

            if(data?._id !== undefined){
                axios.post(`fms/fmshrpolicy/update/${data?._id}`, passData, authHeader)
                .then((res) => {
                    if (res.status === 200) {
                        getData()
                        toast.success(res.data.datas)
                    } else if (res.status === 206) {
                        toast.success('Partially data uploaded')
                    }
                    setloader(false)
                    setdata({docs1_english:'',docs1_kannada:'',docs1_hindi:'',docs2_english:'',docs2_kannada:'',docs2_hindi:'',docs1_english_text:'',docs1_kannada_text:'',docs1_hindi_text:'',docs1_1_english_text:'',docs1_1_kannada_text:'',docs1_1_hindi_text:'',docs2_english_text:'',docs2_kannada_text:'',docs2_hindi_text:''})
                }).catch((err)=>{
                    setloader(false)
                })
            }else{
                axios.post(`fms/fmshrpolicy/create`, passData, authHeader)
                    .then((res) => {
                        getData()
                        if (res.status === 201) {
                            toast.success(res.data.datas)
                        } else if (res.status === 206) {
                            toast.success('Partially data uploaded')
                        }
                        setloader(false)
                        setdata({docs1_english:'',docs1_kannada:'',docs1_hindi:'',docs2_english:'',docs2_kannada:'',docs2_hindi:'',docs1_english_text:'',docs1_kannada_text:'',docs1_hindi_text:'',docs1_1_english_text:'',docs1_1_kannada_text:'',docs1_1_hindi_text:'',docs2_english_text:'',docs2_kannada_text:'',docs2_hindi_text:''})
                    }).catch((err)=>{
                        setloader(false)
                    })
            }    
       
    }

    async function getData(){
        axios.get(`fms/fmshrpolicy/get`, authHeader)
        .then((res) => {
            if(res?.data?.data?.length > 0){
                setdata({...res?.data?.data[0]})
            }
        })       
    }

  return (
    <FmsDashboardMainRoot>
        <div style={{width:'96%'}}>
            <div style={{display:'flex',borderBottom:'1px solid #eee',alignItems:'center',width:'100%',justifyContent:'space-between'}}>
            <BoldText1 val={`Fms Hr Policy Data`} />
            </div>    

                <div style={{margin:'30px 0PX'}}>
                    <Toaster />
                   <div style={{width:'300px'}}>
                   
                    <div style={{marginBottom:'5px'}}>
                   <SmallText fontWeight={'600'} val={'Docs 1 English'}  />
                    {data?.docs1_english === undefined || data?.docs1_english === null || data?.docs1_english === ''  ?
                    <Dragger style={{ marginTop: '0px' }}
                        onChange={(e)=>handleUpload(e,'docs1_english')}
                        beforeUpload={() => false}
                        showUploadList={false}>
                        
                        <div style={{display:'flex',alignItems:'center',flexDirection:'column'}}>
                        
                        </div>
                    </Dragger>
                    :
                    <div>
                        <div style={{ display: 'flex', alignItems: 'center', border: '1px solid lightgray', padding: '6px 10px', position: 'relative', marginTop: '0px' }}>
                            <div style={{ marginLeft: '10px' }}>
                                <h6 style={{ fontSize: '13px', fontWeight: '600', marginBottom: '2px' }}>{data?.docs1_english?.name === undefined ? data?.docs1_english?.slice(0,20) : data?.docs1_english?.name?.slice(0,20)}</h6>
                            </div>
                            <MdOutlineDeleteOutline size={22} style={{ position: 'absolute', right: '10px' }} onClick={()=>reselectfile('docs1_english')} />
                        </div>

                       
                    </div>}
                    </div>

                    <div style={{marginBottom:'5px'}}>
                   <SmallText fontWeight={'600'} val={'Docs 1 Kannada'}  />
                    {data?.docs1_kannada === undefined || data?.docs1_kannada === null || data?.docs1_kannada === ''  ?
                    <Dragger style={{ marginTop: '0px' }}
                        onChange={(e)=>handleUpload(e,'docs1_kannada')}
                        beforeUpload={() => false}
                        showUploadList={false}>
                        
                        <div style={{display:'flex',alignItems:'center',flexDirection:'column'}}>
                        
                        </div>
                    </Dragger>
                    :
                    <div>
                        <div style={{ display: 'flex', alignItems: 'center', border: '1px solid lightgray', padding: '6px 10px', position: 'relative', marginTop: '0px' }}>
                            <div style={{ marginLeft: '10px' }}>
                                <h6 style={{ fontSize: '13px', fontWeight: '600', marginBottom: '2px' }}>{data?.docs1_kannada?.name === undefined ? data?.docs1_kannada?.slice(0,20) : data?.docs1_kannada?.name?.slice(0,20)}</h6>
                            </div>
                            <MdOutlineDeleteOutline size={22} style={{ position: 'absolute', right: '10px' }} onClick={()=>reselectfile('docs1_kannada')} />
                        </div>

                       
                    </div>}
                    </div>
                   
                    <div style={{marginBottom:'5px'}}>
                   <SmallText fontWeight={'600'} val={'Docs 1 Hindi'}  />
                    {data?.docs1_hindi === undefined || data?.docs1_hindi === null || data?.docs1_hindi === ''  ?
                    <Dragger style={{ marginTop: '0px' }}
                        onChange={(e)=>handleUpload(e,'docs1_hindi')}
                        beforeUpload={() => false}
                        showUploadList={false}>
                        
                        <div style={{display:'flex',alignItems:'center',flexDirection:'column'}}>
                        
                        </div>
                    </Dragger>
                    :
                    <div>
                        <div style={{ display: 'flex', alignItems: 'center', border: '1px solid lightgray', padding: '6px 10px', position: 'relative', marginTop: '0px' }}>
                            <div style={{ marginLeft: '10px' }}>
                                <h6 style={{ fontSize: '13px', fontWeight: '600', marginBottom: '2px' }}>{data?.docs1_hindi?.name === undefined ? data?.docs1_hindi?.slice(0,20) : data?.docs1_hindi?.name?.slice(0,20)}</h6>
                            </div>
                            <MdOutlineDeleteOutline size={22} style={{ position: 'absolute', right: '10px' }} onClick={()=>reselectfile('docs1_hindi')} />
                        </div>

                       
                    </div>}
                    </div>

                    <div style={{marginBottom:'5px'}}>
                   <SmallText fontWeight={'600'} val={'Docs 2 English'}  />
                    {data?.docs2_english === undefined || data?.docs2_english === null || data?.docs2_english === ''  ?
                    <Dragger style={{ marginTop: '0px' }}
                        onChange={(e)=>handleUpload(e,'docs2_english')}
                        beforeUpload={() => false}
                        showUploadList={false}>
                        
                        <div style={{display:'flex',alignItems:'center',flexDirection:'column'}}>
                        
                        </div>
                    </Dragger>
                    :
                    <div>
                        <div style={{ display: 'flex', alignItems: 'center', border: '1px solid lightgray', padding: '6px 10px', position: 'relative', marginTop: '0px' }}>
                            <div style={{ marginLeft: '10px' }}>
                                <h6 style={{ fontSize: '13px', fontWeight: '600', marginBottom: '2px' }}>{data?.docs2_english?.name === undefined ? data?.docs2_english?.slice(0,20) : data?.docs2_english?.name?.slice(0,20)}</h6>
                            </div>
                            <MdOutlineDeleteOutline size={22} style={{ position: 'absolute', right: '10px' }} onClick={()=>reselectfile('docs2_english')} />
                        </div>

                       
                    </div>}
                    </div>

                    <div style={{marginBottom:'5px'}}>
                   <SmallText fontWeight={'600'} val={'Docs 2 Kannada'}  />
                    {data?.docs2_kannada === undefined || data?.docs2_kannada === null || data?.docs2_kannada === ''  ?
                    <Dragger style={{ marginTop: '0px' }}
                        onChange={(e)=>handleUpload(e,'docs2_kannada')}
                        beforeUpload={() => false}
                        showUploadList={false}>
                        
                        <div style={{display:'flex',alignItems:'center',flexDirection:'column'}}>
                        
                        </div>
                    </Dragger>
                    :
                    <div>
                        <div style={{ display: 'flex', alignItems: 'center', border: '1px solid lightgray', padding: '6px 10px', position: 'relative', marginTop: '0px' }}>
                            <div style={{ marginLeft: '10px' }}>
                                <h6 style={{ fontSize: '13px', fontWeight: '600', marginBottom: '2px' }}>{data?.docs2_kannada?.name === undefined ? data?.docs2_kannada?.slice(0,20) :data?.docs2_kannada?.name?.slice(0,20)}</h6>
                            </div>
                            <MdOutlineDeleteOutline size={22} style={{ position: 'absolute', right: '10px' }} onClick={()=>reselectfile('docs2_kannada')} />
                        </div>

                       
                    </div>}
                    </div>

                    <div style={{marginBottom:'5px'}}>
                   <SmallText fontWeight={'600'} val={'Docs 2 Hindi'}  />
                    {data?.docs2_hindi === undefined || data?.docs2_hindi === null || data?.docs2_hindi === ''  ?
                    <Dragger style={{ marginTop: '0px' }}
                        onChange={(e)=>handleUpload(e,'docs2_hindi')}
                        beforeUpload={() => false}
                        showUploadList={false}>
                        
                        <div style={{display:'flex',alignItems:'center',flexDirection:'column'}}>
                        
                        </div>
                    </Dragger>
                    :
                    <div>
                        <div style={{ display: 'flex', alignItems: 'center', border: '1px solid lightgray', padding: '6px 10px', position: 'relative', marginTop: '0px' }}>
                            <div style={{ marginLeft: '10px' }}>
                                <h6 style={{ fontSize: '13px', fontWeight: '600', marginBottom: '2px' }}>{data?.docs2_hindi?.name === undefined ? data?.docs2_hindi?.slice(0,20) :data?.docs2_hindi?.name?.slice(0,20)}</h6>
                            </div>
                            <MdOutlineDeleteOutline size={22} style={{ position: 'absolute', right: '10px' }} onClick={()=>reselectfile('docs2_hindi')} />
                        </div>

                       
                    </div>}
                    </div>
                   

                    <div style={{marginBottom:'10px'}}>
                    <div style={{width:'150px'}}>
                    <SmallText fontWeight={'600'} val={'Docs1 English Text '}  />
                    </div>
                    <TextArea type="text"   value={data.docs1_english_text} onChange={(e)=>setdata({...data,docs1_english_text:e.target.value})} />
                    </div>

                    <div style={{marginBottom:'10px'}}>
                    <div style={{width:'150px'}}>
                    <SmallText fontWeight={'600'} val={'Docs1 Kannada Text '}  />
                    </div>
                    <TextArea type="text"   value={data.docs1_kannada_text} onChange={(e)=>setdata({...data,docs1_kannada_text:e.target.value})} />
                    </div>

                    <div style={{marginBottom:'10px'}}>
                    <div style={{width:'150px'}}>
                    <SmallText fontWeight={'600'} val={'Docs1 Hindi Text '}  />
                    </div>
                    <TextArea type="text"   value={data.docs1_hindi_text} onChange={(e)=>setdata({...data,docs1_hindi_text:e.target.value})} />
                    </div>

                    <div style={{marginBottom:'10px'}}>
                    <div style={{width:'150px'}}>
                    <SmallText fontWeight={'600'} val={'Docs1 1 English Text '}  />
                    </div>
                    <TextArea type="text"   value={data.docs1_1_english_text} onChange={(e)=>setdata({...data,docs1_1_english_text:e.target.value})} />
                    </div>

                    <div style={{marginBottom:'10px'}}>
                    <div style={{width:'150px'}}>
                    <SmallText fontWeight={'600'} val={'Docs1 1 Kannada Text '}  />
                    </div>
                    <TextArea type="text"   value={data.docs1_1_kannada_text} onChange={(e)=>setdata({...data,docs1_1_kannada_text:e.target.value})} />
                    </div>

                    <div style={{marginBottom:'10px'}}>
                    <div style={{width:'150px'}}>
                    <SmallText fontWeight={'600'} val={'Docs1 1 Hindi Text '}  />
                    </div>
                    <TextArea type="text"   value={data.docs1_1_hindi_text} onChange={(e)=>setdata({...data,docs1_1_hindi_text:e.target.value})} />
                    </div>

                    <div style={{marginBottom:'10px'}}>
                    <div style={{width:'150px'}}>
                    <SmallText fontWeight={'600'} val={'Docs2 English Text '}  />
                    </div>
                    <TextArea type="text"   value={data.docs2_english_text} onChange={(e)=>setdata({...data,docs2_english_text:e.target.value})} />
                    </div>

                    <div style={{marginBottom:'10px'}}>
                    <div style={{width:'150px'}}>
                    <SmallText fontWeight={'600'} val={'Docs2 Kannada Text '}  />
                    </div>
                    <TextArea type="text"   value={data.docs2_kannada_text} onChange={(e)=>setdata({...data,docs2_kannada_text:e.target.value})} />
                    </div>

                    <div style={{marginBottom:'10px'}}>
                    <div style={{width:'150px'}}>
                    <SmallText fontWeight={'600'} val={'Docs2 Hindi Text '}  />
                    </div>
                    <TextArea type="text"   value={data.docs2_hindi_text} onChange={(e)=>setdata({...data,docs2_hindi_text:e.target.value})} />
                    </div>


                        </div>
                        </div>

                        <div style={{display:'flex',zIndex:10,paddingBottom:'60px',width:'40px',marginLeft:10}}>
                        <div style={{width:'200px',zIndex:1}} onClick={submitdata}>
                        <PrimaryButton  btn_name = {`Save`}  />
                        </div>
                        <div onClick={()=>navigator(-1)}>
                        <SecondaryButton btn_name = 'cancel' />
                        </div>
                    </div>

        </div>
    </FmsDashboardMainRoot>
  )
}

export default FmsHrPolicy