import React,{useState,useEffect} from 'react';

import TextInput from '../../components/ItComponents/TextInput';
import {  Itheadings, ItSmallText, SmallText } from '../../helpers/constants/ConstantsText';
import ITMain from './ITMain';
import {  gray_dark_1 } from '../../helpers/constants/ConstantColor';
import PrimaryButton, { PrimaryButtonAdd } from '../../components/buttons/PrimaryButton';


import {IoClose} from 'react-icons/io5'
import {theme_color } from '../../helpers/constants/ConstantColor';

import { Drawer,Input} from 'antd';
import IconButton from '@mui/material/IconButton';
import Tooltip from "@material-ui/core/Tooltip";
import toast, { Toaster } from 'react-hot-toast'
import { Select } from 'antd';
import Dragger from "antd/lib/upload/Dragger";
import { base_url } from '../../App';


import {AiOutlineInfoCircle} from 'react-icons/ai';
import {BiEditAlt} from 'react-icons/bi';

import axios from 'axios';
import { MdOutlineDelete } from 'react-icons/md';
import './Maincss.css';
import { authHeader } from '../../helpers/usertoken/UserToken';
import { image_not_Added_for_Asset } from '../../components/constants/Constants1';


const { Option } = Select;
const { TextArea } = Input;

function Cpu(){

   
    const [modal,setmodal] = useState(false)
    const [cpu,setcpu] = useState({id:null,cpu_id:'',model_id:'',device_stage:'',brand:'',description:'',purchase_type:'',image:null,})
    const [error,seterror] = useState({id:null,cpu_id:'',model_id:'',device_stage:'',brand:'',description:'',purchase_type:'',image:null,})
    const [pagination,setpagination] = useState({})
    const [cpu_Arr,setcpu_arr] = useState([])
    // const [wireschema_arr,setwireschema_arr] = useState([])
    const [purchasetype_arr,setpurchasetype_arr] = useState([])
    const [brand_arr,setbrand_arr] = useState([])
    const [devicestage_arr,setdevicestage_arr] = useState([])
    const [processor_arr,setprocessor_arr] = useState([])

    useEffect(()=>{
        setcpu({id:null,cpu_id:'',model_id:'',device_stage:'',brand:'',description:'',purchase_type:'',image:null})
        getdata()
        // getwireschema()
        getbrands()
        getstages()
        getprocessorschema()
        getpurchasetypeschema()
        setmodal(false)
    },[])  


    function getdata(){
        setcpu({id:null,cpu_id:'',model_id:'',device_stage:'',brand:'',description:'',purchase_type:'',image:null})
        axios.get('it/cpu/get')
        .then((res)=>{
            setpagination(res.data.pagination)
            setcpu_arr(res.data.datas)
        }).catch((err)=>{
        })
    }


    function getpurchasetypeschema(){
        axios.get('it/purchasetype/get')
        .then((res)=>{
            let arr = []
            res.data.data.forEach((d)=>{
                let obj = {
                    id : d._id,
                    type : d.type
                }
                arr.push(obj)
            })
            setpurchasetype_arr(arr)
        }).catch((err)=>{
        })
    }

    function getprocessorschema(){
        axios.get('it/processor/get')
        .then((res)=>{
            let arr = []
            res.data.data.forEach((d)=>{
                let obj = {
                    id : d._id,
                    name : d.processor
                }
                arr.push(obj)
            })
            setprocessor_arr(arr)
        }).catch((err)=>{
        })
    }

    function getbrands(){
        axios.get('it/brand/get')
        .then((res)=>{
            let arr = []
            res.data.data.forEach((d)=>{
                let obj = {
                    id : d._id,
                    brand_name:d.brand_name
                }
                arr.push(obj)
            })
            setbrand_arr(arr)
        }).catch((err)=>{
            // console.log('err',err)
        })
    }

    function getstages(){
        axios.get('it/devicestage/get')
        .then((res)=>{
            let arr = []
            res.data.data.forEach((d)=>{
                let obj = {
                    id : d._id,
                    stage:d.stage
                }
                arr.push(obj)
            })
            setdevicestage_arr(arr)
        }).catch((err)=>{
            // console.log('err',err)
        })
    }
    
    
    function add_cpu(){

        if(!cpu.cpu_id){
            seterror({...error,cpu_id:'The CPU Id Field is required'})
        }else if(!cpu.model_id){
            seterror({...error,model_id:'The Model Id Field is required'})
        }else if(!cpu.purchase_type?.id){
            seterror({...error,purchase_type:'The Purchase Type Field is required'})
        }else if(!cpu.device_stage?.id){
            seterror({...error,device_stage:'The Device Stage Field is required'})
        }else if(!cpu.brand?.id){
            seterror({...error,brand:'The Brand Field is required'})
        }else if(!cpu.description){
            seterror({...error,description:'The Description Field is required'})
        }else{
            const fd = new FormData()
            fd.append('cpu_id',cpu?.cpu_id)
            fd.append('model_id',cpu?.model_id)
            fd.append('purchase_type',cpu?.purchase_type?.id)
            fd.append('brand',cpu?.brand?.id)
            fd.append('device_stage',cpu?.device_stage?.id)
            fd.append('laptop_processor',cpu?.processor?.id)
            fd.append('description',cpu?.description)
            fd.append('os_original',cpu?.os_original)
            
            cpu?.image?.originFileObj !== undefined && fd.append('cpu_image',cpu?.image?.originFileObj)

            authHeader['headers']['Content-Type'] = 'multipart/form-data'

            axios.post('it/cpu/create',fd,authHeader)
            .then((res)=>{
                toast.success(res.data.message)
                setmodal(false)
                getdata()
            }).catch((err)=>{
                if(err?.response?.data?.err?.keyPattern?.cpu_id){
                    seterror({...error,cpu_id:'CPU Id should be unique'})
                }else if(err?.response?.data?.err?.keyPattern?.model_id){
                    seterror({...error,model_id:'Model Id should be unique'})
                }
            })
        }
    }


    function update_cpu(){
        if(!cpu.cpu_id){
            seterror({...error,cpu_id:'The CPU Id Field is required'})
        }else if(!cpu.model_id){
            seterror({...error,model_id:'The Model Id Field is required'})
        }else if(!cpu.purchase_type?.id){
            seterror({...error,purchase_type:'The Purchase Type Field is required'})
        }else if(!cpu.device_stage?.id){
            seterror({...error,device_stage:'The Device Stage Field is required'})
        }else if(!cpu.brand?.id){
            seterror({...error,brand:'The Brand Field is required'})
        }else if(!cpu.description){
            seterror({...error,description:'The Description Field is required'})
        }else{
        const fd = new FormData()
        fd.append('cpu_id',cpu.cpu_id)
        fd.append('model_id',cpu.model_id)
        fd.append('purchase_type',cpu?.purchase_type?.id)
        fd.append('brand',cpu?.brand?.id)
        fd.append('device_stage',cpu?.device_stage?.id)
        fd.append('description',cpu.description)
        
        cpu?.image?.originFileObj !== undefined && fd.append('cpu_image',cpu?.image?.originFileObj)

        authHeader['headers']['Content-Type'] = 'multipart/form-data'

        fd.append('method','_PUT')
        axios.post(`it/cpu/update/${cpu.id}`,fd)
        .then((res)=>{
            toast.success(res.data.message)
            setmodal(false)
            getdata()
        }).catch((err)=>{
            if(err?.response?.data?.err?.keyPattern?.cpu_id){
                seterror({...error,cpu_id:'CPU Id should be unique'})
            }else if(err?.response?.data?.err?.keyPattern?.model_id){
                seterror({...error,model_id:'Model Id should be unique'})
            }
        })
        }
    }

    function delete_cpu(v){
        axios.delete(`it/cpu/delete/${v._id}`)
        .then((res)=>{
            toast.success(res.data.message)
            getdata()
        }).catch((err)=>{
        })
    }

    const handleUpload = (v) => {
        setcpu({...cpu,image:v.fileList[0]})
    }

    function setdataforedit(p){

        setcpu({
            id:p._id,
            model_id:p.model_id,
            cpu_id:p.cpu_id,
            description:p.description,
            purchase_type:{type:p?.purchase_type?.type,id:p?.purchase_type?._id},
            device_stage:{stage:p?.device_stage?.stage,id:p?.device_stage?._id},
            brand:{brand_name:p?.brand?.brand_name,id:p?.brand?._id},
            processor:{processor_name:p?.laptop_processor?.processor,id:p?.laptop_processor?._id},
            image:p.image})
        setmodal(true)
    }

    function handleChange({ key, value }) {
        setcpu({ ...cpu, [key]: value })
        seterror({ ...error,[key]:''})   
    }

    // console.log('laptop',laptop)
    // console.log("laptop?.image",laptop?.image)


    return(
        <ITMain>
            <div style={{width:'98%'}}>
                <Toaster />

                <Drawer 
                  visible={modal}
                  width={300}
                  closable={false}
                  >

                    <div  style={{position:'relative',height: (cpu.image === undefined || cpu.image === null || cpu.image === "") ? '560px' : '500px'}}>

                        <Tooltip title="Close" >
                            <IconButton style={{padding:'2px',marginTop:'-5px',position:'absolute',right:-10,top:-10}}>
                            <IoClose  onClick={()=>setmodal(!modal)} style={{ cursor: 'pointer',color:gray_dark_1, }} size={16} />
                            </IconButton>
                        </Tooltip>



                        <div style={{marginLeft:'-5px'}}>
                        <p style={{fontSize:'10px',fontWeight:'500',background:'#c9ffe2',padding:'4px',marginTop:'0px'}}>Use the below form to create or update the devices for your employees assets.</p>

                        <ItSmallText val={'CPU Id'} fontSize={12.5} marginfun={'5px 0px 5px 0px'}/>
                        <TextInput val={cpu.cpu_id} onchange={(e)=>{setcpu({...cpu,cpu_id:e.target.value});seterror({...error,cpu_id:''})}} />
                        <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                            {(error?.cpu_id) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                            <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.cpu_id}</p>
                        </div>

                        <ItSmallText val={'Model Id'} fontSize={12.5} marginfun={'5px 0px 5px 0px'}/>
                        <TextInput val={cpu.model_id} onchange={(e)=>{setcpu({...cpu,model_id:e.target.value});seterror({...error,model_id:''})}} />
                        <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                            {(error?.model_id) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                            <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.model_id  }</p>
                        </div>

                        <div style={{marginLeft:'0px'}}>
                        <ItSmallText val={'Purchase Type'} fontSize={12.5} marginfun={'5px 0px 5px 0px'}/>
                        <Select value={cpu?.purchase_type?.type} onChange={(v)=>{setcpu({...cpu,purchase_type:purchasetype_arr?.find((b)=>b?.id === v)});seterror({...error,purchase_type:''})}} style={{width:'100%',borderLeft:`3px solid ${theme_color}`,borderRadius:'4px'}} >
                                {purchasetype_arr?.map((r)=>(
                                    <Option key={r?.id} value={r?.id} style={{textTransform:'capitalize'}}>{r?.type}</Option>
                                ))}
                        </Select>
                        <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                            {(error?.purchase_type) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                            <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.purchase_type  }</p>
                        </div>
                        </div>  

                        <ItSmallText val={'Device Stage'} fontSize={12.5} marginfun={'5px 0px 5px 0px'} />
                        <Select value={cpu?.device_stage?.stage} onChange={(v)=>{setcpu({...cpu,device_stage:devicestage_arr?.find((b)=>b?.id == v)});seterror({...error,device_stage:''})}} style={{width:'100%',borderLeft:`3px solid ${theme_color}`,borderRadius:'4px'}} >
                                {devicestage_arr?.map((r)=>(
                                    <Option key={r?.id} value={r?.id} >{r?.stage}</Option>
                                ))}
                        </Select>
                        <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                            {(error?.device_stage) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                            <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.device_stage  }</p>
                        </div>

                        <ItSmallText val={'Brand'} fontSize={12.5} marginfun={'5px 0px 5px 0px'} />
                        <Select value={cpu?.brand?.brand_name} onChange={(v)=>{setcpu({...cpu,brand:brand_arr?.find((b)=>b?.id == v)});seterror({...error,brand:''})}} style={{width:'100%',borderLeft:`3px solid ${theme_color}`,borderRadius:'4px'}} >
                                {brand_arr.map((r)=>(
                                    <Option key={r?.id} value={r?.id}>{r?.brand_name}</Option>
                                ))}
                        </Select>
                        <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                            {(error?.brand) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                            <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.brand  }</p>
                        </div>

                       

                        <ItSmallText val={'Description'} fontSize={12.5} marginfun={'5px 0px 5px 0px'}/>
                        <TextArea style={{borderLeft:`3px solid ${theme_color}`,borderRadius:'4px'}} value={cpu?.description} onChange={(e)=>{setcpu({...cpu,description:e.target.value});seterror({...error,description:''})}} />
                        <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                            {(error?.description) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>}
                            <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.description}</p>
                        </div>

                        

                        <ItSmallText val={'Image'} fontSize={12.5} marginfun={'5px 0px 5px 0px'} />
                        {(cpu.image === undefined || cpu.image === null || cpu.image === "")   ?
                        <Dragger style={{ marginTop: '10px',height:'100px',minHeight:'100px',maxHeight:'80px'}}
                            onChange={handleUpload}
                            beforeUpload={() => false}
                            showUploadList={false}>
                            
                            <div style={{display:'flex',height:'100px',alignItems:'center',flexDirection:'column'}}>
                            <img src={'https://cdn-icons-png.flaticon.com/512/2504/2504768.png'} style={{ width: '30px', objectFit: 'contain',alignItems:'center',display:'flex',justifyItems:'center',justifySelf:'center',alignSelf:'center' }} />
                            <p className="ant-upload-text" style={{fontWeight:'600',fontSize:'8px',marginTop:'10px'}}>Click or drag file to this area to upload</p>
                            <p className="ant-upload-hint" style={{fontSize:'6px'}}>
                                Support for a single file upload. Strictly used from uploading brand image
                            </p>
                            </div>
                        </Dragger>
                        :
                        <div>
                            {cpu?.image?.name?.length !== undefined && cpu?.image?.name?.length !== null  ?
                            <div style={{ display: 'flex', alignItems: 'center', border: '1px solid lightgray', padding: '2px 10px', position: 'relative', marginTop: '10px' }}>
                                <img src={'https://cdn-icons-png.flaticon.com/512/2504/2504768.png'} style={{ width: '20px', objectFit: 'contain' }} />
                                <div style={{ marginLeft: '10px',marginTop:'5px' }}>
                                    <h6 style={{ fontSize: '8px', fontWeight: '600', marginBottom: '2px' }}>{cpu?.image?.name?.slice(0,25)}</h6>
                                    <h6 style={{ fontSize: '6px', color: 'gray' }}>{cpu?.image?.size} bytes</h6>
                                </div>
                                <MdOutlineDelete size={15} style={{ position: 'absolute', right: '10px' }} onClick={()=>setcpu({...cpu,image:null})} />
                            </div>
                            :
                            <div style={{position:'relative',}}>
                                <IoClose  onClick={()=>setcpu({...cpu,image:null})} style={{ cursor: 'pointer',color:gray_dark_1,position:'absolute',right:0 }} size={16} />
                                <img src={`${base_url}${cpu?.image}`} style={{ width: '40px',height:'40px', objectFit: 'contain' }} />
                            </div>  }  
                        </div>}
                        </div>


                        <div style={{marginLeft:'-5px'}}>
                           <PrimaryButton btn_name={cpu.id === null ? 'Add CPU' : "Update CPU"} onClick={cpu.id === null ? add_cpu : update_cpu}  />
                        </div>
                    </div>
                </Drawer>

                <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between',marginTop:'-10px',borderBottom:`1px solid #f2f2f2`,maxHeight:'50px'}}>
                    <Itheadings val={'CPU'} />
                    
                    <div style={{display:'flex',alignItems:'center',flexDirection:'row'}}>
                        <div onClick={()=>{setmodal(true);setcpu({id:null,laptop_id:'',model_id:'',device_stage:'',device_stage_id:'',brand:'',brand_id:'',description:'',processor:'',processor_id:'',stage:'',image:null,os_original:false,date:''})}} > 
                        <PrimaryButtonAdd btn_name={'Add CPU'}  />
                        </div>
                    </div>
                </div>

                <SmallText fontWeight={'600'} fontSize={13} val={`Total Found (${pagination?.total})`} marginfun={'10px 0px 10px 0px'} />
            
                <div className='row'>
                {cpu_Arr?.map((p)=>(
                <div className='col-md-3'>
                <div className='card_processor '  key={p._id} style={{display:'flex',margin:'5px 0px',cursor:'pointer',paddingLeft:'10px',width:'100%',borderRadius:'2px',border:'0.5px solid #ddd',padding:'10px',display:'flex',position:'relative'}}>
                    <span style={{position:'absolute',right:0,top:0,fontSize:'8px',padding:'2px 10px',textAlign:'center',fontWeight:'800',background:(p.device_stage.stage !== 'damaged' &&  p.device_stage.stage !== 'under-service') ? '#72dba1' : '#c4655c',borderBottomLeftRadius:'21px',textTransform:'uppercase'}}>{p.device_stage.stage}</span>
                    {(p?.image !== null && p?.image !== undefined) ? 
                     <img src={`${base_url}${p.image}`} style={{minWidth:'80px',maxWidth:'80px',height:'60px',objectFit:'contain'}} />
                     :
                    <img src={`${image_not_Added_for_Asset}`} style={{minWidth:'80px',maxWidth:'80px',height:'60px',objectFit:'contain'}} />}
                    <div style={{paddingTop:'1px',marginLeft:'3%'}}>
                    <SmallText fontWeight={'600'} fontSize={13} val={`${p?.brand?.brand_name} laptop`} marginfun={'0px 0px 0px 0px'} />
                    <SmallText fontWeight={'500'} fontSize={10} val={`Model Id / CPU Id: ${p?.model_id} / ${p?.cpu_id}` } marginfun={'0px 0px 0px 0px'} />
                    <SmallText fontWeight={'500'} fontSize={10} val={`Type  : ${p?.purchase_type?.type}`} marginfun={'0px 0px 0px 0px'} />
                    <div style={{display:'flex',marginLeft:'-10px',marginTop:'5px'}} >
                    <Tooltip title="Delete" >
                        <IconButton style={{marginTop:'-5px'}} onClick={()=>delete_cpu(p)}>
                    <MdOutlineDelete  size={16} style={{color:'#000'}} />
                    </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit" >
                        <IconButton style={{marginTop:'-5px',}} onClick={()=>setdataforedit(p)}>
                    <BiEditAlt  size={16} style={{color:'#000'}} />
                    </IconButton>
                    </Tooltip>
                    </div>
                    </div>
                </div>
                </div>
                ))}
                </div>
            </div>
        </ITMain>
    )
}


export default Cpu