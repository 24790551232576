import React from 'react'
import { isMobile } from '../../helpers/mobileresize/Mobileresize'
import WebLogin from './web/index'
import MobileLogin from './mobile/index'

const index = () =>{
    if(isMobile){
        return(
            <div>
                <MobileLogin />
            </div>
        )
    }else{
        return(
            <div>
                <WebLogin />
            </div>
        )
    }
}

export default index