


const initialstate = {
   theme_color : '#222f3e'
}




const ThemeReducer = (state=initialstate,action)=>{
    switch(action.type){
        default:
            return state
    }
}

export default ThemeReducer


