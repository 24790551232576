import React from 'react'
import pnf from '../../../images/notfound.jpg'
import {Button} from 'antd'
import PrimaryButton from '../../../components/buttons/PrimaryButton'
import { useNavigate } from 'react-router-dom';

function PageNotFound1(){
    const navigate = useNavigate()
    return(
        <div style={{display:'grid',justifyContent:'center',alignItems:'center',marginTop:'100px',maxHeight:'90vh'}}>
            <img src={pnf} />
            <h6 style={{textAlign:'center',fontSize:'14px',fontWeight:'500'}}>We can't find the page your looking for </h6>
            <div style={{width:'150px',display:'grid',justifySelf:'center',justifyContent:'center'}}>
            <span onClick={()=>navigate(-1)}>
              <PrimaryButton btn_name="Go Back" padding='5px 35px'  />
            </span>

            </div>
        </div>
    )
}


export default PageNotFound1