import React, { useState, useEffect } from 'react'
import { Tabs } from 'antd'
import { Row, Col, Avatar, Input, Upload, Modal, DatePicker } from 'antd'
import PrimaryButton from '../buttons/PrimaryButton';
import { Button } from 'antd';
import InputField from "../inputcomponents/InputField";
import DropDown from '../inputcomponents/DropDown';
import Inputerror from '../errors/Inputerror';


import { MdMail, MdPhone } from 'react-icons/md'
import { FaUserAlt } from 'react-icons/fa'
import { FaGlobeAmericas, FaGraduationCap } from 'react-icons/fa'
import { BsCalendar2Check } from 'react-icons/bs'
import { InboxOutlined } from '@ant-design/icons';
import { RiBuildingLine } from 'react-icons/ri'
import { FaIndustry } from 'react-icons/fa'
import { FiEdit } from 'react-icons/fi'



import { HiOutlineFolderDownload } from 'react-icons/hi'
import { AiOutlineDelete } from 'react-icons/ai'

import moment from 'moment'

import toast, { Toaster } from "react-hot-toast";

import fileDownload from "js-file-download";
import axios from 'axios'
import { authHeader } from '../../helpers/usertoken/UserToken';
import Reminder from './Reminder';

const { TabPane } = Tabs;
const { TextArea } = Input;


function CLDmainDesign({ values, type, id }) {


    const [notes, setnotes] = useState({ title: '', summary: '' })

    const [modal1, setmodal1] = useState(false)
    const [modal2, setmodal2] = useState(false)
    const [modal3, setmodal3] = useState(false)

    const [data, setdata] = useState({ type: type, type_id: id, title: '', description: '', on_date_time: '', repeat: '', notify: '' })
    const [dataerr, setdataerr] = useState({ on_date_time_err: {}, repeat_type_err: {}, notify_err: {}, type_err: {}, title_err: {} })

    const [added_notes, setadded_notes] = useState({ contact_notes: [], lead_notes: [], deal_notes: [] })
    const [added_attachments, setadded_attachments] = useState({ contact_attachments: [], lead_attachments: [], deal_attachments: [] })
    const [added_reminders, setadded_reminders] = useState({ contact_reminders: [], lead_reminders: [], deal_reminders: [] })
    const [upload, setupload] = useState('')

    useEffect(() => {
        getContactNotes()
        getContactAttachments()
        getContactReminder()
    }, [])

    const { Dragger } = Upload;

    const getContactNotes = () => {
        axios.get(`activity/get-note?type=${'contact'}&type_id=${id}`, authHeader).then((res) => {
            setadded_notes({ ...added_notes, contact_notes: res.data.datas.reverse() })

            // setContactNote(res.data.datas.reverse())
        })
    }

    const getContactAttachments = () => {
        axios.get(`activity/get-attachement?type=${type}&type_id=${id}`, authHeader).then((res) => {
            setadded_attachments({ ...added_attachments, contact_attachments: res.data.datas })

        })
    }


    function AddNotes(v) {
        if (v === 'contact') {
            authHeader['Content-Type'] = 'multiplart/form-data'
            let fd = new FormData()
            fd.append('note_type', 'contact')
            fd.append('type_id', id)
            fd.append('note_title', notes.title)
            fd.append('summary', notes.summary)
            axios.post('activity/create-note', fd, authHeader).then((res) => {
                getContactNotes()
                setnotes({ title: '', summary: '' })
                toast.success('Note added successfully')
            }).catch((err) => {
                toast.error('Oops something went wrong!')
            })
        }
    }
    async function getContactReminder() {
        await axios.get(`reminder/get?type=${'contact'}&type_id=${id}`, authHeader).then((res) => {
            setadded_reminders({ ...added_reminders, contact_reminders: res.data.datas.reverse() })
        })
    }

    function handleUpload(v) {
        if (upload === '1') {
            authHeader['Content-Type'] = 'multiplart/form-data'
            let fd = new FormData()
            fd.append('attachment_type', 'Contact')
            fd.append('type_id', id)
            fd.append('file', v.fileList[v.fileList.length - 1].originFileObj)
            axios.post('activity/create-attachment', fd, authHeader).then((res) => {
                setmodal2(false)
                toast.success('Attachment uploaded successfully')
                getContactAttachments()
            })
        } else {
        }
    }

    const handleDownload = (url, filename) => {
        axios.get(url, {
            responseType: 'blob',
        }).then((res) => {
            toast.success(`${filename} downloaded successfully`)
            fileDownload(res.data, filename)
        })
    }

    const deleteAttachment = (v) => {
        if (window.confirm('Are you sure?')) {
            axios.delete(`activity/delete-attachment/${v}`, authHeader).then((res) => {
                getContactAttachments()
                toast.success('Attachment deleted successfully')
            }).catch((err) => {
                toast.error('Opps something went wrong!')
            })
        }
    }



    //reminder methods 

    const repeatData = [
        {
            'id': 'Once',
            'value': 'Once'
        },
    ]
    const notifyData = [
        {
            'id': 'Email',
            'value': 'Email'
        },

    ]
    function addReminder() {
        if (!data.title) {
            setdataerr({ ...dataerr, title_err: { error: true, message: 'This field is required' } })
        } else if (!data.on_date_time) {
            setdataerr({ ...dataerr, on_date_time_err: { error: true, message: 'This field is required' } })
        } else if (!data.repeat) {
            setdataerr({ ...dataerr, repeat_type_err: { error: true, message: 'This field is required' } })
        } else if (!data.notify) {
            setdataerr({ ...dataerr, notify_err: { error: true, message: 'This field is required' } })
        } else {
            if (data.type === 'assign_lead' || data.type === 'assign_lead1' || data.type === 'assign_lead2') {
                data.type = 'lead'
            }
            let reminderData = {
                'type': data.type,
                'type_id': id,
                'title': data.title,
                'description': data.description,
                'on_date_time': data.on_date_time,
                'repeat': data.repeat,
                'notify': data.notify
            }
            axios.post(`reminder/create`, reminderData, authHeader).then((res) => {
                toast.success({
                    content: 'Reminder created successfully',
                })
                setdata({ ...data, title: '', description: '', on_date_time: '', repeat: '', notify: '' })
                // getreminderfun(id)
                // setstage('1')
                // getReminder()
            })
        }
    }

    function disabledDate(current, anna) {
        return current && current < moment().startOf('day');

    }

    function handleChange({ key, value }) {
        if (key === 'title') {
            setdataerr({ ...dataerr, title_err: {} })
        } else if (key === 'on_date_time') {
            setdataerr({ ...dataerr, on_date_time_err: {} })
        } else if (key === 'repeat') {
            setdataerr({ ...dataerr, repeat_type_err: {} })
        } else if (key === 'notify') {
            setdataerr({ ...dataerr, notify_err: {} })
        }
        setdata({ ...data, [key]: value })
    }

    function selectedvalue(v) {
        if (v?.id) {
            setdataerr({ ...dataerr, [v.nameerr]: {} })
            setdata({ ...data, [v.name]: v.id })
        }
    }


    function handledatechange(v, v1) {
        setdataerr({ ...dataerr, on_date_time_err: {} })
        setdata({ ...data, on_date_time: v1 })
    }

    function handleok(v, v1) {
    }

    // function updatereminder(v) {
    //     const send_data = {}
    //     send_data['status'] = false
    //     axios.put(`reminder/update/${v}`, send_data, authHeader)
    //         .then((res) => {
    //             if (res.data.status) {
    //                 // getreminderfun(id)
    //             }
    //         })
    // }

    return (
        <div style={{ display: 'flex', marginTop: '40px' }}>
            <Toaster />
            <Reminder visible={modal3} func={() => setmodal3(false)} id={id} type={type} />

            <Modal visible={modal1} footer={false} onCancel={() => setmodal1(false)} style={{ maxWidth: '350px' }}>
                <div style={{ width: '95%', margin: 'auto' }}>
                    <h6>Add Note</h6>
                    <Input value={notes.title} onChange={e => setnotes({ ...notes, title: e.target.value })} placeholder='Add Title' style={{ marginBottom: '10px' }} />
                    <TextArea value={notes.summary} onChange={e => setnotes({ ...notes, summary: e.target.value })} placeholder='Add Description...' style={{ marginBottom: '10px' }} />
                    <Button onClick={() => AddNotes('contact')} size={'medium'} block style={{ fontSize: '12px', width: '100%', background: 'rgb(41 41 97)', color: 'white' }}>Add Note</Button>
                </div>
            </Modal>

            <Modal visible={modal2} footer={false} onCancel={() => setmodal2(false)} style={{ maxWidth: '380px' }}>
                <div style={{ width: '95%', margin: 'auto' }}>
                    <h6>Add Attachment</h6>
                    <Dragger onChange={handleUpload}
                        beforeUpload={() => false}
                        showUploadList={false}
                    >
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    </Dragger>
                </div>
            </Modal>

            <Modal visible={modal3} footer={false} onCancel={() => setmodal3(false)} style={{ maxWidth: '350px' }}>
                <div style={{ width: '98%', margin: 'auto' }}>
                    <h5 style={{ fontSize: '16px', fontWeight: '600' }}>Add Reminder</h5>

                    <div className="col-md-12 mt-3">
                        <h6 style={{ fontSize: '14px' }}>Title</h6>
                        <InputField
                            placeholder="Enter title"
                            type="text"
                            priority="high"
                            error_obj={dataerr.title_err}
                            onChange={handleChange}
                            name={'title'}
                            value={data.title}
                        />
                    </div>

                    <div className="col-md-12 mt-3">
                        <h6 style={{ fontSize: '14px' }}>Description</h6>
                        <TextArea
                            priority="high"
                            onChange={(e) => setdata({ ...data, description: e.target.value })}
                            name={'description'}
                            value={data.description}
                            error_obj={dataerr.description_err}
                        />
                    </div>

                    <div className="col-md-6 mt-3">
                        <div style={{ margin: '0px 0px', width: '100%', alignItems: 'center' }}>
                            <h6 style={{ fontSize: '14px' }}>Date</h6>
                            <DatePicker
                                showTime
                                disabledDate={disabledDate}
                                error_obj={dataerr.on_date_time_err}
                                onChange={handledatechange} onOk={handleok}
                                format="YYYY-MM-DD HH:mm"
                                value={data.on_date_time !== '' ? moment(data.on_date_time) : null}
                                size={'medium'}
                                style={{ width: '100%', borderLeft: '4px solid #292961', borderRadius: '4px', marginTop: '1px' }}
                            />
                            <Inputerror message={dataerr.on_date_time_err.message} />
                        </div>
                    </div>

                    <div className="col-md-6 mt-3">
                        <h6 style={{ fontSize: '14px' }}>Repeat Type</h6>
                        <DropDown
                            onChange={(v) => selectedvalue(v)}
                            priority={'high'}
                            name='repeat'
                            nameerr='repeat_type_err'
                            value={data.repeat}
                            error_obj={dataerr.repeat_type_err}
                            dropdownValue={data.repeat}
                            options={repeatData}
                        />
                    </div>

                    <div className="col-md-6 mt-3">
                        <h6 style={{ fontSize: '14px' }}>Notify</h6>
                        <DropDown
                            onChange={(v) => selectedvalue(v)}
                            priority={'high'}
                            name='notify'
                            nameerr='notify_err'
                            value={data.notify}
                            error_obj={dataerr.notify_err}
                            dropdownValue={data.notify}
                            options={notifyData}
                        />
                    </div>

                    <div onClick={() => addReminder()} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <PrimaryButton btn_name="Add Reminder" />
                    </div>
                </div>
            </Modal>

            <div style={{ flex: 0.20, height: '100vh', marginTop: '0px', borderRight: '1px solid #eee', position: 'relative' }}>
                <div style={{ width: '100%', margin: 'auto', position: 'relative' }}>

                    <FiEdit size={18} style={{ fontSize: '12px', position: 'absolute', right: '10px', top: '10px' }} />
                    <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', alignContent: 'center', alignSelf: 'center', justifyItems: 'center', paddingTop: '30PX', background: '#f7f7f7' }}>
                        <Avatar
                            style={{ width: '90px', height: '90px', color: 'red' }}
                        />
                        <h6 style={{ fontWeight: '600', marginTop: '10px', marginBottom: '2px' }}>{values[0][0]?.contact_name}</h6>
                        <h6 style={{ color: '#b5b5b5', fontSize: '13px' }}>{values[0][0]?.designation}</h6>

                        <PrimaryButton btn_name="Contact Saved" />
                    </div>

                    <div >

                        <div style={{ width: '90%', margin: 'auto' }}>
                            <div style={{ display: 'flex', width: '90%', margin: '10px auto', }}>
                                <MdMail size={32} style={{ padding: '6px', background: '#f7f7f7', }} />
                                <div style={{ marginLeft: '20px' }}>
                                    <h6 style={{ color: '#88898a', fontSize: '10px', marginBottom: '3px' }}>Email(Verified) </h6>
                                    <h6 style={{ marginBottom: '0px', fontSize: '10px', color: '#38acff', fontWeight: '600', wordBreak: 'break-word' }}>{values[0][0]?.email}</h6>
                                </div>
                            </div>

                            <div style={{ display: 'flex', width: '90%', margin: '10px auto', }}>
                                <MdPhone size={32} style={{ padding: '6px', background: '#f7f7f7', }} />
                                <div style={{ marginLeft: '20px' }}>
                                    <h6 style={{ color: '#88898a', fontSize: '10px', marginBottom: '3px' }}>Contact Phone (1) </h6>
                                    <h6 style={{ marginBottom: '0px', fontSize: '10px', color: '#38acff', fontWeight: '600' }}>{values[0][0]?.phone}</h6>
                                </div>
                            </div>

                            <div style={{ display: 'flex', width: '90%', margin: '10px auto', }}>
                                <FaGraduationCap size={32} style={{ padding: '6px', background: '#f7f7f7', }} />
                                <div style={{ marginLeft: '20px' }}>
                                    <h6 style={{ color: '#b5b5b5', fontSize: '12px', marginBottom: '3px' }}>Designation </h6>
                                    <h6 style={{ marginBottom: '0px', fontSize: '12px', color: '#38acff', fontWeight: '600' }}>{values[0][0]?.designation}</h6>
                                </div>
                            </div>


                            <div style={{ display: 'flex', width: '90%', margin: '10px auto', }}>
                                <FaUserAlt size={32} style={{ padding: '10px', background: '#f7f7f7', }} />
                                <div style={{ marginLeft: '20px' }}>
                                    <h6 style={{ color: '#b5b5b5', fontSize: '12px', marginBottom: '3px' }}>Secondary Contact</h6>
                                    <h6 style={{ marginBottom: '0px', fontSize: '12px', color: '#38acff', fontWeight: '600' }}>{values[0][0]?.secondary_contact_person}</h6>
                                </div>
                            </div>

                            <div style={{ display: 'flex', width: '90%', margin: '10px auto', }}>
                                <MdPhone size={32} style={{ padding: '6px', background: '#f7f7f7', }} />
                                <div style={{ marginLeft: '20px' }}>
                                    <h6 style={{ color: '#b5b5b5', fontSize: '12px', marginBottom: '3px' }}>Email(Verified) </h6>
                                    <h6 style={{ marginBottom: '0px', fontSize: '12px', color: '#38acff', fontWeight: '600', wordBreak: 'break-word' }}>{values[0][0]?.secondary_contact_phone}</h6>
                                </div>
                            </div>

                            <div style={{ display: 'flex', width: '90%', margin: '10px auto', }}>
                                <FaGraduationCap size={32} style={{ padding: '6px', background: '#f7f7f7', }} />
                                <div style={{ marginLeft: '20px' }}>
                                    <h6 style={{ color: '#b5b5b5', fontSize: '12px', marginBottom: '3px' }}>Designation </h6>
                                    <h6 style={{ marginBottom: '0px', fontSize: '12px', color: '#38acff', fontWeight: '600' }}>{values[0][0]?.secondary_contact_designation}</h6>
                                </div>
                            </div>


                            <h6 style={{ color: 'rgb(41 41 97)', fontWeight: 'bold', fontSize: '13px', marginLeft: '10px', marginTop: '30px' }}>Contact Owner</h6>

                            <div style={{ fontSize: '12px', background: '#e6ffef', padding: '5px', borderRadius: '4px', width: '90%', margin: 'auto' }}>
                                <p style={{ background: '#e6ffef' }}>This contact added by krishna prasad pk (Radha's Team)</p>
                            </div>





                        </div>
                    </div>
                </div>
                <div style={{ position: 'absolute', right: '10px', bottom: '50px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <BsCalendar2Check size={12} />
                        <p style={{ fontSize: '10px', marginLeft: '10px', marginBottom: '0px', fontWeight: '500' }}>Created at 12 th jan 2021</p>
                    </div>




                </div>
            </div>

            <div style={{ width: '100%', flex: 0.80, margin: 'auto', height: '100vh', background: '#f7f7f7' }}>
                <Row>
                    <Col span={8}>
                        <div style={{ width: '96%', margin: 'auto', background: 'white', height: '100vh' }}>
                            <Col span={24}>
                                <FiEdit size={18} style={{ fontSize: '12px', position: 'absolute', right: '10px', top: '10px' }} />

                                <div style={{ background: 'white', padding: '10px', borderBottom: '0.5px solid #f5f5f5' }}>
                                    <div style={{ marginTop: '30px' }}>
                                        <span style={{ fontWeight: '700', fontSize: '14px', marginTop: '30px', paddingBottom: '8px', borderBottom: '3px solid #6666c4' }}>Company Information</span>
                                    </div>

                                    <Row style={{ marginTop: '15px' }}>
                                        <Col span={12}>
                                            <div style={{ display: 'flex', width: '90%', margin: '10px auto', }}>
                                                <RiBuildingLine size={32} style={{ padding: '6px', background: '#f7f7f7', }} />
                                                <div style={{ marginLeft: '20px' }}>
                                                    <h6 style={{ color: '#88898a', fontSize: '10px', marginBottom: '3px' }}>Company Name  </h6>
                                                    <h6 style={{ marginBottom: '0px', fontSize: '10px', color: '#38acff', fontWeight: '600' }}>{values[0][0]?.company_name}</h6>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col span={12}>
                                            <div style={{ display: 'flex', width: '90%', margin: '10px auto', }}>
                                                <MdPhone size={32} style={{ padding: '6px', background: '#f7f7f7', }} />
                                                <div style={{ marginLeft: '20px' }}>
                                                    <h6 style={{ color: '#88898a', fontSize: '10px', marginBottom: '3px' }}>Company Phone  </h6>
                                                    <h6 style={{ marginBottom: '0px', fontSize: '10px', color: '#38acff', fontWeight: '600' }}>{values[0][0]?.company_phone}</h6>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col span={12}>
                                            <div style={{ display: 'flex', width: '90%', margin: '10px auto', }}>
                                                <MdMail size={32} style={{ padding: '4px', background: '#f7f7f7', }} />
                                                <div style={{ marginLeft: '20px' }}>
                                                    <h6 style={{ color: '#88898a', fontSize: '10px', marginBottom: '3px' }}>Company Email </h6>
                                                    <h6 style={{ marginBottom: '0px', fontSize: '10px', color: '#38acff', fontWeight: '600', wordBreak: 'break-word' }}>{values[0][0]?.company_email}</h6>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col span={12}>
                                            <div style={{ display: 'flex', width: '90%', margin: '10px auto', }}>
                                                <FaGlobeAmericas size={32} style={{ padding: '6px', background: '#f7f7f7', }} />
                                                <div style={{ marginLeft: '20px' }}>
                                                    <h6 style={{ color: '#88898a', fontSize: '10px', marginBottom: '3px' }}>Website</h6>
                                                    <h6 style={{ marginBottom: '0px', fontSize: '10px', color: '#38acff', fontWeight: '600' }}>{values[0][0]?.website}</h6>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col span={12}>
                                            <div style={{ display: 'flex', width: '90%', margin: '10px auto', }}>
                                                <FaIndustry size={32} style={{ padding: '6px', background: '#f7f7f7', }} />
                                                <div style={{ marginLeft: '20px' }}>
                                                    <h6 style={{ color: '#88898a', fontSize: '10px', marginBottom: '3px' }}>Industry </h6>
                                                    <h6 style={{ marginBottom: '0px', fontSize: '10px', color: '#38acff', fontWeight: '600' }}>{values[0][0]?.industry}</h6>
                                                </div>
                                            </div>
                                        </Col>

                                    </Row>
                                </div>
                            </Col>
                            <Tabs defaultActiveKey="1" style={{ width: '94%', margin: 'auto', paddingTop: '10px' }} size='small' >
                                <TabPane tab="Notes" key="1">
                                    <h6 onClick={() => setmodal1(true)} style={{ color: '#3da5ff', fontSize: '12px', cursor: 'pointer' }}>+ Add Notes</h6>

                                    <div>


                                        <div style={{ width: '94%', margin: '8px auto', }}>

                                            {added_notes?.contact_notes?.map(n => (
                                                <div key={n.id}>
                                                    <h6 style={{ marginLeft: '10px', fontSize: '12px', fontWeight: '800', marginBottom: '0px' }}>Krishna prasad pk <span style={{ fontSize: '10px', color: '#949494', marginTop: '5px', fontWeight: '800', marginLeft: '5px' }}>{moment(n?.createdAt).format('LL')}</span></h6>

                                                    <div style={{ background: '#ffffff', padding: '5px 10px' }}>
                                                        <p style={{ fontSize: '11px', marginBottom: '0px', lineHeight: '15px', fontWeight: '500' }}><strong style={{ fontWeight: 'bold', color: 'black', marginRight: '10px' }}>{n.note_title}</strong>{n.summary}</p>
                                                    </div>
                                                </div>
                                            ))}


                                        </div>
                                    </div>
                                </TabPane>

                                <TabPane tab="Attachments" key="2" style={{ width: '100%', margin: 'auto' }}>
                                    <h6 onClick={() => { setmodal2(true); setupload('1') }} style={{ color: '#3da5ff', fontSize: '12px', cursor: 'pointer' }}>+ Add Attachments</h6>

                                    {added_attachments?.contact_attachments?.map((a) => (
                                        <div key={a.id} style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            margin: '2px 0px'
                                        }}>
                                            {/* <p>{a.file.split('/')[0]}</p>  */}
                                            <p>{a.file.split('/')[a.file.split('/').length - 1]}</p>
                                            <div style={{ width: '20%' }}>
                                                <HiOutlineFolderDownload size={20} style={{ marginRight: '10px' }}
                                                    onClick={() => handleDownload(`http://localhost:5000/${a.file}`, a.file.split('/')[a.file.split('/').length - 1])} />
                                                <AiOutlineDelete size={20} onClick={() => deleteAttachment(a.id)} />
                                            </div>
                                        </div>
                                    ))}

                                </TabPane>

                                <TabPane tab="Reminders" key="3" style={{ width: '100%', margin: 'auto' }}>

                                    <h6 onClick={() => setmodal3(true)} style={{ color: '#3da5ff', fontSize: '12px', cursor: 'pointer' }}>+ Add Reminders</h6>
                                </TabPane>


                            </Tabs>

                        </div>
                    </Col>

                    <Col span={8}>
                        <FiEdit size={18} style={{ fontSize: '12px', position: 'absolute', right: '10px', top: '10px' }} />
                        <div style={{ width: '96%', margin: 'auto', background: 'white', height: '100vh' }}>
                            <Row>
                                <Col span={24}>
                                    <div style={{ background: 'white', padding: '10px' }}>
                                        <div style={{ marginTop: '30px' }}>
                                            <span style={{ fontWeight: '700', fontSize: '14px', marginTop: '30px', paddingBottom: '8px', borderBottom: '3px solid #6666c4' }}>Lead Information</span>
                                        </div>

                                        <Row style={{ marginTop: '15px' }}>
                                            <Col span={12}>
                                                <div style={{ display: 'flex', width: '90%', margin: '10px auto', }}>
                                                    <RiBuildingLine size={32} style={{ padding: '6px', background: '#f7f7f7', }} />
                                                    <div style={{ marginLeft: '20px' }}>
                                                        <h6 style={{ color: '#88898a', fontSize: '10px', marginBottom: '3px' }}>Lead Name  </h6>
                                                        <h6 style={{ marginBottom: '0px', fontSize: '10px', color: '#38acff', fontWeight: '600' }}>{values[1][0]?.lead_name}</h6>
                                                    </div>
                                                </div>
                                            </Col>

                                            <Col span={12}>
                                                <div style={{ display: 'flex', width: '90%', margin: '10px auto', }}>
                                                    <MdPhone size={32} style={{ padding: '6px', background: '#f7f7f7', }} />
                                                    <div style={{ marginLeft: '20px' }}>
                                                        <h6 style={{ color: '#88898a', fontSize: '10px', marginBottom: '3px' }}>Lead Title  </h6>
                                                        <h6 style={{ marginBottom: '0px', fontSize: '10px', color: '#38acff', fontWeight: '600' }}>{values[1][0]?.lead_title}</h6>
                                                    </div>
                                                </div>
                                            </Col>

                                            <Col span={12}>
                                                <div style={{ display: 'flex', width: '90%', margin: '10px auto', }}>
                                                    <MdMail size={32} style={{ padding: '6px', background: '#f7f7f7', }} />
                                                    <div style={{ marginLeft: '20px' }}>
                                                        <h6 style={{ color: '#88898a', fontSize: '10px', marginBottom: '3px' }}>Lead status </h6>
                                                        <h6 style={{ marginBottom: '0px', fontSize: '10px', color: '#38acff', fontWeight: '600' }}>{values[1][0]?.lead_status}</h6>
                                                    </div>
                                                </div>
                                            </Col>

                                            <Col span={24}>
                                                <div style={{ display: 'flex', width: '94%', margin: '10px auto', }}>
                                                    <FaGlobeAmericas size={32} style={{ padding: '6px', background: '#f7f7f7', }} />
                                                    <div style={{ marginLeft: '20px' }}>
                                                        <h6 style={{ color: '#88898a', fontSize: '10px', marginBottom: '3px' }}>Description</h6>
                                                        <h6 style={{ marginBottom: '0px', fontSize: '10px', color: '#38acff', fontWeight: '600' }}>7411747133</h6>
                                                    </div>
                                                </div>
                                            </Col>

                                        </Row>




                                    </div>
                                </Col>
                            </Row>

                            <Tabs defaultActiveKey="1" style={{ width: '94%', margin: 'auto', paddingTop: '10px' }} size='small' >
                                <TabPane tab="Notes" key="1">
                                    <h6 onClick={() => setmodal1(true)} style={{ color: '#3da5ff', fontSize: '12px', cursor: 'pointer' }}>+ Add Notes</h6>

                                    <div>


                                        <div style={{ width: '94%', margin: '8px auto', }}>

                                            <div>
                                                <h6 style={{ marginLeft: '10px', fontSize: '12px', fontWeight: '800', marginBottom: '0px' }}>Krishna prasad pk <span style={{ fontSize: '10px', color: '#949494', marginTop: '5px', fontWeight: '800' }}>12 th Jun 2020</span></h6>

                                                <div style={{ background: '#ffffff', padding: '5px 10px' }}>
                                                    <p style={{ fontSize: '11px', marginBottom: '0px', lineHeight: '15px', fontWeight: '500' }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </TabPane>


                                <TabPane tab="Attachments" key="2" style={{ width: '100%', margin: 'auto' }}>
                                    <h6 onClick={() => { setmodal2(true); setupload('2') }} style={{ color: '#3da5ff', fontSize: '12px', cursor: 'pointer' }}>+ Add Attachments</h6>

                                    <Dragger onChange={handleUpload}
                                        beforeUpload={() => false}
                                        showUploadList={false}
                                    >
                                        <p className="ant-upload-drag-icon">
                                            <InboxOutlined />
                                        </p>
                                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                    </Dragger>
                                </TabPane>

                                <TabPane tab="Reminders" key="3" style={{ width: '100%', margin: 'auto' }}>

                                    <h6 onClick={() => setmodal3(true)} style={{ color: '#3da5ff', fontSize: '12px', cursor: 'pointer' }}>+ Add Reminders</h6>
                                </TabPane>


                            </Tabs>

                        </div>


                    </Col>

                    <Col span={8}>
                        <FiEdit size={18} style={{ fontSize: '12px', position: 'absolute', right: '10px', top: '10px' }} />
                        <div style={{ width: '96%', margin: 'auto', background: 'white', height: '100vh' }}>
                            <Row>
                                <Col span={24}>
                                    <div style={{ background: 'white', padding: '10px' }}>
                                        <div style={{ marginTop: '30px' }}>
                                            <span style={{ fontWeight: '700', fontSize: '14px', marginTop: '30px', paddingBottom: '8px', borderBottom: '3px solid #6666c4' }}>Deal Information</span>
                                        </div>

                                        <Row style={{ marginTop: '15px' }}>
                                            <Col span={12}>
                                                <div style={{ display: 'flex', width: '90%', margin: '10px auto', }}>
                                                    <RiBuildingLine size={32} style={{ padding: '6px', background: '#f7f7f7', }} />
                                                    <div style={{ marginLeft: '20px' }}>
                                                        <h6 style={{ color: '#88898a', fontSize: '10px', marginBottom: '3px' }}>Company Name  </h6>
                                                        <h6 style={{ marginBottom: '0px', fontSize: '10px', color: '#38acff', fontWeight: '600' }}>7411747133</h6>
                                                    </div>
                                                </div>
                                            </Col>

                                            <Col span={12}>
                                                <div style={{ display: 'flex', width: '90%', margin: '10px auto', }}>
                                                    <MdPhone size={32} style={{ padding: '6px', background: '#f7f7f7', }} />
                                                    <div style={{ marginLeft: '20px' }}>
                                                        <h6 style={{ color: '#88898a', fontSize: '10px', marginBottom: '3px' }}>Company Phone  </h6>
                                                        <h6 style={{ marginBottom: '0px', fontSize: '10px', color: '#38acff', fontWeight: '600' }}>7411747133</h6>
                                                    </div>
                                                </div>
                                            </Col>

                                            <Col span={12}>
                                                <div style={{ display: 'flex', width: '90%', margin: '10px auto', }}>
                                                    <MdMail size={32} style={{ padding: '6px', background: '#f7f7f7', }} />
                                                    <div style={{ marginLeft: '20px' }}>
                                                        <h6 style={{ color: '#88898a', fontSize: '10px', marginBottom: '3px' }}>Company Email </h6>
                                                        <h6 style={{ marginBottom: '0px', fontSize: '10px', color: '#38acff', fontWeight: '600' }}>7411747133</h6>
                                                    </div>
                                                </div>
                                            </Col>

                                            <Col span={12}>
                                                <div style={{ display: 'flex', width: '90%', margin: '10px auto', }}>
                                                    <FaGlobeAmericas size={32} style={{ padding: '6px', background: '#f7f7f7', }} />
                                                    <div style={{ marginLeft: '20px' }}>
                                                        <h6 style={{ color: '#88898a', fontSize: '10px', marginBottom: '3px' }}>Website</h6>
                                                        <h6 style={{ marginBottom: '0px', fontSize: '10px', color: '#38acff', fontWeight: '600' }}>7411747133</h6>
                                                    </div>
                                                </div>
                                            </Col>

                                            <Col span={12}>
                                                <div style={{ display: 'flex', width: '90%', margin: '10px auto', }}>
                                                    <FaIndustry size={32} style={{ padding: '6px', background: '#f7f7f7', }} />
                                                    <div style={{ marginLeft: '20px' }}>
                                                        <h6 style={{ color: '#88898a', fontSize: '10px', marginBottom: '3px' }}>Industry </h6>
                                                        <h6 style={{ marginBottom: '0px', fontSize: '10px', color: '#38acff', fontWeight: '600' }}>7411747133</h6>
                                                    </div>
                                                </div>
                                            </Col>

                                        </Row>




                                    </div>
                                </Col>
                            </Row>

                            <Tabs defaultActiveKey="1" style={{ width: '94%', margin: 'auto', paddingTop: '10px' }} size='small' >
                                <TabPane tab="Notes" key="1">
                                    <h6 onClick={() => setmodal1(true)} style={{ color: '#3da5ff', fontSize: '12px', cursor: 'pointer' }}>+ Add Notes</h6>

                                    <div>


                                        <div style={{ width: '94%', margin: '8px auto', }}>

                                            <div>
                                                <h6 style={{ marginLeft: '10px', fontSize: '12px', fontWeight: '800', marginBottom: '0px' }}>Krishna prasad pk <span style={{ fontSize: '10px', color: '#949494', marginTop: '5px', fontWeight: '800' }}>12 th Jun 2020</span></h6>

                                                <div style={{ background: '#ffffff', padding: '5px 10px' }}>
                                                    <p style={{ fontSize: '11px', marginBottom: '0px', lineHeight: '15px', fontWeight: '500' }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </TabPane>


                                <TabPane tab="Attachments" key="2" style={{ width: '100%', margin: 'auto' }}>
                                    <h6 onClick={() => { setmodal2(true); setupload('2') }} style={{ color: '#3da5ff', fontSize: '12px', cursor: 'pointer' }}>+ Add Attachments</h6>

                                    <Dragger onChange={handleUpload}
                                        beforeUpload={() => false}
                                        showUploadList={false}
                                    >
                                        <p className="ant-upload-drag-icon">
                                            <InboxOutlined />
                                        </p>
                                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                    </Dragger>
                                </TabPane>

                                <TabPane tab="Reminders" key="3" style={{ width: '100%', margin: 'auto' }}>

                                    <h6 onClick={() => setmodal3(true)} style={{ color: '#3da5ff', fontSize: '12px', cursor: 'pointer' }}>+ Add Reminders</h6>
                                </TabPane>


                            </Tabs>

                        </div>


                    </Col>



                </Row>

            </div>

        </div>
    )
}

export default CLDmainDesign