import React, { useState } from 'react'
import { Empty, Button, Input, Card } from 'antd';


const { TextArea } = Input;



function DetailBoby({ section, values, labels, type }, props) {
    const [heading, setheading] = useState(false)

    const [sin_note, setsin_note] = useState({})

    const [notes_data, setnotes_data] = useState([])

    function addnote() {
        if (heading) {
            if (!sin_note.heading) {
                // console.log('no heading added')
            }
        }
        if (!sin_note.description) {
            // console.log('no description added')
        } else {
            const data = {}
            data['description'] = sin_note.description
            data['id'] = Math.floor(Math.random(0, 100))

            if (heading) {
                data['heading'] = sin_note.heading
            }

            setnotes_data([...notes_data, data])
            setsin_note({})

            // console.log('notes_data',notes_data)
        }

    }
    return (
        <div style={{ marginTop: '20px', paddingBottom: '20px' }}>
            {labels.map((label, ind) => (
                <div className="row" style={{ border: '1px solid #dbdbdb', margin: '10px 0px 20px 0px', borderRadius: '4px' }}>
                    <>
                        <h6 style={{ background: '#f0f0f0', color: 'black', borderRadius: '4px', borderBottom: '1px solid #dbdbdb', padding: '10px', fontWeight: '600' }}>{section[ind]}</h6>
                        {label.map((l, i) => (
                            <div className={ind !== 2 ? "col-md-6 m-0" : "col-md-12"}>
                                <div className={ind !== 2 ? "col-md-6 d-flex p-2" : "col-md-12 d-flex p-2"} style={{ width: '100%' }}>
                                    <p style={{ marginBottom: '0px', marginRight: '20px', fontSize: '14px', width: l !== 'Description' ? '40%' : '0%', display: l !== 'Description' ? 'block' : 'none', textAlign: 'end', fontWeight: '600', color: 'black', fontFamily: 'Arial', }}>{l}</p>
                                    <p style={{ marginBottom: '0px', fontWeight: '400', fontSize: '14px', fontFamily: 'Arial', width: l !== 'Description' ? '60%' : '100%', }}>{values[ind][i]}</p>
                                </div>
                            </div>
                        ))}
                    </>
                </div>
            ))}

            <div className="row" style={{ border: '1px solid #dbdbdb', margin: '10px 0px 20px 0px', borderRadius: '4px', paddingBottom: '20px', }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', background: '#f0f0f0', borderRadius: '4px', borderBottom: '1px solid #dbdbdb', alignItems: 'center', marginBottom: '10px' }}>
                    <h6 style={{ padding: '10px', marginLeft: '-10px', fontWeight: '600', background: '#f0f0f0', color: 'black' }}>Add Notes</h6>
                    {/* <Dropdown overlay={menu}> */}
                    {/* <Button type="primary" placement="bottomLeft">
                                        Add Notes 
                                    </Button> */}
                    {/* </Dropdown> */}
                </div>

                {notes_data.length > 0 &&
                    <div style={{ margin: '10px 0px' }}>
                        {/* <h6>Added Notes</h6>  */}
                        {notes_data.map((d) => (
                            <div style={{ marginLeft: '20px' }}>
                                <h6 style={{ fontWeight: '500', fontSize: '14px', marginBottom: '2px' }}>{d?.heading}</h6>
                                <p style={{ marginBottom: '0px', fontSize: '12px' }}>{d?.description}</p>
                            </div>
                        ))}
                    </div>

                }

                <div>
                    <Card >
                        <TextArea style={{ height: '100px', paddingTop: !heading ? '5px' : '30px' }} placeholder="Add Note" value={sin_note.description} onChange={(e) => setsin_note({ ...sin_note, description: e.target.value })} />
                        {heading && <Input type="text" value={sin_note.heading} bordered={false} style={{ fontWeight: '800', fontSize: '18px', paddingTop: '5px', position: 'absolute', left: '2.5%', width: '95%' }} placeholder='Add title' onChange={(e) => setsin_note({ ...sin_note, heading: e.target.value })} />}
                        <div style={{ display: 'flex', marginTop: '30px', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                            <div>{!heading && <h6 style={{ fontWeight: '400', color: '#4dc6ff', cursor: 'pointer', textDecoration: 'underline' }} onClick={() => setheading(true)}>Add Title</h6>}</div>
                            <div>
                                <Button style={{ marginRight: '10px' }} onClick={() => setsin_note({})}>Cancel</Button>
                                <Button type='primary' onClick={() => addnote()}>Save</Button>
                            </div>
                        </div>
                    </Card>
                </div>

                {/* <Empty
                        image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                        imageStyle={{
                        height: 60,
                        }}
                        description={
                        <span>
                            <h6>No Notes Added for this Lead</h6>
                            <Button >Add Note</Button> 
                        </span>
                        }
                        >
                    </Empty> */}
            </div>


            {type !== 'Task' && type !== 'Meeting' &&
                <div className="row" style={{ border: '1px solid #dbdbdb', margin: '10px 0px 20px 0px', borderRadius: '4px', paddingBottom: '20px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', background: '#f0f0f0', borderRadius: '4px', borderBottom: '1px solid #dbdbdb', alignItems: 'center', marginBottom: '10px' }}>
                        <h6 style={{ padding: '10px', marginLeft: '-10px', fontWeight: '600', color: 'black' }}>Attachments</h6>

                        <Button type="primary" placement="bottomLeft" style={{ position: 'relative', width: '150px' }} >
                            Add Attachment
                            <input type="file" style={{ position: 'absolute', opacity: 0, zIndex: 10, left: '10px', width: '150px', }} />
                        </Button>

                    </div>

                    <Empty
                        image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                        imageStyle={{
                            height: 60,
                        }}
                        description={
                            <span>
                                No <span style={{ textTransform: 'lowercase' }}>attachment added for this {type}</span>
                            </span>
                        }
                    >
                    </Empty>
                </div>
            }
        </div>
    )
}

export default DetailBoby
