import React,{useState,useEffect} from 'react';

import TextInput from '../../components/ItComponents/TextInput';
import {  Itheadings, ItSmallText, SmallText } from '../../helpers/constants/ConstantsText';
import ITMain from './ITMain';
import {  gray_dark_1 } from '../../helpers/constants/ConstantColor';
import PrimaryButton, { PrimaryButtonAdd } from '../../components/buttons/PrimaryButton';


import {IoClose} from 'react-icons/io5'
import {theme_color } from '../../helpers/constants/ConstantColor';

import { Drawer, Modal} from 'antd';
import IconButton from '@mui/material/IconButton';
import Tooltip from "@material-ui/core/Tooltip";
import toast, { Toaster } from 'react-hot-toast'
import { Select } from 'antd';
import Dragger from "antd/lib/upload/Dragger";
import { base_url } from '../../App';


import { createMuiTheme, } from "@material-ui/core/styles";
import {AiOutlineInfoCircle} from 'react-icons/ai';
import {BiEditAlt} from 'react-icons/bi';

import axios from 'axios';
import { MdOutlineDelete } from 'react-icons/md';
import './Maincss.css';
import { authHeader } from '../../helpers/usertoken/UserToken';


const { Option } = Select;

function Disk(){

    const theme = createMuiTheme({
        overrides: {
            MuiTooltip: {
            tooltip: {
                // fontSize: "2em",
                color: "black",
                backgroundColor: {gray_dark_1}
            }
            }
        }
    });


    const [modal,setmodal] = useState(false)
    const [disk,setdisk] = useState({id:null,model_id:'',disk_capacity:'',related_to:'',related_to_id:'',brand:'',brand_id:'',image:null,device_stage:'',device_stage_id:''})
    const [error,seterror] = useState({id:null,model_id:'',disk_capacity:'',related_to:'',related_to_id:'',brand:'',brand_id:'',image:null,device_stage:'',device_stage_id:''})
    const [pagination,setpagination] = useState({})
    const [disk_arr,setdisk_arr] = useState([])
    const [wireschema_arr,setwireschema_arr] = useState([])
    const [brand_arr,setbrand_arr] = useState([])
    const [devicestage_arr,setdevicestage_arr] = useState([])
    const [related_to_arr,setrelated_to_arr] = useState([])

    useEffect(()=>{
        setdisk({id:null,model_id:'',disk_capacity:'',related_to:'',related_to_id:'',brand:'',brand_id:'',image:null,device_stage:'',device_stage_id:''})
        getdata()
        getwireschema()
        getbrands()
        getstages()
        getrelatedtoSchema()
    },[])  
 

    function getdata(){
        setdisk({id:null,model_id:'',device_stage:'',device_stage_id:'',brand:'',brand_id:'',type:'',type_id:'',image:null})
        axios.get('it/disk/get')
        .then((res)=>{
            console.log('res data disk',res.data)
            setpagination(res.data.pagination)
            setdisk_arr(res.data.datas)

        }).catch((err)=>{
            // console.log('err',err)
        })
    }

    function getrelatedtoSchema(){
        axios.get('it/relatedtodevice/get')
        .then((res)=>{
            let arr = []
            res.data.data.forEach((d)=>{
                let obj = {
                    id : d._id,
                    type : d.related_to
                }
                arr.push(obj)
            })

            setrelated_to_arr(arr)
        }).catch((err)=>{
            // console.log('err',err)
        })
    }

    function getwireschema(){
        axios.get('it/wireschema/get')
        .then((res)=>{
            console.log('res.data.data',res.data.data)

            let arr = []
            res.data.data.forEach((d)=>{
                let obj = {
                    id : d._id,
                    type : d.type
                }
                arr.push(obj)
            })
            setwireschema_arr(arr)
        }).catch((err)=>{
            // console.log('err',err)
        })
    }

    function getbrands(){
        axios.get('it/brand/get')
        .then((res)=>{
            let arr = []
            res.data.data.forEach((d)=>{
                let obj = {
                    id : d._id,
                    brand_name:d.brand_name
                }
                arr.push(obj)
            })
            setbrand_arr(arr)
        }).catch((err)=>{
            // console.log('err',err)
        })
    }

    function getstages(){
        axios.get('it/devicestage/get')
        .then((res)=>{
            let arr = []
            res.data.data.forEach((d)=>{
                let obj = {
                    id : d._id,
                    stage:d.stage
                }
                arr.push(obj)
            })
            setdevicestage_arr(arr)
        }).catch((err)=>{
            // console.log('err',err)
        })
    }
    
    
    function add_disk(){


        const fd = new FormData()
        fd.append('model_id',disk.model_id)
        fd.append('brand',disk.brand_id)
        fd.append('disk_capacity',disk.disk_capacity)
        fd.append('related_to',disk.related_to_id)
        fd.append('device_stage',disk.device_stage_id)
        disk?.image?.originFileObj !== undefined && fd.append('disk_image',disk?.image?.originFileObj)

        authHeader['headers']['Content-Type'] = 'multipart/form-data'

        console.log('disk data amma',fd)
        axios.post('it/disk/create',fd,authHeader)
        .then((res)=>{
            toast.success(res.data.message)
            setmodal(false)
            getdata()
        }).catch((err)=>{
            console.log('err data kp',err.response.data)
            if(err){
                seterror({...error,model_id:'Model Id should be unique'})
            }
            // console.log('err add_disk',err.response)
        })
    }

    function update_disk(){
        const fd = new FormData()
        fd.append('model_id',disk.model_id)
        fd.append('brand',disk.brand_id)
        fd.append('disk_capacity',disk.disk_capacity)
        fd.append('related_to',disk.related_to_id)
        fd.append('device_stage',disk.device_stage_id)
        disk?.image?.originFileObj !== undefined && fd.append('disk_image',disk?.image?.originFileObj)
        fd.append('method','_PUT')
        axios.post(`it/disk/update/${disk.id}`,fd)
        .then((res)=>{
            toast.success(res.data.message)
            setmodal(false)
            getdata()
        }).catch((err)=>{
        })
    }

    function delete_disk(v){
        axios.delete(`it/disk/delete/${v._id}`)
        .then((res)=>{
            toast.success(res.data.message)
            getdata()
        }).catch((err)=>{
        })
    }

    const handleUpload = (v) => {
        setdisk({...disk,image:v.fileList[0]})
    }


    function setdataforedit(p){
        console.log('p',p)
        setdisk({
            id:p._id,
            model_id:p.model_id,
            disk_capacity:p?.disk_capacity,
            device_stage:p?.device_stage?.stage,
            device_stage_id:p?.device_stage?._id,
            brand:p?.brand?.brand_name,
            brand_id:p?.brand?._id,
            related_to:p?.related_to?.related_to,
            related_to_id:p?.related_to?._id,
            image:p?.image})
        setmodal(true)
    }


    return(
        <ITMain>
            <div style={{width:'98%'}}>
                <Toaster />


                    <Drawer visible={modal} closable={false}>
                {/* <Modal visible={modal} footer={false} closable={false} width='260px'> */}
                    <div style={{width:'220px',position:'relative',height: (disk.image === undefined || disk.image === null || disk.image === "") ? '450px' : '380px'}}>

                    <Tooltip title="Close" >
                            <IconButton style={{padding:'2px',marginTop:'-5px',position:'absolute',right:-10,top:-10}}>
                            <IoClose  onClick={()=>setmodal(!modal)} style={{ cursor: 'pointer',color:gray_dark_1, }} size={16} />
                            </IconButton>
                        </Tooltip>

                        <p style={{fontSize:'10px',fontWeight:'500',background:'#c9ffe2',padding:'4px',marginTop:'0px'}}>Use the below form to create or update the disk for your employees</p>


                        <ItSmallText val={'Related To'} fontSize={12.5} marginfun={'5px 0px 5px 0px'} />
                        <Select value={disk.related_to} onChange={(v)=>{console.log('van here',v);setdisk({...disk,related_to:related_to_arr.find((b)=>b?.id == v)?.type,related_to_id:v});seterror({...error,related_to_id:''})}} style={{width:'100%',borderLeft:`3px solid ${theme_color}`,borderRadius:'2px'}} >
                                {related_to_arr.map((r)=>(
                                    <Option key={r} value={r?.id} style={{textTransform:'capitalize'}}>{r?.type}</Option>
                                ))}
                        </Select>

                        <ItSmallText val={'Brand'} fontSize={12.5} marginfun={'5px 0px 5px 0px'} />
                        <Select value={disk.brand} onChange={(v)=>{setdisk({...disk,brand:brand_arr?.find((b)=>b?.id == v)?.brand_name,brand_id:v});seterror({...error,brand:''})}} style={{width:'100%',borderLeft:`3px solid ${theme_color}`,borderRadius:'2px'}} >
                                {brand_arr.map((r)=>(
                                    <Option key={r} value={r?.id}>{r?.brand_name}</Option>
                                ))}
                        </Select>
                        <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                            {(error?.brand) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                            <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.brand  }</p>
                        </div>

                      


                        <div style={{marginLeft:'0px'}}>
                        <ItSmallText val={'Model Id'} fontSize={12.5} marginfun={'5px 0px 5px 0px'}/>
                        <TextInput val={disk.model_id} onchange={(e)=>{setdisk({...disk,model_id:e.target.value});seterror({...error,model_id:''})}} />
                        <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                            {(error?.model_id) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                            <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.model_id  }</p>
                        </div>

                        <ItSmallText val={'Disk Capacity'} fontSize={12.5} marginfun={'5px 0px 5px 0px'}/>
                        <TextInput val={disk.disk_capacity} onchange={(e)=>{setdisk({...disk,disk_capacity:e.target.value});seterror({...error,disk_capacity:''})}} />
                        <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                            {(error?.model_id) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                            <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.model_id}</p>
                        </div>

                        <ItSmallText val={'Device Stage'} fontSize={12.5} marginfun={'5px 0px 5px 0px'} />
                        <Select value={disk.device_stage} onChange={(v)=>{setdisk({...disk,device_stage_id:v,device_stage:devicestage_arr.find((b)=>b?.id == v)?.stage});seterror({...error,device_stage:''})}} style={{width:'100%',borderLeft:`3px solid ${theme_color}`,borderRadius:'2px'}} >
                                {devicestage_arr.map((r)=>(
                                    <Option key={r} value={r?.id} style={{textTransform:'capitalize'}}>{r?.stage}</Option>
                                ))}
                        </Select>
                        <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                            {(error?.device_stage) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                            <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.device_stage  }</p>
                        </div>

                       

                       
                     

                       

                       
                        
                        <ItSmallText val={'Image'} fontSize={12.5} marginfun={'5px 0px 5px 0px'} />
                        {(disk.image === undefined || disk.image === null || disk.image === "")   ?
                        <Dragger style={{ marginTop: '10px',height:'100px',minHeight:'100px',maxHeight:'80px'}}
                            onChange={handleUpload}
                            beforeUpload={() => false}
                            showUploadList={false}>
                            
                            <div style={{display:'flex',height:'100px',alignItems:'center',flexDirection:'column'}}>
                            <img src={'https://cdn-icons-png.flaticon.com/512/2504/2504768.png'} style={{ width: '30px', objectFit: 'contain',alignItems:'center',display:'flex',justifyItems:'center',justifySelf:'center',alignSelf:'center' }} />
                            <p className="ant-upload-text" style={{fontWeight:'600',fontSize:'8px',marginTop:'10px'}}>Click or drag file to this area to upload</p>
                            <p className="ant-upload-hint" style={{fontSize:'6px'}}>
                                Support for a single file upload. Strictly used from uploading brand image
                            </p>
                            </div>
                        </Dragger>
                        :
                        <div>
                            {disk?.image?.name?.length !== undefined && disk?.image?.name?.length !== null ?
                            <div style={{ display: 'flex', alignItems: 'center', border: '1px solid lightgray', padding: '2px 10px', position: 'relative', marginTop: '10px' }}>
                                <img src={'https://cdn-icons-png.flaticon.com/512/2504/2504768.png'} style={{ width: '20px', objectFit: 'contain' }} />
                                <div style={{ marginLeft: '10px',marginTop:'5px' }}>
                                    <h6 style={{ fontSize: '8px', fontWeight: '600', marginBottom: '2px' }}>{disk?.image?.name?.slice(0,25)}</h6>
                                    <h6 style={{ fontSize: '6px', color: 'gray' }}>{disk?.image?.size} bytes</h6>
                                </div>
                                <MdOutlineDelete size={15} style={{ position: 'absolute', right: '10px' }} onClick={()=>setdisk({...disk,image:null})} />
                            </div>
                            :
                            <div style={{position:'relative',}}>
                                <IoClose  onClick={()=>setdisk({...disk,image:null})} style={{ cursor: 'pointer',color:gray_dark_1,position:'absolute',right:0 }} size={16} />
                                <img src={`${base_url}${disk?.image}`} style={{ width: '40px',height:'40px', objectFit: 'contain' }} />
                            </div>  }  
                        </div>}
                        </div>
                        <div style={{marginLeft:'-5px'}}>
                        <PrimaryButton btn_name={disk.id === null ? 'Add disk' : "Update disk"} onClick={disk.id === null ? add_disk : update_disk}  />
                        </div>

                    </div>
                {/* </Modal> */}
                </Drawer>


                <div style={{width:'100%',display:'flex',alignItems:'center',justifyContent:'space-between',marginTop:'-10px',borderBottom:`1px solid #f2f2f2`,maxHeight:'50px'}}>
                <Itheadings val={'Disk'} />
                
                <div style={{display:'flex',alignItems:'center'}}>
                   <Select value={disk.device_stage =='' ?null : disk.device_stage} onChange={(v)=>{setdisk({...disk,device_stage_id:v,device_stage:devicestage_arr.find((b)=>b?.id == v)?.stage});seterror({...error,device_stage:''})}} style={{width:'40%',borderLeft:`3px solid ${theme_color}`,borderRadius:'2px',marginRight:'10px'}} placeholder="current stage" >
                            {devicestage_arr.map((r)=>(
                                <Option key={r} value={r?.id} style={{textTransform:'capitalize'}}>{r?.stage}</Option>
                            ))}
                    </Select>
                    <div style={{width:'240px'}} onClick={()=>{setmodal(true);setdisk({id:null,model_id:'',device_stage:'',brand:'',type:'',image:''})}} > 
                    <PrimaryButtonAdd btn_name={'Add disk'}  />
                    </div>
                </div>
                 
                </div>

                <SmallText fontWeight={'600'} fontSize={13} val={`Total Found (${pagination?.total})`} marginfun={'10px 0px 10px 0px'} />
            
                        <div className='row'>
                        {disk_arr?.map((p)=>(
                        <div className='col-md-3'>
                        <div className='card_processor '  key={p._id} style={{display:'flex',margin:'5px 0px',cursor:'pointer',paddingLeft:'10px',width:'100%',borderRadius:'2px',border:'0.5px solid #ddd',padding:'10px',display:'flex',position:'relative'}}>
                            {/* <span style={{position:'absolute',right:0,top:0,fontSize:'8px',padding:'2px 10px',textAlign:'center',fontWeight:'800',background:(p.device_stage.stage !== 'damaged' &&  p.device_stage.stage !== 'under-service') ? '#72dba1' : '#c4655c',borderBottomLeftRadius:'21px',textTransform:'uppercase'}}>{p?.device_stage?.stage}</span> */}
                            <img src={`${base_url}${p.image}`} style={{minWidth:'80px',maxWidth:'80px',height:'60px',objectFit:'contain'}} />
                            <div style={{paddingTop:'1px',marginLeft:'3%'}}>
                            <SmallText fontWeight={'500'} fontSize={12} val={`${p?.brand?.brand_name}`} marginfun={'0px 0px 0px 0px'} />
                            <SmallText fontWeight={'500'} fontSize={10} val={`Model Id : ${p?.model_id}`} marginfun={'0px 0px 0px 0px'} />
                            <div style={{display:'flex',marginLeft:'-10px',marginTop:'5px'}} >
                            <Tooltip title="Delete" >
                                <IconButton style={{marginTop:'-5px'}} onClick={()=>delete_disk(p)}>
                            <MdOutlineDelete  size={16} style={{color:'#000'}} />
                            </IconButton>
                            </Tooltip>
                            <Tooltip title="Edit" >
                                <IconButton style={{marginTop:'-5px',}} onClick={()=>setdataforedit(p)}>
                            <BiEditAlt  size={16} style={{color:'#000'}} />
                            </IconButton>
                            </Tooltip>
                            </div>
                            </div>
                        </div>
                        </div>
                        ))}
                        </div>
            

            </div>
        </ITMain>
    )
}


export default Disk