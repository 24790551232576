


//main bg is responsible for the navbar 
export const main_bg = '#222f3e'


//active orange color for drawer 
export const act_col = '#007eb9'

export const image_not_Added_for_Asset = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTMhbZipbb5Vy2d5oF9tLJQ_5db4CG68J8iaw&usqp=CAU'