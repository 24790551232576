import React, { useState } from 'react'
import './HomeMain.css'
import { Row, Col } from 'antd';
import { useNavigate } from 'react-router-dom'
import FooterMain from './FooterMain';
import { HiOutlineMenuAlt4 } from 'react-icons/hi'
import { FaUserAlt } from 'react-icons/fa'
import { IoClose } from 'react-icons/io5'
import { Drawer } from 'antd'
import 'aos';
import { banner_img_url, fcpl_white_logo, mobile_img_url } from '../../helpers/Constants';
import { useSelector } from 'react-redux';

function HomeMain() {

  const theme_color = useSelector(state=>state.Auth.theme_color)

  const navigate = useNavigate()

  const [visible, setvisible] = useState(false)


  
  return (
    <div>
      <Drawer mask={false} closable={false} placement="top" visible={visible} style={{ padding: '0px !important', margin: '0px !important', }} width={window.innerWidth} height={window.innerHeight / 1.3}>
        <div style={{ background: '#fff', width: '100%', height: '100%', margin: '0px', padding: '0px' }}>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', margin: 'auto', padding: '5px 0px' }}>
            <IoClose color="black" size={24} onClick={() => setvisible(!visible)} />
            <img className="inactive" src={fcpl_white_logo} style={{ width: '25px', height: '25px', marginLeft: '10px' }} />
            <IoClose color="black" style={{ opacity: 0 }} size={24} />
          </div>

          <h6 className="nav_link inactive" style={{ color: 'black' }}><a href="#section2" style={{ color: 'black', textDecoration: 'none' }}>Let's Explore</a></h6>
          <h6 className="nav_link inactive" style={{ color: 'black' }}><a href="#section2" style={{ color: 'black', textDecoration: 'none' }}>Our goal</a></h6>
          <h6 className="nav_link inactive" style={{ color: 'black' }}><a href="#section3" style={{ color: 'black', textDecoration: 'none' }}>About us</a></h6>
          <h6 className="nav_link inactive" style={{ color: 'black' }}><a href="#section3" style={{ color: 'black', textDecoration: 'none' }}>Our team</a></h6>
          <h6 className="nav_link inactive" style={{ color: 'black' }}><a href="#section4" style={{ color: 'black', textDecoration: 'none' }}>Future works</a></h6>
          <h6 className="nav_link inactive" style={{ color: 'black' }}><a href="#section4" style={{ color: 'black', textDecoration: 'none' }}>Contact us</a></h6>
          <h6 className="nav_link inactive" style={{ color: 'black' }}><a href="#section4" style={{ color: 'black', textDecoration: 'none' }}>Support</a></h6>
          <h6 className="nav_link inactive" style={{ color: 'black' }} onClick={() => navigate('./login')} > <FaUserAlt className="nav_link inactive" style={{ marginRight: '2px', color: 'black' }} /> Sign in</h6>

        </div>
      </Drawer>
      <div style={{ minHeight: window.innerHeight < 700 ? '100vh' : '100%', color: 'black' }}>
        <div className="sticky-hone-nav-bar sticky-hone-nav-bar-color" style={{ paddingTop: '0px', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}} >
          <div className="navbar-child">
            <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
              <HiOutlineMenuAlt4 className="nav_link1 inactive" onClick={() => setvisible(!visible)} />

              <img className="inactive" src={fcpl_white_logo} style={{ width: '25px', height: '25px' }} />
              <h6 className="nav_link inactive"><a href="#section2" style={{ color: 'white', textDecoration: 'none' }}>Let's Explore</a></h6>
              <h6 className="nav_link inactive"><a href="#section2" style={{ color: 'white', textDecoration: 'none' }}>Our goal</a></h6>
             
              <h6 className="nav_link inactive"><a href="#section4" style={{ color: 'white', textDecoration: 'none' }}>Future works</a></h6>
              <h6 className="nav_link inactive"><a href="#section4" style={{ color: 'white', textDecoration: 'none' }}>Contact us</a></h6>
              <h6 className="nav_link inactive"><a href="#section4" style={{ color: 'white', textDecoration: 'none' }}>Support</a></h6>
              <h6 className="nav_link inactive" onClick={() => navigate('./login')} > <FaUserAlt className="nav_link inactive" style={{ marginRight: '2px' }} /> Sign in</h6>
              <FaUserAlt className="nav_link1 inactive" onClick={() => navigate('./login')} />
            </div>
          </div>
        </div>

        <p className="subheader_nav">We are trained to make everything impossible to  <strong style={{ color: '#05b0ff', fontWeight: 'bold' }}>possible</strong></p>
        <div style={{ width: '80vw', margin: 'auto 10vw' }}>
          <Row>
            <Col xs={24} xl={24} >
              <div className='grid_container'>
                <h2 className='header_title'>
                  We are ready make your daily work easy
                </h2>
                <h2 className='header_description'>
                  Customized software to make your daily routine go smooth and fast as you think,brought to you by a company with the long term vision to transform the way you work.
                </h2>
              </div>
            </Col>

            <Col xs={24} xl={24} style={{ display: 'flex', justifyItems: 'center', width: '100%', justifySelf: 'center', alignItems: 'center', justifyContent: 'center' }} className="banner_img" >
              <img src={banner_img_url} style={{ width: '100vw', height: '100vh', objectFit: 'contain', marginTop: '-50px' }} />
            </Col>
          </Row>

        </div>
      </div>

      <div className="section2" id="section2">

        <h6 className='heading2'><bold style={{ color: '#0fafff' }}> FCPL CRM </bold> helps you create and automate a  </h6>
        <h6 className='heading3'>better customer journey</h6>

        <Row style={{ marginTop: '40px' }} className="section2_sub">
          <Col xs={24} xl={10} >
            <div data-aos="animation_name">
              <h3 className="heading2">We Help your Sales Team Close More Leads Faster & Smarter</h3>
              <h4 className="heading3" style={{ color: 'black', opacity: 0.8 }}>Everything you need to manage your leads, follow-ups, pipeline, sales team, opportunities & clients and grow your Business in the best possible way is here.</h4>
            </div>

          </Col>
          <Col xs={24} xl={2} ></Col>

          <Col xs={24} xl={12} >
            <img src={mobile_img_url} style={{ width: '90%', height: '90%' }} className="img_banner" alt="img_banner" />
          </Col>
        </Row>
      </div>

     

        <FooterMain />
    </div>
  )
}

export default HomeMain